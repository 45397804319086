import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'editColumnCheck',
  standalone: true
})
export class EditColumnCheckPipe implements PipeTransform {

  // SK10APR24 condition to check the edit column
  transform(arg:any, element: any, editCol: any): any {
    var returnVal = "";
    if(editCol.length > 0){
      if(typeof editCol[0] === 'string'){
        if(editCol.includes(element)) returnVal = "string";
      } else if(typeof editCol[0] === 'object'){
        returnVal = (editCol.filter((col:any)=>col.name === element)).length > 0 ? "object" : "";
      }
    }
    return returnVal 
  }

}
