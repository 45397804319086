<div class="form-card">
    <div class="col-md-12 mt-3">
        <div class="static-width-for-card width-100">
            <app-cards #hidebasic [inlineDetailed]="true">
                <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                <div class="header-section flex content-space-between">
                     <span>{{ 'HEADINGS.BASIC INFO' | customTranslate : 'Basic info' }}</span>
                    <div class="card-btn show-detail-view">
                        <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                        <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                            class="card-icon" (click)="ngOnInit()"></svg-icon>
                    </div>
                </div>
                <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                <div class="body-section card-text">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-start width-100">
                            <div class="d-flex flex-column width-25">
                                <label class="label-head">{{ 'WORK_BASICINFO.EMPLOYEEID' | customTranslate : 'Employee ID' }}</label>
                                <label
                                    class="label-sub">{{userGetData?.employeeId}}</label>
                            </div>
                            <div class="d-flex flex-column width-25">
                                <label class="label-head">{{ 'WORK_BASICINFO.EMPLOYEETYPE' | customTranslate : 'Employee Type' }}</label>
                                <label
                                    class="label-sub">{{userGetData?.basicinfo?.employmentType}}</label>
                            </div>
                            <div class="d-flex flex-column width-25">
                                <label class="label-head">{{'WORK_BASICINFO.WORKLOCATION' | customTranslate : 'Work Location'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.basicinfo?.officeLocation}}</label>
                            </div>
                            <div class="d-flex flex-column width-25">
                                <label class="label-head">{{'WORK_BASICINFO.EMPLOYEESTATUS' | customTranslate : 'Employee Status'  }}</label>
                                <label class="label-sub">
                                    {{ userGetData?.isActive ? ( 'WORK_BASICINFO.ACTIVE' | customTranslate : 'Active' ) : ( 'WORK_BASICINFO.INACTIVE' | customTranslate : 'Inactive' ) }}
                                  </label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4 justify-content-start width-100">
                            <div class="d-flex flex-column width-25">
                                <label class="label-head">{{'WORK_BASICINFO.PROBATIONPERIOD' | customTranslate : 'Probation Period'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.basicinfo?.probationPeriod}}</label>
                            </div>
                            <div class="d-flex flex-column width-25">
                                <label class="label-head">{{'WORK_BASICINFO.DATEOFJOINING' | customTranslate : 'Date Of Joining'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.basicinfo?.dateOfJoining | date:'mediumDate'}}</label>
                            </div>
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'WORK_BASICINFO.WORKEXPERIENCE' | customTranslate : 'Work Experience(In Months)'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.basicinfo?.workExperience}}</label>
                            </div>
                            <div *ngIf="!userGetData?.isActive" class="d-flex flex-column width-25">
                                <!-- RS27AUG24 -->
                                <!-- Used the translate pipe to successfully implement the translation -->
                                <label class="label-head">{{'DATE_OF_RESIGNATION' | customTranslate : 'Date of Resignation'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.basicinfo?.dateOfLeft | date:'mediumDate'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                <div class="card-expanded-section detailed-section" plain>
                    <!-- 'plain' or 'modal' based on the requirement -->
                    <div class="card-header">{{'WORK_BASICINFO.EDITBASICINFO' | customTranslate : 'Edit Basic Info'  }}</div>
                    <div class="card-body">
                        <form [formGroup]="basicInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-6" [class]=" basicInfoForm.controls['empID'].invalid && basicInfoForm.controls['empID'].touched ? 'has-error' : '' ">
                                        <!--RG26AUG24 changed the label UI -->
                                        <label for="employee-id" class="form-label label-txt modal-field-label">{{ 'WORK_BASICINFO.EMPLOYEEID' | customTranslate : 'Employee ID' }}*</label>
                                        <input type="text" formControlName="empID"
                                            id="employee-id" placeholder=""
                                            (focusout)="handleEmpID($event)"
                                            (keyup)="handleEmpID($event)" [readonly]="globalValues.superUser">
                                    </div>
                                    <div class="field-input col-md-6" [class]=" basicInfoForm.controls['empType'].invalid && basicInfoForm.controls['empType'].touched ? 'has-error' : '' ">
                                        <label for="emp-type" class="form-label label-txt modal-field-label">{{ 'WORK_BASICINFO.EMPLOYEETYPE' | customTranslate : 'Employee Type' }}*</label>
                                        <select id="emp-type" formControlName="empType"
                                            class="form-select" (change)="handleEmpType($event)"
                                            (keyup)="handleEmpType($event)">
                                            <option [disabled]="!fromHrmPage" *ngFor="let et of empTypeDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                                        </select>
                                        <p class="error-msg"><span
                                            *ngIf="this.basicInfoForm.controls['empType'].invalid && this.basicInfoForm.controls['empType'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6" [class]=" basicInfoForm.controls['empLocation'].invalid && basicInfoForm.controls['empLocation'].touched ? 'has-error' : '' ">
                                        <label for="location" class="form-label label-txt modal-field-label">{{ 'WORK_BASICINFO.WORKLOCATION' | customTranslate : 'Work Location' }}*</label>
                                        <input type="text" formControlName="empLocation"
                                            id="location" placeholder=""
                                            (focusout)="handleLocation($event)"
                                            (keyup)="handleLocation($event)" [readOnly]="!fromHrmPage">
                                        <p class="error-msg"><span
                                            *ngIf="this.basicInfoForm.controls['empLocation'].invalid && this.basicInfoForm.controls['empLocation'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6" [class]=" basicInfoForm.controls['probPeriod'].invalid && basicInfoForm.controls['probPeriod'].touched ? 'has-error' : '' ">
                                        <label for="probation-period" class="form-label label-txt modal-field-label">{{ 'WORK_BASICINFO.PROBATIONPERIOD' | customTranslate : 'Probation Period' }}</label>
                                        <select id="probation-period" formControlName="probPeriod"
                                            class="form-select" (change)="handleProb($event)"
                                            (keyup)="handleProb($event)">
                                            <option [disabled]="!fromHrmPage" *ngFor="let pb of probPeriodDropdown.valueList" selected [value]="pb.key">{{pb.value}}</option>
                                        </select>
                                        <!-- <p class="error-msg"><span
                                            *ngIf="this.basicInfoForm.controls['probPeriod'].invalid && this.basicInfoForm.controls['probPeriod'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p> -->
                                    </div>
                                    <div class="field-input col-md-6" [class]=" basicInfoForm.controls['dateOfJoin'].invalid && basicInfoForm.controls['dateOfJoin'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="d.o.j">{{ 'WORK_BASICINFO.DATEOFJOINING' | customTranslate : 'Date Of Joining' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfJoin"
                                            id="d.o.j" name="date" format="mm-dd-yyyy"
                                            (focusout)="handleDOJ($event)" (keyup)="handleDOJ($event)" [readOnly]="!fromHrmPage"/>
                                        <p class="error-msg"><span
                                            *ngIf="this.basicInfoForm.controls['dateOfJoin'].invalid && this.basicInfoForm.controls['dateOfJoin'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6" [class]=" basicInfoForm.controls['empExperience'].invalid && basicInfoForm.controls['empExperience'].touched ? 'has-error' : '' ">
                                        <label for="employee-experience" class="form-label label-txt modal-field-label">{{'WORK_BASICINFO.WORKEXPERIENCE' | customTranslate : 'Work Experience(In Months)'  }}</label>
                                        <input type="text" formControlName="empExperience"
                                            class="form-control" id="employee-experience"
                                            placeholder="Ex: 10 years, 2 months"
                                            (focusout)="handleEmpExp($event)"
                                            (keyup)="handleEmpExp($event)">
                                        <!-- <p class="error-msg"><span
                                            *ngIf="this.basicInfoForm.controls['empExperience'].invalid && this.basicInfoForm.controls['empExperience'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p> -->
                                    </div>
                                    <div class="field-input col-md-6 w-50" [class]=" basicInfoForm.controls['empStatus'].invalid && basicInfoForm.controls['empStatus'].touched ? 'has-error' : '' ">
                                        <label for="emp-status" class="form-label label-txt modal-field-label">{{'WORK_BASICINFO.EMPLOYEESTATUS' | customTranslate : 'Employee Status'  }}*</label>
                                        <select id="emp-status" formControlName="empStatus"
                                            class="form-select" (change)="handleEmpStat($event)">
                                            <option [disabled]="!fromHrmPage" selected value="" ></option>
                                            <option [disabled]="!fromHrmPage">{{ 'WORK_BASICINFO.ACTIVE' | customTranslate : 'Active' }} </option>
                                            <option [disabled]="!fromHrmPage">{{ 'WORK_BASICINFO.INACTIVE' | customTranslate : 'Inactive' }} </option>
                                        </select>
                                        <p class="error-msg"><span
                                            *ngIf="this.basicInfoForm.controls['empStatus'].invalid && this.basicInfoForm.controls['empStatus'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div *ngIf="basicInfoForm.controls['empStatus'].value === 'Inactive'" class="field-input col-md-6" [class]=" basicInfoForm.controls['dateOfResign'].invalid && basicInfoForm.controls['dateOfResign'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="dateOfResign">{{'DATE_OF_RESIGNATION' | customTranslate : 'Date of Resignation'  }}</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfResign"
                                            id="dateOfResign" name="date" format="mm-dd-yyyy"
                                            (focusout)="handleDOJ($event)" (keyup)="handleDOJ($event)" [readOnly]="!fromHrmPage"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer border-0">
                        <div class="center-button text-right">
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'"
                                [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); basicInfoForm.reset()"
                                [fcBtnId]="'myId'"></app-fusion-button>
                                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}"
                                [buttonType]="'input-button'" 
                                (buttonClickEmit)="savebasicInfo(); hidebasic.hidePlainView()"
                                [buttonDisable]="!basicInfoForm.valid"></app-fusion-button>
                        </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>

    <div class="col-md-12 mt-4">
        <div class="static-width-for-card width-100">
            <app-cards #hidework [inlineDetailed]="true">
                <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                <div class="header-section flex content-space-between">
                    <span>{{'WORK_INFO.WORKINFO' | customTranslate : 'Work Info'  }}</span>
                    <div class="card-btn show-detail-view">
                        <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                        <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                            class="card-icon" (click)="ngOnInit()"></svg-icon>
                    </div>
                </div>
                <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                <div class="body-section card-text">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-start width-100">
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'WORK_INFO.JOBTITLE' | customTranslate : 'Job Title'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.workinfo?.jobTitle}}</label>
                            </div>
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'WORK_INFO.DEPARTMENT' | customTranslate : 'Department'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.workinfo?.department}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4 justify-content-start width-100">
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'WORK_INFO.PRIMARY REPORTING MANAGER' | customTranslate : 'Primary Reporting Manager'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.primaryReportingManager}}</label>
                            </div>
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'WORK_INFO.SECONDARY REPORTING MANAGER' | customTranslate : 'Secondary Reporting Manager'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.secondaryReportingManager}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4 justify-content-start width-100">
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'WORK_INFO.DESIGNATION' | customTranslate : 'Designation'  }}</label>
                                <label
                                    class="label-sub">{{userGetData?.workinfo?.designation}}</label>
                            </div>
                          
                        </div>
                    </div>
                </div>
                <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                <div class="card-expanded-section detailed-section edit-work-info" plain>
                    <!-- 'plain' or 'modal' based on the requirement -->
                    <div class="card-header">{{'WORK_INFO.EDITWORKINFO' | customTranslate : 'Edit Work Info'  }}</div>
                    <div class="card-body">
                        <form [formGroup]="workInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-6" [class]="workInfoForm.controls['jobTitle'].invalid && workInfoForm.controls['jobTitle'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label">{{'WORK_INFO.JOBTITLE' | customTranslate : 'Job Title'  }}*</label>
                                        <input type="text" formControlName="jobTitle"
                                            class="form-control" id="job-title"
                                            placeholder=""
                                            (focusout)="handleJobtitle($event)"
                                            (keyup)="handleJobtitle($event)" [readOnly]="!fromHrmPage">
                                            <p class="error-msg"><span
                                                *ngIf="this.workInfoForm.controls['jobTitle'].invalid && this.workInfoForm.controls['jobTitle'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                        </div>
                                        <div class="field-input col-md-4">
                                        <label class="form-label label-txt modal-field-label">{{'WORK_INFO.DEPARTMENT' | customTranslate : 'Department'  }}</label>
                                        <input type="text" formControlName="empDept"
                                            class="form-control" id="job-department"
                                            placeholder=""
                                            (focusout)="handleWorkDept($event)"
                                            (keyup)="handleWorkDept($event)" [readOnly]="!fromHrmPage">
                                    </div>
                                    <div class="field-input col-md-4">
                                        <label class="form-label label-txt modal-field-label">{{'WORK_INFO.PRIMARY REPORTING MANAGER' | customTranslate : 'Primary Reporting Manager'  }}*</label>
                                        <app-drop-down  class="field-input" [lists]='primaryList'  [isDisabled]="!fromHrmPage"
                                        [errorValue]="'Select'" [errorMessage]="'Please select an option'"
                                        (dataEmit)="emittedValue($event,'reportingManager1')"
                                        [selectedData]="userGetData?.primaryReportingManager"></app-drop-down>
                                      </div>
                                      <div class="field-input col-md-4">
                                        <label class="form-label label-txt modal-field-label">{{ 'WORK_INFO.SECONDARY REPORTING MANAGER' | customTranslate : 'Secondary Reporting Manager' }}</label>
                                        <app-drop-down  class="field-input" [lists]='primaryList' id="rp2"  [isDisabled]="!fromHrmPage"
                                        [errorValue]="'Select'" [errorMessage]="'Please select an option'"
                                        (dataEmit)="emittedValue($event,'reportingManager2')"
                                        [selectedData]="userGetData?.secondaryReportingManager"></app-drop-down>
                                      </div>
                                    <div class="field-input col-md-4 w-50">
                                        <label class="form-label label-txt modal-field-label">{{'WORK_INFO.DESIGNATION' | customTranslate : 'Designation'  }}</label>
                                        <!-- <app-drop-down  class="field-input" [lists]='designationList' [isDisabled]="!fromHrmPage"
                                        [errorValue]="'Select'" [errorMessage]="'Please select an option'"
                                        (dataEmit)="emittedValue($event,'empdesignation')"
                                        [selectedData]="userGetData?.workinfo?.designation"></app-drop-down> -->
                                        <input type="text" formControlName="empdesignation"
                                            class="form-control" id="job-designation"
                                            placeholder=""
                                            (focusout)="handleWorkDesig($event)"
                                            (keyup)="handleWorkDesig($event)" [readOnly]="!fromHrmPage">
                                            </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer  border-0">
                        <div class="center-button text-right">
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'"
                                [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); workInfoForm.reset()"
                                [fcBtnId]="'myId'">
                            </app-fusion-button>
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}"
                             [buttonType]="'input-button'" (buttonClickEmit)="saveworkInfo(); hidework.hidePlainView()"
                            [buttonDisable]="!workInfoForm.valid || defaultValueDropDown">
                            </app-fusion-button>
                         </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>

    <div class="col-md-12 mt-4">
        <div class="static-width-for-card width-100">
            <app-cards #hidePF [inlineDetailed]="true">
                <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                <div class="header-section flex content-space-between">
                    <span>{{'ACCOUNT_INFO.ACCOUNT INFO' | customTranslate : 'Account Info' }}</span>
                    <div class="card-btn show-detail-view">
                        <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                        <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                            class="card-icon" (click)="ngOnInit()"></svg-icon>
                    </div>
                </div>
                <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                <div class="body-section card-text">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-start width-100">
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'ACCOUNT_INFO.PF ACCOUNT NUMBER' | customTranslate : 'PF Account Number' }}</label>
                                <label
                                    class="label-sub">{{financeInfo?.pfAccountNumber}}</label>
                            </div>
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'ACCOUNT_INFO.UAN' | customTranslate : 'UAN' }}</label>
                                <label
                                    class="label-sub">{{financeInfo?.uan}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4  justify-content-start width-100">
                            <div class="d-flex flex-column  width-50">
                                <label class="label-head">{{'ACCOUNT_INFO.ESI' | customTranslate : 'ESI' }}</label>
                                <label
                                    class="label-sub">{{financeInfo?.esi}}</label>
                            </div>
                            <div class="d-flex flex-column width-50">
                                <label class="label-head">{{'ACCOUNT_INFO.AADHAAR' | customTranslate : 'Aadhaar' }}</label>
                                <label
                                    class="label-sub">{{financeInfo?.aadhaar}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4 justify-content-start width-100">
                            <div class="d-flex flex-column width-100">
                                <label class="label-head">{{'ACCOUNT_INFO.PAN' | customTranslate : 'PAN' }}</label>
                                <label
                                    class="label-sub">{{financeInfo?.panNumber}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                <div class="card-expanded-section detailed-section" plain>
                    <!-- 'plain' or 'modal' based on the requirement -->
                    <div class="card-header">{{'ACCOUNT_INFO.EDIT PF ACCOUNT DETAILS' | customTranslate : 'Edit PF Account Details' }}</div>
                    <div class="card-body">
                        <form [formGroup]="pfInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-6"  [class]=" pfInfoForm.controls['pfAccNum'].invalid && pfInfoForm.controls['pfAccNum'].touched ? 'has-error' : '' ">                                        
                                        <label for="pf" class="form-label label-txt modal-field-label">{{'ACCOUNT_INFO.PF ACCOUNT NUMBER' | customTranslate : 'PF Account Number' }}</label>
                                        <input type="text" formControlName="pfAccNum" class="form-control"
                                            id="pf" placeholder="Ex:54110 000 0054321" [readOnly]="!fromHrmPage">
                                        <p class="error-msg">
                                           <span
                                            *ngIf="this.pfInfoForm.controls['pfAccNum'].invalid && this.pfInfoForm.controls['pfAccNum'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_PF_NUMBER' | customTranslate : 'Please enter a valid PF number' }}</span>
                                        </p>
                                        </div>
                                    <div class="field-input col-md-4"[class]=" pfInfoForm.controls['uan'].invalid && pfInfoForm.controls['uan'].touched ? 'has-error' : '' ">
                                        <label for="u-a-n" class="form-label label-txt modal-field-label">{{ 'ACCOUNT_INFO.UAN' | customTranslate : 'UAN' }}</label>
                                        <input type="text" formControlName="uan" class="form-control"
                                            id="u-a-n" placeholder="Ex: 100904319456333333456" [readOnly]="!fromHrmPage">
                                        <p class="error-msg">
                                            <span
                                            *ngIf="this.pfInfoForm.controls['uan'].invalid && this.pfInfoForm.controls['uan'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_UAN_NUMBER' | customTranslate : 'Please enter a Valid UAN Number' }}</span>
                                        </p>
                                        </div>
                                    <div class="field-input col-md-4" [class]=" pfInfoForm.controls['esi'].invalid && pfInfoForm.controls['esi'].touched ? 'has-error' : '' ">
                                        <label for="e-s-i" class="form-label label-txt modal-field-label">{{ 'ACCOUNT_INFO.ESI' | customTranslate : 'ESI' }}</label>
                                        <input type="text" formControlName="esi" class="form-control"
                                            id="e-s-i" placeholder="Ex: 12345678987654333" [readOnly]="!fromHrmPage" >
                                            <p class="error-msg">
                                               
                                                <span
                                                *ngIf="this.pfInfoForm.controls['esi'].invalid && this.pfInfoForm.controls['esi'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_ESI_NUMBER' | customTranslate : 'Please enter a Valid ESI Number' }}</span>
                                            </p>  
                                        </div>
                                    <div class="field-input col-md-4"  [class]=" pfInfoForm.controls['aadhaar'].invalid && pfInfoForm.controls['aadhaar'].touched ? 'has-error' : '' ">
                                        <label for="aadhaar" class="form-label label-txt modal-field-label">{{ 'ACCOUNT_INFO.AADHAAR' | customTranslate : 'Aadhaar' }}*</label>
                                        <input type="text" formControlName="aadhaar" class="form-control"
                                            id="aadhaar" placeholder="Ex:123456789102" [readOnly]="!fromHrmPage">
                                            <p class="error-msg">
                                                <span *ngIf="pfInfoForm.controls['aadhaar'].invalid && pfInfoForm.controls['aadhaar'].touched && !this.pfInfoForm.controls['aadhaar'].hasError('pattern')">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                </span>  
                                                <span
                                                *ngIf="pfInfoForm.controls['aadhaar'].touched && this.pfInfoForm.controls['aadhaar'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon></span>
                                            </p>                                          
                                         </div>
                                    <div class="field-input col-md-4" [class]=" pfInfoForm.controls['pan'].invalid && pfInfoForm.controls['pan'].touched ? 'has-error' : '' ">
                                        <label for="pan" class="form-label label-txt modal-field-label">{{ 'ACCOUNT_INFO.PAN' | customTranslate : 'PAN' }}</label>
                                        <input type="text" formControlName="pan" class="form-control"
                                            id="pan" placeholder="Ex: ABCDE1234F" [readOnly]="!fromHrmPage">
                                            <p class="error-msg"> 
                                                <span
                                                *ngIf="this.pfInfoForm.controls['pan'].invalid && this.pfInfoForm.controls['pan'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_PAN_NUMBER' | customTranslate : 'Please enter a Valid PAN Number' }}</span>
                                            </p>  
                                        </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer  border-0">
                        <div class="center-button text-right">
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'"
                                [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); pfInfoForm.reset()"
                                [fcBtnId]="'myId'">
                            </app-fusion-button>
                            <app-fusion-button [buttonType]="'input-button'"buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}"
                             (buttonClickEmit)="setPF(); hidePF.hidePlainView()"
                                [buttonDisable]="!pfInfoForm.valid">
                            </app-fusion-button>

                        </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div> 
</div>

