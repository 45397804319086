<!-- Kindy read the README.md file inside the fusion-framework folder to under more about this page -->
<div class="fusion-components">
    <section class="fc-popover fc-section">
        <div class="fc-component-title">Popover</div>
        <div class="flex flex-direction-row">
            <div class="component-wrapper">
                <div class="fw-label">Bootstrap popover</div>
                <p class="fw-details"> This is a Popover component its not fullyflexible for now. We need to include options to enable clickable contents inside the popovers.</p>
                <div class="fw-label">Example :-</div>
                <app-popover [isBootstrap]="true" [dynamicPopover]="'Popover string comes here'">
                    <div class="hover-base">Hove to pop</div>
                </app-popover>
                <details>
                    <summary>HTML</summary>
                    <pre>
                    <code>&lt;app-popover [isBootstrap]="true" [dynamicPopover]="'Popover string comes here'"&gt;</code>
                    &nbsp;&nbsp;<code>&lt;div class="hover-base"&gt;Company Setting&lt;/div&gt;</code>
                    <code>&lt;/app-popover&gt;</code>
                    </pre>
                </details>
            </div>
            <div class="component-wrapper">
                <div class="fw-label">Fusion popover</div>
                <p class="fw-details">This is our customised popover. under the '<i>hover-base</i>' span we can add in any icon or text. Which when hovered will activate the popover and display the content with class '<i>hover-content</i> as popover content.<br>We can even overwrite the css from the parent, pass in events,functions.</p> 
                <div class="fw-label">Example :-</div>
                <app-popover [isBootstrap]="false">
                    <span class="hover-base">
                        <img class="header-right-img" src="../assets/images/icons/general/apps.svg">
                    </span>
                    <div class="hover-content">
                        <ul>
                            <li (click)="popOverItemClick()">abcd</li>
                            <li>efgh</li>
                            <li>ijkl</li>
                        </ul>
                    </div>
                </app-popover>  
                <details>
                    <summary>HTML</summary>
                    <pre>
                    <code>&lt;app-popover [isBootstrap]="true" [dynamicPopover]="'Popover string comes here'"&gt;</code>
                    &nbsp;&nbsp;<code>&lt;div class="hover-base"&gt;Company Setting&lt;/div&gt;</code>
                    <code>&lt;/app-popover&gt;</code>
                    </pre>
                </details>
            </div>
        </div>
    </section>
    <section class="fc-tabs fc-section">
        <div class="fc-component-title">Tab View / Tabs</div>
        <div class="component-wrapper">
            <p class="fw-details">When we need to dislay some tabs and display respective content based on the clicked tab below code to be used.</p>
            <p class="fw-details">This is not made as an component hence the developer should make a copy of the below example or code and make the changes respectively.</p>
            <p class="fw-details">The user will be making changes in the below areas when ever the developer includes a tab view by copying the below code / example.</p>
            <p class="fw-details">* Keep all the id inside the 'fc-tab-view' unique always across the pages and with in a screen too.</p>
            <div class="fw-details">* To have the default selected view in tab onload of a page we should add class 'active' to the <code>&lt;button class="nav-link"&gt;&lt;/button&gt;</code></div>
            <p class="fw-details">* Also make sure the 'aria-selected' is set to true for the same button where we have set 'active'.</p>
            <p class="fw-details">* Then in the 'tab-content' section  on the respective 'tab-pane' we need to give the class names 'show' & 'active'.</p>
            <p class="fw-details">* Make sure the 'nav-link' and 'tab-pane' are properly synced using the attributes 'id', 'data-bs-target', 'aria-controls' and 'aria-labelledby'.</p>
            <div class="fw-label">Example :-</div>
            <div class="fc-tab-view">
                <div class="flex flex-direction-row fc-tab-buttons">
                    <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist"> <!-- Make sure ID are unique everywhere -->
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane" type="button" role="tab" aria-controls="first-tab-pane" aria-selected="true">Tab 1</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane" type="button" role="tab" aria-controls="second-tab-pane" aria-selected="false">Tab 2</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="fcTabContent"><!-- Make sure ID are unique everywhere -->
                    <div class="tab-pane fade show active" id="first-tab-pane" role="tabpanel" aria-labelledby="first-tab" tabindex="0">
                        <!-- Add the content you wnat to display inside the first tab -->
                        First default tab
                    </div>
                    <div class="tab-pane fade" id="second-tab-pane" role="tabpanel" aria-labelledby="second-tab" tabindex="0">
                        <!-- Add the content you wnat to display inside the second tab -->
                        This is Second Tab
                    </div>
                </div>
            </div>
            <details>
                <summary>HTML</summary>
                <pre>
                    <code>
                        &lt;div class="fc-tab-view"&gt;
                        &nbsp;&nbsp;&lt;div class="flex flex-direction-row fc-tab-buttons"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&lt;ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item" role="presentation"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane" type="button" role="tab" aria-controls="first-tab-pane" aria-selected="true"&gt;Tab 1&lt;/button&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;li class="nav-item" role="presentation"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button class="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane" type="button" role="tab" aria-controls="second-tab-pane" aria-selected="false"&gt;Tab 2&lt;/button&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&lt;/ul&gt;
                        &nbsp;&nbsp;&lt;/div&gt;
                        &nbsp;&nbsp;&lt;div class="tab-content" id="fcTabContent"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="tab-pane fade show active" id="first-tab-pane" role="tabpanel" aria-labelledby="first-tab" tabindex="0"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First default tab
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="tab-pane fade" id="second-tab-pane" role="tabpanel" aria-labelledby="second-tab" tabindex="0"&gt;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is Second Tab
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                        &nbsp;&nbsp;&lt;/div&gt;
                        &lt;/div&gt;
                    </code>
                </pre>
            </details>
        </div>
    </section>
    <section class="fc-buttons fc-section">
        <div class="fc-component-title">Buttons</div>
        <div class="component-wrapper">
            <p class="fw-details">Here we have our <code>app-fusion-button</code> component which helps to have a customised button. Fallbacks are yet to be created if some of the css values are not mentioned</p>
            <p class="fw-details">This component contains multiple decorators which are explained below:-</p>
            <p class="fw-details">* <code>buttonValue</code> - Text to be shown in the button and <code>buttonClickEmit</code> - function to fire on click of the button. These are the basic required decorators.</p>
            <p class="fw-details">* Next, <code>buttonType</code> adds a class name for the buttons. Which helps to modify the looks of the button to some pre-existing designs by mentioning values like</p>
            <p class="fw-details">&nbsp;&nbsp;>> 'text-only'  = Border less, background less button,</p>
            <p class="fw-details">&nbsp;&nbsp;>> 'grey-text' or 'blue-text' = To make the button text as grey or blue. Mention any one value</p>
            <p class="fw-details">&nbsp;&nbsp;>> 'grey-border' or 'blue-border' = To make the button border as grey or blue. Mention any one value</p>
            <p class="fw-details">&nbsp;&nbsp;>> 'bg-transparent' = To make the button background transparent</p>
            <p class="fw-details">* In order to add the id to a button we can make use of the decorator <code>fcBtnId</code>, which gets a value as string and binds it as the ID for a button.</p>
            <p class="fw-details">* For disabling a button its enough if we pass a boolean value to the <code>buttonDisable</code> decorator.</p>
            <p class="fw-details">* To add an icon to the button either to the left or right we can make use of the decorators <code>fcBtnIconRightSrc</code> or <code>fcBtnIconLeftSrc</code> which takes the image path as a value.</p>
            <p class="fw-details">* We can also opt for the icon to use an &lt;svg-icon&gt; component by making the value of <code>isImageSvg</code> to true, by default its set as false and will render normal &lt;img&gt; tag.</p>
            <p class="fw-details">* To make this button a trigger for opening modal we have to pass the modal-id alone to  <code>modalToTrigger</code> which will open the modal dialog</p>
            <p class="fw-details">* Incase the developer wishes to fully customize a button they can use the <code>[buttonType]="'custom-btn'"</code>. Which has multiple overwritting options to modify the css.</p>
            <p class="fw-details">&nbsp;&nbsp;>> 'fcBtnBorder' => custom border color , 'fcBtnBgColor' => custom background color,  etc...</p>
            <div class="fw-label mb-3 mt-3">Buttons wrapped inside Display block</div>
            <div class="d-grid gap-2 d-xl-block"><!-- optional wrapper div, we should directly make use of the app-fusion-button -->
                <app-fusion-button [buttonValue]="'Default'" (buttonClickEmit)="clickedButton($event)" [fcBtnId]="'myId'"></app-fusion-button>
                <app-fusion-button [buttonType]="'text-only'" [buttonValue]="'Text only'" [buttonDisable]="true"></app-fusion-button>
                <app-fusion-button [buttonType]="'grey-border text-only'" [buttonValue]="'Grey border'" [fcBtnIconRightSrc]="'../assets/images/icons/general/hamburger.svg'"></app-fusion-button>
            </div>
            <div class="fw-label mb-3 mt-3">Buttons wrapped inside Display Grid</div>
            <div class="d-grid gap-2"> <!-- optional wrapper div, we should directly make use of the app-fusion-button -->
                <app-fusion-button [buttonType]="'grey-border grey-text bg-transparent'" [buttonValue]="'Grey border2'"></app-fusion-button>
                <app-fusion-button [buttonType]="'custom-btn'" [fcBtnBgColor]="'red'" [fcBtnHoverBgColor]="'white'"  [fcBtnHoverTextColor]="'yellow'" [buttonValue]="'Blue border'"></app-fusion-button>
            </div>
            <details>
                <summary>HTML</summary>
                <pre>
                    <code>
                    &nbsp;&nbsp;&lt;app-fusion-button [buttonValue]="'Default'" (buttonClickEmit)="clickedButton($event)" [fcBtnId]="'myId'"&gt;&lt;/app-fusion-button&gt;
                    &nbsp;&nbsp;&lt;app-fusion-button [buttonType]="'text-only'" [buttonValue]="'Text only'" [buttonDisable]="true"&gt;&lt;/app-fusion-button&gt;
                    &nbsp;&nbsp;&lt;app-fusion-button [buttonType]="'grey-border text-only'" [buttonValue]="'Grey border'" [fcBtnIconRightSrc]="'../assets/images/icons/general/hamburger.svg'"&gt;&lt;/app-fusion-button&gt;
                    &nbsp;&nbsp;&lt;app-fusion-button [buttonType]="'grey-border grey-text bg-transparent'" [buttonValue]="'Grey border2'"&gt;&lt;/app-fusion-button&gt;
                    &nbsp;&nbsp;&lt;app-fusion-button [buttonType]="'custom-btn'" [fcBtnBgColor]="'red'" [fcBtnHoverBgColor]="'white'" [fcBtnTextColor]="'yellow'" [buttonValue]="'Blue border'"&gt;&lt;/app-fusion-button&gt;
                    </code>
                </pre>
            </details>
        </div>
    </section>
    <section class="fc-buttons fc-section">
        <div class="fc-component-title">Cards</div>
        <div class="component-wrapper">
            <p class="fw-details">This is just a UI wrapper over the bootstrap cards with little changes in the css. This component helps us to create content inside a white box with a rounded carner.</p>
            <p class="fw-details">This component uses the same concept of card header, body, footer.</p>
            <p class="fw-details">In order for the component to work we need to declare this </p>
            <p class="fw-details">&nbsp;&nbsp;<code>&lt;app-cards&gt;</code>All HTML content to be in the card goes here along with the modal popup content<code>&lt;/app-cards&gt;</code></p>
            <p class="fw-details">Just make sure the classes "header-section", "body-section", "footer-section", "detailed-section" are providing respectively.</p>
            <p class="fw-details">Because the content inside these classes only will be copied to the Card's respective section.</p>
            <p class="fw-details">This Component also has a feature to trigger a modal dialog. In case a detailed view is need or a form to be modifed. Thats were we use bootstrap's modal.</p>
            <p class="fw-details">For this to work we need make sure "modal" trigger and content are declared , defined properly in HTML</p>
            <div class="row mt-4">
                <div class="col-md-12">
                    <h4>Inline detail View Example:-</h4>
                    <div class="static-width-for-card" style="width: 375px">
                        <app-cards [inlineDetailed]="true">
                            <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                            <div class="header-section flex content-space-between">
                                <span>Personal Info</span>
                                <div class="card-btn show-detail-view"> <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                                    <svg-icon src="../assets/images/icons/general/pencil-icon.svg" class="card-icon"></svg-icon>
                                </div>
                            </div>
                            <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                            <p class="body-section card-text">
                                Click on the pencil icon to show the in-line-detail-view
                            </p>
                            <!-- when a HTML element has a class 'footer-section', it will get rendered in the 'card-foter' div in the component's HTML -->
                            <p class="footer-section card-text">In Case a divider is needed at bottom use footer</p>
        
                            <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                            <div class="card-expanded-section detailed-section" plain> <!-- 'plain' or 'modal' based on the requirement -->
                                <div class="card-header">{{'Detailed View' | customTranslate : 'Detailed View'}}</div>
                                <div class="card-body">
                                    <form>
                                        <div class="field-sets">
                                            <div class="field-set-row flex">
                                                <div class="field-input">
                                                    <input type="text" tabindex="1" id="company-name" formControlName="companyName"
                                                        placeholder="Company Name"  autocomplete="off">
                                                    <label class="form-field-label" for="company-name">{{'Company Name' | customTranslate : 'Company Name'}}</label>
                                                    <p class="error-msg"><span [style]="false ? 'visibility: visible' : 'visibility: hidden' ">{{'Enter valid Org Name' | customTranslate : 'Enter valid Org Name'}}</span></p>
                                                </div>
                                                <div class="field-input has-error" [class]="false ? ' has-error' : ' ' ">
                                                    <input type="text" tabindex="2" id="contact-person" formControlName="contactPerson"
                                                    placeholder="Contact Person name"
                                                        autocomplete="off" >
                                                    <label class="form-field-label" for="contact-person">
                                                        {{'Contact Person' | customTranslate : 'Contact Person'}}
                                                    </label>
                                                    <p class="error-msg"><span [style]="true ? 'visibility: visible' : 'visibility: hidden' ">{{'Enter valid Name' | customTranslate : 'Enter valid Name'}}</span></p>
                                                </div>
                                                <div class="field-input" style="transition: none;">
                                                    <input type="text" tabindex="3" id="business-mail" formControlName="businessMail"
                                                    placeholder="Contact Person name"
                                                        autocomplete="off">
                                                    <label class="form-field-label" for="business-mail" >
                                                        {{'Business Email (uneditable)' | customTranslate : 'Business Email (uneditable)'}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="card-footer border-0">
                                    <div class="center-button text-right">
                                        <app-fusion-button [buttonValue]="('BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel')" [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''" (buttonClickEmit)="clickedButton($event)" [fcBtnId]="'myId'"></app-fusion-button>
                                        <button type="submit" class="input-button" [disabled]="false">{{'BUTTON_VALUE.SAVE' | customTranslate : 'Save'}}</button>
                                    </div>
                                </div>
                            </div>
                        </app-cards>
                    </div>
                    <details>
                        <summary>HTML</summary>
                        <pre>
                            <code>
                                &nbsp;&nbsp;&lt;app-cards [inlineDetailed]="true"&gt;
                                &nbsp;&nbsp;&lt;!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML --&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="header-section flex content-space-between"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span&gt;Personal Info&lt;/span&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-btn show-detail-view"&gt; &lt;!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" --&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;svg-icon src="../assets/images/icons/general/pencil-icon.svg" class="card-icon"&gt;&lt;/svg-icon&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML --&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="body-section card-text"&gt;Click on the pencil icon to show in-line-detail view&lt;/p&gt;
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- when a HTML element has a class 'footer-section', it will get rendered in the 'card-foter' div in the component's HTML --&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="footer-section card-text"&gt;In Case a divider is needed at bottom use footer&lt;/p&gt;
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI--&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-expanded-section detailed-section" plain&gt; &lt;!-- 'plain' or 'modal' based on the requirement --&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-header"&gt;Detailed View&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;form&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="field-sets"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="field-set-row flex"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="field-input"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="text" tabindex="1" id="company-name" formControlName="companyName" placeholder="Company Name"  autocomplete="off"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;label class="form-field-label" for="company-name"&gt;Company Name&lt;/label&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="error-msg"&gt;&lt;span [style]="false ? 'visibility: visible' : 'visibility: hidden' "&gt;Enter valid Org Name&lt;/span&gt;&lt;/p&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="field-input has-error" [class]="false ? ' has-error' : ' ' "&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="text" tabindex="2" id="contact-person" formControlName="contactPerson" placeholder="Contact Person name" autocomplete="off" &gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;label class="form-field-label" for="contact-person"&gt;Contact Person&lt;/label&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="error-msg"&gt;&lt;span [style]="true ? 'visibility: visible' : 'visibility: hidden' "&gt;Enter valid Name&lt;/span&gt;&lt;/p&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="field-input" style="transition: none;"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="text" tabindex="3" id="business-mail" formControlName="businessMail" placeholder="Contact Person name" autocomplete="off"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;label class="form-field-label" for="business-mail" &gt;Business Email (uneditable)&lt;/label&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/form&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-footer border-0"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="center-button text-right"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;app-fusion-button [buttonValue]="'Cancel'" [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''" (buttonClickEmit)="clickedButton($event)" [fcBtnId]="'myId'"&gt;&lt;/app-fusion-button&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button type="submit" class="input-button" [disabled]="false"&gt;Save&lt;/button&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&lt;/app-cards&gt;
                            </code>
                        </pre>
                    </details>
                </div>
                <div class="col-md-12">
                    <h4>Modal detail View Example:-</h4>
                    <div class="static-width-for-card" style="width: 25rem;">
                        <app-cards [modalDetailed]="true">
                            <div class="header-section flex content-space-between">
                                <span>Featured2</span>
                                <div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#cardDetailedModal">
                                    <svg-icon src="../assets/images/icons/general/pencil-icon.svg" class="card-icon"></svg-icon>
                                </div>
                            </div>
                            <p class="body-section card-text">
                                Click on the pencil icon to show the form in modal popup
                            </p>
                            <p class="footer-section card-text">In Case a divider is needed at bottom use footer</p>
                            <!-- when a HTML element has a class 'modal-section', it will get rendered in the modal pop for modal detailed UI-->
                            <div class="card-expanded-section modal-content modal-section" modal>
                                <div class="modal-header">
                                    <div class="modal-title" id="cardDetailedModalLabel">Card 2Modal</div>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <p>Any detail that needs to be shown in a modal can be shown here , div, component, graph , forms, etc....</p> <!-- paste the form here-->
                                </div>
                                <div class="modal-footer">
                                    <app-fusion-button [buttonValue]="'Close'" [buttonType]="'text-only'" [dataDismiss]="'modal'" (buttonClickEmit)="clickedButton($event)" [fcBtnId]="'myId'"></app-fusion-button>
                                    <app-fusion-button [buttonValue]="'Save Changes'" [fcBtnId]="'modalSave'"></app-fusion-button>
                                </div>
                            </div>
                        </app-cards>
                    </div>
                    <details>
                        <summary>HTML</summary>
                        <pre>
                            <code>
                                &nbsp;&nbsp;&lt;app-cards [modalDetailed]="true"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="header-section flex content-space-between"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;span&gt;Featured2&lt;/span&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#cardDetailedModal"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;svg-icon src="../assets/images/icons/general/pencil-icon.svg" class="card-icon"&gt;&lt;/svg-icon&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="body-section card-text"&gt;Click on the pencil icon to show the form in modal popup&lt;/p&gt;
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="footer-section card-text"&gt;In Case a divider is needed at bottom use footer&lt;/p&gt;
                                <br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- when a HTML element has a class 'modal-section', it will get rendered in the modal pop for modal detailed UI--&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-expanded-section modal-content modal-section" modal&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-header"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h5 class="modal-title" id="cardDetailedModalLabel"&gt;Card 2Modal&lt;/h5&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"&gt;&lt;/button&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-body"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;Any detail that needs to be shown in a modal can be shown here , div, component, graph , forms, etc....&lt;/p&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;!-- paste the form here--&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="modal-footer"&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;app-fusion-button [buttonValue]="'Close'" [buttonType]="'text-only'" [dataDismiss]="'modal'" (buttonClickEmit)="clickedButton($event)" [fcBtnId]="'myId'"&gt;&lt;/app-fusion-button&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;app-fusion-button [buttonValue]="'Save Changes'" [fcBtnId]="'modalSave'"&gt;&lt;/app-fusion-button&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;
                                &nbsp;&nbsp;&lt;/app-cards&gt;
                            </code>
                        </pre>
                    </details>
                </div>
            </div>
        </div>
    </section>
    <section class="fc-tabs fc-section">
        <div class="fc-component-title">Custon Dropdown</div>
        <div class="component-wrapper">
            <p class="fw-details">Dropdown is customized manually </p>
            <p class="fw-details">The properties can be modified based on parameters passed</p>
            <p class="fw-details">* <code>dropDownWidth</code> used to adjust the width of dropdown box</p>
            <p class="fw-details">* <code>dropDownHeight</code> used to adjust the height of dropdown box</p>
            <p class="fw-details">* <code>dropFontSize</code> used to change the size of font in dropdown box</p>
            <p class="fw-details">* <code>dropFontColor</code> used to change the font color in dropdown box</p>
            <p class="fw-details">* <code>dropFontWeight</code> used to change the weight of font in dropdown box</p><br>
            <p class="fw-details">* <code>[list]</code> - the data to be shown in dropdown list is sent through array in this attribute</p><br>
            <p class="fw-details">The <code>errorValue</code> or <code>defaultValue</code> any one can be used</p>
            <p class="fw-details">* <code>errorValue</code> - If error to be shown wehen selecting the particular value, that value can be passed through this variable</p>
            <p class="fw-details">* <code>defaultValue</code> - If we don't want to select any value. Then, that value will be passed to this variable</p>
            <p class="fw-details">* <code>selectedValue</code> - This value is used if we want to set some default value from dropdown</p>
            <p class="fw-details">* <code>(dataEmit)</code> emits the selected value in a '$event', that can fetched using any functions</p>
            <p class="fw-details">* <code>errorMessage</code> - message to be displayed while showing error</p>
            <div class="fw-label">Example with error message:-</div>
            <app-drop-down [lists]='list' dropDownWidth="0px 0px" dropDownHeight="50px" dropFontWeight="500" dropFontSize="12px" dropFontColor="#434555"  (dataEmit)="$event" errorValue="Select" errorMessage="Please select the type"></app-drop-down>
            <details>
                <summary>HTML</summary>
                <pre>
                    <code>
                        &lt;app-drop-down [lists]='list' dropDownWidth="0px 0px" dropDownHeight="50px" dropFontWeight="500" dropFontSize="12px" dropFontColor="#434555"  (dataEmit)="$event" errorValue="Select" errorMessage="Please select the type"&gt; &lt; app-drop-down &gt;;
                    </code>
                </pre>
            </details>
            
            <div class="fw-label">Example without error message:-</div>
            <app-drop-down [lists]='list' dropDownWidth="0% 90% 0% 0%" dropDownHeight="30px" dropFontWeight="500" dropFontSize="10px" dropFontColor="#434555"  (dataEmit)="$event" defaultValue="Select" errorMessage="Please select the type"></app-drop-down>
            <details>
                <summary>HTML</summary>
                <pre>
                    <code>
                        &lt;app-drop-down [lists]='list' dropDownWidth="0% 90% 0% 0%" dropDownHeight="30px" dropFontWeight="500" dropFontSize="10px" dropFontColor="#434555"  (dataEmit)="$event" defaultValue="Select" errorMessage="Please select the type"&gt; &lt; app-drop-down &gt;;
                    </code>
                </pre>
            </details>
        </div>
    </section>
</div>
