import { Component, ElementRef, Input, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { AlertService } from 'src/app/services/alert.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { Constants } from 'src/app/models/Constants';
import { HttpParams } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-it-ess',
  standalone: true,
  imports: [CommonModule, FusionButtonComponent, DropDownComponent, AngularSvgIconModule, DatePipe, DropDownComponent, MatTooltipModule,TranslateModule,CustomTranslatePipe],
  templateUrl: './it-ess.component.html',
  styleUrls: ['./it-ess.component.css']
})

export class ItEssComponent implements OnInit {

  @Input() getData:any;
  @Input() financialYear:any;
  @ViewChildren("arrow") arrow!: QueryList<ElementRef>;
  @ViewChildren("declaredArrow") declaredArrow!: QueryList<ElementRef>;
  @ViewChildren("openAccordion") openAccordion!: QueryList<ElementRef>;
  @ViewChildren("declaredAccordion") declaredAccordion!: QueryList<ElementRef>;

  sectionListData:Array<any> = [];
  sectionTypes:Array<any> = [];
  tdsListArray:Array<any> = [];
  sectionFilterIndex = 0;
  defaultDDValue = 'Select';
  declaredSelctionList!: any;
  filteredTab:any;
  dropDownList: Array<any> = [];
  declarationList: Array<any> = [];
  secMaxLimit:any;
  errorMessage = '';
  
  tableData: Array<any> = [];
  salaryComp! : any;
  hra = Constants.HRA;
  basicSalary:any;
  hraByEmployer:any;
  componentsArray: Array<any> = [];
  deductionComponent : Array<any> = [];
  initialList: Array<any> = [];
  duplicateName:any;
  declaredAmount:any;
  oldTaxNet: any;
  newTaxNet: any;
  taxLiabilityNew : any;
  taxLiabilityOld : any;
  taxRebateOld:any;
  taxRebateNew:any;
  finalNewNetTaxLiable:any;
  finalOldNetTaxLiable:any;
  surChargeOld:any;
  surChargeNew:any;
  educationCessOld:any;
  educationCessNew:any;
  declaredTax = false;
  isDuplicateName = false;
  exceedLimitWarn = false;
  saveButtonDisable = false;
  compareTax = false;
  newTaxRegime = true;
  taxAfterRebateOld : any;
  taxAfterRebateNew : any;
  selectFY = 'Select Financial Year';
  editScreen = 'Edit Screen';
  declaredScreen = 'Declared Screen';
  switchScreens = this.selectFY;
  exemptionList: Array<any> = [];
  allFYDeclarations : Array<any> = [];
  hraDateValidation =  false;
  panValidation =  false;
  hraAdded = false;
  tdsPaid: any;
  taxDueOld:any;
  taxDueNew:any;
 

  constructor(private apiService: ApiService, private globalValues: GlobalValuesService, private alertService: AlertService, private errorHandler: ErrorHandlerService,private translate : TranslateService) { 
   
  }

  ngOnInit(): void {
  }  
  

  ngOnChanges(changes: SimpleChanges){
    this.compareTax = false;
    // executed whenever the financial year dropdoown list changes
    if (changes['financialYear']) {
      if(this.financialYear === this.selectFY ||  this.financialYear === undefined ){
        this.switchScreens = this.selectFY;
      } else {
        this.getUserData();
      }
    }
  }

  getUserData(){
    const params = new HttpParams({
      fromObject:{
        employeeid : this.globalValues.empId,
        companyId : this.globalValues.orgId
      }
    });
  
    // SK01APR24 hitting the employee url instead hrm for ess
    this.apiService.fetchValue('/employee').subscribe((data:any) => {
      this.salaryComp = data;
      this.tdsPaid = this.numberRoundDecimal(this.salaryComp?.financeInfo[0]?.cumulativeTDS[0]?.[this.financialYear] || 0); // total tds paid in selected FY
      this.compareTax = false;
      this.allFYDeclarations = [];
      this.allFYDeclarations = this.salaryComp?.financeInfo[0]?.itDeclarations; // the declarations list for all the Financial years
      if(this.allFYDeclarations[0]?.[this.financialYear]){
        // if the data present in selected financial year, that is assigned to a variable
        this.declaredSelctionList = this.allFYDeclarations[0]?.[this.financialYear];
        this.newTaxRegime = this.declaredSelctionList?.taxRegime === 'New Regime' ? true : false;
        this.switchScreens = this.declaredScreen;
        this.declaredSelctionList.taxCalculatedList.forEach((element:any) => {
          // fetching the tax details and assigning the values that is declared/drafted in selected FY
          if(element.type === 'Tax Details'){
            this.newTaxRegime ? this.finalNewNetTaxLiable = element.total.value : this.finalOldNetTaxLiable = element.total.value;
            element.splitUps.forEach((splits:any) => {
              if(splits.section === 'Net Taxable Income'){
                this.newTaxRegime ? this.newTaxNet = splits.amount : this.oldTaxNet = splits.amount;
              } else if (splits.section === 'Tax Liability') {
                this.newTaxRegime ? this.taxLiabilityNew = splits.amount : this.taxLiabilityOld = splits.amount;
              }
            });
          } else if(element.type === 'Exemption') {
            this.exemptionList = element.splitUps;
          }
        });
      } else {
        this.declaredSelctionList = undefined;
        this.switchScreens = this.editScreen;
      }
      if(this.declaredSelctionList !== undefined){
        // if epf or vpf is present in declaredSelctionList list, that is omitted to initialList array
        const epf = (Constants.EPF + '(Pre-Tax Deduction)');
        const vpf = (Constants.VPF + '(Pre-Tax Deduction)');
        this.initialList = this.declaredSelctionList.declaredList.filter(
          (filterEdit:any) => 
            (filterEdit.name != epf && filterEdit.name != vpf)
          )
      } else {
        this.initialList = [];
      }
      this.tdsDue(); // calculating due tax to be paid in current FY
      this.apiService.fetchValue('/user').subscribe((datas:any) => {
        this.sectionListData = datas?.settings[0]?.taxDeclarations || []; // the hrm-tds component list fetched
        this.tdsListArray = this.sectionListData;
        this.addCompList()
        this.tdsListArray.forEach(element => {
          this.sectionTypes = [...new Set(Object.keys(element))];
          this.sectionTypes = this.sectionTypes.filter((filterSection:any) => filterSection !== Constants.SD);
        });
        this.filterTableData(this.sectionTypes[this.sectionFilterIndex === 0 ? 0 : this.sectionFilterIndex], 'tax');
      });
    });
  }

  // filtering the datas based on the tab selected
  filterTableData(typeTofilter: any, component:any){
    this.filteredTab = typeTofilter;
    this.defaultDDValue = 'Select';    
    this.secMaxLimit = this.sectionListData[0]?.[typeTofilter].maxSectionLimit;
    this.tdsListArray = this.sectionListData[0]?.[typeTofilter]?.['investment'] || [];
    this.declarationList = [...this.componentsArray, ...this.initialList];

    this.dropDownList = [];
    this.tdsListArray.forEach((data:any) => {
      if(data.active){
        this.dropDownList.push(data.name);
      }
    });
    this.excessAmountValidation();
    this.saveButtonValidation();
  }

  // to get the taxable component from salary component list and assigning to the sections
  addCompList(){
    this.componentsArray = [];
    this.deductionComponent = [];
    this.salaryComp?.financeInfo[0].compensation.forEach((element:any) => {
      if(element.isActive){
        element.components.forEach((comp:any) => {
          if(comp.taxable){
            if(comp.type === Constants.DEDUCTIONS) {
              //if component is EPF or VPF, it will be pushed to 80C and the type is Exemption
              if(comp.componentName === Constants.EPF || comp.componentName === Constants.VPF){
                const type = this.sectionListData[0]?.['80C'].maxSectionLimit;
                this.componentsArray.push({'section' : '80C', 'name': comp.componentName + '(Pre-Tax Deduction)',   'amount': comp.amountYearly, 'maxSectionLimit': type, editable: false, duplicateName: '', type:'Exemption', isExempted: comp.isExempted});
              }
              // if it is Professional Tax, the type is assigned as Deduction 
              else if (comp.componentName === Constants.PT) {
                this.deductionComponent.push({'section' : comp.componentName, 'name': comp.componentName,   'amount': comp.amountYearly, 'maxSectionLimit': '', editable: false, duplicateName: '', type:'Deduction', isExempted: comp.isExempted})
              } 
              // else it is added to other investments as Exemption type
              else {
                const type = this.sectionListData[0]?.[Constants.OTHER_INVESTMENTS]?.maxSectionLimit;
                this.componentsArray.push({'section' : Constants.OTHER_INVESTMENTS, 'name': comp.componentName + '(Pre-Tax Deduction)',   'amount': comp.amountYearly, 'maxSectionLimit': type, editable: false, duplicateName: '', type:'Exemption', isExempted: comp.isExempted});
              }
            } else {
              if (comp.componentName === Constants.HRA){
                this.hraByEmployer = comp.amountYearly;
              } else if(comp.componentName === Constants.BASIC){
                this.basicSalary = comp.amountYearly;
              }
            }
          }
        });
      }
    });
  }

  // executing the logics while selecting the investment dropdown
  dropdownEmitFunction(event:any, data:any, index:number, from:any){
    data.duplicateName = '';
    this.isDuplicateName = false;
    this.declarationList.forEach((element:any) => {
      if(this.filteredTab === element.section) { 
        if(element.name === event.data){
          data.duplicateName = 'This declaration type is already in the list';
          this.isDuplicateName = true;
        }
      }
    }); 
    this.declarationList[index][from] = event.data;
    this.sectionListData[0][this.filteredTab]['investment'].forEach((element:any, i:number) => {
      if(element.name === event.data){
        Object.assign(this.declarationList[index], {
          'maxAmount': element.maxAmount, 
          'minAmount' : element.minAmount,
          'minPercent' : element.minPercent,
          'maxPercent' : element.maxPercent,
          'isExempted' : element.isExempted,
          'condition' : element.condition || ''
        })
      }
    });
    this.saveButtonValidation();
  }

  // the function executed, whenver the datas ebtered on input fields, like,  amount declared and datas in hra fields
  textInput(event:any, data:any, i:number, key:any){
    this.declarationList[i][key] = event.target.value;
    this.panValidation = false;
    if(data.section === Constants.HRA){
      if(data.amount > 100000 && data.landLordPan === ''){
        this.panValidation = true;
      }
    }
    data.amount;
    this.excessAmountValidation();
    this.saveButtonValidation();
  }

  // the 'from' and 'to' dates in hra values are stored in list
  hraData(event:any, key:any, index:number, data:any){
    this.declarationList[index][key] = event.target.value;
    data.hraDateValidation = false;
    this.hraDateValidation = false;
    if(new Date(this.declarationList[index]['rentTo']) < new Date(this.declarationList[index]['rentFrom'])){
      data.hraDateValidation =  true;
      this.hraDateValidation = true;
    }
    this.saveButtonValidation();
  }

  // validating, whether the declared amount exceeds the section limit for 80C and 80D sections
  excessAmountValidation(){
    this.exceedLimitWarn = false;
    this.declaredAmount = 0;
    this.declarationList.forEach(element => {
      if(element.section === this.filteredTab){
        this.declaredAmount = Number(element.amount || 0) + this.declaredAmount;
      }
    });
    if(this.filteredTab != Constants.OTHER_INVESTMENTS){
      if(this.secMaxLimit < this.declaredAmount){
        this.exceedLimitWarn = true;
      }
    }
  }

  // save button will be enable only when all the required field sare filled, 
    //if the investment is added, the investment name and amount should be entered
    // if it is hra, all the fields are required
  saveButtonValidation(){
    this.saveButtonDisable = false;
    if(this.declarationList.length === 0){
      this.saveButtonDisable = true;
    }
    this.declarationList.forEach(element => {
      if(element.section === this.hra) {
        if(element.amount === '' || element.rentFrom === '' || element.rentTo === '' || element.address === '' || element.landLordName === '' || element.livingArea === 'Select'){
          this.saveButtonDisable = true;
        }
      } else if (element.section != this.hra){
        if(element.name === 'Select' || element.amount === ''){
          this.saveButtonDisable = true;
        }
      }
    });
  }

  // validating, if the investement is already selected in that section
  duplicateNameValidation(){
    this.isDuplicateName = false;
    this.declarationList.forEach((element:any) => {
      if(element.duplicateName != ''){
        this.isDuplicateName = true;
      }
    });
  }

  // adding the investments based on their section types
  addInvestments(){
    if((this.filteredTab)?.toLocaleLowerCase() === (this.hra).toLocaleLowerCase()){
      const hraFields = {
        'section' : this.filteredTab, 
        'amount': '',
        'rentFrom' : '',
        'rentTo' : '',
        'address' : '',
        'landLordName' : '',
        'landLordPan' : '',
        'livingArea' : 'Select', 
        'maxSectionLimit': this.secMaxLimit,
        'editable': true
      }
      this.initialList.push(hraFields);
    } else {
      const otherFields = {
        'section' : this.filteredTab, 
        'name':'Select',   
        'amount': '', 
        'maxSectionLimit': this.secMaxLimit, 
        'editable': true, 
        'maxAmount': ''
      }
      this.initialList.push(otherFields);
    }
    this.declarationList = [...this.componentsArray, ...this.initialList]; 
    this.saveButtonValidation();
    this.hraAddedFunction();
  }
  
  // cancel button function in edit declaration screen, saves the investments as draft
  cancelITButton(){
    // this.declarationList = this.declarationList.filter((data:any) => data.section !== this.filteredTab);
    // this.initialList = this.initialList.filter((data:any) => data.section !== this.filteredTab);

    // this.declaredSelctionList = {};
    // this.excessAmountValidation();
    // this.duplicateNameValidation()
    // this.saveButtonValidation();
    // this.filterTableData(this.filteredTab, 'any');
    this.saveDeclaration('cancel');
    this.submitButton('DRAFT', 'cancel');
  }

  // the added tax declaration can be removed, this will execute all the necessary validations also
  removeTaxField(data:any,index:any){
    this.declarationList.splice(index, 1);
    this.initialList = this.initialList.filter((eachData:any) => eachData !== data);
    this.excessAmountValidation();
    this.saveButtonValidation();
    this.duplicateNameValidation();
    this.hraAddedFunction();
  }

  // camcualtion amount based on tax slabs
  taxSlabCalculations(){

    // calculation for old regime
    let slabAmountOld = 0;
    if(this.oldTaxNet > 250000 && this.oldTaxNet <= 500000){
      slabAmountOld = (this.oldTaxNet - 250000) * 0.05;
    } else if(this.oldTaxNet > 500000 && this.oldTaxNet <= 1000000){
      const amount = (this.oldTaxNet - 500000) * (0.20);
      slabAmountOld = 12500 + amount;
    } else if(this.oldTaxNet > 1000000){
      const amount = (this.oldTaxNet - 1000000) * (0.30);
      slabAmountOld = 12500 + 100000 +  amount;
    }
    this.taxLiabilityOld = this.numberRoundDecimal(slabAmountOld);

    // calculation for new regime
    let slabAmountNew = 0;
    if(this.newTaxNet > 300000 && this.newTaxNet <= 600000){
      slabAmountNew = (this.newTaxNet - 300000) * 0.05;
    } else if(this.newTaxNet > 600000 && this.newTaxNet <= 900000){
      const amount = (this.newTaxNet - 600000) * (0.10);
      slabAmountNew = 15000 + amount;
    } else if(this.newTaxNet > 900000 && this.newTaxNet <= 1200000){
      const amount = (this.newTaxNet - 900000) * (0.15);
      slabAmountNew = 15000 + 30000 + amount;
    }else if(this.newTaxNet > 1200000 && this.newTaxNet <= 1500000){
      const amount = (this.newTaxNet - 1200000) * (0.20);
      slabAmountNew = 15000 + 30000 + 45000 + amount;
    }else if(this.newTaxNet > 1500000){
      const amount = (this.newTaxNet - 1500000) * (0.30);
      slabAmountNew = 15000 + 30000 + 45000 + 60000 +  amount;
    }    
    this.taxLiabilityNew = this.numberRoundDecimal(slabAmountNew);

    return this.newTaxRegime ? this.taxLiabilityNew : this.taxLiabilityOld;
  }

  // calculating the rebate amount form tax payable
  calculateRebate(){

    // maximun rebate amount for new tax regime is 25000
    let newTaxRebate = 0
    if(this.newTaxNet <= 700000){
      newTaxRebate = this.taxLiabilityNew <= 25000 ? this.taxLiabilityNew : 25000;
    } else {
      newTaxRebate = 0;
    }
    this.taxRebateNew = this.numberRoundDecimal(newTaxRebate);

    // maximun rebate amount for old tax regime is 12500
    let oldTaxRebate = 0;
    if(this.oldTaxNet <= 500000){
      oldTaxRebate = this.taxLiabilityOld <= 12500 ? this.taxLiabilityOld : 12500;
    } else {
      oldTaxRebate = 0;
    }
    this.taxRebateOld = this.numberRoundDecimal(oldTaxRebate);

    //calculation education cess after deducting rebate
    this.educationCessNew =  this.numberRoundDecimal((this.taxLiabilityNew - this.taxRebateNew ) * 0.04);
    this.educationCessOld = this.numberRoundDecimal((this.taxLiabilityOld - this.taxRebateOld ) * 0.04);

    return this.newTaxRegime ? this.taxRebateNew : this.taxRebateOld;
  }

  // deducting the rebate amount from net tax liable
  finalAfterRebate(){
    this.taxAfterRebateNew = this.numberRoundDecimal(this.taxLiabilityNew -  this.taxRebateNew);
    this.taxAfterRebateOld = this.numberRoundDecimal(this.taxLiabilityOld -  this.taxRebateOld);
    return this.newTaxRegime ? this.taxAfterRebateNew : this.taxAfterRebateOld; 
  }

  // calculating surchare amount
  surChargeCalculation(){
    let oldRegimeCharge = 0;
    let newRegimeCharge = 0;
    //  calculating based on net tax amount
    if(this.oldTaxNet > 5000000 && this.oldTaxNet <= 10000000 ) {
      oldRegimeCharge = this.oldTaxNet * 0.10;
    } else if (this.oldTaxNet > 10000000 && this.oldTaxNet <= 20000000 ) {
      oldRegimeCharge = this.oldTaxNet * 0.15;
    } else if (this.oldTaxNet > 20000000 && this.oldTaxNet <= 50000000 ) {
      oldRegimeCharge = this.oldTaxNet * 0.35;
    } else if (this.oldTaxNet > 50000000) {
      oldRegimeCharge = this.oldTaxNet * 0.37;
    }
    this.surChargeOld = this.numberRoundDecimal(oldRegimeCharge);

    if(this.newTaxNet > 5000000 && this.newTaxNet <= 10000000 ) {
      newRegimeCharge = this.newTaxNet * 0.10;
    } else if (this.newTaxNet > 10000000 && this.newTaxNet <= 20000000 ) {
      newRegimeCharge = this.newTaxNet * 0.15;
    } else if (this.newTaxNet > 20000000 && this.newTaxNet <= 50000000 ) {
      newRegimeCharge = this.newTaxNet * 0.25;
    } else if (this.newTaxNet > 50000000) {
      newRegimeCharge = this.newTaxNet * 0.25;
    }
    this.surChargeNew = this.numberRoundDecimal(newRegimeCharge);

    return this.newTaxRegime ? this.surChargeNew : this.surChargeOld;
  }

  // the added declaration list will be processed to calculate the taxable income, i.e, the exemptions are omitted in new tax regime
  newRegimecomponents(array:any){
    let newRegimeFromSections : any = [];
    let newRegimeFromComponents : any = [];
    newRegimeFromSections = array.filter((noExempt:any) => noExempt.isExempted);
    newRegimeFromComponents = array.filter((noExempt2:any) => noExempt2.components);
    newRegimeFromComponents.forEach((newRegimeElement:any, i: number) => {
      const a = [...newRegimeElement.components];
      newRegimeElement.components = a.filter((filterNew:any) => filterNew.isExempted )
    });
    return [...newRegimeFromSections, ...newRegimeFromComponents];
  }

  // calculating the net taxable by deducting exemptions and deductions from grosss taxable income
  calculateNetTax(array:any){
    let addAmount = 0;
    let deductAmount = 0;
    array.forEach((netTaxOld:any) => {
      if(netTaxOld.type === 'Earning'){
        addAmount = netTaxOld.total.value;
      } else if (netTaxOld.type === 'Exemption' || netTaxOld.type === 'Deduction'){
        deductAmount = deductAmount + netTaxOld.total.value
      }
    });
    return (addAmount - deductAmount)
  }

  // The declared investments list by the employee will be processed here
  declarationListcomponents(){
    const data = new Set(this.declarationList.map((data:any) => data.section));
    const result :any = [];
    data.forEach(elementMap => {
      result.push({
        section: elementMap,
        components: this.declarationList.filter((i:any) => (i.section === elementMap))
      });
    });

    result.forEach((elementTotalAmount:any) => {
      let amount = 0;
      elementTotalAmount.components.forEach((elementAmount:any) => {
        if(elementAmount.amount){
          amount = Number(elementAmount.amount) + amount;
        }
      });
      if(elementTotalAmount.section === Constants.INCOME_FROM_OTHERSOUCES){
        Object.assign(elementTotalAmount, {amount: amount, type: 'Earning'});
      } else {
        Object.assign(elementTotalAmount, {amount: amount, type: 'Exemption'});
      }
    });
    return result;
  }

  // here mapping the declaration list data based in the sexction types
  calculateTaxAmounts(filterarray:any){
    var groups = new Set(filterarray.map((item:any) => item.type))
    const array: any = [];
    groups.forEach((type) => {
      array.push({
        type: type, 
        splitUps: filterarray.filter((i:any) => (i.type === type)),
      });
    });

    // the total amount for each mapped section will be calcuated and assigned as object in that mapped list
    array.forEach((arrElement:any, i:number) => {
      let compElementTotal = 0
      arrElement.splitUps.forEach((compElement:any, j:number) => {
        if(compElement.components){
          let total = 0;
          compElement.components.forEach((totalComp:any) => {
            if(totalComp.section != Constants.HRA){
              if(totalComp.section != '80C') {
                if(totalComp.amount < totalComp.maxAmount){
                  total = Number(totalComp.amount) + total;
                } else {
                  total = Number(totalComp.maxAmount) + total;
                }
              } else {
                total = Number(totalComp.amount) + total;
              }
            } else {
              let livingAreaAmount = 0;
              if(totalComp.livingArea === 'Metro'){
                livingAreaAmount = ((Number(this.basicSalary) * 0.50));
              } else {
                livingAreaAmount = ((Number(this.basicSalary) * 0.40));
              }
              let rentPaid = (Number(totalComp.amount) - (Number(this.basicSalary) * 0.10));

              if (this.hraByEmployer <= livingAreaAmount && this.hraByEmployer <= rentPaid) {
                total = this.hraByEmployer + total;
              } else if (livingAreaAmount <= this.hraByEmployer && livingAreaAmount <= rentPaid) {
                total = livingAreaAmount + total;
              } else {
                total = rentPaid + total;
              }   
              totalComp.calculatedAmount =  total;                  
            }
          });
          compElement.amount = total;
        }
        if(compElement.section === '80C'){
          if(compElement.amount < Number(this.sectionListData[0]['80C'].maxSectionLimit)){
            compElementTotal = compElement.amount + compElementTotal;
          } else {
            compElementTotal = Number(this.sectionListData[0]['80C'].maxSectionLimit) + compElementTotal;
            compElement.amount = compElementTotal;
          }
        } else {
          compElementTotal = compElement.amount + compElementTotal;
        }
        Object.assign(array[i],{'total':{key: 'Total ' + arrElement.type, value:compElementTotal}})
      });
    });
    return array;
  }

  // accordion function in compare tax screen
  accordionContent(i:number, j:number){
    let index = 0;
    for(let k=0; k < i; k++){
      this.tableData[k].splitUps.forEach((element:any) => {
        index = index + 1;
      })
    }    
    this.arrow.toArray()[index + j].nativeElement.classList.toggle("active1");
    // finding the element that has class "accordion-content"
    const panel = this.openAccordion.toArray()[index + j].nativeElement.nextElementSibling.className.includes("accordion-content")
      ? this.openAccordion.toArray()[index + j].nativeElement.nextElementSibling
      : '';
    
    // toggling the content based on the active class
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }

  }

  // accordion function in declared/draft screen
  toogleSavedList(i:number){
    this.declaredArrow.toArray()[i].nativeElement.classList.toggle("active1");
    // finding the element that has class "accordion-content"
    const panel = this.declaredAccordion.toArray()[i].nativeElement.nextElementSibling.className.includes("accordion-content")
      ? this.declaredAccordion.toArray()[i].nativeElement.nextElementSibling
      : '';
    
    // toggling the content based on the active class
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  editDeclaration(event:any){
    this.switchScreens = this.editScreen;
    this.hraAddedFunction();
  }

  saveDeclaration(buttonValue:any){
    if(buttonValue === 'saveAndCompare'){
      this.compareTax = true;
    }

    // the standard dedution of 50000 is added ans default exemption
    let standardDecuction: any[] = [];
    let sdAmount = 0;
    this.sectionListData[0][Constants.SD]?.investment.forEach((sd:any) => {
      if(sd.maxAmount){
        sdAmount = Number(sd.maxAmount) + sdAmount;
      }
      standardDecuction.push(
        {amount: sdAmount, section:sd.name, type: 'Deduction', isExempted: sd.isExempted}
      );
    });

    // calculation of gross taxable income by adding 'Earnings', 'Allowances', and 'Deductions that is not an employer contribution'  
    let totalTaxamount = 0;
    let totalTaxEarnings = [];
    this.salaryComp.financeInfo[0].compensation.forEach((activecomp:any) => {
      if(activecomp.isActive){
        activecomp.components.forEach((comp:any) => {
          if(comp.type === Constants.EARNINGS || comp.type === Constants.ALLOWANCES || (comp.type === Constants.DEDUCTIONS && !comp.isEmployerContribution)){
            totalTaxamount = comp.amountYearly + totalTaxamount;
          }
        });
      }
    });
    totalTaxEarnings = [{amount: totalTaxamount, section:'Gross Taxable Income' , type: 'Earning', isExempted: true}]
    
    const oldRegimeTable = [...totalTaxEarnings, ...standardDecuction,...this.deductionComponent, ...this.declarationListcomponents()];
    const newRegimeTable = [...totalTaxEarnings, ...standardDecuction,...this.deductionComponent, ...this.declarationListcomponents()];

    const newRegimeFilter = this.newRegimecomponents(newRegimeTable);
    const oldRegime = this.calculateTaxAmounts(oldRegimeTable);
    const newRegime = this.calculateTaxAmounts(newRegimeFilter);
    
    let addAmountOld = 0;
    let deductAmountOld = 0;
    newRegime.forEach((netTaxOld:any) => {
      if(netTaxOld.type === 'Earning'){
        addAmountOld = netTaxOld.total.value;
      } else if (netTaxOld.type === 'Exemption' || netTaxOld.type === 'Deduction'){
        deductAmountOld = deductAmountOld + netTaxOld.total.value
      }
    });

    this.oldTaxNet = this.calculateNetTax(oldRegime);
    this.newTaxNet = this.calculateNetTax(newRegime);

    const taxDetail = [{
      "type": "Tax Details",
      "splitUps": [
          { "section": "Net Taxable Income", "amount": this.newTaxRegime ? this.newTaxNet : this.oldTaxNet },
          { "section" : "Tax Liability", "amount": this.taxSlabCalculations()},
          { "section" : "Less :  Rebate Under Section 87A", "amount": this.calculateRebate()},
          { "section" : "Surcharge on Tax", "amount": this.surChargeCalculation()},
          { "section" : "Educational Cess @4% of " + this.finalAfterRebate(), "amount": this.numberRoundDecimal(this.finalAfterRebate() * (0.04))}
      ],
      "total": {
          "key": "Total Tax Payable",
          "value": 0
      }
    }];

    let finalNet = 0;
    taxDetail[0].splitUps.forEach((finalNetTax:any) => {
      if(finalNetTax.section !== 'Net Taxable Income'){
        if(finalNetTax.section !== 'Less :  Rebate Under Section 87A' ) {
          finalNet = finalNet + finalNetTax.amount;
        } else {
          finalNet = finalNet - finalNetTax.amount;
        }
      }
      
    });

    Object.assign(taxDetail[0], {
      "total": {
        "key": "Total Tax Payable",
        "value": finalNet
      }
    });


    this.finalNewNetTaxLiable = this.taxLiabilityNew + this.surChargeNew + this.educationCessNew - this.taxRebateNew;
    this.finalOldNetTaxLiable = this.taxLiabilityOld + this.surChargeOld + this.educationCessOld - this.taxRebateOld;
    this.newTaxRegime ? this.tableData = [...newRegime,...taxDetail] : this.tableData = [...oldRegime,...taxDetail];

    this.tdsDue();
    if(buttonValue === 'cancel') {
      this.submitButton('DRAFT', buttonValue);
      this.compareTax = false;
      this.switchScreens = this.editScreen;
    } 
    else if (buttonValue === 'saveAndCompare'){
      this.submitButton('DRAFT', buttonValue);
    }
  }

  // validation for hra single declaration
  hraAddedFunction(){
    this.hraAdded = false;
    this.declarationList.forEach(element => {
      if(element.section === Constants.HRA){
        this.hraAdded = true;
      }
    });
  }

  // final list that to be sent to database
  submitButton(declarationType:any, buttonValue:any){
    if(this.allFYDeclarations.length === 0){
      this.allFYDeclarations.push({
        [this.financialYear]:{
          declaredList : this.declarationList,
          taxCalculatedList : this.tableData,
          taxRegime : this.newTaxRegime ? 'New Regime' : 'Old Regime',
          status: declarationType,
          taxtoBePaid: this.newTaxRegime ? this.taxDueNew : this.taxDueOld
        }
      })
    } else {
      Object.assign(this.allFYDeclarations[0], {
        [this.financialYear]:{
          declaredList : this.declarationList,
          taxCalculatedList : this.tableData,
          taxRegime : this.newTaxRegime ? 'New Regime' : 'Old Regime',
          status: declarationType
        }
      })
    }
    
    this.apiService.writeValue('patch', '/employee/itDeclaration', {'itDeclaration':this.allFYDeclarations, 'employeeId': this.globalValues.empId, 'companyId': this.globalValues.orgId})
    .subscribe({
      next: (success:any) =>{
        if(buttonValue === 'submit'){
          this.successMessage('IT declaration was submitted successfully'); 
        }

        if(buttonValue !== 'saveAndCompare'){
          this.getUserData();
        } 
      },
      error: (err:any) => {
        this.errorMesssage(err);
      }
    })
  }

  // calculating the due to be paid in FY
  tdsDue(){
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    let finYear = '';
    let selectedFYMonth : any;
    let fyMonth : any;
    if(month >= 4 && month <= 12){
      finYear = year + '-' + (Number(year) + 1 );
      selectedFYMonth = new Date(year + '-' + month + '-01');
      fyMonth = new Date((Number(year) + 1 ) + '-04-01');
    } else {
      finYear = (Number(year) - 1 ) + '-' + year;
      selectedFYMonth = new Date(year + '-' + month + '-01');
      fyMonth = new Date(year + '-04-01');
    }
    this.taxDueOld = this.numberRoundDecimal(this.finalOldNetTaxLiable - this.tdsPaid);
    this.taxDueNew = this.numberRoundDecimal(this.finalNewNetTaxLiable - this.tdsPaid);
  }

  successMessage(data:any){
    this.alertService.messageOnPass('success', data);
  }

  errorMesssage(error:any){
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  // this function is used to round the decimal digits, that exceeds two decimal points
  numberRoundDecimal(number:any) {
    return Math.round((number+Number.EPSILON)*Math.pow(10,2))/Math.pow(10,2);
  }
}
