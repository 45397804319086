<div *ngIf="successMessage" class="alert alert-dismissible fade show form-submission-message success" role="alert">
    <div class="info">
        <img  class="ps-2" [src] ="'../assets/images/icons/Vectorsuccesstick.svg'" style="width:20px; height:20px; ">
        <span class="white-text p-4">{{alertMessage}}</span>
        <svg-icon class="white-text svg-icon-white pe-2" src="../assets/images/icons/general/cross-icon.svg" (click)="successMessage = false" style="cursor: pointer;
        align-self: baseline;"></svg-icon>
    </div>
</div>

<div *ngIf="errorMessage" class="alert alert-dismissible fade show form-submission-message failure" role="alert">
    <div class="info">
        <img class="ps-2" [src] ="'../assets/images/icons/Vectorwarn.svg'" style="width:20px; height:20px; ">
        <!-- RS27AUG24 -->
        <!-- Used the translate pipe to successfully implement the translation -->
        <span class="white-text p-4">{{alertMessage ? alertMessage+'.' : ''}} {{'TRY_AGAIN' | customTranslate : 'Please try again.' }}</span>
        <svg-icon class="white-text svg-icon-white pe-2 " src="../assets/images/icons/general/cross-icon.svg" (click)="errorMessage = false" style="cursor: pointer;
        align-self: baseline;"></svg-icon>
    </div>
</div>

<!-- SK24JUN24 direction for rtl/ltr, need to implement further -->

<!-- RS27AUG24  -->
 <!-- USED SCREEN_DIRECTION(KEY) FOR SWITCHING DIRECTION OF rtl/ltr -->
<div class="flex flex-direction-row height-100" [dir]= "'SCREEN_DIRECTION' | translate ">
    <div *ngIf="showSideNav" class="left-nav" [class]="showSideNavInMobile ? 'show-mobile-menu' : ''">
        <div class="side-nav-container flex flex-direction-column empty-space-on-nav" (click)="clickdedEmptySideNav($event)">
            <div class="side-nav" [ngSwitch]="currentPageComponent">
                <section class="nav-group nav-settings" *ngSwitchCase="'Settings'">
                    <!-- RS27AUG24 -->
                    <!-- Used the translate pipe to successfully implement the translation -->
                    <div class="nav-page-header">{{'SETTINGS' | customTranslate : 'Settings' }}</div>
                    <!-- <div class="go-home">
                        <svg-icon src="../assets/images/icons/side-nav/home-2.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Home</span>
                    </div> -->
                    <div class="fc-nav-item cursor-pointer stroke-none" [ngClass]="activeNav == 'company' ? 'active' : ''" (click)="callNavService('company')">
                        <svg-icon src="../assets/images/icons/side-nav/company-settings.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'COMPANY_SETTING' | customTranslate : 'Company Settings' }}</span>
                    </div>
                    <!-- Localization and Email setting tabs are commented. If require in future we can uncomment that -->
                    <!-- SK 9JAN24 -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'localization' ? 'active' : ''" (click)="callNavService('localization')">
                        <svg-icon src="../assets/images/icons/side-nav/Time_progress.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'LOCALIZATION' | customTranslate : 'Localization' }}</span>
                    </div>
                    <!-- <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'email' ? 'active' : ''" (click)="callNavService('email')">
                        <svg-icon src="../assets/images/icons/side-nav/Message.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Email Settings</span>
                    </div> -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'leaveMaster' ? 'active' : ''" (click)="callNavService('leaveMaster')">
                        <svg-icon src="../assets/images/icons/side-nav/calendar.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'LEAVE_MASTER' | customTranslate : 'Leave Master'}}</span>
                    </div>
                    <!-- SK26JUL24 Org's bank Info -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'bankInfo' ? 'active' : ''" (click)="callNavService('bankInfo')">
                        <svg-icon src="../assets/images/icons/side-nav/calendar.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'ORGANIZATION_BANK_INFO' | customTranslate : 'Organization Bank Info' }}</span>
                    </div>
                    <!-- SK 31JAN24 For USERS, APPS, COMPONENTS modules-->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'users' ? 'active' : ''" (click)="callNavService('users')">
                        <svg-icon src="../assets/images/icons/side-nav/group-people.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'USERS' | customTranslate : 'Users' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'apps' ? 'active' : ''" (click)="callNavService('apps')">
                        <svg-icon src="../assets/images/icons/side-nav/file-list-3-line.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'APPS' | customTranslate : 'Apps' }}</span>
                    </div>
                    <!-- <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'components' ? 'active' : ''" (click)="callNavService('components')">
                        <svg-icon src="../assets/images/icons/side-nav/organization-chart.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Components</span>
                    </div> -->
                    <!-- SK12FEB24, added NXT component in module -->
                    <!-- <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'nxtComponents' ? 'active' : ''" (click)="callNavService('nxtComponents')">
                        <svg-icon src="../assets/images/icons/side-nav/organization-chart.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">NXT Components</span>
                    </div> -->
                    <!-- SK23FEB24 for dropdown management module -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'dropDownManagement' ? 'active' : ''" (click)="callNavService('dropDownManagement')">
                        <svg-icon src="../assets/images/icons/side-nav/drop-down.svg" class="nav-icon" style="filter: invert(100%) sepia(24%) saturate(509%) hue-rotate(134deg) brightness(105%) contrast(96%);"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'DROPDOWN_MANAGEMENT' | customTranslate : 'Drop Down Management' }}</span>
                    </div>
                    <!-- SK21AUG24 dynamic modules -->
                    <div *ngFor="let settingsMod of settingsModules" >
                        <div *ngIf="!settingsStaticModules.includes(settingsMod.name) && settingsMod.showModule" class="fc-nav-item cursor-pointer" [ngClass]="activeNav == settingsMod.name ? 'active' : ''" (click)="callNavService(settingsMod.name)">
                            <img [src]="settingsMod.icon" class="nav-icon">
                            <span class="medium-font hide-on-collapse">{{settingsMod.label | customTranslate : settingsMod.label }}</span>
                        </div>
                    </div>
                </section>
                <section class="nav-group nav-ess" *ngSwitchCase="'Ess'">
                    <div class="nav-page-header">{{'ESS' | customTranslate : 'ESS' }}</div>
                    <!-- <div class="go-home">
                        <svg-icon src="../assets/images/icons/side-nav/home-2.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Home</span>
                    </div> -->
                    <!-- <div>
                        <p class="nav-sub-heading">ESS</p>
                    </div>  -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'details' ? 'active' : ''" (click)="callNavService('details')">
                        <svg-icon src="../assets/images/icons/side-nav/bill-line.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'HEADINGS.DETAILS' | customTranslate : 'Details' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'leaves' ? 'active' : ''" (click)="callNavService('leaves')">
                        <svg-icon src="../assets/images/icons/side-nav/calendar-check-fill.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'HEADINGS.LEAVES' | customTranslate : 'Leaves' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'expenses' ? 'active' : ''" (click)="callNavService('expenses')">
                        <svg-icon src="../assets/images/icons/side-nav/money-ess.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'EXPENSES' | customTranslate : 'Expenses' }}</span>
                    </div>
                    <!-- SK02APR24 payslips module added -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'payslips' ? 'active' : ''" (click)="callNavService('payslips')">
                        <svg-icon src="../assets/images/icons/side-nav/coupon-line.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'PAYSLIP' | customTranslate : 'Payslips' }}</span>
                    </div>
                    <!-- <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'companypolicies' ? 'active' : ''" (click)="callNavService('companypolicies')">
                        <svg-icon src="../assets/images/icons/side-nav/file-list-3-line.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Company Policies</span>
                    </div> -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'taxdeclarations' ? 'active' : ''" (click)="callNavService('taxdeclarations')">
                        <svg-icon src="../assets/images/icons/side-nav/percent-line.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'HEADINGS.TAX_DECLARATIONS' | customTranslate : 'Tax Declarations' }}</span>
                    </div>
                    <!--<div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'compensation' ? 'active' : ''" (click)="callNavService('compensation')">
                        <svg-icon src="../assets/images/icons/side-nav/compensation.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Compensation</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'companyorgchart' ? 'active' : ''" (click)="callNavService('companyorgchart')">
                        <svg-icon src="../assets/images/icons/side-nav/organization-chart.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Company Org Chart</span>
                    </div> -->
                    <!-- SK21AUG24 dynamic modules -->
                    <div *ngFor="let essMod of essModules" >
                        <div *ngIf="!essStaticModules.includes(essMod.name) && essMod.showModule" class="fc-nav-item cursor-pointer" [ngClass]="activeNav == essMod.name ? 'active' : ''" (click)="callNavService(essMod.name)">
                            <img [src]="essMod.icon" class="nav-icon">
                            <span class="medium-font hide-on-collapse">{{essMod.label | customTranslate : essMod.label }}</span>
                        </div>
                    </div>
                </section>
                <section class="nav-group nav-hrm" *ngSwitchCase="'Hrm'">
                    <div class="nav-page-header">{{'HRM' | customTranslate : 'HRM' }}</div>
                    <!-- SK01FEB24, Dashboard as separate module -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'dashboard' ? 'active' : ''" (click)="callNavService('dashboard')">
                        <svg-icon src="../assets/images/icons/side-nav/dashboard.svg" style="filter: invert(83%)" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'DASHBOARD' | customTranslate : 'Dashboard' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'employees' ? 'active' : ''" (click)="callNavService('employees')">
                        <svg-icon src="../assets/images/icons/side-nav/group-people.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'EMPLOYEES' | customTranslate : 'Employees' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'compensation' ? 'active' : ''" (click)="callNavService('compensation')">
                        <svg-icon src="../assets/images/icons/side-nav/Compensation-icon.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'COMPENSATION' | customTranslate : 'Compensation' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'payroll' ? 'active' : ''" (click)="callNavService('payroll')">
                        <svg-icon src="../assets/images/icons/side-nav/payroll.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'PAYROLL' | customTranslate : 'Payroll' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'leaveAllocation' ? 'active' : ''" (click)="callNavService('leaveAllocation')">
                        <svg-icon src="../assets/images/icons/side-nav/listing.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'LEAVE_ALLOCATION' | customTranslate : 'Leave Allocation' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'attendance' ? 'active' : ''" (click)="callNavService('attendance')">
                        <svg-icon src="../assets/images/icons/side-nav/time-clock.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'ATTENDANCE' | customTranslate : 'Attendance' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'approvals' ? 'active' : ''" (click)="callNavService('approvals')">
                        <svg-icon src="../assets/images/icons/side-nav/approvals.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'APPROVALS' | customTranslate : 'Approvals' }}</span>
                    </div>
                    <!-- SK21AUG24 dynamic modules -->
                    <div *ngFor="let hrmMod of hrmModules" >
                        <div *ngIf="!hrmStaticModules.includes(hrmMod.name) && hrmMod.showModule" class="fc-nav-item cursor-pointer" [ngClass]="activeNav == hrmMod.name ? 'active' : ''" (click)="callNavService(hrmMod.name)">
                            <img [src]="hrmMod.icon" class="nav-icon">
                            <span class="medium-font hide-on-collapse">{{ hrmMod.label | customTranslate : hrmMod.label }}</span>
                        </div>
                    </div>
                </section>
                <section class="nav-group nav-leave-master" *ngSwitchCase="'LeaveMaster'">
                    <div class="nav-page-header">{{'HEADINGS.LEAVES' | customTranslate : 'Leaves' }}</div>
                    <!-- <div class="go-home" [ngClass]="activeNav == 'home' ? 'active' : ''" (click)="callNavService('home')">
                        <svg-icon src="../assets/images/icons/side-nav/home-2.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Home</span>
                    </div> -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'leaves' ? 'active' : ''" (click)="callNavService('leaves')">
                        <svg-icon src="../assets/images/icons/side-nav/calendar-check-fill.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'LEAVE_MASTER' | customTranslate : 'Leaves Master' }}</span>
                    </div>
                </section>
                <section class="nav-group nav-approvals" *ngSwitchCase="'Approvals'">
                    <div class="nav-page-header">{{'APPROVALS' | customTranslate : 'Approvals' }}</div>
                    <!-- <div class="go-home" [ngClass]="activeNav == 'home' ? 'active' : ''" (click)="callNavService('home')">
                        <svg-icon src="../assets/images/icons/side-nav/home-2.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">Home</span>
                    </div> -->
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'details' ? 'active' : ''" (click)="callNavService('details')">
                        <svg-icon src="../assets/images/icons/side-nav/bill-line.svg" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'HEADINGS.DETAILS' | customTranslate : 'Details' }}</span>
                    </div>
                    <div class="fc-nav-item cursor-pointer" [ngClass]="activeNav == 'settings' ? 'active' : ''" (click)="callNavService('settings')">
                        <svg-icon src="../assets/images/icons/side-nav/settingsicon.svg" style="float: inline-start;" class="nav-icon"></svg-icon>
                        <span class="medium-font hide-on-collapse">{{'SETTINGS' | customTranslate : 'Settings' }}</span>
                    </div>
                </section>
                <div class="app-version menu">  {{currentApplicationVersion}}</div>
            </div>
            
        </div>
    </div>
    <div class="content-section flex-grow max-width-minus-nav-width" style="--grow-value: 1">
        <router-outlet (activate)="componentAdded($event)"></router-outlet>
    </div>
</div>

<!-- SK15JUL24 loading spinner  -->
<ngx-spinner  size="medium"  type="ball-spin-clockwise-fade">
</ngx-spinner>