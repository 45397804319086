<!-- SK13MAR24 The apps for that company is listed -->
<!-- SK22MAR24 added subtab emit function -->
<app-nav-tabs [tabsLists]="tabsLists" [activeTab]="activeTab" (emitTabData)="emitTabData($event)" (emitSubTabData)="emitSubTabData($event)"></app-nav-tabs>
<div *ngIf="!tabContent.browsedTab && tabContent.value !== 'allModules'">
    <app-data-table [data]="tableData?.config?.data" [headerLabels]="tableData?.config?.headerLabels" [translatePath]="'DYNAMIC_TABLE'"
        [displayedColumns]="tableData?.config?.displayedColumns" [fromWhere]="tableData?.config?.fromWhere"
        [withCheckBox]="tableData?.config?.withCheckBox" [isButtons]="tableData?.config?.isButtons"
        [searchBar]="tableData?.config?.searchBar" [buttonArray]="tableData?.config?.buttons"
        [actionButton]="tableData?.config?.actionButton" [isEditRow]="tableData?.config?.isEditRow"
        [isDeleteRow]="tableData?.config?.isDeleteRow" [hyperLinkColumns]="tableData?.config?.hyperLinkColumns"
        (commonButtonClickEmit)="commonButtonClickEmit($event)" (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
        (onEditData)="onEditRecord($event)" (OnDeleteData)="onDeleteRecord($event)">
    </app-data-table>
</div>
<!-- SK13MAR24 the selected app's modules is listed here -->
<div *ngIf="tabContent.value === 'allModules'">
    <app-data-table [title]="'Modules List'" [titleOn]="true" [searchBar]="moduleData.config?.searchBar" [data]="moduleData.config?.data"
        [headerLabels]="moduleData.config?.headerLabels" [translatePath]="'DYNAMIC_TABLE'" [displayedColumns]="moduleData.config?.displayedColumns"
        [hyperLinkColumns]="moduleData.config?.hyperLinkColumns" [fromWhere]="moduleData.config?.fromWhere"
        [withCheckBox]="moduleData.config?.withCheckBox" [isEditRow]="moduleData.config?.isEditRow"
        [isDeleteRow]="moduleData.config?.isDeleteRow" [actionButton]="moduleData.config?.actionButton"
        [isButtons]="moduleData.config?.isButtons" [buttonArray]="moduleData.config?.buttons"
        [addInlineRecord]="moduleData.config?.addInlineRecord" [editColumn]="moduleData.config?.editColumns || []"
        [isEditRow]="moduleData.config?.isEditRow" [isDeleteRow]="moduleData.config?.isDeleteRow"
        [actionButton]="moduleData.config?.actionButton" [inlineElement]="moduleData.config?.inlineElement"
        (commonButtonClickEmit)="commonButtonClickEmit($event)" (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
        (onEditData)="onEditRecord($event)" (OnDeleteData)="onDeleteRecord($event)"
        (saveButtonData)="saveTableData($event)">
    </app-data-table>
</div>
<div *ngIf="tabContent.value !== 'allModules' && tabContent.browsedTab === true">
    <app-data-table [title]="'Tabs List'" [titleOn]="true" [searchBar]="tabData?.config?.searchBar" [data]="tabData?.config?.data"
        [headerLabels]="tabData?.config?.headerLabels" [translatePath]="'DYNAMIC_TABLE'" [displayedColumns]="tabData?.config?.displayedColumns"
        [hyperLinkColumns]="tabData?.config?.hyperLinkColumns" [fromWhere]="tabData?.config?.fromWhere"
        [withCheckBox]="tabData?.config?.withCheckBox" [isEditRow]="tabData?.config?.isEditRow"
        [isDeleteRow]="tabData?.config?.isDeleteRow" [actionButton]="tabData?.config?.actionButton"
        [editColumn]="tabData?.config?.editColumns || []"
        [isButtons]="tabData.config?.isButtons" [buttonArray]="tabData.config?.buttons"
        [isEditRow]="tabData?.config?.isEditRow" [isDeleteRow]="tabData?.config?.isDeleteRow"
        [actionButton]="tabData?.config?.actionButton" [inlineElement]="tabData?.config?.inlineElement"
        [addInlineRecord]="tabData?.config?.addInlineRecord"
        (commonButtonClickEmit)="commonButtonClickEmit($event)" (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
        (onEditData)="onEditRecord($event)" (OnDeleteData)="onDeleteTab($event, 'tab')"
        (saveButtonData)="saveTableData($event)">
    </app-data-table>
</div>

<!-- SK13MAR24 for creating/updating the apps -->
<app-modal [modalTitle]="newAppbutton ? ('ADD_APP' | customTranslate : 'Add App' ) : ('EDIT_APP' | customTranslate : 'Edit App' )"  [modalFooter]="false" [saveButtonValue]="('SAVE' | customTranslate : 'Save')"
    [isModalOpen]="openModal" (cancelButtonEmit)="modalClose($event)" [modalSize]="'modal-md'">
    <div class="modal-body">
        <form class="flex flex-column" id="appForm" [formGroup]="newAppForm" autocomplete="off">
            <div class="bottom-line"></div>
            <div class="flex mt-3">
                <div class="row g-3 flex">
                    <div class="col-md-6">
                        <label for="name" class="form-label  label-txt modal-field-label">{{'PERSONAL_INFO.NAME' | customTranslate : 'Name'  }}</label>
                        <div class="field-input"
                            [class]=" this.newAppForm.controls['name'].invalid && this.newAppForm.controls['name'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="name" class="form-control" id="name"
                                [readOnly]="!newAppbutton?true:false">
                        </div>
                        <p class="error-fields"
                            [style]="this.newAppForm.controls['name'].invalid && this.newAppForm.controls['name'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="label" class="form-label  label-txt modal-field-label">{{'LABEL' | customTranslate : 'Label'  }}</label>
                        <div class="field-input"
                            [class]=" this.newAppForm.controls['label'].invalid && this.newAppForm.controls['label'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="label" class="form-control" id="label">
                        </div>
                        <p class="error-fields"
                            [style]="this.newAppForm.controls['label'].invalid && this.newAppForm.controls['label'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="appName" class="form-label  label-txt modal-field-label">{{'ADDAPP.APP_NAME' | customTranslate : 'App Name*'  }}</label>
                        <div class="field-input"
                            [class]=" this.newAppForm.controls['appName'].invalid && this.newAppForm.controls['appName'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="appName" class="form-control" id="appName"
                                [readOnly]="!newAppbutton?true:false">
                        </div>
                        <p class="error-fields"
                            [style]="this.newAppForm.controls['appName'].invalid && this.newAppForm.controls['appName'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="type" class="form-label  label-txt modal-field-label">{{'TYPE' | customTranslate : 'Type'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="type" class="form-control" id="type">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label for="icon" class="form-label  label-txt modal-field-label">{{'ADDAPP.ICON' | customTranslate : 'Icon'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="icon" class="form-control" id="icon">
                        </div>
                    </div>
                    <div class="form-check form-switch col-md-4">
                        <input class="form-check-input" type="checkbox" formControlName="default" role="switch"
                            id="default">
                        <label class="form-check-label" for="default">{{'DEFAULT' | customTranslate : 'Default'  }}</label>
                    </div>
                    <div class="form-check form-switch col-md-4">
                        <input class="form-check-input" type="checkbox" formControlName="showInLauncher" role="switch"
                            id="showInLauncher">
                        <label class="form-check-label" for="showInLauncher">{{'SHOW_IN_LAUNCHER' | customTranslate : 'Show In Launcher'  }}</label>
                    </div>
                    <div class="form-check form-switch col-md-4">
                        <input class="form-check-input" type="checkbox" formControlName="default" role="switch"
                            id="showTracker">
                        <label class="form-check-label" for="showTracker">{{'ADDAPP.SHOW_TRACKER' | customTranslate : 'Show Tracker'  }}</label>
                    </div>
                    <div class="mb-4 d-flex justify-content-end">
                        <app-fusion-button buttonType="btn text-only ms-2"
                            (buttonClickEmit)="openModal = false;newAppForm.reset()" data-bs-dismiss="modal"
                            buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'}}"></app-fusion-button>
                        <button class="btn btn-primary ms-2 rounded-0" type="button"
                            (click)="updateData(newAppbutton ? 'post' : 'patch', 'app')"
                            [disabled]="!newAppForm.valid  || loadingSpinner">
                            <span class="{{ loadingSpinner ? 'spinner-grow' : '' }} spinner-grow-sm" role="status"
                                aria-hidden="true"></span>
                            <span>{{ loadingSpinner ? ('Loading' | customTranslate : 'Loading') + '...' : ('BUTTON_VALUE.SAVE' | customTranslate : 'Save') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-modal>

<!-- SK13MAR24 for creating/updating the modules -->
<app-modal [modalTitle]="newModulebutton ? 'Add Module' : 'Edit Module' " [modalFooter]="false"
    [saveButtonValue]="('BUTTON_VALUE.SAVE' | customTranslate : 'Save')" [isModalOpen]="openModuleModal" (cancelButtonEmit)="modalClose($event)"
    [modalSize]="'modal-md'">
    <div class="modal-body">
        <form class="flex flex-column" id="moduleForm" [formGroup]="newModuleForm" autocomplete="off">
            <div class="bottom-line"></div>
            <div class="flex mt-3">
                <div class="row g-3 flex w-100">
                    <div class="col-md-6">
                        <label for="name" class="form-label  label-txt modal-field-label">{{'PERSONAL_INFO.NAME' | customTranslate : 'Name'  }}</label>
                        <div class="field-input"
                            [class]=" this.newModuleForm.controls['name'].invalid && this.newModuleForm.controls['name'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="name" class="form-control" id="name"
                                [readOnly]="!newModulebutton?true:false">
                        </div>
                        <p class="error-fields"
                            [style]="this.newModuleForm.controls['name'].invalid && this.newModuleForm.controls['name'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="label" class="form-label  label-txt modal-field-label">{{'LABEL' | customTranslate : 'Label'  }}</label>
                        <div class="field-input"
                            [class]=" this.newModuleForm.controls['label'].invalid && this.newModuleForm.controls['label'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="label" class="form-control" id="label">
                        </div>
                        <p class="error-fields"
                            [style]="this.newModuleForm.controls['label'].invalid && this.newModuleForm.controls['label'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="order" class="form-label  label-txt modal-field-label">{{'ORDER' | customTranslate : 'Order'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="order" class="form-control" id="order">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="icon" class="form-label  label-txt modal-field-label">{{'ADDAPP.ICON' | customTranslate : 'Icon'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="icon" class="form-control" id="icon">
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 d-flex justify-content-end">
                <app-fusion-button buttonType="btn text-only ms-2"
                    (buttonClickEmit)="openModuleModal = false;newModuleForm.reset()" data-bs-dismiss="modal"
                    buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'}}"></app-fusion-button>
                <button class="btn btn-primary ms-2 rounded-0" type="button"
                    (click)="updateData(newModulebutton ? 'post' : 'patch', 'module')"
                    [disabled]="!newModuleForm.valid  || loadingSpinner">
                    <span class="{{ loadingSpinner ? 'spinner-grow' : '' }} spinner-grow-sm" role="status"
                        aria-hidden="true"></span>
                    <span>{{ loadingSpinner ? ('Loading' | customTranslate : 'Loading') + '...' : ('BUTTON_VALUE.SAVE' | customTranslate : 'Save') }}
                    </span>
                </button>
            </div>
        </form>
    </div>
</app-modal>

<!-- SK21MAR24 for creating/updating the tabs -->
<app-modal [modalTitle]="newTabbutton ? 'Add Tab' : 'Edit Tab' " [modalFooter]="false"
    [saveButtonValue]="('BUTTON_VALUE.Save' | customTranslate : 'Save')" [isModalOpen]="openTabModal" (cancelButtonEmit)="modalClose($event)"
    [modalSize]="'modal-md'">
    <div class="modal-body">
        <form class="flex flex-column" id="tabForm" [formGroup]="newTabForm" autocomplete="off">
            <div class="bottom-line"></div>
            <div class="flex mt-3">
                <div class="row g-3 flex w-100">
                    <div class="col-md-6">
                        <label for="name" class="form-label  label-txt modal-field-label">{{'PERSONAL_INFO.NAME' | customTranslate : 'Name'  }}</label>
                        <div class="field-input"
                            [class]=" this.newTabForm.controls['name'].invalid && this.newTabForm.controls['name'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="name" class="form-control" id="name"
                                [readOnly]="!newTabbutton?true:false">
                        </div>
                        <p class="error-fields"
                            [style]="this.newTabForm.controls['name'].invalid && this.newTabForm.controls['name'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="label" class="form-label  label-txt modal-field-label">{{'LABEL' | customTranslate : 'Label'  }}</label>
                        <div class="field-input"
                            [class]=" this.newTabForm.controls['label'].invalid && this.newTabForm.controls['label'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="label" class="form-control" id="label">
                        </div>
                        <p class="error-fields"
                            [style]="this.newTabForm.controls['label'].invalid && this.newTabForm.controls['label'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="order" class="form-label  label-txt modal-field-label">{{'ORDER' | customTranslate : 'Order'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="order" class="form-control" id="order">
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 d-flex justify-content-end">
                <app-fusion-button buttonType="btn text-only ms-2"
                    (buttonClickEmit)="openTabModal = false;newTabForm.reset()" data-bs-dismiss="modal"
                    buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'}}"></app-fusion-button>
                <button class="btn btn-primary ms-2 rounded-0" type="button"
                    (click)="updateData('patch', 'tab')"
                    [disabled]="!newTabForm.valid  || loadingSpinner">
                    <span class="{{ loadingSpinner ? 'spinner-grow' : '' }} spinner-grow-sm" role="status"
                        aria-hidden="true"></span>
                    <span>{{ loadingSpinner ? ('Loading' | customTranslate : 'Loading') + '...' : ('BUTTON_VALUE.SAVE' | customTranslate : 'Save') }}
                    </span>
                </button>
            </div>
        </form>
    </div>
</app-modal>