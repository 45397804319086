import { Injectable , isDevMode} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private readonly TOKEN_NAME = 'auth_token';
  private readonly REFRESH_TOKEN_NAME = 'refresh_token';
  role!:Array<any>;
  user!: UserModel;
  isDevMode: boolean = false;

  get token(): any {
    return localStorage.getItem(this.TOKEN_NAME);
  }

  constructor(private apiService: ApiService) {
    this.isDevMode = isDevMode();
    this._isLoggedIn$.next(!!this.token);
    if(this.token){
      this.user = this.getUser(this.token);
    } 
    // else {
    //   this.user = this.role as unknown as UserModel;
    // }
  }

  login(username: string, password: string) {  
    return this.apiService.writeValue('post','/user/login',{'email' : username, 'password': password}).pipe(
      tap((response: any) => {
        if(this.isDevMode) console.log("Response is ", response);
        this._isLoggedIn$.next(true);
        const expiresAt = (JSON.parse(window.atob(response.token.split('.')[1])).exp);
        const refreshExpiresAt = (JSON.parse(window.atob(response.refreshToken.split('.')[1])).exp);
        //setting token in local storage
        localStorage.setItem(this.TOKEN_NAME, response.token);
        localStorage.setItem(this.REFRESH_TOKEN_NAME, response.refreshToken);
        this.user = this.getUser(response.token);
        //setting the token expiry time in local storage 
        localStorage.setItem("expires_at", expiresAt );
        localStorage.setItem("refresh_expires_at", refreshExpiresAt );
      }) 
    )
  }

  private getUser(token: string): UserModel{
     let roles = JSON.parse(window.atob(token?.split('.')[1]));
     this.role = roles.permissions;
     if(this.isDevMode) console.log('inside auth.service.ts.getUser' + this.role)
     return this.role as any;
  }
}
