import { Component, OnInit, isDevMode, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { Subscription } from 'rxjs';
import { FusionFrameworkService } from 'src/app/services/fusion-framework.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner'; //SK 9JAN24
@Component({

  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule,AngularSvgIconModule,DropDownComponent,FusionButtonComponent, NgxSpinnerModule,TranslateModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  currentApplicationVersion = environment.appVersion;
  public errorMessage: string | undefined = undefined;
  public fpMailError: string | undefined = undefined;
  loginForm!: FormGroup;
  fpForm!: FormGroup;
  buttonDisable = false;
  loginButton = "login-button button-disabled";
  fpButton = "fp-button button-disabled";
  error = '';
  errorMsg = false;
  
  errorPasswordMsg = false;
  passWorderror = '';
  validRegex = "[A-Za-z0-9._%-+]+@[A-Za-z0-9._%-]+\\.[a-z]{2,10}";
  loginScreen = true;
  showResetFields = true;
  passwordHide = false;
  rememberMeChecked = '';
  getData: Object | undefined;
  pageComponentName = 'Login';
  isDevMode: boolean = false;
  successMsg = Constants.SUCCESS_MESSAGE;
  currentLang:any

  shouldDisplayFusionAppLogo: boolean = false;
  shouldDisplayValarhrAppLogo: boolean = false;
  domain:any;
  // jsonSubscription: Subscription; // THIS CODE TO BE USED IN FUTURE FOR JSON BASED FRAMEWORK. DON'T REMOVE IT
  configJSON: any;
  typeSelected! : string;
  languageCode:any; //SK24JUN24
  languageArray: string[] = [];
  translations: Record<string, any> ={
    en: {
      "SCREEN_DIRECTION":"ltr",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "Welcome, Please login into your account",
          "USER_NAME" : "Username/Email",
          "PASSWORD" : "Password",
          "USER_NAME_ERROR" : "Please enter a valid email address",
          "PASSWORD_ERROR" : "Password must contain at least 6 characters",
          "LOGIN" : "Login",
          "BACK" : "Back",
          "RESEND_LINK" : "Resend Link",
          "FORGOT_PASSWORD" : "Forgot your Password?",
          "PASSWORD_RESET" : "Password Reset",
          "RESET_MAIL" : "Please enter your registered email id to receive password reset instructions.",
          "SEND_RESET_LINK" : "Send link to email",
          "GO_BACK_TO_LOGIN" : "Go back to Login",
          "EMAIL_SENT" : "Email Sent",
          "CHECK_INBOX" : "Check your inbox and open the link we sent to",
          "ENTER_NEW_PASSWORD" : "Enter a new password",
          "NEW_PASSWORD" : "New password",
          "CONFIRM_PASSWORD" : "Confirm password",
          "PASSWORD_MISMATCH" : "Password mismatch",
          "WELL_DONE" : "Well Done!",
          "PASSWORD_CHANGE_SUCCESS" : "Your password has been changed successfully. Please use your new password to login! ",
          "REMEMBER_ME":"Remember me"
      }
    },
    ar: {
      "SCREEN_DIRECTION":"rtl",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "مرحبًا ، الرجاء تسجيل الدخول إلى حسابك",
          "USER_NAME" : "اسم المستخدم / البريد الإلكتروني",
          "PASSWORD" : "كلمة المرور",
          "USER_NAME_ERROR" : "يرجى إدخال عنوان بريد إلكتروني صالح",
          "PASSWORD_ERROR" : "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل",
          "LOGIN" : "تسجيل الدخول",
          "BACK" : "Back",
          "RESEND_LINK" : "Resend Link",
          "FORGOT_PASSWORD" : "نسيت كلمة السر؟",
          "PASSWORD_RESET" : "إعادة تعيين كلمة المرور",
          "RESET_MAIL" : "الرجاء إدخال معرف البريد الإلكتروني المسجل الخاص بك لتلقي تعليمات إعادة تعيين كلمة المرور.",
          "SEND_RESET_LINK" : "إرسال ارتباط إلى البريد الإلكتروني",
          "GO_BACK_TO_LOGIN" : "ارجع إلى تسجيل الدخول",
          "EMAIL_SENT" : "أرسل البريد الإلكتروني",
          "CHECK_INBOX" : "تحقق من صندوق الوارد الخاص بك وافتح الرابط الذي أرسلناه إلى",
          "ENTER_NEW_PASSWORD" : "Enter a new password",
          "NEW_PASSWORD" : "New password",
          "CONFIRM_PASSWORD" : "Confirm password",
          "PASSWORD_MISMATCH" : "Password mismatch",
          "WELL_DONE" : "Well Done!",
          "PASSWORD_CHANGE_SUCCESS" : "Your password has been changed successfully. Please use your new password to login! ",
          "REMEMBER_ME":"Remember me"
      }
    },
    ta: {    
      "SCREEN_DIRECTION":"ltr",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "வரவேற்கிறோம், உங்கள் கணக்கில் உள்நுழையவும்",
          "USER_NAME" : "பயனர் பெயர் / மின்னஞ்சல்",
          "PASSWORD" : "கடவுச்சொல்",
          "USER_NAME_ERROR" : "சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும்",
          "PASSWORD_ERROR" : "கடவுச்சொல்லில் குறைந்தது 6 எழுத்துகள் இருக்க வேண்டும்",
          "LOGIN" : "உள்நுழைய",
          "BACK" : "பின் செல்",
          "RESEND_LINK" : "இணைப்பை மீண்டும் அனுப்பு",
          "FORGOT_PASSWORD" : "உங்கள் கடவுச்சொல்லை மறந்து விட்டீர்களா?",
          "PASSWORD_RESET" : "கடவுச்சொல் மீட்டமைப்பு",
          "RESET_MAIL" : "கடவுச்சொல் மீட்டமைப்பு வழிமுறைகளைப் பெற உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சல் ஐடியை உள்ளிடவும்.",
          "SEND_RESET_LINK" : "மின்னஞ்சலுக்கு இணைப்பை அனுப்பவும்",
          "GO_BACK_TO_LOGIN" : "உள்நுழைவுக்குத் திரும்பு",
          "EMAIL_SENT" : "மின்னஞ்சல் அனுப்பப்பட்டது",
          "CHECK_INBOX" : "உங்கள் இன்பாக்ஸைச் சரிபார்த்து, நாங்கள் அனுப்பிய இணைப்பைத் திறக்கவும்",
          "ENTER_NEW_PASSWORD" : "புதிய கடவுச்சொல்லை உள்ளிடுக",
          "NEW_PASSWORD" : "புதிய கடவுச்சொல்",
          "CONFIRM_PASSWORD" : "மீண்டும் கடவுச்சொல்லை உறுதிப்படுத்தவும்",
          "PASSWORD_MISMATCH" : "கடவுச்சொல் பொருந்தவில்லை",
          "WELL_DONE" : "நன்றாக முடிந்தது!",
          "PASSWORD_CHANGE_SUCCESS" : "உங்கள் கடவுச்சொல் வெற்றிகரமாக மாற்றப்பட்டது. உள்நுழைய உங்கள் புதிய கடவுச்சொல்லைப் பயன்படுத்தவும்!",
          "REMEMBER_ME":"Remember me"
      }
    },
    hi: {    
      "SCREEN_DIRECTION":"ltr",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "स्वागत है, कृपया अपने खाते में लॉगिन करें",
          "USER_NAME" : "उपयोक्तानाम/ईमेल",
          "PASSWORD" : "पासवर्ड",
          "USER_NAME_ERROR" : "कृपया एक मान्य ईमेल पता प्रविष्ट करें",
          "PASSWORD_ERROR" : "पासवर्ड में कम से कम 6 अक्षर होने चाहिए",
          "LOGIN" : "लॉग इन करें",
          "BACK" : "Back",
          "RESEND_LINK" : "Resend Link",
          "FORGOT_PASSWORD" : "अपना कूट शब्द भूल गए?",
          "PASSWORD_RESET" : "पासवर्ड रीसेट",
          "RESET_MAIL" : "पासवर्ड रीसेट निर्देश प्राप्त करने के लिए कृपया अपनी पंजीकृत ईमेल आईडी दर्ज करें।",
          "SEND_RESET_LINK" : "ईमेल पर लिंक भेजें",
          "GO_BACK_TO_LOGIN" : "लॉगइन पर वापस जाएं",
          "EMAIL_SENT" : "ईमेल भेजा",
          "CHECK_INBOX" : "अपना इनबॉक्स जांचें और हमारे द्वारा भेजा गया लिंक खोलें",
          "ENTER_NEW_PASSWORD" : "Enter a new password",
          "NEW_PASSWORD" : "New password",
          "CONFIRM_PASSWORD" : "Confirm password",
          "PASSWORD_MISMATCH" : "Password mismatch",
          "WELL_DONE" : "Well Done!",
          "PASSWORD_CHANGE_SUCCESS" : "Your password has been changed successfully. Please use your new password to login! ",
          "REMEMBER_ME":"Remember me"
      }
    }
  };
  isRtl = false;
  languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'தமிழ்', value: 'ta' },
    { label: 'हिंदी', value: 'hi' },
    { label: 'عربي', value: 'ar' }
  ];
  constructor(private apiService:ApiService, private fb: FormBuilder, private authService: AuthService, private router: Router, private errorHandler: ErrorHandlerService, private alertService: AlertService, private fusionFrameWork: FusionFrameworkService, private spinnerService: NgxSpinnerService, private translate: TranslateService, private globalValues:GlobalValuesService,@Inject(DOCUMENT) private _document: Document) { 
    this.isDevMode = isDevMode();
    this.typeSelected = 'ball-spin-clockwise-fade';  //SK 9JAN24 loader type in login
    // THIS CODE TO BE USED IN FUTURE FOR JSON BASED FRAMEWORK. DON'T REMOVE IT
    // this.jsonSubscription = this.fusionFrameWork.appJSONData$.subscribe((type:any) => {
    //   this.configJSON = type.appConfig;
    //   this.redirectUrl = this.configJSON[0]?.reDirectURL;
    // });

    // SK24JUN24 getting lang code from local
    this.languageCode = localStorage.getItem('language') || 'en';

    // Validate if the language code is in the language options; if not, default to 'en'
    const validLanguage = this.languageOptions.some(option => option.value === this.languageCode);
    if (!validLanguage) {
      this.languageCode = 'en';
    }
  
    this.currentLang = this.languageCode;
    this.isRtl = this.currentLang === 'ar';
  }
  //RS04SEP24
  emittedValue(selectedItem: any) {

    if (selectedItem) {
      this.currentLang = selectedItem.data.value;
      this.languageCode = this.currentLang;

      // Update language in localStorage
      localStorage.setItem('language', this.currentLang);
      // Update the direction and language attributes on the document
      if (this.currentLang === 'ar') {
        this.isRtl = true;
      } else {
        this.isRtl = false;
      }
    }
  }

  ngOnInit(): void {
    
     // To display logo dynamiclly
    const currentURL = window.location.href;
    //To extract the domain from URL;
    this.domain = currentURL.split('.')[1]+'.'+currentURL.split('.')[2];
    if (this.domain.includes('valarhr.com')) {
      this.shouldDisplayValarhrAppLogo = true;
    } else {
      this.shouldDisplayFusionAppLogo = true;
    }

    this.loginForm = this.fb.group({
      'username': [localStorage['username'] == null ? '' : localStorage['username'], Validators.compose([Validators.required, Validators.pattern(this.validRegex)])],
      'password': [null,Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30)]) ],
      'rememberMe': [localStorage['checkbox']]
    });

    this.fpForm = this.fb.group({
      'email': [null, Validators.compose([Validators.pattern(this.validRegex), Validators.required])]
    });

    this.rememberMeChecked = localStorage['checkbox'] == '' ? '' : localStorage['checkbox'];
  }

  handlePasswordChange(event: any): void {
    this.loginForm.value.password = event.target.value;
    if (this.loginForm.value.password.length > 5 && this.loginForm.value.password.length < 30 || this.loginForm.value.password.length === 0) {
      this.errorPasswordMsg = false;
      this.passWorderror = '';
    } else {
      this.errorPasswordMsg = true;
      this.passWorderror = 'passWorderror';
    }
  }

  handleRememberMeChange(event: any) {
    this.loginForm.value.rememberMe = event.target.checked;
  }

  settingsData:any;
  localizationdata:any;
  salaryData:any;
  emailData:any;

  handleLoginClick() {
    const localUrl = localStorage.getItem("routerURL"); //SK10AUG24
    localStorage.clear(); //SK12FEB24 clearing the localStorage while login, to avoid previous cache
    localStorage.setItem('language', this.currentLang);
    // this.spinnerService.show();  //SK 9JAN24 loader show function
    if(this.isDevMode) console.log("handleLoginClick");
    this.errorMessage = '';
    if (this.loginForm.value.username !== "") {
      localStorage.setItem('username', this.loginForm.value.username);
    } else {
      localStorage.setItem('username', "");
    };

    if (this.loginForm.value.rememberMe) {
      localStorage['checkbox'] = this.loginForm.value.rememberMe;
    } else {
      localStorage['checkbox'] = null;
    }

    const val = this.loginForm.value;
    // Check if the form is filled with valid values , if so proceed to login else just skip form submission
    if (this.loginForm.invalid) {
      return;
    }
    
    if (val.username && val.password) {
      this.authService.login(val.username, val.password)
        .subscribe({
          next: (v) => {
            // this.spinnerService.hide();  //SK 9JAN24 loader hide function
            if(this.isDevMode) console.log("Next :", v);
            if(this.isDevMode) console.log("User signedup in");
            let usr = JSON.parse(window.atob(v.token.split('.')[1]));
            let roles = (usr.permissions);
            // SK21DEC23 dynamic fusion color module while login
            if(v?.theme){
              document.documentElement.style.setProperty('--fusion-color', v.theme?.fusionColor);
              document.documentElement.style.setProperty('--module', v.theme?.clrBody);
              document.documentElement.style.setProperty('--active-module', v.theme?.fcSubLite);
              document.documentElement.style.setProperty('--active-module-bg', v.theme?.fcPrimaryColor);
            }
            this.errorMessage = '';
            // SK24JUN24 lang code routing in settings, hrm, ess
            if(localUrl!= null){
              this.router.navigateByUrl(localUrl);
            } else {
              if(roles.includes('Super User') && this.settingsData === undefined && this.salaryData === undefined && this.localizationdata == undefined && this.emailData === undefined) {
                this.router.navigate(['/settings', this.languageCode || "en"],{
                  queryParams:{
                    pageFrom: 'login'
                  }
                });
                return;
              } else if(roles.includes('HRM User') && !usr.defaultApp){
                this.router.navigateByUrl('/hrm/'+this.languageCode || "en");
              } else if(roles.includes('ESS User') && !usr.defaultApp){
                this.router.navigateByUrl('/ess/'+this.languageCode || "en");
              } else {
                this.router.navigateByUrl('/app/'+ (this.languageCode || "en") + usr.defaultApp);  // MR 07DEC23 replaced /app/parking/home/entries_button with /app
              }
            } 
          },
          error: (err: HttpErrorResponse) => {
            // this.spinnerService.hide();  //SK 9JAN24
            if(this.isDevMode) console.log("Login Error", err);
            this.errorHandler.handleError(err);
            this.errorMessage = this.errorHandler.errorMessage;
            this.alertService.messageOnPass('error', this.errorMessage);
          }
        });
    }
  }
  
  sendPasswordLink() {
    this.fpMailError= '';
    const val = this.fpForm.value;
    // Check if the form is filled with valid values , if so proceed to login else just skip form submission
    if (this.fpForm.invalid) {
      return;
    }
    /*else {
      this.showResetFields = false;
    }*/
    if (val.email) {
      this.apiService.writeValue('post','/forgotpassword',{'email':val.email,'source':this.domain.split('/')[0]})
      .subscribe({
        next: (v) => {
          if(this.isDevMode) console.log("Next :", v);
          if(this.isDevMode) console.log("Mail Sent");
          this.fpMailError = '';
          this.showResetFields = false;
          this.alertService.messageOnPass('success', 'Password reset mail sent successfully');
        },
        error: (mailErr: HttpErrorResponse) => {
          this.errorHandler.handleError(mailErr);
          this.fpMailError = this.errorHandler.errorMessage;
          this.showResetFields = true;
          this.alertService.messageOnPass('error', this.fpMailError);
        }
      });
    }
  }

  validateInput(event: any) {
    this.loginForm.value.username = event.target.value;
    if (event.target.value.match(this.validRegex) || event.target.value === '') {
      this.error = '';
      this.errorMsg = false;
         } 
    else {
      this.errorMsg = true;
      this.error = 'inputError';
    }
  }


}
