import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { DragDropFilesDirective } from 'src/app/directives/drag-drop-files/drag-drop-files.directive';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TruncateNamePipe } from 'src/app/pipes/truncateName/truncate-name.pipe';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [CommonModule,DragDropFilesDirective,FusionButtonComponent,AngularSvgIconModule,TruncateNamePipe,TranslateModule,CustomTranslatePipe],
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  // FileUploading in new Angular 14
  // Refer:- https://stackblitz.com/edit/angular-drag-n-drop-directive-oxqyns?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.scss
  
  @ViewChildren('fileInput') fileInput: QueryList<ElementRef> | undefined; 
  @Output() selectedFilesEmitter = new EventEmitter<any>();
  @Output() documentEmitter = new EventEmitter<any>();
  @Output() documentDelete = new EventEmitter<any>();
  @Output() fileNameData = new EventEmitter<any>();
  @Output() multipleFileEmitter = new EventEmitter<any>();

  @Input() fileUploadId = 'fileUpload';
  @Input() customHtml = false; /* Temporarily used as boolean, If needed change it to some string and write HTML condition accordingly*/ 
  @Input() multiSelectFile =  false; /* Making it true allows user to select multiple files at once */
  @Input() showPreview = ''; /* If this is true it will enable the file prerview in iframe below the drag drop area */
  @Input() acceptType: string = ''; /* Refer:-  https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv */
  @Input() docVals: any = ['0', 'All']; // contains file size and file type in it
  mbInSize: number = 0; // to check mb's size
  
  @Output() errorMessage: string = '';
  @Input() uploadReadyFiles: Array<any> = []; // This array holds the dropped or selected files. Which later needs to be emitted to the parrent.

  
  isDevMode: boolean = false;
  @Input() multiFileCheckSize: number = 0; // We should make this as zero from parent component based on that function

  constructor(private sanitizer: DomSanitizer, private errorHandler: ErrorHandlerService, private language:TranslateService) { }

  ngOnInit(): void { 
    this.isDevMode = this.isDevMode;
    this.errorMessage = '';
    this.mbInSize = parseInt(this.docVals[0]?.slice(0,1)); // Assigning value to MB
  }

  ngAfterViewInit(): void{
    this.fileInput!.forEach((uploadingInput:any) => {
      if(uploadingInput.nativeElement.id == this.fileUploadId){
        uploadingInput.nativeElement.accept = this.acceptType;
      }
    });
  }

  /**
   * When any file is selected by clicking 'Browse'
  **/
  fileNameArray: Array<any> =[];
  onFileSelected(eventAndTarget: any){
    this.fileSizeCheck(eventAndTarget.target.files);
  }
  
  /**
   * When any file(s) is dragged and dropped
  **/
  onFileDropped(eventAndTarget: any){
    this.fileSizeCheck(eventAndTarget.file.files);
  }

  // This function is to check the file size on different conditions
  fileSizeCheck(event:any){
    let acceptFiles = this.acceptType.split(','); // this is to check the file type that was uploaded is valid

    // This condition is checked while selecting files uding drag and drop. 
     // If displays error, If we select multiple files when it is allowed to upload only single file
    if (!this.multiSelectFile && event.length > 1){
      this.fileErrorMessage("Multi selection of files are not supported");
    }
    
    /** The selected file sizes will be checked by looping the array and checking the file sizes of each
        if the size exceeds the limit, it shows error **/
    else {
      let filetotal: any[] = [];
      let filePresent : any;
      for(let i=0; i<event.length; i++){
        if(acceptFiles.includes(event[i].type)){
          const fileSizeInBytes = event[i].size;
          let fileSizeInKB = fileSizeInBytes / 1024;
          var fileSizeInMegaBytes = fileSizeInKB / 1024; 
          // if it is multiselect, then we calculate if the overall file size is within the provided range (currently we calulate only for mb size) 
          // if its a single file size then we see if that single file alone is within the provided size limit.
          this.multiFileCheckSize = this.multiSelectFile ? fileSizeInMegaBytes + this.multiFileCheckSize : fileSizeInMegaBytes;
          // checking file size and displaying the error if the sixe exceeds else it is sent to file generate function
          if(this.multiFileCheckSize > this.mbInSize){
            this.fileErrorMessage("Total file size exceeds the limit");
            this.multiFileCheckSize = this.multiFileCheckSize - fileSizeInMegaBytes;
          } else {
            filetotal.push(event[i]);
            filePresent = true;
          }
        } else {
          this.fileErrorMessage('File format unsupported');
        }
      }
      if(filePresent ===  true){
        this.fileGenerate({target: {files: filetotal}});
        filePresent = false;
      }
    }
    // This is to clear the selected files from file input so that it wont be blank on reselecting same file ( incase of deleting the currently uploaded file and reselecting / drag-dropping the same file)
    this.fileInput!.forEach((uploadingInput:any) => {
      if(uploadingInput.nativeElement.id == this.fileUploadId){
        uploadingInput.nativeElement.value = null;
      }
    });  
  }

  // displaying a error message on validating file
  fileErrorMessage(message:any){
    this.errorMessage = message;
    let timeOutToClear  = setTimeout(() => {
      this.errorMessage = '';
      clearTimeout(timeOutToClear);
    }, 5000);
  }

  // After all file sixe conditions, here the will be processed and sent to parent component
  fileGenerate(event:any){
    let fileArray: any = [];
    if(!this.multiSelectFile){
      fileArray = [];
      this.uploadReadyFiles = [];
    }

    Object.values(event.target.files).forEach((eachFile: any, index:number) => {
      if (eachFile && !this.multiSelectFile) {
        const reader = new FileReader();
        if(eachFile.type.includes('image')){
          // /* Code to get the uploaded file as ""BASE64""" */
          reader.readAsDataURL(eachFile);
          reader.onload = () => {
            fileArray.splice(index, 1,reader.result as string);
            if(this.isDevMode) console.log("Select ",reader.result);
            const fileExists = this.uploadReadyFiles.some((fileObj: any) => fileObj.file.name === eachFile.name);
            if(!fileExists){
              this.uploadReadyFiles.push({file:eachFile, url:this.sanitizer.bypassSecurityTrustResourceUrl(fileArray)});
              this.documentEmitter.emit("base64");
              this.fileNameData.emit(this.uploadReadyFiles);
              this.selectedFilesEmitter.emit(fileArray);
            }
            else {
              this.fileErrorMessage('File is already added');
            }
          };
          // reader.readAsDataURL(eachFile);
        } else {
          // /* Code to get the uploaded file as ""BLOB""" */
          const url = URL.createObjectURL(eachFile);
          const fileExists = this.uploadReadyFiles.some((fileObj: any) => fileObj.file.name === eachFile.name);
          if(!fileExists){
            this.uploadReadyFiles.push({file:eachFile, url:this.sanitizer.bypassSecurityTrustResourceUrl(url)});
            reader.onload = (event: any) => {
              const blob = new Blob([event.target.result], { type: eachFile.type });
              const formData = new FormData();
              formData.append('text', blob, eachFile.name);
              this.documentEmitter.emit("blob");
              this.fileNameData.emit(this.uploadReadyFiles);
              this.selectedFilesEmitter.emit(blob);
            };
            reader.readAsArrayBuffer(eachFile);
          }
          else {
            this.fileErrorMessage('File is already added');
          }
        }
      } 
      else if (this.multiSelectFile) {
        const url = URL.createObjectURL(eachFile);
        this.uploadReadyFiles.push({file:eachFile, url:this.sanitizer.bypassSecurityTrustResourceUrl(url)});
      };
    });

    if(this.multiSelectFile){
      this.fileNameData.emit(this.uploadReadyFiles);
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.errorMessage = '';
    // While removing form list, the file size will also be reducing from varable
    if(this.multiSelectFile && this.multiFileCheckSize > 0){
      const fileSizeInBytes = this.uploadReadyFiles[index].file.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMegaBytes = fileSizeInKB / 1024;
      this.multiFileCheckSize = this.multiFileCheckSize  - fileSizeInMegaBytes;
    } 
    this.uploadReadyFiles.splice(index, 1);
    this.documentDelete.emit(this.uploadReadyFiles);
  }
 }

