import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'searchFilter',
  standalone: true
})
export class SearchFilterPipe implements PipeTransform {


  //getting the array datas and search data from pipe element on every change
  transform(items: any[], searchText: string, ele?:any): any[] {
    if (!items) return [];
    if (!searchText) return items;
    return this.searchItems(items, searchText.toLowerCase(), ele);
  }
  // passing that data here, and if the search element included in that array, that element will be returned in new array
  private searchItems(items: any[], searchText: string, ele?:any): any[] {
    let results: any[] = [];
    items.forEach((data: any) => {
      if(ele){
        if (data[ele]?.toLowerCase().includes(searchText)) {
          results.push(data);
        }
      } else {
        if (data?.toLowerCase().includes(searchText)) {
          results.push(data);
        }
      }
    });
    return results;
  }
}