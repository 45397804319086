<form class="addTaxDeclarations" [formGroup]="addTaxDeclarations">
    <div class="row">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{ 'SEC_NAME' | customTranslate : 'Section Name' }}</label>
            <app-drop-down [lists]='sectionTypeList' tabindex="1" dropDownHeight="38px" dropDownWidth="100%"
            (dataEmit)="dropdownEmitFunction($event, 'sectionType')" [selectedData]="selectedSectionType">
            </app-drop-down>
            <!-- <div *ngIf="selectedSectionType === 'Other Investments and Excemptions' ">
<label class="form-label label-txt">Enter the section name</label>
                <input  type="text" class="form-control" tabindex="1" placeholder="Enter the section name"
                aria-label="Text input with dropdown button" formControlName="sectionName">
            </div> -->

        </div>
    </div>
    <div class="row" *ngIf="selectedSectionType !== otherInvestments && selectedSectionType !== otherIncome">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{ 'MAX_LIMIT_SECTION' | customTranslate : 'Maximum limit for this Section' }}</label>
            <div>
                <input type="text" class="form-control" tabindex="3" placeholder=""
                    aria-label="Text input with dropdown button" formControlName="maxSectionLimit">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{ 'PERSONAL_INFO.NAME' | customTranslate : 'Name' }}</label>
            <div *ngIf="selectedSectionType !== otherSection ">
                <div
                    [class]="addTaxDeclarations.controls['name'].invalid && addTaxDeclarations.controls['name'].touched  ? 'has-error' : '' ">
                    <app-drop-down [lists]='sectionNameList' tabindex="1" dropDownHeight="38px" dropDownWidth="100%"
                        (dataEmit)="dropdownEmitFunction($event, 'name')" selectedData="selectedName">
                    </app-drop-down>
                </div>
            </div>
            <div *ngIf="selectedSectionType === otherSection || selectedName === otherSection ">
                <div
                    [class]="addTaxDeclarations.controls['name'].invalid && addTaxDeclarations.controls['name'].touched  ? 'has-error' : '' ">
                    <input type="text" class="form-control" tabindex="2" placeholder="Enter the investment name"
                        aria-label="Text input with dropdown button" formControlName="name">
                </div>
            </div>
            
            <p class="error-msg"
                [style]="addTaxDeclarations.controls['name'].invalid && addTaxDeclarations.controls['name'].touched  ? 'display: block' : 'display: none'">
                <!-- RS27AUG24 -->
                <!-- Used the translate pipe to successfully implement the translation -->
                {{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{'MAX_AMOUNT' | customTranslate : 'Maximum Amount' }}</label>
            <div
                [class]="addTaxDeclarations.controls['maxAmount'].invalid && addTaxDeclarations.controls['maxAmount'].touched  ? 'has-error' : '' ">
                <input type="text" class="form-control" tabindex="3" placeholder=""
                    aria-label="Text input with dropdown button" formControlName="maxAmount">
            </div>
            <p class="error-msg"
                [style]="addTaxDeclarations.controls['maxAmount'].invalid && addTaxDeclarations.controls['maxAmount'].touched  ? 'display: block' : 'display: none'">
                {{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{'MIN_AMOUNT' | customTranslate : 'Minimum Amount' }}</label>
            <div
                [class]="addTaxDeclarations.controls['minAmount'].invalid && addTaxDeclarations.controls['minAmount'].touched  ? 'has-error' : '' ">
                <input type="text" class="form-control" tabindex="4" placeholder=""
                    aria-label="Text input with dropdown button" formControlName="minAmount">
            </div>
            <p class="error-msg"
                [style]="addTaxDeclarations.controls['minAmount'].invalid && addTaxDeclarations.controls['minAmount'].touched  ? 'display: block' : 'display: none'">
                {{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{'MAX_PER' | customTranslate : 'Maximum Percentage' }}</label>
            <div
                [class]="addTaxDeclarations.controls['maxPercent'].invalid && addTaxDeclarations.controls['maxPercent'].touched  ? 'has-error' : '' ">
                <input type="text" class="form-control" tabindex="5" placeholder=""
                    aria-label="Text input with dropdown button" formControlName="maxPercent">
            </div>
            <p class="error-msg"
                [style]="addTaxDeclarations.controls['maxPercent'].invalid && addTaxDeclarations.controls['maxPercent'].touched  ? 'display: block' : 'display: none'">
                {{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <label class="form-label label-txt">{{'MIN_PER' | customTranslate : 'Maximum Percentage' }}</label>
            <div
                [class]="addTaxDeclarations.controls['minPercent'].invalid && addTaxDeclarations.controls['minPercent'].touched  ? 'has-error' : '' ">
                <input type="text" class="form-control" tabindex="6" placeholder=""
                    aria-label="Text input with dropdown button" formControlName="minPercent">
            </div>
            <p class="error-msg"
                [style]="addTaxDeclarations.controls['minPercent'].invalid && addTaxDeclarations.controls['minPercent'].touched  ? 'display: block' : 'display: none'">
                {{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <mat-checkbox formControlName="active" tabindex="7"><p class="form-label label-txt mb-0">{{ 'MARK_AS_ACTIVE' | customTranslate : 'Mark this as Active' }}</p></mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-md-10 mt-4">
            <mat-checkbox formControlName="isExempted" tabindex="8"><p class="form-label label-txt mb-0">{{ 'UNDER_NEW_TAX' | customTranslate : 'Is this section excempted under New Tax Regime' }}</p></mat-checkbox>
        </div>
    </div>
    <div class="button-wrapper text-right mb-5 mt-3">
        <app-fusion-button [buttonType]="'text-only side-cancel-component'" [tabIndex]="8" [buttonValue]=" 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'"
            (buttonClickEmit)="addTaxDeclarations.reset();closeSideScreen.emit({'formData': '', 'activity': 'close'}); selectedSectionType = 'Select' ">
        </app-fusion-button>
        <app-fusion-button [buttonType]="'ms-2 side-save-component'" [tabIndex]="9"  buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}"
            (buttonClickEmit)="saveTaxDeclaration($event)" [buttonDisable]="duplicateName != '' ? true : false || !addTaxDeclarations.valid "></app-fusion-button>
    </div>

    <p *ngIf="duplicateName" class="error-msg">{{duplicateName}}</p>
</form>        










