<!-- <select (ngModel)="selectedYear">
  <option selected hidden type>
    Select Year {{selectedYear}}
  </option>
  <option *ngFor="let year of years" [value]="year">{{ year }}</option>
</select> -->
<!-- RS27AUG24 -->
<!-- Used the translate pipe to successfully implement the translation -->
  <div class="header-section">{{ 'LEAVE_HISTORY' | customTranslate : 'Leave History' }}
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'"  [buttonDisable]="downloadCurrentDisable" 
    (buttonClickEmit)="downloadCurrentEmployees()"
    [buttonValue]="'EXPORT' | customTranslate : 'Export'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
    </app-fusion-button>
  </div>
<app-data-table [data]="leaveHistoryData"
          [displayedColumns]="['leaveType', 'startDate', 'endDate','noOfDays', 'reason','appliedOn','status']"
          [headerLabels]="['Leave Type', 'Start Date', 'End Date','Days','Reason','Applied On','Status']"
          [translatePath]="'LEAVE_HISTORY_TABLE'"
          [fromWhere]="'leave'" (selectedValues)="tableCheckedDataa($event)"
          [withCheckBox]="true" 
          [actionButton]="true" [searchBar]="true" 
          (onEditData)="leaveRejectReason($event)" (OnDeleteData)="onCancel($event)" >
</app-data-table>
<!-- rejected reason model-->
<div class="modal fade container-md" id="rejectInfoModal" tabindex="-1" aria-labelledby="rejectInfoModal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header fs-5">
         <b>{{ 'LEAVE_REJECT_REASON' | customTranslate : 'Leave Rejection reason' }}</b> 
          </div>
        <div class="modal-body">
          <div>{{this.rejectReason}}</div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" class="btn btn-primary" aria-label="Close"  data-bs-dismiss="modal">{{ 'OK' | customTranslate : 'OK' }}
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
  <!--leave deletion model-->
  <!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="cancelLeave" tabindex="-1" aria-labelledby="cancelLeavelabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <b class="modal-title fs-5" id="cancelLeavelabel">{{ 'CANCEL_LEAVE' | customTranslate : 'Cancel leave' }}</b>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        {{ 'CANCEL_LEAVE_SURELY' | customTranslate : 'Are you sure want to cancel you leave ?' }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'NO' | customTranslate : 'NO' }}</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" (click)="leaveCancel()">{{ 'YES' | customTranslate : 'Yes' }}</button>
      </div>
    </div>
  </div>
</div>

<!-- here need to modify date formate and pass -->
<!-- <div class="card">
      <div class="header-section flex content-space-between"><h6><b>Leave History</b></h6>
<app-fusion-button [buttonType]="'custom-btn'" [fcBtnBgColor]="'#A7B1E933'" [buttonValue]="'Filter'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/filtericon.svg'"></app-fusion-button></div>
<hr>
 <table class="table" id="leavehistory"> 
   <thead> 
          <tr>
          <th>Leave Type</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Session</th>
          <th>Days</th>
          <th>Reason</th>
          <th>Applied On</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
         <tbody>
         <tr *ngFor="let user of  leave| paginate:{
          itemsPerPage:tableSize,
          currentPage:pageNumber}"> 

          <td>{{user.leaveType}}</td>
          <td>{{user.startDate | date:'mediumDate'}}</td>
          <td>{{user.endDate | date:'mediumDate'}}</td>
          <td>{{user.session}}</td>
          <td>{{user.noOfDays}}</td>
          <td>{{user.reason}}</td>
          <td>{{user.appliedOn }}</td>
          <td>{{user.status}}</td>
         <td class="action">
           <div  *ngIf="user.status === 'Pending'">
            <app-fusion-button  [buttonType]="'text-only'" [buttonValue]="'Cancel'" (click)="onCancel(user)"></app-fusion-button>
           </div>
            <div *ngIf="user.status === 'Rejected'">
            <app-fusion-button [buttonType]="'text-only'" [buttonValue]="'Comments'" (click)="leaveRejectReason(user.rejectionReason)" data-bs-toggle="modal"
              data-bs-target="#rejectInfoModal">
            </app-fusion-button>
          </div>
          </td>
        </tr>
        </tbody>
      </table>

  <div class="modal fade container-md" id="rejectInfoModal" tabindex="-1" aria-labelledby="rejectInfoModal"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header fs-5">
         <b>Leave Rejection Reason</b> 
          </div>
        <div class="modal-body">
          <div>{{this.rejectReason}}</div>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" class="btn btn-primary" aria-label="Close"  data-bs-dismiss="modal">OK
          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
  <br>
<div class="row" style="padding-left:10px ">
     Showing {{(pageNumber-1) * tableSize + 1}} to  {{((pageNumber-1) * tableSize + 1)+(tableSize-1)}} of {{totalEntries}} entries
 <div class="col">
             <ol class="pagination justify-content-end">
              <pagination-controls (pageChange)="renderPage($event)"> 
        </pagination-controls>
         </ol>
        </div>
  </div>
</div> -->
