import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterPipe } from 'src/app/pipes/search-filter/search-filter.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';


@Component({
  selector: 'app-search-filter',
  standalone: true,
  imports: [CommonModule, SearchFilterPipe, ReactiveFormsModule, FormsModule, CustomTranslatePipe],
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css']
})
export class SearchFilterComponent implements OnInit {
  filterCheckBox!: SelectionModel<any>;

  //emits the checked data in a array
  @Output() emitValues = new EventEmitter();
 

  /* getting the data to be listed in filter as a input
    The array should be in the format of
    (foe example)
      filterItems  = [
      'All', 'Admin', 'Accounts', 'Marketing', 'Sales', 'Human Resources', 'IT', 'Civil', 'Mech'
      ]
  */

//   @Input() payrollFilterData: any; 
//  @Input() activeFilterBox:any;
 @Input() filterItems: Array<any> = [];   
  selectedFilter: Array<any> = [];

  //MV24AUG24  Subscribes to active filter changes from GlobalValuesService and restores the checkbox state based on the received filterKeys and activeBox.
  constructor(private globalValues:GlobalValuesService) { 
    
    this.globalValues.activeFilterChange$.subscribe((data:any)=>{
         if(data.filterKeys&&data.activeBox){ 
        const savedCheckboxState = data.filterKeys[data.activeBox];
        if (savedCheckboxState) {
          this.filterCheckBox.clear()
          //const selectedItems = JSON.parse(savedCheckboxState);
          savedCheckboxState.forEach((item: any) => this.filterCheckBox.select(item));
        }else {
          Object.assign(data.filterKeys, { [data.activeBox]: [] })

        }
      }
    })
  }


  searchText: any;
  viewAll = false;


  ngOnInit(): void {
    this.filterCheckBox = new SelectionModel<any>(true, []);
    this.filterCheckBox.clear()
    
    //this.loadCheckboxState();//MV20AUG24 calling the loadedcheckboxstate
  }

  // Function for emitting the checked data in an array to its parent component
  checkedData(event: any) {
    // if (event) {
     // this.saveCheckboxState(); //MV20AUG24 Save state when checkbox is toggled
      this.emitValues.emit(this.filterCheckBox.selected);
  // }
  }
  //MV20AUG24 get the checkbox data and store as Json
  saveCheckboxState() {
    const selectedItems = this.filterCheckBox.selected;
    
  }
  //MV20AUG24 it will retain the checkboxstate from localstorage
  // loadCheckboxState() {
  //   if(this.payrollFilterData&&this.activeFilterBox){
  //     const savedCheckboxState = this.payrollFilterData[this.activeFilterBox];
  //     if (savedCheckboxState) {
  //       //const selectedItems = JSON.parse(savedCheckboxState);
  //       savedCheckboxState.forEach((item: any) => this.filterCheckBox.select(item));
  //     }
  //   }
    
  // }
  
}

