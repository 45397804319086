<div class="flex flex-direction-row full-size" style="background: #F8F9FA; font-family: 'Open Sans';
font-style: normal">
    <div class="content-section flex flex-direction-column flex-grow max-width-minus-side-nav" style="--grow-value: 1">
        <app-page-header (clickedNav)="sideNavChange($event)"></app-page-header>
        <div class="main-content" *ngIf="essTabToShow == 'details'">
            <!-- <div class="tab-heading sub-page-name">{{'HEADINGS.DETAILS' | customTranslate : 'Details'  }}</div> -->
            <div class="flex flex-direction-row tab-view-buttons">
                <ul class="nav nav-tabs fc-tabs width-100" id="essTabs" role="tablist">
                    <!-- SK25JUL24 hiding static tabs from custom configuration -->
                    <li  *ngIf="!hideStaticDetailsTabs?.includes('basic')"  class="nav-item" role="presentation">
                        <button class="nav-link active" id="basic-info-tab" data-bs-toggle="tab"
                            data-bs-target="#basic-info-tab-pane" type="button" role="tab"
                            aria-controls="basic-info-tab-pane" aria-selected="true">{{'HEADINGS.BASIC INFO' | customTranslate : 'Basic Info'  }}</button>
                    </li>
                    <li *ngIf="!hideStaticDetailsTabs?.includes('basic')"  class="nav-item" role="presentation">
                        <button class="nav-link" id="work-tab" data-bs-toggle="tab" data-bs-target="#work-tab-pane"
                            type="button" role="tab" aria-controls="work-tab-pane" aria-selected="false">{{'HEADINGS.WORK' | customTranslate : 'Work'  }}</button>
                    </li>
                    <li *ngIf="!hideStaticDetailsTabs?.includes('qualification')"  class="nav-item" role="presentation">
                        <button class="nav-link" id="education-tab" data-bs-toggle="tab"
                            data-bs-target="#education-tab-pane" type="button" role="tab"
                            aria-controls="education-tab-pane" aria-selected="false">{{'HEADINGS.QUALIFICATION' | customTranslate : 'Qualification'  }}</button>
                    </li>
                    <li *ngIf="!hideStaticDetailsTabs?.includes('experience')"  class="nav-item" role="presentation">
                        <button class="nav-link" id="experiences-tab" data-bs-toggle="tab"
                            data-bs-target="#experiences-tab-pane" type="button" role="tab"
                            aria-controls="experiences-tab-pane" aria-selected="false">{{'HEADINGS.EXPERIENCES' | customTranslate : 'Experiences'  }}</button>
                    </li>
                    <li *ngIf="!hideStaticDetailsTabs?.includes('document')"  class="nav-item" role="presentation">
                        <button class="nav-link" id="documents-tab" data-bs-toggle="tab"
                            data-bs-target="#documents-tab-pane" type="button" role="tab"
                            aria-controls="documents-tab-pane" aria-selected="false">{{'HEADINGS.DOCUMENTS' | customTranslate : 'Documents'  }}</button>
                    </li>
                    <li *ngIf="!hideStaticDetailsTabs?.includes('dependent')"  class="nav-item" role="presentation">
                        <button class="nav-link" id="dependents-tab" data-bs-toggle="tab"
                            data-bs-target="#dependents-tab-pane" type="button" role="tab"
                            aria-controls="dependents-tab-pane" aria-selected="false">{{'HEADINGS.DEPENDENTS' | customTranslate : 'Dependents'  }}</button>
                    </li>
                    <li *ngIf="!hideStaticDetailsTabs?.includes('bank')"  class="nav-item" role="presentation">
                        <button class="nav-link" id="bank-tab" data-bs-toggle="tab"
                            data-bs-target="#bank-tab-pane" type="button" role="tab"
                            aria-controls="bank-tab-pane" aria-selected="false">{{'HEADINGS.BANK_INFO' | customTranslate : 'Bank Information'  }}</button>
                    </li>
                </ul>
            </div>

            <!-- SK01APR24 reducing api call for ess -->
            <div class="tab-content" id="essTabContent">
                <div class="tab-pane fade show active" id="basic-info-tab-pane" role="tabpanel"
                    aria-labelledby="basic-info-tab" tabindex="0">
                    <app-basicinfotab [selectedUser]="selectedEmployee"></app-basicinfotab>
                </div>

                <div class="tab-pane fade" id="work-tab-pane" role="tabpanel" aria-labelledby="work-tab" tabindex="1">
                    <app-worktab [selectedUser]="selectedEmployee" [employeeFinanceInfo]="employeeFinanceInfo" [allEmployeeGet]="empGet"></app-worktab>
                </div>
                <div class="tab-pane fade" id="education-tab-pane" role="tabpanel" aria-labelledby="education-tab" tabindex="2">
                    <app-educationtab [selectedUser]="selectedEmployee"> </app-educationtab>
                </div>

                <div class="tab-pane fade" id="experiences-tab-pane" role="tabpanel" aria-labelledby="experiences-tab" tabindex="3">
                    <app-experiencetab [selectedUser]="selectedEmployee"> </app-experiencetab>
                </div>

                <div class="tab-pane fade" id="documents-tab-pane" role="tabpanel" aria-labelledby="documents-tab"
                    tabindex="4">
                    <app-document [selectedUser]="selectedEmployee"> </app-document>
                </div>

                <div class="tab-pane fade" id="dependents-tab-pane" role="tabpanel" aria-labelledby="dependents-tab"
                    tabindex="5">
                    <app-dependenttab [selectedUser]="selectedEmployee">  </app-dependenttab>
                </div>
                <div class="tab-pane fade" id="bank-tab-pane" role="tabpanel" aria-labelledby="bank-tab"
                  tabindex="6">
                    <app-bankinfotab [selectedUser]="selectedEmployee" [employeeFinanceInfo]="employeeFinanceInfo">  </app-bankinfotab>
                </div>
            </div>
        </div>

        <div class="main-content" *ngIf="essTabToShow == 'leaves'">
            <!-- <div class="tab-heading sub-page-name">{{'HEADINGS.LEAVES'| customTranslate : ''  }}</div> -->
            <div class="fc-tab-view">
                <div class="flex flex-direction-row fc-tab-buttons">
                    <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="first-tab" data-bs-toggle="tab"
                                data-bs-target="#applyleave-tab-pane" type="button" role="tab"
                                aria-controls="applyleave-tab-pane" aria-selected="true">{{'APPLY LEAVES'| customTranslate : 'Apply Leave'  }}</button>
                        </li>
                        <!-- <li class="nav-item" role="presentation">
                            <button class="nav-link" id="second-tab" data-bs-toggle="tab"
                                data-bs-target="#leavehistory-tab-pane" type="button" role="tab"
                                aria-controls="leavehistory-tab-pane" aria-selected="false">Leave
                                History</button>
                        </li> -->
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="third-tab" data-bs-toggle="tab"
                                data-bs-target="#holidays-tab-pane" type="button" role="tab"
                                aria-controls="holidays-tab-pane" aria-selected="false">{{'HOLIDAY DETAILS'| customTranslate : 'Holiday Details'  }}</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="fourth-tab" data-bs-toggle="tab"
                                data-bs-target="#weekoff-tab-pane" type="button" role="tab"
                                aria-controls="weekoff-tab-pane" aria-selected="false">{{'WEEK_OFF'| customTranslate : 'Week Off'  }}</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="fcTabContent">
                    <div class="tab-pane fade show active" id="applyleave-tab-pane" role="tabpanel"
                        aria-labelledby="first-tab" tabindex="0">
                        <app-apply-leaves [selectedUser]="selectedEmployee"   [employeeLeaves]="employeeLeaves"></app-apply-leaves>
                    </div>
                    <!-- <div class="tab-pane fade" id="leavehistory-tab-pane" role="tabpanel" aria-labelledby="second-tab"
                        tabindex="0">
                        <app-Leave-history> </app-Leave-history>

                    </div> -->
                    <div class="tab-pane fade" id="holidays-tab-pane" role="tabpanel" aria-labelledby="third-tab"
                        tabindex="0">
                        <app-holidaydetails [details]="holidays" [header]="['HOLIDAY' | customTranslate : 'Holiday' , 'DATE' | customTranslate : 'Date' ]"> </app-holidaydetails>
                    </div>
                    <div class="tab-pane fade" id="weekoff-tab-pane" role="tabpanel" aria-labelledby="fourth-tab"
                        tabindex="0">
                        <app-holidaydetails [details]="weekOff" [header]="['WEEK_OFF_TYPE' | customTranslate : 'Weekoff Type' , 'DAYS' | customTranslate : 'Days' ]"> </app-holidaydetails>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-content" *ngIf="essTabToShow == 'expenses'">
            <app-expenses-tab [selectedUser]="selectedEmployee" [expenseHistory]="employeeExpenses"  [employeeExpenses]="employeeExpenses"></app-expenses-tab> 
        </div>
        
        <div class="main-content" *ngIf="essTabToShow == 'payslips'">
            <!-- SK02APR24 payslips module content, payslips will be listed in table with preview and download options -->
            <div class="fc-tab-view">
                <div class="flex flex-direction-row fc-tab-buttons">
                    <ul class="nav nav-tabs fc-tabs" id="paySlipTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="payslip-first-tab" data-bs-toggle="tab"
                                data-bs-target="#payslip-tab-pane" type="button" role="tab"
                                aria-controls="payslip-tab-pane" aria-selected="true">{{'PAYSLIP' | customTranslate : 'Payslips' }} </button>
                                <!-- RS27AUG24 -->
                                <!-- Used the translate pipe to successfully implement the translation -->
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="fcTabContent">
                    <div class="card">
                        <div class="m-2">
                            <div class="tab-pane fade show active" id="payslip-tab-pane" role="tabpanel" aria-labelledby="first-tab" tabindex="0">
                                <app-data-table [data]="paySlipDatum" [displayedColumns]="['compensationId','month', 'netPay']" [headerLabels]="['Compensation Id','Payroll month', 'Net Pay']" [translatePath]="'PAYSLIP_TABLE'" fromWhere="common" [actionButton]="true" [viewIcon]="true" [downloadIcon]="true" (viewIconEmit)="viewIconEmit($event)" (downloadIconEmit)="downloadIconEmit($event)" [searchBar]="true"></app-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-content" *ngIf="essTabToShow == 'companypolicies'">
            {{'COMPANY_POLICIES' | customTranslate : 'Company Policies' }} 
        </div>

        <div class="main-content" *ngIf="essTabToShow == 'taxdeclarations'">
            <div class="fc-tab-view">
                <div class="flex flex-direction-row fc-tab-buttons justify-content-between">
                    <div>
                        <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
                            <!-- <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="comp-ess-tab-tab" data-bs-toggle="tab"
                                    data-bs-target="#comp-ess-tab-pane" type="button" role="tab"
                                    aria-controls="comp-ess-tab-pane" aria-selected="true">Compensation</button>
                            </li> -->
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="tax-ess-tab" data-bs-toggle="tab"
                                    data-bs-target="#tax-ess-tab-pane" type="button" role="tab"
                                    aria-controls="tax-ess-tab-pane" aria-selected="false" (shown.bs.tab)="getUserData()">{{'IT_DECLARATION'| customTranslate : 'IT Declaration'  }}</button>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <button class="nav-link" id="proof-ess-tab" data-bs-toggle="tab"
                                    data-bs-target="#proof-ess-tab-pane" type="button" role="tab"
                                    aria-controls="proof-ess-tab-pane" aria-selected="false">Proof Of Investments</button>
                            </li> -->
                        </ul>
                    </div>
                    <div>
                        <app-drop-down [lists]="fyList" dropDownWidth="200px" (dataEmit)="finYear($event)" [selectedData]="selectedFinancialYear"></app-drop-down>
                    </div>
                </div>
                <div class="tab-content w-85" id="fcTabContentTaxESS">
                    <div class="tab-pane fade" id="comp-ess-tab-pane" role="tabpanel"
                        aria-labelledby="first-tab" tabindex="0">
                        {{'COMPENSATION_ESS' | customTranslate : 'Compensation ESS' }}
                    </div>
                    <div class="tab-pane fade show active" id="tax-ess-tab-pane" role="tabpanel" aria-labelledby="second-tab"
                        tabindex="1">
                        <app-it-ess [financialYear]="selectedFinancialYear"></app-it-ess>
                    </div>
                    <div class="tab-pane fade" id="proof-ess-tab-pane" role="tabpanel" aria-labelledby="third-tab"
                        tabindex="2">
                       {{'PROOF_OF_IT' | customTranslate : 'Proof OF IT' }} 
                    </div>
                </div>
            </div>
        </div>

        <div class="main-content" *ngIf="essTabToShow == 'companyorgchart'">
            {{'COMPANY_ORG_CHART' | customTranslate : 'Company Org Chart' }} 
        </div>
    </div>
    <!-- <app-global-app-tabs style="width: 100px"></app-global-app-tabs> -->
</div>

<app-modal [modalTitle]="modalProp?.modalTitle" [isModalOpen]="openModal" [modalFooter]="false" (cancelButtonEmit)="modalClose($event)" [modalSize]="modalProp?.modalSize">
    <div class="modal-body">
      <div>
        <iframe *ngIf="payslipFile" class="w-100 h-100" [src]="payslipFile" style="height: 500px !important;"></iframe>
      </div>
    </div>
  </app-modal>