<div class="card" [ngClass]="{ 'rtl': isRtl }">
    <div class="loginPage ">
        <div class="bodySection flex">
            <div class="leftSection">
              <img src="../assets/images/icons/general/login-banner.png" class="leftImg" alt="" />
            </div>
            <div class="rightSection" [ngClass]="{ 'rtl': isRtl }">
                <div *ngIf="loginScreen" class="login">
                    <div class="imgSection">
                        <div *ngIf="shouldDisplayFusionAppLogo">
                            <img src="https://fusionobjects.s3.ap-south-1.amazonaws.com/iconone.png" alt="" class="logoImg">
                        </div>
                        <div>  
                            <app-drop-down 
                                [lists]="languageOptions" 
                                [selectedData]="currentLang"
                                dropDownWidth="90px"
                                dropBorderRadius="5px"
                                [objectDisplayKey]="'label'"
                                (dataEmit)="emittedValue($event)"
                                class="custom-dropdown-position">
                            </app-drop-down> 
                        </div>
                        
                        <!-- <div *ngIf="shouldDisplayValarhrAppLogo">
                            <img src="../assets/images/icons/general/Valarhr_Logo.png" class="logoImg" alt="" class="width-25"/>
                        </div> -->
                        <p class="logocontent pt-2"></p>{{ translations[currentLang].LOGIN_PAGE.WELCOME_TEXT }}
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="handleLoginClick()" style="transform: scale(0.8)">               
                    
                        <div class="login-form">
                                <!--- Add login Error message here -->
                            <p class="errorContent errcontentSize"><span
                                    *ngIf="errorMessage">{{errorMessage}}</span></p>
                            <div class="inputWithIcon">
                                <fieldset class="field-set">

                                    <label for="Username">{{ translations[currentLang].LOGIN_PAGE.USER_NAME }}
                                    </label>
                                    <div class="form-field inputUserNameIcon">

                                        <div class="login-input username  "
                                            [style]="errorMsg ? 'border-color:red' : ' ' ">
                                            <input type="email" class="input-box " formControlName="username"
                                                placeholder="Enter your email id" (focusout)="validateInput($event)"
                                                (keyup)="validateInput($event)" />
                                        </div>
                                    </div>
                                    <p class="errorContent">
                                        <span [style]="errorMsg ? 'visibility :visible' : 'visibility : hidden'">
                                            {{ translations[currentLang].LOGIN_PAGE.USER_NAME_ERROR }}</span>
                                    </p>
                                    <label for="pwd">{{ translations[currentLang].LOGIN_PAGE.PASSWORD }}</label>
                                    <div class="form-field inputPasswordIcon">
                                        <div class="login-input password"
                                            [style]="errorMsg ? 'border-color:red' : ' ' ">
                                            <input [type]="!passwordHide ? 'password' : 'text'"
                                                formControlName="password" [class]="passWorderror"
                                                placeholder="Enter your password"
                                                (focusout)="handlePasswordChange($event)"
                                                (keyup)="handlePasswordChange($event)" />
                                            <img (click)="passwordHide = !passwordHide"
                                                [src]="!passwordHide ? '../assets/images/icons/general/Hide-Password.svg' : '../assets/images/icons/general/Show-Password.svg'"
                                                class="HideIcon" alt="" />
                                        </div>
                                    </div>
                                    <p class="errorContent">
                                        <span
                                            [style]=" this.loginForm.controls['password'].invalid && this.loginForm.controls['password'].touched ? 'visibility :visible' : 'visibility : hidden'">Password
                                            {{ translations[currentLang].LOGIN_PAGE.PASSWORD_ERROR }}</span>
                                    </p>
                                </fieldset>
                            </div>
                            <div class="rememberMeSection">
                                <label class="lbl-remember-me align-item-center flex justify-center">
                                    <input class="rememberMeCheckBox" type="checkbox"
                                        (change)="handleRememberMeChange($event)" formControlName="rememberMe"
                                        [checked]="rememberMeChecked" />
                                    <span class="rememberMe cursor-pointer">{{ translations[currentLang].LOGIN_PAGE.REMEMBER_ME }}</span></label>
                            </div>
                            <div class="form-buttons form-button-section">
                                <button type="submit" class="login-button"
                                    [disabled]="!loginForm.valid">{{translations[currentLang].LOGIN_PAGE.LOGIN }}</button>
                                <p (click)=" loginScreen = !loginScreen; errorMessage='' "
                                    class="forgetPassword medium-font cursor-pointer">{{ translations[currentLang].LOGIN_PAGE.FORGOT_PASSWORD }} </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="!loginScreen" class="fp-forgotpwd">
                    <div class="show-reset-fields" *ngIf="showResetFields">
                        <!-- <div class="back-btn fp-text medium-font" style="text-decoration: underline; cursor: pointer" (click)="loginScreen = !loginScreen; errorMessage=''" >Go Back</div> -->
                        <div class="imgSection">
                            <div *ngIf="shouldDisplayFusionAppLogo">
                                <img src="https://fusionobjects.s3.ap-south-1.amazonaws.com/iconone.png" alt=""class="logoImg">
                              </div>
                              
                              <div *ngIf="shouldDisplayValarhrAppLogo">
                                <img src="../assets/images/icons/general/Valarhr_Logo.png" class="logoImg" alt=""class="width-25" />
                              </div>
                            <p class="logocontent pt-2"></p>
                        </div>
                        <div style="transform: scale(0.8)">
                        <div class="fp-header">{{ translations[currentLang].LOGIN_PAGE.PASSWORD_RESET }}</div>
                            <p class="fp-text medium-font">{{ translations[currentLang].LOGIN_PAGE.RESET_MAIL }}</p>
                            <form [formGroup]="fpForm" (ngSubmit)="sendPasswordLink()">
                                <div class="form-field inputUserNameIcon">
                                    <label for="Username">{{ translations[currentLang].LOGIN_PAGE.USER_NAME }}</label>
                                    <div class="login-input username">                                    
                                        <input type="email" formControlName="email" [class]="error"
                                            placeholder="Enter your Email" (focusout)="validateInput($event)"
                                            (keyup)="validateInput($event)" />
                                    </div>
                                </div>

                                <p class="errorContent">
                                    <span [style]="errorMsg ? 'visibility :visible' : 'visibility : hidden'">
                                        {{ translations[currentLang].LOGIN_PAGE.USER_NAME_ERROR }}</span>
                                </p>
                                <p class="errorContent errcontentSize">
                                    <span *ngIf="fpMailError">{{fpMailError}}</span>
                                </p>
                                <div class="form-buttons form-button-section">
                                    <button type="submit" class="login-button" [disabled]="!fpForm.valid">
                                        {{ translations[currentLang].LOGIN_PAGE.SEND_RESET_LINK }}</button>
                                    <p (click)=" loginScreen = !loginScreen; errorMessage='' "
                                        class="forgetPassword medium-font cursor-pointer">
                                        {{ translations[currentLang].LOGIN_PAGE.GO_BACK_TO_LOGIN }}</p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="show-reset-message" *ngIf="!showResetFields">
                        <div class="fp-header">{{ translations[currentLang].LOGIN_PAGE.EMAIL_SENT }}</div>
                        <p class="fp-message">{{ translations[currentLang].LOGIN_PAGE.CHECK_INBOX }} <span
                                class="italic-font">"{{fpForm.value.email}}"</span>
                        </p>
                        <div class="center-button text-right">
                            <app-fusion-button buttonValue="{{ translations[currentLang].LOGIN_PAGE.BACK}} "
                                [buttonType]="'text-only card-btn hide-detail-view text-dark'" [dataDismiss]="''"
                                (buttonClickEmit)="loginScreen = !loginScreen; showResetFields = true; fpForm.reset(); fpMailError=''; errorMsg= false "
                                [fcBtnId]="'myId'" data-bs-dismiss="modal"></app-fusion-button>

                            <app-fusion-button class="btn btn-link"
                                buttonValue="{{ translations[currentLang].LOGIN_PAGE.RESEND_LINK }}" [fcBtnId]="'modalSave'"
                                [buttonDisable]="buttonDisable" (buttonClickEmit)="sendPasswordLink()"
                                [buttonType]="'text-only border border-secondary-subtle bg-transparent'"
                                [fcBtnId]="'myId'"></app-fusion-button>
                        </div>
                    </div>
                </div>
            </div>
            <p class="app-version non-menu">{{currentApplicationVersion}}</p>
        </div>
    </div>
</div>
<!-- SK 9JAN24 loader component in login -->
<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>