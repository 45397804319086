import { Component, ErrorHandler, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from '../data-table/data-table.component';
import { NavTabsComponent } from 'src/app/nav-tabs/nav-tabs.component';
import { ApiService } from 'src/app/services/api.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { ModalComponent } from '../modal/modal.component';
import { AlertService } from 'src/app/services/alert.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { CardsComponent } from '../cards/cards.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
//RS27AUG24
//imported the TranslateModule and TranslatePipe from @ngx-translate/core to handle translations in the application
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-apps-list',
  standalone: true,
  imports: [
    CommonModule,
    DataTableComponent,
    NavTabsComponent,
    FusionButtonComponent,
    ModalComponent,
    ReactiveFormsModule,
    CardsComponent,
    AngularSvgIconModule,
    TranslateModule,
    CustomTranslatePipe
  ],
  templateUrl: './apps-list.component.html',
  styleUrls: ['./apps-list.component.css']
})
export class AppsListComponent implements OnInit {
  tableData:any; // SK13MAR24 appslist table data
  tabsLists : Array<any> = []; //SK13MAR24 to assign navtabs list
  activeTab : string = ''; //SK13MAR24 to assign active tabs
  activeSubTab : string = ''; //SK1320MAR24 to assign active sub tabs
  tabContent :any; //SK13MAR24 active tab's content 
  subTabContent : any; //SK13MAR24 active tab's content 
  newAppbutton = false; //SK13MAR24 button condition fo creating 'New App'
  openModal = false; // SK13MAR24 open modal on clicking 'New App' button
  newModulebutton = false; //SK13MAR24 button condition fo creating 'New Module'
  openModuleModal = false; // SK13MAR24 open modal on clicking 'New Module' button
  newTabbutton = false; //SK21MAR24 button condition fo creating 'New Tab'
  openTabModal = false; // SK21MAR24 open modal on clicking 'New Tab' button
  newAppForm!: FormGroup; // SK13MAR24 form declaration for new App
  newModuleForm!: FormGroup; // SK13MAR24 form declaration for new module
  newTabForm!: FormGroup; // SK21MAR24 form declaration for new module
  loadingSpinner = false; // SK13MAR24 loading spinner 
  appsData:any; // SK13MAR24 storing apps list
  moduleData:any; // SK13MAR24 storing modules list
  tabData:any; // SK13MAR24 storing tabs list
  errorMessage = ''; // SK13MAR24

  constructor(private apiService: ApiService, private globalValues: GlobalValuesService, private fb: FormBuilder, private alertService:AlertService, private errorHandler:ErrorHandlerService) { 
    // SK13MAR24 form for apps
    this.newAppForm = this.fb.group({
      name: ["", Validators.required],
      label: ["", Validators.required],
      appName:["", Validators.required],
      icon: [""],
      modules: [[]],
      default: [false],
      showInLauncher: [false],
      showTracker : [false],
      type:[""]
    });

    // SK13MAR24 form for modules
    this.newModuleForm = this.fb.group({
      name: ["", Validators.required],
      label: ["", Validators.required],
      icon: [""],
      order: [],
      tabs:[[]],
      _id:[null]
    });

    // SK21MAR24 form for tabs
    this.newTabForm = this.fb.group({
      name: ["", Validators.required],
      label: ["", Validators.required],
      order: [],
    });

    // SK13MAR24 default declarations of apps, modules and tabs table datas
    this.tableDataDeclaration();
    this.moduleDataDeclaration();
    this.tabDataDeclaration();

  }

  ngOnInit(): void {
    this.initialData();
  }

  // SK13MAR24 fetching the navtabs list initially
  initialData(){
    this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || [{name:'All Apps', value:'allApps', browsedTab:false}];
    this.activeTab = sessionStorage.getItem('activeNavTab') as string || this.tabsLists[0].value;
    this.activeSubTab = sessionStorage.getItem('activeNavSubTab') as string || "";
    const tab = this.tabsLists.filter((tabActive:any)=> tabActive.value === this.activeTab);
    this.apiService.fetchValue("/settings/apps").subscribe((data: any) => {
      this.appsData = data;
      this.emitTabData({data:tab[0], allData:this.tabsLists});
    });
  }

  // SK13MAR24 app declaraation
  tableDataDeclaration(){
    this.tableData = {
      "name":"",
      "label":"", 
      "config": {
        "data":[],
        "withCheckBox": true,
        "searchBar": true,
        "fromWhere": "common",
        "isButtons": true,
        "actionButton":true,
        "isEditRow":true,
        "isDeleteRow":true,
        "headerLabels": [
          "Name",
          "Label",
          "Show In Launcher",
          "Type"
        ],
        "displayedColumns": [
          "name",
          "label",
          "showInLauncher",
          "type"
        ],
        "hyperLinkColumns": [
          "name",
        ],
        "buttons": [
          {
            "name": "New App",
            "class": "btn btn-primary",
            "buttonType": "newRecord",
            "buttonAction": "openModal"
          }
        ]
      }
    }    
  }

  // SK13MAR24 module declartion
  moduleDataDeclaration(){
    this.moduleData = {
      "name": "",
      "label": "",
      "type": "table",
      "browsedTab":true,
      "config": {
        "data":[],
        "searchBar": true,
        "headerLabels": [
          "Name",
          "Label",
          "Order",
          "Icon"
        ],
        "displayedColumns": [
          "name",
          "label",
          "order",
          "icon"
        ],
        "hyperLinkColumns": [
          "name",
        ],
        "buttons": [
          {
            "name": "New Module",
            "class": "btn btn-primary",
            "buttonType": "newRecord",
            "buttonAction": "openModuleModal"
          }
        ],
        "fromWhere": "common",
        "isButtons": true,
        "withCheckBox": true,
        "isEditRow": true,
        "isDeleteRow": true,
        "actionButton": true,
      }
    };  
  }

  //  SK13MAR24 tab declaration
  tabDataDeclaration(){
    this.tabData = {
      "name": "",
      "label": "",
      "type": "table",    
      "config": {
        "data":[],
        "searchBar": true,
        "headerLabels": [
          "Name",
          "Label",
          "Order"
        ],
        "displayedColumns": [
          "name",
          "label",
          "order"
        ],
        "buttons": [
          {
            "name": "New Tab",
            "class": "btn btn-primary",
            "buttonType": "newRecord",
            "buttonAction": "openTabModal"
          }
        ],
        "fromWhere": "common",
        "isButtons": true,
        "withCheckBox": true,
        "isEditRow": true,
        "isDeleteRow": true,
        "actionButton": true,
      }
    };
  }

  // SK13MAR24 table button emit
  commonButtonClickEmit(event:any){
    // SK13MAR24 based on condition, opening the apps/module/tabs modal
    if(event.buttonAction === "openModal"){
      this.newAppbutton = true;
      this.openModal = true;
    } else if(event.buttonAction === "openModuleModal") {
      this.openModuleModal = true;
      this.newModulebutton = true;
    } else if(event.buttonAction === "openTabModal") {
      this.openTabModal = true;
      this.newTabbutton = true;
    }
  }

  // SK13MAR24 on clicking apps hyperlink
  hyperLinkColumnEmit(event:any){
    if(Object.keys(event.element).includes("modules")){
      const tab = {
        name: event.element.label,
        value: event.element.name,
        browsedTab: true,
        subTabs:[{name:"All Modules", value:"allModules", type:"subTab" }],
        activeSubTab:"allModules"
      }
      this.emitTabData({data:tab, allData:this.tabsLists, element:event.element, activeSubTab: "allModules"});
    } else if(Object.keys(event.element).includes("tabs")){
      this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || this.tabsLists;
      const filterTabIndex = this.tabsLists.findIndex((tab1:any)=>tab1.value === this.activeTab)
      const tab = {
        name: event.element.label,
        value: event.element.name,
        browsedTab: true,
        type: "subTab"
      }
      if(filterTabIndex >= 0) this.tabsLists[filterTabIndex].subTabs.push(tab);
      this.tabsLists[filterTabIndex].activeSubTab = event.element.name;
      this.emitSubTabData({data:tab, tabData:this.tabsLists, activeTab:this.activeTab, activeSubTab: this.activeSubTab});
    }
  }

  // SK13MAR24 selecting the tabs
  emitTabData(event:any){
    this.activeTab = event.data.value;
    this.tabContent = event.data;
    // SK13MAR24 if 'appsData' has no data, fetching from server, else assigning it directly
    if(!this.appsData){
      this.apiService.fetchValue("/settings/apps").subscribe((data: any) => {
        this.appsData = data;
        // SK13MAR24 event.element will get defined on clicking hyperlink only
        if(event.element === undefined){
          // SK13MAR24 All Apps tab selected, displaying tabs list, else module list shown
          if(event.data.value === "allApps"){   
            this.tableData.config.data = this.appsData;
          } else {
            this.appsDetailsGet(event);
          }
        } 
      })
    } else {
      if(event.element === undefined){
        if(event.data.value === "allApps"){   
          this.tableData.config.data = this.appsData;
        } else {
          this.appsDetailsGet(event);
        }
      } else {
        this.appsDetailsGet(event);
      }
    }
  }

  emitSubTabData(event:any){
    this.tabContent = event.data;
    this.activeSubTab = event.data.value;
    const tab = this.appsData.filter((app:any)=> app.name === event.activeTab);
    if(event.data.value === "allModules"){
      this.moduleData.name = event.data.name;
      this.moduleData.label = event.data.value;
      this.moduleData.config.data = tab[0].modules.sort((a:any, b:any) => a.order - b.order);
    } else {
      this.tabData.name = event.data.label;
      this.tabData.value = event.data.name;
      const mods = tab[0].modules.sort((a:any, b:any) => a.order - b.order);
      const modFilter = mods.filter((mod:any)=>mod.name === event.data.value);
      this.tabData.config.data = modFilter[0].tabs;
    }
    this.tabsLists = event.tabData || JSON.parse(sessionStorage.getItem('navTabsList') as any) ;
    const filterTab = this.tabsLists.filter((tab1:any)=>tab1.value === this.activeTab);
    const sub = filterTab[0].subTabs.filter((subTab:any)=> subTab.value === event.data.value);
    if(sub.length === 0)filterTab[0].subTabs.push(event.data);
    this.globalValues.navTabData({tabsList:this.tabsLists, hideTabList:[], activeTab:this.activeTab, activeSubTab:event.data.value});
  }

  // SK13MAR24 getting modules list
  appsDetailsGet(event:any){
    const tab = this.appsData.filter((app:any)=> app.name === event.data.value);
    this.moduleData.config.data = tab[0].modules.sort((a:any, b:any) => a.order - b.order);
    this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || this.tabsLists;
    const filterTab = this.tabsLists.filter((tab1:any)=>tab1.value === event.data.value);
    if(filterTab.length === 0)this.tabsLists.push(event.data);
    // const subTabActive = sessionStorage.getItem('activeNavSubTab') as string|| event.data.subTabs[0].value;
    const subTabActive = filterTab[0]?.activeSubTab ||  event.data.subTabs[0].value;
    // const subTabActive = event.data.subTabs[0].value ||   filterTab[0].activeSubTab;
    const subTab = event.data.subTabs.filter((st:any)=>st.value === subTabActive);
    this.globalValues.navTabData({tabsList:this.tabsLists, hideTabList:[], activeTab:event.data.value, activeSubTab:this.activeSubTab});

    if(event.data.subTabs){
      this.emitSubTabData({data:subTab[0], tabData:this.tabsLists, activeTab:this.activeTab, activeSubTab: subTabActive});
    } 
  }

  // SK13MAR24 closing the modal
  modalClose(event: any) {
    this.openModal = false;
    this.newAppForm.reset();

    this.openModuleModal = false;
    this.newModuleForm.reset();

    this.openTabModal = false;
    this.newTabForm.reset();
  }

  // SK13MAR24 post or patch the data
  updateData(type:string, from:string){
    // SK13MAR24 based on conditions, the apps/modules updated
    const tabs = this.tabsLists.filter((tab:any)=> tab.value === this.activeTab);
    this.newAppForm.patchValue({
      "modules" : this.newAppForm.value?.modules || []
    }) 
    if(from === "app"){
      if(this.newAppForm.value.modules?.length > 0)this.newAppForm.value.modules.sort((a:any, b:any) => a.order - b.order);
      this.loadingSpinner = true;
      this.apiService.writeValue(type, "/settings/apps", this.newAppForm.value).subscribe({
        next:(success:any)=>{
          this.saveSuccess(success.message);
        },
        error:(error:any)=>{
          this.showError(error);
        }
      })
    } else if(from === "module"){
      this.newModuleForm.patchValue({
        "tabs" : this.newModuleForm.value?.tabs || []
      }) 
      if(this.newModuleForm.value?.tabs?.length > 0)this.newModuleForm.value?.tabs?.forEach((element:any) => {
        delete element.editRow;
      });
      if(this.newModuleForm.value?.tabs?.length > 0)this.newModuleForm.value.tabs.sort((a:any, b:any) => a.order - b.order);
      this.loadingSpinner = true;
      this.apiService.writeValue(type, "/settings/apps/"+tabs[0].value+"/modules", this.newModuleForm.value).subscribe({
        next:(success:any)=>{
          this.saveSuccess(success.message);
        },
        error:(error:any)=>{
          this.showError(error);
        }
      })      
    } else if(from === "tab"){
      if(this.tabData.config.data.length > 0)this.tabData.config.data.sort((a:any, b:any) => a.order - b.order);
      const subTab = tabs[0].subTabs.filter((sub:any)=> sub.value === this.activeSubTab);
      const ind = this.tabData.config.data.findIndex((sub:any)=> sub.name === this.newTabForm.value.name);
      if(ind >= 0){
        this.tabData.config.data[ind]["name"] = this.newTabForm.value.name;
        this.tabData.config.data[ind]["label"] = this.newTabForm.value.label;
        this.tabData.config.data[ind]["order"] = this.newTabForm.value.order;
        delete this.tabData.config.data[ind].editRow
      } else {
        this.tabData.config.data.push({name:this.newTabForm.value.name, label:this.newTabForm.value.label, order:this.newTabForm.value.order});
      }
      const data ={
        name:subTab[0].value,
        tabs:this.tabData.config.data
      }
      this.loadingSpinner = true;
      this.apiService.writeValue(type, "/settings/apps/"+tabs[0].value+"/modules", data).subscribe({
        next:(success:any)=>{
          this.saveSuccess(success.message);
        },
        error:(error:any)=>{
          this.showError(error);
        }
      })      
    }
  }

  // SK13MAR24 on clicking edit from the, assiging the values to respective forms, based on condition
  onEditRecord(event:any){
    if(Object.keys(event.data).includes("modules")){
      this.newAppForm.patchValue({
        name: event.data.name,
        label: event.data.label,
        type: event.data.type,
        icon: event.data.icon,
        modules:event.data.modules,
        appName: event.data.appName,
        default: event.data.default,
        showInLauncher: event.data.showInLauncher,
        showTracker: event.data.showTracker
      })
      this.newAppbutton = false;
      this.openModal = true;
    } else if(Object.keys(event.data).includes("tabs")) {
      this.newModuleForm.patchValue({
        name: event.data.name,
        label: event.data.label,
        icon: event.data.icon,
        order:event.data.order,
        tabs:event.data.tabs || [],
        _id:event.data._id
      })
      this.newModulebutton = false;
      this.openModuleModal = true;
    } else {
      this.newTabForm.patchValue({
        name: event.data.name,
        label: event.data.label,
        order:event.data.order,
      })
      this.newTabbutton = false;
      this.openTabModal = true;
    }
  }

  onDeleteRecord(event:any){
    // SK13MAR24 should implement delete for apps and modules
  }

  // SK13MAR24 deleting tab, i.e. inline delete
  onDeleteTab(event:any, from:any){
    if(from === "tab"){
      const tabs = this.tabsLists.filter((tab:any)=> tab.value === this.activeTab);
      const subTab = tabs[0].subTabs.filter((sub:any)=> sub.value === this.activeSubTab);
      const ind = this.tabData.config.data.findIndex((sub:any)=> sub.name === event.data.name);
      this.tabData.config.data.splice(ind,1);
      const data ={
        name:subTab[0].value,
        tabs:this.tabData.config.data
      }
      this.apiService.writeValue("patch", "/settings/apps/"+tabs[0].value+"/modules", data).subscribe({
        next:(success:any)=>{
          this.saveSuccess(success.message);
        },
        error:(error:any)=>{
          this.showError(error);
        }
      })
    }
  }

  saveTableData(event:any){
  }

  // SK13MAR24 on success response from server
  saveSuccess(data: any) {
    this.alertService.messageOnPass('success', data)
    this.initialData();
    this.modalClose("event")
    this.errorMessage = '';
    this.loadingSpinner = false;
  }

  // SK13MAR24 on error response from server
  showError(err: any) {
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', err);
    this.loadingSpinner = false;
  }
}