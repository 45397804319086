<div class="d-flex flex-row">
    <div class="card p-2 w-25" *ngIf="!editTemplate">
        <div *ngFor="let list of formatList" class="card cursor-pointer rounded-0 p-2 m-2 medium-font list-background"
            [ngClass]="list.switchCase === activeList ? 'bg-primary text-light' : '' "
            (click)="clickedFormat(list.switchCase)">
            {{list.name}} {{list.switchCase === defaultTemplate ? '(Default)' : ''}}
        </div>
    </div>
    
    <div class="card ms-4 w-75 template" [ngSwitch]="activeList">
        <div class="position-relative">
            <div *ngIf="!editTemplate" class="text-end mt-2 position-absolute" [ngClass]="!editTemplate ? 'hide-preview-button' : ''" style="left: 80%">
                <app-fusion-button [buttonType]="defaultTemplate !== activeList ? 'visible' : 'invisible' " buttonValue="{{'Set as Default' | customTranslate : 'Set as Default'}}" (buttonClickEmit)="saveShowFields()"></app-fusion-button>
                <svg-icon class="ms-2" src="../assets/images/icons/general/pencil-icon.svg" (click)="editClick()"></svg-icon>
            </div>
            <div class="flex flex-column p-4" *ngSwitchCase="'default template'">
                <div class="flex flex-row justify-content-center">
                    <svg-icon class="ps-2 pe-4" src="../assets/images/icons/general/logo-payslip.svg"></svg-icon>
                    <div class="text-center ps-4 pe-2 align-self-center">
                        <div class="medium-font">{{formatData.company.companyName}}</div>
                        <div class="medium-font">{{formatData.company.address}}</div>
                    </div>
                </div>
                <div class="border-bottom border-dark mt-4"></div>
                <div class="mt-4 flex flex-row justify-content-between">
                    <div class="flex flex-column">
                        <div class="mb-2">
                            {{formatData.employeePayDetails.payslipMonth}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.summary}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.name}}</div>
                        <div *ngIf="accessData.department" class="mb-2">
                            {{formatData.employeePayDetails.department}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.designation}}</div>
                        <div *ngIf="accessData.workLocation" class="mb-2">
                            {{formatData.employeePayDetails.workLocation}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.doj}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.payPeriod}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.payDate}}</div>
                        <div *ngIf="accessData.pan" class="mb-2">
                            {{formatData.employeePayDetails.pan}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.pf}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.uan}}</div>
                        <div class="mb-2">
                            {{formatData.employeePayDetails.tds}}</div>
                    </div>
                    <div class="p-5">
                        <div class="card rounded-0 border-dark netpay">
                            <div class="p-4 text-center">
                                <div>{{formatData.payDetails.netPay.name}}</div>
                                <div class="fw-semibold">{{formatData.payDetails.netPay.value}}</div>
                                <div>
                                    <span *ngIf="accessData.workingDays">
                                        {{formatData.employeePayDetails.paidDays}}</span>
                                    <span *ngIf="accessData.workingDays && accessData.lossOfPay" > | </span>
                                    <span *ngIf="accessData.lossOfPay">{{formatData.employeePayDetails.lop}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="border-bottom border-dark mt-4"></div>
                <div class="mt-4  ps-2 pe-2">
                    <table>
                        <tbody>
                            <tr>
                                <td width="2%" style="padding: 0px;" valign="top">
                                    <table cellspacing="0" cellpadding="0" width="100%"></table>
                                </td>
                                <td>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="fw-semibold">{{'EARNINGS' | customTranslate : 'Earnings' }}</th>
                                                <th class="text-end fw-semibold">{{'AMOUNT' | customTranslate : 'Amount' }}</th>
                                                <th *ngIf="accessData.ytd" class="text-end fw-semibold">{{'YTD' | customTranslate : 'YTD' }}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let earning of formatData.payDetails.components.earnings">
                                            <tr>
                                                <td>{{earning.name}}</td>
                                                <td class="text-end">{{earning.value}}</td>
                                                <td *ngIf="accessData.ytd" class="text-end">{{earning.ytd}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td width="2%" style="padding: 0px 15px;" valign="top">
                                    <table cellspacing="0" cellpadding="0" width="100%"></table>
                                </td>
                                <td style="padding: 0px;" valign="top" width="47%">
                                    <table cellspacing="0" cellpadding="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th class="fw-semibold">{{'DEDUCTIONS' | customTranslate : 'Deductions' }}</th>
                                                <th class="text-end fw-semibold">{{'AMOUNT' | customTranslate : 'Amount' }}</th>
                                                <th *ngIf="accessData.ytd" class="text-end fw-semibold">{{'YTD' | customTranslate : 'YTD' }}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let deduction of formatData.payDetails.components.deductions">
                                            <tr>
                                                <td>{{deduction.name}}</td>
                                                <td class="text-end">{{deduction.value}}</td>
                                                <td *ngIf="accessData.ytd" class="text-end">{{deduction.ytd}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td width="2%" style="padding: 0px;" valign="top">
                                    <table cellspacing="0" cellpadding="0" width="100%"></table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="border-bottom border-dark mt-2"></div>
                <div class="ps-2 pe-2">
                    <table>
                        <tbody>
                            <tr>
                                <td width="2%" style="padding: 0px;" valign="top">
                                    <table cellspacing="0" cellpadding="0" width="100%"></table>
                                </td>
                                <td>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th class="fw-semibold">{{formatData.payDetails.grossEarning.name}}</th>
                                                <th class="text-end fw-semibold">
                                                    {{formatData.payDetails.grossEarning.value}}</th>
                                                <th *ngIf="accessData.ytd" class="text-end fw-semibold">{{formatData.payDetails.grossEarning.ytd}}</th>
    
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                                <td width="2%" style="padding: 0px 15px;" valign="top">
                                    <table cellspacing="0" cellpadding="0" width="100%"></table>
                                </td>
                                <td style="padding: 0px;" valign="top" width="47%">
                                    <table cellspacing="0" cellpadding="0" width="100%">
                                        <thead>
                                            <tr>
                                                <th class="fw-semibold">{{formatData.payDetails.totalDeductions.name}}</th>
                                                <th class="text-end fw-semibold">
                                                    {{formatData.payDetails.totalDeductions.value}}</th>
                                                <th *ngIf="accessData.ytd" class="text-end fw-semibold">{{formatData.payDetails.totalDeductions.ytd}}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                                <td width="2%" style="padding: 0px;" valign="top">
                                    <table cellspacing="0" cellpadding="0" width="100%"></table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="ps-2 pe-2 flex flex-row justify-content-between mt-4">
                    <div class="fw-semibold">{{'REIMBURSEMENTS' | customTranslate : 'REIMBURSEMENTS' }}</div>
                    <div class="fw-semibold">{{'AMOUNT'| customTranslate : 'AMOUNT' }}</div>
                </div>
                <div class="ps-2 pe-2 flex flex-column mt-2">
                    <div *ngFor="let reim of formatData.payDetails.components.reimbursements" class="flex flex-column">
                        <div class="flex flex-row justify-content-between">
                            <div>{{reim.name}}</div>
                            <div>{{reim.value}}</div>
                        </div>
                    </div>
                </div>
                <div class="border-bottom border-dark mt-2"></div>
                <div class="ps-2 pe-2 flex flex-row justify-content-between mt-2">
                    <div class="fw-semibold">{{formatData.payDetails.totalReimbursements.name}}</div>
                    <div class="fw-semibold">{{formatData.payDetails.totalReimbursements.value}}</div>
                </div>
                <div class="ps-2 pe-2 flex flex-row justify-content-between mt-4">
                    <div class="fw-semibold">{{formatData.payDetails.netPay.name}}(({{'Gross Earnings'| customTranslate : 'Gross Earnings' }} - {{'TDS'| customTranslate : 'TDS' }}) + {{'TOTAL_REIMBURSEMENT'| customTranslate : 'Total Reimbursement' }})</div>
                    <div class="fw-semibold">{{formatData.payDetails.netPay.value}}</div>
                </div>
                <div class="mt-4 text-center fw-semibold">
                    {{'TOTAL_NET_PAY' | customTranslate : 'Total Net Payable' }} {{formatData.payDetails.netPay.value}} ({{formatData.payDetails.netPayInWords}})
                </div>
            </div>
            <div class=" flex flex-column p-4" *ngSwitchCase="'template 1'">
                <div>
                    <div class="mt-4">
                        <div class="flex flex-row justify-content-between">
                            <svg-icon class="ps-2 pe-4" src="../assets/images/icons/general/logo-payslip.svg"></svg-icon>
                            <div class="align-self-end text-end">
                                <b>{{formatData.company.companyName}}</b>
                                <p>{{formatData.company.address}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom border-dark mt-4"></div>
                    <div class="mt-2">
                        <div class="flex flex-row align-items-center">
                            <div class="flex flex-column w-50">
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.payslipMonth}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.summary}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.name}}</div>
                                <div *ngIf="accessData.department" class="mb-2">
                                    {{formatData.employeePayDetails.department}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.designation}}</div>
                                <div *ngIf="accessData.workLocation" class="mb-2">
                                    {{formatData.employeePayDetails.workLocation}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.doj}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.payPeriod}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.payDate}}</div>
                                <div *ngIf="accessData.pan" class="mb-2">
                                    {{formatData.employeePayDetails.pan}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.pf}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.uan}}</div>
                                <div class="mb-2">
                                    {{formatData.employeePayDetails.tds}}</div>
                            </div>
                            <div  class="flex flex-column w-50 align-items-center">
                                <div class="big-font">{{formatData.payDetails.netPay.name}}</div>
                                <div class="fs-3 mt-3 fw-semibold">{{formatData.payDetails.netPay.value}}</div>
                                <div class="mt-3">
                                    <span *ngIf="accessData.workingDays">
                                        {{formatData.employeePayDetails.paidDays}}</span>
                                    <span *ngIf="accessData.workingDays && accessData.lossOfPay" > | </span>
                                    <span *ngIf="accessData.lossOfPay">{{formatData.employeePayDetails.lop}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-bottom border-dark mt-2"></div>
                    <div class="mt-3">
                        <div class="flex flex-row justify-content-between">
                            <div class="fw-semibold w-50">{{'EARNINGS' | customTranslate : 'Earnings' }}</div>
                            <div class="fw-semibold text-end" [ngClass]="accessData.ytd ? 'w-25' : 'w-50' ">{{'AMOUNT' | customTranslate : 'Amount' }}</div>
                            <div *ngIf="accessData.ytd" class="fw-semibold w-25 text-end">YTD</div>
                        </div>
                        <div *ngFor="let earning of formatData.payDetails.components.earnings" class="flex flex-row justify-content-between mt-2">
                            <div class=" w-50">{{earning.name}}</div>
                            <div class="text-end" [ngClass]="accessData.ytd ? 'w-25' : 'w-50' ">{{earning.value}}</div>
                            <div *ngIf="accessData.ytd" class=" w-25 text-end">{{earning.ytd}}</div>
                        </div>
                        <div class="flex flex-row justify-content-between mt-3">
                            <div class="fw-semibold w-50">{{formatData.payDetails.grossEarning.name}}</div>
                            <div class="fw-semibold text-end" [ngClass]="accessData.ytd ? 'w-25' : 'w-50' ">{{formatData.payDetails.grossEarning.value}}</div>
                            <div *ngIf="accessData.ytd" class="fw-semibold w-25 text-end">{{formatData.payDetails.grossEarning.ytd}}</div>
                        </div>
                    </div>
                    <div class="border-bottom border-dark mt-3"></div>
                    <div class="mt-3">
                        <div class="flex flex-row justify-content-between">
                            <div class="fw-semibold w-50">{{'DEDUCTIONS' | customTranslate : 'Deductions' }}</div>
                            <div class="fw-semibold text-end" [ngClass]="accessData.ytd ? 'w-25' : 'w-50' ">{{'AMOUNT' | customTranslate : 'Amount' }}</div>
                            <div *ngIf="accessData.ytd" class="fw-semibold w-25 text-end">YTD</div>
                        </div>
                        <div *ngFor="let deduction of formatData.payDetails.components.deductions" class="flex flex-row justify-content-between mt-2">
                            <div class=" w-50">{{deduction.name}}</div>
                            <div class="text-end" [ngClass]="accessData.ytd ? 'w-25' : 'w-50' ">{{deduction.value}}</div>
                            <div *ngIf="accessData.ytd" class=" w-25 text-end">{{deduction.ytd}}</div>
                        </div>
                        <div class="flex flex-row justify-content-between mt-3">
                            <div class="fw-semibold  w-50">{{formatData.payDetails.totalDeductions.name}}</div>
                            <div class="fw-semibold text-end" [ngClass]="accessData.ytd ? 'w-25' : 'w-50' ">{{formatData.payDetails.totalDeductions.value}}</div>
                            <div *ngIf="accessData.ytd" class="fw-semibold w-25 text-end">{{formatData.payDetails.totalDeductions.ytd}}</div>
                        </div>
                    </div>
                    <div class="border-bottom border-dark mt-3"></div>

                    <div class="mt-3">
                        <div class="flex flex-row justify-content-between">
                            <div class="fw-semibold">{{'REIMBURSEMENTS' | customTranslate : 'Reimbursements' }}</div>
                            <div class="fw-semibold">{{'AMOUNT' | customTranslate : 'Amount' }}</div>
                        </div>
                        <div *ngFor="let reimbursement of formatData.payDetails.components.reimbursements" class="flex flex-row justify-content-between mt-2">
                            <div>{{reimbursement.name}}</div>
                            <div>{{reimbursement.value}}</div>
                        </div>
                        <div class="flex flex-row justify-content-between mt-3">
                            <div class="fw-semibold">{{formatData.payDetails.totalReimbursements.name}}</div>
                            <div class="fw-semibold">{{formatData.payDetails.totalReimbursements.value}}</div>
                        </div>
                    </div>
                    <div class="p-2 flex flex-row justify-content-between mt-4">
                        <div class="fw-semibold">{{formatData.payDetails.netPay.name}}((Gross Earnings - TDS) + Total Reimbursement)</div>
                        <div class="fw-semibold">{{formatData.payDetails.netPay.value}}</div>
                    </div> 
                    <div class="mt-4 text-center fw-semibold">
                        {{'TOTAL_NET_PAY' | customTranslate : 'Total Net Payable' }}  {{formatData.payDetails.netPay.value}} ({{formatData.payDetails.netPayInWords}})
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card w-25 ms-4 h-50" *ngIf="editTemplate">
        <div class="card-header">
            {{'TEMPLATE_1' | customTranslate : 'Template 1' }}
        </div>
        <div class="card-body">
            <div *ngFor="let list of editList; let i = index" class="mt-2">
                <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? showValues(list.value, $event) : null"
                    [checked]="list.checked">{{list.name}}
                </mat-checkbox>
            </div>
        </div>
        <div class="card-footer">
            <div class=" flex justify-content-end">
                <app-fusion-button buttonType="text-only" buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  }}" (buttonClickEmit)="cancelButton()"></app-fusion-button>
                <app-fusion-button buttonValue="{{ 'SAVEAS' | customTranslate : 'Save as Default'  }}" (buttonClickEmit)="saveShowFields()"></app-fusion-button>
            </div>
        </div>
    </div>
</div>