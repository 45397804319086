<!-- <div class="side-screen">
    <div class="side-header">
        <p class="flex content-space-between mb-0">{{headerTitle}}
            <span class="close-icon"><svg-icon src="../assets/images/icons/general/cross-icon.svg" (click)="sideScreenEmittedEvent.emit({'open': false})" style="cursor: pointer;" class="svg-icon-white"></svg-icon></span>
        </p>
    </div>
    <div class="side-content"> -->
        <form class="addPayRollComponent" [formGroup]="addPayRollComponent" (ngSubmit)="submitPayRollComponent()">
            <!-- <div class="scrollable-content"> -->
                <div class="row">
                    <div class="col-md-10">
                        <!-- RS27AUG24 -->
                        <!-- Used the translate pipe to successfully implement the translation -->
                        <label class="form-label label-txt">{{ 'COMPONENT_TYPE' | customTranslate : 'Component Type'  }}</label> <!-- removed 'class="form-field-label"'-->
                        <app-drop-down [lists]='componentTypeList' tabindex="1" dropDownHeight="38px" dropDownWidth="100%" (dataEmit)="dropdownEmitFunction($event, 'type')" [selectedData]="selectedComponentType"></app-drop-down>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 mt-4">
                        <label class="form-label label-txt">{{'COMPONENT_NAME' | customTranslate : 'Component Name' }}</label>
                        <app-drop-down [lists]="nameList" tabindex="1" dropDownHeight="38px" dropDownWidth="100%"
                            (dataEmit)="dropdownEmitFunction($event, 'name')" [selectedData]="selectedComp">
                        </app-drop-down>
                        <div *ngIf="selectedComp === otherSection || selectedComp === 'Reimbursements' " [class]="addPayRollComponent.controls['name'].invalid && addPayRollComponent.controls['name'].touched  ? 'has-error' : '' ">
                            <input type="text" class="form-control" tabindex="2" placeholder="Component name"
                                    aria-label="Text input with dropdown button" formControlName="name">
                        </div>
                        <p class="error-fields"
                            [style]="addPayRollComponent.controls['name'].invalid && addPayRollComponent.controls['name'].touched  ? 'display: block' : 'display: none'">{{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input'  }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10 mt-4">
                        <label class="form-label label-txt">{{'NAME_IN_PAYSLIP' | customTranslate : 'Name in Payslip' }}</label>
                        <div [class]="addPayRollComponent.controls['nameInPaySlip'].invalid && addPayRollComponent.controls['nameInPaySlip'].touched  ? 'has-error' : '' ">
                            <input type="text" class="form-control" tabindex="3" placeholder="Name in Payslip"
                                    aria-label="Text input with dropdown button" formControlName="nameInPaySlip">
                        </div>
                        <p class="error-fields"
                            [style]="addPayRollComponent.controls['nameInPaySlip'].invalid && addPayRollComponent.controls['nameInPaySlip'].touched  ? 'display: block' : 'display: none' ">{{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input'  }}</p>
                    </div>
                </div>
                <div class="show-only-if type-is-deduction" *ngIf="addPayRollComponent.controls['type'].value == 'Allowances' || addPayRollComponent.controls['type'].value == 'Earnings' || addPayRollComponent.controls['type'].value == 'Deductions'" >
                    <!-- <div class="row">
                        <div class="col-md-11 mt-4 pe-0">
                            <label class="form-label label-txt">Pay Type</label>
                            <div class="flex flex-direction-row">
                                <div class="ms-4">
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="payType" tabindex="4"
                                            formControlName="payType" [value]="'Fixed'"
                                            />
                                        <span class="radio-text">Fixed Pay</span>
                                        <span class="tiny-info-text">(Fixed amount paid at the end of every month.)</span>
                                    </label>
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="payType" tabindex="5"
                                            formControlName="payType" [value]="'Variable'"
                                            />
                                        <span class="radio-text">Variable Pay</span>
                                        <span class="tiny-info-text">(Variable amount paid during any payroll.)</span>
                                    </label>
                                </div>
                            </div>
                            <p class="error-msg" *ngIf="addPayRollComponent.controls['payType'].invalid && addPayRollComponent.controls['payType'].touched">
                                <span>
                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required.
                                </span>
                            </p>
                        </div>
                    </div>     -->
                    <!-- <div class="row">
                        <div class="col-md-11 mt-4">
                        <mat-checkbox formControlName="proRataBasis" tabindex="6"><p class="form-label label-txt mb-0">Calculate on Pro-rata basis</p></mat-checkbox>
                        <p class="tiny-info-text mb-0">Pay will be adjusted, based on employee working days.</p>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-11 mt-4">
                            <label class="form-label label-txt">{{ 'CALCULATION_TYPE' | customTranslate : 'Calculation Type'  }}</label>
                            <div class="flex flex-direction-row">
                                <div class="ms-4">
                                    <label *ngIf="selectedComp !== basicPay " class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="calculationType" tabindex="7"
                                            formControlName="calculationType" [value]="'flat'"
                                            /> <!-- (focusout)="handlePayType($event)"
                                                (keyup)="handlePayType($event)" -->
                                        <span class="radio-text">{{ 'FLAT_AMOUNT' | customTranslate : 'Flat Amount'  }}</span>
                                    </label>
                                    <label *ngIf="selectedComp !== basicPay " class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="calculationType" tabindex="8"
                                            formControlName="calculationType" [value]="'percentage'"
                                            /> <!-- (focusout)="handlePayType($event)"
                                                (keyup)="handlePayType($event)" -->
                                        <span class="radio-text">{{ 'PERCENTAGE_BASIC' | customTranslate : 'Percentage of Basic'  }}</span>
                                    </label>
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="calculationType" tabindex="9"
                                            formControlName="calculationType" [value]="'fixed'"
                                            /> <!-- (focusout)="handlePayType($event)"
                                                (keyup)="handlePayType($event)" -->
                                        <span class="radio-text">{{ 'GROSS_PER' | customTranslate : 'Percentage of Gross'  }}</span>
                                    </label>
                                </div>
                            </div>
                            <p class="error-msg" *ngIf="addPayRollComponent.controls['calculationType'].invalid && addPayRollComponent.controls['calculationType'].touched">
                                <span>
                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required.'  }}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 mt-4">
                            <label class="form-label label-txt">Enter {{addPayRollComponent.controls['calculationType'].value == 'flat' ? 'Amount' : 'Percentage'}}</label>
                            <div [class]="addPayRollComponent.controls['amount'].invalid && addPayRollComponent.controls['amount'].touched  ? 'has-error' : '' " class="flex flex-row">
                                <input type="text" class="form-control" tabindex="10" placeholder=""
                                        aria-label="Text input with dropdown button" formControlName="amount">
                                <div class="ms-2">
                                    <div *ngIf="addPayRollComponent.controls['calculationType'].value == 'percentage'">{{ 'OF_BASIC' | customTranslate : 'of Basic'  }}</div>
                                    <div *ngIf="addPayRollComponent.controls['calculationType'].value == 'fixed'">{{ 'OF_GROSS' | customTranslate : 'of Gross'  }}</div>
                                </div>
                            </div>
                            <p class="error-fields"
                                [style]="addPayRollComponent.controls['amount'].invalid && addPayRollComponent.controls['amount'].touched  ? 'display: block' : 'display: none' ">Enter valid input</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="show-only-if type-is-allowance-or-earnings" *ngIf="addPayRollComponent.controls['type'].value == 'Deductions'">
                    <div class="row">
                        <div class="col-md-11 mt-4 pe-0">
                            <label class="form-label label-txt">Deduction frequency</label>
                            <div class="flex flex-direction-row">
                                <div class="ms-4">
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="deductionFrequency" tabindex="4"
                                            formControlName="deductionFrequency" [value]="'recurring'"
                                            />
                                        <span class="radio-text">Recurring</span>
                                    </label>
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="deductionFrequency" tabindex="5"
                                            formControlName="deductionFrequency" [value]="'one-time'"
                                            />
                                        <span class="radio-text">One time deduction</span>
                                    </label>
                                </div>
                            </div>
                            <p class="error-msg" *ngIf="addPayRollComponent.controls['deductionFrequency'].invalid && addPayRollComponent.controls['deductionFrequency'].touched">
                                <span>
                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required.
                                </span>
                            </p>
                        </div>
                    </div>
                </div> -->
                <div class="show-only-if type-is-allowance-or-reimbursements" *ngIf="addPayRollComponent.controls['type'].value == 'Reimbursements'">
                    <div class="row">
                        <div class="col-md-11 mt-4 pe-0">
                            <label class="form-label label-txt">{{ 'MAX_REIMBURSABLE_AMT' | customTranslate : 'Maximum Reimbursable Amount'  }}</label>
                            <div [class]="addPayRollComponent.controls['maxReimburse'].invalid && addPayRollComponent.controls['maxReimburse'].touched  ? 'has-error' : '' ">
                                <input type="text" class="form-control" tabindex="4" placeholder="1000"
                                        aria-label="Text input with dropdown button" formControlName="maxReimburse">
                            </div>
                            <p class="error-fields"
                                [style]="addPayRollComponent.controls['maxReimburse'].invalid && addPayRollComponent.controls['maxReimburse'].touched  ? 'display: block' : 'display: none' ">{{ 'VALIDATION.VAL_INPUT' | customTranslate : 'Enter valid input'  }}</p>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="addPayRollComponent.controls['type'].value === 'Deductions' ">
                    <div class="col-md-11 mt-4">
                        <mat-checkbox formControlName="isEmployerContribution" tabindex="10"><p class="form-label label-txt mb-0">{{'Is Employers contribution' | customTranslate : 'Is Employer's contribution' }}</p></mat-checkbox>
                    </div>
                </div>
                <!-- SK15JUL24 adding isVariable type  -->
                <div class="row">
                    <div class="col-md-11 mt-4">
                        <mat-checkbox formControlName="isVariablePay" tabindex="11" ><p class="form-label label-txt mb-0">{{ 'VARIABLE_COMPONENT' | customTranslate : 'Variable Component'  }}</p></mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-11 mt-4">
                        <mat-checkbox formControlName="taxable" tabindex="12" ><p class="form-label label-txt mb-0">{{ 'TAXABLE' | customTranslate : 'Taxable'  }}</p></mat-checkbox>
                    </div>
                </div>
                <div *ngIf="addPayRollComponent.controls['taxable'].value == true">
                    <div class="row">
                        <div class="col-md-11 mt-4 pe-0">
                            <label class="form-label label-txt">{{ 'UNDER_NEW_TAX' | customTranslate : 'Is Exempted under New Tax Regime'  }}</label>
                            <div class="flex flex-direction-row">
                                <div class="ms-4">
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="isExempted" tabindex="13"
                                            formControlName="isExempted" [value]="true"
                                            />
                                        <span class="radio-text">{{ 'YES' | customTranslate : 'Yes'  }}</span>
                                    </label>
                                    <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="isExempted" tabindex="14"
                                            formControlName="isExempted" [value]="false"
                                            />
                                        <span class="radio-text">{{ 'NO' | customTranslate : 'No'  }}</span>
                                    </label>
                                    <!-- <label class="form-label label-txt increase-text flex" style="margin-left:-20px;">
                                        <input type="radio" name="isExempted" tabindex="14"
                                            formControlName="isExempted" [value]="'bothRegime'"
                                            />
                                        <span class="radio-text">Both</span>
                                    </label> -->
                                </div>
                            </div>
                            <p class="error-msg" *ngIf="addPayRollComponent.controls['deductionFrequency'].invalid && addPayRollComponent.controls['deductionFrequency'].touched">
                                <span>
                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required.'  }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-11 mt-4">
                        <mat-checkbox formControlName="active" tabindex="15"><p class="form-label label-txt mb-0">{{ 'MARK_AS_ACTIVE' | customTranslate : 'Mark this as Active'  }}</p></mat-checkbox>
                    </div>
                </div>
            <!-- </div> -->
            <div class="button-wrapper text-right mb-5">
                <app-fusion-button [buttonType]="'text-only side-cancel-component'" [tabIndex]="13" buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  }}" (buttonClickEmit)="addPayRollComponent.reset();sideScreenEmittedEvent.emit({'open': false})"></app-fusion-button>
                <app-fusion-button [buttonType]="'ms-2 side-save-component'" [tabIndex]="12" buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save'  }}" (buttonClickEmit)="hitPayrollSettingComponentApi($event)"></app-fusion-button>
            </div>
        </form>
    <!-- </div>
</div> -->