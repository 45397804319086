import { Component, OnInit, isDevMode} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { approval } from '../../../../models/approval';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-approval-notificationtab',
  standalone: true,
  imports: [CommonModule,
     AngularSvgIconModule, 
     FusionButtonComponent,
     SuccessFailureMessageComponent, 
     ReactiveFormsModule,
     MatTableModule,
     MatPaginatorModule,
     MatSortModule,
     DataTableComponent,
     TranslateModule,
     CustomTranslatePipe
  ],
  templateUrl: './approval-notificationtab.component.html',
  styleUrls: ['./approval-notificationtab.component.css']
})

export class ApprovalNotificationtabComponent implements OnInit {
  
  rejectionform!: FormGroup;
  approval: approval[] = [];
  errorMessage = '';
  rejectReason: any;
  approvalnotification: Array<any> = [];
  historyData: Array<any> = [];
  defaultCall = 'notPending';
  isDevMode: boolean = false;
  inProgressTab = true; // current active tab is declared as true
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  approveMsg = Constants.APPROVE_MESSAGE;
  rejectMsg = Constants.REJECT_MESSAGE;
  companyId = this.globalValues.orgId;
  headersLabel : Array<any> = [];
  historyLabel : Array<any> = [];
  downloadCurrentDisable = true;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private errorHandler : ErrorHandlerService, private alertService: AlertService, private datePipe: DatePipe,private globalValues: GlobalValuesService, private translate : TranslateService) {
    this.rejectionform = this.formBuilder.group({
      rejectReason: ["", Validators.required],
      empId: "",
      _id: "",
      type: ""
    });
    this.isDevMode = isDevMode();
    this.globalValues.laguageChanges$.subscribe(lang => {     
     }); 
  }

  saveSuccess(data:any) {
    this.alertService.messageOnPass('success', data);
    this.ngOnInit();
    this.errorMessage = '';
  }

  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }
    
  ngOnInit(): void {    
    this.approvals();
    // this.historyTab(this.defaultCall);
  }

  // SK14OCT24 api call on only selecting Inprogress / History tab
  resetForm(type:any){
    // this.ngOnInit();
    if(type === "inProgress"){
      this.approvals();
    } else if("history"){
      this.historyTab(this.defaultCall);
    }
    this.historyData = [];
    this.approvalnotification=[];
  }

  //This function run onload to get the pending leave datas in inprogress tab
  approvals(): void {
    this.apiService.fetchValue('/approval?leaves=Pending').subscribe((data: any) => {
      if(this.isDevMode) console.log('Data', data);
      this.approvalnotification = data;
      this.approvalnotification.forEach((data:any, index:number = 0) => {
        // assigning a data with key and value for storing the checkbox event and rejection reason in approvalnotification array
        // the pipe transform is used change the date format that to be displayed in table
        data.tableStartDate = this.datePipe.transform(data.startdate, 'mediumDate');
        data.tableEndDate = this.datePipe.transform(data.enddate, 'mediumDate');
        data.rejectReason = '';
        data.appliedon = this.datePipe.transform(data.appliedon, 'mediumDate');
      })
      if(this.isDevMode) console.log('Pending Data', this.approvalnotification);
    })
  }

  //This function run onload to get the approved or rejected leave datas based on query params in history tab
  historyTab(defaultData:any) {
    const params = new HttpParams({
      'fromObject':{
        'leaves' : defaultData
      }
    });
    this.apiService.fetchValue('/approval',params).subscribe((data: any) => {
      if(this.isDevMode) console.log('History Data database', data);
      this.historyData = data;
      this.historyData.forEach((data:any, index:number = 0) => {
        // assigning a data with key and value for storing the checkbox event and rejection reason in historyData array
        data.tableStartDate = this.datePipe.transform(data.startdate, 'mediumDate');
        data.tableEndDate = this.datePipe.transform(data.enddate, 'mediumDate');
        data.rejectReason = data.rejectReason || '-';
        data.defaultStatus = data.status;
        data.appliedon = this.datePipe.transform(data.appliedon, 'mediumDate')
      })

      if(this.isDevMode) console.log('history Data', this.historyData);
    })
  }

  onApprove(data:{empId:string,id:string}){
     const time = new Date().toLocaleString("en-AU");
     const status = "Approved";
     const approvalData = [{type:"leaves", employeeId:data.empId, _id:data.id, time:time, status:status, 'companyId' : this.companyId}];
     this.apiService.writeValue('patch','/approval',approvalData)
     .subscribe({
       next: (approvalData) => {
         if(this.isDevMode) console.log("Next :", approvalData);
         this.ngOnInit();
         this.saveSuccess(this.approveMsg)
         this.globalValues.employeeDataGet();
       },
       error: (err: HttpErrorResponse) => {
         this.showError(err);
       }
     });
  }
  onreject(data:{empId:string,id:string}){
    this.rejectionform.setValue({
      rejectReason: "",
      empId: data.empId,
      _id: data.id,
      type:"leaves"
    })
  }
  
  // after clicking reject button, the stored data in form will be sent to api
  reasonText(){
    const value = this.rejectionform.value;
    const time = new Date().toLocaleString("en-AU");
    const status = "Rejected";
    const rejectedData = [{type:value.type, employeeId:value.empId, _id:value._id, rejectionReason:value.rejectReason, time:time, status: status, 'companyId' : this.companyId}];
    this.apiService.writeValue('patch','/approval',rejectedData)
    .subscribe({
      next: (rejectData) => {
        this.saveSuccess(this.rejectMsg);
        if(this.isDevMode) console.log("Reject Data :", rejectData);
        this.ngOnInit();
        this.globalValues.employeeDataGet();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }
  
  tableCheckedDataa(checkedArray:any){
    this.checkedEmployees = [];
    this.checkedEmployees = checkedArray;
    if(this.checkedEmployees.length === 0){
      this.downloadCurrentDisable = true;
    } else {
      this.downloadCurrentDisable = false;
    }
    if(this.isDevMode) console.log('this.checkedEmployees',this.checkedEmployees);
  }
  checkedEmployees:any;
  downloadCurrentEmployees(){
    const csv = this.convertToCsv(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'Approval-leave History.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }
  convertToCsv(objArray: any[]) {
    let arrayOfObjects: { employeeId: any;name:any,tableStartDate:any,tableEndDate:any,leaveType:any,leaveBalance:any,noofdays:any,status:any,reason:any,appliedon:any,comments:any}[] = [];
    objArray.forEach(element => {
    
      arrayOfObjects.push({
        employeeId: element.employeeId,
        name:element.name,
        tableStartDate: element.tableStartDate,
        tableEndDate: element.tableEndDate,
        leaveType: element.leaveType,
        leaveBalance: element.leaveBalance,
        noofdays: element.noofdays,
        status: element.status,
        reason: element.reason,
        appliedon: element.appliedon,
        comments:element.rejectReason,

      });
    });
      var selectedObjectsForCSV = this.filterObjectKeys(arrayOfObjects, ['employeeId', 'name','tableStartDate','tableEndDate','leaveType','leaveBalance','noofdays','status','reason','appliedon','comments']);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    array.unshift({employeeId: "Employee Id",name:'Employee Name',tableStartDate:'Start Date',tableEndDate:'tableEndDate',leaveType:'Leave Type','leaveBalance':'Leave Balance','noofdays':'NoOfDays',status:'Status',reason:'Reason', appliedon:'appliedon',rejectReason:'Comments'})
  
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        } if (index === 'tableStartDate' || index === 'tableEndDate' || index === 'appliedon' ) {
         // Format startDate: "October 11, 2023" to "October 11 2023"
          line += array[i][index].replace(/,/g, '-');
      }     
        else {
        line += array[i][index] ;
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 
  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) {
    let filteredObjects: any = [];
    arrayOfObjects.forEach((obj: any) => {
        filteredObjects.push(
            keys.reduce((acc: any, key: any) => {
                // Check if the key exists in the object and is not null or undefined
                if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined) {
                    acc[key] = obj[key];
                } else {
                    // If the key is not present or is null/undefined, replace it with an empty string
                    acc[key] = '';
                }
                return acc;
            }, {})
        );
    });
    return filteredObjects;
}

}