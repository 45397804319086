import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LeaveHistoryComponent } from '../../Leave-history/Leave-history.component';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HrmComponent } from 'src/app/pages/hrm/hrm.component';
import { EssComponent } from '../../ess.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-apply-leaves',
  standalone: true,
  imports: [CommonModule, FusionButtonComponent,
    ReactiveFormsModule, SuccessFailureMessageComponent, LeaveHistoryComponent, EssComponent,TranslateModule,CustomTranslatePipe],
  templateUrl: './apply-leaves.component.html',
  styleUrls: ['./apply-leaves.component.css']
})
export class ApplyLeavesComponent implements OnInit {

  @Input() selectedUser?: any;
  @Input() fromHrmPage: boolean = false;
  @Input() employeeLeaves: any;
  
  @ViewChild('leaveHistory') leaveHistory!: LeaveHistoryComponent;

  applyLeaveForm!: FormGroup;
  templateGet: any;
  holidayGet: any;
  weekOffGet: any;
  holidayArray: Array<any> = [];
  weekOffArray: Array<any> = [];
  weekActiveDaysArray: any;


  SelectedEmpleaveHistory: any[] = [];

  leaveStartDate!: any;
  leaveEndDate!: any;
  leaveStartsession: any;
  leaveEndSession: any;

  startSessionError = false;
  startDayError = false;
  leaveError = false;
  endSession = false;
  showNoOfLeaves = false;
  holidayDay = false;

  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  errorMessage: any;
  companyId = this.globalValues.orgId;

  noOfCasualLeave:any;
  noOfSickLeave:any;
  noOfEarnedLeave = 0;
  leaveBalance: any;
  leaveNum = 0;
  dateDiffer:any;
  startingDay:any;
  endingDay:any;
  startDate1: any;
  endDate1: any;
  dates!: Array<any>;
  leaveType: any;
  availDays:any;
  employeeId:any;
  isDevMode: boolean = false;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;

  constructor(private fb: FormBuilder, private apiService: ApiService, private errorHandler: ErrorHandlerService,private hrmfuntion:HrmComponent, private alertService: AlertService, private globalValues: GlobalValuesService, private router: Router) {
    this.isDevMode = isDevMode();
    this.applyLeaveForm = this.fb.group({
      'leaveType': ['', Validators.required],
      'startDate': ['', Validators.required],
      'startDateSession': ['Full Day', Validators.required],
      'endDate': ['', Validators.required],
      'endDateSession': ['Full Day', Validators.required],
      'reason': ['', Validators.required],
    })
   
  }

  ngOnChanges() {
    if(this.router.routerState.snapshot.url.includes('/hrm')){
      this.employeeId = this.selectedUser?.employeeId;
      this.getData();
    }else{
      this.employeeId = this.globalValues.empId;
      this.getData();
    }

  }
  
  ngOnInit(): void {
    if(this.router.routerState.snapshot.url.includes('/hrm')){
      this.employeeId = this.selectedUser?.employeeId;
      this.getData();
    }else{
      this.employeeId = this.globalValues.empId;
      this.getData();
    }

    this.applyLeaveForm = this.fb.group({
      'leaveType': ['', Validators.required],
      'startDate': ['', Validators.required],
      'startDateSession': ['Full Day', Validators.required],
      'endDate': ['', Validators.required],
      'endDateSession': ['Full Day', Validators.required],
      'reason': ['', Validators.required],
    })
   
  }

  getData() {

    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    // SK17JAN24 reducing api call
    this.templateGet  = this.selectedUser?.templates;
    this.SelectedEmpleaveHistory = this.employeeLeaves;
    this.templateGet?.forEach((element: any) => {
    element.leaveType === 'Casual leave' ? this.noOfCasualLeave = element.noOfDays : null;
    element.leaveType === 'Sick leave' ? this.noOfSickLeave = element.noOfDays : null;
      if (this.applyLeaveForm.value.leaveType === element.leaveType) {
        this.leaveType = element.leaveType;
        this.availDays = element.noOfDays;
      }
    });
    this.holidayArray = [];
    this.holidayGet = this.selectedUser?.holidays;
    this.holidayGet?.forEach((element: any) => {
      this.holidayArray.push(new Date(element.startDate).toDateString());
      this.holidayArray.push(new Date(element.endDate).toDateString());
    });
    this.weekOffArray = [];
    this.weekActiveDaysArray = {}; //SK14OCT24 to find the active weekoff days
    this.weekOffGet = this.selectedUser?.weekoff?.days; // SK21DEC23 to solve undefined error, added '?'
    if(this.selectedUser?.weekoff?.startDate && this.selectedUser?.weekoff?.endDate){
      this.weekActiveDaysArray.startDate = new Date(this.selectedUser?.weekoff?.startDate);
      this.weekActiveDaysArray.endDate = new Date(this.selectedUser?.weekoff?.endDate);
    }
    this.weekOffGet?.forEach((element: any) => {
    //These string days should be matched with leave master weekoff days. The week days are converted to numbers and pushed to array
    element.name === "Sunday" ? this.weekOffArray.push(0) : null;
    element.name === "Monday" ? this.weekOffArray.push(1) : null;
    element.name === "Tuesday" ? this.weekOffArray.push(2) : null;
    element.name === "Wednesday" ? this.weekOffArray.push(3) : null;
    element.name === "Thursday" ? this.weekOffArray.push(4) : null;
    element.name === "Friday" ? this.weekOffArray.push(5) : null;
    element.name === "Saturday" ? this.weekOffArray.push(6) : null;
    });
  }

  resetForm() {
    this.ngOnInit();
    this.leaveStartDate = NaN;
    this.leaveEndDate = NaN;
    this.leaveStartsession = null;
    this.leaveEndSession = null;
    this.startSessionError = false;
    this.startDayError = false;
    this.leaveError = false;
    this.endSession = false;
    this.showNoOfLeaves = false;
    this.holidayDay = false;
    this.noOfEarnedLeave = 0;
    this.leaveBalance = null;
    this.startDate1 = null;
    this.endDate1 = null;
    this.dates=[];
    this.startingDay = null;
    this.endingDay = null;
    this.dateDiffer = null;
    this.leaveNum = 0;
  }

  startDate(event: any) {
    this.leaveStartDate = event.target.value;
  }

  endDate(event: any) {
    this.leaveEndDate = event.target.value;
  } 

  // displays end date session when the end date is greater than the start date
  sessionDisplay() {
    this.endSession = this.endingDay - this.startingDay > 0;
  }

  //displays error when the applying leave days is greater than number of leave available
  leaveTypeError() {
    this.templateGet?.forEach((element: any) => {
      if (this.applyLeaveForm.value.leaveType === element.leaveType) {
        this.leaveType = element.leaveType;
        this.availDays = element.noOfDays;
        this.leaveError = this.leaveNum > this.availDays;
      }
    })
  }

  //displays error when end date is lesser than start date
  dateError() {
    this.startDayError = this.endingDay - this.startingDay < 0
  }

  //Calculating session error, if we selected the wrong session
  sessionError() {
    this.startSessionError = this.endingDay - this.startingDay >= 1 && this.leaveStartsession === 'First Half';
  }

  // the holidays and weekoff days are calculated and exluded in the day calculation array
  holidayCalculation(){
    this.dates = [];
    const startDay = new Date(this.startingDay);
    while (startDay <= this.endingDay) {
      if ( ((this.weekOffArray.includes(startDay.getDay())) && (this.weekActiveDaysArray.startDate <= startDay && this.weekActiveDaysArray.endDate >= startDay )) || this.holidayArray.includes(startDay.toDateString())) { //SK14OCT24 check active weekoff days
        this.dates?.push(new Date(startDay));
        startDay.setDate(startDay.getDate() + 1);
      } else {
        startDay.setDate(startDay.getDate() + 1);
      }
      this.holidayDay = ((this.weekOffArray.includes(this.startingDay.getDay())) && (this.weekActiveDaysArray.startDate <= this.startingDay && this.weekActiveDaysArray.endDate >= this.startingDay )) || this.holidayArray.includes(this.startingDay.toDateString()) || ((this.weekOffArray.includes(this.endingDay.getDay())) && (this.weekActiveDaysArray.startDate <= this.endingDay && this.weekActiveDaysArray.endDate >= this.endingDay )) || this.holidayArray.includes(this.endingDay.toDateString()); //SK14OCT24
    }
    this.leaveNum = this.leaveNum - this.dates.length;
    if(this.isDevMode) console.log(this.dates)
  }

  leaveCalc(){
    this.startingDay = new Date(this.leaveStartDate);
    this.endingDay = new Date (this.leaveEndDate);
    this.startDate1 = Math.floor((Date.UTC(this.endingDay?.getFullYear(),  this.endingDay?.getMonth(), this.endingDay?.getDate())));
    this.endDate1 =  Math.floor((Date.UTC(this.startingDay?.getFullYear(), this.startingDay?.getMonth(), this.startingDay?.getDate()) ));
    this.dateDiffer = (this.startDate1 - this.endDate1) / (1000 * 60 * 60 * 24); //finding the difference between selected dates
    this.leaveStartsession = this.applyLeaveForm.value.startDateSession;
    this.leaveEndSession = this.applyLeaveForm.value.endDateSession;
    //calculating the number of leave days applied
    if(this.startDate1 === this.endDate1){
      this.leaveNum = this.leaveStartsession === "Full Day" ? 1 : 0.5;
    } else if ( this.startDate1 > this.endDate1){
      if( (this.leaveStartsession === "Full Day") && (this.leaveEndSession === "Full Day")){
        this.leaveNum = this.dateDiffer + 1;
      } else if ( (this.leaveStartsession != "Full Day") || (this.leaveEndSession != "Full Day")){
        this.leaveNum = this.dateDiffer + 0.5;
      } 
      if( (this.leaveStartsession != "Full Day") && (this.leaveEndSession != "Full Day")){
        this.leaveNum = this.dateDiffer;
      }
    }
    this.holidayCalculation();
    this.sessionDisplay();
    this.dateError();
    this.sessionError();
    this.leaveTypeError();
    this.showNoOfLeaves = !this.startDayError;
  }

  applyLeaveData() {  //applying leave function 
    this.templateGet.forEach((element: any) => {
      if (this.applyLeaveForm.value.leaveType === element.leaveType) {
        this.leaveBalance = element.noOfDays;
        const value = this.applyLeaveForm.value;

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
        const day = currentDate.getDate();
        const appliedOn = `${year}-${month}-${day}`;

        const time = currentDate.toLocaleTimeString('en-AU');
        const formattedDateTime = `${appliedOn}, ${time}`;

        this.apiService.writeValue('post', '/employee/leaves', { 'employeeId': this.employeeId, 'leavetype': value.leaveType, 'startdate': value.startDate, 'startDateSession': value.startDateSession, 'enddate': value.endDate, 'endDateSession': value.endDateSession, 'noofdays': this.leaveNum, 'availabledays': this.leaveBalance, 'reason': value.reason, 'time': formattedDateTime, 'appliedOn': appliedOn })
        .subscribe({
          next: (data) => {
            if(this.isDevMode) console.log('leaveData', data);
            this.saveSucces();
            this.resetForm();
            this.leaveHistory.leaveList(); //This is to update the leavehistory component
            this.globalValues.employeeDataGet();
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err)
          }
        })
      }
    })
  }

  saveSucces(){
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg); // SK17JAN24 reducing api call
  }

  showError(err: HttpErrorResponse){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  // SK14OCT24 get dates between a date
  getDatesBetween(startDate:any, endDate:any) {
    const startingDate = new Date(startDate);
    const endingDate = new Date(endDate);

    const datesBetween = [];
    const currentDate = new Date(startingDate);

    while (currentDate <= endingDate) {
        datesBetween.push(new Date(currentDate).toISOString());
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return datesBetween;
  }
}
