<!-- SK06MAR24 imported nav tabs to display the employee details in separate tab -->
<app-nav-tabs [tabsLists]="tabsLists" [activeTab]="activeTab" (emitTabData)="emitTabData($event)"></app-nav-tabs>
<!-- SK10APR24 month and year dropdown kept as common in all tabs -->
<div class="d-flex flex-row justify-content-end">
    <app-drop-down [lists]='monthArray' class="me-2" [selectedData]="month" dropDownHeight="32px"
        dropFontWeight="500" dropFontSize="12px" dropFontColor="#434555" dropBorderRadius="5px"
        dropDownWidth="110px" (dataEmit)="selectedValue($event, 'month')">
    </app-drop-down>
    <app-drop-down [lists]='yearArray' class="me-2" [selectedData]="year" dropDownHeight="32px"
        dropFontWeight="500" dropFontSize="12px" dropFontColor="#434555" dropBorderRadius="5px"
        dropDownWidth="80px" (dataEmit)="selectedValue($event, 'year')">
    </app-drop-down>
</div>

<div *ngIf="!tabContent.browsedTab" class="w-100">
    <div class="attendance-container">
        <div>
            <!-- SK03OCT24 added input/output for filter screen -->
            <app-data-table-two *ngIf="finalTableData" (doubleClickEmit)="doubleClickData($event)" [withCheckBox]="true"
                [data]="finalTableData" [searchBar]="true" [headerLabels]="headerLabels" [translatePath]="'DYNAMIC_TABLE'"
                [displayedColumns]="displayedColumns" (exportButton)="downloadCurrentEmployees()"
                (importButton)="emitImportButton()" (selectedValues)="tableCheckedDataa($event)"
                [defaultWorkingHours]="companyWorkingHours" [hyperLinkColumns]="hyperLinkColumns" [stickyColumn]="['employeeDetail']" 
                (hyperLinkClickEmit)="hyperLinkClick($event)" [title]="tableTitle" [titleOn]="true"
                [filterScreenConfigs]="filterScreenConfigs"
                [exportFieldsListConfigs]="exportFieldsListConfigs" [exportFilterListConfigs]="exportFilterListConfigs"
                (sideNavButtons)="siveNavExportButtonEmit($event)"
                (exportReports)="exportReports()"
                (multipleSelectFilterExport)="multipleSelectFilterExport($event)"
                (filterDateEmitEvt)="filterDateEmitEvt($event)"></app-data-table-two>
        </div>
    </div>
    <!-- Addendance upload csv-->
    <div class="modal fade container-md" id="demoModalID" #demoModal>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">

                    <!-- RS27AUG24 -->
                    <!-- Used the translate pipe to successfully implement the translation -->
                    <div class="modal-title fs-5 mb-0" id="attendModalLabel">{{ 'UPLOAD_ATTENDANCE' | customTranslate : 'Upload Attendance'  }}</div>
                    <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal'" [fcBtnId]="'close'"
                        (buttonClickEmit)="filesArrayClear()"></app-fusion-button>
                </div>
                <div class="modal-body padding-space">
                    <div class="tab-heading sub-page-name">{{ 'STEPS_TO_UPLOAD_ATTENDANCE' | customTranslate : 'Steps to upload Attendance'  }}</div>
                    <ol>
                        <!-- SK10APR24 need to develop UI to get attendance type -->
                        <!-- <li>Download the 'ATTENDANCE' template <span class="link-to-download"
                                (click)="downloadTemplate('employeeRowAttendance')">here</span>.</li> -->
                        <li class="download-atten-temp">{{'DOWNLOAD_ATTENDANCE_TEMPLATE' | customTranslate : 'Download the ATTENDANCE template'  }} <span class="link-to-download"
                            (click)="downloadTemplate('employeeColumnAttendance')">{{ 'HERE' | customTranslate : 'here'  }}</span>.</li>
                    </ol>
                    <div class="d-flex row align-items-center">
                        <div class="fc-tab-view w-100">
                            <div class="flex flex-direction-row fc-tab-buttons">
                                <ul class="nav nav-tabs fc-tabs" id="uploadAttendance" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="first-tab" data-bs-toggle="tab"
                                            data-bs-target="#attendance-upload-pane" type="button" role="tab"
                                            aria-controls="user-upload-pane" aria-selected="true">{{ 'UPLOAD_ATTENDANCE' | customTranslate : 'Upload Attendance'  }}</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="fcTabContent">
                                <div class="tab-pane fade show active" id="attendance-upload-pane" role="tabpanel"
                                    aria-labelledby="first-tab" tabindex="0">
                                    <app-file-upload [fileUploadId]="'uploadAttendanceFile'"
                                        [docVals]="['5 mb','csv']" [multiSelectFile]="false"
                                        [showPreview]="'fileName'"
                                        [acceptType]="'.csv,text/csv,.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                                        (selectedFilesEmitter)="userFileEmitter($event)" [uploadReadyFiles]="filesArrayComp"
                                        (documentDelete)="deletedFileInComp($event)">
                                    </app-file-upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <app-fusion-button [buttonValue]="'Upload' | customTranslate : 'Upload'" (buttonClickEmit)="uploadRecordsFile()"
                        [dataDismiss]="'modal'" [buttonDisable]="!employeeBulkFile" [buttonType]="'btn btn-primary'">
                    </app-fusion-button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="addentanceEdit" #editAttendanceModal>
        <div class="modal-dialog d-flex align-items-center h-100">
            <div class="modal-content modal-custom">
                <div class="modal-header modal-custom-header modal-custom">
                    <div class="modal-title">{{editedEmployee}} {{ 'ATTENDANCE_FOR' | customTranslate : 'attendance for'  }}
                        {{editedDate}}</div>
                    <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal'"
                        (buttonClickEmit)="resetForm()" [fcBtnId]="'close'"></app-fusion-button>
                </div>
                <div class="modal-body modal-custom-body d-flex flex-column">
                    <form [formGroup]="editAttendanceForm">
                        <div class="d-flex flex-column">
                            <div class="mw-100">
                                <div class="row flex">
                                    <div class="d-flex flex-row col-md-2 align-items-center">
                                        <input type="radio" name="status" id="present" formControlName="status" value="present"
                                            (change)="onRadioChange($event)">
                                        <label class="ms-1" for="present">{{ 'PRESENT' | customTranslate : 'Present'  }}</label>
                                        <img class="ms-1" src="../../../../assets/images/icons/general/Present.svg">
                                    </div>
                                    <div class="d-flex flex-row col-md-2  align-items-center">
                                        <input type="radio" name="status" id="absent" formControlName="status" value="absent"
                                            (change)="onRadioChange($event)">
                                        <label class="ms-1" for="absent">{{ 'ABSENT' | customTranslate : 'Absent'  }}</label>
                                        <img class="ms-1" src="../../../../assets/images/icons/general/Absent.svg">
                                    </div>
                                    <div class="d-flex flex-row col-md-2  align-items-center">
                                        <input type="radio" name="status" id="halfday" formControlName="status" value="halfday"
                                            (change)="onRadioChange($event)" [checked]="editAttendanceForm.controls['halfDay'].value || editAttendanceForm.controls['status'].value === 'halfDay' ">
                                        <label class="ms-1" for="halfday">{{ 'HALF_DAY' | customTranslate : 'Half Day'  }}</label>
                                        <img class="ms-1" src="../../../../assets/images/icons/general/halfday.svg">
                                    </div>
                                    <!-- SK03JUL24 added weekoff and holiday radios -->
                                    <div class="d-flex flex-row col-md-2  align-items-center">
                                        <input type="radio" name="status" id="weekoff" formControlName="status" value="weekoff"
                                            (change)="onRadioChange($event)">
                                        <label class="ms-1" for="weekoff">{{ 'WEEK_OFF' | customTranslate : 'Week Off'  }}</label>
                                        <img class="ms-1" src="../../../../assets/images/icons/general/Off.svg">
                                    </div>
                                    <div class="d-flex flex-row  col-md-2 align-items-center">
                                        <input type="radio" name="status" id="holiday" formControlName="status" value="holiday"
                                            (change)="onRadioChange($event)">
                                        <label class="ms-1" for="holiday">{{ 'HOLIDAY' | customTranslate : 'Holiday'  }}</label>
                                        <img class="ms-1" src="../../../../assets/images/icons/general/holiday_1.svg">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-4">
                                <div *ngIf="isDropdownVisible" >
                                    <label class="form-label">{{ 'LEAVE_TYPE' | customTranslate : 'Select Leave Type:'  }}</label>
                                    <app-drop-down [isDisabled]="true" [lists]='leaveTypeList' class="me-2"
                                        dropFontWeight="500" dropFontSize="12px" dropFontColor="#434555"
                                        dropBorderRadius="5px" 
                                        (dataEmit)="selectedValued($event,'editAttendance')" [selectedData]="defaultLeaveTypeDropDown">
                                    </app-drop-down>
                                    <div>{{this.leaveType ? this.leaveType + ' : ' : ''}}{{this.availDays}}</div>
                                </div>
                                <!-- SK18JUN24 added checkin, checkout and comments in edit addendance modal -->
                                <div class="flex flex-row"  *ngIf="editAttendanceForm.controls['status'].value === 'present' || editAttendanceForm.controls['halfDay'].value === true ">
                                    <div class="flex flex-column">
                                        <label for="checkIn" class="form-label">{{ 'CHECK_IN' | customTranslate : 'Check In'  }}</label>
                                        <input type="time"  class="table-width" matInput formControlName="checkIn" class="input-box" placeholder="HH:mm:ss">
                                    </div>
                                    <div class="flex flex-column ms-5">
                                        <label for="checkOut" class="form-label">{{ 'CHECK_OUT' | customTranslate : 'Check Out'  }}</label>
                                        <input type="time"  class=" table-width" matInput formControlName="checkOut" class="input-box" placeholder="HH:mm:ss">
                                    </div>
                                    <div class="flec flex-column  ms-5" *ngIf="editAttendanceForm.controls['status'].value === 'present' ">
                                        <label for="overtimeHours" class="form-label">{{ 'OVERTIME_HOURS' | customTranslate : 'Overtime Hours'  }}</label>
                                        <input [readonly]="true" class="form-control" type="number" id="overtimeHours" name="overtimeHours"
                                            formControlName="overTimeHours">
                                    </div>
                                </div>
                                
                                <div class="mt-2">
                                    <label for="overtimeHours" class="form-label">{{ 'COMMENTS' | customTranslate : 'Comments'  }}</label>
                                    <input class="form-control" type="textarea" id="comments" name="comments"
                                        formControlName="comments">
                                </div>
                            </div>
                            
                            <!-- The modal -->
                            <!-- <div class="card-expanded-section modal-content modal-section ot-modal" modal-1
                                [style.display]="modalOpen ? 'block' : 'none'">
                                <div class="modal-footer">
                                    <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal-1'"
                                        (buttonClickEmit)="closeOvertimeHoursModal()"></app-fusion-button>
                                    <form (ngSubmit)="submitOvertimeHoursForm()" style="margin-top: -30px;">
                                        <label for="overtimeHours">Enter Overtime Hours:</label>
                                        <input type="number" id="overtimeHours" name="overtimeHours"
                                            [(ngModel)]="overtimeHours">
                                    </form>
                                </div>
                            </div> -->
    
                            <!-- <div class="d-flex flex-column">
                            <div class="col-md-12">
                                <label for="attendance" class="form-label">Edit {{editedEmployee}}'s Attendance for
                                    {{editedDate}}</label>
                                <input type="text" formControlName="editAttendance" class="form-control" id="attendance">
                                </div-->
                        </div>
                    </form>
                    <div class="mt-5 d-flex justify-content-end">
                        <app-fusion-button [buttonValue]=" 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  " (buttonClickEmit)="resetForm()"
                            [buttonType]="'text-only'" [dataDismiss]="'modal'" [fcBtnId]="'myId'"></app-fusion-button>
                        <app-fusion-button [buttonValue]=" 'BUTTON_VALUE.SAVE' | customTranslate : 'Save'  " [dataDismiss]="'modal'" [fcBtnId]="'modalSave'"
                            (buttonClickEmit)="attendanceGet(); resetForm()">
                        </app-fusion-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- SK06MAR24 the browsed content of employee in detail -->
<div *ngIf="tabContent.browsedTab">
    <app-data-table [data]="empAttendanceDataTable?.data" [headerLabels]="empAttendanceDataTable?.headerLabels" [translatePath]="'DYNAMIC_TABLE'" [displayedColumns]="empAttendanceDataTable?.displayedColumns" [withCheckBox]="empAttendanceDataTable?.withCheckBox" [fromWhere]="empAttendanceDataTable?.fromWhere" [isButtons]="empAttendanceDataTable?.isButtons"
    [buttonArray]="empAttendanceDataTable?.buttonsArray" (commonButtonClickEmit)="downloadEmpCsv($event)"
    (selectedValues)="selectedEmployeeCheckedDataa($event)" [editColumn]="empAttendanceDataTable?.editColumn || []" [actionButton]="empAttendanceDataTable?.actionButton" [isEditRow]="empAttendanceDataTable?.editButton" 
    [searchBar]="true"
    (saveButtonData)="saveEmpAttendanceData($event)"></app-data-table>
</div>

