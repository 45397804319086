<!-- SK14MAR24 update from table to divs, added header, updated stylings -->
<div class="card">
    <div *ngIf="title" class="p-2 fw-bold bg-primary text-light"> 
        {{title | customTranslate : title }}
    </div>
    <div class="p-2 head">
        <div class="flex justify-content-between">
            <div *ngFor="let head of  header" class="fw-bold fs-7">
                {{head | customTranslate : head }}
            </div>
        </div>
    </div>
    
    <div class="flex flex-column {{fixedTable ? 'scroll' : ''}}" [style]="fixedTableSize && fixedTable ? fixedTableSize : ''">
        <div>
            <div *ngFor="let user of  holidayArray" class="flex flex-row justify-content-between border-bottom">
                <div class="pe-5 ps-2 pb-2 pt-3" style="font-size: small;">{{user.type}}</div>
                <div class="ps-5 pe-2 pb-2 pt-3" style="font-size: small;">{{ user.days }}</div>
            </div>
        </div>
    </div>
</div>
