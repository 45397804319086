<div  [style.height]="(empNotification?.length < 1 && leaveNotification?.length < 1) ? '90px' : ''" class="card">
    <div class="header">
        <div class="head">{{'NOTIFICATION' | customTranslate : "Notifications"}}</div>
        <!-- SK07MAR24 modified  condition -->
        <div *ngIf="count >= 1" class="markasread">
            <div class="tick">
                <img src="../assets/images/icons/Vectortick.svg">
            </div>
            <div class="text ms-1" (click)="markasRead()"> {{'MARK_AS_READ'| customTranslate : 'Mark as read'}}</div>
        </div>
    </div>
    <div class="scroll" [style]="'overflow-y: auto'">
        <ng-container *ngIf="(empNotification?.length < 1 && leaveNotification?.length < 1); else recordsBlock">
            <div class="notifymsg">
                <div class="empBody">{{'NO_RECORDS' | customTranslate : 'No records/data found.'}}</div>
            </div>
        </ng-container>
        <ng-template #recordsBlock>
            <div class="notifymsg" *ngFor="let data of leaveNotification; let i = index">
                <div class="w-100" *ngIf="data.leaveType">
                    <!-- SK07MAR24 making click notification on whole div -->
                    <div class="body"  (click)="showApproval('leave','applied')">
                        <img class="img" src="../assets/images/icons/general/avatar-default.svg">
                        <div class="content">
                            <!-- Applied leave to HR-->
                            <div class="notif-text"><b>{{data.name}}</b> {{'HAS_APPLIED_FOR' | customTranslate : 'has Applied for' }}{{data.leaveType}} {{'REQUEST' | customTranslate : 'request'}}</div>
                            <div class="date-text">{{data.appliedon}}</div>
                        </div>
                    </div>
                </div>
                <div class="w-100" *ngIf="data.category">
                    <div class="body" (click)="showApproval('expense','applied')">
                        <img class="img" src="../assets/images/icons/general/avatar-default.svg">
                        <div class="content">
                            <!-- Applied Expense to HR--> 
                            <div class="notif-text"><b>{{data.name}}</b> {{'HAS_APPLIED_FOR' | customTranslate : 'has Applied for' }} {{data.category}} {{'EXPENSES' | customTranslate : 'expense'}} {{'REQUEST' | customTranslate : 'request'}}</div>
                            <div class="date-text">{{data.appliedOn}}</div>
                        </div>
                    </div>
                </div>
                <div class="w-100" style="border-bottom:solid 1px rgba(67, 69, 85, 0.2);"></div>
            </div>
            <div class="notifymsg" *ngFor="let data of empNotification; let i = index">
                <div class="body w-100"  (click)="showApproval(data,'apprej')">
                    <img class="img" src="../assets/images/icons/general/avatar-default.svg">
                    <div class="content">
                        <div class="notif-text"><b>{{data.viewedBy.name}}</b> {{'has' | customTranslate : 'has' }}
                            {{data.status}} {{'your'  | customTranslate : 'your'}} {{data.type}} {{'REQUEST' | customTranslate : 'request'}}</div>
                        <div class="date-text">{{data.viewedOn}}</div>
                    </div>
                </div>
                <div class="w-100"  style="border-bottom:solid 1px rgba(67, 69, 85, 0.2);"></div>
            </div>
        </ng-template>
    </div>

    <!-- SK07MAR24 removed view all notofications and made scrollable by default -->
    <!-- <div class="footer-text" *ngIf="((empNotification?.length > 3 || leaveNotification?.length > 3))">
        <div class="viewall"  (click)="scrollBar = true">View all
            Notifications</div>
    </div> -->
</div>