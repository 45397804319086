<div class="flex flex-direction-row tab-view-buttons">
    <ul class="nav nav-tabs fc-tabs width-100" id="commonTabs" role="tablist">
        <li *ngFor="let eachTypeOfComponent of browseTabs?.tabs; let i=index"  class="nav-item d-flex me-2" role="presentation">
            <button  class="nav-link active" [class.active]="displayedTabContent === eachTypeOfComponent.name" id="{{eachTypeOfComponent}}" 
                type="button" role="tab" aria-controls="common-component-tab-pane" aria-selected="false" (click)="currentTabNav(eachTypeOfComponent)"> <!-- data-bs-toggle="tab" data-bs-target="#common-component-tab-pane" (shown.bs.tab)="currentTabNav(eachTypeOfComponent)"> -->
                <a class="tabs" [class]="displayedTabContent === eachTypeOfComponent.name ? 'tab-active-color' : 'tab-color'">
                    {{eachTypeOfComponent.label | customTranslate : eachTypeOfComponent.label }}
                </a>
            </button>
            <svg-icon class="close-hover" *ngIf="eachTypeOfComponent.browsedTab" src="../assets/images/icons/general/cross-icon.svg" (click)="closeBrowseTab(eachTypeOfComponent,i)"></svg-icon>
        </li>
    </ul>                   
</div>
<!-- SK11MAY24 added filterColumns for table -->
<!-- SK14MAY24 added table width customization -->
<div class="tab-content mt-4" id="commonTabContent">
    <div *ngFor="let tab of browseTabs?.tabs; let i=index">
        <div *ngIf="displayedTabContent === tab.name">
            <div *ngIf="tab?.components == undefined">
                <div *ngIf="tab.type === 'dashboard'">
                    <app-dashboard [metricsArray]="metricsArray"></app-dashboard>
                </div>
                <div *ngIf="tab.type === 'table'">
                    <app-data-table 
                        [tableId]="tableInputs?.tableId"
                        [searchBar]="tableInputs?.searchBar" 
                        [data]="data"
                        [tableWidth]="tableInputs?.tableWidth" 
                        [headerLabels]="tableInputs?.headerLabels"
                        [translatePath]="'DYNAMIC_TABLE'"
                        [displayedColumns]="tableInputs?.displayedColumns"
                        [hyperLinkColumns]="tableInputs?.hyperLinkColumns"
                        [filterColumns]="tableInputs?.filterColumns || []"
                        [multipleFilter]="this.multipleFilterArray || []"
                        [cumulativeDataColumns]="tableInputs?.cumulativeDataColumns"
                        [fromWhere]="tableInputs?.fromWhere"
                        [withCheckBox]="tableInputs?.withCheckBox"
                        [isEditRow]="tableInputs?.isEditRow" 
                        [isDeleteRow]="tableInputs?.isDeleteRow"
                        [actionButton]="tableInputs?.actionButton"
                        [isButtons]="tableInputs?.isButtons"
                        [buttonArray]="tableInputs?.buttons"
                        [addInlineRecord]="tableInputs?.addInlineRecord"
                        [editColumn]="tableInputs?.editColumns || []"
                        (commonButtonClickEmit)="commonButtonClickEmit($event)"
                        (exportReports)="commonButtonClickEmit($event)"
                        (selectedValues)="tableCheckedDataa($event)"
                        (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
                        (onEditData)="onEditRecord($event)"
                        (OnDeleteData)="onDeleteRecord($event)"
                        (filteredArray)="searchFilterData($event)"
                        [filterDataArray]="filterKeys"
                        [moudleName]="moduleName">
                        </app-data-table>
                </div>
                <div *ngIf="tab.type === 'tabset'">
                </div>
                <div *ngIf="tab.type === 'nxtPage'">
                    <!-- SK24JUN24 language code update -->
                    <app-browse [nxtJSON]="nxtJSON" [bookValue]="bookValue" [languageCode]="languageCode" (nxtFormHandlerEmit)="nxtFormHandler($event)" (nxtButtonHandlerEmit)="nxtButtonHandler($event) " (handleCalendarDateEmit)="handleCalendarDate($event)" (handleCalendarEventDateEmit)="handleCalendarEventDate($event)"></app-browse>
                </div>
                <!-- SK12FEB24 added card component -->
                <div *ngIf="tab.type === 'card'">
                    <div *ngFor="let card of cardData">
                      <div class="">
                        <div class="{{card.cardSize}} mt-3">
                            <div class="static-width-for-card width-100">
                                <app-cards [inlineDetailed]="true">
                                    <div class="header-section flex content-space-between">
                                        <span>{{card.label | customTranslate : card.label}}</span>
                                        <div class="card-btn" *ngIf="card.isEditButton" (click)="onClickEditCard(card, tab)">
                                            <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                                class="card-icon"></svg-icon>
                                        </div>
                                    </div>
                                    <div class="body-section card-text">
                                      <div class="flex">
                                        <div class="row g-3 flex w-100">
                                          <div *ngFor="let detail of card.config.details" class="{{card.cardInlineSize}} mt-3 mb-3 d-flex flex-column">
                                            <label class="label-head">{{detail.label | customTranslate : detail.label}}</label>
                                            <label class="label-sub">{{detail.value}}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </app-cards>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <!-- SK10AUG24 NXT questionnaire -->
                <div *ngIf="tab.type === 'nxtQuestionnairePage' && nxtQuestionnaireId != '' ">
                    <lib-questionnaire [serv]="'api'" [qbId]="nxtQuestionnaireId" [api]="baseURL+'/salesforce/questionnaire'" (handleQuestion)="handleQuestionnaireQuestion($event)"  (handleSubmit)="handleQuestionnaireSubmit($event)"></lib-questionnaire>
                </div>
            </div>
            <!-- SK02FEB24, If components are defined, that component will be looped to get multiple components in single page -->
            <!-- SK11MAY24 added filter columns for table -->
            <!-- SK14MAY24 added table width customization -->
            <div *ngIf="tab?.components !== undefined">
                <!-- SK12FEB24, if the component is defined in tabs config, that will be looped and displayed -->
                <div class="row flex" *ngFor="let comp of tab?.components; let j=index">
                    <div *ngIf="comp.type === 'dashboard'">
                        <app-dashboard [metricsArray]="comp.config.metricsArray"></app-dashboard>
                    </div>
                    <div *ngIf="comp.type === 'table'">
                        <!-- SK 9JAN24 added ternary operator to solve undefined error  -->
                        <!-- 30JAN24 added edit/delete dynamic inputs -->
                        <app-data-table 
                            [tableId]="comp.config?.tableId"
                            [searchBar]="comp.config?.searchBar" 
                            [data]="comp?.config?.data"
                            [tableWidth]="comp?.config?.tableWidth"
                            [headerLabels]="comp.config?.headerLabels"
                            [translatePath]="'DYNAMIC_TABLE'"
                            [displayedColumns]="comp.config?.displayedColumns"
                            [hyperLinkColumns]="comp.config?.hyperLinkColumns"
                            [filterColumns]="comp.config?.filterColumns || []"
                            [cumulativeDataColumns]="comp?.config?.cumulativeDataColumns"
                            [multipleFilter]="comp.config?.multipleFilterArray || []" 
                            [fromWhere]="comp.config?.fromWhere"
                            [withCheckBox]="comp.config?.withCheckBox"
                            [isEditRow]="comp.config?.isEditRow" 
                            [isDeleteRow]="comp.config?.isDeleteRow"
                            [actionButton]="comp.config?.actionButton"
                            [isButtons]="comp.config?.isButtons"
                            [buttonArray]="comp.config?.buttons"
                            [addInlineRecord]="comp.config?.addInlineRecord"
                            [editColumn]="comp.config?.editColumns || []"
                            (commonButtonClickEmit)="commonButtonClickEmit($event)"
                            (exportReports)="commonButtonClickEmit($event)"
                            (selectedValues)="tableCheckedDataa($event)"
                            (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
                            (onEditData)="onEditRecord($event)"
                            (OnDeleteData)="onDeleteRecord($event)"
                            (filteredArray)="searchFilterData($event)"
                            [moudleName]="moduleName">
                        </app-data-table>
                    </div>
                    <div *ngIf="comp.type === 'tabset'">
                        <!-- <app-dashboard></app-dashboard> -->
                    </div>
                    <div *ngIf="comp.type === 'nxtPage'">
                        <!-- SK24JUN24 language code update to nxt -->
                        <app-browse [nxtJSON]="comp.config.nxtJSON" [bookValue]="bookValue" [languageCode]="languageCode" (nxtFormHandlerEmit)="nxtFormHandler($event)" (nxtButtonHandlerEmit)="nxtButtonHandler($event)" (handleCalendarDateEmit)="handleCalendarDate($event)" (handleCalendarEventDateEmit)="handleCalendarEventDate($event)"></app-browse>
                    </div>
                    <!-- SK14FEB24 card component -->
                    <div *ngIf="comp.type === 'card'">
                        <div class="row flex">
                          <div *ngFor="let card of cardData" class="{{card.cardSize}}">
                            <div class="mt-3">
                                <div class="static-width-for-card width-100">
                                    <app-cards [inlineDetailed]="true">
                                        <div class="header-section flex content-space-between">
                                            <span>{{card.label | customTranslate : card.label}}</span>
                                            <div class="card-btn" *ngIf="card.isEditButton" (click)="onClickEditCard(card, comp)">
                                                <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                                    class="card-icon"></svg-icon>
                                            </div>
                                        </div>
                                        <div class="body-section card-text">
                                          <div class="flex">
                                            <div class="row g-3 flex w-100">
                                              <div *ngFor="let detail of card.config.details" class="{{card.cardInlineSize}} mt-3 mb-3 d-flex flex-column">
                                                <label class="label-head">{{detail.label | customTranslate : detail.label}}</label>
                                                <label class="label-sub">{{detail.value}}</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </app-cards>
                                </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <!-- SK16APR24 attendance component in custom apps, data table can be specific employee table can be modified dynamicaklly-->
                    <div *ngIf="tab.type === 'attendanceTable'">
                        <!-- SK22APR24 attendance list, accessor and specific employee attendance table colums is sent as input to parent-->
                        <app-attendance-hrm [accessor]="comp.accessor" [employeeListAttendance]="comp.employeeListAttendance" [employeeAttendanceData]="comp.employeeAttendanceData"></app-attendance-hrm>
                    </div>
                    <!-- SK15FEB24 multi checkbox component -->
                    <!-- SK03OCT24 added congifs -->
                    <div *ngIf="comp.type === 'multiCheck'">
                        <app-multi-checkbox
                            [label]="comp?.config?.label" 
                            [checkboxList]="comp?.config?.checkboxList" 
                            [activeCheckboxList]="comp?.config?.activeCheckboxList" 
                            [buttonList]="comp?.config?.buttons" 
                            [cardInlineSize]="comp?.config?.cardInlineSize"
                            [cardSize]="comp?.config?.cardSize"
                            [cardClass]="comp?.config?.cardClass || 'mt-2' "
                            [checkBoxStyles]="comp?.config?.checkBoxStyles || '' "
                            [componentData]="comp"
                            (compDataEmit)="multiCheckEmit($event)"></app-multi-checkbox>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>

<!-- SK08JUL24 if condition to open modal -->
<app-modal *ngIf="openModal" [modalTitle]="modalProperties?.modalTitle" [modalFooter]="modalProperties?.isModalFooter" [saveButtonValue]="modalProperties?.modalSaveButtonValue" [isModalOpen]="openModal" (cancelButtonEmit)="modalClose($event)" [modalSize]="modalProperties?.modalSize">
    <div class="modal-body">
      <div>
        <!-- SK24JUN24 language code update to nxt -->
        <app-browse [nxtJSON]="nxtJSON" [bookValue]="bookValue" [languageCode]="languageCode" (nxtFormHandlerEmit)="nxtFormHandler($event)" (nxtButtonHandlerEmit)="nxtButtonHandler($event)" (handleCalendarDateEmit)="handleCalendarDate($event)" (handleCalendarEventDateEmit)="handleCalendarEventDate($event)"></app-browse>
      </div>
    </div>
</app-modal>