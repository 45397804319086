import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { Subscription } from 'rxjs';
import { SideNavEmitService } from '../../services/side-nav-emit.service';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ExperiencetabComponent } from './experiencetab/experiencetab.component';
import { EducationtabComponent } from './educationtab/educationtab.component';
import { DependenttabComponent } from './dependenttab/dependenttab.component';
import { DocumentComponent } from './document/document.component';
import { LeaveHistoryComponent } from './Leave-history/Leave-history.component';
import { ApplyLeavesComponent } from './leaves/apply-leaves/apply-leaves.component';
import { HolidaydetailsComponent } from './holidaydetails/holidaydetails.component';
import { BasicinfotabComponent } from './basicinfotab/basicinfotab.component';
import { WorktabComponent } from './worktab/worktab.component';
import { BankinfotabComponent } from './bankinfotab/bankinfotab.component';
import { GlobalAppTabsComponent } from 'src/app/components/global-apps/global-app-tabs/global-app-tabs.component';
import { EmpnotifyComponent } from 'src/app/components/empnotify/empnotify.component';
import { ApprovalNotificationtabComponent } from './leaves/approval-notificationtab/approval-notificationtab.component';
import { ExpenseTabComponent } from 'src/app/components/global-apps/expense-tab/expense-tab.component';
import { ExpensesTabComponent } from './expenses/expenses-tab/expenses-tab.component';
import { ActivatedRoute } from '@angular/router';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { ApiService } from 'src/app/services/api.service';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { ItEssComponent } from './it-ess/it-ess.component';
import { Constants } from 'src/app/models/Constants';
import { TranslateModule ,TranslateService} from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';



@Component({
  selector: 'app-ess',
  standalone: true,
  templateUrl: './ess.component.html',
  imports: [
    CommonModule,
    PageHeaderComponent, 
    FormsModule, 
    ReactiveFormsModule, 
    SuccessFailureMessageComponent,
    CardsComponent,
    PopoverComponent,
    FusionButtonComponent,
    AngularSvgIconModule,
    ExperiencetabComponent,
    EducationtabComponent, 
    DocumentComponent, 
    DependenttabComponent,
    LeaveHistoryComponent,
    ApplyLeavesComponent,
    HolidaydetailsComponent,  
    BasicinfotabComponent, 
    WorktabComponent, 
    BankinfotabComponent,
    ApprovalNotificationtabComponent, 
    GlobalAppTabsComponent, 
    EmpnotifyComponent, 
    ExpenseTabComponent, 
    ExpensesTabComponent,
    DataTableComponent,
    DropDownComponent,
    ItEssComponent,
    TranslateModule,
    ModalComponent,
    CustomTranslatePipe
  ],
  styleUrls: ['./ess.component.css']
})
export class EssComponent implements OnInit {
  
  @Input() isHamClicked:boolean = false;

  sideNavsubscription: Subscription;

  essTabToShow!: string ;
  pageComponentName = 'Ess';
  tamilPattern = "^[அ-ஹா-ௌா-ௗ்ௐ]*$";
  hideOrShowNav: string = "side-nav-container";
  errorMessage = '';
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  isDevMode: boolean = false;
  holidays:any;
  weekOff:any;
  sectionFilterIndex: number = 0;
  defaultDDValue = 'Select';
  selectedFinancialYear ='Select Financial Year' ;
  startYear = new Date().getFullYear();
  fyList : Array<any> = [];
  empUpdatesSubscription:Subscription;
  dropDownList: Array<any> = [];
  dropDownValue: any = 'Select month and year';
  queryMonth:any;
  payslipFile:any;
  paySlipDatum:Array<any> = [];
  openModal = false;
  modalProp:any;
  // SK25JUL24 to get the dynamic ESS
  isDynamicESS = false;
  configJSON:any;
  dynamicDetailsModule:any;
  dynamicDetailsTabs:any;
  hideStaticDetailsTabs:any;


  constructor(private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService,private route: ActivatedRoute, private globalValues: GlobalValuesService, private datePipe: DatePipe, private apiService: ApiService, private alertService: AlertService, private activeRoute: ActivatedRoute,  private sanitizer: DomSanitizer) {
    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.globalValues.globalValues();
    this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => {
      // SK01APR24 hitting api based on module selection in ESS
      if(navItem === "details"){
        if(this.employeeFinanceInfo === null || this.employeeFinanceInfo === undefined){
          this.apiService.fetchValue('/employee').subscribe((datas:any) => {
            this.selectedEmployee = datas.loggedInUser;
            this.employeeFinanceInfo = datas.financeInfo;
          });
        }
      } else if(navItem === "leaves"){
        if(this.employeeLeaves === null || this.employeeLeaves === undefined){
          this.apiService.fetchValue('/employee/leaves').subscribe((datas:any) => {
            this.employeeLeaves = datas;
          });
        }
      } else if(navItem === "expenses"){
        if(this.employeeExpenses === null || this.employeeExpenses === undefined){
          this.apiService.fetchValue('/employee/expense').subscribe((datas:any) => {
            this.employeeExpenses = datas;
          });
        }
      } else if(navItem === "payslips"){ //SK02APR24 payslips details fetch
        this.apiService.fetchValue('/employee/payslip').subscribe
        ({
          next: (savedData:any) => {
            savedData.currentNetPay.sort((a:any, b:any) => new Date("01 " + b.month).getTime() - new Date("01 " + a.month).getTime());
            this.paySlipDatum = savedData.currentNetPay;
          }
        });
      }
      this.essTabToShow = navItem
    });
    this.isDevMode = isDevMode();
    this.empUpdatesSubscription = this.alertService.passAlertType$.subscribe((alert: any) => {
      if (alert.type === 'successFetch') { this.getValues(alert.fetchId); }
    });
  }

  selectedEmployee:any;
  employeeFinanceInfo:any;
  empGet:any
  employeeLeaves:any;
  employeeExpenses:any
  /** This is Add button function used to create a new data in that form group */
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.essTabToShow = params['activeNav'] || 'details';
    });

    // SK25JUL24 to find the dynamic ESS and configurations
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'ess'){
          this.isDynamicESS = true;
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'details'){
              this.dynamicDetailsModule = modArr;
              this.hideStaticDetailsTabs = []
            }
          });
        }
      });
    } else {
      console.log('waiting for the api response');
    }

    // SK01APR24 to get employee leaves/expense/details
    if(this.selectedEmployee === null || this.selectedEmployee === undefined || this.employeeFinanceInfo === null || this.employeeFinanceInfo === null ){
      this.apiService.fetchValue('/employee').subscribe((datas:any) => {
        this.selectedEmployee = datas.loggedInUser;
        this.employeeFinanceInfo = datas.financeInfo[0];
        this.holidaysWeekOff(datas);
      });
    } 
    if(this.essTabToShow === "leaves"){
      if(this.employeeLeaves === null || this.employeeLeaves === undefined){
        this.apiService.fetchValue('/employee/leaves').subscribe((datas:any) => {
          this.employeeLeaves = datas;
        });
      }
      
    } else if(this.essTabToShow === "expenses"){
      if(this.employeeExpenses === null || this.employeeExpenses === undefined ){
        this.apiService.fetchValue('/employee/expense').subscribe((datas:any) => {
          this.employeeExpenses = datas;
        });
      }
    } else if(this.essTabToShow === "payslips"){ // payslips details fetch
      if(this.paySlipDatum.length === 0){
        this.apiService.fetchValue('/employee/payslip').subscribe
        ({
          next: (savedData:any) => {
            savedData.currentNetPay.sort((a:any, b:any) => new Date("01 " + b.month).getTime() - new Date("01 " + a.month).getTime());
            this.paySlipDatum = savedData.currentNetPay;
          }
        });
      }
    }
    
    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    }

    // the last five financial year list dropdown, that will be sent to IT declarations component
    for (let i = 0; i < 5; i++) {
      this.fyList.push(this.startYear - i + '-' + (this.startYear - (i - 1)));
    }

    // to set the current financial year as default
    const currentMonth = (new Date().getMonth() + 1);
    if((currentMonth >= 4) && (currentMonth <= 12)) {
      this.selectedFinancialYear = this.fyList[0];
    } else {
      this.selectedFinancialYear = this.fyList[1];
    }
    const token1 = localStorage.getItem('auth_token') as string;
    let empDetails = (JSON.parse(window.atob(token1.split('.')[1])));
    // this.getValues(empDetails.employeeId);
  }

  getValues(employeeId:any){
    if(this.globalValues.permissions.includes("HRM User") || this.globalValues.permissions.includes("HRM Manager") || this.globalValues.permissions.includes("Super User")){
      this.apiService.fetchValue('/hrm?employeeid='+employeeId).subscribe((getData:any)=>{
        this.selectedEmployee = getData.loggedInUser;
        this.employeeFinanceInfo = getData.financeInfo[0];
        this.employeeLeaves = getData?.leaves;
        this.employeeExpenses = getData?.expense;
        this.holidaysWeekOff(getData);
      })
      this.apiService.fetchValue('/hrm/allemployees').subscribe((datum: any) => {
        this.empGet = datum;
      })
    } else {
      this.apiService.fetchValue('/employee').subscribe((datas:any) => {
        this.selectedEmployee = datas.loggedInUser;
        this.employeeFinanceInfo = datas.financeInfo;
        this.holidaysWeekOff(datas);
      });
      this.apiService.fetchValue('/employee/leaves').subscribe((datas:any) => {
        this.employeeLeaves = datas;
      });
      this.apiService.fetchValue('/employee/expense').subscribe((datas:any) => {
        this.employeeExpenses = datas;
      });
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sideNavsubscription.unsubscribe();
  }

  sideNavChange(event: any){
    //if(this.isDevMode) console.log("asasdasdasdasdasdas",JSON.stringify(event));
  }

  saveSuccess(){
    this.showSubmissionMsg = true;
    setTimeout(()=>{
      this.showSubmissionMsg = false;
    }, 5000);
    this.errorMessage = '';
  }

  deleteSuccess() {
    this.showDeletedMsg = true;
    setTimeout(()=>{
      this.showDeletedMsg = false;
    }, 5000);
    this.errorMessage = "";
  }

  showError(err:any){
    this.showErrorMsg = true;
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
  }

  getDatas:any;
  getUserData(){
    this.apiService.fetchValue('/user').subscribe((datas:any) => {
      this.getDatas = datas;
    });
  }

  finYear(event:any){
    this.selectedFinancialYear = event.data;
  }

  holidaysWeekOff(data:any){
    this.holidays = data.loggedInUser?.holidays || [];
    this.holidays.forEach((holidayElement:any) => {
      holidayElement.type = holidayElement.leaveType;
      const startDate = this.datePipe.transform(holidayElement.startDate, 'mediumDate');
      const endDate = this.datePipe.transform(holidayElement.endDate, 'mediumDate')
      if(startDate === endDate){
        holidayElement.days = this.datePipe.transform(holidayElement.startDate, 'mediumDate');
      } else {
        holidayElement.days = this.datePipe.transform(holidayElement.startDate, 'mediumDate') + ' to ' + this.datePipe.transform(holidayElement.endDate, 'mediumDate');
      }
    });
    const weekoffDays = data.loggedInUser?.weekoff || []; 
    const days = weekoffDays.days || [];let loopDays = '';
    days.forEach((weekOffElement:any, index:number) => {
      loopDays = loopDays + weekOffElement.value + (index < (days.length - 1)? ', ' : '');
    });
    const dayObject = {days :loopDays };
    const typeObject = {type : weekoffDays.leaveType}
    this.weekOff = [{...dayObject,...typeObject}];
  }
  
  // SK02APR24 payslip file processed as text
  processFile(url:any, payrollMonth: string, from:any) : any{
    this.payslipFile = "";
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = (e1:any) =>  {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        const blob = request.response;
        // then blob is passed here to read the as text
        from === 'viewPayslip' ? this.onChange(blob, payrollMonth) : this.onChangeDownload(blob, payrollMonth);
    };
    request.send();
  }

  // SK02APR24 processed text is converted to blob
  onChange(event:any, payrollMonth:any) {
    this.payslipFile = "";
    var reader = new FileReader();
    reader.onload = (e: any) => {
      var linkSource = e.target.result;
      const byteCharacters = window.atob(linkSource);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      this.convertPdf(blob);
    };
    reader.readAsText(event);

  }  
  
  // SK02APR24 converting blob again as base64 to get the proper format
  convertPdf(filePay:any){
    var file: File = filePay;
    var reader: FileReader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      this.payslipFile = this.sanitizer.bypassSecurityTrustResourceUrl(res as any);
    };
    reader.readAsDataURL(file);
  }

  // SK02APR24 processing and downloading the base64 file
  onChangeDownload(event:any, payrollMonth:any) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      var linkSource = e.target.result;
      const byteCharacters = window.atob(linkSource);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
    
      const url = URL.createObjectURL(blob);
      const fileName = "Payslip_" + payrollMonth;
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.click();
      this.alertService.messageOnPass('success', 'Payslip downloaded successfully');  
    };
    reader.readAsText(event);
  }

  // SK02APR24 download emit icon
  downloadIconEmit(evt:any){
    if(evt.element.file)this.processFile(evt.element.file, evt.element.month, 'download');;
  }

  // SK02APR24 view emit icon
  viewIconEmit(evt:any){
    this.modalProp = {
      modalTitle:"Payslip",
      modalSize:"modal-lg"
    }
    this.openModal = true;
    if(evt.element.file)this.processFile(evt.element.file, evt.element.month, 'viewPayslip');
  }

  // SK02APR24 closing opened modal
  modalClose(event:any){
    this.openModal = false;
  }

}