import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NxtAppModule } from '@rangertechnologies/ngnxt';
import { ApiService } from 'src/app/services/api.service';
import { NbLayoutModule } from '@nebular/theme';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';

@Component({
  selector: 'app-hire-registration',
  standalone: true,
  imports: [CommonModule, NxtAppModule, NbLayoutModule],
  templateUrl: './hire-registration.component.html',
  styleUrls: ['./hire-registration.component.css']
})
export class HireRegistrationComponent implements OnInit {
  nxtJSON:any;
  bookValue:any;
  languageCode:any;
  pageComponentName = 'HireRegistration';
  companyId:any
  errorPage = false; //SK01AUG24
  successRegister = false; //SK01AUG24
  successMessage = "Thank you for registering. Please check your mail"; //SK01AUG24

  constructor(private router: Router, private routeActive: ActivatedRoute, private apiService: ApiService, private alertService: AlertService, private errorHandler:ErrorHandlerService) {
    // SK31JUL24 gettings company id from params
    this.routeActive.paramMap.subscribe(params => {
      this.companyId = params.get('companyId');
      // SK01AUG24 registration url updated. The NXT page will be fetched from settings
      this.apiService.fetchValue("/hireregister?companyId="+this.companyId).subscribe({
        next : (success:any) => {
          console.log(success);
          this.nxtJSON = success["jsonBody"]
          this.successMessage = success["jsonBody"]["questionbook"]["Summary_Text__c"];
        },
        error: (err: HttpErrorResponse) => {
          this.errorPage = true;
          this.alertService.messageOnPass('error', err.error.message);
        }
      })
    } 
  )};

  ngOnInit(): void {

  }

  // SK31JUL24 after saving form, json details fetched and processed for saving
  nxtFormHandlerHRM(event:any){
   
    if(event.action === "submit"){
      let body:any = {}
      event.data.forEach((element:any) => {
        // SK07AUG24 ishide check is done
        if(!element["Is_Hide__c"]){
          body[element["Reference_Field__c"]] = element.input
        }
      })

      const currentURL = window.location.href;
      //To extract the domain from URL;
      const domain = (currentURL.split('/')[2]).split(".")
      if (domain.includes('valarhr')) {
        body["sourceURLClient"] = "valarhr";
      } else {
        body["sourceURLClient"] = "fusion";
      }
      this.apiService.writeValue('post', '/hireregister/'+this.companyId+'/signup', body)
        .subscribe({
          next: (savedData:any) => {
            this.bookValue = {};
            this.successRegister = true; //SK02AUG24
            this.saveSuccess(savedData.message);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
      });
    }
  }

    // SK31JUL24 converting base64 to blob
    createFormData(base64: string, fileName: string, contentType: string): FormData {
      const formData = new FormData();
      const blob = this.base64ToBlob(base64, contentType);
      formData.append('files', blob, fileName);
      return formData;
    }
  
    // SK31JUL24 converting base64 to blob
    base64ToBlob(base64: string, contentType: string): Blob {
      base64 = base64.split(",")[1];
      const byteCharacters = atob(base64);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    }


  // SK31JUL24 succes message comp on save
  saveSuccess(event:any) {
    this.alertService.messageOnPass('success',event);
  }

  // SK31JUL24 error message comp on save
  errorMessage = '';
  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

}
