<div class="table-layout" [id]="tableId">
  <div class="m-4">
    <div class="d-flex justify-content-between overflow-scroll">
      <div class="search mt-4 me-2" *ngIf="searchBar">
        <div class="search-bar">
          <input class="medium-font" type="text" placeholder="{{ 'SEARCH' | customTranslate : 'Search'  }}..." matInput (keyup)="applyFilter($event)" #input>
          <img class="card-btn" src="../assets/images/icons/general/Search.svg">
        </div>
      </div>
      <div class="flex mt-4 me-2" *ngIf="isButtons">
        <div class="ms-1 me-1 flex" *ngFor="let button of buttonArray" >
          <app-fusion-button *ngIf="button.name !== 'Export' && button.name !== 'Import'" (buttonClickEmit)="commonButtonClick(button)" [buttonType]="button.class" [buttonValue]="button.name | customTranslate : button.name ">  <!--SKS25OCT24 If the value is 'Import' or 'Export', this button does not work-->
          </app-fusion-button>
          <!-- SK03OCT24 export and import buttons in table -->
          <div *ngIf="button.name === 'Export'" class="d-flex align-items-center flex-row ms-2 me-2 cursor-pointer short-button-white-bg button-hov"
            (click)="exportButtonEmit(button)">
            <svg-icon src="../assets/images/icons/general/upload-icon.svg " class="card-icon color me-1"
                title="download"></svg-icon>
                <div> {{ "EXPORT" | customTranslate : 'Export' }}</div>
          </div>
          <div *ngIf="button.name === 'Import'" class="d-flex align-items-center flex-row me-2 cursor-pointer short-button-white-bg button-hov"
          (click)="importButtonEmit(button)">
          <svg-icon src="../assets/images/icons/general/download-icon.svg" class="card-icon color me-1"
              title="download"></svg-icon>
          <div>{{ "IMPORT" | customTranslate : 'Import'}} </div>
        </div>
        </div>
      </div>
      
      <!-- SK23FEB24 for adding new record in table -->
      <div class="flex mt-4 me-2 cursor-pointer" *ngIf="addInlineRecord" (click)="addTableRecord(inlineElement)" [matTooltip]="'Add New Record'">
        <div class="ms-1 me-1">
          <svg-icon src="../assets/images/icons/general/add_record_table.svg" class="nav-icon"></svg-icon>
        </div>
      </div>
    </div>
     <!-- to include title for the table -->
     <div class="table-title" *ngIf="titleOn">
      {{title}}
    </div>

    <div class="table-margin mt-4">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" style="width: {{tableWidth}}" matSort>
                    <!-- Checkbox Columns -->
          <ng-container [sticky]="true"  *ngIf="withCheckBox" matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="sticky-column head-color">
              <mat-checkbox  (click)="$event.stopPropagation()" (change)="$event ? masterToggle()  : null" [checked]="selection.hasValue()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" style="z-index: 101;">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="body-color">
              <!-- SK18OCT24 check payroll Inprogress/Completed from backend data -->
              <mat-checkbox [matTooltip]="(fromWhere === 'payrollTable' || fromWhere === 'paySlip') ? (row?.payProcessComment || '') : '' "  (click)="$event.stopPropagation()"
                (change)="$event ? separateRowSelect(selection.toggle(row), row) : null"
                [checked]="selection.isSelected(row)" [disabled]="(fromWhere === 'payrollTable' && row.isPayProcessed === true) || (fromWhere === 'paySlip' && row.isPayProcessed === false) ">
              </mat-checkbox>
            </td>
            <td  mat-footer-cell *matFooterCellDef>{{ "" }}
            </td>
          </ng-container>

          <!-- data Columns -->
          <!-- set the matColumnDef attribute to the column name and use interpolation to dynamically generate the mat-header-cell and mat-cell elements based on the column name-->
          <ng-container *ngFor="let column of displayedColumns; let i=index" [matColumnDef]="column"
            [stickyEnd]="i === (stickyCondition - 1) ? true : false">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="head-color ms-2  ps-2 pe-2"
              [class]=" i === (stickyCondition - 1) ? 'sticky-column' : '' " (click)="$event.stopPropagation()">
              <span [class]="fromWhere === 'payrollTable' || fromWhere === 'paySlip'|| fromWhere === 'leaveMaster' ? 'table-width  ms-4 me-4' : 'table-width' ">{{headerTranslateLabels[displayedColumns.indexOf(column)] }}</span>
              <div (click)="$event.stopPropagation()">
                <img class="ms-1 position-relative" *ngIf="filterColumns.includes(column)"
                  (click)="searchFilter = !searchFilter; filter(column); selectedFilterColumn = column" src="../assets/images/icons/general/filter-icon.svg">
                <app-search-filter class="search-component position-absolute"
                  [class]="searchFilter === true && column === selectedFilter ? 'd-block' : 'd-none' "
                  [filterItems]="filterArray" (emitValues)="filteredData($event)"></app-search-filter>
              </div>
              <!-- SK27FEB24 removed edit icon in header -->
              <!-- <img class="ms-1" style="width: 13px; height: 13px" *ngIf="editColumn.includes(column)" src="../assets/images/icons/Vectorpencil.svg"> -->
            </th>
            <td mat-cell class="body-color  ps-2 pe-2" *matCellDef="let element; let j = index">
              <div *ngIf="fromWhere !== 'leaveMaster' && (column !== 'active' && (fromWhere === 'hrmTaxComponent' || fromWhere === 'payrollSettingComponent' || fromWhere === 'hrm' || fromWhere  === 'compensation'|| fromWhere  === 'payrollTable'|| fromWhere  === 'payrollCompare'|| fromWhere  === 'paySlip' || fromWhere  === 'approval'  || fromWhere  === 'leave' || fromWhere  === 'expense' || fromWhere  === 'expenseApproval' || fromWhere  === 'leaveAllocation' ||fromWhere  === 'parkingApp'  ||(fromWhere  === 'common' && column != 'signature')))">
                <!-- SK10APR24 getting the table values using pipe instead of function to increase performance-->
                <div *ngIf=" ('-' | editColumnCheck: column : editColumn) === ('') ">
                  <span class ="font-size-13 {{checkHyperlinkCheck(column) ||  fromWhere  === 'hrm' || fromWhere  === 'compensation' || fromWhere  === 'parkingApp' ? ' hyper-link clickable ' : ''}} {{fromWhere === 'payrollTable' || fromWhere === 'paySlip' ? 'table-width' : '' }}"
                    *ngIf="hyperLinkColumns?.includes(column) || isFirstCell(column)"
                    (click)="tableClick(element); onClickHyperlink(column,element, j, checkHyperlinkCheck(column))">
                    {{ '-' | getValue: element : column  }}
                  </span>
                  <div *ngIf="!isFirstCell(column) && !editColumn?.includes(column) && column != 'receipt'" [class]="fromWhere === 'payrollTable' || fromWhere === 'paySlip' ? 'table-width' : '' " >
                    <div *ngIf="!checkHyperlinkCheck(column)" class="font-size-13">
                      {{ '-' | getValue: element : column  }}
                    </div>
                  </div>
                </div>
                <!-- SK27FEB24 added input box for inline edit input-->
                <div *ngIf="editColumn.length > 0">
                  <div *ngIf=" ('-' | editColumnCheck: column : editColumn) === ('string') " class="on-edit d-flex">
                    <input #editColElement  class="lop-input table-width" [class]="element.editRow ? 'input-box' : '' " matInput placeholder="Enter"
                      [value]="element[column]" [(ngModel)]="element[column]" [readOnly]="element.editRow ? false : true">
                  </div>
                  <!-- SK10APR24 In table, like text input, added drop down and time inputs as well -->
                  <div *ngIf=" ('-' | editColumnCheck: column : editColumn) === ('object') " class="on-edit d-flex">
                    <input *ngIf=" ('-' | editColumnType: column : editColumn) === ('text') " #editColElement  class="lop-input table-width" [class]="element.editRow ? 'input-box' : '' " matInput placeholder="Enter"
                      [value]="element[column]" [(ngModel)]="element[column]" [readOnly]="element.editRow ? false : true">
                    <input *ngIf=" ('-' | editColumnType: column : editColumn) === ('time') " type="time"  class="lop-input table-width" matInput [class]="element.editRow ? 'input-box' : '' "
                      placeholder="HH:mm:ss" [(ngModel)]="element[column]" [disabled]="element.editRow ? false : true" />
                    <div *ngIf=" ('-' | editColumnType: column : editColumn) === ('dropdown') ">
                      <select type="dropdown" *ngIf="element.editRow"  class="lop-input table-width" matInput [class]="element.editRow ? 'input-box' : '' "
                        [(ngModel)]="element[column]">
                        <option [disabled]="element.editRow ? false : true"  *ngFor="let data of [] | editColumnDropdown: column : editColumn " [value]="data.value || data.name">{{data.name}}</option>
                      </select>
                      <input *ngIf="!element.editRow" #editColElement  class="lop-input table-width" [class]="element.editRow ? 'input-box' : '' " matInput placeholder="Enter"
                        [value]="element[column]" [(ngModel)]="element[column]" [readOnly]="true">
                    </div>
                    
                  </div>
                </div>
                
                <app-popover *ngIf="column === 'receipt' && !editColumn.includes(column)  && receiptImg == true && element[column].length != 0"   [isBootstrap]="false" [dynamicPopover]="'Popover string comes here'">
                  <div class="hover-base">
                    <img class="ms-1" src="../../../assets/images/icons/general/file-attach.svg" >
                  </div>
                  <div class="hover-content">
                      <ul style="list-style: none;">
                          <li *ngFor="let data of element[column]">
                            <div class="mt-2 cursor-pointer expense-file" (click)="expenseAttachment(data?.file)" >{{data?.fileName}}</div>
                          </li>
                      </ul>
                  </div>
                </app-popover>
              </div>
              <div *ngIf="fromWhere === 'leaveMaster'">
                <!-- <div [class]="(element[column]) === 'Week Off' ? 'weekoff-color' : '' " class="font-size-13 table-width ms-4 me-4">{{ (column == 'default' || column == 'active' || column == 'paidLeave') ? '' :  element[column]}}</div> -->
                <div *ngIf="element[column] === 'Week Off'" class="font-size-13 table-width ms-4 me-4 weekoff-color">{{ 'WEEK_OFF_DAY' | customTranslate : 'Week Off'  }}</div>
                <div *ngIf="element[column] === 'Default'" class="font-size-13 table-width ms-4 me-4">{{ 'DEFAULT_DAY' | customTranslate : 'Default'  }}</div>
                <div *ngIf="element[column] != 'Week Off' && element[column] != 'Default'" class="font-size-13 table-width ms-4 me-4">{{ (column == 'default' || column == 'active' || column == 'paidLeave') ? '' : element[column] }}</div>
                <input *ngIf="(column === 'default' || column === 'active' || column === 'paidLeave')" class="form-check-input ms-4 me-4" [checked]="element[column] == true" type="checkbox" disabled>
              </div>
              <div *ngIf="fromWhere === 'payrollSettingComponent' && column === 'combinedColumn'">
                <!-- for the Payroll Settings Component ( Side Screen Component )-->
                <!-- Since the last column is a combination of data and it is also conditional based on the paytype and calculation type we are using this div-->
                <!-- if Component type is Earnings and
                      calculationType is 'flat' then output should be        => "'payType": Flat, Amount of 'amount'"
                      calculationType is 'percentage' then output should be  => "'payType': 'amount' % of Basic "
                      calculationType is 'fixed' then output should be       => "'payType': 'calculationType'" --> 
                <div class="font-size-13">
                  <span *ngIf="element['calculationType'] == 'flat'" [class]="'calculationType-'+element['calculationType']">{{element['payType']+': Flat, Amount '+element['amount']}}</span>
                  <span *ngIf="element['calculationType'] == 'percentage'" [class]="'calculationType-'+element['calculationType']">{{element['payType']+': '+element['amount']+' % of Basic'}}</span>
                  <span *ngIf="element['calculationType'] == 'fixed'" [class]="'calculationType-'+element['calculationType']">{{element['payType']+': '+element['amount']+' % of Gross'}}</span>
                </div>
              </div>
              <div *ngIf="fromWhere === 'payrollSettingComponent' && column === 'active'">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" [attr.checked]="element['active'] ? true : null" (click)="element[column]=!element[column]; onToggleChange.emit(fromWhere === 'payrollSettingComponent' ? element : '')">
                </div>
              </div>
            </td>
            <!-- SK16JUL24 to get the sum of column data -->
            <td class="ps-2 pe-2 fw-bold" mat-footer-cell *matFooterCellDef="let element; let j = index">
              {{ '' | totalColumSum: data : cumulativeDataColumns : column }}
            </td>
          </ng-container>
           
           <!-- action button Columns -->
           <!-- 30JAN24 condition for edit and delete row in table for dynamic table data // SK27FEB24 made action column as sticky-->
          <ng-container [stickyEnd]="true" *ngIf="actionButton || isDeleteRow || isEditRow" matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="head-color action-size sticky-column"> {{ actionColumHeader | customTranslate : actionColumHeader  }} </th>
            <td  mat-cell *matCellDef="let element; let i=index" class="body-color">
              <div class="d-flex flex-row">
                <div *ngIf="element.status === 'Rejected'|| fromWhere === 'leaveMaster' || fromWhere === 'payrollSettingComponent' || fromWhere === 'hrmTaxComponent' || isEditRow">
                  <app-fusion-button *ngIf="fromWhere == 'leave'" [buttonType]="'text-only'" [buttonValue]="'COMMENTS' | customTranslate : 'comments' " (buttonClickEmit)="onEdit(element.rejectionReason, i)" data-bs-toggle="modal"
                    data-bs-target="#rejectInfoModal">
                  </app-fusion-button>
                  <img *ngIf="fromWhere == 'expense' || fromWhere === 'leaveMaster' || fromWhere === 'payrollSettingComponent' || fromWhere === 'hrmTaxComponent' || isEditRow" [matTooltip]="editIconTooltip"  (click)="onEdit(fromWhere === 'payrollSettingComponent' || isEditRow ? element : element._id, i)" src="../assets/images/icons/Vectorpencil.svg">
                  <!-- <img *ngIf="fromWhere == 'expense'" (click)="delete(element._id)"  src="../assets/images/icons/Vectordelete.svg"> -->
                </div>
                <div  *ngIf="element.status === 'Pending' || fromWhere === 'leaveMaster' || fromWhere === 'payrollSettingComponent' || fromWhere === 'hrmTaxComponent' || fromWhere == 'expenseApproval' || isDeleteRow">
                  <app-fusion-button *ngIf="fromWhere == 'leave'" [buttonType]="'text-only'" [buttonValue]=" 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  " (buttonClickEmit)="delete(element, i)" data-bs-toggle="modal" data-bs-target="#cancelLeave"></app-fusion-button>
                  <img *ngIf="fromWhere == 'expense' || fromWhere === 'leaveMaster' || fromWhere === 'payrollSettingComponent' || fromWhere === 'hrmTaxComponent' || isDeleteRow" [matTooltip]="'Delete Record'" (click)="delete(fromWhere === 'payrollSettingComponent' || isDeleteRow ? element : element._id, i)" src="../assets/images/icons/Vectordelete.svg">
                  <div *ngIf="fromWhere == 'approval' || fromWhere == 'expenseApproval'" class="flex">
                    <img *ngIf="(fromWhere === 'expenseApproval' && element.status === 'Approved'&& element.reImbursed !== 'Reimbursed') || fromWhere == 'approval'" class="approval-image me-2" style="padding: 0px;" (click)="onApprove( element.employeeId, element._id, element.reImbursed )" src="../assets/images/icons/general/Approved.svg">
                    <img *ngIf="fromWhere !== 'expenseApproval'" class="approval-image"  style="padding: 0px;" (click)="onReject( element.employeeId, element._id )" src="../assets/images/icons/general/Declined.svg" data-bs-toggle="modal" data-bs-target="#approvalReject">
                  </div>
                </div>
                <!-- SK02APR24 added preview and download icons -->
                <div *ngIf="viewIcon" class="ms-2">
                  <svg-icon src="../assets/images/icons/general/preview.svg" class="card-icon color" title="preview" (click)="viewIconClick(element)"
                    style="cursor: pointer;"></svg-icon>
                </div>
                <div *ngIf="downloadIcon" class="ms-2">
                  <svg-icon src="../assets/images/icons/general/download-icon.svg" class="card-icon color" title="download"
                  (click)="downloadIconClick(element)" style="cursor: pointer"></svg-icon>
                </div>
              </div>
              <div>
                
                <app-fusion-button *ngIf="fromWhere == 'document-icons'" [buttonType]="'custom-btn'"
                 [fcBtnIconLeftSrc]="'../assets/images/icons/general/downloadfile.svg'" (buttonClickEmit)="downloadPdf(element)"></app-fusion-button>
                <app-fusion-button *ngIf="fromWhere == 'document-icons'" [buttonType]="'custom-btn'"
                 [fcBtnIconLeftSrc]="'../assets/images/icons/general/pencil-icon.svg'" (buttonClickEmit)="delete(element, i)"
                  data-bs-toggle="modal" data-bs-target="#documentsInfoModal"></app-fusion-button>
                  <app-fusion-button *ngIf="fromWhere == 'document-icons'" [buttonType]="'custom-btn'"
                  [fcBtnIconLeftSrc]="'../assets/images/icons/Vectordelete.svg'" (buttonClickEmit)="delete(element, i)"
                   data-bs-toggle="modal" data-bs-target="#deleteDocModal"></app-fusion-button>
              </div>
            </td>
            <td  mat-footer-cell *matFooterCellDef="let element; let i=index">{{ "" }}
            </td>
          </ng-container>

          <!-- total Row -->
          <ng-container  [sticky]="true"  matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef class="sticky-column head-color">
            </th>
            <td mat-cell *matCellDef="let row" class="body-color">
            </td>
            <!-- RS27AUG24 -->
            <!-- Used the translate pipe to successfully implement the translation -->
            <td mat-footer-cell *matFooterCellDef class="fw-bold">{{'TOTAL' | customTranslate : 'Total' }}
            </td>
          </ng-container>

          <!-- Render the columns -->
          <!-- SK16JUL24 adding the cumulative data row as footer -->
          <div [ngSwitch]="renderColumns">
            <div *ngSwitchCase="'select'">
              <tr mat-header-row *matHeaderRowDef="['select'].concat(displayedColumns) sticky: true"></tr>
              <tr mat-row  *matRowDef="let row; columns:  ['select'].concat(displayedColumns);"></tr>
              <!-- SK19JUL24 table total update -->
              <tr [class]="cumulativeDataColumns != undefined && cumulativeDataColumns.length !== 0 ? '' : 'border border-white' "  mat-footer-row *matFooterRowDef="cumulativeDataColumns != undefined && cumulativeDataColumns?.length !== 0 ? ['total'].concat(displayedColumns) : null "></tr>
            </div>
            <div *ngSwitchCase="'action'">
              <tr mat-header-row *matHeaderRowDef="displayedColumns.concat(['action']) sticky: true"></tr>
              <tr mat-row  *matRowDef="let row; columns: displayedColumns.concat(['action']); "></tr>
              <tr [class]="cumulativeDataColumns != undefined && cumulativeDataColumns.length !== 0 ? '' : 'border border-white' "  mat-footer-row *matFooterRowDef="cumulativeDataColumns != undefined && cumulativeDataColumns?.length !== 0 ? ['total'].concat(displayedColumns).concat('action') : null"></tr>
            </div>
            <!-- 30JAN24 for both checkbox and action columns-->
             <div *ngSwitchCase="'both'">
              <tr mat-header-row *matHeaderRowDef="['select'].concat(displayedColumns).concat('action') sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: ['select'].concat(displayedColumns).concat('action')" (click)="tableClick(row)"></tr>
              <tr [class]="cumulativeDataColumns != undefined && cumulativeDataColumns.length !== 0 ? '' : 'border border-white' "  mat-footer-row *matFooterRowDef="cumulativeDataColumns != undefined && cumulativeDataColumns?.length !== 0 ? ['total'].concat(displayedColumns).concat('action') : null"></tr>
            </div> 
            <div *ngSwitchDefault>
              <tr  mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
              <tr mat-row  *matRowDef="let row;let i =index; columns: displayedColumns;"></tr>
              <tr [class]="cumulativeDataColumns != undefined && cumulativeDataColumns.length !== 0 ? 'd-block' : 'border border-white' " mat-footer-row *matFooterRowDef="cumulativeDataColumns != undefined && cumulativeDataColumns?.length !== 0 ? ['total'].concat(displayedColumns) : null"></tr>
            </div>
          </div>

          <tr class="mat-row" *matNoDataRow>
            <!-- SK9JAN24 Removed attr.colspan since it throws undefined error -->
            <td class="mat-cell">
              {{'NO_RECORDS' | customTranslate : 'No records/data found.' }}
            </td>
          </tr>
        </table>
      </div>
      <!-- SK23FEB24 table input save button changes -->
      <div class="d-flex justify-content-end">
        <div class="flex mt-4 me-2 cursor-pointer" *ngIf="addInlineRecord " (click)="addTableRecord(inlineElement)" [matTooltip]="'Add New Record'">
          <div class="ms-1 me-1">
            <svg-icon src="../assets/images/icons/general/add_record_table.svg" class="nav-icon"></svg-icon>
          </div>
        </div>
        <!-- SK27FEB24 removed button disable logic, SK13MAR24 added another condition for button showing-->
        <app-fusion-button *ngIf="editColumn.length > 0  || tableSaveButton" buttonType="btn btn-primary mt-4 me-2"  (buttonClickEmit)="saveButton()" buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save'  }}"></app-fusion-button> 
      </div>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="itemsPerPage" [pageSize]="10"></mat-paginator>
    </div>
  </div>
</div>

<!-- SK03OCT24 while clicking export button, side screen will be displayed, with fields selection and filter boxes -->
<app-common-screen *ngIf="exportSidescreen" headerTitle="{{'Export Data' | customTranslate : 'Export Data'}}" (sideScreenEmittedEvent)="exportSidescreen = false" >
  <div class="side-main-content">
        <div class="d-flex align-items-center flex-row p-3 justify-content-center cursor-pointer short-button-white-bg button-hov"
            (click)="exportButtonSideNav()">
            <svg-icon src="../assets/images/icons/general/upload-icon.svg " class="card-icon color me-1"
                title="download"></svg-icon>
            <div>{{ 'Export File' ? ( 'Export File' | customTranslate : 'Export File'  ) : 'Export File' }}</div>
        </div>
        <!-- SK03OCT24 displaying components from parent (multipleCheckBox/multipleFilter) -->
        <div *ngFor="let type of filterScreenConfigs">
          <app-multi-checkbox *ngIf="type.type === 'multipleCheckBox' " class="ms-2 me-2"
              [label]="type?.label" 
              [cardSize]="type?.cardSize"
              [cardInlineSize]="type?.cardInlineSize"
              [checkboxList]="type?.checkboxList" 
              [activeCheckboxList]="type?.activeCheckboxList" 
              [buttonList]="type?.buttons" 
              [displayValues]="type?.displayValues" 
              [componentData]="type"
              [checkBoxStyles]="type?.checkBoxStyles"
              (compDataEmit)="exportFieldsButtonEmit($event)">
          </app-multi-checkbox>
          <app-multiple-filter *ngIf="type.type === 'multipleFilter' "  class="ms-2 me-2"
            [isDateFilter]="type?.isDateFilter || false"
            [label]="type?.label" 
            [cardSize]="type?.cardSize"
            [cardInlineSize]="type?.cardInlineSize"
            [buttonList]="type?.buttons" 
            [displayValues]="type?.displayValues" 
            [componentData]="type"
            [checkBoxStyles]="type?.checkBoxStyles"
            [selectionData]="type?.selectionData"
            (compDataEmit)="exportFilterButtonEmit($event)"
            (selectedValuesEmit)="selectedValuesEmit($event)"
            (filterDateEmit)="filterDateEmit($event)">
          </app-multiple-filter>
        </div>
  </div>
</app-common-screen>
<!-- SKS25OCT24 bulk upload -->
<div *ngIf="uploadModal" class="modal fade show d-block" id="uploadModal" tabindex="-1" aria-labelledby="employeeUploadModalLabel"
  [attr.aria-hidden]="!uploadModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 mb-0" id="personalModalLabel">Upload {{moudleName}}</h1>
        <button type="button" class="btn-close" (click)="filesArrayClear()" aria-label="Close"></button>
      </div>
      <div class="modal-body padding-space">
        <div class="d-flex row align-items-center modal-with-list">
          <b class="bold-text">Steps to upload {{moudleName}}</b>
          <ol>
            <li>Download the '{{moudleName}}' template <span class="link-to-download" (click)="downloadTemplate()">here</span>.</li>
            <li>Fill the appropriate {{moudleName}} Details and upload it below.</li>
          </ol>
          <app-file-upload [fileUploadId]="'uploadEmployee'" [docVals]="['5 mb','csv']" [multiSelectFile]="false" [showPreview]="'fileName'"
            [acceptType]="'.csv,text/csv,.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            (selectedFilesEmitter)="userFileEmitter($event)" [uploadReadyFiles]="filesArrayComp" (documentDelete)="deletedFileInComp($event)">
          </app-file-upload>
        </div>
      </div>
      <div class="modal-footer">
        <app-fusion-button type="button" (buttonClickEmit)="uploadRecordsFile()" [buttonValue]="'Upload'" [buttonDisable]="!employeeBulkFile" [buttonType]="'btn btn-primary'">
        </app-fusion-button>
      </div>
    </div>
  </div>
</div>

<!--SKS25OCT24 Add the modal backdrop -->
<div *ngIf="uploadModal" class="modal-backdrop fade show"></div>
