import { Component, EventEmitter, OnInit, Output, Input, isDevMode } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ApiService } from '../../../services/api.service';
import { LEAVE_HISTORY } from "../../../models/LEAVE_HISTORY";
import { NgxPaginationModule } from 'ngx-pagination';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ApplyLeavesComponent } from '../leaves/apply-leaves/apply-leaves.component';
import { HrmComponent } from '../../hrm/hrm.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DataTableComponent } from '../../../../app/components/data-table/data-table.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import * as moment from 'moment';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-Leave-history',
  standalone: true,
  imports: [FusionButtonComponent, AngularSvgIconModule, CommonModule, NgxPaginationModule, ApplyLeavesComponent, MatTableModule, MatPaginatorModule, MatSortModule, DataTableComponent,TranslateModule,CustomTranslatePipe],
  templateUrl: './Leave-history.component.html',
  styleUrls: ['./Leave-history.component.css']
})

export class LeaveHistoryComponent implements OnInit {

  @Output() leavesEmitData = new EventEmitter();

  @Input() fromHrmPage: boolean = false;
  @Input() selectedUserLeaveHistory: any;
  @Input() selectedUser: any;
  @Input() templateData: any;
  @Input() selectedEmployeeId: any
  @Input() employeeLeaves:any

  leave: LEAVE_HISTORY[] = [];

  selectedYear: number;
  years: number[] = [];
  leaveHistoryTable: Array<any> = [];
  leaveHistoryData: Array<any> = [];
  pageNumber: number = 1;
  tableSize: number = 4;
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  templateGet: any;
  noOfCasualLeave: any;
  noOfSickLeave: any;
  availableDays: any;
  leaveId: any;
  errorMessage: any;
  rejectReason: any;
  emittedLeaveCancel :any;
  isEmitted :boolean= false;
  isDevMode: boolean = false;
  employeeId: any;
  templateHeaders : Array<any> = [];
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  companyId = this.globalValues.orgId;
  downloadCurrentDisable = true;
  constructor(private apiService: ApiService, private errorHandler: ErrorHandlerService,private hrmFun:HrmComponent, private alertService: AlertService, private globalValues: GlobalValuesService,private router: Router, private datepipe: DatePipe,private translate : TranslateService) {
    this.isDevMode = isDevMode();
    if(this.isDevMode) console.log(this.leave);
    //to select the year from dropdown;
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year >= 2010; year--) {
      this.years.push(year);
    }
    this.globalValues.laguageChanges$.subscribe(lang => {
    
      this.templateHeadersValues();
      });

  }

  ngOnChanges() {
    //  when try to assign the selectedUserLeaveHistory on ngOnInit  , first called the parent  ng onInit and next child 2 and after that
    //  child 1 is called so here data  shown correctly agin back to data table to change the user now first called the child 1 and child 2 after 
    // that parent is called so here child 2 has old user data so table data not changing event user has been changed.
    // to avoid the above issue we assign the selectedUserLeaveHistory on ngOnchanges here both child component are called after the parent 
    // component so data is updated correctly 
    if (this.router.routerState.snapshot.url.includes('/hrm')) {
      this.employeeId = this.selectedEmployeeId;
      this.leaveList();
    } else {
      this.employeeId = this.globalValues.empId;
      this.leaveList();
    }
  }

  ngOnInit(): void {
    if (this.router.routerState.snapshot.url.includes('/hrm')) {
      this.employeeId = this.selectedEmployeeId;
      this.leaveList();
    } else {
      this.employeeId = this.globalValues.empId;
      this.leaveList();
    }
  }

  clickedButton($event: any) {
    if(this.isDevMode) console.log("The button is clicked and here is the $Event", $event);
  }
  
  templateHeadersValues(){
    const list = ["LEAVE_TYPE", "START_DATE", "END_DATE","DAYS","REASON","APPLIED_ON","STATUS"];
    this.returnValue(list, 'templateHeaders', 'array')
  }
  
  returnValue(array:any, variable:any, type:any){
    type === 'array' ? (this as any)[variable] = [] : (this as any)[variable] = '';
    array.forEach((element:any) => {
      this.translate.get(element).subscribe((keyValue) => {
        if(type === 'array'){
          (this as any)[variable].push(keyValue);
        } else if (type === 'string'){
          (this as any)[variable] = keyValue;
        }
      });
    });
  }
  
  // to get the leavehistory;
  leaveList(): void {
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    console.log('Selected Employe ------>', this.employeeLeaves)
    // SK17JAN24 reducing api call
    if (this.employeeLeaves?.length >= 0) {
      this.leave = this.employeeLeaves;
      this.leaveHistoryTable = [];
      this.templateGet = this.selectedUser?.templates;
      this.leave.forEach((datas: any) => {
        const leaveHistoryDate = new Date(datas.appliedOn);
        const year = leaveHistoryDate.getFullYear();
        const month = leaveHistoryDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
        const day = leaveHistoryDate.getDate();
        const appliedOn = `${year}-${month}-${day}`;
        this.leaveHistoryTable.push({
          appliedOn: moment(datas.updatedAt).format('LL'),
          leaveType: datas.leaveType,
          startDate: this.datepipe.transform(datas.startDate),
          endDate: this.datepipe.transform(datas.endDate),
          noOfDays: datas.noOfDays,
          reason: datas.reason,
          status: datas.status,
          rejectionReason: datas.rejectionReason,
          _id: datas._id,
          employeeId: datas.employeeId
        })
      })
    }
    this.leaveHistoryData = this.leaveHistoryTable;
    this.leaveDays();
  }

  //To get the total number of entries;
  get totalEntries(): number {
    return this.leave?.length;
  }

  // To render different pages based on page numbers;
  renderPage(event: any) {
    this.pageNumber = event;
    this.leaveList();

  }


  saveSuccess(event:any) {
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.successMsg = event || this.successMsg;
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }

  showError(err: any) {
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  //to show the increased leave balance on canceling a leave;
  leaveDays() {
    this.templateGet?.forEach((element: any) => {
      if (element?.leaveType === 'Casual leave') {
        this.noOfCasualLeave = element.noOfDays;
      }
      if (element?.leaveType === 'Sick leave') {
        this.noOfSickLeave = element.noOfDays;
      }
    })
  }

  leaveRejectReason(reason: any) {
    this.rejectReason = reason.data;
  }

  // to call the cancel leave funtion on the model
  removeLeave() {
    this.emittedLeaveCancel.emitted = false;
    // SK27FEB24 changed since updated code on delete in datatable
    this.onCancel({data:this.emittedLeaveCancel});
  }
  tableCheckedDataa(checkedArray:any){
    this.checkedEmployees = [];
    this.checkedEmployees = checkedArray;
    if(this.checkedEmployees.length === 0){
      this.downloadCurrentDisable = true;
    } else {
      this.downloadCurrentDisable = false;
    }
    if(this.isDevMode) console.log('this.checkedEmployees',this.checkedEmployees);
  }
  checkedEmployees:any;
  downloadCurrentEmployees(){
    const csv = this.convertToCsv(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'Leave History.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }
  convertToCsv(objArray: any[]) {
    let objectArray: Array<any> = [];
    objArray.forEach(element => {
      objectArray.push(Object.keys(element));
    });
       
    console.log(objArray,'to check List')
    var selectedObjectsForCSV = this.filterObjectKeys(objArray, ['leaveType', 'startDate', 'endDate','noOfDays', 'reason','appliedOn','status']);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    array.unshift({leaveType: "Leave Type",startDate:'Start Date',endDate:'End Date',noOfDays:'No Of Days',reason:'Reason',appliedOn:'Applied On', status:'Status'})
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        } if (index === 'startDate' || index === 'endDate' || index === 'appliedOn') {
          
          // Format startDate: "October 11, 2023" to "October 11 2023"
          line += array[i][index].replace(/,/g, '-');
      }
      
        else{
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 
  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) { // keys are the required fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undefined
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }
  //on canceling a leave 
  onCancel(user: any) {
    // SK27FEB24 changed since updated code on delete in datatable
    this.emittedLeaveCancel = user.data;
    this.templateGet.forEach((element: any) => {
      if (element.leaveType === user.data.leaveType) {
        this.availableDays = element.noOfDays;
      }
    })
  }


  leaveCancel() {
    const options = {
      'body': {
        'id': this.emittedLeaveCancel._id,
        'employeeId' : this.emittedLeaveCancel.employeeId,
        'leavetype' : this.emittedLeaveCancel.leaveType,
        'availableDays' : this.availableDays,
        'noOfDays' : this.emittedLeaveCancel.noOfDays,
        'companyId' : this.companyId
      },
    }; 

    this.apiService.writeValue('delete','/employee/leaves', options)
      .subscribe({
        next: (data) => {
          if (isDevMode()) console.log('leaveData', data);
          this.saveSuccess('Leave cancel was successful');
          this.leaveList();
          this.leavesEmitData.emit(); //This command executes the getEmployees() function from ApplyLeavesComponent
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
  }
}




