import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavEmitService } from '../../services/side-nav-emit.service';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { GlobalAppTabsComponent } from 'src/app/components/global-apps/global-app-tabs/global-app-tabs.component';
import { ApprovalNotificationtabComponent } from '../ess/leaves/approval-notificationtab/approval-notificationtab.component';
import { ExpenseTabComponent } from 'src/app/components/global-apps/expense-tab/expense-tab.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-approvals',
  standalone: true,
  imports: [CommonModule,PageHeaderComponent,NgSelectModule,TranslateModule,GlobalAppTabsComponent,ApprovalNotificationtabComponent, ExpenseTabComponent, DropDownComponent, CustomTranslatePipe],
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.css']
})
export class ApprovalsComponent implements OnInit {
  //sideNavsubscription: Subscription; /* hiden temporarily as we have modified the nav tab functionalities and also have made few changes to retain the page on reload */
  approvalsTabToShow= 'details';
  pageComponentName = 'Approvals';

  option=['Leaves', 'Expense'];
  eventValue = 'Leaves';
  activeNav:any;
  
  constructor(private sideNavItem: SideNavEmitService,private activatedRouteApprovals: ActivatedRoute, private router: Router) {
    //  this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => this.approvalsTabToShow = navItem); /* hiden temporarily as we have modified the nav tab functionalities and also have made few changes to retain the page on reload */
  }

  ngOnInit(): void {
    /* hiden temporarily as we have modified the nav tab functionalities and also have made few changes to retain the page on reload */
    // this.route.queryParams.subscribe(params => { 
    //   this.approvalsTabToShow = params['activeNav'] || 'details';
    // });
    // SK07MAR24 adding approvalType as query params to route the page on notification click
    let approvalType:any;
    this.activatedRouteApprovals.queryParams.subscribe((params:any) => {
      approvalType = params['approvalType'];
      this.activeNav = params['activeNav'];
      if(approvalType!=undefined)this.emittedValue({data:approvalType || 'Leaves'});
    });    
  }

  emittedValue(event:any){
    this.eventValue=event.data;  
    this.router.navigate([], { queryParams: { activeNav: this.activeNav, approvalType: event.data } });
  }
}

