import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbLayoutModule, NbThemeService } from '@nebular/theme';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';


@Component({
  selector: 'app-charts',
  standalone: true,
  imports: [CommonModule, NgxChartsModule, NbCardModule, ChartModule, NbLayoutModule, CustomTranslatePipe],
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {
  @Input() nonComparisonResults : any;
  @Input() comparisonResults : any;
  @Input() data : any;
  @Input() filterType  = 'noncomparison';
  @Input() chart : any;
  @Input() label : any;

  showLegend = true;
  showLabels = true;
  colorScheme: any;
  themeSubscription: any;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  autoScale = true;
  xAxisLabel : any;
  yAxisLabel : any;
  dashboardQuery  = 'designation';
  barChartOptions : any;
  lineChartOptions : any;
  horizontalBarChartOptions : any;

  nonCompDisplayResult!:Array<any>;
  compD3Result!:Array<any>;
  compChartJSResult:any;
  below = LegendPosition.Below
  
  ngOnChanges(changes: SimpleChanges){
  }



  constructor(private theme: NbThemeService, private globalValues: GlobalValuesService) {
  }

  // SK10MAY24 executing function in ngOnInit instead in ngOnChanges, due to performance
  ngOnInit(): void {
      this.themeSubscription = this.theme.getJsTheme().subscribe((config:any) => {
        const colors: any = config.variables;
        const chartjs: any = config.variables.chartjs;
        this.colorScheme = {
          domain: [colors.primaryLight, colors.infoLight, colors.successLight, colors.warningLight, colors.dangerLight, '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#ff3d71', '#8cfac7', '#42aaff', '#6f42c1', '#17a2b8', '#007bff', '#fd7e14', '#A10A28', '#C7B42C'],
        };
  
        // for comparison dashboards
        if(this.filterType == 'comparison'){
  
          //options may differ based on chart selected
          // for barchart
          this.barChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              labels: {
                fontColor: chartjs.textColor,
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                    color: chartjs.axisLineColor,
                  },
                  ticks: {
                    fontColor: chartjs.textColor,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    color: chartjs.axisLineColor,
                  },
                  ticks: {
                    fontColor: chartjs.textColor,
                  },
                },
              ],
            },
          };
  
          // for horizontal bar charts
          this.horizontalBarChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              rectangle: {
                borderWidth: 2,
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    color: chartjs.axisLineColor,
                  },
                  ticks: {
                    fontColor: chartjs.textColor,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                    color: chartjs.axisLineColor,
                  },
                  ticks: {
                    fontColor: chartjs.textColor,
                  },
                },
              ],
            },
            legend: {
              position: 'right',
              labels: {
                fontColor: chartjs.textColor,
              },
            },
          };
  
          // for line chart
          this.lineChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    color: chartjs.axisLineColor,
                  },
                  ticks: {
                    fontColor: chartjs.textColor,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    color: chartjs.axisLineColor,
                  },
                  ticks: {
                    fontColor: chartjs.textColor,
                  },
                },
              ],
            },
            legend: {
              labels: {
                fontColor: chartjs.textColor,
              },
            },
          };
        }
      });
  }

}
