<div class="form-card">
    <div class="col-md-12 mt-3">
    <div *ngIf="tabsPage">
        <div class="d-flex flex-row justify-content-between">
            <!-- <div class="tab-heading">{{'EXPENSES' | customTranslate : 'Expense'  }}</div> -->
            <app-fusion-button buttonType="btn btn-primary" buttonValue="{{'NEW_EXPENSES' | customTranslate : 'New Expense'  }}" [buttonDisable]="editExpenseDatas"
                (buttonClickEmit)="newExpense()">
            </app-fusion-button>
        </div>

        <div class="d-flex row align-items-center">
            <div class="tab-pane fade show active" id="newexpense-tab-pane" role="tabpanel" aria-labelledby="first-tab"
                tabindex="0">
                <div *ngIf="editExpenseDatas" class="flex flex-row mt-4">
                    <div class="card">
                        <form [formGroup]="expenseForm">
                            <div class="row input-margin">
                                <div class="col-md-4 input-margin-top">
                                    <!--RG26AUG24 added asterisk to important fields -->
                                    <label class="form-label label-txt modal-field-label">{{'EXPENSE_DATE' | customTranslate : 'Expense Date'  }}</label>
                                    <div class="field-input"
                                        [class]=" this.expenseForm.controls['expenseDate'].invalid && this.expenseForm.controls['expenseDate'].touched  ? 'has-error' : '' ">
                                        <input class="form-control" type="date" max="9999-12-31" placeholder="Select the date"
                                            formControlName="expenseDate">
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['expenseDate'].invalid && this.expenseForm.controls['expenseDate'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                        <!-- RS27AUG24 -->
                                        <!-- Used the translate pipe to successfully implement the translation -->
                                        {{'SELECT_VALID_DATE' | customTranslate : 'Select valid date'  }}</p>
                                </div>
                                <div class="col-md-4 input-margin-top">
                                    <label class="form-label label-txt modal-field-label">{{'CATEGORY' | customTranslate : 'Category'  }}*</label>
                                    <div class="field-input" [class]=" this.expenseForm.controls['category'].invalid && this.expenseForm.controls['category'].touched  ? 'has-error' : '' ">
                                        <input type="text" class="form-control" placeholder="Travel Expense"
                                                aria-label="Text input with dropdown button" formControlName="category">
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['category'].invalid && this.expenseForm.controls['category'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                        {{'ENTER_CATEGORY' | customTranslate : 'Enter the category'  }}</p>
                                </div>
                                <div class="col-md-4 input-margin-top">
                                    <label class="form-label label-txt modal-field-label">{{'AMOUNT' | customTranslate : 'Amount'  }}*</label>
                                    <div class="field-input flex"
                                        [class]=" this.expenseForm.controls['amount'].invalid && this.expenseForm.controls['amount'].touched  ? 'has-error' : '' ">
                                        <app-drop-down #curCode [lists]='currencyList' dropDownWidth="70px"
                                            dropBorderRadius="5px 0px 0px 5px"
                                            (dataEmit)="emittedValue($event, 'currencyCode')"
                                            [selectedData]="currencyValue"></app-drop-down>
                                        <input type="number" class="form-control currency-amount"
                                            aria-label="Text input with dropdown button" formControlName="amount">
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['amount'].invalid && this.expenseForm.controls['amount'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                        {{'VALIDATION.VALID_AMOUNT' | customTranslate : 'Enter the Valid Amount'  }}</p>
                                </div>
                            </div>
                            <div class="row input-margin">
                                <div class="col-md-4 input-margin-top">
                                    <label class="form-label label-txt modal-field-label">{{'TO_BE_REIMBURSED' | customTranslate : 'To be Reimbursed'  }}</label>
                                    <div class="field-input"
                                        [class]=" this.expenseForm.controls['claimReimbursement'].invalid && this.expenseForm.controls['claimReimbursement'].touched  ? 'has-error' : '' ">
                                        <app-drop-down #reimbursmentData [lists]='toBeReimbursed'
                                            (dataEmit)="emittedValue($event, 'claimReimbursement')"
                                            [selectedData]="reimbursmentValue">
                                        </app-drop-down>
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['claimReimbursement'].invalid && this.expenseForm.controls['claimReimbursement'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                        {{'SELECT_VALUE' | customTranslate : 'Select the Value'  }}</p>
                                </div>
                                <!-- SK25JUL24 project code in expense -->
                                <div class="col-md-4 input-margin-top">
                                    <label class="form-label label-txt modal-field-label">{{'SELECT_RELATED_PROJECT(IF_ANY)' | customTranslate : 'Select related projects if any'  }}</label>
                                    <div class="field-input"
                                        [class]=" this.expenseForm.controls['project'].invalid && this.expenseForm.controls['project'].touched  ? 'has-error' : '' ">
                                        <app-drop-down #reimbursmentData [lists]='projectsList'
                                            (dataEmit)="emittedValue($event, 'project')"
                                            [selectedData]="projectCode">
                                        </app-drop-down>
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['project'].invalid && this.expenseForm.controls['project'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                        {{'SELECT_VALUE' | customTranslate : 'Select the Value'  }}</p>
                                </div>
                                <div class="col-md-4 input-margin-top">
                                    <label class="form-label label-txt modal-field-label">{{'BILL NUMBER(OPT)' | customTranslate : 'Bill Number(Optional)'  }}</label>
                                    <div class="field-input"
                                        [class]=" this.expenseForm.controls['billnumber'].invalid && this.expenseForm.controls['billnumber'].touched  ? 'has-error' : '' ">
                                        <input class="form-control" type="text" placeholder="Enter the bill number"
                                            formControlName="billnumber">
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['billnumber'].invalid && this.expenseForm.controls['billnumber'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                    </p>
                                </div>
                            </div>
                            <div class="row input-margin">
                                <div class="col-md-4 input-margin-top">
                                    <label class="form-label label-txt modal-field-label">{{'DESCRIPTION(OPT)' | customTranslate : 'Description(Optional)'  }}</label>
                                    <div class="field-input"
                                        [class]=" this.expenseForm.controls['description'].invalid && this.expenseForm.controls['description'].touched  ? 'has-error' : '' ">
                                        <textarea class="form-control" [placeholder]="'DES_PLACEHOLDER' | customTranslate : 'Enter Text'"
                                            formControlName="description"></textarea>
                                    </div>
                                    <p class="error-fields"
                                        [style]="this.expenseForm.controls['description'].invalid && this.expenseForm.controls['description'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                                    </p>
                                </div>
                            </div>
                            <div class="row input-margin">
                                <div class="flex flex-column">
                                    <div class="d-flex">
                                        <div class="text-primary fw-semibold cursor-point"
                                            data-bs-target="#addReceiptModal" data-bs-toggle="modal">
                                            {{'ADD_RECEIPT' | customTranslate : '+ Add Receipt'  }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- displays the file that is attached -->
                            <div class="row input-margin">
                                <div class="col-md-3" *ngFor="let files of receiptArray; let i=index">
                                    <div class="badge align-item-center">
                                        <img src="../assets/images/icons/general/file-attach.svg">
                                        <span class="file-name">{{files?.file?.name | truncateName }}</span>
                                        <app-fusion-button [buttonType]="'btn-close'" (click)="removeFile(i,files.file.name)"></app-fusion-button>
                                    </div>
                                </div>
                            </div>
                            <div class="row input-margin justify-content-end">
                                <div class="col-md-4 text-end input-margin-top">
                                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                        [buttonType]="'text-only card-btn hide-detail-view'"
                                        (buttonClickEmit)="docResetButton()" [dataDismiss]="''" [fcBtnId]="'myId'">
                                    </app-fusion-button>
                                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SUBMIT' | customTranslate : 'Submit' }}"
                                        [buttonDisable]="!expenseForm.valid || expenseForm.value.category === 'Select' "
                                        [buttonType]="'btn btn-primary card-btn hide-detail-view'" fcButtonBorder="0px"
                                        (buttonClickEmit)="saveExpenseInfo()" [dataDismiss]="''" [fcBtnId]="'myId'">
                                    </app-fusion-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div *ngIf="!editExpenseDatas" class="mt-4">
                    <div>{{'EXPENSE_HISTORY' | customTranslate : 'Expense History'  }}</div>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                        <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'" 
                         [buttonDisable]="downloadCurrentDisable" 
                        (buttonClickEmit)="downloadCurrentEmployees()"
                    [buttonValue]="'EXPORT' | customTranslate : 'Export'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
                        </app-fusion-button>
                      </div>
                    <app-data-table *ngIf="expenseClaimHistory" [data]="expenseClaimHistory"
                        [displayedColumns]="['category','receipt', 'expenseDate', 'amount', 'status', 'project','rejectionReason', 'appliedOn', 'reImbursed' ]"
                        [headerLabels]="['Category','Attachments', 'Date', 'Amount','Status', 'Project','Comments', 'Applied On', 'Reimbursement Status']"
                        [translatePath]="'EXPENSES_TAB_TABLE'"
                        [fromWhere]="'expense'" [withCheckBox]="true" (selectedValues)="tableCheckedDataa($event)"
                        [actionButton]="true" [searchBar]="true" (onEditData)="editExpense($event)" [receiptImg]="true"
                        (OnDeleteData)="deleteExpense($event)" (expenseAttachmentEmit)="emitAttachmentClick($event)">
                    </app-data-table>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="modal fade" id="addReceiptModal" tabindex="-1" aria-labelledby="addReceiptsLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title fs-5 mb-0">{{'UPLOAD_RECEIPT' | customTranslate : 'Upload Receipt'  }}</div>
                <app-fusion-button [buttonType]="'btn-close'" (click)="filePopupCancel()" [dataDismiss]="'modal'"
                [fcBtnId]="'close'"></app-fusion-button>

            </div>
            <div class="modal-body">
                <app-file-upload [multiSelectFile]="true" [docVals]="['5 mb','jpg, png,svg,pdf, docs']" [showPreview]="'fileName'"  [acceptType]="'application/pdf,image/jpeg,image/jpg,image/png,image/svg+xml'"
                fileUploadId="'expNewId'" [uploadReadyFiles]="receiptArray"
                (fileNameData)="fileNameArray($event)" (documentDelete)="removeReceipt($event)">
                <!-- 'showPreview' can have either of the values 'iFrame' (or)  'fileName' -->
                <!-- 'acceptType'  helps to partially restrict user to select only given file type, check the list for acceptable values -->
                </app-file-upload>
                <!-- Added error message call in below line-->
                <p class="error-msg" *ngIf="errorMessage"><span>{{ errorMessage }}</span></p>
            </div>
        </div>
    </div>
</div>

<div class="modal fade container-sm" id="expenseReceiptModel"  aria-labelledby="expenseReceiptModelLabel"
  aria-hidden="true" #demoModal>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-5" id="expenseReceiptModelLabel"></div>
        <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
        [fcBtnId]="'close'"  (buttonClickEmit)="safeSrc = '' ">
        </app-fusion-button>
      </div>
      <div class="modal-body">
        <iframe *ngIf="safeSrc" [src]="safeSrc" width="100%" height="600"></iframe>
      </div>
    </div>
  </div>
</div>