import { CommonModule, DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NxtAppModule } from '@rangertechnologies/ngnxt';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankinfotabComponent } from 'src/app/pages/ess/bankinfotab/bankinfotab.component';
import { BasicinfotabComponent } from 'src/app/pages/ess/basicinfotab/basicinfotab.component';
import { DependenttabComponent } from 'src/app/pages/ess/dependenttab/dependenttab.component';
import { DocumentComponent } from 'src/app/pages/ess/document/document.component';
import { EducationtabComponent } from 'src/app/pages/ess/educationtab/educationtab.component';
import { ExpensesTabComponent } from 'src/app/pages/ess/expenses/expenses-tab/expenses-tab.component';
import { ExperiencetabComponent } from 'src/app/pages/ess/experiencetab/experiencetab.component';
import { HolidaydetailsComponent } from 'src/app/pages/ess/holidaydetails/holidaydetails.component';
import { ApplyLeavesComponent } from 'src/app/pages/ess/leaves/apply-leaves/apply-leaves.component';
import { WorktabComponent } from 'src/app/pages/ess/worktab/worktab.component';
import { CompensationEditComponent } from 'src/app/pages/hrm/compensation/compensation-edit/compensation-edit.component';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { CardsComponent } from '../cards/cards.component';
import { DataTableComponent } from '../data-table/data-table.component';
import { ModalComponent } from '../modal/modal.component';
import { MultiCheckboxComponent } from '../multi-checkbox/multi-checkbox.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.css'],
  imports:[
    CommonModule,
    BasicinfotabComponent,
    WorktabComponent,
    EducationtabComponent,
    ExperiencetabComponent,
    DocumentComponent,
    DependenttabComponent,
    BankinfotabComponent,
    ApplyLeavesComponent,
    ExpensesTabComponent,
    NxtAppModule,
    CardsComponent,
    AngularSvgIconModule,
    ModalComponent,
    DataTableComponent,
    HolidaydetailsComponent,
    MultiCheckboxComponent,
    CompensationEditComponent,
    CustomTranslatePipe
  ],
  standalone: true
})
export class BrowseComponent implements OnInit {
  
  @Input() selectedEmployee:any;
  @Input() employeeFinanceInfo:any;
  @Input() empGet:any;
  @Input() employeeLeaves:any;
  @Input() employeeExpenses:any;
  @Input() expenseHistory:any
  @Input() nxtJSON:any;
  @Input() bookValue:any;
  @Input() languageCode :any; //SKS11OCT24
  @Output() nxtFormHandlerEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() nxtButtonHandlerEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCalendarDateEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleCalendarEventDateEmit: EventEmitter<any> = new EventEmitter<any>();
  
  @Input() staticComp = false; //SK21AUG24 getting the variable as input
  //SK24JAN24 Passing sub tab list as Array
  subTabsList = [
    {name:"Basic Info", subTab:"basic"}, 
    {name:"Work Info", subTab:"work"}, 
    {name:"Qualifications", subTab:"education"}, 
    {name:"Experiences", subTab:"experience"}, 
    {name:"Documents", subTab:"documents"}, 
    {name:"Dependents", subTab:"dependents"}, 
    {name:"Bank Info", subTab:"bank"}, 
    {name:"Apply Leaves", subTab:"leaves"}, 
    {name:"Leaves & Allocation", subTab:"leaveAllocation"},
    {name:"Expenses", subTab:"expenses"},
    {name:"Compensation", subTab:"compensation"},
    {}
  ]
  activeSubTab:any; //SK24JAN24 to show the active subtab since the subtab list passed as Array now
  // SK29JAN24 Static subatabs is defined
  staticTabs = ["basic", "work", "education", "experience", "documents", "dependents", "bank", "leaves", "expenses", "leaveAllocation", "compensation"]
  dynamicTab : Array<any> = [];
  isDynamicTabs = false;
  modalProperties:any;
  openModal = false;
  dynamicTabContent : Array<any> = [];
  cardData:any;
  data:any;
  dynamicTabType = 'card';
  hideStaticTabs : Array<any> = []; // 30JAN24 hiding static tabs if any
  tableInputs : any;
  employeeId!:string;
  configTemplate:any; // SK14MAR24 template configs to be passed to component
  configHolidays:any; // SK14MAR24 holiday configs to be passed to component
  configWeekoffs:any; // SK14MAR24 weekoff configs to be passed to component
  templateData:any // SK14MAR24 list of template data assigned
  holidayData:any; // SK14MAR24 list of holidays data assigned
  weekOffData:any; // SK14MAR24 list of weekoff data assigned

  constructor( private router: Router, private apiService: ApiService, private alertService: AlertService, private errorHandler: ErrorHandlerService, private datePipe: DatePipe, private globalValues: GlobalValuesService, private spinnerService: NgxSpinnerService) { }
  //SK 18JAN24 to display dynamic browse component
  ngOnInit(): void {
    // if(this.router.routerState.snapshot.url.includes('/hrm') || this.router.routerState.snapshot.url.includes('/ess')){
    //   this.staticComp = true;
    // } else{
    //   this.staticComp = false;
    // }

    this.hideStaticTabs = [];
    // SK29JAN24 Check, whether the dyanic tab content is present in custom HRM
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      this.dynamicTab = [];
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'hrm'){
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'employees'){
              modArr.tabs.forEach((tabArr:any) => {
                if(tabArr.name === 'dynamicTabs'){
                  this.isDynamicTabs = true;
                  // 30JAN24 hiding static tabs if any
                  this.hideStaticTabs = tabArr.hideStaticTabs;
                  this.dynamicTab = tabArr.config.dynamicTabs;
                }
              });
            }
          });
        }
      });
    }
    this.subTabsList = this.dynamicTab.length > 0 ?  this.subTabsList.concat(this.dynamicTab) : this.subTabsList;
  }

  ngOnChanges(change:SimpleChanges){
    const userData = JSON.parse(localStorage.getItem('userData') as any);  //SKS11OCT24
    this.languageCode = userData.languageCode || "en";
    if(change['nxtJSON'] || change["bookValue"] || change["languageCode"]){
      this.nxtJSON = this.nxtJSON;
      this.bookValue = this.bookValue;
      // this.languageCode = userData.languageCode || "en";
    } 
    //SK24JAN24 assign active subtab from local storage
    if(change['selectedEmployee']){
      // SK19FEB24 on the subtabs change, calling the acriveSubTabs method
      if(this.selectedEmployee){
        const tabs: Array<any> = JSON.parse(localStorage.getItem('hrmTabList') as any);
        const isActiveSubTab = tabs.filter((tab:any)=> tab.tab === this.selectedEmployee?.employeeId);
        this.activeSubTab = isActiveSubTab[0]?.subTab;
        this.activeSubTabs(isActiveSubTab[0]);
        this.leaveMaster(); //SK14MAR24 get leave master data
      }
    }

    //SK24JAN24
    if(change['subTabsList']){
      this.subTabsList = this.subTabsList.concat(this.dynamicTab); //SK29JAN24 Concating static tabs with dynamic tab if any
    }
  }

  nxtFormHandler(event:any){
    this.nxtFormHandlerEmit.emit(event);
  }

  nxtButtonHandler(event:any){
    this.nxtButtonHandlerEmit.emit(event);
  }

  //SK24JAN24 the active subtab of the current tab is stored in local
  activeSubTabs(tab:any){
    // SK19FEB24 fetching the users data on every tab select to avoid overlapping of datas
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      this.dynamicTab = [];
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'hrm'){
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'employees'){
              modArr.tabs.forEach((tabArr:any) => {
                if(tabArr.name === 'dynamicTabs'){
                  this.isDynamicTabs = true;
                  // 30JAN24 hiding static tabs if any
                  this.hideStaticTabs = tabArr.hideStaticTabs;
                  this.dynamicTab = tabArr.config.dynamicTabs;
                }
              });
            }
          });
        }
      });
    }
    this.activeSubTab = tab?.subTab;
    this.employeeId = this.selectedEmployee?.employeeId;
    const tabs: Array<any> = JSON.parse(localStorage.getItem('hrmTabList') as any);
    const ind = tabs.findIndex((tab1:any)=> tab1.tab === this.selectedEmployee?.employeeId);
    if(ind>0)tabs[ind].subTab = tab?.subTab;
    localStorage.setItem('hrmTabList', JSON.stringify(tabs));
    this.dynamicTabContent = [];
    // SK29JAN24 checking if the tab content is dynamic cards on clicking tab
    this.dynamicTab.forEach((element:any) => {
      if(element.subTab === tab?.subTab){
        //SK30JAN24 switching the content based on type of component
        if(element.type === "dynamicCards"){
          this.dynamicTabType = 'card';
          this.dynamicTabContent = element.cardList;
          //SK30JAN24, If selectedEmployee values is not null, assigning that value to card data, else getting dynamically
          if(this.selectedEmployee || this.employeeFinanceInfo){
            this.employeeId = this.selectedEmployee?.employeeId;
            this.dynamicTabContent.forEach((cardDatum:any) => {
              // SK19FEB24 configuration for financeinfo object for cards
              if(cardDatum.config.responseObjectKey === 'financeInfo'){
                cardDatum.config.data = {};
                cardDatum.config.details.forEach((fieldsValue:any) => {
                 if(this.employeeFinanceInfo) fieldsValue.value = this.getValue(this.employeeFinanceInfo||{}, fieldsValue.RF);
                  cardDatum.config["data"][fieldsValue.refField] = fieldsValue.value;
                });;
              } else {
                cardDatum.config.details.forEach((fieldsValue:any) => {
                  cardDatum.config.data = {};
                  fieldsValue.value = this.getValue(this.selectedEmployee, fieldsValue.RF);
                  cardDatum.config.data[fieldsValue.refField] = fieldsValue.value;
                });
              }
            });
          }  else {
            this.dynamicTabContent.forEach((cardDatum:any) => {
              this.dynamicGet(cardDatum.config.apiCall, cardDatum.config.endpoint, cardDatum.config);
            });
          }   
        }  else if (element.type === "table"){
          this.dynamicTabType = 'table';
          this.tableInputs = element.config;
          console.log("tableInputs",this.tableInputs)
          if(this.selectedEmployee){
            if(element.config.responseObject){
              this.data = this.selectedEmployee[element.config.responseObjectKey]
            } else if(element.config.responseArray) {
              this.data = this.selectedEmployee;
            }
          } else {
            this.dynamicTabContent = [];
            this.dynamicGet(element.config.apiCall, element.config.endpoint, element.config);
          }
          
        }
      }
    });
  }

  // SK29JAN24 get call for dynamic tab content
  // SK25JUL24 passing card details 
  dynamicGet(apiCall:any, endPoint:any, config:any, cardDetails?:any){
    if(apiCall === 'get'){
      this.apiService.fetchValue(endPoint).subscribe((getData:any) => {
        if(config.responseObject){
          const key = config.responseObjectKey;
          if(config.type == 'openNXTPage'){
            this.nxtJSON = getData[key];
            if(cardDetails != null){
              this.bookValue = cardDetails.config.data;
            }
          } else {
            this.data = [];
            this.data = getData[key];
          }
        } else if(config.responseArray){
          if(config.type === 'tableData'){ // SK20DEC23 assigning table data if response is an array array
            this.data = getData;
          }else if(config.type === 'card'){ // SK20DEC23 assigning table data if response is an array array
            this.data = getData[0];
            config.details.forEach((element:any) => {
              element.value = this.data[element.RF];             
            });
          }
        }
      })
    }
  }

  // SK29JAN24 on clicking card edit
  onClickEdit(card:any){
    this.bookValue = {}; //SK19FEB24
    if(card.isEditButton){
      this.openModal = true;
      this.modalProperties = card.editButtonActions;
      this.commonButtonClickEmit(this.modalProperties, card)
    } 
  }

  // SK29JAN24 type of action on clicking edit
  commonButtonClickEmit(event:any, cardDetails:any){
    if(event.buttonAction == 'openModal'){
      this.openModal = true;
      this.modalProperties = event.modalProperties;
      if(this.modalProperties?.type === 'openNXTPage'){
        const url = '/nxt?name=' + this.modalProperties.pageContent;
        this.dynamicGet('get', url, this.modalProperties, cardDetails);
      }
    }
  }

  // SK29JAN24 function on clicking nxt buttons
  nxtFormHandlerHRM(event:any){
    if(event.action == 'close'){
      this.modalClose(false);
    } else if(event.action == 'save'){ //SK19FEB24 handling the nxt button properties for patch/post call
      // SK19FEB24 based on App configs, the nxt data is sent to server  
      let patchObject:any = {};
      const types = event.data.map((type:any)=>type.Type__c);
      // SK25JUL24 processing file on upload to postgres
      if(types.includes("File")){
        event.data.forEach((record:any) => {
          patchObject[record.Reference_Field__c] = record.input
          if(record.Type__c === "File"){  
            if(record?.input){
              const fileLength = record?.input?.length;
              let finalFileArray:any = [];
              record?.input.forEach((files:any, index:number = 0) => {
                const fileCurrent = files
                if(files.doc){
                  const fileFormData = this.createFormData(fileCurrent.doc, fileCurrent.name, fileCurrent.type);
                  // file is save din postgres and getting the id as response
                  this.apiService.uploadFiles('/file',fileFormData).subscribe({
                    next: (savedData:any) => {
                      finalFileArray.push(savedData.result[0]);
                      if(finalFileArray.length === fileLength){
                        record.input = finalFileArray;
                        const ind = event.data.findIndex((fileD:any)=>fileD.id === record?.id);
                        event.data[ind].input = finalFileArray;
                        patchObject[event.data[ind].Reference_Field__c] = finalFileArray;
                        let endPoint:any;
                        if(this.modalProperties.isQueryParams) {
                          endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
                        } else {
                          endPoint = this.modalProperties.nxtAPI;
                        }
                        const patchData = {...patchObject,...{employeeId:this.employeeId}};
                        this.apiService.writeValue('patch', endPoint, patchData)
                        .subscribe({
                          next: (savedData) => {
                            this.modalClose(false);
                            this.saveSuccess(this.modalProperties.successMessage);
                          },
                          error: (err: HttpErrorResponse) => {
                            this.showError(err);
                          }
                        });
                      }
                    },
                    error: (err: HttpErrorResponse) => {
                      this.showError(err);
                    }
                  })
                } else {
                  finalFileArray.push(files);
                }
              });
              //  saving the data to mongoose only if all the data is converted as id
              if(finalFileArray.length === fileLength){
                record.input = finalFileArray;
                const ind = event.data.findIndex((fileD:any)=>fileD.id === record?.id);
                event.data[ind].input = finalFileArray;
                patchObject[event.data[ind].Reference_Field__c] = finalFileArray;
                // this.spinnerService.show();
                let endPoint:any;
                if(this.modalProperties.isQueryParams) {
                  endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
                } else {
                  endPoint = this.modalProperties.nxtAPI;
                }
                const patchData = {...patchObject,...{employeeId:this.employeeId}};
                this.apiService.writeValue('patch', endPoint, patchData)
                .subscribe({
                  next: (savedData) => {
                    this.modalClose(false);
                    this.saveSuccess(this.modalProperties.successMessage);
                  },
                  error: (err: HttpErrorResponse) => {
                    this.showError(err);
                  }
                });
              }
            } else {
              this.apiService.writeValue('patch', this.modalProperties.nxtAPI,  event).subscribe({
                next: (savedData) => {
                  this.saveSuccess(this.modalProperties.successMessage);
                  this.modalClose(false);
                },
                error: (err: HttpErrorResponse) => {
                  this.showError(err);
                }
              });
            }
          }
        });
      } else {
        let endPoint:any;
        if(this.modalProperties.isQueryParams) {
          endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
        } else {
          endPoint = this.modalProperties.nxtAPI;
        }
        const patchData = {...patchObject,...{employeeId:this.employeeId}};
        this.apiService.writeValue('patch', endPoint, patchData)
        .subscribe({
          next: (savedData) => {
            this.modalClose(false);
            this.saveSuccess(this.modalProperties.successMessage);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
        });
      }
    } else if(event.action == 'submit'){ 
      let patchObject:any = {};
      const types = event.data.map((type:any)=>type.Type__c);
      if(types.includes("File")){
        event.data.forEach((record:any) => {
          patchObject[record.Reference_Field__c] = record.input
          if(record.Type__c === "File"){  
            if(record?.input){
              const fileLength = record?.input?.length;
              let finalFileArray:any = [];
              record?.input.forEach((files:any, index:number = 0) => {
                const fileCurrent = files
                if(files.doc){
                  const fileFormData = this.createFormData(fileCurrent.doc, fileCurrent.name, fileCurrent.type);
                  // the file is save din postgres and getting the id as response
                  this.apiService.uploadFiles('/file',fileFormData).subscribe({
                    next: (savedData:any) => {
                      finalFileArray.push(savedData.result[0]);
                      if(finalFileArray.length === fileLength){
                        record.input = finalFileArray;
                        const ind = event.data.findIndex((fileD:any)=>fileD.id === record?.id);
                        event.data[ind].input = finalFileArray;
                        patchObject[event.data[ind].Reference_Field__c] = finalFileArray;
                        let endPoint:any;
                        if(this.modalProperties.isQueryParams) {
                          endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
                        } else {
                          endPoint = this.modalProperties.nxtAPI;
                        }
                        const patchData = {...patchObject,...{employeeId:this.employeeId}};
                        this.apiService.writeValue('post', endPoint, patchData)
                        .subscribe({
                          next: (savedData) => {
                            this.modalClose(false);
                            this.saveSuccess(this.modalProperties.successMessage);
                          },
                          error: (err: HttpErrorResponse) => {
                            this.showError(err);
                          }
                        });
                      }
                    },
                    error: (err: HttpErrorResponse) => {
                      this.showError(err);
                    }
                  })
                } else {
                  finalFileArray.push(files);
                }
              });
              //  saving the data to mongoose only if all the data is converted as id
              if(finalFileArray.length === fileLength){
                record.input = finalFileArray;
                const ind = event.data.findIndex((fileD:any)=>fileD.id === record?.id);
                event.data[ind].input = finalFileArray;
                patchObject[event.data[ind].Reference_Field__c] = finalFileArray;
                let endPoint:any;
                if(this.modalProperties.isQueryParams) {
                  endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
                } else {
                  endPoint = this.modalProperties.nxtAPI;
                }
                const patchData = {...patchObject,...{employeeId:this.employeeId}};
                this.apiService.writeValue('post', endPoint, patchData)
                .subscribe({
                  next: (savedData) => {
                    this.modalClose(false);
                    this.saveSuccess(this.modalProperties.successMessage);
                  },
                  error: (err: HttpErrorResponse) => {
                    this.showError(err);
                  }
                });
              }
            } else {
              this.apiService.writeValue('post', this.modalProperties.nxtAPI,  event).subscribe({
                next: (savedData) => {
                  this.saveSuccess(this.modalProperties.successMessage);
                  this.modalClose(false);
                },
                error: (err: HttpErrorResponse) => {
                  this.showError(err);
                }
              });
            }
          }
        });
      } else {
        let endPoint:any;
        if(this.modalProperties.isQueryParams) {
          endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
        } else {
          endPoint = this.modalProperties.nxtAPI;
        }
        const patchData = {...patchObject,...{employeeId:this.employeeId}};
        this.apiService.writeValue('post', endPoint, patchData)
        .subscribe({
          next: (savedData) => {
            this.modalClose(false);
            this.saveSuccess(this.modalProperties.successMessage);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
        });
      }
    }
  }

  // SK19FEB24 succes message comp on save
  saveSuccess(event:any) {
    this.alertService.messageOnFetch('successFetch', this.employeeId, event);
  }

  // SK19FEB24 error message comp on save
  errorMessage = '';
  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', err);
  }

  // SK29JAN24 modal close
  modalClose(event:any){
    this.openModal = event;
    this.nxtJSON = [];
  }
  
  //SK30JAN24 on selecting data in table, Need to develop further
  tableCheckedDataa(event:any){

  }

  //SK30JAN24, on clicking hyper link, Need to develop further
  hyperLinkColumnEmit(event:any){

  }

  //SK30JAN24 on clicking edit button, Need to develop further
  onEditRecord(event:any){

  }

  //SK30JAN24 on clicking delete button, Need to develop further
  onDeleteRecord(event:any){
  }

  //SK30JAN24, return the data from an object
  getValue(element:any, column:string){
    const initialEl = element; // SK21DEC23 fixed table search issue
    let flds = column.split('.');
    for(let i=0; i<flds.length; i++){
      let splitFlds = flds[i].split('[');
      if(splitFlds.length === 1){
        element = element[flds[i]] || '' as any;
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        element = element[splitFlds[0]][index]  || '' as any; //SK 20DEC23 To solve undefined error
      }
    }
    Object.assign(initialEl, { [column]: element }); // HA 11-DEC-23 Fixed Expression changed issue
    return element;
  }

  leaveMaster(){
    this.configTemplate = {
      "type": "multiCheck",
      "name":"template",
      "label": "Template Allocation",
      "cardSize": "col-md-12",
      "cardInlineSize": "col-md-6",
      "checkboxList":[],
      "activeCheckboxList":[],
      "buttons": [
        {
            "buttonType": "",
            "name": "cancel",
            "label": "Cancel",
            "class": "text-only",
            "buttonAction": "cancel",
            "path": ""
        },
        {
            "buttonType": "",
            "name": "save",
            "label": "Save",
            "class": "btn btn-primary ms-2",
            "buttonAction": "save",
            "path": "/settings/users/",
            "method": "patch",
            "patchKey": "permissions",
            "isParams": "true",
            "paramsKey": "fusionId",
            "paramsValue": "",
            "successMessage": "Permission Assigned Successfully"
        }
      ]
    }

    this.configHolidays = {
      "type": "multiCheck",
      "name":"holidays",
      "label": "Holidays Allocation",
      "cardSize": "col-md-12",
      "cardInlineSize": "col-md-6",
      "checkboxList":[],
      "activeCheckboxList":[],
      "buttons": [
          {
              "buttonType": "",
              "name": "cancel",
              "label": "Cancel",
              "class": "text-only",
              "buttonAction": "cancel",
              "path": ""
          },
          {
              "buttonType": "",
              "name": "save",
              "label": "Save",
              "class": "btn btn-primary ms-2",
              "buttonAction": "save",
              "path": "/settings/users/",
              "method": "patch",
              "patchKey": "permissions",
              "isParams": "true",
              "paramsKey": "fusionId",
              "paramsValue": "",
              "successMessage": "Permission Assigned Successfully"
          }
      ]
    }

    this.configWeekoffs = {
      "type": "multiCheck",
      "name":"weekoff",
      "label": "Weekoff Allocation",
      "cardSize": "col-md-12",
      "cardInlineSize": "col-md-12",
      "checkboxList":[],
      "activeCheckboxList":[],
      "buttons": [
          {
              "buttonType": "",
              "name": "cancel",
              "label": "Cancel",
              "class": "text-only",
              "buttonAction": "cancel",
              "path": ""
          },
          {
              "buttonType": "",
              "name": "save",
              "label": "Save",
              "class": "btn btn-primary ms-2",
              "buttonAction": "save",
              "path": "/settings/users/",
              "method": "patch",
              "patchKey": "permissions",
              "isParams": "true",
              "paramsKey": "fusionId",
              "paramsValue": "",
              "successMessage": "Permission Assigned Successfully"
          }
      ]
    }
    // SK14MAR24 getting all template, holidays and week off from leave master
    this.apiService.fetchValue("/leavemaster").subscribe((get:any)=>{
      // SK14MAR24 modifying and assigning template and passing to MultiCheck and Holiday Components
      this.templateData = this.selectedEmployee?.templates || [];
      this.configTemplate.checkboxList = get.template.map((temp:any)=> temp.leaveType);
      this.configTemplate.activeCheckboxList = this.selectedEmployee?.templates.map((temp:any)=> temp.leaveType);
      this.templateData.forEach((templeateElement:any) => {
        templeateElement.type = templeateElement.leaveType;
        templeateElement.days = templeateElement.noOfDays;
      });

      // SK14MAR24 modifying and assigning holidays and passing to MultiCheck and Holiday Components
      this.holidayData = this.selectedEmployee?.holidays || [];
      this.holidayData.forEach((holidayElement:any) => {
        holidayElement.type = holidayElement.leaveType;
        if(new Date(holidayElement.startDate).setHours(0,0,0) === new Date(holidayElement.endDate).setHours(0,0,0)){
          holidayElement.days = this.datePipe.transform(holidayElement.startDate, 'mediumDate');
        } else {
          holidayElement.days = this.datePipe.transform(holidayElement.startDate, 'mediumDate') + ' to ' + this.datePipe.transform(holidayElement.endDate, 'mediumDate');
        }
      });
      this.holidayData.sort((a:any, b:any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      this.configHolidays.checkboxList = get.holidays.map((hol:any)=> hol.leaveType);
      this.configHolidays.activeCheckboxList = this.selectedEmployee?.holidays.map((temp:any)=> temp.leaveType);

      // SK14MAR24 modifying and assigning weekoff and passing to MultiCheck and Holiday Components
      const weekoffDays = this.selectedEmployee?.weekoff || []; 
      const days = weekoffDays.days;
      let loopDays = '';
      days.forEach((weekOffElement:any, index:number) => {
        loopDays = loopDays + weekOffElement.value + (index < (days.length - 1)? ', ' : '');
      });
      const dayObject = {days :loopDays };
      const typeObject = {type : weekoffDays.leaveType}
      this.weekOffData = [{...dayObject,...typeObject}];
      this.configWeekoffs.checkboxList = get.weekoff.map((off:any)=> off.leaveType);
      this.configWeekoffs.activeCheckboxList = this.selectedEmployee?.weekoff.leaveType;
    })
    
  }

  // SK14MAR24 on saving the checkbox list from multicheck component, this function triggers
  multiCheckEmit(evt:any){
    // this.spinnerService.show();
    let empId = {
      employeeid:[this.selectedEmployee.employeeId],
      companyId:[this.globalValues.orgId],
      template:[],
      holidays:[],
      weekoff:""
    };
    let type:any
    // SK14MAR24 based on condition assigning the leave alllocation data and sent to database
    if(evt.compData.name === "weekoff"){
      type = Object.assign({[evt.compData.name] : evt.checkListArray[0] || ""});
    } else {
      type = Object.assign({[evt.compData.name] : evt.checkListArray});
    }
    const data = {...empId,...type};
    this.apiService.writeValue("patch","/hrm/leaveallocation?name="+evt.compData.name,data).subscribe //SK24JUN24 sending leave type as params
    ({
      next:(success:any)=>{
        // this.spinnerService.hide();
        this.saveSuccess(success.message);
        this.leaveMaster();
      }, 
      error:(err:any)=>{
        // this.spinnerService.hide();
        this.showError(err);
      }
    })
  }

  // SK25JUL24 converting base64 to blob
  createFormData(base64: string, fileName: string, contentType: string): FormData {
    const formData = new FormData();
    const blob = this.base64ToBlob(base64, contentType);
    formData.append('files', blob, fileName);
    return formData;
  }

  // SK25JUL24 converting base64 to blob
  base64ToBlob(base64: string, contentType: string): Blob {
    base64 = base64.split(",")[1];
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  // SK13SEP24 calendar month emit
  handleCalendarDate(event:any){
    this.handleCalendarDateEmit.emit(event)
  }

  // SK13SEP24 calendar event value emit
  handleCalendarEvent(event:any){
    this.handleCalendarEventDateEmit.emit(event)
  }
}