<!-- SK31JUL24 Hire nxt screen displaying -->
<div *ngIf="errorPage && !successRegister" class="m-4 d-flex justify-content-center">The registration url is invalid..Please check and try again..</div>
<div *ngIf="!successRegister"  class="m-4">
    <lib-booklet [bookletJSON]="nxtJSON"  [dataBind]="bookValue" [languageCode]="languageCode" (handleBookletActionEvent)="nxtFormHandlerHRM($event)"></lib-booklet>
</div>
<!-- SK02AUG24 Thank you message -->
<p *ngIf="successRegister" class="m-4 d-flex justify-content-center">{{successMessage}}</p>
<div style="height:0px">
    <nb-layout></nb-layout> 
</div>

