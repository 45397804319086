import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EssComponent } from '../ess.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { SideNavEmitService } from 'src/app/services/side-nav-emit.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-worktab',
  standalone:true,
  templateUrl: './worktab.component.html',
  imports: [CommonModule, PageHeaderComponent, FormsModule, ReactiveFormsModule, SuccessFailureMessageComponent,CardsComponent,PopoverComponent,FusionButtonComponent,AngularSvgIconModule,DropDownComponent,TranslateModule,
  EssComponent, CustomTranslatePipe],
  styleUrls: ['./worktab.component.css']
})
export class WorktabComponent implements OnInit {

  isHamClicked:boolean = false;

  sideNavsubscription: Subscription;

  essTabToShow: string = 'details';
  pageComponentName = 'Ess';
  isDevMode: boolean = false;
  hideOrShowNav: string = "side-nav-container";
  basicInfoForm! : FormGroup;
  workInfoForm!: FormGroup;
  pfInfoForm! : FormGroup;
  sampleFile: File | any;
  emailRegex = "[A-Za-z0-9._%-+]+@[A-Za-z0-9._%-]+\\.[a-z]{2,10}";
  numberRegex = "^[0-9]*$";
  mobileRgx = "^((\\+91-?)|0)?[0-9]{10}"; // Added this for text Validation and used them further down the lines
  aadhaarRgx ="^[0-9]{12}";
  panRgx="[A-Z]{5}[0-9]{4}[A-Z]{1}";
  errorMessage = '';
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  companyId = this.globalValues.orgId;

  genders = ['Male', 'Female', 'Others'];
  closeIcon = false;
  tabToShow = {
    home: false,
    details: true,
    leaves: false,
    expenses: false,
    payslips: false,
    companypolicies: false,
    taxdeclarations: false,
    compensation: false,
    companyorgchart: false
  };

  defaultValueDropDown:boolean = false;

  basicGetData:any;
  workGetData:any;
  pfGetData:any;
  expGetData: any;
  docGetData: any;
  userGetData:any;
  workBasicInfo:any;
  allEmpData : any;
  phError = false;
  primaryList:string[]=[];
  primaryReportingManger : string ='';
  secondaryReportingManager : string ='';
  empDepartment : string ='';
  empDesignation: string ='';
  departmentList: string[] =[
    'Administration',
    'Accounts and Finance',
    'Human Resources',
    'Information Technology',
    'Infrastructures',
    'Security Technology'
  ];
  designationList :string[]=[
    'Admin',
    'Accountant',
    'Business Analyst',
    'Developer',
    'Designer',
    'Chief Architect',
    'Chief Technology Officer'
  ]
  
  empIDError = false;
  empTypeError = false;
  locationError = false;
  probationError = false;
  dojError = false;
  workExpError = false;
  empStatusError = false;
  jobTitleError = false;
  workDeptError = false;
  workDesigError = false;
  fileInfos: Observable<any> | null = null;
  base64Output: string | undefined;
  getAllDatas!: object;
  errPercent = false;
  allGetData:any;

  employeeId:any;
  financeInfo:any;

  @Input() fromHrmPage: boolean = false;
  @Input() selectedUser: any;
  @Input() employeeFinanceInfo: any;
  @Input() allEmployeeGet: any;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  empTypeDropdown:any;
  probPeriodDropdown:any;
  
  constructor(private formBuilder: FormBuilder, private apiService: ApiService,private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService, public globalValues: GlobalValuesService, private datePipe: DatePipe, private language:TranslateService) {
    // Made glovalValues as public since we are using it in HTML and it threw an error stating that since it is 'private' it cannot be used in HTML ( outside of this file)
    this.isDevMode = isDevMode();
    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => this.essTabToShow = navItem);


    this.basicInfoForm = this.formBuilder.group({
      'empID' : ['', Validators.required],
      'empType' : ['', Validators.required],
      'empLocation' : ['', Validators.required],
      'probPeriod' : [''],
      'dateOfJoin' : ['', Validators.required],
      'empExperience' : [''],
      'empStatus' : ['', Validators.required],
      'dateOfResign' : [''],
    });

    this.workInfoForm = this.formBuilder.group({
      'jobTitle' : ['',Validators.required],
      'empDept' : [''],
      'empdesignation' : [''],
      'reportingManager1':['',Validators.required],
      'reportingManager2' : [''],
    });


    this.pfInfoForm = this.formBuilder.group({
      'pfAccNum' : ['',Validators.compose([Validators.minLength(8),Validators.maxLength(12),Validators.pattern(this.numberRegex)])],
      'uan' : ['',Validators.compose([Validators.minLength(12),Validators.maxLength(15),Validators.pattern(this.numberRegex)])],
      'esi' : ['',Validators.compose([Validators.minLength(12), Validators.maxLength(17), Validators.pattern(this.numberRegex)])],
      'aadhaar' : ['', Validators.compose([Validators.required,Validators.pattern(this.aadhaarRgx)])],   
      'pan' : ['',Validators.compose([Validators.pattern(this.panRgx)])]
    })
  }

  ngOnInit(): void {
    // SK01APR24
    this.employeeId = this.globalValues?.empId;
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();
    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    }

    // SK27FEB24 getting emptype and  probPeriod dropdowns from local
    // SK06MAR24 settings data as whole stored in local and getting dropdowns from it
    const dropDowns = JSON.parse((localStorage.getItem('settingsData'))as any);
    this.empTypeDropdown = dropDowns?.dropdowns?.filter((bg:any)=>bg.name === 'emptype')[0]
    || {valueList:[
      {key:"", value:""},
      {key:"fullTime", value:"Full Time"},
      {key:"partTime", value:"Part Time"},
      {key:"contract", value:"Freelancer"},
      {key:"freeLancer", value:"Contract"}
    ]};
    this.probPeriodDropdown = dropDowns?.dropdowns?.filter((ms:any)=>ms.name === 'probperiod')[0]
    || {valueList:[
      {key:"", value:""},
      {key:"30days", value:"30 Days"},
      {key:"60days", value:"60 Days"},
      {key:"90days", value:"90 Days"}
    ]};
  }

  ngOnChanges(){
    this.employeeId = this.globalValues?.empId;
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();
    
    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    }
    // SK17JAN24 reducing api call
    this.primaryList = [];
    this.allEmployeeGet?.forEach((element:any)=>{
      const datum = element.employee
    this.primaryList.push(datum.employeeId + ' - ' + datum.firstName + ' ' + datum.lastName);
    })
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sideNavsubscription.unsubscribe();
  }

  sideNavChange(event: any){
    //if(this.isDevMode) console.log("asasdasdasdasdasdas",JSON.stringify(event));
  }
  saveMsg(){
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch', this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }
  deleteMsg(){
    this.alertService.messageOnPass('success', this.deletedMsg);

    this.ngOnInit();
  }
  errorMsg(err: HttpErrorResponse){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  savebasicInfo(){
    if(this.isDevMode) console.log("Submit basic Info");
    const value = this.basicInfoForm.value;
    if(this.basicInfoForm.value.empStatus === 'Active' || this.basicInfoForm.value.empStatus === 'செயலில்' ){
      this.basicInfoForm.value.empStatus = true;
    } else if(
      this.basicInfoForm.value.empStatus === 'Inactive' || this.basicInfoForm.value.empStatus === 'செயலற்றது')

     {
      this.basicInfoForm.value.empStatus = false;
    }

    this.apiService.writeValue('patch', '/employee/basic',{'employeeId': value.empID, 'employmentType': value.empType, 'officeLocation': value.empLocation, 'probationPeriod': value.probPeriod, 'dateOfJoining': value.dateOfJoin, 'workExperience':value.empExperience, 'employeeStatus': value.empStatus, 'dateOfLeft': value.dateOfResign })
      .subscribe({
        next: (basicInfo) => {
          if(this.isDevMode) console.log("Next :", basicInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
  }

  saveworkInfo(){
    if(this.isDevMode) console.log("Submit work Info");
    const value = this.workInfoForm.value;
    
    this.apiService.writeValue('patch','/employee/workinfo',{'jobTitle': value.jobTitle,'department': value.empDept,'designation': value.empdesignation,'employeeId': this.employeeId,'primaryReportingManager': value.reportingManager1,'secondaryReportingManager':value.reportingManager2})
    .subscribe({
      next: (workInfo) => {
        if(this.isDevMode) console.log("Next :", workInfo);
        this.saveMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      }
    });
  }

  setPF(){
    const value = this.pfInfoForm.value;
    this.apiService.writeValue('patch', '/employee/accdetails', {'pfAccountNumber': value.pfAccNum, 'uan': value.uan, 'esi': value.esi, 'aadhaar': value.aadhaar, 'panNumber': value.pan, 'employeeId': this.userGetData?.employeeId})
    .subscribe({
      next: (pfInfo) => {
        if(this.isDevMode) console.log("Next :", pfInfo);
        this.saveMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      }
    });
  }

   /** These are the GET method functions that returns the data from database */
  getESSDatas(){
    this.apiService.fetchValue('/user').subscribe((datas:any) => {
        this.employeeId = datas?.loggedInUser[0]?.employeeId;
        this.getData();
    })
  }

  getData(){
    // SK17JAN24 reducing api call
    this.userGetData = [];
    this.userGetData.push(this.selectedUser);
    this.userGetData = this.selectedUser;
    this.financeInfo = this.employeeFinanceInfo;
    this.setDatas();
  }

  setDatas(){
    this.basicInfoForm = this.formBuilder.group({
      'empID' : [this.userGetData?.employeeId, Validators.required],
      'empType' : [this.userGetData?.basicinfo?.employmentType, Validators.required],
      'empLocation' : [this.userGetData?.basicinfo?.officeLocation, Validators.required],
      'probPeriod' : [this.userGetData?.basicinfo?.probationPeriod],
      'dateOfJoin' : [this.datePipe.transform(this.userGetData?.basicinfo?.dateOfJoining,"YYYY-MM-dd") , Validators.required],
      'empExperience' : [this.userGetData?.basicinfo?.workExperience],
      'empStatus' : [this.userGetData?.isActive ? 'Active' : 'Inactive', Validators.required],
      'dateOfResign' : [this.datePipe.transform(this.userGetData?.basicinfo?.dateOfLeft,"YYYY-MM-dd")],
    });
    
    this.workInfoForm = this.formBuilder.group({
      'jobTitle' : [this.userGetData?.workinfo?.jobTitle,Validators.required],
      'empDept' : [this.userGetData?.workinfo?.department],
      'empdesignation' : [this.userGetData?.workinfo?.designation],
      'reportingManager1' : [this.userGetData?.primaryReportingManager,Validators.required],
     'reportingManager2' : [this.userGetData?.secondaryReportingManager],
    });

    this.pfInfoForm = this.formBuilder.group({
      'pfAccNum' : [this.financeInfo?.pfAccountNumber,Validators.compose([Validators.minLength(8), Validators.maxLength(12),Validators.pattern(this. numberRegex)])],
      'uan' : [this.financeInfo?.uan,Validators.compose([Validators.minLength(12), Validators.maxLength(15), Validators.pattern(this. numberRegex)])],
      'esi' : [this.financeInfo?.esi,Validators.compose([ Validators.minLength(12), Validators.maxLength(17), Validators.pattern(this. numberRegex)])],
      'aadhaar' : [this.financeInfo?.aadhaar,Validators.compose([Validators.required,Validators.pattern(this.aadhaarRgx)])],
      'pan' : [this.financeInfo?.panNumber,Validators.compose([Validators.pattern(this.panRgx)])],    });
  }
  
  handleEmpID(event: any): void{
    if (event.target.value != '') {
      this.empIDError = false;
    } else {
      this.empIDError = true;
    }
  }

  handleEmpType(event: any): void{
    if (event.target.value != '') {
      this.empTypeError = false;
    } else {
      this.empTypeError = true;
    }
  }

  handleLocation(event: any): void{
    if (event.target.value != '') {
      this.locationError = false;
    } else {
      this.locationError = true;
    }
  }

  handleProb(event: any): void{
    if (event.target.value != '') {
      this.probationError = false;
    } else {
      this.probationError = true;
    }
  }

  handleDOJ(event: any): void{
    if (event.target.value != '') {
      this.dojError = false;
    } else {
      this.dojError = true;
    }
  }

  handleEmpExp(event: any): void{
    if (event.target.value != '') {
      this.workExpError = false;
    } else {
      this.workExpError = true;
    }
  }

  handleEmpStat(event: any): void{
    if(event.target.value === 'Active') this.basicInfoForm.controls['dateOfResign'].patchValue('');
    if (event.target.value != '') {
      this.empStatusError = false;
    } else {
      this.empStatusError = true;
    }
  }

  handleJobtitle(event: any): void{
    if (event.target.value != '') {
      this.jobTitleError = false;
    } else {
      this.jobTitleError = true;
    }
  }

  handleWorkDept(event: any): void{
    if (event.target.value != '') {
      this.workDeptError = false;
    } else {
      this.workDeptError = true;
    }
  }

  handleWorkDesig(event: any): void{
    if (event.target.value.length != '') {
      this.workDesigError = false;
    } else {
      this.workDesigError = true;
    }
  }
 

  popOverItemClick(){
    alert("Function called on click");
  }

  clickedButton($event: any){
    if(this.isDevMode) console.log("The button is clicked and here is the $Event", $event);
  }

  cardHeaderIconClick(){
    alert('Header icon is clicked in Card');
  }
 
  emittedValue(selectedValue:any,formName:string){
    this.workInfoForm.controls[formName].setValue(selectedValue.data);
    if(selectedValue.data == 'Select'){
      this.defaultValueDropDown = true;
    }else{
      this.defaultValueDropDown = false;
    }
}
}


