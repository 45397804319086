<nb-layout></nb-layout> <!-- Since we use nebular module for dashboards, the tooltip throws an error, so the nb-layout to be used as wrapper-->
<!-- <div class="page-header">
    <div class="flex align-item-center">
        <div class="show-till-desktop hamburger-menu m-3" (click)="onclickHamburger()">
            <img src="../assets/images/icons/general/hamburger.svg" width="24" height="24" alt="menu icon" />
        </div>
        <div class="logo-company-name hide-on-mobile">
            <span class="logo-image {{showUrl ? 'cursor-pointer' : 'cursor-default'}} dashboard-border" *ngIf="showSettings">
                <div *ngIf="!isCompanyLogo" class="company-avatar-text align-content-center default-icon-size ">
                    {{companyNameLetter}}</div>
                <img *ngIf="isCompanyLogo" [src]="companyLogo" height="40" width="40" />
                <svg-icon src="../assets/images/icons/general/pencil-icon.svg" data-bs-toggle="modal" *ngIf="showUrl" 
                    data-bs-target="#uploadLogo" class="edit-icon logo-edit"></svg-icon>
            </span>
            <span class="logo-image" *ngIf="!showSettings">
                <div *ngIf="!isCompanyLogo" class="company-avatar-text align-content-center default-icon-size ">
                    {{companyNameLetter}}</div>
                <img *ngIf="isCompanyLogo" [src]="companyLogo" height="40" width="40" />
            </span>
            <span class="company-name-text">{{companyName}}</span>
        </div>
    </div>
    <div class="right-icons align-content-center">
        <div class="header-right-icon default-icon-size">
            <div matTooltip="notifications" class="default-icon-size flex justify-center align-item-center" (click)="notifyPopup = !notifyPopup">
                <img class="header-right-img1" src="../assets/images/icons/page-header/bell.png">
                <div>
                    <div class="notification-badge" [class]="count || appliedCounts ? '' : 'd-none'">{{count + appliedCounts}}</div>
                    </div>
                </div>
                <div class="hover-content white-parent cursor-default">
                    <div class="notifyPopup">
                        <div class="box" [class]="notifyPopup ? 'd-block' : 'd-none'">
                            <div>
                                <app-empnotify (notifyCount)="notifyCount($event)" (appliedCount)="appliedCount($event)" ></app-empnotify>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div *ngIf="showSettings" matTooltip="settings" class="header-right-icon default-icon-size"  (click)="showPage()">
            <img class="header-right-img" src="../assets/images/icons/page-header/gear.png">
        </div>
        <div class="header-right-icon default-icon-size header-icon-for-app-launcher">
            <app-popover [isBootstrap]="false" [dynamicPopover]="'Popover string comes here'">
                <div class="hover-base">
                    <div class="default-icon-size flex justify-center align-item-center">
                        <img class="header-right-img" src="../assets/images/icons/general/apps.svg">
                    </div>
                </div>
                <div class="hover-content white-parent cursor-default">
                    <ul class="app-launcher-list d-flex flex-row">
                        <li (click)="goToPage('ess')" *ngIf="rolesArray.includes('ESS User') || showHrm || showSettings" class="app-launcher-popover">
                            <div class="app-list-item flex flex-direction-column align-item-center cursor-pointer stroke-none" [ngClass]="parentApp == 'ess' ? 'active' : ''">
                                <svg-icon src="../assets/images/icons/app-launcher/ess.svg" class="header-sub-icon"></svg-icon>
                                <span class="medium-font">{{'ESS' | customTranslate : '' }}</span>
                            </div>
                        </li>
                        <li (click)="goToPage('hrm')" *ngIf="showHrm || showSettings" class="app-launcher-popover">
                            <div class="app-list-item flex flex-direction-column align-item-center cursor-pointer stroke-none" [ngClass]="parentApp == 'hrm' ? 'active' : ''">
                                <svg-icon src="../assets/images/icons/app-launcher/hrm-app.svg" class="header-sub-icon"></svg-icon>
                                <span class="medium-font">{{'HRM' | customTranslate : '' }}</span>
                            </div>
                        </li>
                        <li (click)="goToPage('app')" [routerLink]="['/app', 'parking']" *ngIf="rolesArray.includes('App User')" class="app-launcher-popover">
                            <div class="app-list-item flex flex-direction-column align-item-center cursor-pointer stroke-none" [ngClass]="parentApp == 'app' ? 'active' : ''">
                                <svg-icon src="../assets/images/icons/app-launcher/hrm-app.svg" class="header-sub-icon"></svg-icon>
                                <span class="medium-font">Parking</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </app-popover>
        </div>
        <div class="header-right-icon default-icon-size white-bg-popover">
            <app-popover [isBootstrap]="false" [dynamicPopover]="''">
                <div class="hover-base">
                    <p class="user-avatar-text align-content-center default-icon-size ">{{firstName}}{{lastName}}</p>
                </div>
                <div class="hover-content">
                    <ul class="profile-hover-list">
                        <li class="" (click)="logout()">
                            <div class="header-list-item cursor-pointer">
                                <svg-icon src="../assets/images/icons/page-header/logout-icon.svg"
                                    class="header-sub-icon"></svg-icon>
                                <span class="header-list-item-span" style="font-size: 13px">Log Out</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </app-popover>
        </div>
    </div>
</div> -->


<!--THIS CODE TO BE USED IN FUTURE FOR JSON BASED FRAMEWORK. DON'T REMOVE IT-->
<div class="modal fade" id="uploadLogo" tabindex="-1" data-bs-backdrop="static">
    <div class="modal-dialog d-flex align-items-center h-100">
        <div class="modal-content modal-custom">
            <div class="modal-header modal-custom-header modal-custom">
                <p class="modal-title fs-5">{{'Upload Logo' | customTranslate : 'Upload Logo'}}</p>
                <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal'"
                [fcBtnId]="'close'" (buttonClickEmit)="filesArrayClear()"></app-fusion-button>
           </div>    
        
            <div class="modal-body modal-custom-body d-flex flex-column">
                <app-file-upload [multiSelectFile]="false" [docVals]="['5 mb','jpg, jpeg, png']" [showPreview]="'iFrame'" [acceptType]="'image/jpeg,image/jpg,image/png'"
                    (selectedFilesEmitter)="fileEmitted($event)" [fileUploadId]="'docId1'" [uploadReadyFiles]="filesArrayComp">
                </app-file-upload>
            </div>
            <div class="modal-footer modal-custom-footer d-flex flex-column">
                <app-fusion-button id="uploadButton" [buttonValue]="'Upload' | customTranslate : 'Upload'" (buttonClickEmit)="clickedButton($event)"
                    [fcBtnId]="'logoUpload'"></app-fusion-button>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-row justify-content-between header">
    <div class="flex align-item-center" style="margin-left: 10px; margin-right: 10px;">
        <div class="show-till-desktop hamburger-menu" (click)="onclickHamburger()">
            <img src="../assets/images/icons/general/hamburger.svg" width="24" height="24" alt="menu icon" />
        </div>
        <div class="logo-company-name hide-on-mobile">
            <span class="logo-image {{showUrl ? 'cursor-pointer' : 'cursor-default'}} dashboard-border" *ngIf="showSettings">
                <div *ngIf="!isCompanyLogo" class="company-avatar-text align-content-center default-icon-size ">
                    {{companyNameLetter}}</div>
                <img *ngIf="isCompanyLogo" [src]="companyLogo" height="40"/>
                <svg-icon src="../assets/images/icons/general/pencil-icon.svg" data-bs-toggle="modal" *ngIf="showUrl" 
                    data-bs-target="#uploadLogo" class="edit-icon logo-edit"></svg-icon>
            </span>
            <span class="logo-image" *ngIf="!showSettings">
                <div *ngIf="!isCompanyLogo" class="company-avatar-text align-content-center default-icon-size ">
                    {{companyNameLetter}}</div>
                <img *ngIf="isCompanyLogo" [src]="companyLogo" height="40"/>
            </span>
            <span class="company-name-text">{{companyName}}</span>
        </div>
    </div>
    <div class="flex align-content-center" style="padding-top: 2.5px;">
        <!-- RS04SEP24
        This snippet conditionally displays a language dropdown, binding it to languageDropdown and currentLang. It displays the key property of each item and emits the selected language code via the dataEmit event. -->
        <div *ngIf="showLanguageDropdown" >  
            <app-drop-down 
                [lists]="languageDropdown" 
                [selectedData]="currentLang"
                dropDownWidth="90px"
                dropBorderRadius="5px"
                [objectDisplayKey]="'key'"
                (dataEmit)="emittedValue($event, 'languageCode')">
            </app-drop-down>
        </div>
        <div class="header-right-icon default-icon-size">
            <div matTooltip="notifications" class="default-icon-size flex justify-center align-item-center" (click)="notifyPopup = !notifyPopup">
                <img class="header-right-img1" src="../assets/images/icons/page-header/bell.png">
                <div>
                    <div class="notification-badge" [class]="count || appliedCounts ? '' : 'd-none'">{{count + appliedCounts}}</div>
                </div>
            </div>
            <div class="hover-content white-parent cursor-default">
                <div class="notifyPopup">
                    <div class="box" [class]="notifyPopup ? 'd-block' : 'd-none'">
                        <div>
                            <app-empnotify (notifyCount)="notifyCount($event)" (appliedCount)="appliedCount($event)" ></app-empnotify>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showSettings" matTooltip="settings" class="header-right-icon default-icon-size"  (click)="showPage()">
            <img class="header-right-img" src="../assets/images/icons/page-header/gear.png">
        </div>
        <div class="header-right-icon default-icon-size header-icon-for-app-launcher">
            <app-popover [isBootstrap]="false" [dynamicPopover]="'Popover string comes here'">
                <div class="hover-base">
                    <div class="default-icon-size flex justify-center align-item-center">
                        <img class="header-right-img" src="../assets/images/icons/general/apps.svg">
                    </div>
                </div>
                <div class="hover-content white-parent cursor-default">
                    <ul class="app-launcher-list d-flex flex-row" >
                        <li (click)="goToPage({name:'ess'})" *ngIf="rolesArray.includes('ESS User') || showHrm || showSettings" class="app-launcher-popover">
                            <div class="app-list-item flex flex-direction-column align-item-center cursor-pointer stroke-none" [ngClass]="parentApp == 'ess' ? 'active' : ''">
                                <svg-icon src="../assets/images/icons/app-launcher/ess.svg" class="header-sub-icon"></svg-icon>
                                <!-- RS27AUG24 -->
                                <!-- ADDED MARGIN -->
                                <!-- Used the translate pipe to successfully implement the translation -->
                                <span class="medium-font" style="margin:0%;">{{ 'ESS' | customTranslate : 'ESS'  }}</span>
                            </div>
                        </li>
                        <li (click)="goToPage({name:'hrm'})" *ngIf="showHrm || showSettings" class="app-launcher-popover">
                            <div class="app-list-item flex flex-direction-column align-item-center cursor-pointer stroke-none" [ngClass]="parentApp == 'hrm' ? 'active' : ''">
                                <svg-icon src="../assets/images/icons/app-launcher/hrm-app.svg" class="header-sub-icon"></svg-icon>
                                <span class="medium-font" style="margin:0%;">{{ 'HRM' | customTranslate : 'HRM'  }}</span>
                            </div>
                        </li>
                        <!-- SK 20DEC23 User with 'Super User' permission can also acces custom apps and the app will be visible if 'showInLauncher' is true from -->
                        <div *ngIf="rolesArray.includes('App User') || rolesArray.includes('Super User')" class="flex">
                            <div *ngFor="let app of configJSON">
                                <li *ngIf="app.showInLauncher" (click)="goToPage({name:'app', appName: app.name, moduleName: app.modules[0].name, tabName: app.modules[0].tabs[0].name})" class="app-launcher-popover">
                                    <div class="app-list-item flex flex-direction-column align-item-center cursor-pointer stroke-none" [ngClass]="activeApp == app.name  ? 'active' : ''" [ngStyle]="app.style">
                                        <img [src]="app.icon" [alt]="app.label" width="25" height="25">
                                        <span class="medium-font" style="margin:0%;">
                                            {{app.label | customTranslate : app.label}}
                                        </span>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </app-popover>
        </div>
        <div class="header-right-icon default-icon-size white-bg-popover">
            <app-popover [isBootstrap]="false" [dynamicPopover]="''">
                <div class="hover-base">
                    <p class="user-avatar-text align-content-center default-icon-size ">{{firstName}}{{lastName}}</p>
                </div>
                <div class="hover-content">
                    <ul class="profile-hover-list">
                        <li class="mb-2" (click)="logout()">
                            <div class="header-list-item cursor-pointer">
                                <svg-icon src="../assets/images/icons/page-header/logout-icon.svg"
                                    class="header-sub-icon"></svg-icon>
                                <span class="header-list-item-span" style="font-size: 13px">{{ 'LOG_OUT' | customTranslate : 'Log Out'  }}</span>
                            </div>
                        </li>
                        <!-- SK06MAR24 refresh the userData in local to skip logout and login -->
                        <li class="mb-2" (click)="refreshLocal()">
                            <div class="header-list-item cursor-pointer">
                                <svg-icon src="../assets/images/icons/page-header/refresh.svg"
                                    class="header-sub-icon"></svg-icon>
                                <span class="header-list-item-span" style="font-size: 13px">{{ 'REFRESH_DATA' | customTranslate : 'Refresh Data'  }}</span>
                            </div>
                        </li>
                        <!--FOR SAVING USER JSON-->
                        <!-- <li class="" (click)="saveJSONObject()">
                            <div class="header-list-item cursor-pointer">
                                <span class="header-list-item-span" style="font-size: 13px">Save JSON</span>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </app-popover>
        </div>
    </div>
</div>

<!-- SK06MAR24 ngx spinner -->
<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>