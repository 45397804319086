import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from '../components/data-table/data-table.component';
import { ApiService } from '../services/api.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalComponent } from '../components/modal/modal.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CardsComponent } from '../components/cards/cards.component';
import { FusionButtonComponent } from '../components/fusion-button/fusion-button.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../services/error-handler.service';
import { AlertService } from '../services/alert.service';
import { GlobalValuesService } from '../services/global-values.service';
//RS27AUG24
//imported the TranslateModule from @ngx-translate/core to enable translation features in the application.
import { TranslateModule } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-drop-down-management',
  standalone: true,
  imports: [
    CommonModule,
    DataTableComponent,
    AngularSvgIconModule,
    ModalComponent,
    CardsComponent,
    FusionButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CustomTranslatePipe
  ],
  templateUrl: './drop-down-management.component.html',
  styleUrls: ['./drop-down-management.component.css']
})
export class DropDownManagementComponent implements OnInit {
  dropDownList: any; //SK23FEB24 the dropdown list with tabs and values
  displayedTabContent: any; //SK23FEB24 active tab name
  openModal = false; //SK23FEB24 to open/close based on condition
  newDropDownForm!: FormGroup; //SK23FEB24 dropdown form declare
  loadingSpinner = false; //SK23FEB24 spinner o saving the modal data
  errorMessage = ''; //SK23FEB24

  constructor(private apiService: ApiService, private fb: FormBuilder, private alertService: AlertService, private errorHandler: ErrorHandlerService, private globalValues: GlobalValuesService) {
    //SK23FEB24 form for dropdown 
    this.newDropDownForm = this.fb.group({
      name: ["", Validators.required],
      label: ["", Validators.required],
      type: [""],
      language: [""],
      default: [""]
    })
  }

  ngOnInit(): void {
    this.initialData(); //SK23FEB24
  }

  //SK23FEB24 initially called to fetch the dropdown in data table 
  initialData() {
    this.dropDownList = {
      "name": "dropDownManagement",
      "label": "Drop Down Management",
      "icon": "",
      "tabs": [
        {
          "name": "dropDownManagement",
          "label": "DropDown List",
          "type": "table",
          "browsedTab":false,
          "config": {
            "endpoint": "/user",
            "apiCall": "get",
            "type": "tableData",
            "responseObject": true,
            "responseObjectKey": "settings[0].dropdowns",
            "responseArray": false,
            "searchBar": true,
            "addInlineRecord": false,
            "headerLabels": [
              "Name",
              "Label",
              "Language",
              "Type"
            ],
            "displayedColumns": [
              "name",
              "label",
              "language",
              "type"
            ],
            "hyperLinkColumns": [
              "name"
            ],
            "fromWhere": "common",
            "isButtons": true,
            "withCheckBox": true,
            "isEditRow": true,
            "isDeleteRow": true,
            "actionButton": true,
            "buttons": [
              {
                "name": "New Dropdown",
                "class": "btn btn-primary",
                "buttonType": "newRecord",
                "buttonAction": "openModal"
              }
            ]
          }
        }
      ]
    }
  
    this.dropDownList.tabs.forEach((element: any) => {
      if (element.name === 'dropDownManagement') {
        this.displayedTabContent = element.name;
        this.getData(element.config.apiCall, element.config.endpoint, element);
      }
    });
  }

  //SK23FEB24 get data function for table
  getData(apiCall: any, endPoint: any, event: any) {
    if (apiCall === 'get') {
      this.apiService.fetchValue(endPoint).subscribe(async (getData: any) => {
        if (event.config.responseObject) {
          const key = event.config.responseObjectKey;
          if (event.config.type == 'tableData') {
            event.config.data = [];
            event.config.data = this.getValue(getData, key);
            const tabs = JSON.parse(localStorage.getItem('dropdownTabList') as any) || [];
            event.config.data.forEach((element:any) => {
              if(tabs.includes(element.name) && element.name !== 'dropDownComponent'){
                this.tabDataStructure({element:element});
              }
            });
            const list = event.config.data.filter((data:any)=> data.name === localStorage.getItem('activeDropDownTab'));
            if(list.length > 0)this.hyperLinkColumnEmit({element:list[0]}) // SK27FEB24
          }
        }
      })
    }
  }

  //SK23FEB24 spits the object and returns the value
  getValue(element: any, column: string) {
    let flds = column.split('.');
    for (let i = 0; i < flds.length; i++) {
      let splitFlds = flds[i].split('[');
      if (splitFlds.length === 1) {
        element = element[flds[i]] || '' as any;
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        element = element[splitFlds[0]][index] || '' as any; //SK 20DEC23 To solve undefined error
      }
    }
    return element;
  }

  newDropDownRecord = false;
  //SK23FEB24 on clicking the new dropdown button in table
  commonButtonClickEmit(event: any) {
    this.newDropDownRecord = true;
    event.buttonAction === 'openModal' ? this.openModal = true : this.openModal = false;
  }

  //SK23FEB24 this is the structure for dropdown values, to listit in table 
  tabDataStructure(event:any){
    const tabData = {
      "name": event.element.name,
      "label": event.element.label,
      "type": "table",
      "browsedTab":true,
      "config": {
        "data":event.element.valueList,
        "searchBar": true,
        "addInlineRecord": true,
        "tableSaveButton":true,
        "inlineElement":[{key:"",value:"",order:""}],
        "headerLabels": [
          "Key",
          "Value",
          "Order"
        ],
        "displayedColumns": [
          "key",
          "value",
          "order"
        ],
        "editColumns": [
          "key",
          "value",
          "order"
        ],
        "fromWhere": "common",
        "isButtons": false,
        "withCheckBox": true,
        "isEditRow": true,
        "isDeleteRow": true,
        "actionButton": true,
      }
    };
    const tabs = JSON.parse(localStorage.getItem('dropdownTabList') as any) || [];
    !tabs.includes(event.element.name) ? tabs.push(event.element.name) : "";
    const list = this.dropDownList.tabs.filter((tab:any)=> tab.name === event.element.name);
    if(list.length === 0){
      this.dropDownList.tabs.push(tabData);
    } 
    localStorage.setItem('dropdownTabList', JSON.stringify(tabs));
    return tabData;
  }

  // on clicking the hyperlink in dropdown list the selected tab data will be listed
  hyperLinkColumnEmit(event: any) {
    this.currentTabNav(this.tabDataStructure(event))
  }

  //SK23FEB24 on clicking the tabs
  currentTabNav(eachTypeOfComponent: any) {
    localStorage.setItem('activeDropDownTab', eachTypeOfComponent.name);
    this.displayedTabContent = eachTypeOfComponent.name;
  }

  //SK23FEB24
  closeBrowseTab(eachTypeOfComponent: any, i: any) {
    this.dropDownList.tabs.splice(i,1);
    const tabs = JSON.parse(localStorage.getItem('dropdownTabList') as any);
    const ind = tabs.findIndex((i:any)=>i === eachTypeOfComponent.name);
    tabs.splice(ind,1);
    localStorage.setItem('dropdownTabList', JSON.stringify(tabs));
    if(eachTypeOfComponent.name === this.displayedTabContent){
      this.currentTabNav(this.dropDownList.tabs[i - 1]);
    }
  }

  //SK23FEB24 this is for saving the dropdown values, i.e. inline edit save
  saveTableData(event: any) {
    if(event.allData?.length > 0) event.allData?.sort((a:any, b:any) => a.order - b.order); // SK13MAR24
    this.apiService.writeValue('patch','/settings/dropdown',{...{name:this.displayedTabContent},...{valueList:event.allData}})
    .subscribe({
      next: (success: any) => {
        this.saveSuccess(success.message);
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }

  //SK23FEB24
  modalClose(event: any) {
    this.openModal = false;
    this.newDropDownForm.reset();
  }

  //SK23FEB24 post/patch call for saving/editing the dropdown data
  addDropDown(call: any) {
    this.loadingSpinner = true;
    if (call == 'post') {
      this.apiService.writeValue('post', '/settings/dropdown', this.newDropDownForm.value)
        .subscribe({
          next: (success: any) => {
            this.saveSuccess(success.message);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
        });
    } else if (call == 'patch') {
      this.apiService.writeValue('patch', '/settings/dropdown', this.newDropDownForm.value)
        .subscribe({
          next: (success: any) => {
            this.saveSuccess(success.message);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
        });
    }
  }

  //SK23FEB24 on edit the dropdown, modal opens up with the data bindIn
  onEditRecord(event: any) {
    if(event.data.key === undefined){
      this.newDropDownForm.patchValue({
        name: event.data.name,
        label: event.data.label,
        type: event.data.type,
        language: event.data.language,
        default: event.data.default
      })
      this.newDropDownRecord = false;
      this.openModal = true;
    }
  }

  //SK23FEB24 delete the dropdown record
  onDeleteRecord(event: any) {
    // SK27FEB24 the specific dropdown is not deletable
    if(event.data.name != undefined){
      // SK28FEB24 removed the delete condition for dropdown
      // if(['gender', 'documents', 'maritalstatus', 'bloodgroup', 'emptype', 'probperiod', 'degree', 'relation'].includes(event.data.name)){
      //   this.alertService.messageOnPass('error', "This dropdown is not deletable");
      // } 
      // else {
        this.apiService.writeValue('delete', '/settings/dropdown?name=' + event.data.name, null).subscribe({
          next: (success: any) => {
            this.saveSuccess(success.message);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
        })
      // }
    } else {
      event.allData.splice(event.index,1);
      this.globalValues.tableData({config:{data:event.allData}});
    }
  }
 
  //SK23FEB24 success message component
  saveSuccess(data: any) {
    this.alertService.messageOnPass('success', data)
    this.initialData();
    this.openModal = false;
    this.newDropDownForm.reset();
    this.errorMessage = '';
    this.loadingSpinner = false;
  }

  //SK23FEB24 error message component
  showError(err: any) {
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', err);
    this.loadingSpinner = false;
  }
}
