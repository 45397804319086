<div *ngIf="filterType === 'noncomparison'">
    <div [ngSwitch]="chart">
        <div class="col-md-12" *ngSwitchCase="'Advanced Pie'">
            <div class="chart-container">
                <nb-card size="medium">
                    <nb-card-header>{{ label | customTranslate : label }}</nb-card-header>
                    <nb-card-body>
                        <ngx-charts-advanced-pie-chart [scheme]="colorScheme" [results]="data">
                        </ngx-charts-advanced-pie-chart>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
        <div class="col-md-12" *ngSwitchCase="'Pie'" >
            <div class="chart-container">
                <nb-card size="medium">
                    <nb-card-header>{{label | customTranslate : label}}</nb-card-header>
                    <nb-card-body >
                        <ngx-charts-pie-chart [scheme]="colorScheme" [results]="data" [legend]="true"
                            [labels]="showLabels"[legendPosition]="below" >
                            
                        </ngx-charts-pie-chart>
                        
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
        <div class="col-md-12" *ngSwitchCase="'Bar'">
            <div class="chart-container">
                <nb-card size="medium">
                    <nb-card-header>{{label | customTranslate : label}}</nb-card-header>
                    <nb-card-body>
                        <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="data" [xAxis]="showXAxis"
                            [yAxis]="showYAxis" [legend]="true" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [legendPosition]="below">
                        </ngx-charts-bar-vertical>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </div>
</div>
<div *ngIf="filterType === 'comparison'">
    <div [ngSwitch]="chart">
        <div class="col-md-6" *ngSwitchCase="'Bar'">
            <div class="chart-container">
                <nb-card size="medium">
                    <nb-card-header>{{label | customTranslate : label}}</nb-card-header>
                    <nb-card-body>
                        <chart type="bar" [data]="data" [options]="barChartOptions" ></chart>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
        <div class="col-md-6" *ngSwitchCase="'Line'">
            <div class="chart-container">
                <nb-card size="medium">
                    <nb-card-header>{{label | customTranslate : label}}</nb-card-header>
                    <nb-card-body>
                        <ngx-charts-line-chart
                            [scheme]="colorScheme"
                            [results]="data"
                            [xAxis]="showXAxis"
                            [yAxis]="showYAxis"
                            [legend]="true"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [legendPosition]="below">
                        </ngx-charts-line-chart>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </div>
</div>