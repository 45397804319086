<!-- SK15FEB24 Multi check component -->
<div class="card flex {{cardClass}} {{cardSize}}">
    <div class="border-bottom">
        <label class="form-label m-3 fw-bold">{{label | customTranslate : label}}</label>
    </div>
    <!-- SK03OCT24 search filter in multiple checkbox -->
    <div class="search m-3">
        <div class="search-bar flex">
            <input class="medium-font width-100" type="text" placeholder="{{ 'SEARCH' | customTranslate : 'Search'  }}..." [(ngModel)]="searchText"
                style="border:none;">
            <img class="card-btn" src="../assets/images/icons/general/Search.svg">
        </div>
    </div>
    <!-- SK20FEB24 if the list is array, the list will be shown as it is -->
    <!-- SK14MAR24 fixed stylings and added Select All/ Unselect Allcheckbox -->
    <div *ngIf="!isArrayofObj" style="{{checkBoxStyles}}">
        <div class="row flex">
            <mat-checkbox class="mt-2" #allCheck *ngIf="!radioCheck" (click)="$event.stopPropagation()" (change)="checkAction('Select/UnSelect All', $event)">
                    <span class=" text-wrap" style="font-size: small;" >{{"Select/UnSelect All" | customTranslate : 'Select/UnSelect All'}}</span>
            </mat-checkbox>
            <div *ngIf="radioCheck" #allCheck ></div>
            <div class="{{cardInlineSize}} flex flex-row  pt-2 pb-2" *ngFor="let list of checkboxList | searchFilter : searchText; let i = index">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? checkboxSelection.toggle(list) : null" (change)="checkAction(list, $event)"
                    [checked]="checkboxSelection.isSelected(list)">
                    <span class=" text-wrap" style="font-size: small;" >{{list | customTranslate : list}}</span>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <!-- SK20FEB24 if the list is array of objects, the mopdified list will be shown -->
    <!-- SK14MAR24 fixed stylings and added Select All/ Unselect Allcheckbox -->
    <div *ngIf="isArrayofObj" style="{{checkBoxStyles}}">
        <div class="row flex">
            <mat-checkbox #allCheck *ngIf="!radioCheck" (click)="$event.stopPropagation()" (change)="checkAction('Select/UnSelect All', $event)">
                <span class=" text-wrap" style="font-size: small;" >{{"Select/UnSelect All" | customTranslate : "Select/UnSelect All"}} </span>
            </mat-checkbox>
            <div class="{{cardInlineSize}} flex flex-row pt-2 pb-2" *ngFor="let list of checkboxList | searchFilter : searchText : (displayValues?.keyString || 'label | customTranslate : label'); let i = index">
                <mat-checkbox class=" flex-wrap" (click)="$event.stopPropagation()" (change)="$event ? checkboxSelection.toggle(list) : null" (change)="checkAction(list, $event)"
                    [checked]="checkboxSelection.isSelected(list)">
                    <span class=" text-wrap" style="font-size: small;" >{{ displayValues?.keyString ? (list[displayValues.keyString] | customTranslate : list[displayValues.keyString] ) : (list.label | customTranslate : list.label) }}
                    </span>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="m-2 d-flex justify-content-end">
        <app-fusion-button *ngFor="let button of buttonList" [buttonType]="button.class" [buttonValue]="button.label | customTranslate : button.label"
            (buttonClickEmit)="buttonClick(button)">
        </app-fusion-button>
    </div>
</div>
<!-- [disabled]="checkboxSelection.isSelected('No Access') && days !== 'No Access'" -->