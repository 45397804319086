import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Constants } from "../models/Constants";
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  constructor(private httpClient: HttpClient,private datePipe: DatePipe) { }

  // This function does the GET call alone 
  // SK10MAY24 commented refreshToken function
 fetchValue(apiPath: string, httpsParams: HttpParams | undefined = undefined):Observable<[]>{
  // this.refreshTokenFunction();
    let getURL = Constants.SERVER_BASE_URL;
    // lost track of why we introduced this if condition must be for some undefined , null error hanling , for now keeping it as it is, 
    // may be later we directly bind the constants with path without checking for the path value 
    if (apiPath){  
      getURL =  getURL + apiPath;
    }
    return httpsParams ? this.httpClient.get<[]>(getURL,{params: httpsParams}) : this.httpClient.get<[]>(getURL); 
  }

  writeValue(writeType: string, apiPath: string, apiPayload: object | null):Observable<[]>{
    // this.refreshTokenFunction();
    let returnValue: any;
    let apiURL = Constants.SERVER_BASE_URL;
    // lost track of why we introduced this if condition must be for some undefined , null error hanling , for now keeping it as it is, 
    // may be later we directly bind the constants with path without checking for the path value
    if(apiPath){
       apiURL = apiURL + apiPath;
    }
    switch(writeType){
       case 'patch':
         returnValue = this.httpClient.patch<[]>(apiURL, apiPayload).pipe(
           shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
           catchError(this.handleError)
         );
       break;
       case 'put':
         returnValue = this.httpClient.put<[]>(apiURL, apiPayload).pipe(
           shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
           catchError(this.handleError)
         );
       break;
       case 'post':
         returnValue = this.httpClient.post<any>(apiURL, apiPayload).pipe(
           shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
           catchError(this.handleError)
         );
       break;
       case 'delete':
          if(apiPayload && apiPayload != null){
            returnValue = this.httpClient.delete<[]>(apiURL, apiPayload); // Will be advisible to add the pipe, sharereplay, catchError.
          } else {
            returnValue = this.httpClient.delete<[]>(apiURL); // Will be advisible to add the pipe, sharereplay, catchError.
          } 
    }
    return returnValue;
  }

  // entryCall(apiPayload:any){
  //   return  this.httpClient.post<any>(Constants.SERVER_BASE_URL, apiPayload).pipe(
  //     shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
  //     catchError(this.handleError)
  //   );
  // }

  private handleError(error: HttpErrorResponse) {
    // Return an observable with a user-facing error message.
    return throwError(() => new Error(JSON.stringify(error)));
  }

  getCSVTemplates(type: string): Observable<Blob>{
    // this.refreshTokenFunction();
    return this.httpClient.get(Constants.SERVER_BASE_URL + '/template?type=' + type.trim(),{responseType: 'blob'});
  }

  // SK10APR24 export the employee attendance from server
  // SK22APR24 accessor is added to query to find HR or Manager
  // SK02SEP24 startDate and endDate sent for attendance
  getAttendance(apiPath: string, month:string, accessor:string, startDate:string, endDate:string): Observable<Blob>{
    // this.refreshTokenFunction();
    let getURL = Constants.SERVER_BASE_URL;
    // lost track of why we introduced this if condition must be for some undefined , null error hanling , for now keeping it as it is, 
    // may be later we directly bind the constants with path without checking for the path value 
    if (apiPath){  
      getURL =  getURL + apiPath;
    }
    // return httpsParams ? this.httpClient.get<[]>(getURL,{params: httpsParams},{responseType: 'blob'}) : this.httpClient.get<[]>(getURL); 
    return this.httpClient.get(Constants.SERVER_BASE_URL + '/template?type=' + apiPath.trim()+'&month='+month+'&accessor='+accessor+'&startDate='+startDate+'&endDate='+endDate,{responseType: 'blob'});
  }

  getEsiPFTemplates(type: string): Observable<Blob>{
    // this.refreshTokenFunction();
    return this.httpClient.post(Constants.SERVER_BASE_URL + '/template?type=' + type.trim(),null,{responseType: 'blob'});
  }

  // SK22APR24 accessor is added to query to find HR or Manager
  uploadAttendance(file: any, accessor:any):Observable<any> {
    // this.refreshTokenFunction();
    let uploadUrl = Constants.SERVER_BASE_URL+'/hrm/attendance/employee?accessor='+accessor;
    return this.httpClient.patch(uploadUrl, file).pipe(
      shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
      catchError(this.handleError)
    );
  }

  refreshToken(): Observable<any>{
    let apiURL = Constants.SERVER_BASE_URL;
    const refToken: any = (localStorage.getItem('refresh_token'));
    return this.httpClient.post<[]>(apiURL + '/user/timeout', {refreshToken:refToken}).pipe(
      shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
      catchError(this.handleError)
    );
  }

  refreshTokenFunction(): any{
    const expTime: any = (localStorage.getItem('expires_at'));
    if (new Date() >= new Date (expTime * 1000)) {
      this.refreshToken().subscribe({
        next:(data:any) => {
          localStorage.setItem('auth_token', data.token);
          const expiresAt = (JSON.parse(window.atob(data.token.split('.')[1])).exp);
          localStorage.setItem("expires_at", expiresAt );
        },
        error: (error:any) => {
        }
      })
    };
  }

  // SK24MAY24 getting excel file from api
  getXlsx(type: string): Observable<Blob>{
    // this.refreshTokenFunction();
    return this.httpClient.get(Constants.SERVER_BASE_URL + type,{responseType: 'blob'});
  }

  // SK30MAY24 file upload
  uploadFiles(endpoint:any, file:FormData): Observable<any>{
    let apiURL = Constants.SERVER_BASE_URL;
    return this.httpClient.post<any>(apiURL+endpoint,file).pipe(
      shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
      catchError(this.handleError)
    );
  }

  // SK15JUL24 fetching the data for tabs component
  fetchData(url:any): Observable<any> {
    return this.httpClient.get<any>(Constants.SERVER_BASE_URL + url).pipe(
      shareReplay(), //to prevent the receiver of this Observable from accidentally triggering multiple POST requests due to multiple subscriptions
      catchError(this.handleError)
    );
  }

  fetchDataAsPromise(url:any): Promise<any> {
    return this.fetchData(url).toPromise();
  }

  // SK21AUG24 promise post logic for file
  uploadDataAsPromise(url:any,file:FormData): Promise<any> {
    return this.uploadFiles(url,file).toPromise();
  }
}



