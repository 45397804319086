<div class="content-section flex flex-column flex-grow max-width-minus-side-nav" style="--grow-value: 1">
  <app-page-header></app-page-header>
  <!-- SK01FEB24, Changed Dashboard as separate module -->
  <div class="main-content Compensation" *ngIf="hrmTabToShow == 'dashboard' ">
    <!-- <div class="app-tab-name">Dashboard</div> -->
    <div class="fc-tab-view">
      <div class="flex flex-direction-row fc-tab-buttons">
        <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
         <li class="nav-item" role="presentation">
            <button class="nav-link active" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard-tab-pane"
              type="button" role="tab" aria-controls="dashboard-tab-pane" aria-selected="false">{{ 'DASHBOARD' | customTranslate : 'Dashboard' }}</button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="dashboardTabContent">
        <div class="tab-pane fade show active" id="dashboard-tab-pane" role="tabpanel" aria-labelledby="dashboard-tab" tabindex="0">
          <div class="flex flex-column">
            <!-- <div class="flex flex-row">
              <div class="flex flex-column">
                <label class="form-label label-txt modal-field-label">Type of Chart</label>
                <app-drop-down  class="field-input mt-2" [lists]="['Comparison Charts', 'Non-Comparison Charts']" dropDownHeight="38px" dropDownWidth="270px" (dataEmit)="dropdownEmitFunction($event, 'type')" [selectedData]="'Non-Comparison Charts'"></app-drop-down>
              </div>
              <div class="flex flex-column ms-4">
                <label class="form-label label-txt modal-field-label">Metrics</label>
                <app-drop-down  class="field-input mt-2" [lists]="['Designation', 'Gender', 'Experience', 'Office Location', 'Employment Type', 'Job Title', 'Blood Group', 'Marital Status']" dropDownHeight="38px" dropDownWidth="270px" (dataEmit)="dropdownEmitFunction($event, 'metrics')" [selectedData]="'Designation'"></app-drop-down>
              </div>
            </div> -->
            <app-dashboard *ngIf="metricsArray" [metricsArray]="metricsArray"></app-dashboard>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content" *ngIf="hrmTabToShow == 'employees' ">
    <div class="app-tab-name">{{ 'EMPLOYEES' | customTranslate : 'Employees' }}</div>
    <div class="fc-tab-view">
      <div class="flex flex-direction-row fc-tab-buttons">
        <ul class="nav nav-tabs fc-tabs" id="employeesTabView" role="tablist">
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link active" id="hrmDashboard" data-bs-toggle="tab" data-bs-target="#dashboard-tab-pane"
              type="button" role="tab" aria-controls="dashboard-tab-pane" aria-selected="false" (shown.bs.tab)="dashboardAPI()">Dashboard</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="employeesTab" data-bs-toggle="tab" data-bs-target="#employees-tab-pane"
              type="button" role="tab" aria-controls="employees-tab-pane" aria-selected="false" (shown.bs.tab)="showTabs()">Employees</button>
          </li> -->
          <li *ngFor="let tab of tabsList; let i=index" class="nav-item flex " role="presentation">
            <!-- SK24JAN24, Prevoiusly only the active tab is fetched, So that tab is directly pushed as Array, Now Sub Tab is also stored in local now it is pushed as object with tabs and their active sub tab -->
            <button class="nav-link"  id="{{tab.tab}}" data-bs-toggle="tab" [class]="tab?.tab === activeTab?.tab ? 'active': ''" 
              type="button" role="tab" (click)="showTabs(tab)">{{tab.tab | customTranslate : tab.tab}}<!--SK 20JAN24 Changes form 'shown.bs.tab' to 'click', since had a bug on cliking tab -->
            </button>
            <svg-icon class="close-hover" *ngIf="i > 0" src="../assets/images/icons/general/cross-icon.svg" (click)="closeBrowseTab(tab,i)"></svg-icon>
          </li>
        </ul>
      </div>
      <!-- SK18JAN24 to display employee record in separate tab -->
      <div class="tab-content" id="fcTabContent" [ngSwitch]="tabSwitch">
        <!-- <div *ngSwitchCase="'Dashboard'">
          <div class="flex flex-column">
            <app-dashboard [metricsArray]="metricsArray"></app-dashboard>
          </div>
        </div> -->
        <div *ngSwitchCase="'Employees'" >
          <div class="tab-content container-lg list-employees" >
            <div class="tab-pane fade show active mt-3" id="v-pills-employees" role="tabpanel"
              aria-labelledby="v-pills-employees-tab" tabindex="0">
              <header class="py-3">
                <div class="container p-0">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                      <div>
                      <app-fusion-button (buttonClickEmit)="getEmp(); this.selection.clear();" [buttonType]="'btn btn-primary'" data-bs-toggle="modal"
                        data-bs-target="#addEmployeeModal" [buttonValue]="'NEW_EMPLOYEE' | customTranslate : 'New Employee'">
                      </app-fusion-button>
                    </div>
                    <div class="d-flex imp-exp-btns">
                        <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'"
                        [modalToTrigger]="'uploadEmployeeModal'" [buttonValue]="'IMPORT' | customTranslate : 'Import'"
                        [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'"></app-fusion-button> <!--SKS24OCT24 svg change-->
                  <!-- SK19JUL24 need to work on backend -->
                    <!-- <div>
                      <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'" (buttonClickEmit)="downloadTemplate('employee')"
                          [buttonValue]="'EXPORT_ALL' | translate" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
                        </app-fusion-button>
                    </div> -->
                      <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'" [buttonDisable]="downloadCurrentDisable" (buttonClickEmit)="downloadCurrentEmployees()" [buttonValue]="'EXPORT_SELECTED' | customTranslate : 'Export Selected'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/upload-icon.svg'" [isImageSvg]="true">
                      </app-fusion-button>
                    </div>
                  </div>
                </div>
              </header>
              <!-- SK03OCT24 added input/output for filter screen -->
              <app-data-table *ngIf="allData && !isDynamicHRM" [data]="allData" [tableWidth]="'115%'" 
                [displayedColumns]="['employeeId', 'employee.firstName', 'employee.workinfo.designation','employee.workinfo.department', 'employee.email','employee.phone','employee.basicinfo.employmentType','financeInfo.uan','financeInfo.aadhar']"
                [headerLabels]="['EmployeeId', 'Name', 'Designation','Department','Email','Phone','Employment Type','UAN Number','Aadhar Number']"
                [translatePath]="'HRM_ALLDATA_TABLE'"
                [withCheckBox]="true" [searchBar]="true" (tableRowClick)="selectedRow($event)"
                [multipleFilter]="multipleFilterArray"
                [fromWhere]="'hrm'" 
                [exportFieldsListConfigs]="exportFieldsListConfigs"
                [exportFilterListConfigs]="exportFilterListConfigs"
                [isButtons]="true"
                [filterScreenConfigs]="filterScreenConfigs"
                (selectedValues)="tableCheckedDataa($event)"
                (exportButton)="exportButtonEmit($event)"
                (sideNavButtons)="sideNavSavButtonEmit($event)"
                (exportReports)="exportReports()"
                (multipleSelectFilterExport)="multipleSelectFilterExport($event)"
                (filterDateEmitEvt)="filterDateEmitEvt($event)">
              </app-data-table>
                <!-- SK 21DEC23 If the COnfig JSON has hrm app, that datas will be passed to table-->
              <app-data-table *ngIf="allData && isDynamicHRM" [data]="allData"  
                [displayedColumns]="configJSON.displayedColumns"
                [headerLabels]="configJSON.headerLabels"
                [translatePath]="'EMP_TABLE'"
                [withCheckBox]="configJSON.withCheckBox" [searchBar]="configJSON.searchBar" (tableRowClick)="selectedRow($event)"
                [filterColumns]="configJSON?.filterColumns || []"
                [multipleFilter]="multipleFilterArray"
                [fromWhere]="'hrm'" 
                [exportFieldsListConfigs]="configJSON?.exportFieldsListConfigs || exportFieldsListConfigs"
                [exportFilterListConfigs]="configJSON?.exportFilterListConfigs || exportFilterListConfigs"
                [isButtons]="configJSON?.isButtons"
                [buttonArray]="configJSON?.buttons"
                [filterScreenConfigs]=" configJSON?.filterScreenConfigs ||filterScreenConfigs"
                (selectedValues)="tableCheckedDataa($event)"
                (exportButton)="exportButtonEmit($event)"
                (sideNavButtons)="sideNavSavButtonEmit($event)"
                (exportReports)="exportReports()"
                (multipleSelectFilterExport)="multipleSelectFilterExport($event)"
                (filterDateEmitEvt)="filterDateEmitEvt($event)">
              </app-data-table>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'employeeId'" >
          <app-browse [selectedEmployee]="selectedEmployee" [employeeFinanceInfo]="employeeFinanceInfo" [empGet]="empGet" [employeeLeaves]="employeeLeaves" [employeeExpenses]="employeeExpenses" [expenseHistory]="expenseHistory" [staticComp]="true"></app-browse>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content leave-allocation" *ngIf="hrmTabToShow == 'leaveAllocation' ">
    <!-- <div class="app-tab-name">{{ 'LEAVE_ALLOCATION' | customTranslate : 'Leave Allocation' }}</div> -->
    <div class="fc-tab-view">
      <div class="flex flex-direction-row tab-view-buttons">
        <ul class="nav nav-tabs fc-tabs" id="leavesTabs" role="tablist">
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings-tab-pane"
              type="button" role="tab" aria-controls="settings-tab-pane" aria-selected="false">Settings
            </button>
          </li> -->
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="assign-leaves-tab" data-bs-toggle="tab"
              data-bs-target="#assign-leaves-tab-pane" type="button" role="tab" aria-controls="assign-leaves-tab-pane"
              aria-selected="false">{{ 'ASSIGN_LEAVES' | customTranslate : 'Assign Leaves' }}
            </button>
          </li>
        </ul>
      </div>

      <div class="tab-content" id="leavesAllocationTabContent">
        <div class="tab-pane fade" id="settings-tab-pane" role="tabpanel" aria-labelledby="settings-tab" tabindex="0">
          <div class="form-card">
            {{ 'SETTINGS' | customTranslate : 'Settings' }}
          </div>
        </div>

        <div class="tab-pane fade show active" id="assign-leaves-tab-pane" role="tabpanel"
          aria-labelledby="assign-leaves-tab" tabindex="0">
          <div class="form-card">
            <form class="new-emp-form flex flex-column" id="newEmpForm" [formGroup]="leaveAllocationForm"
              autocomplete="off">
              <div class="flex mt-3">
                <div class="row ">
                  <div class="flex">
                    <!-- SK14MAR24 on allocating leaves to bulk employees, the multicheck component is imported and used -->
                    <app-multi-checkbox class="ms-2 me-2"
                      [label]="'TEMPLATE' | customTranslate : 'Template'" 
                      [cardSize]="'col-md-12'"
                      [cardInlineSize]="'col-md-6'"
                      [checkboxList]="tempGet" 
                      [activeCheckboxList]="[]" 
                      [buttonList]="[]" 
                      [componentData]="tempGet"
                      [clearSelection]="templateClear"
                      (checkEmit)="selectedTemplate($event)">
                    </app-multi-checkbox>
                    <app-multi-checkbox class="ms-2 me-2"
                      [label]="'HOLIDAYS' | customTranslate : 'Holidays'" 
                      [cardSize]="'col-md-12'"
                      [cardInlineSize]="'col-md-6'"
                      [checkboxList]="holidayGet" 
                      [activeCheckboxList]="[]" 
                      [buttonList]="[]" 
                      [componentData]="holidayGet"
                      [clearSelection]="holidayClear"
                      (checkEmit)="selectedHolidays($event)">
                    </app-multi-checkbox>
                    <app-multi-checkbox class="ms-2 me-2"
                      [label]="'WEEK_OFF' | customTranslate : 'Week Off'" 
                      [cardSize]="'col-md-12'"
                      [cardInlineSize]="'col-md-12'"
                      [checkboxList]="weekOffGet" 
                      [activeCheckboxList]="[]" 
                      [buttonList]="[]" 
                      [radioCheck]="true"
                      [componentData]="weekOffGet"
                      [clearSelection]="weekOffClear"
                      (checkEmit)="weekOffHolidays($event, null)">
                    </app-multi-checkbox>
                  </div>
                  <app-data-table #leaveAllocTable *ngIf="empFilterGet" [data]="empFilterGet" [tableWidth]="'100%'"  [searchBar]="true"
                    [displayedColumns]="['employeeId','name','department','designation','gender','officeLocation']" 
                    [headerLabels]="['Employee Id','Employee Name','Department','Designation','Gender','Office Location']"
                    [translatePath]="'EMPFILTER_TABLE'"
                    [withCheckBox]="true"
                    [filterColumns]="['employeeId','name','department','designation','gender']"
                    [multipleFilter]="multipleFilterArray"  (filteredArray)="searchFilterData($event)"
                    fromWhere="leaveAllocation" [editColumn]="['null']"
                    (selectedValues)="tableClickData($event)"
                    (filteredArray)="searchFilterData1($event)" 
                    (saveButtonData)="saveLeaveAllocation()"
                    (selectedValues)="tableCheckedDataa($event)">
                  </app-data-table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-content Compensation" *ngIf="hrmTabToShow == 'compensation' ">
    <!-- <div class="app-tab-name">{{'COMPENSATION' | customTranslate : 'Compensation'  }}</div> -->
    <div class="fc-tab-view">
      <div class="flex flex-direction-row fc-tab-buttons">
        <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
         <li class="nav-item" role="presentation">
            <button #demoTab class="nav-link active" id="Salary-tab" data-bs-toggle="tab" data-bs-target="#Salary-tab-pane"
              type="button" role="tab" aria-controls="Salary-tab-pane" aria-selected="false">{{ 'SALARY' | customTranslate : 'Salary' }}</button>
          </li>
          <!-- SK01APR24 commented since not developed -->
          <!-- <li class="nav-item" role="presentation">
            <button #demoTab class="nav-link" id="CTC-tab" data-bs-toggle="tab" data-bs-target="#CTC-tab-pane"
              type="button" role="tab" aria-controls="CTC-tab-pane" aria-selected="false">{{ 'CTC' | customTranslate : 'CTC Calculator' }}</button>
          </li> -->
        </ul>
      </div>
      <div class="tab-content" id="fcTabContent">
        <div class="tab-pane fade show active" id="Salary-tab-pane" role="tabpanel" aria-labelledby="Salary-tab" tabindex="0">
         <app-compensation></app-compensation>
        </div>
      </div>
    </div>
  </div>
  <div class="main-content payroll" *ngIf="hrmTabToShow == 'payroll' "> 
    <!-- <div class="app-tab-name">{{ 'PAYROLL' | customTranslate : 'Pay Roll' }}</div> -->
    <div class="fc-tab-view">
      <div class="flex flex-direction-row fc-tab-buttons" style="width: 75%">
        <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
          <!-- TO BE USED IN FUTURE ********************** DON'T REMOVE IT ***********************************
            <li class="nav-item" style="margin-left: 0px" role="presentation">
              <button class="nav-link active" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard-tab-pane"
                type="button" role="tab" aria-controls="dashboard-tab-pane" aria-selected="true">Dashboard</button>
            </li>
          -->
          <li class="nav-item" role="presentation">
            <button #demoTab class="nav-link active" id="payroll-tab" data-bs-toggle="tab" data-bs-target="#payroll-tab-pane"
              type="button" role="tab" aria-controls="payroll-tab-pane" aria-selected="false">{{ 'PAYROLL' | customTranslate : 'Pay Roll' }}</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="tax-tab" data-bs-toggle="tab" data-bs-target="#tax-tab-pane" type="button"
              role="tab" aria-controls="tax-tab-pane" aria-selected="false" (shown.bs.tab)="getUserData()">{{ 'HEADINGS.TAX_DECLARATIONS' | customTranslate : 'Tax Declarations' }}</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings-tab-pane"
              type="button" role="tab" aria-controls="settings-tab-pane" aria-selected="false" (shown.bs.tab)="getUserData()">{{ 'SETTINGS' | customTranslate : 'Settings' }}</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="payslips-tab" data-bs-toggle="tab" data-bs-target="#payslips-tab-pane"
              type="button" role="tab" aria-controls="payslips-tab-pane" aria-selected="false">{{ 'PAYSLIP' | customTranslate : 'Pay Slip' }}</button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="fcTabContent1">
        <!-- TO BE USED IN FUTURE ********************** DON'T REMOVE IT ***********************************
          <div class="tab-pane fade show active" id="dashboard-tab-pane" role="tabpanel" aria-labelledby="dashboard-tab"
            tabindex="0">
            <app-payroll (runPayrollScreen)="payrollActiveTab()"></app-payroll>
          </div>
        -->
        <div class="tab-pane fade show active" id="payroll-tab-pane" role="tabpanel" aria-labelledby="payroll-tab">
          <app-payroll-table (alertMessageEmit)="saveSuccess($event)" (errorMessageEmit)="showError($event)"></app-payroll-table>
        </div>
        <div class="tab-pane fade" id="tax-tab-pane" role="tabpanel" aria-labelledby="tax-tab">
          <div class="fc-tab-view card inner-tab">
            <div class="m-2">
              <div class="d-flex justify-content-between align-items-center">
                <div class="flex flex-direction-row fc-tab-buttons">
                  <ul class="nav nav-tabs fc-tabs" id="sectionsTabs" role="tablist">
                    <li class="nav-item d-flex" role="presentation">
                      <button class="nav-link active" [class.active]="sectionFilterIndex === i" id="tax-component" data-bs-toggle="tab" data-bs-target="#tax-component-tab-pane"
                        type="button" role="tab" aria-controls="tax-component-tab-pane" aria-selected="false" (shown.bs.tab)="sectionFilterIndex = i;filterTableData(eachTypeOfComponent, 'tax')" *ngFor="let eachTypeOfComponent of sectionTypes; let i=index">{{eachTypeOfComponent}}</button>
                    </li>
                  </ul>
                </div>
                <div class="d-flex justify-content-end">
                  <app-fusion-button buttonValue="{{'ADD_COMPONENT' | customTranslate : 'Add Component'  }}" (buttonClickEmit)="addTaxComp()"></app-fusion-button>
                </div>
              </div>
              
              <app-data-table *ngIf="tdsListArray" [data]="tdsListArray" [tableWidth]="'100%'" 
                  [displayedColumns]="['name', 'sectionName', 'maxAmount', 'minAmount', 'maxPercent', 'minPercent', 'active']" [headerLabels]="['Name', 'Section Name', 'Max Amount', 'Min Amount', 'Max Percent', 'Min Percent', 'Active']" [translatePath]="'TDS_TABLE'" [withCheckBox]="false" 
                  [searchBar]="true" fromWhere="payrollSettingComponent" [actionButton]="true" (OnDeleteData)="deletePayrollComponent($event, 'tax')" (onEditData)="editPayrollComponent($event, 'tax')" (onToggleChange)="detectToggleChange($event, 'tax')">
              </app-data-table>
              
            </div>
          </div>
          <app-common-screen *ngIf="taxSidescreen" [headerTitle]=" 'ADD_TAX_DECLARATION' | customTranslate : 'Add Tax Declaration types'" (sideScreenEmittedEvent)="taxSidescreen = false" >
            <div class="side-main-content">
              <app-hrm-tds (closeSideScreen)="saveTDS($event)" [onLoadData]="taxSideScreenData" [taxComponentActivity]="taxComponentActivity" [activeSection]="activeSection" [sectionLimit]="sectionLimit" [totalSections]="sectionListData"></app-hrm-tds>
            </div>
          </app-common-screen>
        </div>
        <div class="tab-pane fade" id="settings-tab-pane" role="tabpanel" aria-labelledby="settings-tab">
          <div class="fc-tab-view card inner-tab payroll-component-setting">
            <div class="flex flex-direction-row fc-tab-buttons">
              <ul class="nav nav-tabs fc-tabs" id="payRollSettingsTabView" role="tablist">
                <!-- <li class="nav-item" role="presentation">
                  <button #demoTab class="nav-link active" id="payout" data-bs-toggle="tab" data-bs-target="#payout-tab-pane"
                    type="button" role="tab" aria-controls="payout-tab-pane" aria-selected="false">Payout Settings</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="tax-transform" data-bs-toggle="tab" data-bs-target="#tax-transform-pane"
                    type="button" role="tab" aria-controls="tax-transform-pane" aria-selected="false">Tax Transformation</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pf-esi" data-bs-toggle="tab" data-bs-target="#pf-esi-tab-pane"
                    type="button" role="tab" aria-controls="pf-esi-tab-pane" aria-selected="false">PF & ESI</button>
                </li> -->
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="payslip-templates" data-bs-toggle="tab" data-bs-target="#payslip-template-tab-pane"
                    type="button" role="tab" aria-controls="payslip-template-tab-pane" aria-selected="false">{{ 'PAYSLIP_TEMPLATE' | customTranslate : 'Payslip templates' }}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="salary-component" data-bs-toggle="tab" data-bs-target="#salary-component-tab-pane"
                    type="button" role="tab" aria-controls="salary-component-tab-pane" aria-selected="false">{{ 'SALARY_COMPONENTS' | customTranslate : 'Salary Components' }}</button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="payrollInnerTabContent">
              <!-- <div class="tab-pane fade show active" id="payout-tab-pane" role="tabpanel" aria-labelledby="payout">
                Under Construction
              </div>
              <div class="tab-pane fade" id="tax-transform-pane" role="tabpanel" aria-labelledby="tax-transform">
                Tax Transform Under Construction
              </div>
              <div class="tab-pane fade" id="pf-esi-tab-pane" role="tabpanel" aria-labelledby="pf-esi">
                PF & ESI Under Construction
              </div> -->
              <div class="tab-pane fade  show active" id="payslip-template-tab-pane" role="tabpanel" aria-labelledby="payslip-template">
                <app-pdf-template></app-pdf-template>
              </div>
              <div class="tab-pane fade" id="salary-component-tab-pane" role="tabpanel" aria-labelledby="salary-component">
                <div class="button-section flex content-space-between">
                  <div class="table-filter-buttons-wrapper flex">
                    <div class="filter-component-btn cursor-pointer" [class.active]="componentFilterIndex === i" (click)="componentFilterIndex = i;filterTableData(eachTypeOfComponent, 'salary')" *ngFor="let eachTypeOfComponent of componentTypes; let i=index"> {{ eachTypeOfComponent ? (eachTypeOfComponent + '' | translate) : '' }}</div>
                  </div>
                  <app-fusion-button [buttonType]="'side-screen-btn me-4'" [buttonValue]="('ADD_COMPONENT' | customTranslate : 'Add Component')" (buttonClickEmit)="showSideScreen = true; componentActivity = 'create';sideScreenData = '';"></app-fusion-button>
                </div>
                <div class="settings-component-table">
                  <app-data-table [data]="settingsComponentListTableData" [tableWidth]="'100%'" 
                    [displayedColumns]="['name','nameInPaySlip', 'active','combinedColumn']" [headerLabels]="['Name','Name In PaySlip', 'Active', 'Calculation Type']" [translatePath]="'SETTINGCOMP_TABLE'" [withCheckBox]="false" 
                    [actionButton]="true" [fromWhere]="'payrollSettingComponent'" [receiptImg]="true" [searchBar]="true" (onEditData)="editPayrollComponent($event, 'salary')" (onToggleChange)="detectToggleChange($event, 'salary')" (OnDeleteData)="deletePayrollComponent($event, 'salary')">
                  </app-data-table>
                </div>
              </div>
            </div>
          </div>
          <app-common-screen *ngIf="showSideScreen" headerTitle="{{'ADD_COMPONENT' | customTranslate : 'Add Component'  }}" (sideScreenEmittedEvent)="showSideScreen = false" >
            <div class="side-main-content">
              <app-side-screen (sideScreenEmittedEvent)="sideScreenEvent($event)" [onLoadData]="sideScreenData"></app-side-screen>
            </div>
          </app-common-screen>
          <!-- <app-side-screen *ngIf="showSideScreen" (sideScreenEmittedEvent)="sideScreenEvent($event)" [headerTitle]="'Add Component'" [onLoadData]="sideScreenData"></app-side-screen> -->
        </div>
        <div class="tab-pane fade" id="payslips-tab-pane" role="tabpanel" aria-labelledby="payslips-tab" tabindex="0">
          <app-pay-slips-tab (alertMessageEmit)="saveSuccess($event)"></app-pay-slips-tab>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content attendance" *ngIf="hrmTabToShow == 'attendance' ">
    <!-- SK16APR24 sending the employee attendance edit table columns form here -->
    <!-- SK22APR24 attendance list, accessor and specific employee attendance table colums is sent as input to parent-->
    <app-attendance-hrm [accessor]="accessor" [employeeListAttendance]="employeeListAttendance" (alertMessageEmit)="saveSuccess($event)" (errorMessageEmit)="showError($event)"></app-attendance-hrm>
  </div>

  <div class="main-content approvals" *ngIf="hrmTabToShow == 'approvals' ">
    <app-approvals></app-approvals>
  </div>

  <!-- SK21AUG24 rendering tabs component for dynamic module -->
  <div class="main-content approvals" *ngIf="!hrmStaticModules.includes(hrmTabToShow)">
    <app-tabs *ngIf="hrmModuleData" [inputModule]="hrmModuleData" [skipRouting]="true"></app-tabs>
  </div>
     <!-- <app-global-app-tabs style="width: 100px"></app-global-app-tabs>  -->
</div>

<!-- New Employee Modal -->
<div class="modal fade container-md" id="addEmployeeModal" tabindex="-1" aria-labelledby="addEmployeeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <b class="modal-title fs-5 mb-0" id="addEmployeeModalLabel">{{ 'ADD_EMPLOYEE' | customTranslate : 'Add Employee' }}</b>
        <button type="button" style="margin:0%;" class="btn-close" data-bs-dismiss="modal" #closeAddEmployeeModal (click)="getEmp(); ngOnInit()"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="new-emp-form flex flex-column" id="newEmpForm" [formGroup]="newEmployeeForm" autocomplete="off">
          <div class="bottom-line"></div>
          <div class="flex mt-3">
            <div class="row g-3 flex">
              <div class="col-md-6">
                <!--RG26AUG24 changed the label UI -->
                <label for="first-name" class="form-label label-txt modal-field-label">{{ 'PERSONAL_INFO.FIRST NAME' | customTranslate : 'First Name' }}*</label>
                <!-- Updated the error message with formbuilder pattern-->
                <input type="text" formControlName="firstName" class="form-control" [style]="this.newEmployeeForm.controls['firstName'].invalid && this.newEmployeeForm.controls['firstName'].touched && !this.newEmployeeForm.controls['firstName'].hasError('pattern') ? 'border-color: red' : '' " id="first-name">
                <p class="error-msg">
                  <span   class="error-msg" *ngIf="this.newEmployeeForm.controls['firstName'].invalid && this.newEmployeeForm.controls['firstName'].touched && !this.newEmployeeForm.controls['firstName'].hasError('pattern')">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                  <span  class="error-msg" *ngIf="this.newEmployeeForm.controls['firstName'].hasError('pattern') && this.newEmployeeForm.controls['firstName'].touched">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name' }}
                  </span>
                </p>
                <!-- <p class="error-msg"><span [style]="officeMailError ? 'visibility: visible' : 'visibility: hidden' ">Enter Valid Mail</span></p> -->
              </div>
              <div class="col-md-6">
                <label for="last-name" class="form-label label-txt modal-field-label">{{ 'PERSONAL_INFO.LAST NAME' | customTranslate : 'Last Name' }}</label>
                <input type="text" formControlName="lastName" class="form-control" id="last-name">
                <p class="error-msg">
                  <!--
                  MR 17DEC23 Removed EMAIL Required condition
                  <span *ngIf="this.newEmployeeForm.controls['lastName'].invalid && this.newEmployeeForm.controls['lastName'].touched && !this.newEmployeeForm.controls['lastName'].hasError('pattern')">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                  </span>
                  <span *ngIf="this.newEmployeeForm.controls['lastName'].hasError('pattern') && this.newEmployeeForm.controls['lastName'].touched">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>Please enter a valid name
                  </span>
                  -->
                </p>
                <!-- <p class="error-msg"><span [style]="personalMailError ? 'visibility: visible' : 'visibility: hidden' ">Enter Valid Mail</span></p> -->
              </div>
              <div class="col-md-6">
                <label for="email" class="form-label label-txt modal-field-label">{{ 'EMAIL' | customTranslate : 'Email' }}</label>
                <input type="text" formControlName="email" class="form-control" id="email">
                <p class="error-msg">
                  <!--
                  MR 17DEC23 Removed EMAIL Required condition
                  <span *ngIf="this.newEmployeeForm.controls['email'].invalid && this.newEmployeeForm.controls['email'].touched && !this.newEmployeeForm.controls['email'].hasError('pattern')">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                  </span>
                  -->
                  <!-- SK01APR24 modified the employee adding UI -->
                  <span class="error-msg" *ngIf="this.newEmployeeForm.controls['email'].hasError('pattern') && this.newEmployeeForm.controls['email'].touched">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'LOGIN_PAGE.USER_NAME_ERROR' | customTranslate : 'Please enter a valid email address' }}
                  </span>
                </p>
                <!-- <p class="error-msg"><span [style]="phError ? 'visibility: visible' : 'visibility: hidden' ">Enter Valid Phone Number</span></p> -->
              </div>
              <div class="col-md-6">
                <label for="emp-id" class="form-label label-txt modal-field-label">{{ 'WORK_BASICINFO.EMPLOYEEID' | customTranslate : 'Employee ID' }}*</label>
                <input type="text" formControlName="employeeID" class="form-control" class="form-control" [style]="this.newEmployeeForm.controls['employeeID'].invalid && this.newEmployeeForm.controls['employeeID'].touched && !this.newEmployeeForm.controls['employeeID'].hasError('pattern') ? 'border-color: red' : '' " id="emp-id">
                <p class="error-msg">
                  <span class="error-msg" *ngIf="this.newEmployeeForm.controls['employeeID'].invalid && this.newEmployeeForm.controls['employeeID'].touched">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                </p>
              </div>
              <div class="col-md-6">
                <label for="phone" class="form-label label-txt modal-field-label">{{ 'USER_DETAILS.PHONE_NUMBER' | customTranslate : 'Phone Number' }}</label>
                <input type="text" formControlName="phone" class="form-control" class="form-control" id="phone">
              </div>
              <div class="col-md-6">
                <label class="form-label label-txt modal-field-label" for="date">{{ 'WORK_BASICINFO.DATEOFJOINING' | customTranslate : 'Date Of Joining' }}*</label>
                <input type="date" max="9999-12-31" formControlName="dateOfJoining" class="form-control" class="form-control" [style]="this.newEmployeeForm.controls['dateOfJoining'].invalid && this.newEmployeeForm.controls['dateOfJoining'].touched && !this.newEmployeeForm.controls['dateOfJoining'].hasError('pattern') ? 'border-color: red' : '' " id="date" name="date"
                  format="mm-dd-yyyy" />
                  <p class="error-msg">
                    <span class="error-msg" *ngIf="this.newEmployeeForm.controls['dateOfJoining'].invalid && this.newEmployeeForm.controls['dateOfJoining'].touched">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                    </span>
                  </p>
              </div>
              <div class="col-md-6">
                <label class="form-label label-txt modal-field-label" for="office-location">{{'WORK_BASICINFO.WORKLOCATION' | customTranslate : 'Work Location'  }}</label>
                <input type="text" formControlName="officeLocation" class="form-control" id="office-location" name="location"/>
                <!-- <p class="error-msg">
                  <span *ngIf="this.newEmployeeForm.controls['officeLocation'].invalid && this.newEmployeeForm.controls['officeLocation'].touched">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                  </span>
                </p> -->
              </div>
              <div class="col-md-6">
                <label class="form-label label-txt modal-field-label" for="dept">{{ 'WORK_INFO.DEPARTMENT' | customTranslate : 'Department' }}</label>
                <input type="text" formControlName="department" class="form-control" id="dept" name="department"/>
                <!-- <p class="error-msg">
                  <span *ngIf="this.newEmployeeForm.controls['department'].invalid && this.newEmployeeForm.controls['department'].touched">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                  </span>
                </p> -->
              </div>
              <!-- <div class="col-md-6">
                <label class="form-label" for="designation">{{'WORK_INFO.DESIGNATION' | customTranslate : 'Designation'  }}</label>
                <input type="text" formControlName="designation" class="form-control" id="designation" name="desig"/>
              </div> -->
              <!-- SK01APR24 employee adding Ui modification -->
              <div class="col-md-6">
                <label for="gender" class="form-label label-txt modal-field-label">{{ 'PERSONAL_INFO.GENDER' | customTranslate : 'Gender' }}*</label>
                <select id="gender" formControlName="gender" [style]="this.newEmployeeForm.controls['gender'].invalid && this.newEmployeeForm.controls['gender'].touched ? 'border-color: red' : '' " class="form-select">
                  <option selected value=""></option>
                  <option value="male">{{ 'PERSONAL_INFO.MALE' | customTranslate : 'Male' }}</option>
                  <option value="female">{{ 'PERSONAL_INFO.FEMALE' | customTranslate : 'Female' }}</option>
                  <option value="others">{{ 'PERSONAL_INFO.OTHERS' | customTranslate : 'Others' }}</option>
                </select>
                <p class="error-msg">
                  <span class="error-msg" *ngIf="this.newEmployeeForm.controls['gender'].invalid && this.newEmployeeForm.controls['gender'].touched">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                </p>
              </div>
              <div class="col-md-12 align-self-center">
                <label for="isAppUser" class="form-label label-txt modal-field-label">{{ 'APP_USER' | customTranslate : 'App user' }}</label>
                <input class="ms-2" type="checkbox" (change)="checkAppUser($event, 'checkbox')" formControlName="isAppUser" id="isAppUser">
              </div>
              <div class="col-md-6"  *ngIf="newEmployeeForm.controls['isAppUser'].value">
                <label for="fusionId" class="form-label label-txt modal-field-label">{{ 'LOGIN_PAGE.USER_NAME' | customTranslate : 'Username/Email' }}*</label>
                <input type="text" formControlName="fusionId" class="form-control" class="form-control" (keyup)="checkAppUser($event, 'username')" [style]="appUserCondition && this.newEmployeeForm.controls['fusionId'].value === '' ? 'border-color: red' : '' " id="fusionId">
                <p class="error-msg">
                  <span class="error-msg" *ngIf="appUserCondition && this.newEmployeeForm.controls['fusionId'].value === '' ">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                </p>
              </div>
              <div class="col-md-6" *ngIf="newEmployeeForm.controls['isAppUser'].value">
                <label for="pwd" class="form-label label-txt modal-field-label">{{ 'LOGIN_PAGE.PASSWORD' | customTranslate : 'Password' }}*</label>
                <!-- Updated the error message with formbuilder pattern-->
                <input type="text" formControlName="password" class="form-control" (keyup)="checkAppUser($event, 'password')" [style]="appUserCondition && this.newEmployeeForm.controls['password'].value === '' ? 'border-color: red' : '' " id="pwd">
                <p class="error-msg">
                  <span class="error-msg" *ngIf="appUserCondition && this.newEmployeeForm.controls['password'].value === '' ">
                      <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                </p>
                <!-- <p class="error-msg"><span [style]="officeMailError ? 'visibility: visible' : 'visibility: hidden' ">Enter Valid Mail</span></p> -->
              </div>
              <!-- 
              MR 17DEC23 Commented Reporting Managers on Add Employee
              <div class="col-md-6">
                <label for="rp1" class="form-label">{{'WORK_INFO.PRIMARY REPORTING MANAGER' | customTranslate : 'Primary Reporting Manager'  }}</label>
                <select id="rp1" formControlName="reportingManager1" class="form-select">
                  <option *ngFor="let data of empGet; let i=index" [value]="data.employee.employeeId + ' - ' + data.employee.firstName + ' ' + data.employee.lastName">
                    {{data.employee.firstName + ' ' + data.employee.lastName + " " +  '[' + data.employee.employeeId + ']'}}
                  </option>
                </select>
                <p class="error-msg">
                  <span *ngIf="this.newEmployeeForm.controls['reportingManager1'].invalid && this.newEmployeeForm.controls['reportingManager1'].touched">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                  </span>
                </p>
              </div>
              <div class="col-md-6">
                <label for="rp2" class="form-label">{{'WORK_INFO.SECONDARY REPORTING MANAGER' | customTranslate : 'Secondary Reporting Manager'  }}</label>
                <select id="rp2" formControlName="reportingManager2" class="form-select">
                  <option *ngFor="let data of empGet; let i=index" [value]="data.employee.employeeId + ' - ' + data.employee.firstName + ' ' + data.employee.lastName">
                    {{data.employee.firstName + ' ' + data.employee.lastName + " " + '[' + data.employee.employeeId + ']' }}
                  </option>
                </select>
              </div>
              -->
              <!-- <label  class="form-label">Module Permissions*</label>
              <div class="col-md-4 flex flex-row" *ngFor="let days of rolesList; let i = index">
                <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? separateRowSelect(selection.toggle(days)) : null"
                  [checked]="selection.isSelected(days)">
                  {{ 'days' | translate }}
                </mat-checkbox>
               </div> -->
              <div class="mb-4 d-flex justify-content-end">
                <app-fusion-button buttonType="btn text-only ms-2" (buttonClickEmit)="resetEmp()"
                  data-bs-dismiss="modal" buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"></app-fusion-button>
                <button class="btn btn-primary ms-2 rounded-0" type="button" (click)="saveEmployee()" [disabled]="!newEmployeeForm.valid  || loadingSpinner || appUserCondition">
                  <span class="{{ loadingSpinner ? 'spinner-grow' : '' }} spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                  <span>{{ loadingSpinner ? ('Loading' | customTranslate : 'Loading') + '...' : ('BUTTON_VALUE.SAVE' | customTranslate : 'Save') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Employee bulk upload -->
<div class="modal fade container-md" id="uploadEmployeeModal" tabindex="-1" aria-labelledby="employeeUploadModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5 mb-0" id="personalModalLabel">{{ 'UPLOAD_EMPLOYEES' | customTranslate : 'Upload Employees' }}</h1>
        <button type="button" style="margin:0%;" class="btn-close" data-bs-dismiss="modal" (click)="filesArrayClear()" aria-label="Close"></button>
      </div>
      <div class="modal-body padding-space">
        <div class="d-flex row align-items-center modal-with-list">
          <b class="bold-text">{{ 'STEPS_TO_UPLOAD_EMPLOYEES' | customTranslate : 'Steps to upload employees' }}</b>
          <ol>
            <li>{{ 'DOWNLOAD_THE_EMPLOYEE_TEMPLATE' | customTranslate : 'Download the Employee Template' }} <span class="link-to-download"
                (click)="downloadTemplate('user')">{{ 'HERE' | customTranslate : 'here' }}</span>.</li>
            <li>{{ 'FILL_EMPLOYEE_TEMPLATE_AND_UPLOAD' | customTranslate : 'Fill the appropriate Employee Details and upload it below' }}</li>
          </ol>
          <app-file-upload [fileUploadId]="'uploadEmployee'" [docVals]="['5 mb','csv']" [multiSelectFile]="false" [showPreview]="'fileName'"
            [acceptType]="'.csv,text/csv,.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            (selectedFilesEmitter)="userFileEmitter($event)" [uploadReadyFiles]="filesArrayComp" (documentDelete)="deletedFileInComp($event)">
            <!-- 'showPreview' can have either of the values 'iFrame' (or)  'fileName' -->
            <!-- 'acceptType'  helps to partially restrict user to select only given file type, check the list for acceptable values -->
          </app-file-upload>
        </div>
      </div>
      <div class="modal-footer">
        <app-fusion-button type="button" (buttonClickEmit)="uploadRecordsFile()" [buttonValue]="'Upload' | customTranslate : 'Upload'" [buttonDisable]="!employeeBulkFile"  [buttonType]="'btn btn-primary'">
          
        </app-fusion-button>
      </div>
    </div>
  </div>
</div>
