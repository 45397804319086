import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { Constants } from 'src/app/models/Constants';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-hrm-tds',
  standalone: true,
  imports: [AngularSvgIconModule, DropDownComponent,CommonModule,ReactiveFormsModule, MatCheckboxModule,FusionButtonComponent,TranslateModule, CustomTranslatePipe],
  templateUrl: './hrm-tds.component.html',
  styleUrls: ['./hrm-tds.component.css']
})

export class HrmTdsComponent implements OnInit {

  @Input() onLoadData: any = "";
  @Input() taxComponentActivity: any = "";
  @Input() activeSection: any = "";
  @Input() sectionLimit: any = "";
  @Input() totalSections: any = "";
  @Output() closeSideScreen  = new EventEmitter<{}>;

  selectedSectionType: string = 'Select';
  selectedName: string = 'Select';
  sectionTypeList: Array<string | number> = [];
  sectionNameList : Array<any> = [];
  nameDropDownList:any
  sectionAmountLimits:any;
  duplicateName = '';
  otherSection = 'Add other';
  otherInvestments = Constants.OTHER_INVESTMENTS;
  otherIncome = Constants.INCOME_FROM_OTHERSOUCES;
  addTaxDeclarations!: FormGroup;
 

   constructor(private formBuilder: FormBuilder,private translate : TranslateService) {
    this.sectionTypeList = [Constants.SD, "80C","80D",Constants.HRA, this.otherInvestments] // dropdown list for the section types
    this.addTaxDeclarations = this.formBuilder.group({ // this will be the default data when user opens the side component to add a new salary-setting-component
      'name': ["", Validators.required],
      'sectionName': ["", Validators.required],
      'maxSectionLimit': ["", Validators.required],
      'maxAmount': ["", Validators.required],
      'minAmount': ["", Validators.required],
      'maxPercent': ["", Validators.required],
      'minPercent': ["", Validators.required],
      'active': true,
      'isExempted' : false,
      'condition' : ''
    });

    // the section types, and it's investments list
    this.nameDropDownList = {
      [Constants.SD] : [
        {name:Constants.SD, limitAmount:50000}, 
      ],

      '80C' : [
        {name:Constants.EPF, limitAmount:150000}, 
        {name:Constants.VPF, limitAmount:150000}, 
        {name:'Public Provident Fund (PPF)', limitAmount:150000}, 
        {name:'Life Insurance Premiums', limitAmount:150000}, 
        {name:'Tax Saving Mutual Funds', limitAmount:150000}, 
        {name:'Savings in Pension Plans', limitAmount:150000}, 
        {name:'Tax Saving Fixed Deposit with Banks', limitAmount:150000}, 
        {name:'Unit Linked Insurance Plan', limitAmount:150000}, 
        {name:'Senior Citizens Savings Scheme', limitAmount:150000}, 
        {name:'Sukanya Samriddhi Yojana', limitAmount:150000}, 
        {name:'Children Education Fee', limitAmount:150000}, 
        {name:'Housing Loan Principal Repayment/Stamp Duty Paid', limitAmount:150000}, 
        {name:'National Pension System (NPS)- Additional Contribution', limitAmount:150000}, 
        {name:this.otherSection, limitAmount:''}
      ],
  
      '80D' : [
        {name:'Medical Insurance Premium - Self & Family', limitAmount:25000}, 
        {name:'Medical Insurance Premium - Sr.Citizen Parents', limitAmount:50000}, 
        {name:this.otherSection, limitAmount:''}
      ],

      [Constants.HRA] : [
        {name:Constants.HRA, limitAmount:''}, 
      ],

      [this.otherInvestments] : [
        {name:'Medical treatment for handicapped dependents (Section 80DD)', limitAmount : 125000, condition:'Disability lesser than 80% : 75000 / Disability greater than 80% : 125000',},
        {name:'Medical treatment for specified diseases (Section 80DDB)', limitAmount : 100000, condition:'Senior Citizens: 100000 / Others : 40000 '}, 
        {name:'Deduction for permanent disability (Section 80U)', limitAmount : 125000, condition:'Disability above 40% and below 80% : 75000 / Disability above 80% : 125000'}, 
        {name:'Interest on Higher Education Loan (Section 80E)', limitAmount : '', condition:'Only interest amount is allowed'},
        {name:'Interest on Electric Vehicle Loan (Section 80EEB)', limitAmount : '', condition:'Only interest amount is allowed'}, 
        {name:'Donation made to National Relief Funds (Section 80G, 80GGA && 80GGC)', limitAmount : '', condition:'Donation made to national relief fund : 100% of amount / Others : 50% of amount'}, 
        {name:this.otherSection, limitAmount:''}
      ],

      [this.otherIncome] : [
        {name:'Interest income from bank deposits (80TTA/80TTB)', limitAmount : ''},
        {name:'Any Other Taxable Income (Other than interest income)', limitAmount : ''}, 
        {name:'TDS deducted, other than employer (eg.Bank)', limitAmount : ''}, 
        {name:this.otherSection, limitAmount:''}
      ]
    }
   this.sectionValueList();
  }

  resetForm(){
    this.addTaxDeclarations.patchValue({
      'name': "",
      'sectionName': "",
      'maxSectionLimit': "",
      'maxAmount': "",
      'minAmount': "",
      'maxPercent': '',
      'minPercent': '',
      'isExempted' : false,
      'condition' : ''
    });
    this.selectedName = 'Select';
  }

  ngOnInit(): void {
    if(Object.keys(this.onLoadData).length > 0){
      this.selectedSectionType = this.onLoadData?.sectionName; // for the dropdown value 
      this.selectedName = this.onLoadData?.name;
      
      this.addTaxDeclarations.patchValue({
        'name': this.onLoadData?.name,
        'sectionName': this.onLoadData?.sectionName,
        'maxSectionLimit': this.sectionLimit?.maxSectionLimit || '-',
        'maxAmount': this.onLoadData?.maxAmount,
        'minAmount': this.onLoadData?.minAmount,
        'maxPercent': this.onLoadData?.maxPercent,
        'minPercent': this.onLoadData?.minPercent,
        'active': this.onLoadData?.active,
        'isExempted' : this.onLoadData?.isExempted,
        'condition' : this.onLoadData?.condition
      });
    }
    this.nameList();
  }
  sectionValueList()
  {
    // Constants.SD, "80C","80D",Constants.HRA, this.otherInvestments] // dropdown list for the section types
   
    const list=["80C","80D","HRA","OTHER_INVESTMENTS"]
    this.returnValue(list, 'sectionTypeList', 'array')
  }
  returnValue(array:any, variable:any, type:any){
    type === 'array' ? (this as any)[variable] = [] : (this as any)[variable] = '';
    array.forEach((element:any) => {
      this.translate.get(element).subscribe((keyValue) => {
        if(type === 'array'){
          (this as any)[variable].push(keyValue);
        } else if (type === 'string'){
          (this as any)[variable] = keyValue;
        }
      });
    });
  }
  dropdownEmitFunction(event: any, type:any){
    // the values are assigned to forms based on dropdown type selected
    if(type === 'sectionType'){
      this.addTaxDeclarations.reset();
      this.selectedName = 'Select';
      this.selectedSectionType = event?.data;
      event?.data === this.otherSection ? this.addTaxDeclarations.controls['sectionName'].patchValue('') : this.addTaxDeclarations.controls['sectionName'].patchValue(event?.data);
      this.addTaxDeclarations.controls['maxSectionLimit'].patchValue(this.totalSections[0]?.[event?.data]?.maxSectionLimit || '');
      this.nameList();
      if(event?.data === this.otherInvestments || event?.data === this.otherIncome)  this.addTaxDeclarations.controls['maxSectionLimit'].patchValue('-');
    } 
    else if(type === 'name'){
      this.selectedName = event?.data;
      this.addTaxDeclarations.controls['name'].patchValue(event?.data);
      this.duplicateName = '';
      event?.data === this.otherSection ? this.addTaxDeclarations.controls['name'].patchValue('') : this.addTaxDeclarations.controls['name'].patchValue(event?.data);
      const limit = this.nameDropDownList[this.selectedSectionType].filter((data:any) => data.name === this.selectedName );
      if(limit[0]?.condition) this.addTaxDeclarations.controls['condition'].patchValue(limit[0]?.condition);
      if(limit[0]?.limitAmount) {
        this.addTaxDeclarations.controls['maxAmount'].patchValue(limit[0]?.limitAmount);
      } else {
        this.addTaxDeclarations.controls['maxPercent'].patchValue(limit[0]?.limitPercent);
      }
    }
  }

  // based on section type selected, the name list will be displayed in dropdown
  nameList(){
    this.sectionNameList = [];
    if(this.selectedSectionType !== this.otherSection && this.selectedSectionType !== 'Select'){
      this.nameDropDownList[this.selectedSectionType].forEach((element:any) => {
        this.sectionNameList.push(element.name);
      });
    }
  }

  // this function will emit the entered form datas to hrm component and it will be sent to database
  saveTaxDeclaration($event: any){
    this.closeSideScreen.emit({'formData': this.addTaxDeclarations.value, 'activity' : this.taxComponentActivity})
  }
}
