<!-- Hidden Section Starts -->
<ng-template #plainView>
    <ng-content select="[plain]"></ng-content>
</ng-template>
<ng-template #modalView>
    <ng-content select="[modal]"></ng-content>
</ng-template>
<ng-template #modalDelete>
    <ng-content [select]="[modalId]"></ng-content>
</ng-template>
<!-- Hidden Section Ends -->

<div class="card fc-card fc-card-light" > <!-- Width needs to be defined since by default its 100% -->
    <div class="short-view" *ngIf="!isDetailedView">
        <div class="card-header">
            <ng-content select=".header-section"></ng-content>
        </div>
        <div class="card-body">
            <ng-content select=".body-section"></ng-content>
        </div>
        <div class="card-footer">
            <ng-content select=".footer-section"></ng-content>
        </div>
    </div>
    <div *ngIf="isDetailedView" class="card-detailed-view" >
        <!-- Card layout on click of view/edit/new hide the listing view and shows editable div / form in same area -->
        <div *ngIf="inlineDetailed" class="detailed-card-inline">
                <ng-container *ngTemplateOutlet="plainView"></ng-container>
        </div>
    </div>
</div>

<!-- Card layout on click of view/edit/new hide the listing view and shows editable div / form in same area -->
<div *ngIf="modalDetailed" class="card-detailed-modal">
    <div class="modal fade" id="cardDetailedModal" tabindex="-1" role="dialog" aria-labelledby="cardDetailedModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg">
            <ng-container *ngTemplateOutlet="modalView"></ng-container>
        </div>
    </div>
</div>
<div *ngIf="modalDeleted" class="card-detailed-modal">
    <div class="modal fade" [id]="modalId" tabindex="-1" role="dialog" aria-labelledby="cardModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog">
            <ng-container *ngTemplateOutlet="modalDelete"></ng-container>
        </div>
    </div>
</div>
