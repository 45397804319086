<div class="table-flex">
  <div class="flex flex-row">
    <div class="fc-tab-view">
      <div class="flex flex-direction-row mt-25">
        <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane"
              type="button" role="tab" aria-controls="first-tab-pane" (click)="resetForm(); inProgressTab = true"
              aria-selected="true">
              {{'IN_PROGRESS' | customTranslate : 'Inprogress' }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane"
              type="button" role="tab" aria-controls="second-tab-pane" (click)="resetForm(); inProgressTab = false"
              aria-selected="false">
              {{'HISTORY' | customTranslate : 'History' }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
   <!-- InProgress table -->
  <div class="tab-content" id="fcTabContent">
    <div class="tab-pane fade show active" id="first-tab-pane" role="tabpanel" aria-labelledby="Inprogress"
      tabindex="0">
      <app-data-table [data]="approvalNotification"  [tableWidth]="'100%'"  [displayedColumns]="['employeeId','name','date','category','receipt','description',
                'status','amount', 'reImbursed']" [headerLabels]="['Employee Id','Employee Name','Applied Date','Category','Receipt','Comments', 'Status','Amount', 'Reimbursement Status']" [translatePath]="'APPROVALNOTIFICATION_TABLE'" [withCheckBox]="false" [actionButton]="true"
        [fromWhere]="'approval'" [receiptImg]="true" [searchBar]="true" (approveData)="onApprove($event, 'inProgress')"
        (rejectData)="onreject($event)" (expenseAttachmentEmit)="emitAttachmentClick($event)">
      </app-data-table>
    </div>
    <!-- History table -->
    <div class="tab-pane fade" id="second-tab-pane" role="tabpanel" aria-labelledby="History" tabindex="1">
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'" 
        [buttonDisable]="downloadCurrentDisable" 
        (buttonClickEmit)="downloadCurrentEmployees()"
        [buttonValue]="'EXPORT' | customTranslate : 'Export'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
        </app-fusion-button>
      </div>
      <app-data-table [data]="historyData"  [tableWidth]="'100%'" [displayedColumns]="['employeeId','name','date','category','receipt','amount',
                'status','rejectionReason', 'reImbursed']" [headerLabels]="['Employee Id','Employee Name','Applied On','Category','Receipt','Amount',
                'Status','Reject Reason', 'Reimbursement Status' ]" [translatePath]="'HISTORYDATA_TABLE'" [fromWhere]="'expenseApproval'"
          [actionButton]="true" [withCheckBox]="true"
          (selectedValues)="tableCheckedDataa($event)"
           [receiptImg]="true" [searchBar]="true" (onEditData)="leaveRejectReason($event)"
          (expenseAttachmentEmit)="emitAttachmentClick($event)" (approveData)="openIsReimburseModal($event, 'history')" [actionColumHeader]="'Is Reimbursed'">
        </app-data-table>
    </div>
  </div>
</div>
<!-- To open rejection-reason comment modal -->
<div class="modal fade" id="approvalReject">
  <div class="modal-dialog d-flex align-items-center h-100">
    <div class="modal-content modal-custom">
      <div class="modal-header modal-custom-header modal-custom">
        <!-- RS27AUG24 -->
        <!-- Used the translate pipe to successfully implement the translation -->
        <div class="modal-title fs-6">{{ 'REJ_REASON' | customTranslate : 'Rejection Reason'  }}</div>
        <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
        [fcBtnId]="'close'">
        </app-fusion-button>
        </div>
      <div class="modal-body modal-custom-body d-flex flex-column">
        <form [formGroup]="rejectionform">
          <textarea class="text-box" formControlName="rejectionReason"></textarea>
        </form>
        <div class="mt-5 d-flex justify-content-end">
          <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  }}" [buttonType]="'text-only'" [dataDismiss]="'modal'"
           [fcBtnId]="'myId'"></app-fusion-button>
          <app-fusion-button [buttonValue]="'Reject' | customTranslate : 'Reject'" [dataDismiss]="'modal'"
            (buttonClickEmit)="reasonText()" [fcBtnId]="'modalSave'">
          </app-fusion-button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- To view the uploaded receipt detailed-view -->
<div class="modal fade container-sm" id="expenseApprovalReceipt" aria-labelledby="expenseApprovalReceiptLabel"
  aria-hidden="true" #receiptModal>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="safeSrc = '' " aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <iframe class="receipt-view" *ngIf="safeSrc" [src]="safeSrc"></iframe>
      </div>
    </div>
  </div>
</div>

<app-modal [modalTitle]="'Is Reimbursed ?'" [saveButtonValue]="'Yes'" [isModalOpen]="openModal" (saveButtonEmit)="sendApprovalSuccess('history')" (cancelButtonEmit)="openModal = $event" [modalSize]="'modal-md'">
  <div class="modal-body">
    {{ 'CONFIRM_EXPENSE' | customTranslate : 'Are you sure, you want to make this expense as Reimbursed ?'  }}
  </div>
</app-modal>
