import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FusionFrameworkService {
  private appData = new Subject<any>();
  appJSONData$ = this.appData.asObservable();

  private moduleData = new Subject<any>();
  moduleJSONData$ = this.moduleData.asObservable();

  private tabData = new Subject<any>();
  tabJSONData$ = this.tabData.asObservable();

  constructor(private apiService: ApiService) {
  }

  getJSON(data: any) {
    this.appData.next(data);
  }

  getModuleData(moduleData:any){
    this.moduleData.next(moduleData);
  }

  getTabData(tabData:any){
    this.tabData.next(tabData);
  }
}
