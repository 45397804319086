<div class="side-screen">
    <div class="side-header">
        <p class="flex content-space-between mb-0">{{headerTitle}}
            <span class="close-icon"><svg-icon src="../assets/images/icons/general/cross-icon.svg" (click)="sideScreenEmittedEvent.emit({'open': false})" style="cursor: pointer;" class="svg-icon-white"></svg-icon></span>
        </p>
    </div>
    <div class="side-content">
        <div class="scrollable-content">
            <ng-content select=".side-main-content"></ng-content>
        </div>
    </div>
</div>