<label class="drag-drop-files" [attr.for]="fileUploadId" appDragDropFiles (fileDropped)="onFileDropped($event)">
    <!-- This is our default design -->
    <div class="text-center default-text" *ngIf="!customHtml">
        <div class="drag-drop-text">
            <p class="drag-drop-header">{{'FILE_UPLOAD.DRAG_DROP' | customTranslate : 'Drag / drop file(s) to upload' }}</p>
        </div>
        
        <p class="or-break">(or)</p>
        <p><span class="btn btn-primary fc-button primary">{{'FILE_UPLOAD.BROWSE' | customTranslate : 'Browse Files' }}</span></p>

        <p class="upload-information flex justify-center mb-0">
            <!-- From line 13-16 dynamic file type screen logic-->
            <span>[ {{docVals[0]}} (max)</span>
            <span class="vertical-separator"></span>
            <span> {{docVals[1]}} ]</span>
            <!-- <span *ngIf="docVals[2]" class="vertical-separator"></span>
            <span> {{docVals[2]}}</span>
            <span>&nbsp;]</span> -->
        </p>
    </div>
    <!--Just in case user wishes to have custom text add respective code here -->
    <div *ngIf="customHtml"> 
        <!-- make use of ng-content, ng-container to display custom html here-->
    </div>

    <!-- Based on the need enable multiple select or single select option -->
    <!-- ~~~~~~~~~~~~~~~~~~~~~~ -->
    <!-- If multiSelectFile == 'true' , enable multi select of files -->
    <input #fileInput *ngIf="multiSelectFile" type='file' [id]="fileUploadId" (change)="onFileSelected($event)" multiple/>

    <!-- If multiSelectFile == 'flase' , enable only single selection of file -->
    <input #fileInput *ngIf="!multiSelectFile" type='file' [id]="fileUploadId" (change)="onFileSelected($event)"/>
    
</label>
<p class="text-center error-msg" *ngIf="errorMessage"><br><span>{{ errorMessage }}</span></p>

<div class="upload-preview-wrapper" [ngSwitch]="showPreview">
    <div *ngFor="let data of uploadReadyFiles; let i=index" class="preview-section">
        <div class="i-frame-wrapper" *ngSwitchCase="'iFrame'">
            <div class="badge align-item-center">
                <img src="../assets/images/icons/general/file-attach.svg">
                <p class="file-name mb-0">{{data?.file?.name | truncateName }}</p>
                <button type="button" class="btn-close" (click)="deleteFile(i)"></button>
            </div>
            <iframe class="i-frame" [src]="data.url" ></iframe>
        </div>
        <div class="name-wrapper" *ngSwitchCase="'fileName'">
            <div class="badge align-item-center">
                <img src="../assets/images/icons/general/file-attach.svg">
                <span class="file-name">{{data?.file?.name | truncateName }}</span>
                <button type="button" class="btn-close" (click)="deleteFile(i)"></button>
            </div>
        </div>
    </div>
</div>

<!-- we are yet to limit the number of files that can be uploaded at a time if multiple option is enabled "-->
<!-- Yet to work on displaying the error messages-->
