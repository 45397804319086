import { Component, Input, OnInit, isDevMode } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule,TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { SuccessFailureMessageComponent } from "src/app/components/alert-message/success-failure-message.component";
import { CardsComponent } from "src/app/components/cards/cards.component";
import { PopoverComponent } from "src/app/components/popover/popover.component";
import { FusionButtonComponent } from "src/app/components/fusion-button/fusion-button.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ApiService } from "../../../services/api.service";
import { ErrorHandlerService } from "../../../services/error-handler.service";
import { DataTableComponent } from "src/app/components/data-table/data-table.component";
import { Router } from "@angular/router";
import { FileUploadComponent } from "src/app/components/file-upload/file-upload.component";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from "src/app/services/global-values.service";
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: "app-document",
  standalone: true,
  imports: [
    CommonModule,
    SuccessFailureMessageComponent,
    CardsComponent,
    PopoverComponent,
    FusionButtonComponent,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    DataTableComponent,
    FileUploadComponent,
    TranslateModule,
    CustomTranslatePipe
    
  ],
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.css"],
})
export class DocumentComponent implements OnInit {

  showSubmissionMsg = false;
  sampleFile: File | any;
  showFile:File | any;
  showErrorMsg = false;
  errorMessage = "";
  docGetData: any;
  deleteID: any;
  closeIcon = false;
  showDeletedMsg = false;
  documentsForm!: FormGroup;
  uploadedName: any;
  isFileuploaded=false;
  isEditOn = false;
  base64File: any;
  url!:string;
  public safeSrc!: SafeResourceUrl;
  docList:any[]=[];
  emittedFileType:string='';
  isDevMode: boolean = false;
  employeeId: any;
  @Input() selectedUser: any;
  filesArrayComp:any;
  documentFileName!: string
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;  
  companyId = this.globalValues.orgId;

  constructor(private fb: FormBuilder, private apiService: ApiService,  private sanitizer: DomSanitizer,  private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService, private globalValues: GlobalValuesService, private http:HttpClient,private language:TranslateService) {
    this.isDevMode = isDevMode();
    this.documentsForm = this.fb.group({
      docType: ["", Validators.required],
      docID: ["", Validators.required],
      file: [{}], //SK19FEB24 removed required condition for file upload
      docIssuedDate: [""], // SK17JAN24
      docExpiryDate: [""],
      issuedBy:[""], //SK19FEB24
      sponsor:[""], //SK19FEB24
      uploadedBy:[""],
      verification:[true],
      docGetID: [],
    });
  }

  docDropdown:any; //SK27FEB24
  ngOnInit(): void {
    this.isFileuploaded = false;
    // SK01APR24
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();

    // SK27FEB24 getting document dropdown from local
    // SK06MAR24 settings data as whole stored in local and getting dropdowns from it
    const dropDowns = JSON.parse((localStorage.getItem('settingsData'))as any);
    this.docDropdown = dropDowns?.dropdowns?.filter((doc:any)=>doc.name === 'documents')[0]
    || {valueList:[
      {key:"aadhar", value:"Aadhar"},
      {key:"voter", value:"Voter ID"},
      {key:"drivingLicense", value:"Driving License"},
      {key:"pan", value:"PAN Card"}
    ]};
  }

  ngOnChanges(): void {
    this.isFileuploaded = false;
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();
  }
  
  /** These are the GET method functions that returns the data from database */
  getESSDatas(){
    this.apiService.fetchValue('/user').subscribe((datas:any) => {
        this.employeeId = datas?.loggedInUser[0]?.employeeId
        this.getData();
    })
  }

  getData(){
    // MR 10JAN24 - Removed API Call
    /*
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    this.apiService.fetchValue('/hrm',params).subscribe((datas: any) => {
      this.docGetData = datas?.loggedInUser[0]?.documents;
      this.docList =[];
      this.docGetData?.forEach((element:any, index:number)=>{
        this.processFile(element.document.file, index); // to process the AWS S3link as base64
        this.docList.push({
            "docId": element.docId,
            "type" : element.type,
            "uploadedBy" : element.uploadedBy,
            "document" : element.document,
            "id" : element.id
        })
      })
    })
    */

    // MR 10JAN24 - Using data from HRM Component
    if(this.selectedUser){
      this.docGetData = this.selectedUser.documents;
      this.uploadedName = this.selectedUser?.firstName +' '+ this.selectedUser?.lastName;
      this.docList =[];
      this.docGetData?.forEach((element:any, index:number)=>{
        this.docList.push({
            "docId": element.docId,
            "type" : element.type,
            "uploadedBy" : element.uploadedBy,
            "document" : element.document,
            "id" : element.id,
            "docIssuedDate":element.issuedDate,
            "docExpiryDate":element.expiryDate,
            "issuedBy":element.issuedBy,
            "sponsor":element.sponsor,
        })
        
        if(element.document.file != null) this.processFile(element.document.file, index); // to process the AWS S3link as base64
      })
    }
  }

  // the AWS S3 link will be converted to a blob here
  processFile(url:any, object:any) : any{
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      request.onload = (e1:any) =>  {
          var reader = new FileReader();
          const blob = request.response;
          // then blob is passed here to read the as text
          this.onChange(blob, object)
      };
      request.send();
  }

  // finally base64 string will be generated here
  onChange(event:any, object:any) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      const fileOutput = e.target.result;
      // this base64 string will be assigned to docList array to display in UI
      this.docList[object].safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileOutput);
      this.docList[object].document.file = fileOutput;
    };
    reader.readAsText(event);
  }

  safeUrl(file:any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(file);
  }

  addDocument() {
    this.isFileuploaded = false;
    this.isEditOn = true;
    this.documentsForm.reset();
    this.docFileReset()
  }

  fileType(type:string){
    this.emittedFileType = type;
  }

  fileUpload(event: any) {
    if(this.emittedFileType == 'blob'){
    var file: File = event;
    var reader: FileReader = new FileReader();
    reader.onload = () => {
      this.sampleFile = reader.result;
      this.isFileuploaded=true;
      this.documentsForm.controls['file'].patchValue({file:this.sampleFile, fileName: this.documentFileName});
    };
    reader.readAsDataURL(file);    
    }else{
      this.sampleFile = event[0];
      this.isFileuploaded=true;
      this.documentsForm.controls['file'].patchValue({file:this.sampleFile, fileName: this.documentFileName});
    }
  }

  // To preview the selected file in modal
  previewFile(doc:any) {
    this.safeSrc = doc;
  }

  saveDocuments() {
    if(this.isDevMode) console.log("Submit documents");
    this.isEditOn = false;
    let value = this.documentsForm.value;
    if (value.docGetID != null) {
      return this.apiService.writeValue('patch', '/employee/documents', {'type': value.docType, 'id': value.docID, 'document': value.file, 'docId': value.docGetID, 'issuedDate': value.docIssuedDate, 'expiryDate': value.docExpiryDate, 'issuedBy': value.issuedBy, 'sponsor': value.sponsor, 'uploadedBy': this.uploadedName, 'employeeId' : this.employeeId}) // SK17JAN24 removed company ID //SK19FEB24 modified for documents new fields
      .subscribe({
        next: (docInfo) => {
          if(this.isDevMode) console.log("Next :", docInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        },
      });
    }
    return this.apiService.writeValue('post', '/employee/documents', {'type': value.docType, 'id': value.docID, 'document': value.file, 'issuedDate': value.docIssuedDate, 'expiryDate': value.docExpiryDate, 'issuedBy': value.issuedBy, 'sponsor': value.sponsor, 'uploadedBy': this.uploadedName, 'employeeId': this.employeeId}) // SK17JAN24 removed company ID //SK19FEB24 modified for documents new fields
    .subscribe({
      next: (docInfo) => {
        if(this.isDevMode) console.log("Next :", docInfo);
        this.saveMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      },
    });
  }

  //to download the uploadedDocuments;
  downloadDoc(docFile:any){
    var linkSource = docFile.document.file;
    var downloadLink  = document.createElement('a');
    var filename = docFile.document.fileName;
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }

  onEditDoc(docData: any) {
    this.deleteID = docData.docId;
    this.documentsForm.patchValue({
      docType : docData.type,
      docID : docData.id,
      file : docData.document,
      docGetID : docData.docId,
      issuedBy:docData.issuedBy,
      sponsor:docData.sponsor,
      uploadedBy:this.uploadedName,
      verification:true,
      docIssuedDate:docData.docIssuedDate,
      docExpiryDate:docData.docExpiryDate,
    });
    this.isEditOn =true;

    // The file in base64 format is changed to file format and sent to uploadReadyFiles array in file-upload component
    const fileType = docData.document.file.split(':')[1].split(';')[0]; // used to split filetype from base64
    const byteCharacters = window.atob(docData.document.file.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], docData.document.fileName, { type: fileType });
    // This is the format of uploadReadyFiles array that to be displayed in child component
    // So, on editing, the file in the database is displayed in this format
    this.filesArrayComp = [{file:file, url:this.sanitizer.bypassSecurityTrustResourceUrl(docData.document.file)}]
  }
 
  deleteDoc() {
    this.apiService.writeValue('delete','/employee/documents',{ body: { 'docId': this.deleteID, 'employeeId': this.employeeId }}, ).subscribe({
      next: () => {
        this.deleteMsg();
        if(this.isDevMode) console.log('deleted successfully');
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      },
    });
  }

  onDelete(docData: any){
    this.deleteID = docData.docId;
  }

  docCancelButton() {
    this.isEditOn = false;
    this.isFileuploaded=false;
    this.documentsForm.reset();
    this.docFileReset()
  }

  saveMsg(){
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }
  
  deleteMsg(){
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }

  errorMsg(error: HttpErrorResponse){
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  // this is to clear the filename array in child component
  docFileReset(){
    this.filesArrayComp =  [];
  }

  // when the file removed form list, we are assigning null to that formcontrolname
  deletedFileInComp(event:any){
    if(event.length === 0){
      this.documentsForm.controls['file'].patchValue('');
    }
  }

  // this is to clear the filename array in child component
  fileNameArray(event:any){
    this.documentFileName = event[0]?.file?.name;
  }
}
