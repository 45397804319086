<!-- //SK23FEB24 list of tabs wiil be displayed here -->
<div class="flex flex-direction-row tab-view-buttons">
    <ul class="nav nav-tabs fc-tabs width-100" id="commonTabs" role="tablist">
        <li *ngFor="let eachTypeOfComponent of dropDownList.tabs; let i=index" class="nav-item d-flex me-2"
            role="presentation">
            <button class="nav-link active" [class.active]="displayedTabContent === eachTypeOfComponent.name"
                id="{{eachTypeOfComponent}}" type="button" role="tab" aria-controls="common-component-tab-pane"
                aria-selected="false" (click)="currentTabNav(eachTypeOfComponent)">
                <a class="tabs"
                    [class]="displayedTabContent === eachTypeOfComponent.name ? 'tab-active-color' : 'tab-color'">
                    <!-- SK24MAY24 browsed tab index change -->
                    {{eachTypeOfComponent.name}}
                </a>
            </button>
            <!-- //SK23FEB24 if the tab is browsed Tab, then ison will be added -->
            <svg-icon class="close-hover" *ngIf="eachTypeOfComponent.browsedTab"
                src="../assets/images/icons/general/cross-icon.svg" (click)="closeBrowseTab(eachTypeOfComponent,i)">
            </svg-icon>
        </li>
    </ul>
</div>
 
<!-- //SK23FEB24, the data table component for showing the dropdown list and valueList of that dropdown -->
<div class="tab-content mt-4" id="commonTabContent">
    <div *ngFor="let tab of dropDownList.tabs; let i=index">
        <div *ngIf="displayedTabContent === tab.name">
            <app-data-table [tableId]="tab.config?.tableId" [searchBar]="tab.config?.searchBar" [data]="tab.config?.data"
                [headerLabels]="tab.config?.headerLabels" [translatePath]="'DYNAMIC_TABLE'" [displayedColumns]="tab.config?.displayedColumns"
                [hyperLinkColumns]="tab.config?.hyperLinkColumns" [fromWhere]="tab.config?.fromWhere"
                [withCheckBox]="tab.config?.withCheckBox" [isEditRow]="tab.config?.isEditRow"
                [isDeleteRow]="tab.config?.isDeleteRow" [actionButton]="tab.config?.actionButton"
                [isButtons]="tab.config?.isButtons" [buttonArray]="tab.config?.buttons"
                [addInlineRecord]="tab.config?.addInlineRecord" [editColumn]="tab.config?.editColumns || []"
                [isEditRow]="tab.config?.isEditRow" [isDeleteRow]="tab.config?.isDeleteRow"
                [actionButton]="tab.config?.actionButton" [inlineElement]="tab.config?.inlineElement"
                [tableSaveButton]="tab.config?.tableSaveButton"
                (commonButtonClickEmit)="commonButtonClickEmit($event)"
                (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
                (onEditData)="onEditRecord($event)" (OnDeleteData)="onDeleteRecord($event)"
                (saveButtonData)="saveTableData($event)">
            </app-data-table>
        </div>
    </div>
</div>

<!-- //SK23FEB24 to create new dropdown/ edit the dropdown data -->
<app-modal [modalTitle]="newDropDownRecord ? ('Add Dropdown' | customTranslate : 'Add Dropdown') : ('Edit Dropdown' | customTranslate : 'Edit Dropdown')"
[modalFooter]="false" [saveButtonValue]="'Save'" [isModalOpen]="openModal"
    (cancelButtonEmit)="modalClose($event)" [modalSize]="'modal-md'">
    <div class="modal-body">
        <form class="flex flex-column" id="dropDownForm" [formGroup]="newDropDownForm" autocomplete="off">
            <div class="bottom-line"></div>
            <div class="flex mt-3">
                <div class="row g-3 flex">
                    <div class="col-md-6">
                        <!--RG26AUG24 added asterisk to important fields -->
                        <label for="ddName" class="form-label  label-txt modal-field-label">{{ 'NAME' | customTranslate : 'Name*'  }}</label>
                        <div class="field-input"
                            [class]=" this.newDropDownForm.controls['name'].invalid && this.newDropDownForm.controls['name'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="name" class="form-control" id="ddName"
                                [readOnly]="!newDropDownRecord?true:false">
                        </div>
                        <p class="error-fields"
                            [style]="this.newDropDownForm.controls['name'].invalid && this.newDropDownForm.controls['name'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="ddLabel" class="form-label  label-txt modal-field-label">{{ 'LABEL' | customTranslate : 'Label*'  }}</label>
                        <div class="field-input"
                            [class]=" this.newDropDownForm.controls['label'].invalid && this.newDropDownForm.controls['label'].touched  ? 'has-error' : '' ">
                            <input type="text" formControlName="label" class="form-control" id="ddLabel">
                        </div>
                        <p class="error-fields"
                            [style]="this.newDropDownForm.controls['label'].invalid && this.newDropDownForm.controls['label'].touched  ? 'visibility: visible' : 'visibility: hidden' ">
                            {{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'Required'  }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="ddLanguage" class="form-label  label-txt modal-field-label">{{ 'LANGUAGE' | customTranslate : 'Label'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="language" class="form-control" id="ddLanguage">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="ddType" class="form-label  label-txt modal-field-label">{{ 'TYPE' | customTranslate : 'Type'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="type" class="form-control" id="ddType">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="ddDefault" class="form-label  label-txt modal-field-label">{{ 'DEFAULT' | customTranslate : 'Default'  }}</label>
                        <div class="field-input">
                            <input type="text" formControlName="default" class="form-control" id="ddDefault">
                        </div>
                    </div>
                    <div class="mb-4 d-flex justify-content-end">
                        <app-fusion-button buttonType="btn text-only ms-2"
                            (buttonClickEmit)="openModal = false;newDropDownForm.reset()" data-bs-dismiss="modal"
                            buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'}}"></app-fusion-button>
                        <button class="btn btn-primary ms-2 rounded-0" type="button"
                            (click)="addDropDown(newDropDownRecord ? 'post' : 'patch')"
                            [disabled]="!newDropDownForm.valid  || loadingSpinner">
                            <span class="{{ loadingSpinner ? 'spinner-grow' : '' }} spinner-grow-sm" role="status"
                                aria-hidden="true"></span>
                            <span>{{ loadingSpinner ? ('Loading' | customTranslate : 'Loading') + '...' : ('BUTTON_VALUE.SAVE' | customTranslate : 'Save') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-modal>