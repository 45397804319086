<!-- <div class="tab-content" id="essTabContent"> -->
    <!-- <div class="tab-pane fade show active" id="basic-info-tab-pane" role="tabpanel" -->
        <!-- aria-labelledby="basic-info-tab" tabindex="0"> -->

        <div class="form-card">
            <!-- Personal Info -->
            <div class="col-md-12 mt-3">
                <div class="static-width-for-card width-100">
                    <app-cards #hideplainview [inlineDetailed]="true">
                        <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                        <div class="header-section flex content-space-between">
                            <span>{{ 'PERSONAL_INFO.PERSONAL INFO' | customTranslate : 'Personal Info' }}</span>
                            <div class="card-btn show-detail-view">
                                <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                                <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                    class="card-icon" (click)="getData()"></svg-icon>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                        <div class="body-section card-text">
                            <div class="d-flex flex-column" *ngFor="let data of personalGetData">
                                <div class="d-flex flex-row justify-content-start">
                                    <div class="d-flex flex-column width-33">
                                        <label class="label-head">{{ 'PERSONAL_INFO.NAME' | customTranslate : 'Name' }}</label>
                                        <label
                                            class="label-sub">{{data?.firstName}} {{data?.lastName}}</label>
                                    </div>
                                    <div class="d-flex flex-column width-33">
                                        <label class="label-head">{{ 'PERSONAL_INFO.BLOOD GROUP' | customTranslate : 'Blood Group' }}</label>
                                        <label
                                            class="label-sub">{{data?.personalinfo?.bloodgroup}}</label>
                                    </div>
                                    <div class="d-flex flex-column width-33">
                                        <label class="label-head">{{ 'PERSONAL_INFO.GENDER' | customTranslate : 'Gender' }}</label>
                                        <!--SK17JAN24 Changed the path of object -->
                                        <!-- <label *ngIf="this.userGetData?.personalinfo?.gender === 'male' " class="label-sub">Male</label>
                                        <label *ngIf="this.userGetData?.personalinfo?.gender === 'female' " class="label-sub">Female</label>
                                        <label *ngIf="this.userGetData?.personalinfo?.gender === 'others' " class="label-sub">Others</label> -->
                                        <label class="label-sub">{{this.userGetData?.gender}}</label>
                                        <!-- RS27AUG24 -->
                                    </div>
                                </div>
                                <div class="d-flex flex-row mt-4 justify-content-start">
                                    <div class="d-flex flex-column width-33">
                                        <label class="label-head">{{ 'PERSONAL_INFO.DATEOFBIRTH' | customTranslate : 'Date of Birth' }}</label>
                                        <label
                                            class="label-sub">{{data?.personalinfo?.dateOfBirth | date: 'dd/MM/yyyy'}}</label>
                                    </div>
                                    <div class="d-flex flex-column width-33">
                                        <label class="label-head">{{ 'PERSONAL_INFO.MARITAL STATUS' | customTranslate : 'Marital Status' }}</label>
                                        <label
                                            class="label-sub">{{data?.personalinfo?.maritalStatus}}</label>
                                    </div>
                                    
                                    <div class="d-flex flex-column width-33">
                                        <label class="label-head">{{ 'PERSONAL_INFO.WEDDING ANNIVERSARY' | customTranslate : 'Wedding Annverisary' }}</label>
                                        <label
                                            class="label-sub">{{data?.personalinfo?.weddingAnniversary | date: 'dd/MM/yyyy'}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                        <div class="card-expanded-section detailed-section" plain>
                            <!-- 'plain' or 'modal' based on the requirement -->
                            <div class="card-header">{{ 'PERSONAL_INFO.EDIT PERSONAL INFO' | customTranslate : 'Edit Personal Info' }}</div>
                            <div class="card-body">
                                <form [formGroup]="personalInfoForm">
                                    <div class="field-sets">
                                        <div class="field-set-row flex">
                                            <div class="field-input col-md-6" [class]="personalInfoForm.controls['firstName'].invalid && personalInfoForm.controls['firstName'].touched ? 'has-error' : '' ">
                                                <!-- <label for="first-name" class="form-label label-txt modal-field-label">First
                                                    Name</label>
                                                <input type="text" formControlName="firstName"
                                                    id="first-name" placeholder="Ex.Adithya"
                                                    (focusout)="handleFirstName($event)"
                                                    (keyup)="handleFirstName($event)"> -->
                                                <!--RG26AUG24 Changed the label UI -->
                                                <label class="form-label label-txt modal-field-label">{{ 'PERSONAL_INFO.FIRST NAME' | customTranslate : 'First Name' }}*</label>
                                                <!-- <div class="field-input col-md-6" > -->
                                                    <input type="text" formControlName="firstName"
                                                    id="first-name" placeholder="Ex.Adithya"
                                                    (focusout)="handleFirstName($event)"
                                                    (keyup)="handleFirstName($event)">
                                                <!-- </div> -->
                                                    <!-- Updated the error message with formbuilder pattern-->
                                                <p class="error-msg">
                                                    <span *ngIf="personalInfoForm.controls['firstName'].invalid && personalInfoForm.controls['firstName'].touched && !this.personalInfoForm.controls['firstName'].hasError('pattern')">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                    </span>
                                                    <span *ngIf="personalInfoForm.controls['firstName'].touched && this.personalInfoForm.controls['firstName'].hasError('pattern')">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name' }}
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="field-input col-md-6" [class]="personalInfoForm.controls['lastName'].invalid && personalInfoForm.controls['lastName'].touched ? 'has-error' : '' ">
                                                <label class="form-label label-txt modal-field-label">{{ 'PERSONAL_INFO.LAST NAME' | customTranslate : 'Last Name' }}*</label>
                                                <input type="text" formControlName="lastName" id="last-name"
                                                    placeholder="Ex.Rajeshkumar"
                                                    (focusout)="handleLastName($event)"
                                                    (keyup)="handleLastName($event)">
                                                <!-- <label for="last-name" class="form-label label-txt modal-field-label">Last
                                                    Name</label> -->
                                                <p class="error-msg">
                                                    <span *ngIf="personalInfoForm.controls['lastName'].invalid && personalInfoForm.controls['lastName'].touched && !this.personalInfoForm.controls['lastName'].hasError('pattern')">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                    </span>
                                                    <span *ngIf="personalInfoForm.controls['lastName'].touched && this.personalInfoForm.controls['lastName'].hasError('pattern')">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name' }}
                                                    </span>
                                                </p>
                                                <!-- <p class="error-msg"><span
                                                        [style]="lastNameError ? 'visibility: visible' : 'visibility: hidden' ">Enter
                                                        Last Name</span></p> -->
                                            </div>
                                            <div class="field-input col-md-6" [class]="personalInfoForm.controls['dateOfBirth'].invalid && personalInfoForm.controls['dateOfBirth'].touched ? 'has-error' : '' ">
                                                <label class="form-label label-txt modal-field-label">{{ 'PERSONAL_INFO.DATEOFBIRTH' | customTranslate : 'Date of Birth' }}*</label>
                                                <input type="date" max="9999-12-31" formControlName="dateOfBirth" id="date"
                                                    name="date" format="mm-dd-yyyy"
                                                    (focusout)="handleDOB($event)"
                                                    (keyup)="handleDOB($event)" />
                                                <!-- <label class="form-label label-txt modal-field-label" for="date">Date of
                                                    Birth</label> -->
                                                    <p class="error-msg"><span
                                                        *ngIf="personalInfoForm.controls['dateOfBirth'].invalid && personalInfoForm.controls['dateOfBirth'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                        </span></p>
                                                <!-- <p class="error-msg"><span
                                                        [style]="dobError ? 'visibility: visible' : 'visibility: hidden' ">Select
                                                        Date of Birth</span></p> -->
                                            </div>
                                            <div class="col-md-6" style="width: 48%" >
                                                <label class="form-label label-txt modal-field-label"
                                                            for="flexRadioDefault">{{ 'PERSONAL_INFO.GENDER' | customTranslate : 'Gender' }}*</label>
                                                <div class="flex flex-direction-row">
                                                    <div class="mb-3 ms-4" *ngFor="let gender of genders">
                                                        <label style="margin-left:-20px;">
                                                            <input type="radio" name="gender"
                                                                formControlName="gender" [value]="gender.value"
                                                                (focusout)="handleGender($event)"
                                                                (keyup)="handleGender($event)" />
                                                            {{ gender.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <p class="error-msg"><span
                                                    *ngIf="personalInfoForm.controls['gender'].invalid && personalInfoForm.controls['gender'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                    </span></p>
                                                <!-- <p class="error-msg"><span
                                                        [style]="genderError ? 'visibility: visible' : 'visibility: hidden' ">Select
                                                        Gender</span></p> -->
                                            </div>
                                           
                                            <div class="field-input col-md-4" [class]="personalInfoForm.controls['maritalStatus'].invalid && personalInfoForm.controls['maritalStatus'].touched ? 'has-error' : '' ">
                                                <label class="form-label label-txt modal-field-label">
                                                    {{ 'PERSONAL_INFO.MARITAL STATUS' | customTranslate : 'Marital Status' }}</label>
                                                <select id="marital-status" formControlName="maritalStatus"
                                                    class="form-select" (change)="handleMarStat($event)"
                                                    (keyup)="handleMarStat($event)">
                                                    <!-- SK27FEB24 binding from dropdown list -->
                                                    <option *ngFor="let ms of maritalStatusDropDown.valueList" selected [value]="ms.key">{{ms.value}}</option>
                                                </select>
                                                
                                                   
                                                    <p class="error-msg"><span
                                                        *ngIf="personalInfoForm.controls['maritalStatus'].invalid && personalInfoForm.controls['maritalStatus'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                        </span></p>
                                                <!-- <p class="error-msg"><span
                                                        [style]="marStatusError ? 'visibility: visible' : 'visibility: hidden' ">Select
                                                        Marital Status</span></p> -->
                                            </div>
                                            <div class="field-input col-md-4"  [class]="personalInfoForm.controls['bloodGroup'].invalid && personalInfoForm.controls['bloodGroup'].touched ? 'has-error' : '' ">
                                                <label for="blood-group" class="form-label label-txt modal-field-label">
                                                    {{ 'PERSONAL_INFO.BLOOD GROUP' | customTranslate : 'Blood Group' }}*</label>
                                                <select id="blood-group" formControlName="bloodGroup"
                                                    class="form-select" (change)="handleBloodgroup($event)"
                                                    (keyup)="handleBloodgroup($event)">
                                                    <option *ngFor="let bg of bloodGroupDropdown.valueList" selected [value]="bg.key">{{bg.value}}</option>
                                                </select>
                                                
                                                
                                                    <p class="error-msg"><span
                                                        *ngIf="personalInfoForm.controls['bloodGroup'].invalid && personalInfoForm.controls['bloodGroup'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                        </span></p>
                                                <!-- <p class="error-msg"><span
                                                        [style]="bloodGroupError ? 'visibility: visible' : 'visibility: hidden' ">Select
                                                        Blood Group</span></p> -->
                                            </div>
                                            <div class="field-input col-md-4 w-50" >
                                                <label class="form-label label-txt modal-field-label" for="date">
                                                    {{ 'PERSONAL_INFO.WEDDING ANNIVERSARY' | customTranslate : 'Wedding Annverisary' }}</label>
                                                <input type="date" max="9999-12-31" formControlName="wedAnniversary"
                                                    id="date" name="date" format="mm-dd-yyyy" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer border-0">
                                <div class="center-button text-right" style="margin-top:-50px">
                                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                        [buttonType]="'text-only card-btn hide-detail-view'"
                                        [dataDismiss]="''" (buttonClickEmit)="personalInfoForm.reset()"
                                        [fcBtnId]="'myId'"></app-fusion-button>
                                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                                        [buttonType]="'input-button'"
                                        [dataDismiss]="''" [buttonDisable]="!personalInfoForm.valid"
                                        [fcBtnId]="'submit'" (buttonClickEmit)="savePersonalInfo(); hideplainview.hidePlainView()"></app-fusion-button>
                                </div> 

                            </div>
                        </div>
                    </app-cards>
                </div>
            </div>
            <!-- Contact Info -->
            <div class="col-md-12 mt-4">
                <div class="static-width-for-card">
                    <app-cards #hidecontview [inlineDetailed]="true">
                        <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                        <div class="header-section flex content-space-between">
                            <span>{{ 'CONTACT_INFO.CONTACT INFO' | customTranslate : 'Contact Info' }}</span>
                            <div class="card-btn show-detail-view">
                                <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                                <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                    class="card-icon" (click)="getData()"></svg-icon>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                        <div class="body-section card-text">
                            <div class="d-flex flex-column"
                                *ngFor="let data of personalGetData">
                                <div class="d-flex flex-row justify-content-start width-100">
                                    <div class="d-flex flex-column" style="width: 50%">
                                        <label for="lastname" class="label-head">{{'CONTACT_INFO.OFFICIAL EMAIL ID' | customTranslate : 'Official Email ID' }}</label>
                                        <label for="lastname"
                                            class="label-sub">{{this.userGetData?.email}}</label>
                                    </div>
                                    <div class="d-flex flex-column" style="width: 50%">
                                        <label for="lastname" class="label-head">{{ 'CONTACT_INFO.PERSONAL EMAIL ID' | customTranslate : 'Personal Email ID' }}</label>
                                        <label for="lastname"
                                            class="label-sub">{{data?.contactinfo?.personalmail}}</label>
                                    </div>
                                </div>
                                <div class="d-flex flex-row mt-4 justify-content-start width-100">
                                    <div class="d-flex flex-column" style="width: 50%">
                                        <label for="lastname" class="label-head">{{  'USER_DETAILS.PHONE_NUMBER' | customTranslate : 'Phone Number' }}</label>
                                        <label for="lastname"
                                            class="label-sub">{{data?.phone}}</label>
                                    </div>
                                    <div class="d-flex flex-column" style="width: 50%">
                                        <label for="lastname" class="label-head">{{ 'CONTACT_INFO.ALTERNATE NUMBER' | customTranslate : 'Alternate Number' }}</label>
                                        <label for="lastname"
                                            class="label-sub">{{data?.contactinfo?.alternatephone}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                        <div class="card-expanded-section detailed-section" plain>
                            <!-- 'plain' or 'modal' based on the requirement -->
                            <div class="card-header">{{ 'CONTACT_INFO.EDIT CONTACT INFO' | customTranslate : 'Edit Contact Info' }}</div>
                            <div class="card-body">
                                <form [formGroup]="contactInfoForm">
                                    <div class="field-sets">
                                        <div class="field-set-row flex">
                                            <div class="field-input col-md-6" [class]="contactInfoForm.controls['officeMail'].invalid && contactInfoForm.controls['officeMail'].touched ? 'has-error' : '' ">
                                                <label for="office-mail" class="form-label label-txt modal-field-label">
                                                    {{  'CONTACT_INFO.OFFICIAL EMAIL ID' | customTranslate : 'Official Email ID' }}*</label>
                                                <input type="text" formControlName="officeMail"
                                                    id="office-mail" placeholder="Ex.adithya@gmail.com" [readonly]="true">
                                                
                                                <p class="error-msg">
                                                    <span *ngIf="this.contactInfoForm.controls['officeMail'].invalid && this.contactInfoForm.controls['officeMail'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                    </span>
                                                </p>
                                                <!-- <p class="error-msg"><span [style]="true ? 'visibility: visible' : 'visibility: hidden' ">Enter valid Org Name</span></p> -->
                                            </div>
                                            <div class="field-input col-md-6" [class]="contactInfoForm.controls['personalMail'].invalid && contactInfoForm.controls['personalMail'].touched ? 'has-error' : '' ">
                                                <label for="personal-mail" class="form-label label-txt modal-field-label">
                                                    {{ 'CONTACT_INFO.PERSONAL EMAIL ID' | customTranslate : 'Personal Email ID' }}*</label>
                                                <input type="text" formControlName="personalMail"
                                                    id="personal-mail" placeholder="Ex.adithya@yahoo.com">
                                                
                                                <p class="error-msg"><span
                                                        *ngIf="this.contactInfoForm.controls['personalMail'].invalid && this.contactInfoForm.controls['personalMail'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                    </span></p>
                                            </div>
                                            <div class="field-input col-md-6" [class]="contactInfoForm.controls['phNumber'].invalid && contactInfoForm.controls['phNumber'].touched ? 'has-error' : '' ">
                                                <label for="ph-number" class="form-label label-txt modal-field-label">
                                                    {{'USER_DETAILS.PHONE_NUMBER' | customTranslate : 'Phone Number' }} *</label>
                                                <input type="number" formControlName="phNumber"
                                                    id="ph-number" placeholder="Ex.+919876543210">
                                                
                                                <p class="error-msg">
                                                    <span *ngIf="this.contactInfoForm.controls['phNumber'].invalid && this.contactInfoForm.controls['phNumber'].touched && !this.contactInfoForm.controls['phNumber'].hasError('pattern')">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                    </span>
                                                    <span *ngIf="this.contactInfoForm.controls['phNumber'].hasError('pattern') && this.contactInfoForm.controls['phNumber'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="field-input col-md-6">
                                                <label for="alt-number" class="form-label label-txt modal-field-label">
                                                    {{ 'CONTACT_INFO.ALTERNATE NUMBER' | customTranslate : 'Alternate Number' }}</label>
                                                <input type="number" formControlName="altNumber"
                                                    id="alt-number" placeholder="Ex.+911234567890">
                                               <p class="error-msg"><span
                                                        *ngIf="this.contactInfoForm.controls['altNumber'].invalid && this.contactInfoForm.controls['altNumber'].touched">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}</span></p>
                                            </div> 
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer border-0">
                                <div class="center-button text-right">
                                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                        [buttonType]="'text-only card-btn hide-detail-view'"
                                        [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); contactInfoForm.reset()"
                                        [fcBtnId]="'myId'"></app-fusion-button>
                                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}"
                                        [buttonType]="'input-button'" (buttonClickEmit)="saveContactInfo();  hidecontview.hidePlainView()"
                                        [buttonDisable]="!contactInfoForm.valid"></app-fusion-button>                                  
                                </div>
                            </div>
                        </div>
                    </app-cards>
                </div>
            </div>
            <!-- Address Info -->
            <div class="col-md-12 mt-4">
                <div class="static-width-for-card">
                    <app-cards #hideaddview [inlineDetailed]="true">
                        <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                        <div class="header-section flex content-space-between">
                            <span> {{ 'ADDRESS.ADDRESS' | customTranslate : 'Address' }} </span>
                            <div class="card-btn show-detail-view">
                                <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                                <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                    class="card-icon" (click)="getData()"></svg-icon>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                        <div class="body-section card-text">
                            <div class="d-flex flex-column"
                                *ngFor="let data of personalGetData">
                                <div class="d-flex flex-row justify-content-start width-100">
                                    <div class="d-flex flex-column" style="width: 50%">
                                        <label for="current-address" class="label-head">{{ 'ADDRESS_BasicInfo.CURRENT ADDRESS' | customTranslate : 'Current Address' }}</label>
                                        <div class="d-flex flex-column">
                                            <label class="label-sub">{{data?.addressinfo?.currentaddress.address1}}{{data?.addressinfo?.currentaddress.address1 ? ',' : ''}}</label>
                                            <label class="label-sub">{{data?.addressinfo?.currentaddress.address2}}{{data?.addressinfo?.currentaddress.address2 ? ',' : ''}}</label>
                                            <label class="label-sub">{{data?.addressinfo?.currentaddress.city}}{{data?.addressinfo?.currentaddress.city ? ',' : ''}}</label>
                                            <label class="label-sub">{{data?.addressinfo?.currentaddress.state}} {{data?.addressinfo?.currentaddress.state ? '-' : ''}} {{data?.addressinfo?.currentaddress.postalcode}}{{data?.addressinfo?.currentaddress.postalcode ? ',' : ''}} {{data?.addressinfo?.currentaddress.country}}{{data?.addressinfo?.currentaddress.country ? '.' : '' }}</label>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column" style="width: 50%">
                                        <label for="current-address" class="label-head">{{ 'ADDRESS_BasicInfo.PERMANENT ADDRESS' | customTranslate : 'Permanent Address' }}</label>
                                        <div class="d-flex flex-column">
                                            <label class="label-sub">{{data?.addressinfo?.permanentaddress.address1}}{{data?.addressinfo?.permanentaddress.address1 ? ',' : ''}}</label>
                                            <label class="label-sub">{{data?.addressinfo?.permanentaddress.address2}}{{data?.addressinfo?.permanentaddress.address2 ? ',' : ''}}</label>
                                            <label class="label-sub">{{data?.addressinfo?.permanentaddress.city}}{{data?.addressinfo?.permanentaddress.city ? ',' : ''}}</label>
                                            <label class="label-sub">{{data?.addressinfo?.permanentaddress.state}} {{data?.addressinfo?.permanentaddress.state ? '-' : ''}} {{data?.addressinfo?.permanentaddress.postalcode}}{{data?.addressinfo?.permanentaddress.postalcode ? ',' : ''}} {{data?.addressinfo?.permanentaddress.country}}{{data?.addressinfo?.permanentaddress.country ? '.' : '' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                        <div class="card-expanded-section detailed-section" plain>
                            <!-- 'plain' or 'modal' based on the requirement -->
                            <div class="card-header"> {{ 'ADDRESS_BasicInfo.EDIT ADDRESS INFO' | customTranslate : 'Edit Address Info'  }}</div>
                            <div class="card-body">
                                <form [formGroup]="addressForm" class="d-flex flex-row">
                                    <div class="field-sets w-100">
                                        <div class="field-set-row flex">
                                            <div class="field-input">
                                                <div> {{ 'ADDRESS_BasicInfo.CURRENT ADDRESS' | customTranslate : 'Current Address' }} </div>
                                                <div class="field-set-row flex flex-column mt-4">
                                                    <div class="field-input col-md-6" style="width: 95%;" [class]="addressForm.controls['cAddress1'].invalid && addressForm.controls['cAddress1'].touched ? 'has-error' : '' ">
                                                        <label for="c-address-1" class="form-label label-txt modal-field-label">{{ 'ADDRESS_BasicInfo.ADDRESS1' | customTranslate : 'Address 1' }}*</label>
                                                        <input type="text" tabindex="1" formControlName="cAddress1" id="c-address-1" placeholder="Ex: No.01, ABC Street">
                                                        <p class="error-msg"><span
                                                            *ngIf="addressForm.controls['cAddress1'].invalid && addressForm.controls['cAddress1'].touched">
                                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                                
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;" [class]="addressForm.controls['cAddress2'].invalid && addressForm.controls['cAddress2'].touched ? 'has-error' : '' ">
                                                        <label for="c-address-2" class="form-label label-txt modal-field-label"> {{ 'ADDRESS_BasicInfo.ADDRESS2' | customTranslate : 'Address 2' }}*</label>
                                                        <input type="text" tabindex="2" formControlName="cAddress2" id="c-address-2" placeholder="Ex: Pallavaram">
                                                        <p class="error-msg"><span
                                                            *ngIf="addressForm.controls['cAddress2'].invalid && addressForm.controls['cAddress2'].touched">
                                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                                
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;" [class]="addressForm.controls['cCity'].invalid && addressForm.controls['cCity'].touched ? 'has-error' : '' ">
                                                        <label for="c-city" class="form-label label-txt modal-field-label"> {{ 'ADDRESS.CITY' | customTranslate : 'City' }} *</label>
                                                        <input type="text" tabindex="3" formControlName="cCity"
                                                            id="c-city" placeholder="Ex: Chennai">
                                                        <p class="error-msg"><span
                                                            *ngIf="addressForm.controls['cCity'].invalid && addressForm.controls['cCity'].touched">
                                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                                
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;" [class]="addressForm.controls['cPinCode'].invalid && addressForm.controls['cPinCode'].touched ? 'has-error' : '' ">
                                                        <label for="c-pincode" class="form-label label-txt modal-field-label">{{ 'ADDRESS.POSTAL_CODE' | customTranslate : 'Postal code / Zip code' }} *</label>
                                                        <input type="number" tabindex="4" formControlName="cPinCode"
                                                            id="c-pincode" placeholder="Ex: 600000">
                                                        <p class="error-msg">
                                                            <span *ngIf="addressForm.controls['cPinCode'].invalid && addressForm.controls['cPinCode'].touched && !this.addressForm.controls['cPinCode'].hasError('pattern')">
                                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                            </span>
                                                            <span *ngIf="addressForm.controls['cPinCode'].touched && this.addressForm.controls['cPinCode'].hasError('pattern')">
                                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                                {{ 'VALIDATION.POSTAL_CODE' | customTranslate : 'Please enter a valid pincode' }}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;" [class]="addressForm.controls['cState'].invalid && addressForm.controls['cState'].touched ? 'has-error' : '' ">
                                                        <label for="c-state" class="form-label label-txt modal-field-label">{{ 'ADDRESS.STATE' | customTranslate : 'State' }} *</label>
                                                        <input type="text" tabindex="5" formControlName="cState"
                                                            id="c-state" placeholder="Ex: Tamil Nadu">
                                                        <!-- <select id="c-state" tabindex="5" formControlName="cState" placeholder="EX.TamilNadu"
                                                            class="form-select">
                                                            <option selected value=""></option>
                                                            <option>TamilNadu</option>
                                                            <option>Kerala</option>
                                                            <option>Andhra Pradesh</option>
                                                            <option>Telangana</option>
                                                            <option>Karnataka</option>
                                                            <option>Maharastra</option>
                                                        </select> -->
                                                        <p class="error-msg"><span
                                                            *ngIf="addressForm.controls['cState'].invalid && addressForm.controls['cState'].touched">
                                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;" [class]="addressForm.controls['cCountry'].invalid && addressForm.controls['cCountry'].touched ? 'has-error' : '' ">
                                                        <label for="c-country" class="form-label label-txt modal-field-label">{{ 'ADDRESS_BasicInfo.COUNTRY' | customTranslate : 'Country' }} *</label>
                                                        <select id="c-country" tabindex="6" formControlName="cCountry" placeholder="EX.India"
                                                            class="form-select">
                                                            <option value="Afghanistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Antartica">Antarctica</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African Republic">Central African Republic</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo">Congo</option>
                                                            <option value="Congo">Congo, the Democratic Republic of the</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                                            <option value="Croatia">Croatia (Hrvatska)</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="East Timor">East Timor</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="France Metropolitan">France, Metropolitan</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                                            <option value="Holy See">Holy See (Vatican City State)</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Iran">Iran (Islamic Republic of)</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                                            <option value="Korea">Korea, Republic of</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Lao">Lao People's Democratic Republic</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon" selected>Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macau">Macau</option>
                                                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Micronesia">Micronesia, Federated States of</option>
                                                            <option value="Moldova">Moldova, Republic of</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Pitcairn">Pitcairn</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Reunion">Reunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russia">Russian Federation</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option value="Saint LUCIA">Saint LUCIA</option>
                                                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                                            <option value="Span">Spain</option>
                                                            <option value="SriLanka">Sri Lanka</option>
                                                            <option value="St. Helena">St. Helena</option>
                                                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syria">Syrian Arab Republic</option>
                                                            <option value="Taiwan">Taiwan, Province of China</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania">Tanzania, United Republic of</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States">United States</option>
                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Viet Nam</option>
                                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                                            <option value="Western Sahara">Western Sahara</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Serbia">Serbia</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                        <p class="error-msg"><span
                                                            *ngIf="addressForm.controls['cCountry'].invalid && addressForm.controls['cCountry'].touched">
                                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                                    </div>
                                                    <div class="form-check flex flex-row col-md-12">
                                                        <input class="form-check-input" tabindex="7"
                                                            (change)="handleCheckBox($event)" name="checkbox"
                                                            formControlName="checkbox" type="checkbox"
                                                            id="flexCheckDefault" style="
                                                            max-width: 20px;
                                                            max-height: 20px;">
                                                        <label class="form-check-label ms-3" for="flexCheckDefault">
                                                            {{ 'ADDRESS_BasicInfo.SAME' | customTranslate : 'Permanent Address same as Current Address' }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field-input">
                                                <div>{{ 'ADDRESS_BasicInfo.PERMANENT ADDRESS' | customTranslate : 'Permanent Address' }} </div>
                                                <div class="field-set-row d-flex flex-column mt-4 ">
                                                    <div class="field-input col-md-6" style="width: 95%;">
                                                        <label for="p-address-1" class="form-label label-txt modal-field-label">{{ 'ADDRESS_BasicInfo.ADDRESS1' | customTranslate : 'Address 1' }}</label>
                                                        <input type="text" tabindex="8" value="{{addressForm.value.pAddress1}}" formControlName="pAddress1" id="c-address-1" placeholder="Ex: No.01, ABC Street">
                                                
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;">
                                                        <label for="p-address-2" class="form-label label-txt modal-field-label">{{ 'ADDRESS_BasicInfo.ADDRESS2' | customTranslate : 'Address 2' }}</label>
                                                        <input type="text" tabindex="9" formControlName="pAddress2"
                                                            id="p-address-2" value="{{addressForm.value.pAddress2}}"
                                                            placeholder="Ex: Pallavaram">
                                                
                                                    </div>
                                                    <div class="field-input  col-md-6" style="width: 95%;">
                                                        <label for="p-city" class="form-label label-txt modal-field-label">{{ 'ADDRESS.CITY' | customTranslate : 'City' }}</label>
                                                        <input type="text" tabindex="10" value="{{addressForm.value.pCity}}" formControlName="pCity"
                                                            id="p-city" placeholder="Ex: Chennai">
                                                
                                                    </div>
                                                    <div class="field-input  col-md-6" style="width: 95%;">
                                                        <label for="p-pincode" class="form-label label-txt modal-field-label">{{ 'ADDRESS.POSTAL_CODE' | customTranslate : 'Postal code / Zip code' }}</label>
                                                        <input type="number" tabindex="11" value="{{addressForm.value.pPinCode}}" formControlName="pPinCode"
                                                            id="p-pincode" placeholder="Ex: 600000">
                                                        <p class="error-msg"><span *ngIf="addressForm.controls['pPinCode'].touched && this.addressForm.controls['pPinCode'].hasError('pattern')"><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> Please enter a valid pincode</span></p>
                                                    </div>
                                                    <div class="field-input  col-md-6" style="width: 95%;">
                                                        <label for="p-state" class="form-label label-txt modal-field-label">{{ 'ADDRESS.STATE' | customTranslate : 'State' }}</label>
                                                        <input type="text" tabindex="12" value="{{addressForm.value.pState}}" formControlName="pState"
                                                            id="p-state" placeholder="Ex: Tamil Nadu">
                                                        <!-- <select id="p-state" tabindex="12" value="{{addressForm.value.pState}}" formControlName="pState"
                                                            class="form-select">
                                                            <option selected value=""></option>
                                                            <option>TamilNadu</option>
                                                            <option>Kerala</option>
                                                            <option>Andhra Pradesh</option>
                                                            <option>Telangana</option>
                                                            <option>Karnataka</option>
                                                            <option>Maharastra</option>
                                                        </select> -->
                                                    </div>
                                                    <div class="field-input col-md-6" style="width: 95%;">
                                                        <label for="p-country" class="form-label label-txt modal-field-label">{{ 'ADDRESS_BasicInfo.COUNTRY' | customTranslate : 'Country' }}</label>
                                                        <select id="p-country" tabindex="13" value="{{addressForm.value.pCountry}}" formControlName="pCountry"
                                                            class="form-select">
                                                            <option value="Afghanistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="American Samoa">American Samoa</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Anguilla">Anguilla</option>
                                                            <option value="Antartica">Antarctica</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Aruba">Aruba</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bermuda">Bermuda</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Bouvet Island">Bouvet Island</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Cape Verde">Cape Verde</option>
                                                            <option value="Cayman Islands">Cayman Islands</option>
                                                            <option value="Central African Republic">Central African Republic</option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Christmas Island">Christmas Island</option>
                                                            <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo">Congo</option>
                                                            <option value="Congo">Congo, the Democratic Republic of the</option>
                                                            <option value="Cook Islands">Cook Islands</option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                                            <option value="Croatia">Croatia (Hrvatska)</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czech Republic">Czech Republic</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic</option>
                                                            <option value="East Timor">East Timor</option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                                            <option value="Faroe Islands">Faroe Islands</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="France Metropolitan">France, Metropolitan</option>
                                                            <option value="French Guiana">French Guiana</option>
                                                            <option value="French Polynesia">French Polynesia</option>
                                                            <option value="French Southern Territories">French Southern Territories</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Gibraltar">Gibraltar</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Greenland">Greenland</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guadeloupe">Guadeloupe</option>
                                                            <option value="Guam">Guam</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                                            <option value="Holy See">Holy See (Vatican City State)</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hong Kong">Hong Kong</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Iran">Iran (Islamic Republic of)</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                                            <option value="Korea">Korea, Republic of</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Lao">Lao People's Democratic Republic</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon" selected>Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Macau">Macau</option>
                                                            <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Martinique">Martinique</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mayotte">Mayotte</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Micronesia">Micronesia, Federated States of</option>
                                                            <option value="Moldova">Moldova, Republic of</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montserrat">Montserrat</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option value="New Caledonia">New Caledonia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="Niue">Niue</option>
                                                            <option value="Norfolk Island">Norfolk Island</option>
                                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Pitcairn">Pitcairn</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Puerto Rico">Puerto Rico</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Reunion">Reunion</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russia">Russian Federation</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option value="Saint LUCIA">Saint LUCIA</option>
                                                            <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                                            <option value="Span">Spain</option>
                                                            <option value="SriLanka">Sri Lanka</option>
                                                            <option value="St. Helena">St. Helena</option>
                                                            <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                                            <option value="Swaziland">Swaziland</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syria">Syrian Arab Republic</option>
                                                            <option value="Taiwan">Taiwan, Province of China</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania">Tanzania, United Republic of</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tokelau">Tokelau</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States">United States</option>
                                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Viet Nam</option>
                                                            <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                            <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                                            <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                                            <option value="Western Sahara">Western Sahara</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Serbia">Serbia</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer border-0">
                                <div class="center-button text-right">
                                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                        [buttonType]="'text-only card-btn hide-detail-view'"
                                        [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); addressForm.reset()"
                                        [fcBtnId]="'myId'"></app-fusion-button>
                                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                                        [buttonType]="'input-button'" (buttonClickEmit)="saveaddress(); hideaddview.hidePlainView()"
                                        [buttonDisable]="!addressForm.valid"></app-fusion-button>

                                </div>
                            </div>
                        </div>
                    </app-cards>
                </div>
            </div>
            <!-- SocialMedia Info -->
            <div class="col-md-12 mt-4">
                <div class="static-width-for-card">
                    <app-cards #hidesmview [inlineDetailed]="true">
                        <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                        <div class="header-section flex content-space-between">
                            <span>{{ 'SOCIAL_MEDIA.SOCIAL PROFILE' | customTranslate : 'Social Profile' }}</span>
                            <div class="card-btn show-detail-view">
                                <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                                <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                    class="card-icon" (click)="getData()"></svg-icon>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'body-section', it will get rendered in the 'card-body' div in the component's HTML -->
                        <div class="body-section card-text">
                            <div class="d-flex flex-row justify-content-around w-100"
                                *ngFor="let data of personalGetData">
                                <div class="d-flex flex-row width-100">
                                    <div >
                                        <a [href]="'//'+data?.socialinfo?.facebook" target="_blank" class="form-label label-head">
                                            <img src="../assets/images/icons/social-media/facebook.svg"></a>
                                    </div>
                                    <div class="ms-2">
                                        <a [href]="'//'+data?.socialinfo?.linkedin" target="_blank" class="form-label label-head">
                                            <img src="../assets/images/icons/social-media/linkedin.svg"></a>
                                    </div>
                                    <div class="ms-2">
                                        <a [href]="'//'+data?.socialinfo?.twitter" target="_blank" class="form-label label-head">
                                            <img src="../assets/images/icons/social-media/twitter.svg"></a>
                                    </div>
                                    <div class="ms-2">
                                        <a [href]="'//'+data?.socialinfo?.instagram" target="_blank" class="form-label label-head">
                                            <img src="../assets/images/icons/social-media/instagram.svg"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- when a HTML element has a class 'detailed-section', it will get rendered in the  detail view secion for inline detailed UI-->
                        <div class="card-expanded-section detailed-section" plain>
                            <!-- 'plain' or 'modal' based on the requirement -->
                            <div class="card-header">{{ 'SOCIAL_MEDIA.EDIT SOCIAL PROFILE' | customTranslate : 'Edit Social Profile' }}</div>
                            <div class="card-body">
                                <form [formGroup]="socialMediaForm">
                                    <div class="field-sets">
                                        <div class="field-set-row flex">
                                            <div class="field-input col-md-6">
                                                <label for="fb"
                                                    class="form-label label-txt modal-field-label">{{ 'SOCIAL_MEDIA.FACEBOOK' | customTranslate : 'Facebook' }}</label>
                                                <input type="url" formControlName="facebook" id="fb"
                                                    placeholder="Facebook">
                                                <p class="error-msg"><span
                                                    *ngIf="this.contactInfoForm.controls['officeMail'].invalid && this.contactInfoForm.controls['officeMail'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                            </div>
                                            <div class="field-input col-md-6" [class]="false ? ' has-error' : ' ' ">
                                                <label for="insta"
                                                    class="form-label label-txt modal-field-label">{{ 'SOCIAL_MEDIA.INSTAGRAM' | customTranslate : 'Instagram' }}</label>
                                                <input type="url" formControlName="instagram" id="insta"
                                                    placeholder="Instagram">
                                                <p class="error-msg"><span
                                                    *ngIf="this.contactInfoForm.controls['personalMail'].invalid && this.contactInfoForm.controls['personalMail'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                            </div>
                                            <div class="field-input col-md-6" style="transition: none;">
                                                <label for="linkedin"
                                                    class="form-label label-txt modal-field-label">{{ 'SOCIAL_MEDIA.LINKEDIN' | customTranslate : 'LinkedIn' }}</label>
                                                <input type="url" formControlName="linkedIN"
                                                    id="linkedin" placeholder="LinkedIN">
                                                <p class="error-msg"><span
                                                    *ngIf="this.contactInfoForm.controls['phNumber'].invalid && this.contactInfoForm.controls['phNumber'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                            </div>
                                            <div class="field-input col-md-6" style="transition: none;">
                                                <label for="twitter"
                                                    class="form-label label-txt modal-field-label">{{ 'SOCIAL_MEDIA.TWITTER' | customTranslate : 'Twitter' }}</label>    
                                                <input type="url" formControlName="twitter" id="twitter"
                                                    placeholder="Twitter">
                                                <p class="error-msg"><span
                                                    *ngIf="this.contactInfoForm.controls['altNumber'].invalid && this.contactInfoForm.controls['altNumber'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer border-0">
                                <div class="center-button text-right">
                                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                        [buttonType]="'text-only card-btn hide-detail-view'"
                                        [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); socialMediaForm.reset()"
                                        [fcBtnId]="'myId'"></app-fusion-button>
                                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}"
                                        [buttonType]="'input-button'"
                                        (buttonClickEmit)="saveSocialMediaInfo(); hidesmview.hidePlainView()"
                                            [buttonDisable]="!socialMediaForm.valid"></app-fusion-button>
                                </div>
                            </div>
                        </div>
                    </app-cards>
                </div>
            </div>
        </div>
    <!-- </div> -->
<!-- </div> -->
