import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { FusionFrameworkService } from 'src/app/services/fusion-framework.service';
import { SideNavEmitService } from 'src/app/services/side-nav-emit.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Constants } from 'src/app/models/Constants';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';


@Component({
  selector: 'app-modules',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, RouterModule, CustomTranslatePipe],
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {
  activeNav: string = '';
  currentPageComponent: string = 'NoComponent'
  currentApplicationVersion = environment.appVersion;

  hamBurgerClickSubscribe: Subscription;
  showSideNavInMobile: boolean = false;  
  myActiveNav:any;
  isDevMode: boolean = false;
  jsonSubscription: Subscription;
  moduleSubscription:Subscription;
  configJSON: Array<any> = [];
  moduleName: any;
  tabName:any;

  @Input() jsonArray : Array<any> = [];
  @Input() languageCode:any; //SKS11OCT24
  @Output() moduleLabel = new EventEmitter<any>;

  constructor(private fusionFrameWork: FusionFrameworkService, private sideNavItem: SideNavEmitService,private router: Router, private routeActive: ActivatedRoute) { 
    this.configJSON = this.jsonArray;
    const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24
    this.languageCode = userData?.languageCode || "en";
    this.jsonSubscription = this.fusionFrameWork.appJSONData$.subscribe((apps:any) => {
      console.log('inside modules.component.ts constructor jsonSubscription with ' + apps[0].modules.length + ' modules');
      this.configJSON = apps;
      // SK10MAY24 to sort module based on order
      this.configJSON.forEach(element => {
        element.modules.sort((a:any, b:any) => a.order - b.order);
      });
      // this.configJSON = Constants.configJSON;
    });

    this.moduleSubscription = this.fusionFrameWork.moduleJSONData$.subscribe((module:any) => {
      console.log('inside modules.component.ts constructor moduleSubscription with ' + module.tabs.length + ' tabs');
      // this.configJSON = type;
      // this.currentPageComponent = type[0]?.modules?.label;
      this.routeActive.paramMap.subscribe(params => {
        this.appName = params.get('appName');
        this.currentPageComponent = this.appName;
        this.moduleName = params.get('moduleName'); // params.get('moduleName');

        // Set-up the Tabs of this module
        this.fusionFrameWork.getTabData(module);
        // this.ngOnDestroy();
      });
    });

    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.hamBurgerClickSubscribe = this.sideNavItem.isHamburgerClicked$.subscribe(navItem =>  { 
      if(navItem == 'hamburger') {
        this.showSideNavInMobile = !this.showSideNavInMobile;
      }
    });
  }

  ngOnInit(): void {
    console.log('inside modules.component.ts ngOnInit with ' + this.moduleName);
    this.routeActive.paramMap.subscribe(params => {
      this.appName = params.get('appName');
      this.currentPageComponent = this.appName;
      this.moduleName = params.get('moduleName');
    });
    const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24
    this.languageCode = userData?.languageCode || "en";
  }

  ngOnChanges(changes:SimpleChanges): void {
    //SK 23JAN24 detect changes when jsonArray value from input changed 
    if(changes["jsonArray"]){
      this.configJSON = this.jsonArray;
    }
  }

  clickdedEmptySideNav($event: any){
    /* This function is created to close the side nav when clicked on the white space */
    /* We have to write more condition to detect if it is a mobile device to avoid unwanted execution */
    if($event.target?.classList.length > 1){
      if($event.target?.classList?.contains("empty-space-on-nav")){
        this.sideNavItem.clickedOnHamburger('hamburger'); /* This is used to close the side nav item in mobile devices */
      }
    }
  }

  moduleList:any;
  appName:any;
  callNavService(module: any){
    this.moduleList = module;
    this.moduleName = module.name;
    this.moduleLabel.emit(module);
    if(this.isDevMode) console.log('callNavService:', module.name);
    this.sideNavItem.clickedOnSideNav(module.name);
    this.sideNavItem.clickedOnHamburger('hamburger'); /* This is used to close the side nav item in mobile devices */
    this.activeNav = module.name;
    // this.router.navigateByUrl('/app/'+ this.appName + '/' + module?.name, module?.tabs[0]?.name);
    this.fusionFrameWork.getTabData(module);
    // this.ngOnDestroy();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.moduleSubscription.unsubscribe();
  }
}