<div class="form-card">
    <div class="col-md-12 mt-3">
        <div class="static-width-for-card width-100">
            <app-cards #hideAddExperience [inlineDetailed]="true">
                <div class="header-section flex flex-column content-space-between">
                    <span>{{ 'HEADINGS.EXPERIENCES' | customTranslate : 'Experiences' }}</span>
                </div>

                <div class="body-section card-text">
                    <div class="card-btn show-detail-view text-primary" (click)="addExperience()" *ngIf="!isEditOn">
                        + {{ 'EXPERIENCES_INFO.ADDEXPERIENCE' | customTranslate : 'ADD EXPERIENCE' }}
                    </div>
                </div>
                <div class="card-expanded-section detailed-section" plain>
                    <div class="card-header">{{ 'EXPERIENCES_INFO.EXPERIENCEDETAILS' | customTranslate : 'ADD EXPERIENCE DETAILS' }}</div>
                    <div class="card-body">
                        <form [formGroup]="experienceInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-4"
                                        [class]=" this.experienceInfoForm.controls['designation'].invalid && this.experienceInfoForm.controls['designation'].touched  ? 'has-error' : '' ">
                                        <!--RG26AUG24 changed the label UI -->
                                        <label for="designationPost" class="form-label label-txt modal-field-label"
                                            cursor="none">{{ 'WORK_INFO.DESIGNATION' | customTranslate : 'Designation' }}*</label>
                                        <input type="text" formControlName="designation" placeholder="Developer"
                                            class="form-control" id="designationPost">
                                        <!-- <select id="designationPost" formControlName="designation"
                                            placeholder="Ex. Bachelor's" class="form-select">
                                            <option selected value=""></option>
                                            <option>Developer</option>
                                            <option>Designer</option>
                                            <option>Tester</option>
                                        </select> -->
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['designation'].invalid && this.experienceInfoForm.controls['designation'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-4"
                                        [class]=" this.experienceInfoForm.controls['companyName'].invalid && this.experienceInfoForm.controls['companyName'].touched  ? 'has-error' : '' ">
                                        <label for="companyName" class="form-label label-txt modal-field-label">{{ 'COMPANY_NAME' | customTranslate : 'Company Name' }}*</label>
                                        <input type="text" formControlName="companyName" placeholder=""
                                            class="form-control" id="companyName">
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['companyName'].invalid && this.experienceInfoForm.controls['companyName'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" this.experienceInfoForm.controls['location'].invalid && this.experienceInfoForm.controls['location'].touched  ? 'has-error' : '' ">
                                        <label for="companyName" class="form-label label-txt modal-field-label"
                                            cursor="none">{{ 'EXPERIENCES_INFO.LOCATION' | customTranslate : 'Location' }}*</label>
                                        <input type="text" formControlName="location" placeholder="Tamil Nadu / Chennai"
                                            class="form-control" id="location">
                                        <!-- <select id="location" formControlName="location" placeholder="Tamil Nadu / Chennai"
                                            class="form-select">
                                            <option selected value=""></option>
                                            <option>Chennai</option>
                                            <option>Bangalore</option>
                                            <option>Kochi</option>
                                        </select> -->

                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['location'].invalid && this.experienceInfoForm.controls['location'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" this.experienceInfoForm.controls['empType'].invalid && this.experienceInfoForm.controls['empType'].touched  ? 'has-error' : '' ">
                                        <label for="empType" class="form-label label-txt modal-field-label" cursor="none">{{ 'WORK_BASICINFO.EMPLOYEETYPE' | customTranslate : 'Employee Type' }}*</label>
                                        <select id="empType" formControlName="empType" placeholder=""
                                            class="form-select">
                                            <option *ngFor="let et of empTypeDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                                        </select>
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['empType'].invalid && this.experienceInfoForm.controls['empType'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6"
                                        [class]=" experienceInfoForm.controls['dateOfStart'].invalid && experienceInfoForm.controls['dateOfStart'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="dateOfStart">{{ 'QUALIFICATION_INFO.DOS' | customTranslate : 'Date Of Start' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfStart"
                                            id="dateOfStart" name="date" format="dd-mm-yyyy" />
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['dateOfStart'].invalid && this.experienceInfoForm.controls['dateOfStart'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6"
                                        [class]=" experienceInfoForm.controls['dateOfEnd'].invalid && experienceInfoForm.controls['dateOfEnd'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="dateOfEnd">{{ 'QUALIFICATION_INFO.DOE' | customTranslate : 'Date Of End' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfEnd"
                                            id="dateOfEnd" name="date" format="dd-mm-yyyy" />
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['dateOfEnd'].invalid && this.experienceInfoForm.controls['dateOfEnd'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer border-0">
                        <div class="center-button text-right">
                            <div>{{ 'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button" }}</div>
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''"
                                [fcBtnId]="'myId'" data-bs-dismiss="" (buttonClickEmit)="cancelButton()">
                            </app-fusion-button>
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                            buttonType="btn btn-primary input-button" [dataDismiss]="''"
                                (buttonClickEmit)="saveExperienceInfo(); hideAddExperience.hidePlainView()"
                                [buttonDisable]="!experienceInfoForm.valid"></app-fusion-button>
                        </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>
    <div class="col-md-12 mt-1" *ngFor="let user of expGetData; let i=index">
        <div class="static-width-for-card width-100">
            <app-cards #hideAll [inlineDetailed]="true" [modalDeleted]="true" [modalId]="'experienceModalId'">
                <div class="header-section flex content-space-between">
                    <span>{{'HEADINGS.EXPERIENCES' | customTranslate : 'Experiences' }} : {{user.designation}}</span>
                    <div class="header-section flex content-space-between">
                        <div class="card-btn show-detail-view text-primary" *ngIf="!isEditOn">
                            <svg-icon src="../assets/images/icons/general/pencil-icon.svg" class="card-icon ms-3"
                                (click)="onEdit(user)" style="cursor: pointer;"></svg-icon>
                        </div>
                        <div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#experienceModalId">
                            <svg-icon src="../assets/images/icons/general/delete.svg" class="card-icon ms-3"
                                (click)="expOnDelete(user)" style="cursor: pointer;"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="card-expanded-section modal-content modal-section" experienceModalDelete>
                    <div class="modal-header">
                        <div class="modal-title fs-6" id="cardModalLabel">{{ 'EXPERIENCES_INFO.DELETEEXPERIENCE' | customTranslate : 'Delete Experience' }}</div>
                        <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                             [fcBtnId]="'close'">
                        </app-fusion-button>
                       </div>  
                    <div class="modal-body">
                        <p>{{'QUALIFICATION_INFO.DELETEINFO' | customTranslate : 'Are you sure you want to delete your'  }} {{deleteExpInfo?.designation}}?</p>
                    </div>
                    <div class="modal-footer">
                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL_THANKS' | customTranslate : 'No Thanks' }}"
                        [buttonType]="'text-only'"
                            [dataDismiss]="'modal'" [fcBtnId]="'myId'" data-bs-dismiss="modal"
                            (buttonClickEmit)="cancelButton()"></app-fusion-button>
                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.DELETE' | customTranslate : 'Delete' }}"
                        [fcBtnId]="'modalSave'" (buttonClickEmit)="deleteExp()"
                            data-bs-dismiss="modal"></app-fusion-button>
                    </div>
                </div>
                <div class="body-section card-text">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-start width-100">
                            <svg-icon src="../assets/images/icons/general/logo.svg"
                                class="card-icon ms-3 align-self-center"></svg-icon>
                            <div class="d-flex flex-column ms-5 width-25">
                                <label class="label-head">{{user.designation}}</label>
                                <label class="label-sub">{{user.companyName}}</label>
                                <label class="label-sub">{{user.location}}</label>
                                <label class="label-sub">{{user.employementType}}</label>
                                <label class="label-sub">{{user.startDate | date:'mediumDate'}} - {{user.endDate |
                                    date:'mediumDate'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-expanded-section detailed-section" plain>
                    <div class="card-header">
                        <div class="modal-title" id="cardDetailedModalLabel">{{ 'EXPERIENCES_INFO.EDITEXPERIENCE' | customTranslate : 'Edit Experience Details' }}</div>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="experienceInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-4"
                                        [class]="this.experienceInfoForm.controls['designation'].invalid && this.experienceInfoForm.controls['designation'].touched  ? 'has-error' : '' ">
                                        <label for="designationPatch" class="form-label label-txt modal-field-label">{{ 'WORK_INFO.DESIGNATION' | customTranslate : 'Designation' }}*</label>
                                        <input type="text" formControlName="designation" placeholder="Developer"
                                            class="form-control" id="designationPatch">
                                        <!-- <select id="designationPatch" formControlName="designation"
                                            placeholder="Ex. Bachelor's" class="form-select" [value]="user.Designation">
                                            <option selected value=""></option>
                                            <option>Developer</option>
                                            <option>Designer</option>
                                            <option>Tester</option>
                                        </select> -->
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['designation'].invalid && this.experienceInfoForm.controls['designation'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]="experienceInfoForm.controls['companyName'].invalid && experienceInfoForm.controls['companyName'].touched  ? 'has-error' : '' ">
                                        <label for="companyNamePatch" class="form-label label-txt modal-field-label" cursor="none">
                                            {{ 'COMPANY_NAME' | customTranslate : 'Company Name' }}*</label>
                                        <input type="text" formControlName="companyName" placeholder=""
                                            class="form-control" id="companyNamePatch">
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['companyName'].invalid && this.experienceInfoForm.controls['companyName'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-4"
                                        [class]=" experienceInfoForm.controls['location'].invalid && experienceInfoForm.controls['location'].touched  ? 'has-error' : '' ">
                                        <label for="location" class="form-label label-txt modal-field-label" cursor="none">{{ 'EXPERIENCES_INFO.LOCATION' | customTranslate : 'Location' }}*</label>
                                        <input type="text" formControlName="location" placeholder="Tamil Nadu / Chennai"
                                            class="form-control" id="location">
                                        <!-- <select id="location" formControlName="location" placeholder=""
                                            class="form-select">
                                            <option selected value=""></option>
                                            <option>Chennai</option>
                                            <option>Bangalore</option>
                                            <option>Kochi</option>
                                        </select> -->


                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['location'].invalid && this.experienceInfoForm.controls['location'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]="experienceInfoForm.controls['empType'].invalid && experienceInfoForm.controls['empType'].touched  ? 'has-error' : '' ">
                                        <label for="empType" class="form-label label-txt modal-field-label" cursor="none">{{ 'WORK_BASICINFO.EMPLOYEETYPE' | customTranslate : 'Employee Type' }}*</label>
                                        <select id="empType" formControlName="empType" placeholder=""
                                            class="form-select">
                                            <option *ngFor="let et of empTypeDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                                        </select>
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['empType'].invalid && this.experienceInfoForm.controls['empType'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6"
                                        [class]=" experienceInfoForm.controls['dateOfStart'].invalid && experienceInfoForm.controls['dateOfStart'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="dateOfStart">{{ 'QUALIFICATION_INFO.DOS' | customTranslate : 'Date Of Start' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfStart"
                                            id="dateOfStart" name="date" format="dd-mm-yyyy" />
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['dateOfStart'].invalid && this.experienceInfoForm.controls['dateOfStart'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6"
                                        [class]=" experienceInfoForm.controls['dateOfEnd'].invalid && experienceInfoForm.controls['dateOfEnd'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="dateOfEnd">{{ 'QUALIFICATION_INFO.DOE' | customTranslate : 'Date Of End' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfEnd"
                                            id="dateOfEnd" name="date" format="dd-mm-yyyy" />
                                        <p class="error-msg"><span
                                                *ngIf="this.experienceInfoForm.controls['dateOfEnd'].invalid && this.experienceInfoForm.controls['dateOfEnd'].touched">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-footer border-0">
                        <div class="center-button text-right">
                            <div>{{ 'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button" }}*</div>
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''"
                                [fcBtnId]="''" (buttonClickEmit)="cancelButton()"></app-fusion-button>
                           <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                            buttonType="btn btn-primary input-button" [dataDismiss]="''"
                                (buttonClickEmit)="saveExperienceInfo(); hideAll.hidePlainView()"
                                [buttonDisable]="!experienceInfoForm.valid" ></app-fusion-button>
                        </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>