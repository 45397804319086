<div class="d-flex flex-column mt-1" style="padding-top: 45px">
  <app-cards #hideDocument [inlineDetailed]="true">
    <div class="header-section flex flex-column content-space-between">
      <span>{{'HEADINGS.DOCUMENTS' | customTranslate : 'Documents'  }}</span>
  </div>
  <div class="body-section card-text">
    <div class="card-btn show-detail-view text-primary" (click)="addDocument()" *ngIf="!isEditOn">
    + {{'DOCUMENTS_INFO.ADDDOCUMENT' | customTranslate : 'Add Document'  }}
    </div>
</div>
<div class="card-expanded-section detailed-section" plain>
  <div class="card-body">
    <form [formGroup]="documentsForm" autocomplete="off">
      <div class="field-sets">
        <div class="field-set-row flex">
          <div class="field-input col-md-4" [class]="this.documentsForm.controls['docType'].invalid && this.documentsForm.controls['docType'].touched ? 'has-error' : '' ">
            <!-- <input type="text" formControlName="docType" placeholder="Aadhaar"
                class="form-control" id="document-type"> -->
            <!--RG26AUG24 changed the label UI -->
            <label for="document-type" class="form-label label-txt modal-field-label" cursor="none">{{'DOCUMENTS_INFO.DOCTYPE' | customTranslate : 'Document Type'  }}*</label>
            <select id="document-type" formControlName="docType" class="form-select">
              <option *ngFor="let document of docDropdown.valueList" selected [value]="document.key">{{document.key}}</option>
            </select>
            <p class="error-msg">
              <span *ngIf="
                        this.documentsForm.controls['docType'].invalid &&
                        this.documentsForm.controls['docType'].touched
                      ">
                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
              </span>
            </p>
          </div>
          <div class="field-input col-md-4" [class]="this.documentsForm.controls['docID'].invalid && this.documentsForm.controls['docID'].touched ? 'has-error' : '' ">
            <label for="doc-ID" class="form-label label-txt modal-field-label" cursor="none">{{'DOCUMENTS_INFO.DOCID' | customTranslate : 'Document ID'  }}*</label>
            <input type="text" formControlName="docID" placeholder="DL0001213"
                class="form-control" id="doc-ID">
            <p class="error-msg">
              <span *ngIf="
                        this.documentsForm.controls['docID'].invalid &&
                        this.documentsForm.controls['docID'].touched
                      ">
                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
              </span>
            </p>
          </div>
          <div class="field-input col-md-4">
            <!-- Added document issue and expiry date -->
            <label for="docIssueDate" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.ISSUEDDATE' | customTranslate : 'Is issued on' }}*</label>
            <input type="date" formControlName="docIssuedDate" placeholder=""
                class="form-control" id="docIssueDate">
          </div>
          <div class="field-input col-md-4">
            <label for="docExpiryDate" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.EXPIRYDATE' | customTranslate : 'It expire on' }}*</label>
            <input type="date" formControlName="docExpiryDate" placeholder=""
                class="form-control" id="docExpiryDate">
          </div>
          <div class="field-input col-md-4">
            <!-- SK19FEB24 added new fields for documents -->
            <label for="issuedBy" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.ISSUEDBY' | customTranslate : 'Issued BY' }}*</label>
            <input type="text" formControlName="issuedBy" placeholder=""
                class="form-control" id="issuedBy">
          </div>
          <div class="field-input col-md-4">
            <label for="sponsor" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.SPONSOR' | customTranslate : 'Sponsor' }}*</label>
            <input type="text" formControlName="sponsor" placeholder=""
                class="form-control" id="sponsor">
          </div>
          <div class="field-input col-md-4">
            <!-- Added attribute [fileUploadId] in below line-->
            <app-file-upload [multiSelectFile]="false" [docVals]="['5 mb','jpg, png, svg, pdf, docs']" [showPreview]="'fileName'"  [acceptType]="'application/pdf,image/jpeg,image/jpg,image/png,image/svg+xml'" (documentEmitter)="fileType($event)" 
            (selectedFilesEmitter)="fileUpload($event)" fileUploadId="'docNewId'" [uploadReadyFiles]="filesArrayComp" (documentDelete)="deletedFileInComp($event)" (fileNameData)="fileNameArray($event)">
            <!-- 'showPreview' can have either of the values 'iFrame' (or)  'fileName' -->
            <!-- 'acceptType'  helps to partially restrict user to select only given file type, check the list for acceptable values -->
            </app-file-upload>
            <!-- <div class="mb-12" enctype="multipart/form-data">
              <label for="documentUpload" class="form-label fileUpload text-center">Select File
                <input class="form-control"  (change)="fileUpload($event)" type="file" id="documentUpload">
              </label>
              <div class="alert alert-success d-flex align-items-center" *ngIf="isFileuploaded" role="alert"
                style="padding:1px;font-size: small;">
                <svg-icon src="../assets/images/icons/general/verifiedtick.svg" style="padding:10px"></svg-icon>
  
                Your {{this.documentsForm.value.docType}} is uploaded successfully
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                  (click)="isFileuploaded=false" style="padding:8px;float:right"></button>
              </div>
              <p class="error-msg">
                <span *ngIf="
                          this.documentsForm.controls['file'].invalid &&
                          this.documentsForm.controls['file'].touched
                        ">
                  <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                </span>
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer border-0">
    <div class="center-button text-right">
      <app-fusion-button [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''" 
      [buttonValue]="  'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' " (buttonClickEmit)="docCancelButton()"></app-fusion-button>
      <app-fusion-button [buttonValue]="  'BUTTON_VALUE.SAVE' | customTranslate : 'Save' " (buttonClickEmit)="saveDocuments(); hideDocument.hidePlainView()"
      [buttonType]="'btn btn-primary ms-2'" [dataDismiss]="''" [buttonDisable]="!documentsForm.valid"
      [fcBtnId]="'submit'" >
      </app-fusion-button>

    </div>
  </div>
</div>
  </app-cards>
  <!-- <app-data-table *ngIf="docGetData" [data]="docGetData" [titleOn]="true" title="Upload Documents"
    [displayedColumns]="['type', 'id', 'uploadedBy']" [headerLabels]="['Type','Id','Uploaded By']"
    [pageSizeOptions]="[1, 3, 5,7]" [fromWhere]="'document-icons'" [actionButton]="true"
    (OnDeleteData)="onEditDoc($event)" (onDownload)="downloadDoc($event)">
  </app-data-table> -->
  <!-- <div class="" id="addDocument">
    <div class="card-btn show-detail-view text-primary" data-bs-toggle="modal" (click)="addDocument()"
      data-bs-target="#documentsInfoModal">
      + Add Document
    </div>
  </div> -->
</div>

<div class="col-md-12 mt-1" *ngFor="let doc of docList; let i=index">
  <div class="static-width-for-card width-100">
      <app-cards #hideAll [inlineDetailed]="true" [modalDeleted]="true" [modalId]="'documentModelDeleteId'">
        <div class="header-section flex content-space-between">
          <span>{{doc.type}}</span>
          <div class="header-section flex content-space-between">
              <div class="card-btn show-detail-view text-primary" *ngIf="!isEditOn">
                  <svg-icon src="../assets/images/icons/general/pencil-icon.svg" class="card-icon ms-3"
                      (click)="onEditDoc(doc)" style="cursor: pointer;"></svg-icon>
              </div>
              <div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#documentModelDeleteId">
                  <svg-icon src="../assets/images/icons/general/delete.svg" class="card-icon ms-3"
                      (click)="onDelete(doc)" style="cursor: pointer;"></svg-icon>
              </div>
          </div>
      </div>
      <div class="card-expanded-section modal-content modal-section">
        <div class="modal-header">
            <div class="modal-title fs-6" id="cardModalLabel">{{ 'DOCUMENTS_INFO.DELCONFIRMATION' | customTranslate : 'Delete Confirmation' }}</div>
            <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
            [fcBtnId]="'close'"></app-fusion-button>
        </div>

        <div class="modal-body">
            <p>{{'QUALIFICATION_INFO.DELETEINFO' | customTranslate : 'Are you sure you want to delete your'  }}  {{doc.type}}?</p>
        </div>
        <div class="modal-footer">
          <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL_THANKS' | customTranslate : 'No Thanks' }}"
          [buttonType]="'text-only'" [dataDismiss]="'modal'"
           (buttonClickEmit)="docCancelButton()" [fcBtnId]="'myId'" [dataDismiss]="'modal'"></app-fusion-button>
           <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.DELETE' | customTranslate : 'Delete' }}"
           [fcBtnId]="'modalSave'" (buttonClickEmit)="deleteDoc()"
           [dataDismiss]="'modal'"></app-fusion-button>
         </div>
    </div>
      <div class="body-section card-text">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row justify-content-start width-100">
              <!-- <svg-icon  src="../assets/images/icons/general/preview.svg"  data-bs-toggle="modal" data-bs-target="#previewModel" (click)="previewFile(doc.document)"
                  class="card-icon ms-3 align-self-center"></svg-icon> -->
                  <!-- SK19FEB24 cards modified for displaying new fields -->
              <!-- <iframe *ngIf="doc.document.file !== null" [src]="safeUrl(doc.document.file)" width="100" height="100"></iframe> -->
              <div class="flex flex-column w-100 ms-2">
                <div class="row flex">
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.DOCTYPE' | customTranslate : 'Document Type'  }}</label>
                    <label class="label-sub">{{doc.type || "-"}}</label>
                  </div>
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.DOCID' | customTranslate : 'Document ID'  }}</label>
                    <label class="label-sub">{{doc.id || "-"}}</label>
                  </div>
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.UPLOADBY' | customTranslate : 'Uploaded by'  }}</label>
                    <label class="label-sub">{{doc.uploadedBy || "-"}}</label>
                  </div>
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.ISSUEDDATE' | customTranslate : 'Is issued on'  }}</label>
                    <label class="label-sub">{{doc.docIssuedDate | date: "mediumDate" || "-"}}</label>
                  </div>
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.EXPIRYDATE' | customTranslate : 'It expire on'  }}</label>
                    <label class="label-sub">{{doc.docExpiryDate  | date: "mediumDate" || "-"}}</label>
                  </div>
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.ISSUEDBY' | customTranslate : 'Issued BY'  }}</label>
                    <label class="label-sub">{{doc.issuedBy || "-"}}</label>
                  </div>
                  <div class="col-3 mb-3 flex flex-column width-25">
                    <label class="label-head">{{'DOCUMENTS_INFO.SPONSOR' | customTranslate : 'Sponsor'  }}</label>
                    <label class="label-sub">{{doc.sponsor || "-"}}</label>
                  </div>
                  <!-- <div class="col-md-4 label-sub">Document ID/Number: {{doc.id}}</div>
                  <div class="col-md-4 label-sub">Uploadedy by: {{doc.uploadedBy}}</div>
                  <div class="col-md-4 label-sub">Issued Date: {{doc.docIssuedDate}}</div>
                  <div class="col-md-4 label-sub">Expiry Date: {{doc.docExpiryDate}}</div>
                  <div class="col-md-4 label-sub">Issued by: {{doc.issuedBy}}</div>
                  <div class="col-md-4 label-sub pad-bottom">Sponsor: {{doc.sponsor}}</div> -->
                  <!-- <app-fusion-button [buttonValue]="'Download'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'" [fcButtonBorder]="'5px'"   (click)="downloadDoc(doc)"></app-fusion-button> -->
              </div>
              <div class="flex" *ngIf="doc.document.file != null" >
                <svg-icon src="../assets/images/icons/general/download-icon.svg" class="card-icon color" title="download"
                (click)="downloadDoc(doc)" style="cursor: pointer"></svg-icon>
                <svg-icon src="../assets/images/icons/general/preview.svg" class="card-icon color" title="preview" data-bs-toggle="modal" data-bs-target="#previewModel" (click)="previewFile(doc.safeUrl)"
                  style="cursor: pointer;"></svg-icon>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="card-expanded-section detailed-section" plain>
      <div class="card-header">
          <div class="modal-title fs-6" id="cardDetailedModalLabel">{{'DOCUMENTS_INFO.EDITDOC' | customTranslate : 'Edit document details'  }}</div>
      </div>
      <div class="card-body">
        <form class="documents-form flex flex-direction-column" style="padding: 20px;" [formGroup]="documentsForm" autocomplete="off">
          <div class="field-sets">
            <div class="field-set-row flex">
              <div class="field-input col-md-4" [class]="this.documentsForm.controls['docType'].invalid && this.documentsForm.controls['docType'].touched ? 'has-error' : '' ">
                <label for="document-type" class="form-label label-txt modal-field-label" cursor="none">{{'DOCUMENTS_INFO.DOCTYPE' | customTranslate : 'Document Type'  }}*</label>
                <select id="document-type" formControlName="docType" class="form-select">
                  <option *ngFor="let document of docDropdown.valueList" selected [value]="document.key">{{document.value}}</option>
                </select>
                <p class="error-msg">
                  <span *ngIf="
                            this.documentsForm.controls['docType'].invalid &&
                            this.documentsForm.controls['docType'].touched
                          ">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                </p>
              </div>
              <div class="field-input col-md-4" [class]="this.documentsForm.controls['docID'].invalid && this.documentsForm.controls['docID'].touched ? 'has-error' : '' ">
                <label for="docPatch-ID" class="form-label label-txt modal-field-label" cursor="none">{{'DOCUMENTS_INFO.DOCID' | customTranslate : 'Document ID'  }}*</label>
                <input type="text" formControlName="docID" placeholder="DL0001213"
                    class="form-control" id="docPatch-ID">
                <p class="error-msg">
                  <span *ngIf="
                            this.documentsForm.controls['docID'].invalid &&
                            this.documentsForm.controls['docID'].touched
                          ">
                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                  </span>
                </p>
              </div>
              <div class="field-input col-md-4">
                <!-- // SK17JAN24 added doc issue and expiry date -->
                <label for="docIssuedDate" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.ISSUEDDATE' | customTranslate : 'Is issued on' }}*</label>
                <input type="date" formControlName="docIssuedDate" placeholder=""
                    class="form-control" id="docIssuedDate">
              </div>
              <div class="field-input col-md-4">
                <label for="docExpiryDate" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.EXPIRYDATE' | customTranslate : 'It expire on' }}*</label>
                <input type="date" formControlName="docExpiryDate" placeholder=""
                    class="form-control" id="docExpiryDate">
              </div>
              <div class="field-input col-md-4">
                <!-- SK19FEB24 added new fields -->
                <label for="issuedBy" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.ISSUEDBY' | customTranslate : 'Issued BY' }}*</label>
                <input type="text" formControlName="issuedBy" placeholder=""
                    class="form-control" id="issuedBy">
              </div>
              <div class="field-input col-md-4">
                <label for="sponsor" class="form-label label-txt modal-field-label" cursor="none">{{ 'DOCUMENTS_INFO.SPONSOR' | customTranslate : 'Sponsor' }}*</label>
                <input type="text" formControlName="sponsor" placeholder=""
                    class="form-control" id="sponsor">
              </div>
              <div class="field-input col-md-4">
                <div class="mb-12">
                  <!-- Added attribute [fileUploadId] in below line-->
                  <app-file-upload [multiSelectFile]="false" [docVals]="['5 mb','jpg, png, svg, pdf, docs']" [showPreview]="'fileName'"  [acceptType]="'application/pdf,image/jpeg,image/jpg,image/png,image/svg+xml'" (fileNameData)="fileNameArray($event)"
                 (selectedFilesEmitter)="fileUpload($event)" (documentEmitter)="fileType($event)" fileUploadId="'docEditId'" (documentDelete)="deletedFileInComp($event)" [uploadReadyFiles]="filesArrayComp">
                  <!-- 'showPreview' can have either of the values 'iFrame' (or)  'fileName' -->
                 <!-- 'acceptType'  helps to partially restrict user to select only given file type, check the list for acceptable values -->
                 </app-file-upload>
                 <!-- Added error message call in below line-->
                 <p class="error-msg" *ngIf="errorMessage"><span>{{ errorMessage }}</span></p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer border-0">
        <div class="center-button text-right">
          <app-fusion-button buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  }}"
          [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''"
       (buttonClickEmit)="docCancelButton()"></app-fusion-button>
          <app-fusion-button buttonValue="{{'BUTTON_VALUE.SAVE' | customTranslate : 'Save'  }}" 
          [buttonType]="'btn btn-primary ms-2'"
          [dataDismiss]="''" [buttonDisable]="!documentsForm.valid"
          [fcBtnId]="'submit'" (buttonClickEmit)="saveDocuments(); hideDocument.hidePlainView()"></app-fusion-button>
        </div>
      </div>
    </div>
      </app-cards>
  </div>
</div>


<div class="modal fade container-sm" id="previewModel" tabindex="-1" aria-labelledby="previewModel"
  aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-6" id="documentsModalLabel">{{ 'DOCUMENTS_INFO.DOCINFO' | customTranslate : 'Document Info' }}</div>
        <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
        [fcBtnId]="'close'" (buttonClickEmit)="safeSrc = '' ">
        </app-fusion-button>

      </div>
      <div class="modal-body">
        <iframe *ngIf="safeSrc" [src]="safeSrc" width="100%" height="600"></iframe>
      </div>
    </div>
  </div>
</div>