import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-fusion-button',
  standalone: true,
  imports: [CommonModule,AngularSvgIconModule, CustomTranslatePipe],
  templateUrl: './fusion-button.component.html',
  styleUrls: ['./fusion-button.component.css']
})
export class FusionButtonComponent implements OnInit {
 
  @Input() buttonValue: string = '';
  @Input() buttonType: string = 'primary';
  @Input() buttonDisable: boolean = false;
  @Input() fcBtnBgColor: string = '';
  @Input() fcBtnBorder: string = '';
  @Input() fcBtnTextColor: string = '';
  @Input() fcBtnHeight: string = '';
  @Input() fcBtnWidth: string = '';
  @Input() fcBtnIconLeftSrc: string = '';
  @Input() fcBtnIconRightSrc: string = '';
  @Input() fcBtnHoverBgColor: string = '';
  @Input() fcBtnHoverTextColor: string = '';
  @Input() fcBtnId: string = 'default';
  @Input() dataDismiss: string = '';
  @Input() fcButtonBorder: string = '0px';
  @Input() modalToTrigger: string = '';
  @Input() isImageSvg: boolean = false;
  @Input() tabIndex: any = '';

  @Output() buttonClickEmit:EventEmitter<any>
  = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  buttonClicked($event:any){
    this.buttonClickEmit.emit($event);
  }

}
