import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Modal } from 'bootstrap';

// Third party plugins 
import { AngularSvgIconModule } from 'angular-svg-icon'; /* To conver img tag to svg decoded format */ 

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  isDetailedView: boolean = false;

  @Input() modalDetailed:boolean = false;
  @Input() inlineDetailed:boolean = false;
  @Input() modalDeleted:boolean = false;
  @Input() modalId: string = "cardModal";

  @HostListener('click', ['$event'])
  onClick(clickEvent:any) {
    if(this.inlineDetailed){
      
      if(clickEvent.target.closest('.card-btn')?.classList.value.indexOf('show-detail-view') >= 0 || clickEvent.target.parentElement.classList.value.indexOf('show-detail-view') >= 0){
        this.isDetailedView = true;
      }
      else if(clickEvent.target.closest('.card-btn')?.classList.value.indexOf('hide-detail-view') >= 0 || clickEvent.target.parentElement.classList.value.indexOf('hide-detail-view') >= 0){
        this.isDetailedView = false;
      }
    }

    if(clickEvent.target.classList.value.indexOf('show-detail-view') != -1 && this.modalDetailed){
      new Modal(document.getElementById('cardDetailedModal') as HTMLElement).show()
    }
  }

  constructor() { }

  ngOnInit(): void {
    
  }

  hidePlainView(){
    this.isDetailedView = false;
  }

  ngAfterViewInit(){
   
  }
  
}
