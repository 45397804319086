
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, isDevMode, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PopoverComponent } from '../popover/popover.component';
import { EmpnotifyComponent } from '../empnotify/empnotify.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FileUploadComponent } from '../file-upload/file-upload.component';
//Services
import { SideNavEmitService } from '../../services/side-nav-emit.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SuccessFailureMessageComponent } from '../alert-message/success-failure-message.component';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { TranslateModule } from '@ngx-translate/core';
import { NbLayoutModule } from '@nebular/theme';
import { FusionFrameworkService } from 'src/app/services/fusion-framework.service';
import { Constants } from 'src/app/models/Constants';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';//RS04SEP24
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [CommonModule, PopoverComponent, EmpnotifyComponent,DropDownComponent, AngularSvgIconModule, FileUploadComponent, FusionButtonComponent, SuccessFailureMessageComponent, MatTooltipModule, NbLayoutModule, RouterModule, NgxSpinnerModule,TranslateModule,CustomTranslatePipe],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  parentApp: any;
  empNotification: any;
  leaveNotification: any;
  notifyPopup = false;
  firstName: any;
  lastName: any;
  companyName: any;
  showSettings = false;
  showHrm = false;
  notifyLength!: number;
  count: number = 0;
  appliedCounts: any;
  errorMessage: any;
  companyLogo: any;

  // isModalVisible = true;

  showSubmissionMsg: any;
  showDeletedMsg: any;
  showErrorMsg: any;
  isCompanyLogo = false;
  isEditIcon = true;
  showUrl: any;
  pageFrom = '';
  isDevMode: boolean = false;
  companyNameLetter: any;
  filesArrayComp: any;
  logoFile: any;
  essUser: any;
  currentLang : any;
  frameWorkService: Subscription; // THIS CODE TO BE USED IN FUTURE FOR JSON BASED FRAMEWORK. DON'T REMOVE IT

  @Output() clickedNav: EventEmitter<boolean> = new EventEmitter<boolean>();
  sideNavsubscription: Subscription = new Subscription;
  private clickListener!: (event: MouseEvent) => void;
  loggedInUserData: any;
  configJSON: Array<any> = [];
  activeApp : any;
  typeSelected! : string; //SK06MAR24
  languageCode:any; //SK24JUN24
  showLanguageDropdown: boolean = false;//RS04SEP24
  languageDropdown: Array<any> = [];//RS04SEP24

  constructor(@Inject(DOCUMENT) private _document: Document,private sideNavItem: SideNavEmitService, private router: Router, private globalValues: GlobalValuesService, private translate : TranslateService, private apiService: ApiService, private errorHandler: ErrorHandlerService, private sanitizer: DomSanitizer, private alretService: AlertService, private readonly elementRef: ElementRef, private authServ: AuthService, private routeActive: ActivatedRoute, private fusionFrameWork: FusionFrameworkService, private spinnerService: NgxSpinnerService, private route: ActivatedRoute) {
  

//RS28AUG24
console.log(localStorage)
// Ensure the router's URL is correctly split and only used if defined
this.parentApp = this.router.url.split('/')[1] ? this.router.url.split('/')[1].split('?')[0] : 'defaultApp';

// If the localStorage item is missing, provide a default value
// const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24
// if(userData) {
//   userData.languageCode = localStorage.getItem('language') || userData.languageCode;  //SKS11OCT24 Update the languageCode in the userData object
//   localStorage.setItem('userData', JSON.stringify(userData));
//   this.languageCode = localStorage.getItem('language') || userData.languageCode || "en";
//   this.currentLang = localStorage.getItem('language') || userData.languageCode || "en";
// }else{
//   this.currentLang = localStorage.getItem('language') || 'en';
//   this.languageCode = this.currentLang;
// } 

// Subscribe to route parameters
this.routeActive.paramMap.subscribe(params => {
  this.activeApp = params.get('appName');
});

    // this.parentApp = this.router.url.split('/')[1].split('?')[0];
    // this.currentLang = localStorage.getItem('language');
    // this.routeActive.paramMap.subscribe(params => {
    //   this.activeApp = params.get('appName');
    // });

    //THIS CODE TO BE USED IN FUTURE FOR JSON BASED FRAMEWORK. DON'T REMOVE IT
    this.frameWorkService = this.fusionFrameWork.appJSONData$.subscribe((apps: any) => {
      console.log('Apps from Backend ' + apps.length);
      this.configJSON = apps;
    });

    // MR 11DEC23 
    this.globalValues.userGet();
    // SK06MAR24 spinner type
    this.typeSelected = 'ball-spin-clockwise-fade';

    // SK24JUN24 language code update in db while changing in url
    this.routeActive.paramMap.subscribe((params:any) => {
      this.apiService.writeValue("post","/user/setlanguage", {languageCode:this.languageCode || "en"}).subscribe({
        next:(data:any)=>{
          // this.successMessageFunction(data);
        },
        error:(err:HttpErrorResponse)=>{
          // this.errorMessageFunction(err.message);
        }
      })
    })
  }

  //RS04SEP24
  ngOnInit(): void {
    this.essUser = this.authServ.user.includes('ESS User');
    this.getCompanyDatas();
    // RS28AUG24: Sync dropdown with URL language
    this.routeActive.paramMap.subscribe(params => {
      this.languageCode = params.get('languageCode') || "en";
      this.currentLang = this.languageCode;
      const dropDowns = JSON.parse(localStorage.getItem('settingsData') as any);
      // Filter for the 'languages' dropdown
      //searches for a dropdown named 'languages' within a nested dropDowns object and assigns its valueList to languageDropdown. If the languageDropdown array is not empty, it sets showLanguageDropdown to true, making the language dropdown visible.
      const showLanguageDD = dropDowns?.dropdowns?.find((dropdown: any) => dropdown.name === 'languages')
      this.languageDropdown = showLanguageDD?.valueList || []
      if (dropDowns && this.languageDropdown.length > 0) {
        this.showLanguageDropdown = true

      } else if(dropDowns) {
        localStorage.setItem('language', "en");
        this.languageCode = "en";
        this.currentLang = this.languageCode;
        this._document.documentElement.setAttribute('dir', 'ltr');
        this._document.documentElement.setAttribute('lang', this.languageCode);
      }

    });
  

    if(this.loggedInUserData == undefined) {
      console.log('loggedInUserData is undefined fetching from the localstorage');
      const userDataConst:any = localStorage.getItem('userData');
      if(userDataConst) {
        this.loggedInUserData = JSON.parse(userDataConst);
        this.configJSON = this.loggedInUserData.apps;
        // this.languageCode = this.loggedInUserData.languageCode || "en";
        this.processHeaderInfo();
      } else {
        console.log('waiting for the api response');
      }
      const settingsDataConst:any = localStorage.getItem('settingsData');
      if(settingsDataConst) {
        const setData = JSON.parse(settingsDataConst);
        if (setData.companyLogo === '' || setData.companyLogo === undefined) {
          this.isCompanyLogo = false;
        } else {
          this.isCompanyLogo = true;
        }
        this.companyLogo = this.sanitizer.bypassSecurityTrustUrl(setData.companyLogo)
      } else {
        console.log('waiting for the api response');
      }
    }

    this.clickListener = this.handleDocumentClick.bind(this);
    document.addEventListener('click', this.clickListener);
  }

  getCompanyDatas() {
    // this.globalValues.globalValues();
    // MR 07DEC23 Replacing the /user call with Global Values
    // this.apiService.fetchValue('/user').subscribe((datas: any) => {
    console.log('inside getCompanyDatas() with ' + this.loggedInUserData);
    this.globalValues.loggedInuserDetails$.subscribe((userData: any) => {
      console.log('inside getCompanyDatas() after loggedInuserDetails subscribe ' + userData.apps.length);
      window.location.reload()
      this.fusionFrameWork.getJSON(userData.apps);
      // this.fusionFrameWork.getJSON(Constants.configJSON);
      this.loggedInUserData = userData;
      this.processHeaderInfo();
    });
    this.showUrl = this.router.routerState.snapshot.url.includes('/settings') && this.globalValues.superUser;
  }

  // languageOptions = [
  //   { label: 'English', value: 'en' },
  //   { label: 'தமிழ்', value: 'ta' },
  //   { label: 'हिंदी', value: 'hi' },
  //   { label: 'عربي', value: 'ar' }
  // ];

  //SKS11OCT24 const labelValues = this.languageOptions.map(option => option.label);
  emittedValue(selectedItem: any, languageCode: string) {
    if (selectedItem && selectedItem.data.value) {
      this.currentLang = selectedItem.data.value;
      this.languageCode = selectedItem.data.value;
      //SKS11OCT24 Update language in the translation service
      this.translate.use(this.currentLang);
      //SKS11OCT24 update language in backend
      this.updateLanguageInBackend(this.languageCode);
      const userData = JSON.parse(localStorage.getItem('userData') as any);
      userData.languageCode = this.languageCode;  //SKS11OCT24 Update the languageCode in the userData object
      localStorage.setItem('userData', JSON.stringify(userData)); //SKS11OCT24 Save the updated object back to localStorage
      //SKS11OCT24 Update the URL with the selected language
      const currentUrl = this.router.url;
      //SKS11OCT24 URL has path structure, modify the path to include the language
      const modifiedUrl = this.getModifiedPathUrl(currentUrl, this.languageCode);
      this.router.navigateByUrl(modifiedUrl)
    }
  }
  //SKS11OCT24 Helper function to modify path-based URLs
  getModifiedPathUrl(url: string, selectedLang: string): string {
    const dropDowns = JSON.parse(localStorage.getItem('settingsData') as any);
    if(dropDowns){
      const langlist = dropDowns.dropdowns?.find((item: { name: string; }) => item.name === 'languages')?.valueList.map((item: { value: any; }) => item.value) || [];
      //SKS22OCT24 Split the URL into path and query components
      const [path, queryString] = url.split('?');
      //SKS22OCT24 Split the path by '/'
      const parts = path.split('/');
      // SKS11OCT24 Check if the language exists in the path
      const languageIndex = parts.findIndex(part =>
        langlist.includes(part)
      );
      if (languageIndex !== -1) {
        // SKS11OCT24 Replace the existing language with the selected language
        parts[languageIndex] = selectedLang;
      } else {
        // SKS11OCT24 Insert the language in the second position if not found
        if (parts[0] === '') {
          parts.splice(1, 0, selectedLang); // Root path
        } else {
          parts.splice(0, 0, selectedLang); // Non-root path
        }
      }
      //SKS22OCT24 Reconstruct the path
      const modifiedPath = parts.join('/');
      //SKS22OCT24 If no query string exists, return just the modified path
      if (!queryString) {
        return modifiedPath;
      }
      //SKS22OCT24 If query string exists, preserve it
      return `${modifiedPath}?${queryString}`;
    }
    return url
  }
  

  processHeaderInfo() {
    this.showSetting();
    this.firstName = this.loggedInUserData.firstName?.charAt(0);
    this.lastName = this.loggedInUserData.lastName?.charAt(0);
    this.companyName = this.loggedInUserData.companyName;
    this.companyNameLetter = this.companyName?.charAt(0);
    // if (this.loggedInUserData.logo === '' || this.loggedInUserData?.logo === undefined) {
    //   this.isCompanyLogo = false;
    // } else {
    //   this.isCompanyLogo = true;
    // }
    // this.companyLogo = this.sanitizer.bypassSecurityTrustUrl(this.loggedInUserData?.logo)
  }

  saveSuccess() {
    this.getCompanyDatas();
    this.alretService.messageOnPass('success', 'Logo updated successfully')
  }

  showError(err: any) {
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alretService.messageOnPass('error', this.errorMessage)
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sideNavsubscription.unsubscribe();
    document.removeEventListener('click', this.clickListener);
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem("expires_at");
    localStorage.removeItem('userData'); // MR 07DEC23 LocalStorage
    this.router.navigateByUrl('login', { replaceUrl: true }).then(() => {
      // Reload the login component to ensure it resets completely
      window.location.reload();
    });
    localStorage.setItem("language",this.currentLang)
    localStorage.removeItem("auth_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("username");
    localStorage.removeItem("checkbox");
    this.globalValues.superUser = false;
    this.globalValues.hrmUser = false;
    this.globalValues.hrmManager = false;
  }

  onclickHamburger() {
    // this.clickedNav.emit(true);
    this.sideNavItem.clickedOnHamburger('hamburger');
  }

  // MR 15DEC23 Apps Change
  goToPage(appPage: any) {
    if (this.parentApp != appPage.name && appPage.name !== 'app') {
      this.router.navigate([`${appPage.name}`,`${this.languageCode || "en"}` ]);
    } else if (appPage.name === 'app') {
      this.router.navigate([`${appPage.name}`,`${this.languageCode || "en"}`, `${appPage.appName}`, `${appPage.moduleName}`, `${appPage.tabName}`]);
      // this.fusionFrameWork.getJSON(this.loggedInUserData.apps);
      // this.fusionFrameWork.getJSON(Constants.configJSON);
    }
  }
  showPage() {
    this.router.navigate(['/settings', this.languageCode || "en"], {
      queryParams: {
        pageFrom: 'PageHeader'
      }
    });
  }

  popOverItemClick() { }

  rolesArray: Array<any> = [];
  showSetting() {
    const datas = this.loggedInUserData.permissions;
    this.rolesArray = datas;
    datas.forEach((element: any) => {
      if (element.includes('Super User')) {
        this.showSettings = true;
      }

      if (element.includes('HRM User') || element.includes('HRM Manager')) {
        this.showHrm = true;
      }
    });
  }

  notifyCount(item: any) {
    this.count = item;
    if (this.isDevMode) console.log(item);
  }
  appliedCount(value: any) {
    this.appliedCounts = value;
    if (this.isDevMode) console.log(value);
  }

  // on click upload, the file will be sent to database
  clickedButton(event: any) {
    const companyId = this.globalValues.orgId;
    this.apiService.writeValue('patch', '/settings/logo', { 'logo': this.logoFile, 'companyName': this.companyName, 'companyId': companyId })
      .subscribe({
        next: (logoResult) => {
          if (this.isDevMode) console.log("Next :", logoResult);
          this.saveSuccess();
          this.filesArrayClear();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
  }

  // the emitted file is stored in variable
  fileEmitted(evt: any) {
    this.logoFile = evt[0];
  }

  // this is to clear the filename array in child component
  filesArrayClear() {
    this.filesArrayComp = [];
  }
  private handleDocumentClick(event: MouseEvent): void {
    const isClickInside = this.elementRef.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.notifyPopup = false;
    }
  }
  

  private updateLanguageInBackend(languageCode: string) {
    this.apiService.writeValue('post', '/user/setlanguage', { languageCode }).subscribe({
      next: (data: any) => {
        console.log('Language updated in backend:', data);
      },
      error: (err: HttpErrorResponse) => {
        console.error('Error updating language:', err.message);
      },
    });
  }

  refreshLocal(){
    // SK06MAR24 refreshing user Data in local storage
    // this.spinnerService.show();
    this.globalValues.userGetAPI();
  }

  /*
  saveJSONObject(){
    // const configJson = this.configJSON;
    const configJson = Constants.configJSON;
    this.apiService.writeValue('patch', '/user/appConfig', { appConfig: configJson, userId: this.globalValues.userId, _id: this.objectId, companyId: this.globalValues.orgId })
      .subscribe({
        next: (appConfig) => {
        },
        error: (err: HttpErrorResponse) => {
        }
    });
  }
  */
}