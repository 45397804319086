<div class="d-flex justify-content-end button-top">
    <!-- RS27AUG24 -->
    <!-- Used the translate pipe to successfully implement the translation -->
    <div class="pe-2">
    <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'" 
    [buttonDisable]="downloadCurrentDisable" 
    (buttonClickEmit)="downloadCurrentEmployees()"
    [buttonValue]="'EXPORT'| customTranslate : 'Export'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
    </app-fusion-button>
    </div>
    <app-fusion-button class="me-2 align-self-center" buttonValue="{{ 'DOWNLOAD_PAYSLIP' | customTranslate : 'Download Payslip(s)' }}"
        (buttonClickEmit)="downloadButton()" [buttonDisable]="disableButton"></app-fusion-button>
    <app-drop-down [lists]='dropDownList'  [selectedData]="dropDownValue" dropDownHeight="42px" dropFontWeight="500"
        dropFontSize="12px" dropFontColor="#434555" dropBorderRadius="5px" dropDownWidth="200px" (dataEmit)="emittedValue($event)"></app-drop-down>
</div>
<div class="d-grid gap-2 d-md-flex justify-content-md-end">
    
  </div>
  <!-- SK18JUN24 for dynamic columns added configJSON variable -->
<app-data-table #dataTableRef *ngIf="employeeDataTable" [data]="employeeDataTable"
    [displayedColumns]="displayedColumns"
    [headerLabels]="headers" [translatePath]="'PAY_SLIPS_TAB_TABLE'" (selectedValues)="tableCheckedDataa($event)"
    [withCheckBox]="true" [stickyColumn]="true" tableWidth="this.configJSON?.tableWidth || 300%"
    [filterColumns]="configJSON?.filterColumns || []"
    [cumulativeDataColumns]="cumulativeDataColumns"
    [multipleFilter]="multipleFilterArray"
    (selectedValues)="selectedCheckBox($event)" (filteredArray)="searchFilterData($event)" fromWhere="paySlip">
</app-data-table>