<div class="card" [ngClass]="{ 'rtl': isRtl }">
    <div class="bodySection">
        <div class="leftSection">
            <img src="../assets/images/icons/general/login-banner.png"  class="leftImg" alt="" />
        </div>
        <div *ngIf="resetPasswordScreen" class="rightSection">
            <div class="langButton" >  
                <app-drop-down 
                    [lists]="languageOptions" 
                    [selectedData]="currentLang"
                    dropDownWidth="90px"
                    dropBorderRadius="5px"
                    [objectDisplayKey]="'label'"
                    (dataEmit)="emittedValue($event)"
                    class="custom-dropdown-position">
                </app-drop-down> 
            </div>
            <div class="imgSection">
                <div class="heading">{{ translations[currentLang].LOGIN_PAGE.PASSWORD_RESET}}</div>
                <div class="heading-content">{{ translations[currentLang].LOGIN_PAGE.ENTER_NEW_PASSWORD}}</div>
            </div>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                <div class="reset-form">
                    <div class="input">
                        <fieldset class="field-set">
                            <div class="form-field input-new-password">
                                <label class="input-pwd">{{ translations[currentLang].LOGIN_PAGE.NEW_PASSWORD}}</label>
                                <div class="password-input password">
                                   <input type='password' formControlName="createPassword" placeholder="{{ translations[currentLang].LOGIN_PAGE.PASSWORD}}" (keyup)="handlePasswordChange($event)" (focusout)="handlePasswordChange($event)"/>
                                </div>
                            </div>
                            <p class="errorContent">
                                
                                <span [style.visibility]=" errorCreatePasswordMsg ? 'visible' : 'hidden'">
                                    <img src="../assets/images/icons/general/information-icon (2).svg"/>
                                    {{ translations[currentLang].LOGIN_PAGE.PASSWORD_ERROR}}</span>
                            </p>
                            <div class="form-field input-new-password">
                                <label class="input-pwd"> {{ translations[currentLang].LOGIN_PAGE.CONFIRM_PASSWORD}}</label>
                                <div class="password-input password">
                                   <input type='password' formControlName="confirmPassword"
                                   placeholder="{{ translations[currentLang].LOGIN_PAGE.PASSWORD}}"(keyup)="handleConfirmPasswordChange($event)" (focusout)="handleConfirmPasswordChange($event)" />
                                </div>
                            </div>
                            <p class="errorContent">
                                <span [style.visibility]=" errorConfirmPasswordMsg ? 'visible' : 'hidden'"> {{ translations[currentLang].LOGIN_PAGE.PASSWORD_MISMATCH}}</span>
                            </p>
                        </fieldset>
                    </div>
                    <!-- Add login Error message here -->
                    <p class="errorContent errcontentSize"><span *ngIf="resetPasswordError">{{resetPasswordError}}</span></p>
                               
                    <div class="form-buttons form-button-section" >
                        <button type="submit" [class]="resetPasswordButton">{{ translations[currentLang].LOGIN_PAGE.PASSWORD_RESET}}</button>
                    </div>
                </div>
            </form>
            <p class="app-version non-menu">{{currentApplicationVersion}}</p>
        </div>
        <div *ngIf="!resetPasswordScreen" class="reset-success">
            <div class="reset-head-message">{{ translations[currentLang].LOGIN_PAGE.WELL_DONE}}</div>
            <div class="reset-sub-message">{{ translations[currentLang].LOGIN_PAGE.PASSWORD_CHANGE_SUCCESS}}</div>
            <button type="submit" [class]="resetPasswordButton" (click)="goToPage('login')" >{{ translations[currentLang].LOGIN_PAGE.LOGIN}}</button>
        </div>
    </div>
    
    
    