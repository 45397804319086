import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-common-screen',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './common-screen.component.html',
  styleUrls: ['./common-screen.component.css']
})
export class CommonScreenComponent implements OnInit {

  @Input() headerTitle: string = '';
  @Output() sideScreenEmittedEvent  = new EventEmitter<{}>;

  constructor() { }

  ngOnInit(): void {
  }

}
