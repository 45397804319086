<div class="modal fade container-md" [class]="isModalOpen ? 'show d-block modal-bg' : 'd-none' " tabindex="1" 
  aria-hidden="true">
  <div class="modal-dialog {{modalSize}}">
    <div class="modal-content">
      <div class="modal-header">
        <b class="modal-title fs-5 mb-0" id="openModal">{{modalTitle}}</b>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ng-content select=".modal-body"></ng-content>
      </div>
      <div class="modal-footer" *ngIf="modalFooter">
        <app-fusion-button buttonType="btn text-only ms-2" (buttonClickEmit)="closeModal()"
            data-bs-dismiss="modal" buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'}}">
        </app-fusion-button>
        <app-fusion-button buttonType="btn ms-2" (buttonClickEmit)="saveModal()"
            data-bs-dismiss="modal" [buttonValue]="'SAVE' | customTranslate : 'Save'">
        </app-fusion-button>
      </div>
    </div>
  </div>
</div>