<div class="form-card">
    <div class="col-md-12 mt-3">
        <div class="static-width-for-card width-100">
            
            <div class="flex flex-row justify-content-between">
                {{ 'LEAVE BALANCE' | customTranslate : 'Leave Balance' }}
                <app-fusion-button buttonValue="{{ 'APPLY LEAVES' | customTranslate : 'Apply Leave' }}"[buttonType]="'btn btn-primary'" 
                data-bs-toggle="modal" data-bs-target="#apply-leave" >
                </app-fusion-button>
            </div>
            <div class="d-flex overflow-auto">
                <!-- <div *ngIf="noOfCasualLeave > 0" class="col col-md-4 border-primary rounded-3 align">
                    <div class="row d-flex border-primary rounded-3 align-items-center leave-set-height">
                        <div class="col">
                           <b> {{ 'CASUAL_LEAVE' | customTranslate : 'Casual Leave' }} </b>
                        </div>
                        <div class="col border-primary rounded-3 leave-balance-margin">
                            <div class = "d-flex justify-content-center text-primary"><b>{{noOfCasualLeave}}</b></div>
                            <div class="balance-text">  {{ 'LEAVE BALANCE' | customTranslate : 'Leave Balance' }}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="noOfSickLeave > 0" class="col col-md-4 border-primary rounded-3 leave-margin">
                    <div class="row d-flex border-primary rounded-3 align-items-center leave-set-height">
                        <div class="col">
                           <b>{{ 'SICK_LEAVE' | customTranslate : 'Sick Leave' }}</b>
                        </div>
                        <div class="col border-primary rounded-3 leave-balance-margin">
                            <div class = "d-flex justify-content-center text-primary"><b>{{noOfSickLeave}}</b></div>
                            <div class="balance-text">  {{ 'LEAVE BALANCE' | customTranslate : 'Leave Balance' }}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="noOfEarnedLeave > 0" class="col border-primary rounded-3 leave-margin">
                    <div class="row d-flex border-primary rounded-3 align-items-center leave-set-height">
                        <div class="col">
                           <b>{{ 'EARNED_LEAVE' | customTranslate : 'Earned Leave' }}</b>
                        </div>
                        <div class="col border-primary rounded-3 leave-balance-margin">
                            <div class = "d-flex justify-content-center text-primary"><b>{{noOfEarnedLeave}}</b></div>
                            <div class="balance-text">  {{ 'LEAVE BALANCE' | customTranslate : 'Leave Balance' }}</div>
                        </div>
                    </div>
                </div> -->
                <!-- SK19JUL24 display all allocated leave types -->
                <div *ngFor="let leave of templateGet" class="col border-primary rounded-3 leave-margin">
                    <div class="row d-flex border-primary rounded-3 align-items-center leave-set-height">
                        <div class="col">
                           <b>{{leave.leaveType}}</b>
                        </div>
                        <div class="col border-primary rounded-3 leave-balance-margin">
                            <!-- RS27AUG24 -->
                            <!-- Used the translate pipe to successfully implement the translation -->
                            <div class="balance-text">{{'LEAVE BALANCE' | customTranslate : 'Leave Balance' }} <p class="blue-text">{{leave.noOfDays}}</p></div>
                            <div class="balance-text">{{'Initial Leave' | customTranslate : 'Initial Leave'  }} <p class="blue-text">{{ leave.initialLeave ?? 0 }}</p></div> <!-- SKS16OCT24 -->
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <!-- to show leave history // SK17JAN24 reducing api call-->
            <app-Leave-history #leaveHistory (leavesEmitData)="getData()" [selectedEmployeeId]="this.employeeId" [employeeLeaves]="employeeLeaves"> </app-Leave-history> 
            <!-- Apply leave Smodal -->
            <div class="modal fade container-md" id="apply-leave" tabindex="-1" aria-labelledby="applyLeaveLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal-title fs-5" id="applyLeaveLabel">{{ 'APPLY LEAVES' | customTranslate : 'Apply Leave' }}</div>
                            <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal'"
                                (buttonClickEmit)="resetForm()" [fcBtnId]="'close'"></app-fusion-button>

                        </div>
                        <form class="form flex flex-direction-column" [formGroup]="applyLeaveForm" autocomplete="off">
                            <div class="flex">
                                <div class="row g-3 flex w-100">
                                    <div class="field-input w-100"
                                        [class]=" applyLeaveForm.controls['leaveType'].invalid && applyLeaveForm.controls['leaveType'].touched ? 'has-error' : '' ">
                                        <!--RG26AUG24 added asterisk to important fields -->
                                        <label for="leave-type" class="modal-field-label">{{ 'LEAVE_TYPE' | customTranslate : 'Leave Type' }}</label>
                                        <select id="leave-type" formControlName="leaveType" class="form-select"
                                            (change)="leaveCalc()">
                                            <option>{{'DEFAULT_DROPDOWN_VALUE' | customTranslate : 'Select' }}</option>
                                            <option *ngFor="let data of templateGet; let i=index"
                                                value="{{data.leaveType}}">
                                                {{data.leaveType}}
                                            </option>
                                        </select>
                                        <div>
                                            <p class="error-msg"><span
                                                    *ngIf="this.applyLeaveForm.controls['leaveType'].invalid && this.applyLeaveForm.controls['leaveType'].touched">
                                                    {{ 'LEAVE_TYPE' | customTranslate : 'Leave Type' }}</span></p>
                                            <p class="msg-display"><span
                                                        *ngIf=" this.applyLeaveForm.value.leaveType != '' ">{{'NO.OF'|translate}} {{leaveType}} : {{availDays}}</span></p>
                                        </div>
                                    </div>
                                    <div class="field-input"
                                        [class]=" applyLeaveForm.controls['startDate'].invalid && applyLeaveForm.controls['startDate'].touched  || startDayError ? 'has-error' : '' ">
                                        <label class="modal-field-label" for="start-date">{{ 'START_DATE' | customTranslate : 'Start Date' }}*</label>
                                        <input type="date" max="9999-12-31" formControlName="startDate" id="start-date" name="startDate"
                                            format="mm-dd-yyyy" class="input-border"
                                            (change)="startDate($event); leaveCalc()" />
                                        <p class="error-msg"><span
                                                *ngIf="applyLeaveForm.controls['startDate'].invalid && applyLeaveForm.controls['startDate'].touched || startDayError">
                                                {{ 'SELECT_VALID_DATE' | customTranslate : 'Select valid date' }}
                                            </span></p>
                                    </div>
                                    <div class="field-input" [style]="this.applyLeaveForm.value.startDate && this.applyLeaveForm.value.endDate ? 'display: block' : 'display:none' " [class]=" applyLeaveForm.controls['startDateSession'].invalid && applyLeaveForm.controls['startDateSession'].touched  || startSessionError ? 'has-error' : '' ">
                                        <label class="modal-field-label" for="start-date-session">{{ 'START_DATE_SESSION' | customTranslate : 'Start Date Session' }}</label>
                                        <select type="checkbox" name="startDateSession" id="start-date-session"
                                            formControlName="startDateSession"
                                            (change)="leaveCalc()" class="form-select">
                                            <option value="Full Day" selected>{{ 'FULL DAY' | customTranslate : 'Full Day' }}</option>
                                            <option *ngIf="endDate1 - startDate1 === 0" value="First Half">{{ 'FIRST_HALF' | customTranslate : 'First Half' }}</option>
                                            <option value="Second Half">{{ 'SECOND_HALF' | customTranslate : 'Second Half' }}</option>
                                        </select>
                                        <p class="error-msg mt-1"><span
                                                [style]="applyLeaveForm.controls['startDateSession'].invalid && applyLeaveForm.controls['startDateSession'].touched || startSessionError ? 'visibility: visible' : 'visibility:hidden' ">
                                                {{ 'SESSION_ERROR' | customTranslate : 'Session Error' }}
                                            </span></p>
                                    </div>
                                    <div class="field-input"
                                        [class]=" applyLeaveForm.controls['endDate'].invalid && applyLeaveForm.controls['endDate'].touched || leaveError ? 'has-error' : '' ">
                                        <label class="modal-field-label" for="end-date">{{ 'END_DATE' | customTranslate : 'End Date' }}*</label>
                                        <input type="date" max="9999-12-31" formControlName="endDate" id="end-date" name="endDate"
                                            format="mm-dd-yyyy" class="input-border"
                                            (change)="endDate($event); leaveCalc()" />
                                        <p class="error-msg"><span
                                                *ngIf="applyLeaveForm.controls['endDate'].invalid && applyLeaveForm.controls['endDate'].touched || leaveError"> {{ (leaveError ? ('VALIDATION.SUFF_LEAVE'| customTranslate : 'Leave is not sufficient') : ('SELECT_VALID_DATE'| customTranslate : 'Select valid date') )}}
                                            </span></p>
                                    </div>
                                    <div class="field-input" [class]=" applyLeaveForm.controls['endDateSession'].invalid && applyLeaveForm.controls['endDateSession'].touched  ? 'has-error' : '' " *ngIf="endSession">
                                        <label class="modal-field-label" for="end-date-session">{{ 'END_DATE_SESSION' | customTranslate : 'End Date Session' }}</label>
                                        <select type="checkbox" name="endDateSession" id="end-date-session"
                                            formControlName="endDateSession"
                                            (change)="leaveCalc()" class="form-select" placeholder="Select">
                                            <option value="Full Day" selected>{{ 'FULL DAY' | customTranslate : 'Full Day' }}</option>
                                            <option value="First Half">{{ 'FIRST_HALF' | customTranslate : 'First Half' }}</option>
                                        </select>
                                    </div>
                                    <div class="field-input w-100 col-md-6"
                                        [class]=" applyLeaveForm.controls['reason'].invalid && applyLeaveForm.controls['reason'].touched ? 'has-error' : '' ">
                                        <label for="reason" class="modal-field-label">{{ 'REASON' | customTranslate : 'Reason' }}*</label>
                                        <input type="text" formControlName="reason" id="reason"
                                            placeholder="Heavy fever since yesterday night. So need 1 day leave today"
                                            class="input-border">
                                        <p class="error-msg"><span
                                                *ngIf="applyLeaveForm.controls['reason'].invalid && applyLeaveForm.controls['reason'].touched">
                                                {{ 'ENTER_REASON' | customTranslate : 'Enter the reason' }}</span></p>
                                    </div>
                                    <div [class]="holidayDay ? 'error-msg' : 'msg-display' "><span
                                            [style]="showNoOfLeaves || holidayDay ? 'visibility: visible' : 'visibility: hidden' "> 
                                            {{ (holidayDay ? ('HOLIDAY_ERROR' | customTranslate : 'The selected start date (or) end date is in week off / holiday lists' ):( 'HOLIDAY.NOOFDAYS' | customTranslate : 'No. of Days applied:') )  }}  {{leaveNum}}
                                        </span></div>
                                    <div class="flex justify-content-end">
                                        <app-fusion-button [buttonType]="'btn btn-secondary'" [dataDismiss]="'modal'"
                                        buttonValue= "{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" (buttonClickEmit)="resetForm()"></app-fusion-button>
                                     <app-fusion-button [buttonType]="'btn btn-primary ms-2'" buttonValue= "{{ 'APPLY' | customTranslate : 'Apply' }}" (buttonClickEmit)="applyLeaveData()"
                                         [buttonDisable]="!applyLeaveForm.valid || startSessionError || startDayError || leaveError || holidayDay"
                                         [dataDismiss]="'modal'"></app-fusion-button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


