import { CommonModule, DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, isDevMode, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
//Custom Standalone Components
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { LeaveMasterComponent } from '../leave-master/leave-master.component';

//Services
import { SideNavEmitService } from '../../services/side-nav-emit.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/services/auth.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';
import { DropDownManagementComponent } from 'src/app/drop-down-management/drop-down-management.component';
import { AppsListComponent } from 'src/app/components/tabs-list/apps-list.component';
import { BankinfotabComponent } from '../ess/bankinfotab/bankinfotab.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    CommonModule, 
    FusionButtonComponent, 
    FormsModule, 
    ReactiveFormsModule, 
    PageHeaderComponent, 
    SuccessFailureMessageComponent, 
    AngularSvgIconModule, 
    LeaveMasterComponent, 
    TabsComponent, 
    DropDownManagementComponent,
    AppsListComponent,
    TranslateModule,
    BankinfotabComponent,
    CustomTranslatePipe
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  @Input() isHamClicked: boolean = false;
  public errorMessage: string | undefined = undefined;
  emailRegex = "[A-Za-z0-9._%-+]+@[A-Za-z0-9._%-]+\\.[a-z]{2,10}";
  numberRegex = "^[0-9]*$";
  pinRegex = "^[0-9]{5,7}";
  mobileRgx = "^[0-9]{4,12}"; //SK9JAN24 update mobile number validation to enable Save buttom
  textRegex = "^[a-zA-Z ]*$";
  tamilPattern = "^[அ-ஹா-ௌா-ௗ்ௐ]*$";
  percentRegex = "^(100\.00|100\.0|100)|([0-9]{1,2}){0,1}(\.[0-9]{1,2}){0,1}$"; // Added this for text Validation and used them further down the lines
  pageComponentName = 'Settings';

  // sideNavsubscription: Subscription;

  hideOrShowNav: string = "side-nav-container";
  settingsForm!: FormGroup;
  settingsResponse!: FormGroup;
  localizationForm!: FormGroup;
  emailForm!: FormGroup;
  permissionsForm!: FormGroup;
  compNameErr = false;
  contPersonErr = false;
  bussMailErr = false;
  phErr = false;
  addrErr = false;
  cityErr = false;
  stateErr = false;
  countryErr = false;
  pincodeErr = false;
  urlErr = false;
  localCountryeErr = false;
  dateErr = false;
  timeErr = false;
  currencyErr = false;
  yearFromErr = false;
  yearToErr = false;
  percentageErr = false;
  defaultMailErr = false;
  exceptionMailErr = false;
  paySlipReportingMailErr = false;
  textSaved = '';
  companyName: any;
  isDevMode: boolean = false;
  companyId = this.globalValues.orgId;
  languageCode:any; //SKS11OCT24

  permissionList: Array<any> = [
    { name: 'CASE Admin', value: 'case admin' },
    { name: 'CASE User', value: 'case user' },
    { name: 'ESS User', value: 'ess user' },
    { name: 'Finance Admin', value: 'finance admin' },
    { name: 'Finance User', value: 'finance user' },
    { name: 'Hire Manager', value: 'hire manager' },
    { name: 'Hire Admin', value: 'hire admin' },
    { name: 'Hire Recruiter', value: 'hire recruiter' },
    { name: 'HRM Manager', value: 'hrm manager' },
    { name: 'HRM Admin', value: 'hrm admin' },
    { name: 'Ken Admin', value: 'ken admin' },
    { name: 'Ken User', value: 'ken user' },
    { name: 'Project Admin', value: 'project admin' },
    { name: 'Project User', value: 'project user' }
  ];

  showSubmissionMsg = false;
  showErrorMsg = false;
  settingToShow = 'company';
  token: any;
  successMsg = Constants.SUCCESS_MESSAGE;
  userId: any;
  // SK31JAN24 to assign the module data from custom apps
  userModuleData:any;
  appModuleData:any;
  componentModuleData:any;
  employeeFinanceInfo:any; //SK26JUL24 settings finance info get

  @Output() alertMessageEmit!: EventEmitter<any>;

  constructor(@Inject(DOCUMENT) document: Document, private formBuilder: FormBuilder, private sideNavItem: SideNavEmitService, private apiService: ApiService, private errorHandler: ErrorHandlerService, private router: Router, private appComp: AppComponent, private routeActive: ActivatedRoute, private authServ: AuthService, private alertService: AlertService, private globalValues: GlobalValuesService, private language : TranslateService) {
    this.globalValues.globalValues();
    this.isDevMode = isDevMode();
    /* We removed the navItem subscription since we are fetching the current selected sideNav from the url , query param */
    this.routeActive.queryParams.subscribe(params => {
      if (params['activeNav']) {
        this.settingToShow = params['activeNav']
      }
    });

    //Adding default setup for the forms
    this.settingsForm = this.formBuilder.group({
      'companyName': [null, Validators.compose([Validators.minLength(3), Validators.required, Validators.pattern(this.textRegex)])],
      'contactPerson': [null, Validators.compose([Validators.minLength(5), Validators.required, Validators.pattern(this.textRegex)])],
      'businessMail': [null, Validators.compose([Validators.pattern(this.emailRegex)])],
      'phone': [null, Validators.compose([Validators.required, Validators.pattern(this.mobileRgx)])],
      'country': [null, Validators.required],
      'address1': [null, Validators.required],
      'address2': [null],
      'city': [null, Validators.required],
      'state': [null, Validators.required],
      'postalcode': [null, Validators.compose([Validators.pattern(this.pinRegex), Validators.required])],
      'website': [null]
    });

    this.localizationForm = this.formBuilder.group({
      'localizedCountry': [null, Validators.required],
      'localizedDateFormat': [null, Validators.required],
      'localTimeZone': [null, Validators.required],
      'localCurrency': [null, Validators.required],
    });
    this.emailForm = this.formBuilder.group({
      'defaultMail': [null, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
      'exceptionMail': [null, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
      'payslipreportingMail': [null, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
    });

    this.permissionsForm = this.formBuilder.group({
      checkArray: this.formBuilder.array([], [Validators.required]),
    });

    //SK31JAN24 to find the setting app from custom apps
    const userData = JSON.parse(localStorage.getItem('userData') as any);
    const settingsData = JSON.parse(localStorage.getItem('settingsData') as any);
    if(userData) {
      const loggedInUserData = userData.apps;
      this.languageCode = userData.languageCode || "en";//RS27AUG24
      loggedInUserData.forEach((appArr:any) => {
        // SK31JAN24, if settings app present, assign the module data to respective modules
        if(appArr.name === 'settings'){
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'users'){
              this.userModuleData = modArr;
            }
            if(modArr.name === 'apps'){
              this.appModuleData = modArr;
            }
          });
        }
        // SK31JAN24 dummy mapping - need to change it for component module
        if(appArr.name === 'hrm'){
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'employees'){
              this.componentModuleData = modArr;
            }
          });
        }
      });
    } else {
      this.languageCode = localStorage.getItem('language') || "en";
      console.log('waiting for the api response');
    }

    if(settingsData){
      this.employeeFinanceInfo = settingsData.finance.orgBankInfo;
    }

  }

  ngOnInit(): void {
    //In ngOnInIt this function called first to get all the form values
    this.getSettingData();

    if (this.isHamClicked) {
      this.hideOrShowNav = "hideOrShowNav hide"
    }
  }

  /** these handle functions are used to display the error message based on our requirement in input field **/
  handleCompanyName(event: any): void {
    if (event.target.value.match(this.textRegex) && (event.target.value.length > 2 || event.target.value.length === '')) {
      this.compNameErr = false;
    } else {
      this.compNameErr = true;
    }
  }

  handleContactPerson(event: any): void {
    if (event.target.value.match(this.textRegex) && (event.target.value.length > 4 || event.target.value.length === '')) {
      this.contPersonErr = false;
    } else {
      this.contPersonErr = true;
    }
  }

  handleBusinessMail(event: any): void {
    if (event.target.value.match(this.emailRegex) || event.target.value.length === '') {
      this.bussMailErr = false;
    } else {
      this.bussMailErr = true;
    }
  }

  handlePhNo(event: any): void {
    if (event.target.value.match(this.mobileRgx) || event.target.value.length === '') {
      this.phErr = false;
    } else {
      this.phErr = true;
    }
  }

  handleAddress(event: any): void {
    if (event.target.value.length != '') {
      this.addrErr = false;
    } else {
      this.addrErr = true;
    }
  }

  handleCity(event: any): void {
    if (event.target.value.length != '') {
      this.cityErr = false;
    } else {
      this.cityErr = true;
    }
  }

  handleState(event: any): void {
    if (event.target.value.length != '') {
      this.stateErr = false;
    } else {
      this.stateErr = true;
    }
  }

  handleCountry(event: any): void {
    if (event.target.value.length != '') {
      this.countryErr = false;
    } else {
      this.countryErr = true;
    }
  }

  handlePinCode(event: any): void {
    if (event.target.value.match(this.pinRegex) || event.target.value.length === '') {
      this.pincodeErr = false;
    } else {
      this.pincodeErr = true;
    }
  }

  handleURL(event: any): void {
    if (event.target.value.length > 8 || event.target.value.length === '') {
      this.urlErr = false;
    } else {
      this.urlErr = true;
    }
  }

  handleLocalCountry(event: any): void {
    if (event.target.value != '') {
      this.localCountryeErr = false;
    } else {
      this.localCountryeErr = true;
    }
  }

  handleDate(event: any): void {
    if (event.target.value.length != '') {
      this.dateErr = false;
    } else {
      this.dateErr = true;
    }
  }

  handleTime(event: any): void {
    if (event.target.value.length != '') {
      this.timeErr = false;
    } else {
      this.timeErr = true;
    }
  }

  handleCurrency(event: any): void {
    if (event.target.value.length != '') {
      this.currencyErr = false;
    } else {
      this.currencyErr = true;
    }
  }


  handleDefaultMail(event: any): void {
    if (event.target.value.match(this.emailRegex) || event.target.value.length === '') {
      this.defaultMailErr = false;
    } else {
      this.defaultMailErr = true;
    }
  }

  handleExceptionMail(event: any): void {
    if (event.target.value.match(this.emailRegex) || event.target.value.length === '') {
      this.exceptionMailErr = false;
    } else {
      this.exceptionMailErr = true;
    }
  }

  handlePaySlipMail(event: any): void {
    if (event.target.value.match(this.emailRegex) || event.target.value.length === '') {
      this.paySlipReportingMailErr = false;
    } else {
      this.paySlipReportingMailErr = true;
    }
  }

  /** this function is used to save the input field data in company settings page*/
  setCompanySettings() {
    if (this.isDevMode) console.log("Submit Company Settings");
    const value = this.settingsForm.value;
    this.apiService.writeValue('patch', '/settings', { 'companyName': value.companyName, 'contactPerson': value.contactPerson, 'businessMail': value.businessMail, 'phone': value.phone, 'address1': value.address1, 'address2': value.address2, 'country': value.country, 'city': value.city, 'state': value.state, 'postalcode': value.postalcode, 'website': value.website })
      .subscribe({
        next: (companysettings) => {
          this.textSaved = 'input-element';
          setTimeout(() => { this.textSaved = ''; }, 3000);
          this.saveMessage();
          this.navigateOnLoginForSuperUser();
        },
        error: (err: HttpErrorResponse) => {
          this.textSaved = 'input-element';
          setTimeout(() => { this.textSaved = ''; }, 5000);
          this.errorMsg(err);
        }
      });
  }

  sideNavChange($event: any) {
    /* If unused for long this sidenav click functionality related code can be removed */
  }

  /** this function is used to save the input field data in localization settings page*/
  setLocalization() {
    if (this.isDevMode) console.log("Submit Localization");
    const value = this.localizationForm.value;

    this.apiService.writeValue('patch', '/settings/localization', { 'country': value.localizedCountry, 'dateFormat': value.localizedDateFormat, 'timezone': value.localTimeZone, 'currencyCode': value.localCurrency, 'companyId': this.companyId })
      .subscribe({
        next: (localization) => {
          if (this.isDevMode) console.log("Next :", localization);
          if (this.isDevMode) console.log("Localization saved");
          this.textSaved = 'input-element';
          setTimeout(() => { this.textSaved = ''; }, 3000);
          this.saveMessage();
          this.navigateOnLoginForSuperUser(); /* if the user is from login page and filling settings page then take to the respective screens which are not filled */
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
          this.textSaved = 'input-element';
          setTimeout(() => { this.textSaved = ''; }, 5000);
          this.errorMsg(err);
        }
      });
  }
  /** this function is used to save the input field data in email settings page*/
  setEmailSettings() {
    if (this.isDevMode) console.log("Submit Email Settings");
    const value = this.emailForm.value;

    this.apiService.writeValue('patch', '/settings/email', { 'defaultEmail': value.defaultMail, 'exceptionEmail': value.exceptionMail, 'payslipReportingEmail': value.payslipreportingMail, 'companyId': this.companyId })
      .subscribe({
        next: (emailsettings) => {
          if (this.isDevMode) console.log("Next :", emailsettings);
          if (this.isDevMode) console.log("Email settings saved");
          this.textSaved = 'input-element';
          setTimeout(() => { this.textSaved = ''; }, 3000);
          this.saveMessage();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
          this.textSaved = 'input-element';
          setTimeout(() => { this.textSaved = ''; }, 5000);
          this.errorMsg(err);
        }
      });
  }

  /**This function is used to get the data from database of localization settings page**/
  getSettingData() {
    this.apiService.fetchValue('/user').subscribe((data: any) => {
      if (this.isDevMode) console.log("Settings Data...", data);
      let Data: any = data;
      // If user have prefilled data take to HRM page , in future we have to redirectly according to the user need
      // if(Data.companySettings.phone != '' || Data.companySettings.phone != undefined || Data.companySettings.phone != null){
      //   this.router.navigateByUrl('/hrm');
      //   return;
      // }

      this.companyName = Data?.loggedInUser[0]?.companyName;
      Data?.settings?.forEach((responseData: any) => {
        this.settingsForm = this.formBuilder.group({
          'companyName': [responseData.companyName, Validators.compose([Validators.minLength(3), Validators.required, Validators.pattern(this.textRegex)])],
          'contactPerson': [responseData.contactPerson, Validators.compose([Validators.minLength(3), Validators.required, Validators.pattern(this.textRegex)])],
          'businessMail': [responseData.businessMail, Validators.compose([, Validators.required, Validators.pattern(this.emailRegex)])],
          'phone': [responseData.phone, Validators.compose([Validators.required, Validators.pattern(this.mobileRgx)])],
          'country': [responseData.country, Validators.required],
          'address1': [responseData.address1, Validators.required],
          'address2': [responseData.address2],
          'city': [responseData.city, Validators.required],
          'state': [responseData.state, Validators.required],
          'postalcode': [responseData.postalcode, Validators.compose([Validators.pattern(this.pinRegex), Validators.required])],
          'website': [responseData.website]
        });

        this.localizationForm = this.formBuilder.group({
          'localizedCountry': [responseData.localization?.country, Validators.required],
          'localizedDateFormat': [responseData.localization?.dateFormat, Validators.required],
          'localTimeZone': [responseData.localization?.timezone, Validators.required],
          'localCurrency': [responseData.localization?.currencyCode, Validators.required],
        });


        this.emailForm = this.formBuilder.group({
          'defaultMail': [responseData.emails?.defaultEmail, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
          'exceptionMail': [responseData.emails?.exceptionEmail, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
          'payslipreportingMail': [responseData.emails?.payslipReportingEmail, Validators.compose([Validators.required, Validators.pattern(this.emailRegex)])],
        });
      });
      this.navigateOnLoginForSuperUser(); /* if the user is from login page and filling settings page then take to the respective screens which are not filled */
    });
  }

  navigateOnLoginForSuperUser() {
    /* if the user is from login page and filling settings page then take to the respective screens which are not filled */
    // Disabled all Salary Setting Functionality 
    if (this.authServ.user.includes('Super User')) {
      this.routeActive.queryParams.subscribe(params => {
        if (params['pageFrom'] == undefined || params['pageFrom'] == '' || params['pageFrom'] === 'login') {

          const addrField = this.settingsForm.controls['address1']?.value;


          if (addrField === null || addrField === undefined || addrField === '') {
            this.appComp.callNavService('company');
          }

          else {
            this.router.navigateByUrl('/hrm/'+this.languageCode || "en");//RS27AUG24
          }
        }
      });
    }
  }

  onCheckboxChange(e: any) {
    const checkArray: FormArray = this.permissionsForm.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: any) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  saveMessage() {
    this.alertService.messageOnPass('success', this.successMsg)
  }

  errorMsg(err: any) {
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage)
  }
}
