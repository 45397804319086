import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, isDevMode, ChangeDetectionStrategy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { AttendanceColorDirective } from 'src/app/directives/attendance-color/attendance-color.directive';
import { Constants } from 'src/app/models/Constants';
import { Subscription } from 'rxjs';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MultiCheckboxComponent } from '../../multi-checkbox/multi-checkbox.component';
import { MultipleFilterComponent } from '../../multiple-filter/multiple-filter.component';
import { CommonScreenComponent } from '../../side-screen/common-screen/common-screen.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
import { ApiService } from 'src/app/services/api.service';
import { CustomPaginatorIntl } from 'src/app/shared/custom-paginator-intl';

@Component({
  selector: 'app-data-table-two',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, ReactiveFormsModule, FormsModule, FileUploadComponent, AttendanceColorDirective, AngularSvgIconModule, MultiCheckboxComponent, MultipleFilterComponent, CommonScreenComponent, TranslateModule, CustomTranslatePipe],
  templateUrl: './data-table-two.component.html',
  styleUrls: ['./data-table-two.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }] // Provide the custom paginator
})
export class DataTableTwoComponent implements OnInit {

  // Important note 
  // ** > == **>> ==
  // ** > == **>> ==
  // this datatable holds change detection using the ChangeDetectionStrategy.OnPush

  @Input() data!: any[];  //  get data that to be displayed in a array
  @Input() displayedColumns!: string[]; // get  columns values to display
  @Input() headerLabels!: any; // columns porper label that to be displayed in  header
  @Input() translatePath!: any;
  @Input() withCheckBox: boolean = false;  // get if with or without checkbox in table
  @Input() searchBar = false; // if it is true, the search bar will be displayed on table
  @Input() editColumn: Array<any> = []; // columns that should be edited to be sent in ana array
  @Input() filterColumns: Array<any> = []; // colums in which filter action should be done is sent in this array
  @Input() saveLOP = false; // save button for editing LOP in payroll table
  @Input() stickyColumn : Array<any> = []; // if the last column should be sticky, it should be sent as boolean value
  @Input() tableWidth = '100%'; // width of table can be adjusted from parent, by default it is 100%
  @Input() defaultWorkingHours: number = -1; //incase the attendance is given as number of hours worked we need to pass the default working hours of the company 
  /** the filter column array should be send as array of objects
   Object = [
    dept: Set(4) {'HR', 'IT', 'Data Science', 'AI'},
    desig: Set(7) {'Admin', 'Developer', 'Tester', 'Architect', 'Accounting Manager'}
    empName: Set(8) {'Sarvesh', 'ABC', 'DEF', 'GHI', 'JKL', …}
    location: Set(4) {'Tamilnadu', 'Kerala', 'Maharastra', 'Pune'}
   ]
  */
  @Input() multipleFilter : any;
  @Input() actionButton:boolean = false;  //to show button colum as action
  @Input() fromWhere:string = '';  // to show the different  action buttons based on the component like If I provide 'expense' will show te expense action button only
  @Input () titleOn= false; // to include titles for the table;
  @Input () title:any;// the title for the table;
  @Input () exportDisable = true; // export button disable;
  @Input () hyperLinkColumns : Array<any> = [];
  @Input () exportFieldsListConfigs : any; //SK03OCT24
  @Input () exportFilterListConfigs : any; //SK03OCT24
  @Input () filterScreenConfigs : Array<any> = []; //SK03OCT24
  

  @Output() selectedValues = new EventEmitter<any[]>(); // to pass the selected checkbox values
  @Output() tableRowClick = new EventEmitter<any>; // datas to be passed when table row is clicked
  @Output() filteredArray = new EventEmitter<any>; // filtered data from heqder icon is sent in an array
  @Output() searchFilterData = new EventEmitter<any>; // search filter data  from search bar is sent to its parent
  @Output() OnDeleteData = new EventEmitter<any>;  // when delete button clicked pass the data
  @Output() onDownload = new EventEmitter<any>;  // when download button is clicked to pass the data
  @Output() importButton = new EventEmitter<any>;
  @Output() exportButton = new EventEmitter<any>; 
  @Output() doubleClickEmit = new EventEmitter<any>; 
  @Output() hyperLinkClickEmit = new EventEmitter<any>; //SK06MAR24
  @Output() exportReports = new EventEmitter<any>; //SK03OCT24
  @Output() sideNavButtons = new EventEmitter<any>; //SK03OCT24
  @Output() multipleSelectFilterExport = new EventEmitter<any>; //SK03OCT24 
  @Output() filterDateEmitEvt : EventEmitter<any> = new EventEmitter<any>(); //SK03OCT24

  @ViewChild(MatPaginator) paginator!: MatPaginator; //get references to the MatPaginator and MatSort components
  @ViewChild("matSort") sort!: MatSort;
  @ViewChild('editInput') editInput!: ElementRef

  dataSource!: MatTableDataSource<any>;  // Declare a MatTableDataSource property for the table data
  selection: any;
  stickyCondition!: number;
  searchFilter = false;
  filterArray: Array<any> = [];
  selectedFilter: any;
  filterCheckBox: any;
  selectedFilterColumn: any;
  selectedFilterKey: Array<any> = [];
  renderColumns: string = 'displayedColumns';
  isDevMode: boolean = false;
  pageSizeOptions: number[] = [10, 25, 50];
  itemsPerPage = Constants.PAGE_SIZE_OPTIONS;
  leaveType : Array<any> = [];
  attendanceSubscription: Subscription;
  exportSidescreen : boolean = false; //SK03OCT24
  headerTranslateLabels: any;

  constructor(private globalValues: GlobalValuesService, private translate : TranslateService, private apiService : ApiService, private paginatorIntl: MatPaginatorIntl) { 
    this.isDevMode = isDevMode();
    //SKS24OCT24 Fetch all leave types
    this.apiService.fetchValue('/leavemaster').subscribe((data:any) =>{
      if(this.isDevMode) console.log('GET LEAVE MASTER',data);
      const leaveTypeListAdded:any = []
      const templateLeaveData = data.template;
      templateLeaveData.forEach((leavedata:any) => {
        leaveTypeListAdded.push((leavedata.leaveType).toLowerCase());
      });
      this.leaveType = [...leaveTypeListAdded,...['absent']]//SK17JUL24
      console.log("leaveTypeListAdded",leaveTypeListAdded)
    })
    // this.leaveType = [...Constants.leaveTypes,...['absent']].map((loweCase:any)=> loweCase.toLowerCase());
    this.attendanceSubscription = this.globalValues.attendanceDataChange$.subscribe((type:any) => {
      this.dataSource = new MatTableDataSource(type);
      this.selection = new SelectionModel<Array<any>>(true, []);
      this.filterCheckBox = new SelectionModel<any>(true, []);
      if(this.isDevMode) console.log('data table data', type);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnChanges(){
    this.dataSource = new MatTableDataSource(this.data);
    this.selection = new SelectionModel<Array<any>>(true, []);
    this.filterCheckBox = new SelectionModel<any>(true, []);
    if(this.isDevMode) console.log('data table data', this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updatePaginatorLabels(); // Call the method to update labels
    });
    // The dataSource property is set to a new MatTableDataSource instance that contains table  data
    // Create a new selection model that allows multiple items to be selected
    this.dataSource = new MatTableDataSource(this.data);
    this.selection = new SelectionModel<any>(true, []);
    this.filterCheckBox = new SelectionModel<any>(true, []);
    if(this.isDevMode) console.log('data table data', this.data);
     //to render the action and check box columns;
    if(this.withCheckBox){
      this.renderColumns = 'select';
    }else if(this.actionButton){
      this.renderColumns = 'action';
    }
    if(this.translatePath){
      this.headerTranslateLabels = this.headerLabels.map((headerLabels: any) => {
        const headerTranslateLabels = this.translate.instant(`${this.translatePath}.${headerLabels}`);
        return headerTranslateLabels === `${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels;
      });
    }else{
      this.headerTranslateLabels = this.headerLabels
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(this.translatePath){
        this.headerTranslateLabels = this.headerLabels.map((headerLabels: any) => {
          const headerTranslateLabels = this.translate.instant(`${this.translatePath}.${headerLabels}`);
          return headerTranslateLabels === `${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels;
        });
      }else{
        this.headerTranslateLabels = this.headerLabels
      }
    });
  }
  updatePaginatorLabels() {
    // Manually trigger an update to the paginator
    if (this.paginator) {
      this.paginator._changePageSize(this.paginator.pageSize); // Re-initialize paginator with the current page size
      console.log("Paginator labels updated and paginator refreshed.");
    }
  }
  ngAfterViewInit() {
    // set the paginator and sort properties of the MatTableDataSource instance to the MatPaginator and MatSort instances that you retrieved using @ViewChild.
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  // apply search bar filter using mat
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.searchFilterData.emit(this.dataSource.filteredData)
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    // check if all rows are selected
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    // emit the selected value only to parent
    if (this.isSomeSelected()) {
      this.selection.clear();
      this.selectedValues.emit(this.selection.selected);
    } else {
      // If no items are selected, either select all items or clear the selection
      // emit the selected values to parent
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.data.forEach(row => this.selection.select(row));
      this.selectedValues.emit(this.selection.selected);
    }
    if(this.isDevMode) console.log(' selected', this.selection.selected);
  }
 
  // Checks whether any items are currently selected in the table.
  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  // emit the check box values, that selected separately
  separateRowSelect(data: any) {
    if (data) {
      this.selectedValues.emit(this.selection.selected)
    }
  }

  // data that to be passed, when table row is clicked
  tableClick(data: any) {
    this.tableRowClick.emit(data);
  }

  importButtonEmit(){
    this.importButton.emit();
  }

  exportButtonEmit(){
    this.exportSidescreen = true;
    this.exportButton.emit();
  }

  doubleClickFunction(element:any, data:any, presentData:any){
    this.doubleClickEmit.emit({employeeDetail:element.employeeDetail, date:data, data:presentData });
  }

  // checks whether the string contains number, that is seperated by dot character. If the condition satisfies, it returns true
  containsOnlyNumbers(str:any) { 
    // return /^[0-9]+$/ .test(str);
    return /^(\d+.)*(\d+)$/.test(str);
  }
  
  // SK06MAR24 hyperlink click data emit
  hyperLinkClick(element:any, column:string, i:number){
    this.hyperLinkClickEmit.emit({element:element, column:column, i:i})
  }

  // SK03OCT24 side nav export button function
  exportButtonSideNav(){
    this.exportReports.emit()
  }

  // SK03OCT24 multipleCheckBox button emit function
  exportFieldsButtonEmit(evt:any){
    this.sideNavButtons.emit(evt)
  }

  // SK03OCT24 this function emit while selecting values in filter box
  selectedValuesEmit(evt:any){
    this.multipleSelectFilterExport.emit(evt)
  }

  // SK03OCT24 multipleFilter button emit function
  exportFilterButtonEmit(evt:any){
    this.sideNavButtons.emit(evt)

  }

  // SK03OCT24 if date filter, emits the slected dates function
  filterDateEmit(event:any){
    this.filterDateEmitEvt.emit(event)
  }
}

