import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { FormsModule } from '@angular/forms';
import { SearchFilterPipe } from 'src/app/pipes/search-filter/search-filter.pipe';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-multiple-filter',
  standalone: true,
  imports: [CommonModule,MatCheckboxModule, FusionButtonComponent, FormsModule, SearchFilterPipe, DropDownComponent,TranslateModule,CustomTranslatePipe],
  templateUrl: './multiple-filter.component.html',
  styleUrls: ['./multiple-filter.component.css']
})
export class MultipleFilterComponent implements OnInit {

  @Input() label!:string; // SK03OCT24 label for card
  @Input() checkboxList:Array<any> = []; // SK03OCT24 the list that to to dispolayed in card
  @Input() activeCheckboxList:Array<any> = []; // the active list that to be check 
  @Input() cardSize = "col-md-6"; // SK03OCT24 card size
  @Input() componentData:any; // SK03OCT24 component data
  @Input() cardInlineSize = "col-md-2"; // SK03OCT24 inline chek box size
  @Input() buttonList:Array<any> = []; // SK03OCT24 button list in component
  @Input() cardClass : string = "";
  @Input() displayValues:any = {
    keyString:"",
    valueString:""
  };
  @Input() checkBoxStyles : string = "";
  @Input() selectionData:Array<any> = [];
  @Input() isDateFilter : boolean = false;
  @Output() compDataEmit: EventEmitter<any> = new EventEmitter<any>(); // SK15FEB24 emit action while clicking save
  @Output() checkEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedValuesEmit: EventEmitter<any> = new EventEmitter<any>();
  searchText:any;
  startDate: any;
  endDate: any;

  constructor() { }

  ngOnInit(): void {
    // SK03OCT24 sample date for filter 
    // this.selectionData = [
    //   {"label":"Department", "$opMap":"workinfo.department", "allValues":["IT", "Finance", "Admin"], "selectedValues":["IT", "Finance"]},
    //   {"label":"Designation", "$opMap":"workinfo.designation", "allValues":["Developer", "Accountant", "HR"], "selectedValues":["Developer", "Accountant"]},
    //   {"label":"Gender", "$opMap":"personalinfo.gender", "allValues":["Male", "Female", "Others"], "selectedValues":[ "Female", "Others"]},
    //   {"label":"Location", "$opMap":"workinfo.officeLocation", "allValues":["Chennai", "Tirunelveli", "Coimbatore"], "selectedValues":["Chennai", "Tirunelveli", "Coimbatore"]},
    // ]
  }

  ngOnChanges(changes:SimpleChanges){
  }

  // SK03OCT24 basid on action the event emits
  buttonClick(event:any){
    this.compDataEmit.emit({compData:this.componentData, button:event});
  }

  multipleSelectDataEmit(event:any, list:any){
    list.selectedValues = event.data;
    this.selectedValuesEmit.emit({list:list})
  }

  dateErr: boolean = false
  leaveCalc(){
    let stDt:any = new Date(this.startDate);
    let edDt:any = new Date(this.endDate);
    this.dateErr = ((edDt - stDt) < 0) ? true : false;
    this.filterDateEmit.emit({startDate:this.startDate, endDate:this.endDate })
  }

  @Output() filterDateEmit : EventEmitter<any> = new EventEmitter<any>();
  // SK03OCT24 dates emit
  buttonClickDate(){
    this.filterDateEmit.emit({startDate:this.startDate, endDate:this.endDate })
  }
}
