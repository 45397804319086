<div class="table-layout w-100">
  <div class="m-4">
    <div class="d-flex justify-content-between" style="padding: 24px 0px 24px 0px">
      <div class="search" *ngIf="searchBar">
        <div class="search-bar">
          <input class="medium-font" type="text" placeholder="{{ 'SEARCH' | customTranslate : 'Search'  }}..." matInput (keyup)="applyFilter($event)" #input>
          <img class="card-btn" src="../assets/images/icons/general/Search.svg">
        </div>
      </div>
      <!-- SK02SEP24 title added in table -->
      <div class="table-title align-self-center" *ngIf="titleOn">
        {{title | customTranslate : title}}
      </div>
      <!-- SK10APR24 import and export button added inside table -->
      <div class="flex">
        <div class="d-flex align--center flex-row me-2 cursor-pointer short-button-white-bg button-hov"
          (click)="exportButtonEmit()">
          <svg-icon src="../assets/images/icons/general/upload-icon.svg " class="card-icon color me-1"
              title="download"></svg-icon>
              <!-- RS27AUG24 -->
              <!-- Used the translate pipe to successfully implement the translation -->
          <div>{{ 'EXPORT' | customTranslate : 'Export' }}</div>
        </div>
        <div class="d-flex align--center flex-row me-2 cursor-pointer short-button-white-bg button-hov"
            (click)="importButtonEmit()">
            <svg-icon src="../assets/images/icons/general/download-icon.svg" class="card-icon color me-1"
                title="download"></svg-icon>
            <div>{{ 'IMPORT' | customTranslate : 'Import' }}</div>
        </div>
      </div>
     
      <!-- <div class="d-flex align--center flex-row ms-2 me-2 cursor-pointer"
        [style]="exportDisable ? 'pointer-events: none; opacity: 0.5' : '' " (click)="exportButtonEmit()">
        <div>Export</div>
        <img class="card-btn" class="ms-1" src="../../../../assets/images/icons/general/attendance-export.svg">
      </div>
      <div class="d-flex align--center flex-row ms-2 me-2 cursor-pointer" (click)="importButtonEmit()">
        <img class="card-btn" class="me-1" src="../../../../assets/images/icons/general/attendance-import.svg">
        <div>Import</div>
      </div> -->

      <!-- CODE TO BE USED IN FUTURE             ******************** DON'T REMOVE ****************************************
          Filter and table view icons in table
          <div class="d-flex align--center align--center">
          <img class="card-btn" class="ms-2 me-2 cursor-pointer" src="../../../../assets/images/icons/general/filtericon.svg">
          <div></div>
          </div>
          <div class="d-flex align--center">
            <img class="card-btn" class="ms-2 me-2 cursor-pointer" src="../../../../assets/images/icons/general/attendance-tableview.svg">
            <div></div>
          </div>
        -->
    </div>

    <div class="table-margin">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" style="width: {{tableWidth}}" matSort>

          <!-- Checkbox Columns -->
          <!-- SK06MAR24 made checkbox sticky -->
          <ng-container [sticky]="true" *ngIf="withCheckBox" matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="head-color sticky-column">
              <mat-checkbox (change)="$event ? masterToggle()  : null" [checked]="selection.hasValue()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="body-color">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? separateRowSelect(selection.toggle(row)) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- data Columns -->
          <!-- set the matColumnDef attribute to the column name and use interpolation to dynamically generate the mat-header-cell and mat-cell elements based on the column name-->
          <ng-container *ngFor="let column of displayedColumns; let i=index" [matColumnDef]="column"
            [stickyEnd]="i === (stickyCondition - 1) ? true : false" [sticky]="stickyColumn.includes(column)">
            <th mat-header-cell *matHeaderCellDef
              class="head-color ms-2 {{ column != 'employeeDetail' ? 'th-td' : '' }}  sticky-column" (click)="$event.stopPropagation()"
              [ngClass]="{'week-off': column === '', 'holiday': [''].includes(column)}">
              <div
                class="d-flex flex-column {{column === 'employeeDetail' ? 'align--start' : 'align--center'}}">
                <div style="font-size: 12px;">{{headerTranslateLabels[displayedColumns.indexOf(column)]?.date }}</div>
                <div style="font-size: 12px;">{{headerTranslateLabels[displayedColumns.indexOf(column)]?.day }}</div>
              </div>

              <!-- CODE TO BE USED IN FUTURE             ******************** DON'T REMOVE ****************************************
                    Search filter in 'table head' codes
                  <div (click)="$event.stopPropagation()">
                  <img class="ms-1 position-relative" *ngIf="filterColumns.includes(column)"
                    (click)="searchFilter = !searchFilter; filter(column); selectedFilterColumn = column" src="../assets/images/icons/general/filter-icon.svg">
                  <app-search-filter class="search-component position-absolute"
                    [class]="searchFilter === true && column === selectedFilter ? 'd-block' : 'd-none' "
                    [filterItems]="filterArray" (emitValues)="filteredData($event)"></app-search-filter>
                  </div>
                -->
            </th>
            <td mat-cell class="body-color {{ column != 'employeeDetail' ? 'th-td' : '' }}" style="text-align: center;"
              *matCellDef="let element">
              <div *ngIf="column === 'employeeDetail'">
                <div class="d-flex flex-row">
                  <!-- SK06MAR24 hyperlink in employee name -->
                  <div [class]="hyperLinkColumns.includes(column) ? 'hyper-link' : '' " (click)="hyperLinkColumns.includes(column) ? hyperLinkClick(element, column, i) : ''" style="font-size: 12px;color: #434555;font-weight: 600;">{{element[column].empName}}</div>
                  <div style="font-size: 11px;color: rgba(67, 69, 85, 0.65);font-weight: 400;" class="ms-1">
                    [{{element[column].empId}}]</div>
                </div>
                <div class="d-flex flex-row">
                  <div style="font-size: 10px;color: rgba(67, 69, 85, 0.65);font-weight: 600;">{{element[column].desig}}
                  </div>
                  <div *ngIf="element[column].desig && element[column].loc"
                    style="font-size: 10px;color: rgba(67, 69, 85, 0.65);font-weight: 600;">,</div>
                  <div style="font-size: 10px;color: rgba(67, 69, 85, 0.65);font-weight: 600;">{{element[column].loc}}
                  </div>
                </div>

              </div>
              <div
                *ngIf="column != 'employeeDetail' && column != 'employeeName' && column != 'employeeId' && column != 'designation' && column != 'location'  "
                (click)="doubleClickFunction(element, column, element[column])">
                <!-- SK02SEP24 assigning data from value key  -->
                <div
                  *ngIf="element[column]['value'] != '' && ['present'].includes((element[column]['value']?.status).toLowerCase()) && element[column]['value']?.halfDay === false">
                  <img class="ms-1" src="../../../../assets/images/icons/general/Present.svg"></div>
                <div
                  *ngIf="(element[column]['value'] != '' && leaveType.includes((element[column]['value']?.status).toLowerCase()) && element[column]['value']?.halfDay != true) "> <!-- SK18JUN24 -->
                  <img class="ms-1" src="../../../../assets/images/icons/general/Absent.svg"></div>
                <div *ngIf="element[column]['value'] != '' && ['holiday'].includes((element[column]['value']?.status).toLowerCase())"><img
                    class="ms-1" src="../../../../assets/images/icons/general/holiday_1.svg"></div>
                <div *ngIf="element[column]['value']?.halfDay === true" ><img class="ms-1"
                    src="../../../../assets/images/icons/general/halfday.svg"></div>
                <div *ngIf="element[column]['value'] != '' && ['weekoff'].includes((element[column]['value']?.status).toLowerCase())"><img
                    class="ms-1" src="../../../../assets/images/icons/general/Off.svg"></div>
                <div *ngIf="column === 'totalHours'" (click)="doubleClickFunction(element, column, element[column])"
                  style="font-size: 12px; color: #434555; font-weight: 600;">{{element[column]['value']}}</div>
                <div (click)="doubleClickFunction(element, column, element[column])"
                  style="font-size: 12px; color: #434555; font-weight: 600;">{{containsOnlyNumbers(element[column]['value']) ?
                  element[column]['value'] : ''}}</div>
              </div>
              <div *ngIf="element[column]['value']  === '' || element[column]['value']?.status  === 'Nil' " (click)="doubleClickFunction(element, column, element[column])"
                style="font-size:11px; text-align: center">{{'Nil' | customTranslate : 'Nil'}}</div>
            </td>
          </ng-container>


          <!-- Render the columns -->
          <div [ngSwitch]="renderColumns">
            <div *ngSwitchCase="'select'">
              <tr mat-header-row *matHeaderRowDef="['select'].concat(displayedColumns) sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns:  ['select'].concat(displayedColumns)" (click)="tableClick(row)">
              </tr>
            </div>
            <div *ngSwitchDefault>
              <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="tableClick(row)"></tr>
            </div>
          </div>

          <!-- CODE TO BE USED IN FUTURE             ******************** DON'T REMOVE **************************************** 
                For empty table data, this code will be executed
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                  No data/ records found
                </td>
              </tr>
            -->

        </table>
      </div>
      <mat-paginator showFirstLastButtons [pageSizeOptions]="itemsPerPage" [pageSize]="10"></mat-paginator>
    </div>
  </div>
</div>


<!-- Legend for Attendance -->
<div class="d-flex flex-row mt-2">
  <div class="d-flex flex-row ms-4 align--center">
    <img class="ms-1" src="../../../../assets/images/icons/general/Present.svg">
    <div class="ms-2">{{ 'PRESENT' | customTranslate : 'Present' }}</div>
  </div>
  <div class="d-flex flex-row ms-4 align--center">
    <img class="ms-1" src="../../../../assets/images/icons/general/Absent.svg">
    <div class="ms-2">{{ 'ABSENT' | customTranslate : 'Absent' }}</div>
  </div>
  <div class="d-flex flex-row ms-4 align--center">
    <img class="ms-1" src="../../../../assets/images/icons/general/Off.svg">
    <div class="ms-2">{{ 'WEEK_OFF' | customTranslate : 'Week Off' }}</div>
  </div>
  <div class="d-flex flex-row ms-4 align--center">
    <img class="ms-1" src="../../../../assets/images/icons/general/halfday.svg">
    <div class="ms-2">{{ 'HALF_DAY' | customTranslate : 'Half day' }}</div>
  </div>
  <div class="d-flex flex-row ms-4 align--center">
    <img class="ms-1" src="../../../../assets/images/icons/general/holiday_1.svg">
    <div class="ms-2">{{ 'HOLIDAY' | customTranslate : 'Holiday' }}</div>
  </div>
</div>

<!-- SK03OCT24 side screen for filter while export button clicked -->
<app-common-screen *ngIf="exportSidescreen" headerTitle="Export Data" (sideScreenEmittedEvent)="exportSidescreen = false" >
  <div class="side-main-content">
        <div class="d-flex align--center flex-row  p-3 justify-content-center cursor-pointer short-button-white-bg button-hov"
            (click)="exportButtonSideNav()">
            <svg-icon src="../assets/images/icons/general/upload-icon.svg " class="card-icon color me-1"
                title="download"></svg-icon>
            <div>{{'Export File' | customTranslate : 'Export File'}}</div>
        </div>
        <!-- SK03OCT24 components to be displayed in side screen -->
        <div *ngFor="let type of filterScreenConfigs">
          <app-multi-checkbox *ngIf="type.type === 'multipleCheckBox' " class="ms-2 me-2"
              [label]="type?.label" 
              [cardSize]="type?.cardSize"
              [cardInlineSize]="type?.cardInlineSize"
              [checkboxList]="type?.checkboxList" 
              [activeCheckboxList]="type?.activeCheckboxList" 
              [buttonList]="type?.buttons" 
              [displayValues]="type?.displayValues" 
              [componentData]="type"
              [checkBoxStyles]="type?.checkBoxStyles"
              (compDataEmit)="exportFieldsButtonEmit($event)">
          </app-multi-checkbox>
          <app-multiple-filter *ngIf="type.type === 'multipleFilter' "  class="ms-2 me-2"
            [isDateFilter]="type?.isDateFilter"
            [label]="type?.label" 
            [cardSize]="type?.cardSize"
            [cardInlineSize]="type?.cardInlineSize"
            [buttonList]="type?.buttons" 
            [displayValues]="type?.displayValues" 
            [componentData]="type"
            [checkBoxStyles]="type?.checkBoxStyles"
            [selectionData]="type?.selectionData"
            (compDataEmit)="exportFilterButtonEmit($event)"
            (selectedValuesEmit)="selectedValuesEmit($event)"
            (filterDateEmit)="filterDateEmit($event)">
          </app-multiple-filter>
        </div>
  </div>
</app-common-screen>