import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, isDevMode, Input, SimpleChanges  } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { DataTableTwoComponent } from 'src/app/components/second-data-table/second-data-table/data-table-two.component';
import { ApiService } from 'src/app/services/api.service';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { Modal } from 'bootstrap';
import { saveAs } from 'file-saver';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { AlertService } from 'src/app/services/alert.service';
import { Constants } from 'src/app/models/Constants';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { NavTabsComponent } from 'src/app/nav-tabs/nav-tabs.component';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-attendance-hrm',
  standalone: true,
  imports: [
    CommonModule,
    FusionButtonComponent,
    DropDownComponent,
    DataTableTwoComponent,
    TranslateModule,
    FileUploadComponent,
    ReactiveFormsModule,
    AngularSvgIconModule,
    FormsModule,
    NavTabsComponent,
    DataTableComponent,
    CustomTranslatePipe
  ],
  templateUrl: './attendance-hrm.component.html',
  styleUrls: ['./attendance-hrm.component.css']
})
export class AttendanceHrmComponent implements OnInit {

  monthArray!: Array<any>; // The months list to be diplayed in month dropdown
  yearArray!: Array<any>; // The year list to be diplayed in month dropdown
  displayedColumns: Array<any> = []; // The 'KEYS' of an object that to be diplayed in datatable is assigned here
  year: any; //selected month from dropdown
  month: any; //selected year from dropdown
  headerLabels: any; // The data to be displayed in table header in sent as array
  tableData!: Array<any>; //  The data that to be displayed in table is assigned here
  modalDirect!: Modal; // bootstrap modal to open using function
  employeeBulkFile: any; // the selected employee attendance is stored in this array
  editAttendanceForm!: FormGroup; // the edited attendance data is stored in this form
  attendanceObject!: any; // the event data stored while clicking the edit attendance 
  selectedMonth: any;
  editedDate: any; // the edited attendance date of employee is stored here
  editedEmployee: any; // the edited attendance of employee is stored here
  finalTableData!: Array<any>; // The data that to be displayed in table is sent in this array, 
  checkedEmployees: any // the checked employee details is stored here
  exportDisable = true; // to disable export button
  isDevMode: boolean = false;
  isDropdownVisible: boolean = false; // Initially, the dropdown is hidden
 
  filesArrayComp: any;
  isFormCanceled = false;
  templateGet:any;
  leaveTypeList:any[]=[];
  availDays:any;
  employeeId:any;
  SelectedEmpleaveHistory : any;
  noOfCasualLeave: any;
  noOfSickLeave: any;
  selectedEmpleaveHistory:any;
  companyWorkingHours: number = -1;
  errorMessage: any;
  companyName: string = ''; // This needs to be a global value rather than declaring in each ts file.
  templateDownload = Constants.DOWNLOAD_TEMPLATE;
  leaveType :any;
  companyId = this.globalValues.orgId;
  
  // In your component.ts file
  leave: string = ''; // This will hold the selected leave type
  modalOpen: boolean = false;
  overtimeHours: number = 0;
  hyperLinkColumns = ['employeeDetail']; //SK06MAR24

  @ViewChild('demoModal') demoModal!: ElementRef<any>; //to open the bootstrap modal based on click function in ts file
  @ViewChild('editAttendanceModal') editAttendanceModal!: ElementRef<any>; //to open the bootstrap modal based on click function in ts file

  @Output() alertMessageEmit = new EventEmitter<any>; // to emit success message
  @Output() errorMessageEmit = new EventEmitter<any>; // to emit failure message
  status: string = '';
  elementDate: string = '';
  defaultLeaveTypeDropDown='Select'
  tabsLists : Array<any> = []; //SK06MAR24 list of tabs to be sent to navTabs comp
  activeTab : string = ''; //SK06MAR24 active tab name
  tabContent = {name:'Attendance', value:'attendance', browsedTab:false}; //SK06MAR24 current tab content structure
  tableCopyData:any;

  @Input() employeeAttendanceData : any;
  empAttendanceDataTable:any;
  @Input() employeeListAttendance:Array<any> = []; //// SK22APR24 selected employee attendance table configurations 
  @Input() accessor:any; // SK22APR24 attendance from HRM or manager is configured
  tableTitle! : string
  attendanceStartDate:any;
  attendanceEndDate:any;
  exportFieldsListConfigs:any; //SK03OCT24
  exportFilterListConfigs:any; //SK03OCT24
  attendanceFilter:any; //SK03OCT24
  filterScreenConfigs:Array<any> = []; //SK03OCT24
  filterStartDate!:string; //SK03OCT24
  filterEndDate!:string; //SK03OCT24

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private errorHandler: ErrorHandlerService, private globalValues: GlobalValuesService, private alertService: AlertService, private datePipe: DatePipe, private router: Router, private routeActive:ActivatedRoute, private spinnerService: NgxSpinnerService) {
    this.isDevMode = isDevMode();
    // form for attendance to be edited
    // SK18JUN24 checkin, checkout and comments added
    this.editAttendanceForm = this.formBuilder.group({
     editAttendance: [''],
     halfDay:[false],
     status:['', Validators.required],
     checkIn:[''],
     checkOut:[''],
     comments:[''],
     overTimeHours: [0],
     date:['']
    });

    // SK02SEP24 to get the payroll dates from settings
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'hrm'){
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'attendance'){
              modArr.tabs.forEach((tabArr:any) => {
                if(tabArr.name === 'employee'){
                  this.employeeAttendanceData = tabArr.config;
                } 
              });
            }
          });
        }
      });
    }
    if(this.employeeAttendanceData) {
      this.empAttendanceDataTable = this.employeeAttendanceData
    } else {
      this.empAttendanceDataTable = {
        "headerLabels":['Date', 'Day', 'Check In', 'Check Out',  'Status', 'Working Hours','Over Time', 'Comments'],
        "displayedColumns":['formattedDate','day', 'checkIn', 'checkOut',  'status', 'workingHours', 'overTime','comments'],
        editColumn:[
          {
            name:"status", 
            type:"dropdown", 
            values:[
              {name:"Nil",value:""},
              {name:"Present",value:"present"},
              {name:"Absent",value:"absent"},
              {name:"Half Day",value:"halfDay"},
              {name:"Weekoff",value:"weekoff"},
              {name:"Holiday",value:"holiday"}
            ]
          },
          {
            name:"checkIn", 
            type:"time"
          },
          {
            name:"checkOut", 
            type:"time"
          },
          {name:"comments", type:"text"},
        ],
        data:[],
        withCheckBox:true,
        fromWhere:"common",
        isButtons:true,
        actionButton: true,
        editButton:true,
        buttonsArray:[{
          "name": "Export Selected",
          "buttonType": "exportSelected",
          "class": "grey-border rounded-3 bg-white text-dark",
          "buttonAction": "downloadCSV",
          "exportFields":['formattedDate', 'checkIn', 'checkOut',  'status', 'workingHours', 'overTime','comments']
        }]
      }
    }
  }

  ngOnInit(): void {
    this.monthArray = [];
    this.yearArray = [];
    // SK06MAR24 adding the selected month in query params
    let monthQuery:any;
    this.routeActive.queryParams.subscribe(params => {
      monthQuery = params['month'];
    });
    this.month = this.getMonthString(monthQuery?.split('-')[1]) || new Date().toLocaleString("default", { month: "long" });
    this.year = monthQuery?.split('-')[0] || new Date().getFullYear();
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(i, 1); // Set the day part to the first day of the month
      date.setFullYear(new Date().getFullYear());
      this.monthArray.push(date.toLocaleString("default", { month: "long" }));
      this.yearArray.push(date.getFullYear() - i);
    }
    this.initialData();
    // SK06MAR24 check whether the tabs is stored in local
    // SK13MAR24 changed from localStorage to sessionStorage
    this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || [{name:'Attendance', value:'attendance', browsedTab:false}];
    this.activeTab = sessionStorage.getItem('activeNavTab') as string || this.tabsLists[0].value;
  }

  ngOnChanges(changes:SimpleChanges): void {
    // SK16APR24 the employee edit attendance table colums sent dynamically from parent
    // if(changes["employeeAttendanceData"]){
    //   // this.empAttendanceDataTable = {
    //   //   headerLabels: [],
    //   //   displayedColumns: [],
    //   //   editColumn:[
    //   //     {
    //   //       name:"status", 
    //   //       type:"dropdown", 
    //   //       values:[
    //   //         {name:"Nil",value:""},
    //   //         {name:"Present",value:"present"},
    //   //         {name:"Absent",value:"absent"},
    //   //         {name:"Half Day",value:"halfDay"},
    //   //         {name:"Weekoff",value:"weekoff"},
    //   //         {name:"Holiday",value:"holiday"}
    //   //       ]
    //   //     },
    //   //     {
    //   //       name:"checkIn", 
    //   //       type:"time"
    //   //     },
    //   //     {
    //   //       name:"checkOut", 
    //   //       type:"time"
    //   //     },
    //   //     {
    //   //       name:"overTime", 
    //   //       type:"text"
    //   //     },
    //   //     {name:"comments", type:"text"},
    //   //   ],
    //   //   data:[],
    //   //   withCheckBox:true,
    //   //   fromWhere:"common",
    //   //   isButtons:true,
    //   //   actionButton: true,
    //   //   editButton:true,
    //   //   buttonsArray:[{
    //   //     "name": "Export Selected",
    //   //     "buttonType": "exportSelected",
    //   //     "class": "grey-border rounded-3 bg-white text-dark",
    //   //     "buttonAction": "downloadCSV"
    //   //   }]
    //   // };
    //   this.empAttendanceDataTable = this.employeeAttendanceData;
    // }

    // SK22APR24
    if(changes["employeeListAttendance"]){
      this.initialData();
    }

    // SK22APR24
    if(changes["accessor"]){
      this.accessor = this.accessor;
    }

  }

  selectedValue(event: any, from: any) {
    this.checkedEmployees = [];
    if (from === 'year') {
      this.year = event.data;
    } else if (from === 'month') {
      this.month = event.data;
    }
    const monthArray = new Date('01' + " " + this.month + this.year); // to get selected date in date format
    this.selectedMonth = monthArray;
    const month = (this.selectedMonth.getMonth() + 1).toString().padStart(2, '0'); // Pad month with leading zero if necessary
    let queryFormat = this.year + "-" + month;
    let actNav:any;
    this.routeActive.queryParams.subscribe(params => {
      actNav = params['activeNav'];
    });
    this.router.navigate([], { queryParams: { activeNav:actNav, month: queryFormat} });
    if(this.activeTab === "attendance"){
      this.tableCalenderDays();
    } else {
      this.employeeAttendData(this.activeTab)
    }
  }

  // The employee datas that sent to table is initiall assigned here
   initialData() {
    this.tableData = [];
    this.employeeListAttendance?.forEach((element: any) => {
      const datum = element.employee;
      const name = datum?.firstName + " " + datum?.lastName;
      const employeeId = datum?.employeeId;
      const designation = datum?.workinfo?.designation;
      const location = datum?.basicinfo?.officeLocation;
      this.tableData.push({
        employeeDetail: {
          empName: name,
          empId: employeeId,
          desig: designation,
          loc: location
        },
        employeeName: name,
        employeeId: employeeId,
        designation: designation,
        location: location
      })
    });
    if(this.tableData.length > 0)this.tableCalenderDays();
  }

  getMonthNumber(monthName: string): any {
    const months: { [key: string]: number } = {
      "january": 0,
      "february": 1,
      "march": 2,
      "april": 3,
      "may": 4,
      "june": 5,
      "july": 6,
      "august": 7,
      "september": 8,
      "october": 9,
      "november": 10,
      "december": 11
    };
    return months[monthName?.toLowerCase()];
  }

  // SK06MAR24 to get the month value while passing as string
  getMonthString(monthNumber: number): any {
    const months: { [key: string]: string } = {
      "01":"January",
      "02":"February",
      "03":"March",
      "04":"April",
      "05":"May",
      "06":"June",
      "07":"July",
      "08":"August",
      "09":"September",
      "10":"October",
      "11":"November",
      "12":"December"
    };
    return months[monthNumber];
  }

  getData() {
    this.SelectedEmpleaveHistory = [];
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
  
    // this.apiService.fetchValue('/hrm',params).subscribe((datas: any) => {
    //   this.templateGet  = datas?.loggedInUser?.templates;
    //   this.SelectedEmpleaveHistory = datas?.leaves;
    //   this.leaveTypeList = [];
    //   this.templateGet?.forEach((element: any) => {
    //     if(element.noOfDays >= 0.5){
    //     this.leaveTypeList.push(element.leaveType)
    //    }
    //   });
    //   this.leaveTypeList = [...this.leaveTypeList,'Absent'];
    // });
    // SK18JUN24 only absent is kept in leave type list
    this.leaveTypeList = ['Absent'];
    this.templateGet = ['Absent'];
  }

  settingsData:any;
  // The dates of selected month is assigned as an object in 'table data' array
  tableCalenderDays() {
    // SK10APR24 moved from initialData() function, to update the table in every month or year selected
    const selectedMonth = new Date(this.year+"-"+this.month+"-"+"1");
    this.setPayrollDates(selectedMonth)
    var datesArray = []; 
    const sDate : any = new Date(JSON.parse(this.attendanceStartDate));
    const eDate : any = new Date(JSON.parse(this.attendanceEndDate));
    var incrementDate = new Date(this.year, this.getMonthNumber(this.month), 1);
    const daysInMnth = (eDate - sDate) / (1000 * 60 * 60 * 24);
    //assiging keys of column to be displayed in table
    this.displayedColumns = ['employeeDetail'];

    // SK02SEP24 assigning values from the startDate and endDate
    // This is used to set the table header label dynamically based on selected month
    if (sDate.getTime()) {
      datesArray.push({ date: 'Employee', day: '' });
      for (let i = 1; i <= daysInMnth; i++) {
        const currentDate = sDate.getDate(); // to get the date from the new Date format
        this.displayedColumns.push(String(currentDate));
        console.log("dateAdde",this.displayedColumns)
        if(this.tableData.length > 0){
          for (let i = 0; i < this.tableData.length; i++) {
            const key = String(currentDate);
            const keyObj:any = {};
            keyObj.day = key;
            keyObj.month = sDate.getMonth()+1;
            keyObj.year = sDate.getFullYear();
            keyObj.value = "";
            Object.assign(this.tableData[i], { [key]: keyObj});
            // Object.assign(this.tableData[i], { [key]: ""});
          }         
        }
        const currentDay = sDate.toLocaleString("default", { weekday: 'narrow' }); // to get the day in words from new Date
        datesArray.push({ date: currentDate, day: currentDay, month:sDate.getMonth()+1, year:sDate.getFullYear() });
        sDate.setDate(sDate.getDate() + 1);

      }
      // datesArray.push({date:'Total Hours', day:''}) /// To be used in future
      this.headerLabels = datesArray;
    }

    this.tableCopyData = JSON.stringify(this.tableData);
    this.finalTableData = this.tableData;
    this.exportDisable = true;
    this.selectedMonth = selectedMonth;
    const month = (this.selectedMonth.getMonth() + 1).toString().padStart(2, '0'); // Pad month with leading zero if necessary
    let queryFormat = this.year + "-" + month;
    if (queryFormat) {
      const parameter = new HttpParams({
        fromObject: {
          month: queryFormat,
          accessor:this.accessor,
          startDate:JSON.parse(this.attendanceStartDate), //SK02SEP24 params sent to get attendanace
          endDate:JSON.parse(this.attendanceEndDate)
        }
      });
      this.apiService.fetchValue('/hrm/attendance', parameter).subscribe((data: any) => {
        console.log("fetchData", data);
        var filterEmp : any = [];
        JSON.parse(this.tableCopyData).forEach((emp:any) => {
          const filtData = data.filter((filt:any)=>filt.employeeId === emp.employeeId);
          if(filtData.length > 0)filterEmp.push(filtData[0]);
        });
        const tableData = this.tableCopyData ? JSON.parse(this.tableCopyData) : [];
        filterEmp.forEach((element: any) => {
          const empId = element.employeeId;
          if(tableData?.length > 0){
            const tableIndex = tableData.findIndex((data: any) => { return empId === data.employeeId })
            const indexValue = tableData[tableIndex];
            const today = new Date().setHours(0, 0, 0);
            element.attendanceData = element;
           // Finally, here the attendance for every date for each employees based on selected month are assigned here
            element.attendance.forEach((elementData: any) => {
              const elementDate = new Date(elementData.date).setHours(0, 0, 0);//  "2023-09-01T00:00:00.000Z"
              if (elementDate <= today || (elementData.status).toLowerCase() != 'present') {
                indexValue[String(new Date(elementData.date).getDate())]["value"] = elementData; //SK02SEP24 assigning the element in value key
                // indexValue[String(new Date(elementData.date).getDate())] = elementData;
              }
            });
          }
        });
        this.finalTableData = tableData; // this 'finalTableData' is sent to table
        this.globalValues.attendancePass(this.finalTableData);
      });
    }
  }
  // to download the attendance template form file upload modal
  downloadTemplate(templateType: any) {
    this.apiService.getCSVTemplates(templateType)
      .subscribe({
        next: (v: any) => {
          if (this.isDevMode) console.log(v);
          const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
          saveAs(file, 'attendance.csv');  //name should be dynamic
          if (templateType == 'user') this.saveSucces('User' + this.templateDownload);
          if (templateType == 'employee') this.saveSucces('Employees' + this.templateDownload);
          if (templateType == 'attendance') this.saveSucces('Attendance ' + this.templateDownload);
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
          this.errorMsg(e);
        },
        complete: () => console.info('complete')
      });
  }

  showDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  hideDropdown() {
    this.isDropdownVisible = false;
  }

  // The file will be emitted in this event form  'file-upload' component
  userFileEmitter(event: any) {
    this.employeeBulkFile = event;
  }

  // Upload the excel to add data to Attendance record
  uploadRecordsFile() {
    const fd = new FormData();
    fd.append('file', this.employeeBulkFile, this.employeeBulkFile.name);
    this.uploadAttendanceFile(fd);
  }

  // the 'file-upload' modal will be opened on this clicking this function
  emitImportButton() {
    this.modalDirect = new Modal(this.demoModal.nativeElement, { backdrop: 'static' });
    this.modalDirect.show();
  }

  // the 'edit-attendance' modal will be opened on this clicking this function
  doubleClickData(event: any) {
    this.modalDirect = new Modal(this.editAttendanceModal.nativeElement, { backdrop: 'static' });
    this.modalDirect.show();
    this.attendanceObject = event;
    this.employeeId= event.employeeDetail.empId;
    this.getData();
    this.editedEmployee = event.employeeDetail.empName;
    this.editedDate = event?.data?.year + "-" + event?.data?.month + "-" + event?.data?.day;
    // SK18JUN24 checkin, checkout and comments added
    if(event?.data?.value === ""){
      this.modalOpen = true;
      this.editAttendanceForm.patchValue({
        overTimeHours: event?.data?.value?.overTime || 0,
        status: "present",
        editAttendance: "present",
        checkIn: "",
        checkOut: "",
        comments: "",
        date:this.editedDate,
      })
    } else {
      if(((event?.data?.value?.status).toLowerCase()).trim() === 'present' || ((event?.data?.value?.status).toLowerCase()).trim() === 'weekoff' || ((event?.data?.value?.status).toLowerCase()).trim() === 'holiday'){
        this.modalOpen = true;
        let checkIn = "";
        let checkOut = "";
        if(isNaN(new Date(event?.data?.value?.checkInString)as any) ){
          checkIn = (event?.data?.value?.checkInString)?.split(":")[0]?.padStart(2,'0') + ":" + (event?.data?.value?.checkInString)?.split(":")[1]?.padEnd(2,'0') || "";
        } else {
          checkIn = String(new Date(event?.data?.value?.checkInString).getHours()).padStart(2,"0") + ":" + String(new Date(event?.data?.value?.checkInString).getMinutes()).padStart(2,"0");
        }
        if(isNaN(new Date(event?.data?.value?.checkOutString)as any) ){
          checkOut =(event?.data?.value?.checkOutString)?.split(":")[0]?.padStart(2,'0') + ":" + (event?.data?.value?.checkOutString)?.split(":")[1]?.padEnd(2,'0') || "";
        } else {
          checkOut = String(new Date(event?.data?.value?.checkOutString).getHours()).padStart(2,"0") + ":" + String(new Date(event?.data?.value?.checkOutString).getMinutes()).padStart(2,"0");
        }
        this.editAttendanceForm.patchValue({
          overTimeHours: event?.data?.value?.overTime || 0,
          status: ((event?.data?.value?.status).toLowerCase()).trim(),
          editAttendance: event?.data?.value?.status,
          checkIn: checkIn,
          checkOut: checkOut,
          comments: event?.data?.value?.comments,
          date: this.editedDate,
        })
      } else if((event?.data?.value?.halfDay)){
        this.modalOpen = true;
        let checkIn = "";
        let checkOut = "";
        if(isNaN(new Date(event?.data?.value?.checkInString)as any) ){
          checkIn = (event?.data?.value?.checkInString)?.split(":")[0]?.padStart(2,'0') + ":" + (event?.data?.value?.checkInString)?.split(":")[1]?.padEnd(2,'0') || "";
        } else {
          checkIn = String(new Date(event?.data?.value?.checkInString).getHours()).padStart(2,"0") + ":" + String(new Date(event?.data?.value?.checkInString).getMinutes()).padStart(2,"0");
        }
        if(isNaN(new Date(event?.data?.value?.checkOutString)as any) ){
          checkOut =(event?.data?.value?.checkOutString)?.split(":")[0]?.padStart(2,'0') + ":" + (event?.data?.value?.checkOutString)?.split(":")[1]?.padEnd(2,'0') || "";
        } else {
          checkOut = String(new Date(event?.data?.value?.checkOutString).getHours()).padStart(2,"0") + ":" + String(new Date(event?.data?.value?.checkOutString).getMinutes()).padStart(2,"0");
        }
        this.editAttendanceForm.patchValue({
          overTimeHours: event?.data?.value?.overTime || 0,
          status: ((event?.data?.value?.status).toLowerCase()).trim(),
          editAttendance: "halfDay",
          checkIn: checkIn,
          checkOut: checkOut,
          halfDay: true,
          comments: event?.data?.value?.comments,
          date: this.editedDate,
        })
      } else {
        this.modalOpen = false;
        this.isDropdownVisible = true;
        this.defaultLeaveTypeDropDown = event?.data?.value?.status;
        if(Constants.leaveTypes.includes(event?.data?.value?.status) && event?.data?.value?.halfDay === false || (event?.data?.value?.status).toLowerCase() === 'absent') {
          this.editAttendanceForm.controls['status'].patchValue('absent');
          this.editAttendanceForm.controls['editAttendance'].patchValue('absent');
        }
      }
    }
    
  }

  selectedValued(selectedValue:any,formName:string){
    this.defaultLeaveTypeDropDown = selectedValue.data;
    this.editAttendanceForm.controls[formName]?.patchValue(this.editAttendanceForm.controls['status']?.value);
    this.editAttendanceForm.controls['editAttendance']?.patchValue(selectedValue.data);
    const selectedLeave = this.templateGet.filter((data:any) => selectedValue.data === data.leaveType);
    this.leaveType = selectedLeave[0]?.leaveType;
    this.availDays = selectedLeave[0]?.noOfDays;
    if(Constants.leaveTypes.includes(selectedValue.data) || selectedValue.data === 'Absent'){
      this.selectLeaveType = false;
    } else {
      this.selectLeaveType = true;
    }
  }

  selectLeaveType = false;
  onRadioChange(event:any) {
    this.leaveType = '';
    this.availDays = '';
    this.defaultLeaveTypeDropDown = 'Select';
    this.editAttendanceForm.controls['editAttendance']?.patchValue(event.target.value);
    // // SK18JUN24 validation done for halfDay status
    if(event.target.value === 'absent') {
      this.editAttendanceForm.controls['status']?.patchValue(event.target.value);
      this.isDropdownVisible = true;
      this.modalOpen = false;
      this.editAttendanceForm.controls['halfDay']?.patchValue(false);
    } else {
      this.isDropdownVisible =false;
      this.editAttendanceForm.controls['status']?.patchValue(event.target.value);
      this.modalOpen = true;
      if( event.target.value === 'halfday'){      
        this.editAttendanceForm.controls['halfDay']?.patchValue(true);
      } else {
        this.editAttendanceForm.controls['halfDay']?.patchValue(false);
      }
    }

    if(this.editAttendanceForm.controls['editAttendance']?.value === 'absent' || this.editAttendanceForm.controls['editAttendance']?.value === 'halfday'){
      this.selectLeaveType = true;
    } else {
      this.selectLeaveType = false;
    }
  }

  resetForm() {
    this.isDropdownVisible = false;
    this.editAttendanceForm.reset();
    this.leaveType = '';
    this.availDays = '';
    this.defaultLeaveTypeDropDown = 'Select';
  }

  // The save button function in 'edit-attendance' modal triggers this function
  attendanceGet() {
    const value = this.editAttendanceForm.value;
    const employeeId = this.attendanceObject?.employeeDetail?.empId || '';
    const month = String(this.attendanceObject?.data?.month).padStart(2, '0');
    const date = this.attendanceObject?.data?.year + "-" + month + "-" + String(this.attendanceObject?.data?.day).padStart(2, '0');
    const checkIn = new Date(date).setHours((value?.checkIn?.split(":")[0] || 0), (value?.checkIn?.split(":")[1] || 0), 0) //SK03JUL24 setting checkin time in date
    const checkOut = new Date(date).setHours((value?.checkOut?.split(":")[0] || 0), (value?.checkOut?.split(":")[1] || 0), 0) //SK03JUL24 setting checkout time in date
    // SK18JUN24 saving checkin, checkout and comments
    const data = {
      "employeeData": [
        {
          "employeeId": employeeId,
          "date": date,
          "status": value.editAttendance,
          "checkIn": new Date(checkIn).toUTCString(), //SK03JUL24 sending as UTC
          "checkOut": new Date(checkOut).toUTCString(),
          'halfDay':value.halfDay,
          "overTime":value.overTimeHours,
          "companyId": this.globalValues.orgId,
          "comments":value.comments,
          "attendanceSelectedMonth": this.year +"-"+ String(this.getMonthNumber(this.month.toLowerCase()) + 1).padStart(2,"0")
        } 
      ]
    };   
    // this.spinnerService.show();
    // // SK18JUN24 changed the enpoint endpoint 
    this.apiService.writeValue('patch','/hrm/attendance/employee', data)
       .subscribe({
         next: (attendance) => {
          // this.spinnerService.hide();
          if (this.isDevMode) console.log("Next :", attendance);
          this.tableCalenderDays();
          this.saveSucces('Attendance has been updated successfully');      
        },
        error: (err: HttpErrorResponse) => {
          // this.spinnerService.hide();
          console.error("upload failed with ", err);
          this.errorMsg(err);
        }
      });
     
  }
 
  calculateCheckInTime(currentTime:Date) {
     return this.generateFormattedTime(9, 0); // Default check-in time is 9:00 AM
  }

  addHoursToTime(timeString:string, hoursToAdd:number) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const newHours = hours + hoursToAdd;
    // Calculate the new time, accounting for exceeding 24 hours
    const newTime = `${(newHours % 24).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return newTime;
  }
  
  // Function to generate a formatted time string
  generateFormattedTime(hours:number, minutes:number) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
    
  // uploaded file will be sent to the api here
  uploadAttendanceFile(data: any) {
    // this.spinnerService.show();
    this.apiService.uploadAttendance(data, this.accessor).subscribe({
      next: (response: any) => {
        // this.spinnerService.hide();
        if (this.isDevMode) console.log("Attendance upload completed ", response);
        this.saveSucces('Attendance has been uploaded successfully');      
        this.tableCalenderDays();
        this.filesArrayClear();
      },
      error: (e: HttpErrorResponse) => {
        // this.spinnerService.hide();
        console.error("upload failed with ", e);
        this.errorMsg(e);
      },
      complete: () => console.info('upload completed')
    })
  }

  disableExport = true;
  /// the checked emoployees is stored in a variable
  tableCheckedDataa(event: any) {
    this.checkedEmployees = event;
    if (this.checkedEmployees.length === 0) {
      this.exportDisable = true;
    } else {
      this.exportDisable = false;
    }
  }

  // SK03OCT24 export report
  // the download the selected employees attendance details for that selected month in .csv format
  downloadCurrentEmployees() {
    this.apiService.fetchData("/settings/reports?reportName=attendance").subscribe((report:any)=>{
      this.attendanceFilter = report;
      this.filterScreenConfigs = [];

      // SK03OCT24 configs for side sceen filters
      let exportFieldsList:any = {};
      let objs = report.exportFields
      let displayValues = {
        keyString:"name",
        valueString:"value"
      }
      exportFieldsList.type = "multipleCheckBox";
      exportFieldsList.displayValues = displayValues;
      exportFieldsList.label = "Select Fields to Export";
      exportFieldsList.cardSize = "col-md-12";
      exportFieldsList.cardInlineSize = "col-md-6";
      exportFieldsList.checkboxList = objs.allFields;
      exportFieldsList.activeCheckboxList = objs.selectedFields;
      exportFieldsList.checkBoxStyles = ""
      exportFieldsList.buttons = [
        {
          "buttonType": "",
          "name": "save",
          "label": "Save Fields",
          "class": "btn btn-primary ms-2",
          "buttonAction": "save",
          "path": "/settings/reports/attendance",
          "method": "patch",
          "patchKey": "permissions",
          "isParams": "false",
          "paramsKey": "",
          "paramsValue": "",
          "successMessage": "Data Saved Successfully"
        }
      ]
      this.exportFieldsListConfigs = exportFieldsList;
      this.filterScreenConfigs.push(exportFieldsList);

      let exportDateFilterList:any = {};
      let displayDateFilterValues = {
        keyString:"name",
        valueString:"value"
      }
      exportDateFilterList.type = "multipleFilter";
      exportDateFilterList.isDateFilter = true;
      exportDateFilterList.displayValues = displayDateFilterValues;
      exportDateFilterList.label = "Select Filters";
      exportDateFilterList.cardSize = "col-md-12";
      exportDateFilterList.cardInlineSize = "col-md-12";
      exportDateFilterList.checkBoxStyles = ""
      exportDateFilterList.selectionData = report?.exportFields?.selectors || [] 
      exportDateFilterList.buttons = [
        {
          "buttonType": "",
          "name": "saveFilter",
          "label": "Save Filters",
          "class": "btn btn-primary ms-2",
          "buttonAction": "saveFilter",
          "path": "/settings/reports/employees",
          "method": "patch",
          "patchKey": "",
          "isParams": "false",
          "paramsKey": "",
          "paramsValue": "",
          "successMessage": "Data Saved Successfully"
        }
      ]
      this.exportFilterListConfigs = exportDateFilterList;
      this.filterScreenConfigs.push(exportDateFilterList);

      let exportFilterList:any = {};
      let displayFilterValues = {
        keyString:"name",
        valueString:"value"
      }

      exportFilterList.type = "multipleFilter";
      exportFilterList.displayValues = displayFilterValues;
      exportFilterList.label = "Select Filters";
      exportFilterList.cardSize = "col-md-12";
      exportFilterList.cardInlineSize = "col-md-12";
      exportFilterList.checkBoxStyles = ""
      exportFilterList.selectionData = report?.exportFields?.selectors || [] 
      exportFilterList.buttons = [
        {
          "buttonType": "",
          "name": "saveFilter",
          "label": "Save Filters",
          "class": "btn btn-primary ms-2",
          "buttonAction": "saveFilter",
          "path": "/settings/reports/employees",
          "method": "patch",
          "patchKey": "",
          "isParams": "false",
          "paramsKey": "",
          "paramsValue": "",
          "successMessage": "Data Saved Successfully"
        }
      ]
      this.exportFilterListConfigs = exportFilterList;
      this.filterScreenConfigs.push(exportFilterList);
    })
  }

  convertToCsv(objArray: any[]) {
    let objectArray: Array<any> = [];
    objArray.forEach(element => {
      objectArray.push(Object.keys(element));
    });
    const removeIndex = objectArray[0]?.indexOf('employeeDetail');
    objectArray[0].splice(removeIndex, 1);
    // const removeTotaalHours = objectArray[0]?.indexOf('totalHours');
    // objectArray[0].splice(removeTotaalHours, 1);
    var selectedObjectsForCSV = this.filterObjectKeys(objArray, objectArray[0]);
    selectedObjectsForCSV.forEach((selectedElement:any) => {
      for (const key in selectedElement) {
        if(key.length <= 2){
          if (selectedElement.hasOwnProperty(key)) { // Check if the property is an own property, not inherited
            selectedElement[key] = ((selectedElement[key].status  || 'Nil') + (selectedElement[key].halfDay ? '(Half day)' : '')) + (selectedElement[key].overTime ? '+' + (selectedElement[key].overTime || 0) : '' );
          }
        } else {
          if (selectedElement.hasOwnProperty(key)) { // Check if the property is an own property, not inherited
            selectedElement[key] = selectedElement[key];
          }
        }
      }
    });
    const attendanceArray = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    const monthAndYear = '/' + (this.selectedMonth.getMonth() + 1) + '/' + this.selectedMonth.getFullYear();
    //assigning the filter objects dynamically when the month and year selected
    const excelHeaderDetails = {
      employeeName: 'employeeName',
      employeeId: 'employeeId',
      designation: 'designation',
      location: 'location',
    }

    const excelHeaderMonths = {
      1: "1" + monthAndYear,
      2: '2' + monthAndYear,
      3: '3' + monthAndYear,
      4: '4' + monthAndYear,
      5: '5' + monthAndYear,
      6: '6' + monthAndYear,
      7: '7' + monthAndYear,
      8: '8' + monthAndYear,
      9: '9' + monthAndYear,
      10: '10' + monthAndYear,
      11: '11' + monthAndYear,
      12: '12' + monthAndYear,
      13: '13' + monthAndYear,
      14: '14' + monthAndYear,
      15: '15' + monthAndYear,
      16: '16' + monthAndYear,
      17: '17' + monthAndYear,
      18: '18' + monthAndYear,
      19: '19' + monthAndYear,
      20: '20' + monthAndYear,
      21: '21' + monthAndYear,
      22: '22' + monthAndYear,
      23: '23' + monthAndYear,
      24: '24' + monthAndYear,
      25: '25' + monthAndYear,
      26: '26' + monthAndYear,
      27: '27' + monthAndYear,
      28: '28' + monthAndYear,
    }
    if ((this.selectedMonth.getMonth() + 1) === 1 || (this.selectedMonth.getMonth() + 1) === 3 || (this.selectedMonth.getMonth() + 1) === 5 || (this.selectedMonth.getMonth() + 1) === 7 || (this.selectedMonth.getMonth() + 1) === 8 || (this.selectedMonth.getMonth() + 1) === 10 || (this.selectedMonth.getMonth() + 1) === 12) {
      const days31 = { ...excelHeaderDetails, ...excelHeaderMonths, ...{ 29: '29' + monthAndYear, 30: '30' + monthAndYear, 31: '31' + monthAndYear } }
      attendanceArray.unshift(days31)

    } else if ((this.selectedMonth.getMonth() + 1) === 4 || (this.selectedMonth.getMonth() + 1) === 6 || (this.selectedMonth.getMonth() + 1) === 9 || (this.selectedMonth.getMonth() + 1) === 11) {
      const days30 = { ...excelHeaderDetails, ...excelHeaderMonths, ...{ 29: '29' + monthAndYear, 30: '30' + monthAndYear } }
      attendanceArray.unshift(days30)
    } else if (((this.selectedMonth.getMonth() + 1) === 2) && ((this.selectedMonth.getFullYear() / 4) % 1 === 0)) {
      const days29 = { ...excelHeaderDetails, ...excelHeaderMonths, ...{ 29: '29' + monthAndYear } }
      attendanceArray.unshift(days29)
    } else {
      attendanceArray.unshift({ ...excelHeaderDetails, ...excelHeaderMonths })
    }

    for (let i = 0; i < attendanceArray.length; i++) {
      let line = '';
      const objKeys = Object.keys(attendanceArray[i]);
      const spliceEmployeDetails = objKeys.splice(objKeys.length - 4);
      for (let j = 0; j < spliceEmployeDetails.length; j++) {
        if (line !== '') {
          line += ',';
        }
        if (typeof attendanceArray[i][spliceEmployeDetails[j]] == 'object') {
          line += JSON.stringify(attendanceArray[i][spliceEmployeDetails[j]]).replace(/,/g, "++");
          if (this.isDevMode) console.log("a", attendanceArray[i][spliceEmployeDetails[j]]);
        } else if (spliceEmployeDetails[j] === "1") {
          line += JSON.stringify(attendanceArray[i][spliceEmployeDetails[j]]).replace(/,/g, "++");
          if (this.isDevMode) console.log("a", attendanceArray[i][spliceEmployeDetails[j]]);
        }
        else {
          line += attendanceArray[i][spliceEmployeDetails[j]];
        }
      }
      for (let k = 0; k < objKeys.length; k++) {
        if (line !== '') {
          line += ',';
        }
        if (typeof attendanceArray[i][objKeys[k]] == 'object') {
          line += JSON.stringify(attendanceArray[i][objKeys[k]]).replace(/,/g, "++");
          if (this.isDevMode) console.log("a", attendanceArray[i][objKeys[k]]);
        } else if (objKeys[k] === "1") {
          line += JSON.stringify(attendanceArray[i][objKeys[k]]).replace(/,/g, "++");
          if (this.isDevMode) console.log("a", attendanceArray[i][objKeys[k]]);
        }
        else {
          line += attendanceArray[i][objKeys[k]];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: any) { // keys are the reuired fields set by us 
    let filteredObjects: any = [];
    arrayOfObjects.forEach((obj: any, i: any) =>   // Should check for error scenario, arrayofObjects will be null / undeifned
      filteredObjects.push(keys.reduce((acc: any, key: any) => {
        if (obj.hasOwnProperty(key)) {
          acc[key] = obj[key];
        }
        return acc;
      }, {})));
    return filteredObjects;
  }

  saveSucces(data: any) {
    this.alertService.messageOnPass('success', data)
  }

  errorMsg(data: any) {
    this.errorHandler.handleError(data);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage)
  }

  // this is to clear the filename array in child component
  filesArrayClear() {
    this.filesArrayComp = [];
    this.employeeBulkFile = null || undefined;
  }

  deletedFileInComp(event: any) {
    this.filesArrayClear();
  }

  openOvertimeHoursModal() {
    this.overtimeHours=0;
    this.modalOpen = true;
  }

  closeOvertimeHoursModal() {
    this.modalOpen = false;
  }

  submitOvertimeHoursForm() {
    // Handle the submitted overtimeHours as needed
    console.log('Overtime hours submitted:', this.overtimeHours);
    this.closeOvertimeHoursModal();
  }

  // SK06MAR24 logics on clicking the hyperlink of employee
  hyperLinkClick(event:any){
    const tab = {
      name: event.element.employeeId,
      value: event.element.employeeId,
      browsedTab: true
    }
    this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || this.tabsLists;
    const filterTab = this.tabsLists.filter((tab1:any)=>tab1.value === tab.value)
    if(filterTab.length === 0)this.tabsLists.push(tab);
    this.globalValues.navTabData({tabsList:this.tabsLists, hideTabList:[], activeTab:tab.value})
    this.emitTabData({data:tab, allData:this.tabsLists});
  }

  // SK06MAR24 on selecting tab from child this function gets the employee attendance details on passing employeeId as params
  // SK06MAR24 this will be shown in separate tabs and the details will be losted in datatable
  
  emitTabData(event:any){
    this.tabContent = event.data;
    this.activeTab = event.data.value;
    // SK10APR24 calling the function based in active tab
    if(event.data.value === "attendance"){
      this.tableCalenderDays();
    } else {
      this.employeeAttendData(event.data.value)
    }
    
  }

  // SK06MAR24 to check the employees 
  checkedList:Array<any> = [];
  selectedEmployeeCheckedDataa(event:any){
    this.checkedList = [];
    this.checkedList = event;
  }

  // SK06MAR24 converting employee attendance data to csv
  // SK02SEP24 the download csv is done using global values service
  convertEmpToCSV(data: any[], headers:any) {
    const csv = this.globalValues.convertToCsv2(this.checkedList, headers);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'exported_data.csv');
    this.saveSucces('Data exported successfully')
    // const headers = Object.keys(data[0]).join(',');
    // const csvData = data.map(item => Object.values(item).join(',')).join('\n');
    // return headers + '\n' + csvData;
  }

  // SK06MAR24 downloading csv
  downloadEmpCsv(event:any) {
    if(event.buttonAction === "downloadCSV"){
      const csv :any= this.convertEmpToCSV(this.checkedList, event?.exportFields);
    }
    
    // const blob = new Blob([csv], { type: 'text/csv' });
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'data.csv';
    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
    // document.body.removeChild(a);
  }

  employeeAttendData(empId:string){
    const selectedMonth = new Date(this.year+"-"+this.month+"-"+"1");
    this.setPayrollDates(selectedMonth)
    const sDate = JSON.parse(this.attendanceStartDate);
    const eDate = JSON.parse(this.attendanceEndDate);
    const monthArray = new Date(JSON.parse(this.attendanceStartDate)); // to get selected date in date format
    this.selectedMonth = monthArray;
    const month = (this.selectedMonth.getMonth() + 1).toString().padStart(2, '0'); // Pad month with leading zero if necessary
    const queryFormat = this.year + "-" + month;
    // this.empAttendanceDataTable.headerLabels = this.employeeAttendanceData?.headerLabels;
    // this.empAttendanceDataTable.displayedColumns = this.employeeAttendanceData?.displayedColumns;
    this.empAttendanceDataTable.displayedColumns
    this.apiService.fetchValue('/hrm/attendance?employeeid='+empId+'&month='+queryFormat+'&accessor='+this.accessor+'&startDate='+sDate+'&endDate='+eDate).subscribe((getEmpAttendance:any)=>{
      const settings = JSON.parse((localStorage.getItem('settingsData'))as any);
      // const monthArray = new Date(this.year, this.getMonthNumber(this.month), 1);
      // const monthDays = new Date(this.year, monthArray.getMonth() + 1, 0).getDate(); // To get number of days in that selected month
      var datesArray = [];

      // var incrementDate = new Date(this.year, this.getMonthNumber(this.month), 1);
      // if (incrementDate.getTime()) {
      //   for (let i = 1; i <= monthDays; i++) {
      //     const currentDate = incrementDate.getDate(); // to get the date from the new Date format
      //     const currentDay = incrementDate.toLocaleString("default", { weekday: 'narrow' }); // to get the day in words from new Date
      //     datesArray.push({ date: currentDate, day: currentDay });
      //     incrementDate.setDate(monthArray.getDate() + i);
      //   }
      // }

      const sDate : any = new Date(JSON.parse(this.attendanceStartDate));
      const eDate : any = new Date(JSON.parse(this.attendanceEndDate));
      var incrementDate = new Date(this.year, this.getMonthNumber(this.month), 1);
      const daysInMnth = (eDate - sDate) / (1000 * 60 * 60 * 24);
      // This is used to set the table header label dynamically based on selected month
      if (sDate.getTime()) {
        for (let i = 1; i <= daysInMnth; i++) {
          const currentDate = sDate.getDate(); // to get the date from the new Date format
          const currentDay = sDate.toLocaleString("default", { weekday: 'narrow' }); // to get the day in words from new Date
          datesArray.push({ date: currentDate, day: currentDay, month:sDate.getMonth()+1, year:sDate.getFullYear() });
          sDate.setDate(sDate.getDate() + 1);
        }
      }

      let attend:Array<any> = [];
      //  SK03JUL24 local time zone fetch
      const date = new Date();
      const offset = date.getTimezoneOffset();
      const absoluteOffset = Math.abs(offset);
      const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, '0');
      const minutes = String(absoluteOffset % 60).padStart(2, '0');
      const sign = offset <= 0 ? '+' : '-';
      const localTimeZone = `${sign}${hours}${minutes}`;

      datesArray.forEach((dates:any)=>{
        const dateFilter = getEmpAttendance[0]?.attendance.findIndex((dateFilt:any)=> this.datePipe.transform(dateFilt.date, 'MM/dd/yyyy') === this.datePipe.transform(dates.year + "-" + dates.month + "-" + dates.date, 'MM/dd/yyyy' ));
        if(dateFilter >= 0){
          const format = 'HH:mm:ss';
          const timezone = settings?.localization?.timezone || localTimeZone;

          // SK03JUL24 handling file upload checkIn and checkOut here
          let checkInValue :any;
          let checkOutValue :any;
          if(isNaN(new Date(getEmpAttendance[0].attendance[dateFilter].checkInString) as any)){
            checkInValue = String((getEmpAttendance[0].attendance[dateFilter].checkInString)?.split(":")[0]).padStart(2,"0") +":"+  String((getEmpAttendance[0].attendance[dateFilter].checkInString)?.split(":")[1]).padStart(2,"0") || NaN
          } else {
            checkInValue = String(new Date(getEmpAttendance[0].attendance[dateFilter].checkIn)?.getHours())?.padStart(2,'0') + ":" + String(new Date(getEmpAttendance[0].attendance[dateFilter].checkIn)?.getMinutes())?.padStart(2,'0') || ""
          }
          if(isNaN(new Date(getEmpAttendance[0].attendance[dateFilter].checkOutString) as any)){
            checkOutValue = String((getEmpAttendance[0].attendance[dateFilter].checkOutString)?.split(":")[0]).padStart(2,"0") +":"+  String((getEmpAttendance[0].attendance[dateFilter].checkOutString)?.split(":")[1]).padStart(2,"0")|| NaN
          } else {
            checkOutValue = String(new Date(getEmpAttendance[0].attendance[dateFilter].checkOut)?.getHours())?.padStart(2,'0') + ":" + String(new Date(getEmpAttendance[0].attendance[dateFilter].checkOut)?.getMinutes())?.padStart(2,'0') || ""
          }

          // const checkIn = getEmpAttendance[0].attendance[dateFilter].checkIn || NaN
          // const checkOut = getEmpAttendance[0].attendance[dateFilter].checkOut || NaN
          attend?.push({
            formattedDate: this.datePipe.transform(getEmpAttendance[0].attendance[dateFilter].date, settings?.localization?.dateFormat),
            date: getEmpAttendance[0].attendance[dateFilter].date,
            day: new Date(getEmpAttendance[0].attendance[dateFilter].date).toLocaleString("default", { weekday: 'long' }),
            status:getEmpAttendance[0].attendance[dateFilter].status || "Nil",
            halfDay:getEmpAttendance[0].attendance[dateFilter].halfDay ? 'Yes' : 'No',
            overTime:getEmpAttendance[0].attendance[dateFilter].overTime,
            workingHours:getEmpAttendance[0].attendance[dateFilter].workingHours,
            comments:getEmpAttendance[0].attendance[dateFilter].comments || "",
            // checkIn: (getEmpAttendance[0].attendance[dateFilter].checkInString?.split(":")[0]?.padStart(2,'0') + ":" + getEmpAttendance[0].attendance[dateFilter].checkInString?.split(":")[1]?.padEnd(2,'0')) || "",
            // checkOut: (getEmpAttendance[0].attendance[dateFilter].checkOutString?.split(":")[0]?.padStart(2,'0') + ":" + getEmpAttendance[0].attendance[dateFilter].checkOutString?.split(":")[1]?.padEnd(2,'0'))  || ""
            // checkIn: String(new Date(checkIn)?.getHours())?.padStart(2,'0') + ":" + String(new Date(checkIn)?.getMinutes())?.padStart(2,'0') || "",
            // checkOut: String(new Date(checkOut)?.getHours())?.padStart(2,'0') + ":" + String(new Date(checkOut)?.getMinutes())?.padStart(2,'0') || "",
            checkIn: checkInValue,
            checkOut: checkOutValue,
            punchIns:getEmpAttendance[0].attendance[dateFilter].punchIns || [],
            tracks:getEmpAttendance[0].attendance[dateFilter].tracks || [],
            geoLocationComment:getEmpAttendance[0].attendance[dateFilter].geoLocationComment || ""
          })
          getEmpAttendance[0]?.attendance.splice(dateFilter,1);
        } else {
          // SK10APR24 selected employee attendance detail displaying for the whole month
          const format = 'HH:mm:ss';
          const timezone = settings?.localization?.timezone || "";
          attend?.push({
            formattedDate: this.datePipe.transform(dates.year + "-" + dates.month + "-" + dates.date, settings?.localization?.dateFormat ),
            date: new Date(dates.year + "-" + dates.month + "-" + dates.date).toISOString(),
            day: new Date(new Date(dates.year + "-" + dates.month + "-" + dates.date).toISOString()).toLocaleString("default", { weekday: 'long' }),
            status:"Nil",
            halfDay:'No',
            overTime:0,
            workingHours:0,
            comments:"",
            checkIn:"",
            checkOut:"",
            punchIns:[],
            tracks:[],
            geoLocationComment:[]
          })
        }
        this.empAttendanceDataTable.data = attend;
      })
    })
  }

  // SK10APR24 in employee attendance tab, data is edited and saved in that specific employee for that selected month 
  saveEmpAttendanceData(event:any){
    let data:any = {employeeData:[]};
    // SK03JUL24
    event.selectedData.forEach((list:any)=>{
      const datePipe : any = this.datePipe.transform(list.date,'yyyy-MM-dd');
      const checkIn = new Date(datePipe).setHours(list?.checkIn?.split(":")[0], list?.checkIn?.split(":")[1], 0)
      const checkOut = new Date(datePipe).setHours(list?.checkOut?.split(":")[0], list?.checkOut?.split(":")[1], 0)
      data.employeeData.push(
        {
          "employeeId": sessionStorage.getItem('activeNavTab') as string,
          "date": datePipe,
          "status": list.status,
          "checkIn":new Date(checkIn).toUTCString() || null,
          "checkOut":new Date(checkOut).toUTCString() || null,
          'halfDay':list.halfDay,
          "overTime":list.overTime,
          "comments": list.comments,
          "companyId": this.globalValues.orgId,
          "attendanceSelectedMonth": this.year +"-"+ String(this.getMonthNumber(this.month.toLowerCase()) + 1).padStart(2,"0")
        } 
      );
    });
    // this.spinnerService.show();
    this.apiService.writeValue('patch','/hrm/attendance/employee', data)
      .subscribe({
        next: (attendance) => {
        // this.spinnerService.hide();
        if (this.isDevMode) console.log("Next :", attendance);
        const tabList = JSON.parse(sessionStorage.getItem('navTabsList')as any);
        const tabs = tabList.filter((tab:any)=> tab.name === sessionStorage.getItem('activeNavTab'));
        this.emitTabData({allData:tabList, data:tabs[0]});
        this.saveSucces('Attendance has been updated successfully');      
      },
      error: (err: HttpErrorResponse) => {
        // this.spinnerService.hide();
        console.error("upload failed with ", err);
        this.errorMsg(err);
      }
    });
  }

  // SK02SEP24 set the payroll dates from settings or defaulr selected month
  setPayrollDates(selectedMonth:any){
    this.settingsData = JSON.parse(localStorage.getItem("settingsData") as any) ; 
    const payDates = this.settingsData?.finance?.payrollDates ;
    if(payDates?.startDate && payDates?.endDate){
      if(payDates.startDateMonth === "previousMonth"){
          let day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth() - 1)
          const year = new Date(day).getFullYear();
          const month = new Date(day).getMonth();
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
          if(payDates?.startDate > lastDayOfMonth ) {
            payDates.startDate = 1
            day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth())
          }
          this.attendanceStartDate = new Date(new Date(day).getFullYear(), (new Date(day).getMonth()), payDates.startDate);
      } else if(payDates.startDateMonth === "currentMonth"){
          let day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth())
          const year = new Date(day).getFullYear();
          const month = new Date(day).getMonth();
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
          if(payDates?.startDate > lastDayOfMonth ) {
            payDates.startDate = 1
            day = (new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth())) + 1
          }
          this.attendanceStartDate = new Date(new Date(day).getFullYear(), (new Date(day).getMonth()), payDates.startDate);
      } 
      // else if(payDates.startDateMonth === "nextMonth"){
      //     const day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth() + 1)
      //     console.log("nextMonth", new Date(day))
      //     const year = new Date(day).getFullYear();
      //     const month = new Date(day).getMonth();
      //     // Create a new date object set to the last day of the month
      //     const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
      //     if(payDates?.startDate > lastDayOfMonth ) {
      //       payDates.startDate = 1;
      //     }
      //     this.attendanceStartDate = new Date(new Date(day).getFullYear(), (new Date(day).getMonth()+1), payDates.startDate);
      // }

      if(payDates.endDateMonth === "previousMonth"){
          let day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth() - 1)
          const year = new Date(day).getFullYear();
          const month = new Date(day).getMonth();
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
          if(payDates?.endDate > lastDayOfMonth ) {
            payDates.endDate = lastDayOfMonth
            day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth() - 1)
          }
          this.attendanceEndDate = new Date(new Date(day).getFullYear(), (new Date(day).getMonth()-1), (payDates.endDate + 1));
      } else if(payDates.endDateMonth === "currentMonth"){
          let day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth())
          const year = new Date(day).getFullYear();
          const month = new Date(day).getMonth();
          const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
          if(payDates?.endDate > lastDayOfMonth ) {
            payDates.endDate = lastDayOfMonth
            day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth())
          }
          this.attendanceEndDate = new Date(new Date(day).getFullYear(), (new Date(day).getMonth()), (payDates.endDate + 1));
      } 
      // else if(payDates.endDateMonth === "nextMonth"){
      //     const day = new Date(selectedMonth).setMonth(new Date(selectedMonth).getMonth() + 1)
      //     console.log("nextMonth", new Date(day))
      //     const year = new Date(day).getFullYear();
      //     const month = new Date(day).getMonth();
      //     if(payDates?.endDate >= (new Date(day).getDate()) ) {
      //       payDates.endDate = 1;
      //     }
      //     this.attendanceEndDate  = new Date(new Date(day).getFullYear(), (new Date(day).getMonth()), (payDates.endDate + 1));
      // } 
      this.attendanceStartDate = JSON.stringify(this.attendanceStartDate);
      this.attendanceEndDate = JSON.stringify(this.attendanceEndDate);

      const sDate = JSON.parse(this.attendanceStartDate);
      const eDate = JSON.parse(this.attendanceEndDate);
      this.tableTitle = new Date(sDate).toDateString() + " to " + (new Date(new Date(eDate).setDate(new Date(eDate).getDate() - 1))).toDateString()
    } else {
      this.attendanceStartDate = new Date(this.year, this.getMonthNumber(this.month), 1)
      const endDate = new Date(this.year, this.getMonthNumber(this.month), 1)
      const monthDays = new Date(this.year, this.attendanceStartDate.getMonth() + 1, 0).getDate(); // To get number of days in that selected month
      this.attendanceEndDate = new Date(endDate.setDate(endDate.getDate() + monthDays))

      this.attendanceStartDate = JSON.stringify(this.attendanceStartDate);
      this.attendanceEndDate = JSON.stringify(this.attendanceEndDate);

      const sDate = JSON.parse(this.attendanceStartDate);
      const eDate = JSON.parse(this.attendanceEndDate);
      this.tableTitle = new Date(sDate).toDateString() + " to " + (new Date(new Date(eDate).setDate(new Date(eDate).getDate() - 1))).toDateString()
    }
  }

  // SK03OCT24 on side nav screen emit
  siveNavExportButtonEmit(evt:any){
    if(evt?.button?.buttonAction === 'export'){
      let collectionName = "attendance";
      this.apiService.getXlsx("/dbops/file?filter=3&reportName="+collectionName).subscribe({
        next: (v: any) => {
          if (this.isDevMode) console.log(v);
          const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
          saveAs(file, collectionName+'.xlsx');  //name should be dynamic
          this.saveSucces("File downloaded successfully");
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
          this.errorMsg(e);
        },
        complete: () => console.info('complete')
      });
    } else if(evt?.button?.buttonAction === 'save'){
        this.apiService.writeValue("patch", "/settings/reports/attendance", {selectedFields:evt.checkListArray}).subscribe({
          next: (success: any) => {
            this.saveSucces(success.message);
            this.globalValues.userGetAPI();
          },
          error: (e: HttpErrorResponse) => {
            console.error(e);
            this.errorMsg(e);
          },
          complete: () => console.info('complete')
        });
    } else if(evt?.button?.buttonAction === 'saveFilter'){
      this.apiService.writeValue("patch", "/settings/reports/attendance", {selectors: this.attendanceFilter.exportFields.selectors}).subscribe({
        next: (success: any) => {
          this.saveSucces(success.message);
          this.globalValues.userGetAPI();
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
          this.errorMsg(e);
        },
        complete: () => console.info('complete')
      });
    }
  }

  exportReports(evt?:any){
    // SK10APR24 exporting employee attendance data from server
    const monthArray = new Date('01' + " " + this.month + this.year); // to get selected date in date format
    this.selectedMonth = monthArray;
    const month = (this.selectedMonth.getMonth() + 1).toString().padStart(2, '0'); // Pad month with leading zero if necessary
    let queryFormat = this.year + "-" + month;
    this.filterStartDate = this.filterStartDate || JSON.parse(this.attendanceStartDate);
    this.filterEndDate = this.filterEndDate ||  JSON.parse(this.attendanceEndDate);

    this.apiService.getAttendance('employeeColumnAttendanceExport', queryFormat, this.accessor, this.filterStartDate, this.filterEndDate).subscribe({
      next:((success:any)=>{
        if (this.isDevMode) console.log(success);
        const file = new Blob([success], { type: 'text/csv' }); // This matches the content type of the response
        saveAs(file, 'attendanceExport.csv');  //name should be dynamic
      }),
      error:((errr:any)=>{
      })
    })
  }

  // SK03OCT24 multiple filters handling from child
  multipleSelectFilterExport(evt:any){
    const ind = this.attendanceFilter?.exportFields?.selectors.findIndex((filtId:any)=> filtId.id === evt.list.id)
    if(ind>=0){
      this.attendanceFilter.exportFields.selectors[ind] = evt.list;
    } 
  }

  // SK03OCT24 date filter fetch from chils emit
  filterDateEmitEvt(event:any){
    if(event.startDate && event.endDate){
      this.filterStartDate = new Date(event.startDate).toISOString();
      this.filterEndDate = new Date(new Date(event.endDate).getFullYear(), new Date(event.endDate).getMonth(), new Date(event.endDate).getDate() + 1).toISOString()
    }
  }
  
}


