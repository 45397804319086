import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValue',
  standalone: true
})
export class GetValuePipe implements PipeTransform {

  // SK10APR24 pipe to get the value from object by passing the key
  transform(arg:any, element: any, column: any): any {
    (console.log('getVal'))
    const initialEl = element; // SK21DEC23 fixed table search issue
    let flds = column.split('.');
    for(let i=0; i<flds.length; i++){
      let splitFlds = flds[i].split('[');
      // SK18JUN24 validating number and text fields
      if(splitFlds.length === 1){
        if(typeof element[flds[i]] === "number"){
          element = element[flds[i]] || 0 as any;
        } else if(typeof element[flds[i]] === "boolean"){
          element = element[flds[i]]; // SK21AUG24 for boolean type
        } else {
          element = element[flds[i]] || "" as any;
        }
        
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        if(typeof element[flds[i]] === "number"){
          element = element[splitFlds[0]][index]  || 0 as any;
        } else if(typeof element[flds[i]] === "boolean"){
          element = element[flds[i]]; // SK21AUG24 for boolean type
        } else {
          element = element[splitFlds[0]][index]  || '' as any; //SK 20DEC23 To solve undefined error
        }
      }
    }
    Object.assign(initialEl, { [column]: element }); // HA 11-DEC-23 Fixed Expression changed issue
    return element;  
  }

}
