<!-- MR 02FEB24 Styling Fix -->
<div class="container-lg card detail-view-of-employee" *ngIf="staticComp">
  <ul class="nav nav-tabs fc-tabs" id="hrmTabs" role="tablist">
    <!-- SK24JAN24 the Sub Tab list is looped as array, previously it was coded separately for each tab-->
    <li *ngFor="let subTab of subTabsList; let i=index" class="nav-item flex " role="presentation">
      <!-- SK 30JAN24 To hide the static tabs by passing the tab value as array -->
      <button *ngIf="!hideStaticTabs?.includes(subTab.subTab)" class="nav-link" id="{{subTab.name}}" data-bs-toggle="tab" [class]="subTab?.subTab === activeSubTab ? 'active': ''"
        type="button" role="tab" (shown.bs.tab)="activeSubTabs(subTab)">{{ subTab.name ? (subTab.name | customTranslate : subTab.name) : '' }}

      </button>
    </li>
  </ul>
  <!-- SK24JAN24 Based on the active tab condition, the component will be displayed -->
  <div class="tab-content" id="hrmtabContent">
     <!-- SK 30JAN24 To hide the static tabs by passing the tab value as array -->
    <div *ngIf="activeSubTab === 'basic' && !hideStaticTabs?.includes('basic') ">
      <app-basicinfotab [selectedUser]="selectedEmployee"></app-basicinfotab>
    </div>
    <div *ngIf="activeSubTab === 'work' && !hideStaticTabs?.includes('work') ">
      <app-worktab [fromHrmPage]="true" [selectedUser]="selectedEmployee" [employeeFinanceInfo]="employeeFinanceInfo" [allEmployeeGet]="empGet"></app-worktab>
    </div>
    <div *ngIf="activeSubTab === 'education' && !hideStaticTabs?.includes('education') ">
      <app-educationtab [selectedUser]="selectedEmployee"></app-educationtab>
    </div>
    <div *ngIf="activeSubTab === 'experience' && !hideStaticTabs?.includes('experience') ">
      <app-experiencetab [selectedUser]="selectedEmployee"></app-experiencetab>
    </div>
    <div *ngIf="activeSubTab === 'documents' && !hideStaticTabs?.includes('documents') ">
      <app-document [selectedUser]="selectedEmployee"></app-document>
    </div>
    <div *ngIf="activeSubTab === 'dependents' && !hideStaticTabs?.includes('dependents') ">
      <app-dependenttab [selectedUser]="selectedEmployee"></app-dependenttab>
    </div>
    <div *ngIf="activeSubTab === 'bank' && !hideStaticTabs?.includes('bank') ">
      <app-bankinfotab [selectedUser]="selectedEmployee"  [employeeFinanceInfo]="employeeFinanceInfo"></app-bankinfotab>
    </div>
    <div *ngIf="activeSubTab === 'leaves' && !hideStaticTabs?.includes('leaves') ">
      <app-apply-leaves [fromHrmPage]="true" [selectedUser]="selectedEmployee" [employeeLeaves]="employeeLeaves"></app-apply-leaves>
    </div>
    <div *ngIf="activeSubTab === 'expenses' && !hideStaticTabs?.includes('expenses') ">
      <app-expenses-tab [fromHrm]="true" [expenseHistory]="expenseHistory" [selectedUser]="selectedEmployee" [employeeExpenses]="employeeExpenses"></app-expenses-tab>
    </div>
    <!-- SK14MAR24 Leaves Allocation and Assigned Leaves are now assigned in separate tab -->
    <div class="m-2 " *ngIf="activeSubTab === 'leaveAllocation' && !hideStaticTabs?.includes('leaveAllocation') ">
      <div class="flex">
        <app-multi-checkbox class="ms-2 me-2"
          [label]="configTemplate?.label" 
          [cardSize]="configTemplate?.cardSize"
          [cardInlineSize]="configTemplate?.cardInlineSize"
          [checkboxList]="configTemplate?.checkboxList" 
          [activeCheckboxList]="configTemplate?.activeCheckboxList" 
          [buttonList]="configTemplate?.buttons" 
          [componentData]="configTemplate"
          (compDataEmit)="multiCheckEmit($event)">
        </app-multi-checkbox>
        <app-holidaydetails class="ms-2 me-2 mt-4" [title]="('Assigned Templates' | customTranslate : 'Assigned Templates')" [details]="templateData" [header]="['Leave Type', 'Balance']"> </app-holidaydetails>
      </div>
      <div class="flex">
        <app-multi-checkbox  class="ms-2 me-2"
          [label]="configHolidays?.label"
          [cardSize]="configHolidays?.cardSize" 
          [cardInlineSize]="configHolidays?.cardInlineSize" 
          [checkboxList]="configHolidays?.checkboxList" 
          [activeCheckboxList]="configHolidays?.activeCheckboxList" 
          [buttonList]="configHolidays?.buttons" 
          [componentData]="configHolidays"
          (compDataEmit)="multiCheckEmit($event)">
        </app-multi-checkbox>
        <app-holidaydetails class="ms-2 me-2 mt-4" [title]="('Assigned Holidays'| customTranslate :'Assigned Holidays')" [details]="holidayData" [header]="['Holiday', 'Date']"> </app-holidaydetails>
      </div>
      <div class="flex">
        <app-multi-checkbox  class="ms-2 me-2"
          [label]="configWeekoffs?.label" 
          [cardSize]="configWeekoffs?.cardSize"
          [cardInlineSize]="configWeekoffs?.cardInlineSize"
          [checkboxList]="configWeekoffs?.checkboxList" 
          [activeCheckboxList]="configWeekoffs?.activeCheckboxList" 
          [buttonList]="configWeekoffs?.buttons" 
          [radioCheck]="true"
          [componentData]="configWeekoffs"
          (compDataEmit)="multiCheckEmit($event)">
        </app-multi-checkbox>
        <app-holidaydetails class="ms-2 me-2 mt-4" [title]="('Assigned Week Off'| customTranslate :'Assigned Week Off')" [details]="weekOffData" [header]="['Type', 'Days']"> </app-holidaydetails>
      </div> 
    </div>

    <!-- SK15MAR24 Compensation details of selected employee-->
    <div class="m-2 " *ngIf="activeSubTab === 'compensation' && !hideStaticTabs?.includes('compensation') ">
      <app-compensation-edit [getEmployeeId]="selectedEmployee.employeeId" [financeInfo]="employeeFinanceInfo"></app-compensation-edit>
    </div>
    <!-- SK29JAN24 For dynamic sub tabs with dynamic cards in HRM -->
    <div *ngIf="!staticTabs.includes(activeSubTab)">
      <!-- 30JAN24 switch the dynamic content, such as card or table  -->
      <div [ngSwitch]="dynamicTabType">
        <div *ngSwitchCase="'card'">
          <div *ngFor="let card of dynamicTabContent">
            <div class="form-card">
              <div class="col-md-12 mt-3">
                  <div class="static-width-for-card width-100">
                      <app-cards [inlineDetailed]="true">
                          <div class="header-section flex content-space-between">
                              <span>{{card.label | customTranslate : card.label}}</span>
                              <div class="card-btn" *ngIf="card.isEditButton" (click)="onClickEdit(card)">
                                  <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                      class="card-icon"></svg-icon>
                              </div>
                          </div>
                          <div class="body-section card-text">
                            <div class="flex">
                              <div class="row g-3 flex w-100">
                                <!-- SK25JUL24 hiding the fields from custom cards -->
                                <div *ngFor="let detail of card.config.details" class="{{card.cardSize}} mt-3 mb-3" >
                                  <div *ngIf="!detail?.hideFromCard" class=" d-flex flex-column">
                                    <label class="label-head">{{detail.label}}</label>
                                    <label class="label-sub">{{detail.value}}</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </app-cards>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngSwitchCase="'table'">
          <app-data-table 
              [tableId]="tableInputs?.tableId"
              [searchBar]="tableInputs?.searchBar" 
              [data]="data" 
              [headerLabels]="tableInputs?.headerLabels"
              [translatePath]="'DYNAMIC_TABLE'"
              [displayedColumns]="tableInputs?.displayedColumns"
              [hyperLinkColumns]="tableInputs?.hyperLinkColumns"
              [fromWhere]="tableInputs?.fromWhere"
              [withCheckBox]="tableInputs?.withCheckBox"
              [isButtons]="tableInputs?.isButtons"
              [buttonArray]="tableInputs?.buttons"
              [isEditRow]="tableInputs?.isEditRow" 
              [isDeleteRow]="tableInputs?.isDeleteRow"
              [actionButton]="tableInputs?.actionButton"
              (commonButtonClickEmit)="commonButtonClickEmit($event, null)"
              (selectedValues)="tableCheckedDataa($event)"
              (hyperLinkColumnEmit)="hyperLinkColumnEmit($event)"
              (onEditData)="onEditRecord($event)"
              (OnDeleteData)="onDeleteRecord($event)">
          </app-data-table>
        </div>
      </div>    
      
    </div>
  </div>
</div>

<div *ngIf="!staticComp">
  <!-- SK12FEB24 input changed to dataBind for bindIn -->
  <!-- SK24JUN24 passing language code to nxt as input -->
  <lib-booklet [bookletJSON]="nxtJSON" [dataBind]="bookValue" [languageCode]="languageCode" (handleBookletActionEvent)="nxtFormHandler($event)" (handleButtonQuestion)="nxtButtonHandler($event)" (handleCalendarDate) = "handleCalendarDate($event)" (handleCalendarEvent) = "handleCalendarEvent($event)"></lib-booklet>
</div>
 
<!-- SK29JAN24 modal open on clicking card edit -->
<!-- SK08JUL24 if condition updated-->
<app-modal  *ngIf="staticComp" [modalTitle]="modalProperties?.modalTitle" [modalFooter]="modalProperties?.isModalFooter" [saveButtonValue]="modalProperties?.modalSaveButtonValue" [isModalOpen]="openModal" (cancelButtonEmit)="modalClose($event)" [modalSize]="modalProperties?.modalSize">
  <div class="modal-body">
    <div>
      <lib-booklet [bookletJSON]="nxtJSON"  [dataBind]="bookValue" [languageCode]="languageCode" (handleBookletActionEvent)="nxtFormHandlerHRM($event)" (handleCalendarDate) = "handleCalendarDate($event)" (handleCalendarEvent) = "handleCalendarEvent($event)"></lib-booklet>
    </div>
  </div>
</app-modal>