import { Component, ElementRef, EventEmitter, Input, isDevMode, OnInit, Output, QueryList, SimpleChange, SimpleChanges, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { Constants } from 'src/app/models/Constants';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import * as moment from 'moment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-compensation-edit',
  standalone: true,
  imports: [
    CommonModule,
    FusionButtonComponent,
    ReactiveFormsModule,
    AngularSvgIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    TranslateModule,
    DropDownComponent,
    CustomTranslatePipe
  ],
  templateUrl: './compensation-edit.component.html',
  styleUrls: ['./compensation-edit.component.css']
})
export class CompensationEditComponent implements OnInit {
  addNewRecord = false;
  editRecord = false;
  compensationForm! : FormGroup;
  percentRegex = "^(100\.00|100\.0|100)|([0-9]{1,2}){0,1}(\.[0-9]{1,2}){0,1}$";
  numberRegex = "^[0-9]*$";
  settingsSubscription! : Subscription;
  successMessage =  Constants.SUCCESS_MESSAGE;
  errorMessage! : string;
  compInfo!: Array<any>;
  activateCompSelection:any;
  compValidate = false;
  ctcValidate = false;
  companyId: any;
  settingsData:any;
  tableData: any;
  result:any;
  postArray:Array<any> = [];
  isViewOn=true; 
  allowance = Constants.ALLOWANCES;
  earnings = Constants.EARNINGS; //SK18JUN24
  deductions = Constants.DEDUCTIONS; //SK18JUN24
  benefits = Constants.BENEFITS; //SK15JUL24
  reimbursement = Constants.REIMBURSEMENTS; //SK15JUL24
  allowanceList : any;
  balanceToTally = 0; //SK18JUN24
  tableData2:Array<any> = []; //SK18JUN24

  @Input() getEmployeeId : any;
  @Input() financeInfo : any; //SK15MAR24 to get financeInfo as input from parent
  @Output() emitBack  = new EventEmitter<any>;

  @ViewChildren("openAccordion") openAccordion!: QueryList<ElementRef>;
  @ViewChildren("arrow") arrow!: QueryList<ElementRef>;
  @ViewChildren("checkComp") checkComp!: QueryList<ElementRef>;

  // SK25JUL24 currency codes
  currencyList =[
    {
        "key": "AED",
        "value": "AED"
    },
    {
        "key": "AFN",
        "value": "AFN"
    },
    {
        "key": "ALL",
        "value": "ALL"
    },
    {
        "key": "ARS",
        "value": "ARS"
    },
    {
        "key": "AUD",
        "value": "AUD"
    },
    {
        "key": "ATS",
        "value": "ATS"
    },
    {
        "key": "BSD",
        "value": "BSD"
    },
    {
        "key": "BHD",
        "value": "BHD"
    },
    {
        "key": "BDT",
        "value": "BDT"
    },
    {
        "key": "BBD",
        "value": "BBD"
    },
    {
        "key": "BEF",
        "value": "BEF"
    },
    {
        "key": "BMD",
        "value": "BMD"
    },
    {
        "key": "BRL",
        "value": "BRL"
    },
    {
        "key": "BGN",
        "value": "BGN"
    },
    {
        "key": "CAD",
        "value": "CAD"
    },
    {
        "key": "CHF",
        "value": "CHF"
    },
    {
        "key": "CLP",
        "value": "CLP"
    },
    {
        "key": "CNY",
        "value": "CNY"
    },
    {
        "key": "CRC",
        "value": "CRC"
    },
    {
        "key": "CYP",
        "value": "CYP"
    },
    {
        "key": "CZK",
        "value": "CZK"
    },
    {
        "key": "COP",
        "value": "COP"
    },
    {
        "key": "DZD",
        "value": "DZD"
    },
    {
        "key": "DKK",
        "value": "DKK"
    },
    {
        "key": "DEM",
        "value": "DEM"
    },
    {
        "key": "DOP",
        "value": "DOP"
    },
    {
        "key": "DEM",
        "value": "DEM"
    },
    {
        "key": "EGP",
        "value": "EGP"
    },
    {
        "key": "EEK",
        "value": "EEK"
    },
    {
        "key": "ESP",
        "value": "ESP"
    },
    {
        "key": "EUR",
        "value": "EUR"
    },
    {
        "key": "FJD",
        "value": "FJD"
    },
    {
        "key": "FIM",
        "value": "FIM"
    },
    {
        "key": "FRF",
        "value": "FRF"
    },
    {
        "key": "GRD",
        "value": "GRD"
    },
    {
        "key": "GTQ",
        "value": "GTQ"
    },
    {
        "key": "GBP",
        "value": "GBP"
    },
    {
        "key": "HKD",
        "value": "HKD"
    },
    {
        "key": "HUF",
        "value": "HUF"
    },
    {
        "key": "HRK",
        "value": "HRK"
    },
    {
        "key": "INR",
        "value": "INR"
    },
    {
        "key": "IDR",
        "value": "IDR"
    },
    {
        "key": "IRR",
        "value": "IRR"
    },
    {
        "key": "ISK",
        "value": "ISK"
    },
    {
        "key": "IQD",
        "value": "IQD"
    },
    {
        "key": "IEP",
        "value": "IEP"
    },
    {
        "key": "ILS",
        "value": "ILS"
    },
    {
        "key": "ITL",
        "value": "ITL"
    },
    {
        "key": "JMD",
        "value": "JMD"
    },
    {
        "key": "JPY",
        "value": "JPY"
    },
    {
        "key": "JOD",
        "value": "JOD"
    },
    {
        "key": "KES",
        "value": "KES"
    },
    {
        "key": "KRW",
        "value": "KRW"
    },
    {
        "key": "KRW",
        "value": "KRW"
    },
    {
        "key": "KWD",
        "value": "KWD"
    },
    {
        "key": "LBP",
        "value": "LBP"
    },
    {
        "key": "LUF",
        "value": "LUF"
    },
    {
        "key": "LKR",
        "value": "LKR"
    },
    {
        "key": "MYR",
        "value": "MYR"
    },
    {
        "key": "MTL",
        "value": "MTL"
    },
    {
        "key": "MUR",
        "value": "MUR"
    },
    {
        "key": "MXN",
        "value": "MXN"
    },
    {
        "key": "MAD",
        "value": "MAD"
    },
    {
        "key": "NLG",
        "value": "NLG"
    },
    {
        "key": "NZD",
        "value": "NZD"
    },
    {
        "key": "NOK",
        "value": "NOK"
    },
    {
        "key": "OMR",
        "value": "OMR"
    },
    {
        "key": "PKR",
        "value": "PKR"
    },
    {
        "key": "PEN",
        "value": "PEN"
    },
    {
        "key": "PHP",
        "value": "PHP"
    },
    {
        "key": "PLN",
        "value": "PLN"
    },
    {
        "key": "PTE",
        "value": "PTE"
    },
    {
        "key": "QAR",
        "value": "QAR"
    },
    {
        "key": "RON",
        "value": "RON"
    },
    {
        "key": "ROL",
        "value": "ROL"
    },
    {
        "key": "RUB",
        "value": "RUB"
    },
    {
        "key": "SAR",
        "value": "SAR"
    },
    {
        "key": "SGD",
        "value": "SGD"
    },
    {
        "key": "SKK",
        "value": "SKK"
    },
    {
        "key": "SIT",
        "value": "SIT"
    },
    {
        "key": "SDD",
        "value": "SDD"
    },
    {
        "key": "SEK",
        "value": "SEK"
    },
    {
        "key": "TWD",
        "value": "TWD"
    },
    {
        "key": "THB",
        "value": "THB"
    },
    {
        "key": "TTD",
        "value": "TTD"
    },
    {
        "key": "TND",
        "value": "TND"
    },
    {
        "key": "TRY",
        "value": "TRY"
    },
    {
        "key": "USD",
        "value": "USD"
    },
    {
        "key": "VEB",
        "value": "VEB"
    },
    {
        "key": "VND",
        "value": "VND"
    },
    {
        "key": "XOF",
        "value": "XOF"
    },
    {
        "key": "XAF",
        "value": "XAF"
    },
    {
        "key": "XPF",
        "value": "XPF"
    },
    {
        "key": "XCD",
        "value": "XCD"
    },
    {
        "key": "XAU",
        "value": "XAU"
    },
    {
        "key": "XDR",
        "value": "XDR"
    },
    {
        "key": "XPD",
        "value": "XPD"
    },
    {
        "key": "XPT",
        "value": "XPT"
    },
    {
        "key": "XAG",
        "value": "XAG"
    },
    {
        "key": "XDR",
        "value": "XDR"
    },
    {
        "key": "ZAR",
        "value": "ZAR"
    },
    {
        "key": "ZMK",
        "value": "ZMK"
    }
  ] 

  currencyValue = "Select";
  settingsDatum:any;
  currencyDropdown : Array<any> = [];

  constructor(private fb: FormBuilder, private globalValues: GlobalValuesService, private apiService: ApiService, private alertService: AlertService, private errorHandler: ErrorHandlerService) { 
    this.companyId = this.globalValues.orgId;

    this.currencyDropdown = this.currencyList.map(currency => currency.key);
    // SK18JUN24
    this.compensationForm = this.fb.group({
      annualCTC : [null, Validators.required],
      grossPay : [null, Validators.required],
      netPay : [null, Validators.required],
      netPayMonthly : [null, Validators.required],
      effectiveDate : [''],
      compId: [null],
      isActive: [false],
      totalCalculatedYearly: [0],
      totalCalculatedMonthly: [0],
      totalDeductionsYearly: [0],
      totalDeductionsMonthly: [0],
      totalNetPayYearly: [0],
      totalNetPayMonthly: [0],
      variablePayYearly:[0],
      isVariablePay:false,
      isExempted: false,
      taxable: false,
      overTimeCalculationComps : '',
      overTimeCalcDays: 0,
      overTimeCalcHours: 0,
      overTimeCalcAmount: 0,
      currencyCode:['Select']
    })
  }

  ngOnInit(): void {

  }

  // SK15MAR24 to detect changes on Inputs employeeId and financeInfo
  ngOnChanges(changes:SimpleChanges){
    this.activateCompSelection = new SelectionModel<any>(true, []);
    if(changes["getEmployeeId"] || changes["financeInfo"])this.getEmployeeCompensation();
    
  }

  // to get the list of compensation and display in UI of selected employee
  getEmployeeCompensation(){
    this.compInfo = [];
    // SK15MAR24 if financeInfo input is undefined
    if(this.financeInfo === undefined){
      const paramsss = new HttpParams({
        fromObject:{
          employeeid : this.getEmployeeId.employeeId,
          companyId: this.companyId
        }
      });
      this.apiService.fetchValue('/hrm', paramsss).subscribe((data:any) => {
        if(isDevMode()) console.log('Compensation Split', data);
        this.compInfo = data?.financeInfo[0]?.compensation;
        this.compValidate = false;
        this.compInfo?.forEach((element:any) => {
          if(element.isActive === true) {
            this.compValidate = true;
            this.activateCompSelection.clear();
            this.activateCompSelection.select(element);
          }
          // to calculate the total amount of components of thet specific id
          let amount = 0;
          element.components.forEach((yearAmount:any) => {
            amount = yearAmount.amountYearly + amount
          });
  
          // Compensation active checkbox validation
          // checks only if the effective date and the total amount tallies the annual CTC
          // SK18JUN24 changed logic to activate compensation(balance to tally should equals zero and ctc should be greater than zero )
          // SK24JUN24 balance to tally storing in db
          if( (element.annualCtc > 0 && element.balanceToTally != 0) || element.effectiveDate === null || element.effectiveDate === ''|| element.effectiveDate === 'Invalid date'){
            element.activeCheckbox = true;
          } else {
            element.activeCheckbox = false;
          }
        });
      })
    } else {
      // SK15MAR24 if financeInfo is sent from parent
      this.compInfo = this.financeInfo.compensation;
      this.compValidate = false;
      this.compInfo?.forEach((element:any) => {
        if(element.isActive === true) {
          this.compValidate = true;
          this.activateCompSelection.clear();
          this.activateCompSelection.select(element);
        }
        // to calculate the total amount of components of thet specific id
        let amount = 0;
        element.components.forEach((yearAmount:any) => {
          amount = yearAmount.amountYearly + amount
        });

        // Compensation active checkbox validation
        // checks only if the effective date and the total amount tallies the annual CTC
        if((element.annualCtc > 0 && element.balanceToTally != 0) || element.effectiveDate === null || element.effectiveDate === ''|| element.effectiveDate === 'Invalid date'){
          element.activeCheckbox = true;
        } else {
          element.activeCheckbox = false;
        }
      });
    }
    

    this.compInfo?.forEach((element:any) => {
     if(element.isActive === true) {
      this.compValidate = true;
      this.activateCompSelection.clear();
      this.activateCompSelection.select(element);
     }
    });

    this.calculateComponents();
  }

  setSalaryData:Array<any> = [];
  // The components that are available in settings are fetched and stored in an array
  calculateComponents(){
    this.apiService.fetchValue('/user').subscribe((settings:any) => {
      this.settingsData = Array.from(settings.settings[0].salary.components);
      this.setSalaryData = Array.from(settings.settings[0].salary.components);
      this.settingsDatum = settings.settings[0];
      const setArray: any[] = [];
      for(let i=0; i< this.setSalaryData.length; i++){
        const filterKey = this.setSalaryData[0].type;
        const removeIndex: number[] = [];
        this.setSalaryData.forEach((obj:any,index:number) =>{
          if(obj.type === filterKey) {
            setArray.push(obj);
            removeIndex.push(index);
          }
        });
        for (var j = removeIndex.length -1; j >= 0; j--){
          this.setSalaryData.splice(removeIndex[j],1);
        }
      }
      const finalArray = [...setArray,...this.setSalaryData];

      // crrating new array of objects with that fetched components, that to be sent while adding the compensation for an employee
      this.tableData2 = [];
      finalArray.forEach((data:any) => {
        if(data.active){
          this.tableData2.push({
            componentName: data.name,
            paySlipName: data.nameInPaySlip,
            type: data.type,
            percentRate: data.calculationType === 'flat' ? 0 : Number(data.amount),
            calculationBasis: data.calculationType,
            isEmployerContribution: data.isEmployerContribution,
            amountYearly: data.calculationType === 'flat' ? Number(data.amount) : 0,
            amountMonthly: data.calculationType === 'flat' ? this.numberRoundDecimal(Number(data.amount)/12) : 0,
            isExempted: data.isExempted,
            taxable: data.taxable,
            isVariablePay: data?.isVariablePay
          });
        }
      });
      this.groupData(this.tableData2);
    })    
  }

  // the components array will be grouped based on the type of component, this will be displayed in UI
  groupData(data:any){
    this.tableData2 = [];
    this.tableData2 = data;
    this.allowanceList = this.tableData2.filter((i:any) => i.type === this.allowance);
    this.result = [];
    // SK18JUN24 added benefits to the group
    const groups = [this.earnings,this.allowance,this.deductions,this.benefits,this.reimbursement]
    groups.forEach((type:any) => {
      if(type != this.reimbursement){
        if(type === this.benefits){
          const components = this.tableData2.filter((i:any) => (i.type === this.deductions) && (i.isEmployerContribution));
          if(components.length > 0){
            this.result.push({
              type: this.benefits, 
              components: components
            });
          }
          
        } if(type === this.deductions){
          const components = this.tableData2.filter((i:any) => (i.type === this.deductions) && (!i.isEmployerContribution));
          if(components.length > 0){
            this.result.push({
              type: this.deductions, 
              components: components
            });
          }
          
        } if(type === this.earnings){
          const components = this.tableData2.filter((i:any) => (i.type === this.earnings));
          if(components.length > 0){
            this.result.push({
              type: this.earnings, 
              components: components
            });
          }
          
        } if(type === this.allowance){
          const components = this.tableData2.filter((i:any) => (i.type === this.allowance));
          if(components.length > 0){
            this.result.push({
              type: this.allowance, 
              components: components
            });
          }
          
        }
      }
    })
    this.ctcFunction()
  }

  successMessageFunction(){
    this.alertService.messageOnPass('success', this.successMessage );
    this.getEmployeeCompensation();
    this.editRecord = false;
    this.addNewRecord = false;
  }

  errorMessageFunction(message:any){
    this.errorHandler.handleError(message);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  // form declaration is for resetting the form values with default values
  formDeclaration(){
    // SK18JUN24 value assign for form fields
    this.currencyValue = this.settingsDatum?.localization?.currencyCode || "";
    this.compensationForm.patchValue({
      annualCTC : null,
      grossPay : null,
      netPay : null,
      netPayMonthly : null,
      compId: null,
      effectiveDate : '',
      isActive: false,
      totalCalculatedYearly: 0,
      totalCalculatedMonthly: 0,
      isExempted: false,
      taxable: false,
      overTimeCalculationComps: '',
      overTimeCalcDays: 0,
      overTimeCalcHours: 0,
      overTimeCalcAmount: 0,
      totalDeductionsYearly: 0,
      totalDeductionsMonthly: 0,
      totalNetPayYearly: 0,
      totalNetPayMonthly: 0,
      variablePayYearly:0,
      isVariablePay:false,
      currencyCode:this.currencyValue
    });

    this.calculateComponents();
  }

  // the edited compensation details are fetched and assigned to a compensation form
  editComp(data:any){
    this.currencyValue = data?.currencyCode ||  "Select";
    this.editRecord = true; //SK03JUL24
    this.compensationForm.patchValue({
      annualCTC : data?.annualCtc,
      grossPay : data?.grossSalary,
      netPay : data?.netSalary,
      netPayMonthly : data?.netSalary || 0,
      effectiveDate : moment(data?.effectiveDate).format('YYYY-MM-DD') || '',
      compId: data?.compensationId,
      isActive: data?.isActive,
      totalCalculatedYearly: 0,
      totalCalculatedMonthly: 0,
      isExempted: data?.isExempted,
      taxable : data?.taxable,
      overTimeCalculationComps: data?.additionalAllowance,
      overTimeCalcDays: data?.workingDays,
      overTimeCalcHours: data?.workingHours,
      overTimeCalcAmount: data?.otAmount,
      totalDeductionsYearly: data?.totalDeductionsYearly,
      totalDeductionsMonthly: data?.totalDeductionsMonthly,
      totalNetPayYearly: data?.totalNetPayYearly,
      totalNetPayMonthly: data?.totalNetPayMonthly,
      isVariablePay: data?.isVariablePay,
      currencyCode: this.currencyValue
    });
    // to add the updated salary components while editing the compesation
    // const editData = this.settingsData.filter((any:any) => (data.components.map((e:any) => e.componentName).indexOf(any.name) === -1) && any.active); // this array finds the object that is excluded in edit array components list
    // editData.forEach((element:any, index:number) => {
    //   editData[index] = {
    //     "componentName": element.name,
    //     "paySlipName": element.nameInPaySlip,
    //     "type": element.type,
    //     "percentRate": element.calculationType === 'flat' ? 0 : Number(element.amount),
    //     "calculationBasis": element.calculationType,
    //     "isEmployerContribution": element.isEmployerContribution,
    //     "amountYearly": element.calculationType === 'flat' ? Number(element.amount) : 0,
    //     "amountMonthly": element.calculationType === 'flat' ? this.numberRoundDecimal(Number(element.amount)/12) : 0,
    //     'isExempted': element.isExempted || false,
    //     "taxable": element.taxable
    //   }
    // });
    // const editFinal = [...data.components, ...editData];
    // this.groupData(editFinal);
    this.groupData(data.components);
    // this.ctcFunction();
 }



  // saving new / editing the compensation details
  saveCompensation(){
    this.postArray.push(this.result);
    const value = this.compensationForm.value;

    var compensationObject = {}
    compensationObject = {
      employeeId:this.getEmployeeId.employeeId,
      annualCtc: value.annualCTC,
      effectiveDate:  new Date(value.effectiveDate),
      grossSalary:  value.grossPay,
      netSalary:  value.netPay,
      isActive: value.isActive && this.ctcValidate === false ? true : false, // if the effective date and the total amount does not tallies the annual CTC, it will become false
      companyId: this.companyId,
      components: this.tableData2,
      additionalAllowance: value.overTimeCalculationComps,
      workingDays: value.overTimeCalcDays,
      workingHours: value.overTimeCalcHours,
      otAmount:value.overTimeCalcAmount,
      balanceToTally: this.balanceToTally,
      isVariablePay: value.isVariablePay,
      currencyCode: value.currencyCode
    };
    const comnpensationId = {compensationId:value.compId};
    // if compensationId is null, the new compensation (POST API call) will be added
    if(value.compId === null){
      this.apiService.writeValue('post', '/employee/compensation' , compensationObject)
      .subscribe({
        next: (success) => {
          if(isDevMode()) console.log('Compensation Success', success);
          this.successMessageFunction();
        }, 
        error: (err: HttpErrorResponse) => {
          if(isDevMode()) console.log('Compensation error', err);
          this.errorMessageFunction(err);
        }
      });
    } 
    // The patch call will be called if, compensationId has value
    else {
      const patchObject = {...compensationObject,...comnpensationId};
      this.apiService.writeValue('patch', '/employee/compensation' , patchObject )
      .subscribe({
        next: (success) => {
          if(isDevMode()) console.log('Compensation  PatchSuccess', success);
          this.successMessageFunction();
        }, 
        error: (err: HttpErrorResponse) => {
          if(isDevMode()) console.log('Compensation Patcherror', err);
          this.errorMessageFunction(err);
        }
      });
    }
  }

  // the compensation activation checkbox is checked to activate or deactivate that specific compensation
  activateCompensation(event:any, data:any, i: number){
    this.compInfo.forEach((element:any, index:number) => {
      if (i === index && event.checked === true) {
        element.isActive = true;
      } else {
        element.isActive = false;
      }
      this.editComp(element);
      this.saveCompensation();
    });
  }

  // the compensation will be added or edited using accordion
  accordionContent(i:number){
    // finding the element that has accordion arrows and activating the "active1" class to rotate arrows
    this.arrow.toArray()[i].nativeElement.classList.toggle("active1");
    // finding the element that has class "accordion-content"
    const panel = this.openAccordion.toArray()[i].nativeElement.nextElementSibling.className.includes("accordion-content")
      ? this.openAccordion.toArray()[i].nativeElement.nextElementSibling
      : '';
    
    // toggling the content based on the active class
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  toggleHelper(i: number) {
    this.editRecord = true;
    // opening the accordion content
    if(this.arrow.toArray()[i].nativeElement.classList.contains('active1') === false){
      this.accordionContent(i);
    }
  }

  // closing the accordion content
  cancelAccordion(i: number) {
    this.editRecord = false;
    this.accordionContent(i);
  }

  // It will be redirected to table page from compendsation list
  backEmitButton(){
    this.emitBack.emit(false);
  }

  // this function is used to round the decimal digits, that exceeds two decimal points
  numberRoundDecimal(number:any) {
    // return Math.round((number+Number.EPSILON)*Math.pow(10,2))/Math.pow(10,2);
    // SK18JUN24 fixing the values to two digits insead of rounding
    return Number(Number(number).toFixed(2));
  }

  // calculating the componensation components fileds based on their calculationBasis
  ctcFunction(){
    // SK15JUL24 moved this logic from calculatePercent function
    this.tableData2 = [];
    this.result.forEach((comp:any)=> {
      comp.components.forEach((element:any) => {
        this.tableData2.push(element);
      });
    })
    this.calculateNetPay();
    this.tableData2.forEach((element:any) => {
      if(element.calculationBasis === 'fixed'){
        element.amountYearly = this.compensationForm.controls['grossPay'].value * (element.percentRate/100);
        element.amountMonthly = this.numberRoundDecimal(element.amountYearly / 12);
      } else if (element.calculationBasis === 'percentage'){
        this.tableData2.forEach((element1:any) => {
          if(element1.componentName === Constants.BASIC){
            element.amountYearly = element1.amountYearly * (element.percentRate/100);
            element.amountMonthly = this.numberRoundDecimal(element.amountYearly / 12);
          }
        });
      }
    });
    this.calculateTotal();
  }

  // calculating the netpay and gross pay based on CTC
  calculateNetPay(){
    let gross = 0;
    let net = 0;
    let earnings = 0;
    let variable = 0;
    const value =  this.compensationForm.value;
    this.tableData2.forEach((element:any) => {
      // net pay is calculated by deducting the total deductions from CTC
      if(element.type === this.deductions){
        net  = Number(element.amountYearly) + net;
      } 

      // gross pay is calculated by deducting the deductions, that has employer's contribution 'true', from CTC
      if (element.type === this.deductions && element.isEmployerContribution === true){
        gross = Number(element.amountYearly) + gross;
      }
      
      if ((element.type === this.earnings && !element.isVariablePay) || (element.type === this.allowance && !element.isVariablePay)){
        earnings = Number(element.amountYearly) + earnings;
      }
      // SK15JUL24 variable pay logic 
      if ((element.type === this.earnings && element.isVariablePay) || (element.type === this.allowance && element.isVariablePay)){
        variable = Number(element.amountYearly) + variable;
        this.compensationForm.controls['variablePayYearly'].patchValue(variable);
      }
    });
    value.annualCTC ? this.compensationForm.controls['netPay'].patchValue(this.numberRoundDecimal(value.annualCTC -  net)) : this.compensationForm.controls['netPay'].patchValue('');
    value.annualCTC ? this.compensationForm.controls['grossPay'].patchValue(this.numberRoundDecimal(value.annualCTC -  gross - variable)) : this.compensationForm.controls['grossPay'].patchValue('');
  }

  // calculating the amount based on their percentage entered in input
  calculatePercentAmount(i:number, event:any, object:any){
    const obj = this.result[i].components[object];
    obj.percentRate = Number(event.target.value) ;
    this.tableData2 = [];
    // SK18JUN24 calcuating the percentage amount for the edited component
    this.ctcFunction();
  }

  // modifying the amount that has flat rate
  calculateFixedAmount(i:number, data:any, object:any){
    this.result[i].components[object].amountYearly = Number(data.target.value) ;
    this.result[i].components[object].amountMonthly = this.numberRoundDecimal(data.target.value)  / 12;
    this.tableData2 = [];
    // SK18JUN24 calcuating the fixed amount for the edited component
    this.ctcFunction();
  }

  // modifying the amount that has flat rate
  calculateFixedPercentAmount(i:number, data:any, object:any){
    this.result[i].components[object].amountYearly = Number(data.target.value) ;
    this.result[i].components[object].amountMonthly = this.numberRoundDecimal(data.target.value)  / 12;
    this.tableData2 = [];
    this.result.forEach((comp:any)=> {
      comp.components.forEach((element:any) => {
        this.tableData2.push(element);
      });
    })
    this.calculateNetPay();
    this.tableData2.forEach((element:any) => {
      if(element.calculationBasis === 'fixed'){
        element.percentRate = (element.amountYearly/this.compensationForm.controls['grossPay'].value) * 100
        element.amountMonthly = this.numberRoundDecimal(element.amountYearly / 12);
      } else if (element.calculationBasis === 'percentage'){
        this.tableData2.forEach((element1:any) => {
          if(element1.componentName === Constants.BASIC){
            element.percentRate = (element.amountYearly/element1.amountYearly) * 100
            element.amountMonthly = this.numberRoundDecimal(element.amountYearly / 12);
          }
        });
      }
    });
    this.calculateTotal();
  }

  // calculating the compensation breakup, and displayed as total CTC
  calculateTotal(){
    let amountCTCYearEarnings = 0;
    let amountBenefits = 0;

    let totalYearEarnings = 0;
    let totalYearDeductions = 0;
    let amountToTally = 0;
    let variableAmountYearly = 0;
    // // SK18JUN24 calcuating the total amount
    this.tableData2.forEach((element:any) => {
      if((element.type === this.earnings && !element.isVariablePay) || (element.type === this.allowance  && !element.isVariablePay )|| (element.type === this.deductions && element.isEmployerContribution && !element.isVariablePay)){
        amountToTally = amountToTally + element.amountYearly;
        amountCTCYearEarnings = this.numberRoundDecimal(element.amountYearly + amountCTCYearEarnings);
      }

      // SK15JUL24 variable pay logic
      if((element.type === this.allowance && element.isVariablePay) || (element.type === this.earnings && element.isVariablePay)){
        variableAmountYearly = variableAmountYearly + element.amountYearly;
        this.compensationForm.controls['variablePayYearly'].patchValue(variableAmountYearly);
      }

      if((element.type === this.deductions && !element.isEmployerContribution)){
        amountBenefits = this.numberRoundDecimal(element.amountYearly + amountBenefits);
      }
      
      if(element.type === this.earnings || element.type === this.allowance){
        totalYearEarnings = this.numberRoundDecimal(element.amountYearly + totalYearEarnings);
      }
      if(element.type === this.deductions){
        totalYearDeductions = this.numberRoundDecimal(element.amountYearly + totalYearDeductions);
      }
      this.balanceToTally = this.numberRoundDecimal(this.compensationForm.controls['annualCTC'].value - amountToTally - variableAmountYearly);
      element.balanceToTally = this.balanceToTally; //SK24JUN24 balance to tally sent as data to db

    });
    const comps = [];
    this.result.forEach((comp:any)=> {
      comp.components.forEach((element:any) => {
        comps.push(element);
      });
    })
    this.compensationForm.controls['totalDeductionsYearly'].patchValue(totalYearDeductions);
    this.compensationForm.controls['totalDeductionsMonthly'].patchValue(this.numberRoundDecimal(totalYearDeductions/12));
    const finalNetYearly = amountCTCYearEarnings - totalYearDeductions + variableAmountYearly;
    this.compensationForm.controls['totalNetPayYearly'].patchValue(finalNetYearly);
    this.compensationForm.controls['totalNetPayMonthly'].patchValue(this.numberRoundDecimal(finalNetYearly/12));

    let amountmonth =  this.numberRoundDecimal((amountCTCYearEarnings + variableAmountYearly) / 12);
    this.compensationForm.controls['totalCalculatedMonthly'].patchValue(amountmonth);
    this.compensationForm.controls['totalCalculatedYearly'].patchValue(this.numberRoundDecimal(amountCTCYearEarnings + variableAmountYearly));
    this.compensationForm.controls['netPayMonthly'].patchValue(this.numberRoundDecimal((this.compensationForm.controls['netPay'].value)/12));

    if((this.balanceToTally != 0 && this.compensationForm.controls['annualCTC'].value > 0) || this.compensationForm.controls['effectiveDate'].value === null || this.compensationForm.controls['effectiveDate'].value === '' || this.compensationForm.controls['effectiveDate'].value === 'Invalid date'){
      this.ctcValidate =  true;
    } else {
      this.ctcValidate =  false;
    }
    // this.compensationForm.controls['overTimeCalcAmount'].value === null ?  this.overTimeAmount(): '';
    // SK18JUN24 OT calculation
    if(this.editRecord === false){
      this.overTimeAmount()
    }
  }

  //to view/close compensation data on clicking the arrow button  
  toggleForm(i:number,comp:any){
    if(this.arrow.toArray()[i].nativeElement.classList.contains('active1')){
      this.cancelAccordion(i);
      this.isViewOn = true;
      this.editRecord = false;
    }else{
      this.accordionContent(i);
      this.isViewOn = false;
      this.editRecord = true;
      this.editComp(comp);
    }
  }

  calculateOT(event:any, controlName:any){
    if( controlName ===  'overTimeCalcAmount'){
      this.compensationForm.controls[controlName].patchValue((event.target.value));
    } else {
      this.overTimeAmount();
    }
  }

  overTimeAmount(){
    const basic = this.tableData2.filter((basicPay:any) => basicPay.componentName === Constants.BASIC);
    const additionalAllowance = this.tableData2.filter((add:any) => add.componentName === this.compensationForm.controls['overTimeCalculationComps'].value);
    // const additionalAllowanceAmount = Number(amount) || 0;
    const additionalAllowanceAmount = (additionalAllowance[0]?.amountYearly !== undefined) ? Number(additionalAllowance[0].amountYearly) : 0;
    const calculationAmount = Number(basic[0]?.amountYearly) + Number(additionalAllowanceAmount);
    const calculationAmountMonth = this.numberRoundDecimal(calculationAmount/12);
    const otAmountMonthly = (calculationAmountMonth / Number(this.compensationForm.controls['overTimeCalcDays'].value));
    const otAmountHourly = otAmountMonthly / Number(this.compensationForm.controls['overTimeCalcHours'].value);
    this.compensationForm.controls['overTimeCalcAmount'].patchValue(this.numberRoundDecimal(otAmountHourly === Infinity ? 0 : otAmountHourly));
  }

  // SK25JUL24 emitting currency codes from dropdown
  emittedValue(event:any, formName:string){
    this.compensationForm.controls[formName].setValue(event.data);
  }
}
