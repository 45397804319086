<div class="flex flex-row height-100">
    <div class="content-section flex flex-column flex-grow max-width-minus-side-nav" style="--grow-value: 1">
        <app-page-header (clickedNav)="sideNavChange($event)"></app-page-header>
        <div class="main-content">
            <div *ngIf="settingToShow == 'company'" class="company-setting section form-card-section">
                <!-- <div class="sub-page-name">{{ 'COMPANY_SETTING' | customTranslate : 'Company Setting' }}</div>  -->
                <div class="form-card">
                    <form [formGroup]="settingsForm" autocomplete="off">
                        <div class="field-sets">
                            <div class="field-set-row flex">
                                <div class="field-input" [class]="compNameErr ? ' has-error' : ' ' ">
                                    <!--RG26AUG24 changed the label UI -->
                                    <label class="form-label label-txt modal-field-label" for="company-name">{{  'COMPANY_NAME' | customTranslate : 'Company Name' }}*</label>
                                    <input type="text" tabindex="1" id="company-name" formControlName="companyName"
                                        [class]="textSaved" placeholder="Company Name"  autocomplete="off" (focusout)="handleCompanyName($event)" (keyup)="handleCompanyName($event)">
                                    <!-- Updated the error message with formbuilder pattern-->
                                    <!-- <p class="error-msg"><span [style]="compNameErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required </span></p> -->
                                    <p class="error-msg">
                                        <span *ngIf="settingsForm.controls['companyName'].invalid && settingsForm.controls['companyName'].touched && !this.settingsForm.controls['companyName'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                        </span>
                                        <span *ngIf="settingsForm.controls['companyName'].touched && this.settingsForm.controls['companyName'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_COMPANY_NAME' | customTranslate : 'Please enter a valid company name' }}
                                        </span>
                                    </p>
                                </div>
                                <div class="field-input" [class]="contPersonErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="contact-person">
                                        {{  'CONTACT_PERSON' | customTranslate : 'Contact Person' }}*
                                    </label>
                                    <input type="text" tabindex="2" id="contact-person" formControlName="contactPerson"
                                    [class]="textSaved" placeholder="Contact Person name"
                                        autocomplete="off" (focusout)="handleContactPerson($event)" (keyup)="handleContactPerson($event)">
                                    <!-- <p class="error-msg"><span [style]="contPersonErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required </span></p> -->
                                    <p class="error-msg">
                                        <span *ngIf="settingsForm.controls['contactPerson'].invalid && settingsForm.controls['contactPerson'].touched && !this.settingsForm.controls['contactPerson'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                        </span>
                                        <span *ngIf="settingsForm.controls['contactPerson'].touched && this.settingsForm.controls['contactPerson'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name' }}
                                        </span>
                                    </p>
                                </div>
                                <div class="field-input" style="transition: none;">
                                    <label class="form-label label-txt modal-field-label" for="business-mail" >
                                        {{  'BUSINESS_MAIL' | customTranslate : 'Business Email (uneditable)' }}
                                    </label>
                                    <input type="text" id="business-mail" formControlName="businessMail"
                                    [class]="textSaved" placeholder=""
                                        autocomplete="off" (focusout)="handleBusinessMail($event)" (keyup)="handleBusinessMail($event)" [readonly]="true">
                                    
                                </div>

                                <div class="field-input" [class]="phErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="phone">
                                        {{  'USER_DETAILS.PHONE_NUMBER' | customTranslate : 'Phone Number' }}*
                                    </label>
                                    <input type="text" tabindex="3" id="phone" [class]="textSaved" formControlName="phone"
                                        placeholder="Phone Number" autocomplete="off" (focusout)="handlePhNo($event)" (keyup)="handlePhNo($event)">
                                    <p class="error-msg">
                                        <span *ngIf="this.settingsForm.controls['phone'].invalid && this.settingsForm.controls['phone'].touched && !this.settingsForm.controls['phone'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                        </span>
                                        <span *ngIf="this.settingsForm.controls['phone'].touched && this.settingsForm.controls['phone'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}
                                        </span>
                                    </p>
                                    <!-- <p class="error-msg"><span [style]="phExceedErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>You are exceeding the length</span></p>
                                    <p class="error-msg"><span [style]="phErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p> -->
                                </div>
                                <div class="field-input" [class]="addrErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="address1" >
                                        {{ 'ADDRESS_BasicInfo.ADDRESS1' | customTranslate : 'Address 1' }}*
                                    </label>
                                    <input type="text" tabindex="4" id="address1" formControlName="address1"
                                    [class]="textSaved" placeholder="Address 1" autocomplete="off" (focusout)="handleAddress($event)" (keyup)="handleAddress($event)">
                                    <p class="error-msg"><span [style]="addrErr ? 'visibility: visible' : 'visibility: hidden' ">
                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span>
                                    </p>
                                </div>
                                <div class="field-input">
                                    <label class="form-label label-txt modal-field-label" for="address2">
                                        {{ 'ADDRESS_BasicInfo.ADDRESS2' | customTranslate : 'Address 2' }} 
                                    </label>
                                    <input type="text" tabindex="5" id="address2" formControlName="address2"
                                    [class]="textSaved" placeholder="Address 2" autocomplete="off">
                                </div>
                                <div class="field-input" [class]="countryErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="country">
                                        {{ 'ADDRESS.SELECT_COUNTRY' | customTranslate : 'Select a country' }}*
                                    </label>
                                    <select [class]="textSaved" formControlName="country" tabindex="6" 
                                    class="form-select" id="country" placeholder="Select Country" (focusout)="handleCountry($event)" (keyup)="handleCountry($event)">
                                        <option selected value=""></option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" selected>Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                    <p class="error-msg"><span [style]="countryErr ? 'visibility: visible' : 'visibility: hidden' "> <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p>
                                </div>
                                <div class="field-input" [class]="stateErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="state">
                                        {{'State/Province' |  customTranslate : 'State/Province'}}*
                                    </label>
                                    <input type="text" tabindex="7" id="state" formControlName="state" [class]="textSaved"
                                        placeholder="State" autocomplete="off" (focusout)="handleState($event)" (keyup)="handleState($event)" >   
                                    <p class="error-msg">
                                    <span *ngIf="this.settingsForm.controls['state'].invalid && this.settingsForm.controls['state'].touched && !this.settingsForm.controls['state'].hasError('pattern')">
                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                    </span>
                                    <span *ngIf="this.settingsForm.controls['state'].touched && this.settingsForm.controls['state'].hasError('pattern')">
                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}
                                    </span>
                                    </p>
                                    <!-- <p class="error-msg"><span [style]="stateErr ? 'visibility: visible' : 'visibility: hidden' ">
                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p> -->
                                </div>
                                <div class="field-input" [class]="cityErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="city">
                                        {{ 'ADDRESS.CITY' | customTranslate : 'City' }}*
                                    </label>
                                    <input type="text" tabindex="8" id="city" formControlName="city" [class]="textSaved"
                                        placeholder="City" autocomplete="off" (focusout)="handleCity($event)" (keyup)="handleCity($event)" >
                                    <p class="error-msg"><span [style]="cityErr ? 'visibility: visible' : 'visibility: hidden' ">
                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                        </span>
                                    </p>
                                    <!-- <p class="error-msg">
                                        <span *ngIf="settingsForm.controls['cityErr'].invalid && settingsForm.controls['cityErr'].touched && !this.settingsForm.controls['cityErr'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required
                                        </span>
                                        <span *ngIf="settingsForm.controls['cityErr'].touched && this.settingsForm.controls['cityErr'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>Please enter a valid city
                                        </span>
                                    </p>
                                 -->
                                </div>
                                <div class="field-input" [class]="pincodeErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="postalcode">
                                        {{ 'ADDRESS.POSTAL_CODE' | customTranslate : 'Postal code / Zip code' }}*
                                    </label>
                                    <input type="text" tabindex="9" id="postalcode" formControlName="postalcode"
                                    [class]="textSaved" placeholder="Postal Code" autocomplete="off" (focusout)="handlePinCode($event)" (keyup)="handlePinCode($event)">
                                    <p class="error-msg">
                                        <span *ngIf="settingsForm.controls['postalcode'].invalid && settingsForm.controls['postalcode'].touched && !this.settingsForm.controls['postalcode'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                        </span>
                                        <!-- SK9JAN24 removed postal code-->
                                        <!-- <span *ngIf="settingsForm.controls['postalcode'].touched && this.settingsForm.controls['postalcode'].hasError('pattern')">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>Please enter a valid pincode
                                        </span> -->
                                    </p>
                                    <!-- <p class="error-msg"><span [style]="pinExceedErr ? 'visibility: visible' : 'visibility: hidden' ">*You are exceeding the length</span></p>
                                    <p class="error-msg"><span [style]="pincodeErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p> -->
                                </div>
                                <!-- <div class="field-input" [class]="urlErr ? ' has-error' : ' ' ">
                                    <input type="text" tabindex="10" id="website" formControlName="website"
                                    [class]="textSaved" placeholder="website" autocomplete="off" (focusout)="handleURL($event)" (keyup)="handleURL($event)">
                                    <label class="form-field-label" for="website">
                                        Website URL*
                                    </label>
                                    <p class="error-msg"><span [style]="urlErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>This field is required</span></p>
                                </div> -->
                            </div>
                            <div class="center-button text-center">
                                <app-fusion-button buttonValue=" {{'BUTTON_VALUE.SAVE' | customTranslate : 'Save'}} " [buttonType]="'input-button'" 
                                [buttonDisable]="!settingsForm.valid" (buttonClickEmit)="setCompanySettings()"  ></app-fusion-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="settingToShow == 'localization'" class="localization section form-card-section">
                <!-- RS27AUG24 -->
                <!-- Used the translate pipe to successfully implement the translation -->
                <div class="sub-page-name">{{ 'LOCALIZATION' | customTranslate : 'Localization' }}</div>
                <div class="form-card">
                    <form [formGroup]="localizationForm"  autocomplete="off">
                        <div class="field-sets">
                            <div class="field-set-row flex">
                                <div class="field-input" [class]="localCountryeErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="localized-country">
                                        {{ 'ADDRESS.SELECT_COUNTRY' |  customTranslate : 'Select a country' }}*
                                    </label>
                                    <select [class]="textSaved" formControlName="localizedCountry" tabindex="1" class="form-select" id="localized-country" (focusout)="handleLocalCountry($event)" (keyup)="handleLocalCountry($event)">
                                        <option value="" selected>Select your country</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" selected>Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                    <p class="error-msg"><span [style]="localCountryeErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                <div class="field-input" [class]="dateErr ? ' has-error' : ' ' ">                 
                                    <label class="form-label label-txt modal-field-label" for="localized-dateFormat">
                                        {{ 'DATE_FORMAT' |  customTranslate : 'Date Format' }}*
                                    </label>
                                    <select [class]="textSaved" formControlName="localizedDateFormat" tabindex="2" class="form-select"  id="localized-dateFormat" (focusout)="handleDate($event)" (keyup)="handleDate($event)">
                                        <!-- SK06MAR24 changing the values of month dropdown, so it is smooth to convert the date and time format -->
                                        <!-- <option value=""></option> -->
                                        <!-- <option value="mdyy">MM/DD/YYYY</option>
                                        <option value="dmyy">DD/MM/YYYY</option>
                                        <option value="mdy">MM/DD/YYYY</option>
                                        <option value="dmy">DD/MM/YYYY</option> -->
                                        <option value="dd/MM/yyyy" >DD/MM/YYYY</option>
                                        <option value="MM/dd/yyyy">MM/DD/YYYY</option>
                                        <option value="yyyy/MM/dd" >YYYY/MM/DD</option>
                                        <option value="dd/MM/yy">DD/MM/YY</option>
                                        <option value="MM/dd/yy">MM/DD/YY</option>
                                        <option value="yy/MM/dd" >YY/MM/DD</option>
                                    </select>
                                    <p class="error-msg"><span [style]="dateErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>

                                </div>
                                <div class="field-input" [class]="timeErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="local-timeZone">
                                        {{ 'TIME_ZONE' |  customTranslate : 'Timezone' }}*
                                    </label>
                                    <select [class]="textSaved" formControlName="localTimeZone" tabindex="3" class="form-select" id="local-timeZone" (focusout)="handleTime($event)" (keyup)="handleTime($event)">
                                        <option value=""></option>
                                        <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                        <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                        <option value="-10:00">(GMT -10:00) Hawaii</option>
                                        <option value="-09:30">(GMT -9:30) Taiohae</option>
                                        <option value="-09:00">(GMT -9:00) Alaska</option>
                                        <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                        <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                        <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City
                                        </option>
                                        <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima
                                        </option>
                                        <option value="-04:30">(GMT -4:30) Caracas</option>
                                        <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                        <option value="-03:30">(GMT -3:30) Newfoundland</option>
                                        <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                        <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                        <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                        <option value="+00:00" selected="selected">(GMT) Western Europe Time, London,
                                            Lisbon, Casablanca</option>
                                        <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                        <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                        <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                        <option value="+03:30">(GMT +3:30) Tehran</option>
                                        <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                        <option value="+04:30">(GMT +4:30) Kabul</option>
                                        <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent
                                        </option>
                                        <option value="+05:30">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                        <option value="+05:45">(GMT +5:45) Kathmandu, Pokhara</option>
                                        <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                        <option value="+06:30">(GMT +6:30) Yangon, Mandalay</option>
                                        <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                        <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                        <option value="+08:45">(GMT +8:45) Eucla</option>
                                        <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                        <option value="+09:30">(GMT +9:30) Adelaide, Darwin</option>
                                        <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                        <option value="+10:30">(GMT +10:30) Lord Howe Island</option>
                                        <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                        <option value="+11:30">(GMT +11:30) Norfolk Island</option>
                                        <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                        <option value="+12:45">(GMT +12:45) Chatham Islands</option>
                                        <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                        <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                    </select>
                                    <p class="error-msg"><span [style]="timeErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                <div class="field-input" [class]="currencyErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="local-currency">
                                        {{ 'CURRENCY_CODE' |  customTranslate : 'Currency Code' }}*
                                    </label>
                                    <select [class]="textSaved" formControlName="localCurrency" tabindex="4" class="form-select"  id="local-currency" (focusout)="handleCurrency($event)" (keyup)="handleCurrency($event)">
                                        <option value=""></option>
                                        <option value="AED">United Arab Emirates dirham</option>
                                        <option value="AFN">Afghan afghani</option>
                                        <option value="ALL">Albanian lek</option>
                                        <option value="AMD">Armenian dram</option>
                                        <option value="AOA">Angolan kwanza</option>
                                        <option value="ARS">Argentine peso</option>
                                        <option value="AUD">Australian dollar</option>
                                        <option value="AWG">Aruban florin</option>
                                        <option value="AZN">Azerbaijani manat</option>
                                        <option value="BAM">Bosnia and Herzegovina convertible mark</option>
                                        <option value="BBD">Barbadian dollar</option>
                                        <option value="BDT">Bangladeshi taka</option>
                                        <option value="BGN">Bulgarian lev</option>
                                        <option value="BHD">Bahraini dinar</option>
                                        <option value="BIF">Burundian franc</option>
                                        <option value="BMD">Bermudian dollar</option>
                                        <option value="BND">Brunei dollar</option>
                                        <option value="BOB">Bolivian boliviano</option>
                                        <option value="BRL">Brazilian real</option>
                                        <option value="BSD">Bahamian dollar</option>
                                        <option value="BTN">Bhutanese ngultrum</option>
                                        <option value="BWP">Botswana pula</option>
                                        <option value="BYR">Belarusian ruble</option>
                                        <option value="BZD">Belize dollar</option>
                                        <option value="CAD">Canadian dollar</option>
                                        <option value="CDF">Congolese franc</option>
                                        <option value="CHF">Swiss franc</option>
                                        <option value="CLP">Chilean peso</option>
                                        <option value="CNY">Chinese yuan</option>
                                        <option value="COP">Colombian peso</option>
                                        <option value="CRC">Costa Rican colón</option>
                                        <option value="CUP">Cuban convertible peso</option>
                                        <option value="CVE">Cape Verdean escudo</option>
                                        <option value="CZK">Czech koruna</option>
                                        <option value="DJF">Djiboutian franc</option>
                                        <option value="DKK">Danish krone</option>
                                        <option value="DOP">Dominican peso</option>
                                        <option value="DZD">Algerian dinar</option>
                                        <option value="EGP">Egyptian pound</option>
                                        <option value="ERN">Eritrean nakfa</option>
                                        <option value="ETB">Ethiopian birr</option>
                                        <option value="EUR">Euro</option>
                                        <option value="FJD">Fijian dollar</option>
                                        <option value="FKP">Falkland Islands pound</option>
                                        <option value="GBP">British pound</option>
                                        <option value="GEL">Georgian lari</option>
                                        <option value="GHS">Ghana cedi</option>
                                        <option value="GMD">Gambian dalasi</option>
                                        <option value="GNF">Guinean franc</option>
                                        <option value="GTQ">Guatemalan quetzal</option>
                                        <option value="GYD">Guyanese dollar</option>
                                        <option value="HKD">Hong Kong dollar</option>
                                        <option value="HNL">Honduran lempira</option>
                                        <option value="HRK">Croatian kuna</option>
                                        <option value="HTG">Haitian gourde</option>
                                        <option value="HUF">Hungarian forint</option>
                                        <option value="IDR">Indonesian rupiah</option>
                                        <option value="ILS">Israeli new shekel</option>
                                        <option value="IMP">Manx pound</option>
                                        <option value="INR">Indian rupee</option>
                                        <option value="IQD">Iraqi dinar</option>
                                        <option value="IRR">Iranian rial</option>
                                        <option value="ISK">Icelandic króna</option>
                                        <option value="JEP">Jersey pound</option>
                                        <option value="JMD">Jamaican dollar</option>
                                        <option value="JOD">Jordanian dinar</option>
                                        <option value="JPY">Japanese yen</option>
                                        <option value="KES">Kenyan shilling</option>
                                        <option value="KGS">Kyrgyzstani som</option>
                                        <option value="KHR">Cambodian riel</option>
                                        <option value="KMF">Comorian franc</option>
                                        <option value="KPW">North Korean won</option>
                                        <option value="KRW">South Korean won</option>
                                        <option value="KWD">Kuwaiti dinar</option>
                                        <option value="KYD">Cayman Islands dollar</option>
                                        <option value="KZT">Kazakhstani tenge</option>
                                        <option value="LAK">Lao kip</option>
                                        <option value="LBP">Lebanese pound</option>
                                        <option value="LKR">Sri Lankan rupee</option>
                                        <option value="LRD">Liberian dollar</option>
                                        <option value="LSL">Lesotho loti</option>
                                        <option value="LTL">Lithuanian litas</option>
                                        <option value="LVL">Latvian lats</option>
                                        <option value="LYD">Libyan dinar</option>
                                        <option value="MAD">Moroccan dirham</option>
                                        <option value="MDL">Moldovan leu</option>
                                        <option value="MGA">Malagasy ariary</option>
                                        <option value="MKD">Macedonian denar</option>
                                        <option value="MMK">Burmese kyat</option>
                                        <option value="MNT">Mongolian tögrög</option>
                                        <option value="MOP">Macanese pataca</option>
                                        <option value="MRO">Mauritanian ouguiya</option>
                                        <option value="MUR">Mauritian rupee</option>
                                        <option value="MVR">Maldivian rufiyaa</option>
                                        <option value="MWK">Malawian kwacha</option>
                                        <option value="MXN">Mexican peso</option>
                                        <option value="MYR">Malaysian ringgit</option>
                                        <option value="MZN">Mozambican metical</option>
                                        <option value="NAD">Namibian dollar</option>
                                        <option value="NGN">Nigerian naira</option>
                                        <option value="NIO">Nicaraguan córdoba</option>
                                        <option value="NOK">Norwegian krone</option>
                                        <option value="NPR">Nepalese rupee</option>
                                        <option value="NZD">New Zealand dollar</option>
                                        <option value="OMR">Omani rial</option>
                                        <option value="PAB">Panamanian balboa</option>
                                        <option value="PEN">Peruvian nuevo sol</option>
                                        <option value="PGK">Papua New Guinean kina</option>
                                        <option value="PHP">Philippine peso</option>
                                        <option value="PKR">Pakistani rupee</option>
                                        <option value="PLN">Polish złoty</option>
                                        <option value="PRB">Transnistrian ruble</option>
                                        <option value="PYG">Paraguayan guaraní</option>
                                        <option value="QAR">Qatari riyal</option>
                                        <option value="RON">Romanian leu</option>
                                        <option value="RSD">Serbian dinar</option>
                                        <option value="RUB">Russian ruble</option>
                                        <option value="RWF">Rwandan franc</option>
                                        <option value="SAR">Saudi riyal</option>
                                        <option value="SBD">Solomon Islands dollar</option>
                                        <option value="SCR">Seychellois rupee</option>
                                        <option value="SDG">Singapore dollar</option>
                                        <option value="SEK">Swedish krona</option>
                                        <option value="SGD">Singapore dollar</option>
                                        <option value="SHP">Saint Helena pound</option>
                                        <option value="SLL">Sierra Leonean leone</option>
                                        <option value="SOS">Somali shilling</option>
                                        <option value="SRD">Surinamese dollar</option>
                                        <option value="SSP">South Sudanese pound</option>
                                        <option value="STD">São Tomé and Príncipe dobra</option>
                                        <option value="SVC">Salvadoran colón</option>
                                        <option value="SYP">Syrian pound</option>
                                        <option value="SZL">Swazi lilangeni</option>
                                        <option value="THB">Thai baht</option>
                                        <option value="TJS">Tajikistani somoni</option>
                                        <option value="TMT">Turkmenistan manat</option>
                                        <option value="TND">Tunisian dinar</option>
                                        <option value="TOP">Tongan paʻanga</option>
                                        <option value="TRY">Turkish lira</option>
                                        <option value="TTD">Trinidad and Tobago dollar</option>
                                        <option value="TWD">New Taiwan dollar</option>
                                        <option value="TZS">Tanzanian shilling</option>
                                        <option value="UAH">Ukrainian hryvnia</option>
                                        <option value="UGX">Ugandan shilling</option>
                                        <option value="USD">United States dollar</option>
                                        <option value="UYU">Uruguayan peso</option>
                                        <option value="UZS">Uzbekistani som</option>
                                        <option value="VEF">Venezuelan bolívar</option>
                                        <option value="VND">Vietnamese đồng</option>
                                        <option value="VUV">Vanuatu vatu</option>
                                        <option value="WST">Samoan tālā</option>
                                        <option value="XAF">Central African CFA franc</option>
                                        <option value="XCD">East Caribbean dollar</option>
                                        <option value="XOF">West African CFA franc</option>
                                        <option value="XPF">CFP franc</option>
                                        <option value="YER">Yemeni rial</option>
                                        <option value="ZAR">South African rand</option>
                                        <option value="ZMW">Zambian kwacha</option>
                                        <option value="ZWL">Zimbabwean dollar</option>
                                    </select>
                                    <p class="error-msg"><span [style]="currencyErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                            </div>
                            <div class="center-button text-center">
                                <app-fusion-button buttonValue=" {{'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" [buttonType]="'input-button'" 
                                    [buttonDisable]="!localizationForm.valid" (buttonClickEmit)="setLocalization()"  ></app-fusion-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- We have to follow the same validation steps across the fusion apps just like in Salary settings -->
            <div *ngIf="settingToShow == 'email'" class="email-settings section form-card-section">
                <div class="sub-page-name">{{ 'EMAIL_SETTINGS' |  customTranslate : 'Email Settings' }}</div>
                <div class="form-card">
                    <form [formGroup]="emailForm" autocomplete="off">
                        <div class="field-sets">
                            <div class="field-set-row flex">
                                <div class="field-input" [class]="defaultMailErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="default-email">
                                        {{ 'DEFAULT_MAIL' |  customTranslate : 'Default mail' }}*
                                    </label>
                                    <input [class]="textSaved" type="text" tabindex="3" id="default-email"
                                        formControlName="defaultMail"
                                        placeholder="Contact Person name" value=""
                                        autocomplete="off" (focusout)="handleDefaultMail($event)" (keyup)="handleDefaultMail($event)">
                                    <p class="error-msg"><span [style]="defaultMailErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                <div class="field-input" [class]="exceptionMailErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="exception-email">
                                        {{ 'EXCEPTION_MAIL' |  customTranslate : 'Exception Mail' }}*
                                    </label>
                                    <input [class]="textSaved" type="text" tabindex="3" id="exception-email"
                                        formControlName="exceptionMail"
                                        placeholder="Contact Person name" value=""
                                        autocomplete="off" (focusout)="handleExceptionMail($event)" (keyup)="handleExceptionMail($event)">
                                    <p class="error-msg"><span [style]="exceptionMailErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                <div class="field-input" [class]="paySlipReportingMailErr ? ' has-error' : ' ' ">
                                    <label class="form-label label-txt modal-field-label" for="Payslip-reporting-email">
                                        {{ 'PAYSLIP_MAIL' |  customTranslate : 'Payslip mail' }}*
                                    </label>
                                    <input [class]="textSaved" type="text" tabindex="3" id="Payslip-reporting-email"
                                        formControlName="payslipreportingMail"
                                        placeholder="Contact Person name" value=""
                                        autocomplete="off" (focusout)="handlePaySlipMail($event)" (keyup)="handlePaySlipMail($event)">
                                    <p class="error-msg"><span [style]="paySlipReportingMailErr ? 'visibility: visible' : 'visibility: hidden' "><svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                +
                            </div>
                            <div class="center-button text-center">
                                <app-fusion-button buttonValue="{{'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" [buttonType]="'input-button'" 
                                    [buttonDisable]="!emailForm.valid" (buttonClickEmit)="setEmailSettings()"  ></app-fusion-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="settingToShow == 'leaveMaster'" class="leave-master section form-card-section">
                <app-leave-master></app-leave-master>
            </div>
            <!-- SK26JUL24 org's bank info -->
            <div *ngIf="settingToShow == 'bankInfo'" class="bank-info section">
                <app-bankinfotab [fromSettings]="true" [employeeFinanceInfo]="employeeFinanceInfo"></app-bankinfotab>
            </div>
            <!-- SK31JAN24 users, apps and components module -->
            <div  *ngIf="settingToShow == 'users'" class="leave-master section form-card-section">
                <app-tabs *ngIf="userModuleData" [inputModule]="userModuleData" [skipRouting]="true"></app-tabs>
            </div>
            <div *ngIf="settingToShow == 'apps'" class="leave-master section form-card-section">
                <!-- SK22MAR24 component for applist -->
                <app-apps-list></app-apps-list>
            </div>
            <!-- <div *ngIf="settingToShow == 'components'" class="leave-master section form-card-section">
                <app-tabs *ngIf="componentModuleData" [inputModule]="componentModuleData" [skipRouting]="true"></app-tabs>
            </div> -->
            <!-- SK12FEB24 NXT componenta module details -->
            <!-- <div *ngIf="settingToShow == 'nxtComponents'" class="leave-master section form-card-section">
                NXT Components will be shown
            </div> -->
            <!-- //SK23FEB24 dropdown management component -->
            <div *ngIf="settingToShow == 'dropDownManagement'" class="leave-master section form-card-section">
                <app-drop-down-management></app-drop-down-management>
            </div>
        </div>
    </div>
</div>