import { Component, OnInit, isDevMode,ViewChild,ElementRef } from '@angular/core';
import { CommonModule, DatePipe} from '@angular/common';
import { FusionButtonComponent } from "../../fusion-button/fusion-button.component";
import { NgxPaginationModule } from 'ngx-pagination';
import { ApprovalNotificationtabComponent } from 'src/app/pages/ess/leaves/approval-notificationtab/approval-notificationtab.component';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { DropDownComponent } from '../../drop-down/drop-down.component';
import { Modal } from 'bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableComponent } from '../../data-table/data-table.component';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import * as moment from 'moment';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { ModalComponent } from '../../modal/modal.component';
import {TranslateModule,TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
    selector: 'app-expense-tab',
    standalone: true,
    templateUrl: './expense-tab.component.html',
    styleUrls: ['./expense-tab.component.css'],
    imports: [CommonModule, FusionButtonComponent,NgxPaginationModule,TranslateModule,ApprovalNotificationtabComponent, ReactiveFormsModule, SuccessFailureMessageComponent, DropDownComponent,DataTableComponent,ModalComponent,CustomTranslatePipe]
})
export class ExpenseTabComponent implements OnInit {

  @ViewChild('receiptModal') receiptModal!: ElementRef<any>; //to open the bootstrap modal based on click function in ts file

  rejectionform!: FormGroup;
  showSubmissionMsg = false;
  errorMessage = '';
  approvalNotification:Array<any>=[];
  rejectionReason: any;
  tableLength!: number;
  historyLength! : number;
  showErrorMsg = false;
  historyData: Array<any> = [];
  headersLabel : Array<any> = [];
  historyLabel : Array<any> = [];
  defaultCall = 'notPending';
  inProgressTab = true;
  modalDirect!: Modal; // bootstrap modal to open using function
  safeSrc:any;
  isDevMode: boolean = false;
  approveMsg = Constants.APPROVE_MESSAGE;
  rejectMsg = Constants.REJECT_MESSAGE;
  companyId = this.globalValues.orgId;
  openModal = false;
  approvalData : Array<any> = [];
  downloadCurrentDisable = true;
  //RS27AUG24
  //Added TranslateService to the constructor to handle translation service
  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private errorHandler : ErrorHandlerService, private sanitizer:DomSanitizer, private alertService: AlertService, private datePipe: DatePipe, private globalValues: GlobalValuesService, private translate : TranslateService) {
    this.rejectionform = this.formBuilder.group({
      rejectionReason: "",
      empId: "",
      _id: "",
      type: ""
    });

  }

  saveSuccess(data:any) {
    this.alertService.messageOnPass('success', data)
    this.ngOnInit();
    this.errorMessage = '';
  }

  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', err);
  }

  ngOnInit(): void {
    this.approvals();
    this.historyTab(this.defaultCall);
  }

  resetForm(){
    this.ngOnInit();
    this.historyData = [];
    this.approvalNotification=[];
  }
     
  approvals():void {
    this.apiService.fetchValue('/approval?expense=Pending').subscribe((data:any) => {
      if(this.isDevMode) console.log('Expense Approval Data', data);
      this.approvalNotification = data;
      this.approvalNotification.forEach((datas:any, index:any) => {
        datas.eventValue = false;
        datas.date = moment(datas.date).format('LL');
        datas.appliedOn =  moment(datas.updatedAt).format('LL');
        datas.rejectionReason = '';
        datas.defaultStatus = datas.status;
        datas.receipt.forEach((file:any, fileIndex:any) => {
          this.processFile(file.file, index, fileIndex, 'inProgress')
        });
      })

      if(this.isDevMode) console.log('Pending Data', this.approvalNotification);
      this.tableLength = this.approvalNotification.length;
    })
  }

  historyTab(defaultData:any) {
    const params = new HttpParams({
      'fromObject':{
        'expense' : defaultData
      }
    });
    this.apiService.fetchValue('/approval',params).subscribe((data: any) => {
      if(this.isDevMode) console.log('History Data database', data);
      this.historyData = data;
      this.historyData.forEach((datas:any, index:any) => {
        // declaring a key and value for storing the event and rejection reason in same array
        datas.name= datas.name;
        datas.appliedOn = moment(datas.updatedAt).format('LL');
        datas.date = moment(datas.date).format('LL');
        datas.eventValue = false;
        datas.defaultStatus = datas.status;
        datas.rejectionReason = datas.rejectionReason || '-';
        datas.receipt.forEach((file:any, fileIndex:any) => {
          this.processFile(file.file, index, fileIndex, 'history')
        });
      })
      if(isDevMode()) console.log('History Data', this.historyData);
        this.historyLength = this.historyData.length;
    })
  }
  
  // the AWS S3 link will be converted to a blob here
  processFile(url:any, elementIndex:number, fileIndex:any, from:string) : any{
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = (e1:any) =>  {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        const blob = request.response;
        // then blob is passed here to read the as text
        this.onChange(blob, elementIndex, fileIndex, from)
    };
    request.send();
  }

  // finally base64 string will be generated here
  onChange(event:any, elementIndex:number, fileIndex:number, from:string) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      const fileOutput = e.target.result;
      // this base64 string will be assigned to docList array to display in UI
      if(from === 'inProgress'){
        this.approvalNotification[elementIndex].receipt[fileIndex].file = fileOutput;
      } else {
        this.historyData[elementIndex].receipt[fileIndex].file = fileOutput;
      }
    };
    reader.readAsText(event);
  }

  leaveRejectReason(reason: any){
    this.rejectionReason = reason.data;
  }

  onApprove(data:{empId:string,id:string, data:string}, from:any){
    const time = new Date().toLocaleString("en-AU");
    const status = "Approved";
    this.approvalData = [{type:"expense", employeeId:data.empId, _id:data.id, time:time, status:status,companyId:this.companyId, reImbursed: from === 'inProgress' ? data.data : 'Reimbursed' }];
    if(from === 'inProgress'){
      this.sendApprovalSuccess(from);
    }
  }

  sendApprovalSuccess(from:any){
    this.openModal = false;
    const successMsg = from === 'history' ? 'Expense reimbursed successfully' : this.approveMsg;
    this.apiService.writeValue('patch','/approval',this.approvalData)
    .subscribe({
      next: (approvalData) => {
        if(isDevMode()) console.log("Next :", approvalData);
        this.ngOnInit();
        this.saveSuccess(successMsg);
        this.globalValues.employeeDataGet();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }

  onreject(data:{empId:string,id:string}){
    this.rejectionform.patchValue({
      rejectionReason: "",
      empId: data.empId,
      _id: data.id,
      type:"expense"
    })
  }

 // after clicking reject button, the stored data in form will be sent to api
  reasonText(){
    const value = this.rejectionform.value;
    const time = new Date().toLocaleString("en-AU");
    const status = "Rejected";
    const rejectedData = [{type:value.type, employeeId:value.empId, _id:value._id, rejectionReason:value.rejectionReason, time:time, status: status,companyId:this.companyId}];
    this.apiService.writeValue('patch','/approval',rejectedData)
    .subscribe({
      next: (rejectData) => {
        this.saveSuccess(this.rejectMsg);
        if(isDevMode()) console.log("Reject Data :", rejectData);
        this.ngOnInit();
        this.globalValues.employeeDataGet();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }

  emitAttachmentClick(event:any){
    this.modalDirect = new Modal(this.receiptModal.nativeElement, { backdrop: 'static' });
    this.modalDirect.show();
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(event);
  }

  tableCheckedDataa(checkedArray:any){
    this.checkedEmployees = [];
    this.checkedEmployees = checkedArray;
    if(this.checkedEmployees.length === 0){
      this.downloadCurrentDisable = true;
    } else {
      this.downloadCurrentDisable = false;
    }
    if(this.isDevMode) console.log('this.checkedEmployees',this.checkedEmployees);
  }
  checkedEmployees:any;
  downloadCurrentEmployees(){
    const csv = this.convertToCsv(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'Approval-Expense History.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }
  convertToCsv(objArray: any[]) {
    let objectArray: Array<any> = [];
    objArray.forEach(element => {
      objectArray.push(Object.keys(element));
    });
  var selectedObjectsForCSV = this.filterObjectKeys(objArray, ['employeeId', 'name','appliedOn','category','amount','status','rejectionReason','reImbursed']);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    array.unshift({employeeId: "Employee Id",name:'Employee Name',appliedOn:'Applied On',category:'Category',amount:'Amount',status:'Status',rejectionReason:'Reject Reason', reImbursed:'Reimbursed Status'})
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        } if (index === 'appliedOn') {
          
          // Format startDate: "October 11, 2023" to "October 11 2023"
          line += array[i][index].replace(/,/g, '-');
      }
      
        else{
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 
 
  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) { // keys are the required fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undefined
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }
  
  openIsReimburseModal(data:{empId:string,id:string, data:string}, from:any){
    this.openModal = true;
    this.onApprove(data,from);
  }
 
}







