import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DropDownComponent } from '../drop-down/drop-down.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { Constants } from 'src/app/models/Constants';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-side-screen',
  standalone: true,
  imports: [AngularSvgIconModule, DropDownComponent,CommonModule,ReactiveFormsModule, MatCheckboxModule,FusionButtonComponent,TranslateModule,CustomTranslatePipe],
  templateUrl: './side-screen.component.html',
  styleUrls: ['./side-screen.component.css']
})
export class SideScreenComponent implements OnInit {

  @Input() headerTitle: string = '';
  @Input() onLoadData: any = "";
  @Output() sideScreenEmittedEvent  = new EventEmitter<{}>;

  selectedComponentType: string = 'Select';
  componentTypeList: Array<string | number> = [Constants.EARNINGS,Constants.ALLOWANCES,Constants.DEDUCTIONS,Constants.REIMBURSEMENTS];
  nameList: Array<any> =[];
  addPayRollComponent!: FormGroup;
  selectedComp = 'Select';
  otherSection = 'Add Other';
  basicPay = Constants.BASIC;
  

  constructor(private formBuilder: FormBuilder) { 
    this.addPayRollComponent = this.formBuilder.group({ // this will be the default data when user opens the side component to add a new salary-setting-component
      'type': "",
      'name': "",
      'nameInPaySlip': "",
      'payType': "fixed",
      'proRataBasis': true,
      'calculationType': 'flat',
      'amount': '',
      'isEmployerContribution': false,
      'taxable': false,
      'active': true,
      'maxReimburse': 0,
      'deductionFrequency': '',
      'isExempted' : false,
      'isVariablePay': false //SK15JUL24
    });
  }

  ngOnInit(): void {
    if(Object.keys(this.onLoadData).length > 0){
      this.selectedComponentType = this.onLoadData.type; // for the dropdown value 
      this.selectedComp = this.onLoadData.name;
      // on editing the component, the dropdown values are listed based on component type
      if(this.onLoadData.type === Constants.EARNINGS){
        this.nameList = [this.basicPay, 'Bonus', 'Commission', this.otherSection];
      } else if (this.onLoadData.type === Constants.ALLOWANCES){
        this.nameList = [Constants.HRA, 'Special Allowance', 'Conveyance Allowance', 'Travel Allowance', 'Medical Allowance', this.otherSection];
      } else if (this.onLoadData.type === Constants.DEDUCTIONS){
        this.nameList = [Constants.PT, Constants.EPF, Constants.VPF, Constants.EMPLOYER_PF, Constants.ESI, Constants.EMPLOYER_ESI, this.otherSection];
      }
      this.addPayRollComponent.patchValue({
        'type': this.onLoadData.type,
        'name': this.onLoadData.name,
        'nameInPaySlip': this.onLoadData.nameInPaySlip,
        'payType': this.onLoadData.payType,
        'proRataBasis': this.onLoadData.proRataBasis,
        'calculationType': this.onLoadData.calculationType,
        'amount': this.onLoadData.amount,
        'isEmployerContribution': this.onLoadData.isEmployerContribution,
        'taxable': this.onLoadData.taxable,
        'active': this.onLoadData.active,
        'maxReimburse': this.onLoadData.maxReimburse,
        'deductionFrequency': this.onLoadData.deductionFrequency,
        'isExempted' : this.onLoadData.isExempted,
        "isVariablePay": this.onLoadData?.isVariablePay //SK15JUL24
      });
    }
  }

  dropdownEmitFunction(event: any, form:any){
    this.addPayRollComponent.controls[form].patchValue(event?.data);
    // should do patch value for the type dropdown
    // assigning the data to formcontrol based on the dropdown type selected
    this.selectedComp = 'Select';
    if(form === 'type'){
      this.selectedComponentType = event?.data;
      if(event?.data === Constants.EARNINGS){
        this.nameList = [this.basicPay, 'Bonus', 'Commission', this.otherSection];
      } else if (event?.data === Constants.ALLOWANCES){
        this.nameList = [Constants.HRA, 'Special Allowance', 'Conveyance Allowance', 'Travel Allowance', 'Medical Allowance', this.otherSection];
      } else if (event?.data === Constants.DEDUCTIONS){
        this.nameList = [Constants.PT, Constants.EPF, Constants.VPF, Constants.EMPLOYER_PF, Constants.ESI, Constants.EMPLOYER_ESI, this.otherSection];
      }
    } 
    else if (form === 'name'){
      if(event?.data === this.otherSection){
        this.addPayRollComponent.controls['name'].patchValue('');
      }
      this.selectedComp = event?.data;
      if(event?.data === this.basicPay){
        this.addPayRollComponent.controls['calculationType'].patchValue('fixed');
      }
    }

  }

  submitPayRollComponent(){
    console.log("Submit Form");
  }

  prorataToggle($event: any){
  }

  // sending the form data to hrm component and sent to database
  hitPayrollSettingComponentApi($event: any){
    this.sideScreenEmittedEvent.emit({'formData': this.addPayRollComponent.value})
  }

}
