<div class="form-card">
    <!-- Personal Info -->
    <div class="col-md-12 mt-3">
        <div class="static-width-for-card width-100">
            <app-cards #hideplainview [inlineDetailed]="true">
                <!-- when a HTML element has a class 'header-sectio', it will get rendered in the 'card-header' in the component's HTML -->
                <div class="header-section flex content-space-between">
                    <span>{{'BANK_INFO.ACCOUNT_DETAILS' | customTranslate : 'Account Details' }}</span>
                    <div class="card-btn show-detail-view">
                        <!-- data-bs-toggle="modal" data-bs-target="#cardDetailedModal" -->
                        <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                            class="card-icon" (click)="setDatas()"></svg-icon>
                    </div>
                </div>
                <div class="body-section card-text">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-start">
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'BANK_INFO.ACCHOLDER_NAME' | customTranslate : "Account Holder's Name" }}</label>
                                <label class="label-sub">{{bankGetData?.accountHolderName || '-'}}</label>
                            </div>
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'BANK_INFO.BANKNAME' | customTranslate : 'Bank Name' }}</label>
                                <label class="label-sub">{{bankGetData?.bankName || '-'}}</label>
                            </div>
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'BANK_INFO.ACCNO' | customTranslate : 'Account Number' }}</label>
                                <label class="label-sub">{{bankGetData?.accountNumber || '-'}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4 justify-content-start">
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'BANK_INFO.BRANCHNAME' | customTranslate : 'Branch Name' }}</label>
                                <label class="label-sub">{{bankGetData?.branchName || '-'}}</label>
                            </div>
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'BANK_INFO.IFSCCODE' | customTranslate : 'IFSC Code' }}</label>
                                <label class="label-sub">{{bankGetData?.ifsc || '-'}}</label>
                            </div>
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'ADDRESS.CITY' | customTranslate : 'City' }}</label>
                                <label class="label-sub">{{bankGetData?.city || '-'}}</label>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4 justify-content-start">
                            <div class="d-flex flex-column width-33">
                                <label class="label-head">{{'ADDRESS.STATE' | customTranslate : 'State' }}</label>
                                <label class="label-sub">{{bankGetData?.state || '-'}}</label>
                            </div>
                            <!-- SK25JUL24 payment mode -->
                            <div class="d-flex flex-column width-33">
                                <!-- RS27AUG24 -->
                                <!-- Used the translate pipe to successfully implement the translation -->
                                <label class="label-head">{{'PAY_MODE' | customTranslate : 'Payment Mode' }}</label>
                                <label class="label-sub">{{bankGetData?.paymentMode || '-'}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-expanded-section detailed-section" plain>
                    <!-- 'plain' or 'modal' based on the requirement -->
                    <div class="card-header"><b>{{'BANK_INFO.EDITBANKINFO' | customTranslate : 'Edit Bank Info' }}</b></div>
                    <div class="card-body">
                        <form [formGroup]="bankInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['accName'].invalid && bankInfoForm.controls['accName'].touched ? 'has-error' : '' ">
                                        <!--RG26AUG24 changed the label UI -->
                                        <label for="first-name" class="form-label label-txt modal-field-label">
                                            {{'BANK_INFO.ACCHOLDER_NAME' | customTranslate : 'Account Holders Name' }}*</label>
                                        <input type="text" formControlName="accName" id="first-name"
                                            placeholder="Ex.Adithya" [readOnly]="!fromHrmPage">
                                        
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['accName'].invalid && bankInfoForm.controls['accName'].touched && !this.bankInfoForm.controls['accName'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['accName'].touched && this.bankInfoForm.controls['accName'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name'  }}
                                            </span>
                                        </p>
                                    </div>

                                    <!-- <p class="error-msg"><span
                                                    [style]="lastNameError ? 'visibility: visible' : 'visibility: hidden' ">Enter
                                                    Last Name</span></p> -->
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['bankName'].invalid && bankInfoForm.controls['bankName'].touched ? 'has-error' : '' ">
                                        <label for="bank-name" class="form-label label-txt modal-field-label">
                                            {{'BANK_INFO.BANKNAME' | customTranslate : 'Bank
                                            Name*' }}*</label>
                                        <input type="text" formControlName="bankName" id="bank-name" placeholder="Ex.ICICI Bank" [readOnly]="!fromHrmPage">
                                        
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['bankName'].invalid && bankInfoForm.controls['bankName'].touched && !this.bankInfoForm.controls['bankName'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['bankName'].touched && this.bankInfoForm.controls['bankName'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name'  }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['accNum'].invalid && bankInfoForm.controls['accNum'].touched ? 'has-error' : '' ">
                                        <label for="account-number" class="form-label label-txt modal-field-label">{{'BANK_INFO.ACCNO' | customTranslate : 'Account Number' }}*</label>
                                        <input type="text" formControlName="accNum" id="account-number" placeholder="Ex.IC000897650" [readOnly]="!fromHrmPage">
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['accNum'].invalid && bankInfoForm.controls['accNum'].touched && !this.bankInfoForm.controls['accNum'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['accNum'].touched && this.bankInfoForm.controls['accNum'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number'  }}
                                            </span>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['branchName'].invalid && bankInfoForm.controls['branchName'].touched ? 'has-error' : '' ">
                                        <label for="branch-name" class="form-label label-txt modal-field-label">
                                            {{'BANK_INFO.BRANCHNAME' | customTranslate : 'Branch
                                            Name' }}*</label>
                                        <input type="text" formControlName="branchName" id="branch-name" placeholder="Ex.Purasawalkam" [readOnly]="!fromHrmPage">
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['branchName'].invalid && bankInfoForm.controls['branchName'].touched && !this.bankInfoForm.controls['branchName'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['branchName'].touched && this.bankInfoForm.controls['branchName'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name'  }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['ifsc'].invalid && bankInfoForm.controls['ifsc'].touched ? 'has-error' : '' ">
                                        <label for="ifsc-code" class="form-label label-txt modal-field-label">*</label>{{'BANK_INFO.IFSCCODE' | customTranslate : 'IFSC Code' }}*
                                        <input type="text" formControlName="ifsc" id="ifsc-code" placeholder="Ex.ICIC000067854" [readOnly]="!fromHrmPage">
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['ifsc'].invalid && bankInfoForm.controls['ifsc'].touched && !this.bankInfoForm.controls['ifsc'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['ifsc'].touched && this.bankInfoForm.controls['ifsc'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid code'  }}
                                            </span>
                                        </p>
                                    </div>
                                    <!-- SK25JUL24 adding payment mode -->
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['paymentMode'].invalid && bankInfoForm.controls['paymentMode'].touched ? 'has-error' : '' ">
                                        <label for="paymentMode" class="form-label label-txt modal-field-label">{{'PAY_MODE' | customTranslate : 'Payment Mode' }}*</label>
                                        <input type="text" formControlName="paymentMode" id="paymentMode" placeholder="Ex.ICIC000067854" [readOnly]="!fromHrmPage">
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['paymentMode'].invalid && bankInfoForm.controls['paymentMode'].touched && !this.bankInfoForm.controls['paymentMode'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['paymentMode'].touched && this.bankInfoForm.controls['paymentMode'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{'VALIDATION.VALID_TEXT' | customTranslate : 'Please enter a valid text' }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['city'].invalid && bankInfoForm.controls['city'].touched ? 'has-error' : '' ">
                                        <label for="city" class="form-label label-txt modal-field-label">{{'ADDRESS.CITY' | customTranslate : 'City' }}*</label>
                                        <input type="text" formControlName="city" id="city" placeholder="Ex.Chennai" [readOnly]="!fromHrmPage">
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['city'].invalid && bankInfoForm.controls['city'].touched && !this.bankInfoForm.controls['city'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['city'].touched && this.bankInfoForm.controls['city'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid city name.'  }}
                                            </span>
                                        </p>
                                    </div>
                                    <div class="field-input col-md-6"
                                        [class]=" bankInfoForm.controls['state'].invalid && bankInfoForm.controls['state'].touched ? 'has-error' : '' ">
                                        <label for="state" class="form-label label-txt modal-field-label">{{'ADDRESS.STATE' | customTranslate : 'State'  }}*</label>
                                        <input type="text" formControlName="state" id="state" placeholder="Ex.TamilNadu" [readOnly]="!fromHrmPage">
                                        <!-- Updated the error message with formbuilder pattern-->
                                        <p class="error-msg">
                                            <span
                                                *ngIf="bankInfoForm.controls['state'].invalid && bankInfoForm.controls['state'].touched && !this.bankInfoForm.controls['state'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required'  }}
                                            </span>
                                            <span
                                                *ngIf="bankInfoForm.controls['state'].touched && this.bankInfoForm.controls['state'].hasError('pattern')">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg">
                                                </svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid state name'  }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer border-0">
                        <div class="center-button text-right" style="margin-top:-50px">
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'  }}"
                                [buttonType]="'text-only card-btn hide-detail-view'" [dataDismiss]="''"
                                (buttonClickEmit)="bankInfoForm.reset()" [fcBtnId]="'myId'"></app-fusion-button>
                                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save'  }}"
                                 (buttonClickEmit)="setBankInfo();hideplainview.hidePlainView()"
                                [fcBtnId]="'submit'" [buttonType]="'input-button'" [buttonDisable]="!bankInfoForm.valid" ></app-fusion-button>    
                    </div>    
                    </div>
                </div>
            </app-cards>
        </div>
    </div>
</div>