<!-- SK03OCT24 Multi Filter component -->
<div class="card flex {{cardClass}} {{cardSize}}">
    <div class="border-bottom">
        <label class="form-label m-3 fw-bold">{{label | customTranslate : label}}</label>
    </div>
    <!-- <div class="search m-3">
        <div class="search-bar flex">
            <input class="medium-font width-100" type="text" placeholder="Search..." [(ngModel)]="searchText"
                style="border:none;">
            <img class="card-btn" src="../assets/images/icons/general/Search.svg">
        </div>
    </div> -->
    <div  style="{{checkBoxStyles}}">
        <div class="row flex" *ngIf="!isDateFilter">
            <div class="{{cardInlineSize}} pt-2 pb-2" *ngFor="let list of selectionData; let i = index">
                <div class="">
                    <label class="text-wrap align-self-center" style="font-size: small;" >{{list.label | customTranslate : list.label}}</label>
                    <div>
                        <app-drop-down [lists]="list.allValues" [multipleSelectedView]="list.selectedValues" selectedData="Select" [multipleSelect]="true" (multipleSelectDataEmit)="multipleSelectDataEmit($event, list)"></app-drop-down>
                    </div>
                </div>
            </div>
        </div>
        <div class="row flex" *ngIf="isDateFilter">
            <div class="{{cardInlineSize}} pt-2 pb-2">
                <!-- SK03OCT24 startDate and endDate inputs -->
                <div class="field-input">
                    <label class="modal-field-label" for="end-date">{{'Start Date*' | customTranslate : 'Start Date*'  }}</label>
                    <input type="date" max="9999-12-31" [(ngModel)]="startDate" id="start-date" [max]="endDate"  name="startDate"
                        format="mm-dd-yyyy" class="input-border" (change)="leaveCalc()"/>
                </div>

                <div class="field-input"
                    [class]="dateErr ? 'has-error' : '' ">
                    <label class="modal-field-label" for="end-date">{{'End Date*' | customTranslate : 'End Date*' }}</label>
                    <input type="date" max="9999-12-31" [(ngModel)]="endDate" id="end-date" [min]="startDate" name="endDate"
                        format="mm-dd-yyyy" class="input-border"  (change)="leaveCalc()" />
                    <p *ngIf="dateErr" class="error-msg">
                        <span> {{ 'SELECT_VALID_DATE'  | customTranslate : 'Select valid date'  }}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="m-2 d-flex justify-content-end"  *ngIf="!isDateFilter">
        <app-fusion-button *ngFor="let button of buttonList" [buttonType]="button.class" [buttonValue]="button.label | customTranslate : button.label"
            (buttonClickEmit)="buttonClick(button)">
        </app-fusion-button>
    </div>
    <!-- <div class="m-2 d-flex justify-content-end"  *ngIf="isDateFilter">
        <app-fusion-button [buttonType]="'btn btn-primary'" [buttonValue]="'Save Dates'" [buttonDisable]="dateErr"
            (buttonClickEmit)="buttonClickDate()">
        </app-fusion-button>
    </div> -->
</div>
