import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { ApiService } from './api.service';


//HttpClient for making API requests and the apiUrl (configurable) as constructor arguments.
export class BackendTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient, private apiUrl: string) {}

  // method performs a GET request to your backend API endpoint, appending the requested language code (lang) to the URL  
  getTranslation(languageCode: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/${languageCode}`);
  }
}

