import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SideNavEmitService } from '../../../services/side-nav-emit.service';
import { ApiService } from '../../../services/api.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { EssComponent } from '../ess.component';
import { Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
    selector: 'app-dependenttab',
    standalone: true,
    templateUrl: './dependenttab.component.html',
    imports: [CommonModule,FormsModule, ReactiveFormsModule, SuccessFailureMessageComponent,CardsComponent,PopoverComponent,FusionButtonComponent,AngularSvgIconModule,DependenttabComponent, EssComponent,TranslateModule,CustomTranslatePipe],
    styleUrls: ['./dependenttab.component.css']
  })
  export class DependenttabComponent implements OnInit {
    @Input() isHamClicked:boolean = false;
    hideOrShowNav: string = "side-nav-container";

    familyMembersForm! : FormGroup;
    emergencyForm! : FormGroup;
    showSubmissionMsg = false;
    showDeletedMsg = false;
    showErrorMsg = false;
    mobileRgx = "^([0-9]{4,16})"; // Added this for text Validation and used them further down the lines
    errorMessage = '';
    famGetData: any;
    
    emergencyGetData: any;
    dobError=false;
    isEditButtonOn=false;
    deletefamInfo: any;
    deleteemerInfo: any;
    isDevMode: boolean = false;
    employeeId:any;
    companyId = this.globalValues.orgId;
    @Input() selectedUser:any;
    successMsg = Constants.SUCCESS_MESSAGE;
    deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
    relationDropdown:any;
  
  
  constructor(private formBuilder: FormBuilder, private apiService: ApiService,private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService,private globalValues:GlobalValuesService,private Language:TranslateService) {

    this.isDevMode = isDevMode();
    
    this.familyMembersForm = this.formBuilder.group({
        'fullName' : ['', Validators.required],
        'relation' : ['', Validators.required],
        'dob' : ['', Validators.required],
        'famID':['']
      })
  
      this.emergencyForm = this.formBuilder.group({
        'fullName' : ['', Validators.required],
        'relation' : ['', Validators.required],
        'phNumber' : ['', Validators.compose([Validators.required, Validators.pattern(this.mobileRgx)])],
        'emerID' : ['']
      })
    }
    
    

  // addFamilyMembers(){
  //   this.familyMembersForm.reset();
  // }

  // addEmergencyNumber(){
  //   this.emergencyForm.reset();
  // }

  addDependent(): void{
    this.isEditButtonOn=true;
    this.familyMembersForm.reset();
  }

  addMember(): void{
    this.isEditButtonOn=true;
    this.emergencyForm.reset();
  }
  
  ngOnInit(): void {
    // SK01APR24 getting data as input
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();

    if (this.isHamClicked) {
      this.hideOrShowNav = "hideOrShowNav hide"
    }

    // SK27FEB24 getting emptype dropdowns from local
    // SK06MAR24 settings data as whole stored in local and getting dropdowns from it
    const dropDowns = JSON.parse((localStorage.getItem('settingsData'))as any);
    this.relationDropdown = dropDowns?.dropdowns?.filter((relation:any)=>relation.name === 'relation')[0]
    || {valueList:[
      {key:"father", value:"Father"},
      {key:"mother", value:"Mother"},
      {key:"spouse", value:"Spouse"},
      {key:"guardian", value:"Guardian"}
    ]};
  }

  // SK17JAN24 reducing api call
  ngOnChanges(): void {
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();

    if (this.isHamClicked) {
      this.hideOrShowNav = "hideOrShowNav hide"
    }
  }

  saveMsg(){
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg);
    this.ngOnInit();
  }

  deleteMsg(){
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg);
    this.ngOnInit();
  }

  errorMsg(error: HttpErrorResponse){
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  /** These are the GET method functions that returns the data from database */
  getESSDatas(){
    this.apiService.fetchValue('/user').subscribe((datas:any) => {
        this.employeeId = datas?.loggedInUser[0]?.employeeId
        this.getData();
    })
  }

  getData(){
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    this.famGetData = this.selectedUser?.dependents;
    this.employeeId = this.selectedUser?.employeeId;
    this.emergencyGetData = this.selectedUser?.emergencyContacts;
  }

  saveFamilyMembers(){
    this.isEditButtonOn=false;
    if(this.isDevMode) console.log("Submit fm");
    let value = this.familyMembersForm.value;
    if(value.famID != null){
      return this.apiService.writeValue('patch', '/employee/family', {'fullName': value.fullName, 'relation': value.relation, 'dateOfBirth': value.dob, 'dependentId': value.famID, 'employeeId':this.employeeId})
      .subscribe({
        next: (fmInfo) => {
          if(this.isDevMode) console.log("Next :", fmInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
      
    } else {
      return this.apiService.writeValue('post', '/employee/family', {'fullName': value.fullName, 'relation': value.relation, 'dateOfBirth': value.dob, 'employeeId': this.employeeId})
      .subscribe({
        next: (fmInfo) => {
          if(this.isDevMode) console.log("Next :", fmInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    }
  }

  saveEmergencyContact(){
    this.isEditButtonOn=false;
    if(this.isDevMode) console.log("Submit documents");
    let value = this.emergencyForm.value;
    if(value.emerID != null){
      return this.apiService.writeValue('patch', '/employee/emergencycontact', {'fullName': value.fullName, 'relation': value.relation, 'phoneNumber': value.phNumber, 'emergencyContactId': value.emerID, 'employeeId':this.employeeId})
      .subscribe({
        next: (emergentInfo) => {
          if(this.isDevMode) console.log("Next :", emergentInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    }
    return this.apiService.writeValue('post', '/employee/emergencycontact', {'fullName': value.fullName, 'relation': value.relation, 'phoneNumber': value.phNumber, 'employeeId': this.employeeId})
    .subscribe({
      next: (emergentInfo) => {
        if(this.isDevMode) console.log("Next :", emergentInfo);
        this.saveMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      }
    });
  }

  famCancelButton(){
    this.isEditButtonOn=false;
    this.familyMembersForm.reset();
  }

  emerCancelButton(){
    this.isEditButtonOn=false;
    this.emergencyForm.reset();
  }

  deleteID:any;
  onEditFam(user: any){
    this.isEditButtonOn=true;
      this.deleteID = user.dependentId;
      this.familyMembersForm.setValue({
        'fullName' : user.fullName,
        'relation' : user.relation,
        'dob' : user.dateOfBirth,
        'famID': user.dependentId
      })
    }

  famOnDelete(deletedfam: any) {
    this.deletefamInfo = deletedfam;
  }

  onEditEmergency(id: any){
    this.isEditButtonOn=true;
    this.deleteID = id.emergencyContactId;
    this.emergencyForm.setValue({
      'fullName' : id.fullName,
      'relation' : id.relation,
      'phNumber' : id.phoneNumber,
      'emerID': id.emergencyContactId
    })
  }

  emerOnDelete(deletedemer: any){
    this.deleteemerInfo = deletedemer;
  }
  
  deleteFM(){

    this.apiService.writeValue('delete','/employee/family',{ body: { 'dependentId': this.deletefamInfo.dependentId, 'employeeId': this.employeeId}} ).subscribe({
      next: () => {
        this.deleteMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
        if(this.isDevMode) console.log("Next:",this.deleteFM);
      }
    });
  }


  deleteEC(){
    this.apiService.writeValue('delete','/employee/emergencycontact',{body: { emergencyContactId: this.deleteemerInfo.emergencyContactId, employeeId: this.employeeId }})
    .subscribe({
      next: () => {
        this.deleteMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
        if(this.isDevMode) console.log("Next:",this.deleteEC);
      }
    });
  }


  clickedButton($event: any){
    if(this.isDevMode) console.log("The button is clicked and here is the $Event", $event);
  }

  cardHeaderIconClick(){
    alert('Header icon is clicked in Card');
  }
   
}
