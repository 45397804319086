import { CommonModule } from '@angular/common';
import { Component, OnInit, isDevMode } from '@angular/core';
// Custom Components are imported here
import { FusionButtonComponent } from '../../components/fusion-button/fusion-button.component';
import { PopoverComponent } from '../../components/popover/popover.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
// Third party plugins 
import { AngularSvgIconModule } from 'angular-svg-icon'; /* To conver img tag to svg decoded format */ 
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-fusion-framework',
  standalone: true,
  imports: [CommonModule,PopoverComponent,FusionButtonComponent,AngularSvgIconModule,CardsComponent,DropDownComponent, CustomTranslatePipe],
  templateUrl: './fusion-framework.component.html',
  styleUrls: ['./fusion-framework.component.css']
})
export class FusionFrameworkComponent implements OnInit {
  isDevMode: boolean = false;

  constructor() { this.isDevMode = isDevMode();}

  ngOnInit(): void {
  }

  popOverItemClick(){
    alert("Function called on click");
  }

  clickedButton($event: any){
    if(this.isDevMode) console.log("The button is clicked and here is the $Event", $event);
  }

  cardHeaderIconClick(){
    alert('Header icon is clicked in Card');
  }

  list=['Select','ABC', 'DEF', 'GHI', 'JKL'];
}
