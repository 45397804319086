import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, OnInit, isDevMode, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, DropDownComponent],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  currentApplicationVersion = environment.appVersion;
  public resetPasswordError: string | undefined = undefined;
  resetPasswordForm!: FormGroup;
  resetPasswordButton = "reset-button button-disabled";
  errorCreatePasswordMsg = false;
  errorConfirmPasswordMsg = false
  resetPasswordScreen = true;
  username = '';
  email='';
  token='';
  pageComponentName = 'ResetPassword';
  isDevMode: boolean = false;
  translations: Record<string, any> ={
    en: {
      "SCREEN_DIRECTION":"ltr",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "Welcome, Please login into your account",
          "USER_NAME" : "Username/Email",
          "PASSWORD" : "Password",
          "USER_NAME_ERROR" : "Please enter a valid email address",
          "PASSWORD_ERROR" : "Password must contain at least 6 characters",
          "LOGIN" : "Login",
          "BACK" : "Back",
          "RESEND_LINK" : "Resend Link",
          "FORGOT_PASSWORD" : "Forgot your Password?",
          "PASSWORD_RESET" : "Password Reset",
          "RESET_MAIL" : "Please enter your registered email id to receive password reset instructions.",
          "SEND_RESET_LINK" : "Send link to email",
          "GO_BACK_TO_LOGIN" : "Go back to Login",
          "EMAIL_SENT" : "Email Sent",
          "CHECK_INBOX" : "Check your inbox and open the link we sent to",
          "ENTER_NEW_PASSWORD" : "Enter a new password",
          "NEW_PASSWORD" : "New password",
          "CONFIRM_PASSWORD" : "Confirm password",
          "PASSWORD_MISMATCH" : "Password mismatch",
          "WELL_DONE" : "Well Done!",
          "PASSWORD_CHANGE_SUCCESS" : "Your password has been changed successfully. Please use your new password to login! ",
          "REMEMBER_ME":"Remember me"
      }
    },
    ar: {
      "SCREEN_DIRECTION":"rtl",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "مرحبًا ، الرجاء تسجيل الدخول إلى حسابك",
          "USER_NAME" : "اسم المستخدم / البريد الإلكتروني",
          "PASSWORD" : "كلمة المرور",
          "USER_NAME_ERROR" : "يرجى إدخال عنوان بريد إلكتروني صالح",
          "PASSWORD_ERROR" : "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل",
          "LOGIN" : "تسجيل الدخول",
          "BACK" : "Back",
          "RESEND_LINK" : "Resend Link",
          "FORGOT_PASSWORD" : "نسيت كلمة السر؟",
          "PASSWORD_RESET" : "إعادة تعيين كلمة المرور",
          "RESET_MAIL" : "الرجاء إدخال معرف البريد الإلكتروني المسجل الخاص بك لتلقي تعليمات إعادة تعيين كلمة المرور.",
          "SEND_RESET_LINK" : "إرسال ارتباط إلى البريد الإلكتروني",
          "GO_BACK_TO_LOGIN" : "ارجع إلى تسجيل الدخول",
          "EMAIL_SENT" : "أرسل البريد الإلكتروني",
          "CHECK_INBOX" : "تحقق من صندوق الوارد الخاص بك وافتح الرابط الذي أرسلناه إلى",
          "ENTER_NEW_PASSWORD" : "Enter a new password",
          "NEW_PASSWORD" : "New password",
          "CONFIRM_PASSWORD" : "Confirm password",
          "PASSWORD_MISMATCH" : "Password mismatch",
          "WELL_DONE" : "Well Done!",
          "PASSWORD_CHANGE_SUCCESS" : "Your password has been changed successfully. Please use your new password to login! ",
          "REMEMBER_ME":"Remember me"
      }
    },
    ta: {    
      "SCREEN_DIRECTION":"ltr",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "வரவேற்கிறோம், உங்கள் கணக்கில் உள்நுழையவும்",
          "USER_NAME" : "பயனர் பெயர் / மின்னஞ்சல்",
          "PASSWORD" : "கடவுச்சொல்",
          "USER_NAME_ERROR" : "சரியான மின்னஞ்சல் முகவரியை உள்ளிடவும்",
          "PASSWORD_ERROR" : "கடவுச்சொல்லில் குறைந்தது 6 எழுத்துகள் இருக்க வேண்டும்",
          "LOGIN" : "உள்நுழைய",
          "BACK" : "பின் செல்",
          "RESEND_LINK" : "இணைப்பை மீண்டும் அனுப்பு",
          "FORGOT_PASSWORD" : "உங்கள் கடவுச்சொல்லை மறந்து விட்டீர்களா?",
          "PASSWORD_RESET" : "கடவுச்சொல் மீட்டமைப்பு",
          "RESET_MAIL" : "கடவுச்சொல் மீட்டமைப்பு வழிமுறைகளைப் பெற உங்கள் பதிவு செய்யப்பட்ட மின்னஞ்சல் ஐடியை உள்ளிடவும்.",
          "SEND_RESET_LINK" : "மின்னஞ்சலுக்கு இணைப்பை அனுப்பவும்",
          "GO_BACK_TO_LOGIN" : "உள்நுழைவுக்குத் திரும்பு",
          "EMAIL_SENT" : "மின்னஞ்சல் அனுப்பப்பட்டது",
          "CHECK_INBOX" : "உங்கள் இன்பாக்ஸைச் சரிபார்த்து, நாங்கள் அனுப்பிய இணைப்பைத் திறக்கவும்",
          "ENTER_NEW_PASSWORD" : "புதிய கடவுச்சொல்லை உள்ளிடுக",
          "NEW_PASSWORD" : "புதிய கடவுச்சொல்",
          "CONFIRM_PASSWORD" : "மீண்டும் கடவுச்சொல்லை உறுதிப்படுத்தவும்",
          "PASSWORD_MISMATCH" : "கடவுச்சொல் பொருந்தவில்லை",
          "WELL_DONE" : "நன்றாக முடிந்தது!",
          "PASSWORD_CHANGE_SUCCESS" : "உங்கள் கடவுச்சொல் வெற்றிகரமாக மாற்றப்பட்டது. உள்நுழைய உங்கள் புதிய கடவுச்சொல்லைப் பயன்படுத்தவும்!",
          "REMEMBER_ME":"Remember me"
      }
    },
    hi: {    
      "SCREEN_DIRECTION":"ltr",
      "LOGIN_PAGE" : {
          "WELCOME_TEXT" : "स्वागत है, कृपया अपने खाते में लॉगिन करें",
          "USER_NAME" : "उपयोक्तानाम/ईमेल",
          "PASSWORD" : "पासवर्ड",
          "USER_NAME_ERROR" : "कृपया एक मान्य ईमेल पता प्रविष्ट करें",
          "PASSWORD_ERROR" : "पासवर्ड में कम से कम 6 अक्षर होने चाहिए",
          "LOGIN" : "लॉग इन करें",
          "BACK" : "Back",
          "RESEND_LINK" : "Resend Link",
          "FORGOT_PASSWORD" : "अपना कूट शब्द भूल गए?",
          "PASSWORD_RESET" : "पासवर्ड रीसेट",
          "RESET_MAIL" : "पासवर्ड रीसेट निर्देश प्राप्त करने के लिए कृपया अपनी पंजीकृत ईमेल आईडी दर्ज करें।",
          "SEND_RESET_LINK" : "ईमेल पर लिंक भेजें",
          "GO_BACK_TO_LOGIN" : "लॉगइन पर वापस जाएं",
          "EMAIL_SENT" : "ईमेल भेजा",
          "CHECK_INBOX" : "अपना इनबॉक्स जांचें और हमारे द्वारा भेजा गया लिंक खोलें",
          "ENTER_NEW_PASSWORD" : "Enter a new password",
          "NEW_PASSWORD" : "New password",
          "CONFIRM_PASSWORD" : "Confirm password",
          "PASSWORD_MISMATCH" : "Password mismatch",
          "WELL_DONE" : "Well Done!",
          "PASSWORD_CHANGE_SUCCESS" : "Your password has been changed successfully. Please use your new password to login! ",
          "REMEMBER_ME":"Remember me"
      }
    }
  };
  isRtl = false;
  currentLang: any;

  constructor(private fb: FormBuilder, private activeroute: ActivatedRoute, private apiService: ApiService, private router: Router, private errorHandler: ErrorHandlerService , private translate: TranslateService , private globalValues : GlobalValuesService,@Inject(DOCUMENT) private _document: Document) { 
    this.isDevMode = isDevMode();
    this.translate.setDefaultLang("en");
    if(localStorage.getItem('language') != undefined || localStorage.getItem('language') != null){
      const a : any = localStorage.getItem('language');
      this.translate.use(a);
    }
    this.currentLang ="en"
  }

  languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'தமிழ்', value: 'ta' },
    { label: 'हिंदी', value: 'hi' },
    { label: 'عربي', value: 'ar' }
  ];
  emittedValue(selectedItem: any) {
    if (selectedItem) {
      this.currentLang = selectedItem.data.value;

      // Update language in localStorage
      localStorage.setItem('language', this.currentLang);
      // Update the direction and language attributes on the document
      if (this.currentLang === 'ar') {
        this.isRtl = true;
      } else {
        this.isRtl = false;
      }
    }
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      'createPassword': [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30)])],
      'confirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(30)])]
    });
    this.translate.setDefaultLang("en");
    if(localStorage.getItem('language') != undefined || localStorage.getItem('language') != null){
      const a : any = localStorage.getItem('language');
      this.translate.use(a);
      this.currentLang = a
    }
    if (this.currentLang === 'ar') {
      this.isRtl = true;
    } else {
      this.isRtl = false;
    }
  }

  goToPage(pageName:string){
    this.router.navigate([`${pageName}`]);
  }

  enableButton(createPassword: any, confirmPassword: any) {
    if (createPassword === confirmPassword && createPassword != "" && confirmPassword != "") {
      this.resetPasswordButton = 'reset-button button-enable';
    }
    else {
      this.resetPasswordButton = 'reset-button button-disabled';
    }
  }

  handlePasswordChange(event: any): void {
    this.resetPasswordForm.value.createPassword = event.target.value;
    if (this.resetPasswordForm.value.createPassword.length > 5 && this.resetPasswordForm.value.createPassword.length < 30 ) {
      this.errorCreatePasswordMsg = false;
    } else {
      this.errorCreatePasswordMsg = true;
    }
  }

  handleConfirmPasswordChange(event: any): void {
    this.resetPasswordForm.value.confirmPassword = event.target.value;
    if (this.resetPasswordForm.value.createPassword === this.resetPasswordForm.value.confirmPassword) {
      this.errorConfirmPasswordMsg = false;
    } else {
      this.errorConfirmPasswordMsg = true;
    }
    this.enableButton(this.resetPasswordForm.value.createPassword, this.resetPasswordForm.value.confirmPassword);
  }


  resetPassword(){
    this.resetPasswordError = '';
    const value = this.resetPasswordForm.value
    this.activeroute.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.apiService.writeValue('put','/resetpassword', {'token':this.token,'password':value.confirmPassword} )
    .subscribe({
      next: (v) => {
        if(this.isDevMode) console.log("Next :", v);
        if(this.isDevMode) console.log("Password reset successful");
        this.resetPasswordScreen = false;
        
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
        this.resetPasswordError = this.errorHandler.errorMessage;
        this.resetPasswordScreen = true;
      }
    });    
  }
  

}
