import { Injectable, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomPaginatorIntl extends MatPaginatorIntl {
  public labelChange: EventEmitter<void> = new EventEmitter<void>(); // Define the labelChange event

  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) {
    super();
    this.updateTranslations(); // Initialize translations

    // Subscribe to language changes
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTranslations(); // Update translations
      this.labelChange.emit(); // Emit the label change event
      this.cdr.detectChanges(); // Trigger change detection
    });
  }

  private updateTranslations() {
    if(this.translate.instant('ITEMS_PER_PAGE') === "ITEMS_PER_PAGE"){
      this.itemsPerPageLabel = "Item per page";
    }else{
      this.itemsPerPageLabel = this.translate.instant('ITEMS_PER_PAGE')
    }
    this.nextPageLabel = this.translate.instant('NEXT_PAGE');
    this.previousPageLabel = this.translate.instant('PREVIOUS_PAGE');
    this.firstPageLabel = this.translate.instant('FIRST_PAGE');
    this.lastPageLabel = this.translate.instant('LAST_PAGE');
    console.log("Updated paginator labels:", this);
  }
}
