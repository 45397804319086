import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private translate: TranslateService) { }

  // Observable string sources
  private passType = new Subject<any>();

  // Observable string streams
  passAlertType$ = this.passType.asObservable();
  messageData!:{}

  // Service message commands
  messageOnPass(condition: string, message:any){
    this.messageData = {
      type: condition,
      message: this.translate.instant(message)
    }
    this.passType.next(this.messageData);
  }

  // Service message commands
  messageOnFetch(condition: string, identifier: string, message:any){
    this.messageData = {
      type: condition,
      fetchId: identifier,
      message: this.translate.instant(message)
    }
    this.passType.next(this.messageData);
  }
}
