import { Component, ElementRef, EventEmitter, Input,SimpleChanges, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule } from '@angular/forms';
import { SearchFilterPipe } from 'src/app/pipes/search-filter/search-filter.pipe';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-drop-down',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, FormsModule, SearchFilterPipe,CustomTranslatePipe],
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.css']
})
export class DropDownComponent implements OnInit {
  @Input() lists! : Array<any>; // dropdown datas are reveived as input in form of array from child component
  @Output() dataEmit = new EventEmitter<any>(); //emits the selected data from dropdown to child component
  @Input() dropDownWidth= ''; // width value for dropdown to increase/decrease width size
  @Input() dropDownHeight= ''; // increase/decrease the height of drop down box
  @Input() dropBorderRadius= '5px'; // to style the of the border;
  @Input() selectedData :any = null; // default value from array list can be sent in this variable
  @Input() controlName: string = '';
  @Input() multipleSelect: boolean = false; //SK03OCT24
  @Input() multipleSelectedData: Array<any> = []; //SK03OCT24
  @Input() multipleSelectedView: Array<any> = []; //SK03OCT24

  /*the 'errorValue' (or) 'defaultValue', any one can be used. If we want to display error while selecting the data, 
      then 'errorValue' variable can be used, else, if we want to select none from list, we can use default value*/
  @Input() errorValue = '';  
  @Input() defaultValue = '';
  @Input() isDisabled = false;
  @Input() isRequired = false; //  input property to indicate whether the dropdown is required or not
  @Input() isError: boolean = false;
  @Output() errorEmit = new EventEmitter();
  @Input() objectDisplayKey: string = ' label '; // Key to display in the dropdown //RS04SEP24
  isListOfTypeObject: boolean = false;//RS04SEP24
  @Output() multipleSelectDataEmit = new EventEmitter(); //SK03OCT24

  //error message to be displayed if we displaying a error
  @Input() errorMessage = '';
  searchText = ""; //SK03OCT24

  //RS04SEP24
  //checks if the lists input has changed and whether it contains objects. If lists is an array of objects and the selectedData is no longer present in the updated list, it sets selectedData to the first item in the list.
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedData']) {
      const currentValue = this.lists;
      if (currentValue && currentValue.length > 0) {
        const firstElement = currentValue[0];
        this.isListOfTypeObject = typeof firstElement === 'object' && firstElement !== null ;
        for (let i = 0; i < currentValue.length; i++) { // SKS11OCT25 Update selectedData to select the matching object
          if (currentValue[i].value === this.selectedData) {
            this.selectedData = currentValue[i];
            break;
          }
        }
      }
    }
  }

  private clickListener!: (event: MouseEvent) => void;


  constructor(private readonly elementRef: ElementRef) { }

  ngOnInit(): void {
    // add an event listener to the document object that listens for a click event.
     this.clickListener = this.handleDocumentClick.bind(this);
     document.addEventListener('click', this.clickListener);
  }
  
  ngOnDestroy(): void {
    // remove the event listener from the document object to prevent memory leaks
    document.removeEventListener('click', this.clickListener);
  }

  dropDown = false;
  showError = false;

  listValue(event: any, data:any, type:string){
    // SK03OCT24 dropdown from single or multiple select
    if(type==="singleSelect"){
      this.dataEmit.emit({event: event, data: data, controlName: this.controlName});
      this.isError = false;
    } else if(type==="multipleSelect"){
      let list = Array.from(this.multipleSelectedView);
      list.push(data)
      this.multipleSelectedView = new Set(list) as any ;
      this.multipleSelectDataEmit.emit({event: event, data:Array.from(this.multipleSelectedView), controlName: this.controlName});
      this.isError = false;
    }
  }

  // first check if the click event occurred inside the dropdown component or not by calling the contains method on the nativeElement property of the elementRef object. 
  // If the click event occurred outside the component, we set the dropDown property to false, which will close the dropdown
  private handleDocumentClick(event: MouseEvent): void {
    const isClickInside = this.elementRef.nativeElement.contains(event.target);
    if (!isClickInside) {
      this.dropDown = false;
    }
  }
  // when outside click if the selected data and error value is equlas then show the error message
  outsideClick(){
    if(this.selectedData == this.errorValue){
      this.isError = true;
      this.errorEmit.emit(true);
    }else{
      this.isError = false;
      this.errorEmit.emit(false);
    }
  }

  // SK03OCT24 removing the values, if multiple select values in filter, 
  removeSelectedFilter(data:string, index:number){
    let list = Array.from(this.multipleSelectedView);
    list.splice(index,1);
    this.multipleSelectedView = list as any;
    this.multipleSelectDataEmit.emit({data:Array.from(this.multipleSelectedView), controlName: this.controlName});
  }
}
