import { Component, Inject, isDevMode, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
// Service for SideNav
import { SideNavEmitService } from './services/side-nav-emit.service';
import { __values } from 'tslib';
import { AlertService } from './services/alert.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { GlobalValuesService } from './services/global-values.service';
import { Constants } from './models/Constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  activeNav: string = '';
  currentPageComponent: string = 'NoComponent'
  title = 'fusion-cloud';
  showSideNav: boolean = false;
  currentApplicationVersion = environment.appVersion;
  lastClickedNavItemHrm: string | undefined;
  lastClickedNavItemEss: string | undefined;

  hamBurgerClickSubscribe: Subscription;
  showSideNavInMobile: boolean = false;
  myActiveNav: any;
  alertMessage: string = '';
  successMessage = false;
  errorMessage = false;
  isDevMode: boolean = false;
  companySettingLabel : any;
  salarySettingLabel : any;
  leaveMasterLabel : any;
  settingsLabel:any
  list: Array<any> = [
    {name:'COMPANY_SETTING', variable: 'companySettingLabel' },
    {name:'SALARY_SETTINGS', variable: 'salarySettingLabel' },
    {name:'LEAVE_MASTER', variable: 'leaveMasterLabel'},
    {name:'SETTINGS', variable: 'settingsLabel'}
  ]
  languageCode :any; //SKS11OCT24

  alertsubscription: Subscription;
  // SK21AUG24 to display dynamic modules
  hrmModules:Array<any> = [];
  hrmStaticModules:Array<any> = Constants.hrmStaticModules;
  essModules:Array<any> = [];
  essStaticModules:Array<any> = Constants.essStaticModules
  settingsModules:Array<any> = [];
  settingsStaticModules:Array<any> = Constants.settingsStaticModules

  constructor(@Inject(DOCUMENT) private _document: Document, private _router: Router, private sideNavItem: SideNavEmitService, private router: Router, private routeActive: ActivatedRoute, private alertService: AlertService, private globalValues: GlobalValuesService, private apiService: ApiService, private translate: TranslateService,private renderer: Renderer2) {
    //SKS11OCT24 get a languageCode from localstorage
    const userData = JSON.parse(localStorage.getItem('userData') as any);
    console.log(localStorage)
    if(userData) {
      userData.languageCode = localStorage.getItem('language') || userData.languageCode;  //SKS11OCT24 Update the languageCode in the userData object
      localStorage.setItem('userData', JSON.stringify(userData)); 
      this.languageCode = localStorage.getItem('language') || userData.languageCode || "en";
      // this.translate.use(this.languageCode);
    }else{
      const storedLanguage = localStorage.getItem('language');
      this.languageCode = storedLanguage ? storedLanguage : "ar";
    }
    
    this.globalValues.laguageChanges$.subscribe((lang:any) => {
      this.languageChange();
    })
    
    this.alertsubscription = this.alertService.passAlertType$.subscribe((alert: any) => {
      // MR 10JAN24 for handling single API request from the calling component
      if (alert.type === 'success' || alert.type === 'successFetch') {
        this.successMessageFunction(alert.message);
      } else {
        this.errorMessageFunction(alert.message);
      }
    });

    // SK07MAR24
    this.globalValues.activeModuleValue$.subscribe((nav: any) => {
      // MR SK07MAR24 on notification click activating the module based on type
      this.activeNav = nav;
      this.callNavService(nav);
    });

    //We can use location.pathname to get the current path name in the url 
    //private location: Location import from angular/common
    this.isDevMode = isDevMode();
    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let currentUrl = event.url;
        const dropDowns = JSON.parse(localStorage.getItem('settingsData') as any);
        if(dropDowns){
          const langlist = dropDowns.dropdowns?.find((item: { name: string; }) => item.name === 'languages')?.valueList.map((item: { value: any; }) => item.value) || [];
          // SKS11OCT25 Get the dropdown language value from the URL
          // Check if any language from the langlist matches the current URL
          let currentLang = langlist.find((lang: string) => currentUrl.match(new RegExp(`/${lang}(\\/|$|\\?)`))) || null;

          // Fallback logic if no match is found
          if (!currentLang) {
            currentLang = langlist.find((lang: string) => currentUrl.includes(`/${lang}?`)) || 'en';
          }
          const userData = JSON.parse(localStorage.getItem('userData') as any);
          if(userData) {
            userData.languageCode = currentLang || userData.languageCode;  //SKS11OCT24 Update the languageCode in the userData object
            localStorage.setItem('userData', JSON.stringify(userData)); 
          }else{
            const storedLanguage = localStorage.getItem('language');
            this.languageCode = storedLanguage ? storedLanguage : "en";
          }
        }
        this.languageChange()
      }
    });

    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.hamBurgerClickSubscribe = this.sideNavItem.isHamburgerClicked$.subscribe(navItem => {
      if (navItem == 'hamburger') {
        this.showSideNavInMobile = !this.showSideNavInMobile;
      }
    });
    // SK21DEC23 dynamic fusion color module
    const userDataConst:any = JSON.parse(localStorage.getItem('userData') as any);
    if(userDataConst?.theme){
      document.documentElement.style.setProperty('--fusion-color', userDataConst.theme?.fusionColor);
      document.documentElement.style.setProperty('--module', userDataConst.theme?.clrBody);
      document.documentElement.style.setProperty('--active-module', userDataConst.theme?.fcSubLite);
      document.documentElement.style.setProperty('--active-module-bg', userDataConst.theme?.fcPrimaryColor);
    }

    //SK21AUG24
    // const userData = JSON.parse(localStorage.getItem('userData') as any);
    const settingsData = JSON.parse(localStorage.getItem('settingsData') as any);
    if(userData) {
      const loggedInUserData = userData.apps;
      this.languageCode = userData.languageCode || "en"; //SKS11OCT24
      loggedInUserData.forEach((appArr:any) => {
        // SK21AUG24
        if(appArr.name === 'hrm'){
          this.hrmModules = appArr.modules;
        } else if(appArr.name === 'ess'){
          this.essModules = appArr.modules;
        } else if(appArr.name === 'settings'){
          this.settingsModules = appArr.modules;
        }
      });
    } else {
      console.log('waiting for the api response');
    }
  }

  componentAdded($event: any) {
    var excludeList = ['Login', 'ResetPassword', 'PageNotFound', 'Parking', 'HireRegistration']; //SK31JUL24
    this.currentPageComponent = $event.pageComponentName;
    if (excludeList.includes(this.currentPageComponent)) {
      //Side navigation is hidden from User.
      this.showSideNav = false; //Side navigation is hidden from User.
    }
    else {
      //Side Navigation is turned on and is visible to the User.
      this.showSideNav = true;

      this.routeActive.queryParams.subscribe(params => {  //get the current active nav
        this.myActiveNav = params['activeNav'];
      });
      //This switch case is to set the default active nav on load of a page.
      switch (this.currentPageComponent) {
        case 'Settings':
          this.activeNav = this.myActiveNav == null ? 'company' : this.myActiveNav;
          break;
        case 'Ess':
          // this.lastClickedNavItemEss = navIemEss === null ?  'details' : navIemEss 
          // this.activeNav = 'details'
          this.activeNav = this.myActiveNav == null ? 'details' : this.myActiveNav;
          break;
        case 'Hrm':
          // this.lastClickedNavItemHrm = navIemHrm === null ?  'employees' : navIemHrm 
          this.activeNav = this.myActiveNav == null ? 'dashboard' : this.myActiveNav; // SK01FEB24
          break;
        case 'Parking':
          // this.lastClickedNavItemHrm = navIemHrm === null ?  'employees' : navIemHrm 
          this.activeNav = this.myActiveNav == null ? 'details' : this.myActiveNav;
          break;
      }
    }
  }

  clickdedEmptySideNav($event: any) {
    /* This function is created to close the side nav when clicked on the white space */
    /* We have to write more condition to detect if it is a mobile device to avoid unwanted execution */
    if ($event.target?.classList.length > 1) {
      if ($event.target?.classList?.contains("empty-space-on-nav")) {
        this.sideNavItem.clickedOnHamburger('hamburger'); /* This is used to close the side nav item in mobile devices */
      }
    }
  }

  callNavService(navName: string) {
    // SK06MAR24 to remove the nav tab list and active tab name
    // SK13MAR24 updated from local storage to session storage, for navigating navtabs in different tabs
    sessionStorage.removeItem("navTabsList");
    sessionStorage.removeItem("activeNavTab");
    sessionStorage.removeItem("activeNavSubTab");
    if (this.isDevMode) console.log('callNavService:', navName);
    this.sideNavItem.clickedOnSideNav(navName);
    this.sideNavItem.clickedOnHamburger('hamburger'); /* This is used to close the side nav item in mobile devices */
    this.activeNav = navName;
    let pageFrom = '';
    this.routeActive.queryParams.subscribe(params => {
      pageFrom = params['pageFrom'];
    });
    this.router.navigate([], { queryParams: { activeNav: this.activeNav, pageFrom: pageFrom } });  // to get the updated active nav in query param
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.hamBurgerClickSubscribe.unsubscribe();
  }
  ngOnInit(): void {
    this.setTitleAndFavicon();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        let currentUrl = event.url;
        // SKS11OCT25 Get the dropdown language value from the URL
        const dropDowns = JSON.parse(localStorage.getItem('settingsData') as any);
        if(dropDowns){
          const langlist = dropDowns.dropdowns?.find((item: { name: string; }) => item.name === 'languages')?.valueList.map((item: { value: any; }) => item.value) || [];
          // SKS11OCT25 Get the dropdown language value from the URL
          // Check if any language from the langlist matches the current URL
          let currentLang = langlist.find((lang: string) => currentUrl.match(new RegExp(`/${lang}(\\/|$|\\?)`))) || null;

          // Fallback logic if no match is found
          if (!currentLang) {
            currentLang = langlist.find((lang: string) => currentUrl.includes(`/${lang}?`)) || 'en';
          }
          const userData = JSON.parse(localStorage.getItem('userData') as any);
          if(userData) {
            userData.languageCode = currentLang || userData.languageCode;  //SKS11OCT24 Update the languageCode in the userData object
            localStorage.setItem('userData', JSON.stringify(userData)); 
          }else{
            const storedLanguage = localStorage.getItem('selectedLanguage');
            this.languageCode = storedLanguage ? storedLanguage : "ar";
          }
        }
        this.languageChange()
      }
    });
  }
  

  successMessageFunction(message: any) {
    this.alertMessage = message;
    this.successMessage = true;
    setTimeout(() => { this.successMessage = false; }, 3000);
  }

  errorMessageFunction(message: any) {
    this.alertMessage = message;
    this.errorMessage = true;
    setTimeout(() => { this.errorMessage = false; }, 3000);
  }

  //To dynamically display title;
  setTitleAndFavicon() {
    const currentURL = window.location.href;
    //To extract the domain from URL;
    const domain = currentURL.split('.')[1] + '.' + currentURL.split('.')[2];
    let title: string;
    let faviconPath: string;
    if (domain.includes('valarhr.com')) {
      title = 'ValarHR|Complete HRM Solutions';
      faviconPath = 'ValarhrLogo.ico';
    } else {
      title = 'Fusion Apps';
      faviconPath = 'favicon.ico';
    }
    this._document.title = title;
    const faviconElement = this._document.getElementById('favicon') as HTMLLinkElement;
    faviconElement.setAttribute('href', faviconPath);
  }


languageChange() {
  const userData = JSON.parse(localStorage.getItem('userData') as any);
  if(userData) {
    this.languageCode = userData.languageCode || "en";
    this.translate.use(this.languageCode);
  }else{
    this.languageCode = localStorage.getItem('language') || "en";
  }
  console.log('Language change detected:', this.languageCode);
  // this.translate.use(this.languageCode);
  //RS27AUG24
  // Set the direction of the document based on language (RTL or LTR)
  if (this.languageCode === 'ar') {
    this._document.documentElement.setAttribute('dir', 'rtl');
    this._document.documentElement.setAttribute('lang', 'ar');
  } else {
    this._document.documentElement.setAttribute('dir', 'ltr');
    this._document.documentElement.setAttribute('lang', this.languageCode);
  }
    this.translate.get('SCREEN_DIRECTION').subscribe((direction: string) => {
      const dirValue = direction === 'rtl' ? 'rtl' : 'ltr';
      this.renderer.setAttribute(document.body, 'dir', dirValue);
    });
}

  

}
