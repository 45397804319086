import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'editColumnDropdown',
  standalone: true
})
export class EditColumnDropdownPipe implements PipeTransform {

  // SK10APR24 to check the data type of table value is dropdown
  transform(arg:any, element: any, editCol: any): any {
    var returnVal :any ;
    returnVal = (editCol.filter((col:any)=>col.name === element));
    return returnVal[0].values;
  }

}
