import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class GlobalValuesService {

  // Declare the variables for each permissions / roles
  orgId: any;
  superUser = false;
  hrmUser = false;
  hrmManager = false;
  empId: any;
  loginName: any;
  loginFirstName: any;
  loginLastName: any;
  fullLoginName: any;
  compFullName: any;
  userId: any;
  allEmployeeData: any;
  allNotificationData: any;
  loggedInUserData: any
  tokenVariable:any; // SK22MAR24 to aviod null error in token
  permissions:any
  token:any;

  private employeeGet = new Subject<any>();
  employeeDetails$ = this.employeeGet.asObservable();

  private userDataGet = new Subject<any>();
  loggedInuserDetails$ = this.userDataGet.asObservable();

  private allEmployeeDetails = new Subject<any>();
  allEmployeeDetails$ = this.allEmployeeDetails.asObservable();

  private notificationGet = new Subject<any>();
  notificationGetData$ = this.notificationGet.asObservable();

  //SK23FEB24 to pass tht table data and received as subscription in data table component
  private tableGet = new Subject<any>();
  tableGetData$ = this.tableGet.asObservable();

  // SK06MAR24 to update nav tabs list
  private navTabsGet = new Subject<any>();
  navTabsGetData$ = this.navTabsGet.asObservable();

  // SK07MAR24 to update the active module on clicking notification
  private activeModule = new Subject<any>();
  activeModuleValue$ = this.activeModule.asObservable();

  constructor(private apiService: ApiService, private spinnerService: NgxSpinnerService, private translate : TranslateService) {
    this.globalValues();
    // this.employeeDataGet();  // MR 07DEC23 Commented this call since its duplicate
    // SK22MAR24 to aviod null error in token
    this.token = localStorage.getItem('auth_token') as string;
    if(this.token) {
      this.globalValues();
      this.allEmployeeGet();
    }
    // const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24 this for remove a translate from login page
    // if (localStorage.getItem('language') && userData) {
    //   const a : any = localStorage.getItem('language');
    //   // this.translate.use(a);
    // }else if(userData) {
    // // this.translate.setDefaultLang("en");
    // }
    
   
    if(this.tokenVariable !== null){
      this.userGet();
      if(this.permissions.includes("HRM User" || "HRM Manager" || "Super User" )){
        this.allEmployeeGet();
      }
      this.getNotifications();
    }
  }

  // Observable string sources to get the updated attendance table datas
  private attendanceData = new Subject<any>();
  // Observable string streams for updated attendanc    e table datas
  attendanceDataChange$ = this.attendanceData.asObservable();

  // Service message commands to get the updated attendance table datas
  attendancePass(message: any) {
    this.attendanceData.next(message);
  }

  globalValues() {
    const token1 = localStorage.getItem('auth_token') as string;
    //21MAR24 got token null error while login, so added a condition
    this.tokenVariable = token1;
    if(token1 != null){
      let split = (JSON.parse(window.atob(token1.split('.')[1])).permissions);
      this.loginName = (JSON.parse(window.atob(token1.split('.')[1])).username);
      // this.loginFirstName = this.loginName.split('.')[0];
      // this.loginLastName = this.loginName.split('.')[1];
  
      //decoded values from token to use globally;
      const tokenObject = JSON.parse(window.atob(token1.split('.')[1]));
      this.fullLoginName = tokenObject.username;
      this.compFullName = tokenObject.companyName;
      this.orgId = tokenObject.companyId;
      this.empId = tokenObject.employeeId;
      this.userId = tokenObject.userId;
      this.permissions = tokenObject.permissions;
  
      split.forEach((element: any) => {
        if (element.includes('Super User')) {
          this.superUser = true;
        }
  
        if (element.includes('HRM User') || element.includes('HRM Manager')) {
          this.hrmUser = true;
        }
      });
  
      // MR 07DEC23 Get All Employees only when you are Super User to HRM User
      if(this.superUser || this.hrmUser) {
        this.employeeDataGet();
      }
    }
  }

  userGet() {
    console.log('inside userGet()');
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      console.log('from localStorage ');
      console.log(JSON.parse(userDataConst));
      
      this.userDataGet.next(JSON.parse(userDataConst));
      this.loggedInUserData = JSON.parse(userDataConst);
    } else {
        this.userGetAPI();
    }
    
    
    /*
    if(userDataConst !== null) {
      console.log('using data from localstorage');
      console.log(userDataConst);
      this.userDataGet.next(userDataConst);
    } else {
      this.apiService.fetchValue('/user').subscribe((data: any) => {
        console.log(data.loggedInUser[0]);
        this.userDataGet.next(data.loggedInUser[0]);
  
        localStorage.setItem('userData', data.loggedInUser[0]);
      });
    }
    */
  }

  // SK06MAR24 to update the user data and store it in local 
  userGetAPI(){
    this.apiService.fetchValue('/user').subscribe((data: any) => {
      // this.spinnerService.hide();
      console.log('after fetch /user response');
      console.log(data.loggedInUser[0].apps);
      this.userDataGet.next(data.loggedInUser[0]);

      this.loggedInUserData = data.loggedInUser[0];
      localStorage.setItem('userData', JSON.stringify(data.loggedInUser[0]));
      localStorage.setItem('settingsData', JSON.stringify(data.settings[0])); //SK27FEB24 storing dropdown data in local //SK06MAR24 storing the setting data as whole
      // window.location.reload();// SK14MAY24
    });
  }

  // MR 09JAN24 Company Id is not needed on All Employees read
  employeeDataGet() {
    const params = new HttpParams({
      fromObject: {
        employeeid: this.empId
      }
    });
    this.apiService.fetchValue('/hrm', params).subscribe((data: any) => {
      // this.spinnerService.hide(); //SK06MAR24
      this.allEmployeeData = data;
      this.employeeGet.next(this.allEmployeeData);
      console.log('Global Get values',data);
    })
  }

    // Observable string sources to get the updated attendance table datas
    private changeLanguage = new Subject<any>();

    // Observable string streams for updated attendance table datas
    laguageChanges$ = this.changeLanguage.asObservable();

  translateText(language:any){
    debugger
    localStorage.setItem('language', language);
    this.translate.use(language);
    this.changeLanguage.next(language);
  }
      // console.log('Global Get values', data);

  allEmployeeGet(){
    this.apiService.fetchValue('/hrm/allemployees').subscribe((datas: any) => {
      // this.spinnerService.hide(); //SK06MAR24
      this.allEmployeeDetails.next(datas);
    })
  }

  getNotifications(){
    // this.apiService.fetchValue('/employee/notification').subscribe((data: any) => {
    //   this.spinnerService.hide(); //SK06MAR24
    //   this.notificationGet.next(data);
    // });
  }

  private chartData = new Subject<any>();
  chartDataChange$ = this.chartData.asObservable();

  // the chart data will be passed here and sent it dashboard component
  chartDataOnPass(data: any) {
    this.chartData.next(data);
  }

  tableData(data:any){
    this.tableGet.next(data);
  }

  // SK06MAR24
  navTabData(data:any){
    this.navTabsGet.next(data);
  }

  // SK07MAR24 module as per notification type passed here
  activeNavModule(data:string){
    this.activeModule.next(data);
  }
  // MV24AUG24 the filter data will be passed here and sent it to search-filter component
  private activeFilter = new Subject<any>();
  activeFilterChange$ = this.activeFilter.asObservable();
  activeFilterBox(data:any){
    this.activeFilter.next(data);
  }

  // SK02SEP24 csv convert as comma separation
  convertToCsv2(objArray: any[], fields:any) {
    let filterteData: any[] = [];
    objArray.forEach(element => {
      let objs:any = {};
      // only selected fields from configuration is returned
      fields.forEach((key:any) => {
        if(!Object.keys(element).includes(key)){
          element[key] = "-";
        } else {
          objs[key] = this.getValue(element, key);

        }
      });
      filterteData.push(objs);
    });
    filterteData
    var selectedObjectsForCSV = this.filterObjectKeys(objArray, fields);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    let header:any = {};
    fields.forEach((head:any) => {
      header[head] = head;
    });
    array.unshift(header)
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        }
        if(typeof array[i][index] == 'object'){
          line += JSON.stringify(array[i][index]).replace(/,/g,"++");
        }
        else{
          // // SK14MAY24 id string has comma, add another string quotes
          if(String(array[i][index])?.includes(",")){
            array[i][index] = '"' + array[i][index] + '"';
          } else if(array[i][index] === "" || array[i][index] === null || array[i][index] === undefined){
            array[i][index] = "-";
          }
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 

  // SK02SEP24 Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) { // keys are the reuired fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undeifned
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }

  // SK02SEP24
  getValue(element:any, column:string){
    let flds = column.split('.');
    for(let i=0; i<flds.length; i++){
      let splitFlds = flds[i].split('[');
      if(splitFlds.length === 1){
        element = element[flds[i]] || '' as any;
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        element = element[splitFlds[0]][index]  || '' as any; //SK 20DEC23 To solve undefined error
      }
    }
    return element;
  }
}
