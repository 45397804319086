

<div class="content-section leave-master flex flex-column flex-grow max-width-minus-side-nav" style="--grow-value: 1">
    <div class=""><!-- Removed class ' main-content' since the leave master is moved to the Company settings page -->
        <div class="tab-heading sub-page-name">{{ 'LEAVE_MASTER' | customTranslate : 'Leave Master' }}</div>
        <div class="flex flex-direction-row tab-view-buttons">
            <ul class="nav nav-tabs fc-tabs" id="essTabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="template-tab" data-bs-toggle="tab"
                        data-bs-target="#template-tab-pane" type="button" role="tab" aria-controls="template-tab-pane"
                        aria-selected="true">{{ 'TEMPLATE' | customTranslate : 'Template' }}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="holiday-tab" data-bs-toggle="tab" data-bs-target="#holiday-tab-pane"
                        type="button" role="tab" aria-controls="holiday-tab-pane"
                        aria-selected="false">{{ 'HOLIDAYS' | customTranslate : 'Holidays' }}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="week-off-tab" data-bs-toggle="tab" data-bs-target="#week-off-tab-pane"
                        type="button" role="tab" aria-controls="week-off-tab-pane" aria-selected="false">
                        {{ 'WEEK_OFF' | customTranslate : 'Week Off' }}
                    </button>
                </li>
            </ul>
        </div>

        <div class="tab-content" id="leavesTabContent">
            <div class="tab-pane fade show active" id="template-tab-pane" role="tabpanel" aria-labelledby="template-tab" tabindex="0">
                <div class="d-flex justify-content-end">
                    <app-fusion-button buttonType="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#templateModal" buttonValue=" + {{ 'TEMPLATE' | customTranslate : 'Template' }}" (buttonClickEmit)="templateFormDeclaration()">
                        </app-fusion-button>
                </div>
                
                <app-data-table *ngIf="templateLeaveData" [data]="templateLeaveData"
                    [displayedColumns]="['leaveType','leaveCycle', 'noOfDays', 'tableStartDate', 'tableEndDate', 'default', 'active', 'paidLeave']"
                    [headerLabels]="['Leave Type', 'Leave Cycle', 'No. of Days', 'Start Date', 'End Date', 'Default', 'Active', 'Paid Leave']"
                    [translatePath]="'LEAVE_MASTER_TEMPLATE_TABLE'"
                    [fromWhere]="'leaveMaster'" [withCheckBox]="false"
                    [actionButton]="true" [searchBar]="true" (onEditData)="onEditTemplate($event)"
                    (OnDeleteData)="deleteTemplate($event)" >
                </app-data-table>

                <!-- Template Info Modal -->
                <div class="modal fade container-md" id="templateModal" tabindex="-1"
                    aria-labelledby="templateModalLabel" aria-hidden="true"  #tempModalOpen>
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header pt-2 pb-2">
                                <div class="modal-title fs-5" id="templateModalLabel">{{ 'TEMPLATE' | customTranslate : 'Template' }}</div>
                                <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                                [fcBtnId]="'close'">
                               </app-fusion-button>
                            </div>
                            <form class="template-form flex flex-column" id="templateForm" [formGroup]="templateForm" autocomplete="off">
                                <div class="bottom-line"></div>
                                <div class="flex mt-3 modal-padding">
                                    <div class="row g-3 flex">
                                        <div>
                                            <!--RG26AUG24 added asterisk to important fields -->
                                            <label class="form-label label-txt modal-field-label">{{ 'LEAVE_TYPE' | customTranslate : 'Leave Type' }}</label>
                                            <app-drop-down #leaveType class="field-input" [lists]='leaveTypeList' [controlName]="'leaveType'" (dataEmit)="emittedValue($event)"
                                                [selectedData]="templateForm.value.leaveType" errorValue="{{ 'DEFAULT_DROPDOWN_VALUE' | customTranslate : 'Select' }}" errorMessage="{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}" #dropDownError [isError]="tempErrorMsg" (errorEmit)="emitDropDownError($event, 'tempLeaveType')"></app-drop-down>
                                                <!-- SK17JUL24 to add additional leave types -->
                                                <div *ngIf="selectedLeaveType === 'Add Other'">
                                                    <input type="text" class="form-control" placeholder="Leave Type" aria-label="Text input with dropdown button" formControlName="leaveType">
                                                </div>                                             
                                        </div>
                                        <div>
                                            <label class="form-label label-txt modal-field-label">{{ 'LEAVE_CYCLE' | customTranslate : 'Leave Cycle' }}*</label>
                                            <app-drop-down #leaveCycle class="field-input" [lists]='leaveCycleList' [controlName]="'leaveCycle'" (dataEmit)="emittedValue($event)"
                                                [selectedData]="templateForm.value.leaveCycle" errorValue="{{ 'DEFAULT_DROPDOWN_VALUE' | customTranslate : 'Select' }}" errorMessage="{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}" #dropDownError [isError]="tempCycleError" (errorEmit)="emitDropDownError($event, 'tempLeaveCycle') "></app-drop-down>
                                        </div>
                                        <div class="field-input w-100"
                                           [class]="templateForm.controls['noOfDays'].invalid && templateForm.controls['noOfDays'].touched? 'has-error' : '' ">
                                            <label for="total-days" class="form-label label-txt modal-field-label">{{ 'TOTAL_DAYS'  | customTranslate : 'Total Days' }}*</label>
                                            <input id="total-days" formControlName="noOfDays" class="form-control w-100" type="number">
                                            <p class="error-msg">
                                                <span *ngIf="templateForm.controls['noOfDays'].invalid && templateForm.controls['noOfDays'].touched && !this.templateForm.controls['noOfDays'].hasError('pattern')">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                </span>
                                                
                                                <ng-container *ngIf="templateForm.controls['leaveType'].value === 'Sick leave' 
                                                || templateForm.controls['leaveType'].value === 'Compensation Off' || 
                                                templateForm.controls['leaveType'].value === 'Casual leave'">
                                                    <span *ngIf="templateForm.controls['leaveCycle'].value === 'Monthly' 
                                                    && (templateForm.controls['noOfDays'].value > 1 
                                                    || templateForm.controls['noOfDays'].value < 0.5)">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                        <!-- RS27AUG24 -->
                                                        <!-- Used the translate pipe to successfully implement the translation -->
                                                        {{ templateForm.controls['leaveType'].value }} {{'CANNOT BE MORE THAN 1 DAY FOR MONTHLY CYCLE' | customTranslate : 'Cannot Be More Than 1 Day For Monthly Cycle.' }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="templateForm.controls['leaveType'].value === 'Loss of Pay'">
                                                    <span *ngIf="templateForm.controls['leaveCycle'].value === 'Monthly' 
                                                    && templateForm.controls['noOfDays'].value > 31">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                        {{ templateForm.controls['leaveType'].value }} {{'CANNOT BE MORE THAN 31 DAYS FOR MONTHLY CYCLE' | customTranslate : 'cannot be more than 31 days for Monthly cycle' }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="templateForm.controls['leaveType'].value === 'Marriage leave' 
                                                || templateForm.controls['leaveType'].value === 'Paternity leave'">

                                                    <span *ngIf="templateForm.controls['leaveCycle'].value === 'Monthly' 
                                                    && templateForm.controls['noOfDays'].value > 15">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                        {{ templateForm.controls['leaveType'].value }} {{'CANNOT BE MORE THAN 15 DAYS FOR MONTHLY CYCLE' | customTranslate : 'cannot be more than 15 days for Monthly cycle' }}
                                                    </span>
                                                 </ng-container>
                                                <ng-container *ngIf="templateForm.controls['leaveType'].value === 'Maternity leave'
                                                 && templateForm.controls['leaveCycle'].value === 'Monthly'">
                                                    <span *ngIf="templateForm.controls['noOfDays'].value > 30">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                        {{'MATERNITY LEAVE CANNOT EXCEED 30 DAYS PER MONTH' | customTranslate : 'Maternity leave cannot exceed 30 days per month' }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngFor="let leaveType of leaveTypeList">
                                                    <span *ngIf="templateForm.controls['leaveType'].value === leaveType  
                                                    && templateForm.controls['noOfDays'].value > maxDays[leaveType] && 
                                                    templateForm.controls['leaveCycle'].value === 'Yearly'" >
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                        {{ leaveType }} {{'CANNOT BE MORE THAN' | customTranslate : 'cannot be more than' }} {{ maxDays[leaveType] }} {{'DAYS' | customTranslate : 'Days' }}
                                                    </span>
                                                </ng-container>
                                                <span *ngIf="templateForm.controls['noOfDays'].touched && this.templateForm.controls['noOfDays'].hasError('pattern')">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}
                                                </span>                                      
                                            </p>
                                        </div>
                                        <div> <!--SKS16OCT24 Yearly Leave Carry Over Days-->
                                            <label class="form-label label-txt modal-field-label">Yearly Leave Carry Over Days *</label>
                                            <app-drop-down #carryOverDays class="field-input" [lists]='carryOverNamesList' [controlName]="'carryOverDays'" (dataEmit)="emittedCarryOverDays($event)"
                                                [selectedData]="carryOverName" errorValue="Select" errorMessage="Please select the value" #dropDownError [isError]="carryOverDayError" (errorEmit)="emitDropDownError($event, 'tempLeaveCarryOver')"></app-drop-down>
                                                <div *ngIf="selectedCarryOverDays === 'Add Days'">
                                                    <input type="text" class="form-control"  aria-label="Text input with dropdown button" formControlName="carryOverDays">
                                                </div>                                             
                                        </div>
                                        
                                        <div class="field-input"
                                        [class]="templateForm.controls['startDate'].invalid && templateForm.controls['startDate'].touched || leaveError ? 'has-error' : '' ">
                                          <label class="form-label label-txt modal-field-label" for="start-date">{{ 'START_DATE' | customTranslate : 'Start Date' }}*</label>
                                            <input type="date" max="9999-12-31" formControlName="startDate" class="form-control" id="start-date" name="date"
                                           format="mm-dd-yyyy"   (change)="startDate($event); leaveCalc()" />
                                            <p class="error-msg">
                                                <span
                                                    *ngIf="templateForm.controls['startDate'].invalid && templateForm.controls['startDate'].touched || startDayError">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'SELECT_VALID_DATE' | customTranslate : 'Select valid date' }}
                                                </span>
                                            </p>
                                        </div>                                    
                                        <div class="field-input" 
                                            [class]=" templateForm.controls['endDate'].invalid && templateForm.controls['endDate'].touched  || leaveError ? 'has-error' : '' ">
                                             <label class="form-label label-txt modal-field-label" for="end-date">{{ 'END_DATE' | customTranslate : 'End Date' }}*</label>
                                            <input type="date" max="9999-12-31" formControlName="endDate" class="form-control" id="end-date" name="date"
                                            format="mm-dd-yyyy"  (change)="endDate($event); leaveCalc()"/>
                                            <p class="error-msg">
                                                <span *ngIf="templateForm.controls['endDate'].invalid && templateForm.controls['endDate'].touched || leaveError"> 
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> {{ (leaveError ? ('VALIDATION.SUFF_LEAVE'| customTranslate : 'Leave is not sufficient') : ('SELECT_VALID_DATE'| customTranslate : 'Select valid date') )}}
                                                </span>
                                            </p>
                                        </div> 

                                        <div class="row pt-4 pb-4 padding-20px" >
                                            <div class="form-check col-md-4">
                                                <input class="form-check-input" name="checkbox-1"
                                                    formControlName="Default" type="checkbox"
                                                    id="flexCheckDefault-1">
                                                <label class="form-label label-txt modal-field-label" for="flexCheckDefault-1">
                                                    {{ 'DEFAULT' | customTranslate : 'Default' }}
                                                </label>
                                            </div>
                                            <div class="form-check col-md-4">
                                                <input class="form-check-input" name="checkbox-2"
                                                    formControlName="active" type="checkbox"
                                                    id="flexCheckDefault">
                                                <label class="form-label label-txt modal-field-label" for="flexCheckDefault">
                                                    {{ 'ACTIVE' | customTranslate : 'Active' }}
                                                </label>
                                            </div>
                                            <!-- Default switch -->
                                            <div class="form-check form-switch col-md-4">
                                                <input class="form-check-input" type="checkbox" formControlName="paidLeave" role="switch" id="flexSwitchCheckDefault">
                                                <label class="form-label label-txt modal-field-label" for="flexSwitchCheckDefault">{{ 'PAID_LEAVE' | customTranslate : 'Paid Leave' }}</label>
                                            </div>
                                        </div>
                                        <div class="row input-margin justify-content-end">
                                            <div class="row-md-4 text-end input-margin-top">
                                                <app-fusion-button [buttonValue]="'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'"    (buttonClickEmit)="resetForm()"
                                                [buttonType]="'text-only card-btn hide-detail-view'"
                                                data-bs-dismiss="modal">Cancel</app-fusion-button>
                                                <app-fusion-button [buttonValue]=" 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' " [buttonDisable]="!templateForm.valid || startDayError || leaveError"
                                                (buttonClickEmit)="saveTemplate()" data-bs-dismiss="modal">Save</app-fusion-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="holiday-tab-pane" role="tabpanel" aria-labelledby="holiday-tab" tabindex="0">
                <div class="d-flex justify-content-end">
                    <app-fusion-button buttonType="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#holidayModal" buttonValue="+ {{ 'HOLIDAYS' | customTranslate : 'Holidays' }}" (buttonClickEmit)="holidayFormDeclaration()">
                     </app-fusion-button>
                </div>
                <app-data-table *ngIf="holidaysLeaveData" [data]="holidaysLeaveData"
                    [displayedColumns]="['leaveType','leaveCycle', 'noOfDays', 'tableStartDate', 'tableEndDate', 'default', 'active', 'paidLeave']"
                    [headerLabels]="['Leave Type', 'Leave Cycle', 'No. of Days', 'Start Date', 'End Date', 'Default', 'Active', 'Paid Leave']"
                    [translatePath]="'LEAVE_MASTER_HOLIDAY_TABLE'"
                    [fromWhere]="'leaveMaster'" [withCheckBox]="false"
                    [actionButton]="true" [searchBar]="true" (onEditData)="onEditHoliday($event)"
                    (OnDeleteData)="deleteHoliday($event)" >
                </app-data-table>
                <!-- Holiday Modal -->
                <div class="modal fade container-md" id="holidayModal" tabindex="-1"
                    aria-labelledby="holidayModalLabel" aria-hidden="true" #holidayModalOpen>
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header pt-2 pb-2">
                                <div class="modal-title fs-5" id="holidayModalLabel">{{ 'HOLIDAYS' | customTranslate : 'Holidays' }}</div>
                                <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                                [fcBtnId]="'close'">
                                </app-fusion-button>
                            </div>

                            <form class="holiday-form flex flex-column" id="holidayForm" [formGroup]="holidayForm" autocomplete="off">
                                <div class="bottom-line"></div>
                                <div class="flex mt-3 modal-padding">
                                    <div class="row g-3 flex">
                                        <div class="field-input w-100"
                                        [class]="holidayForm.controls['leaveType'].invalid && holidayForm.controls['leaveType'].touched? 'has-error' : '' ">
                                            <label for="leaveType" class="form-label label-txt modal-field-label">{{ 'LEAVE_TYPE' | customTranslate : 'Leave Type' }}*</label>
                                            <input id="leaveType" formControlName="leaveType" class="form-control" type="text">
                                            <p class="error-msg">
                                                <span *ngIf="holidayForm.controls['leaveType'].invalid && holidayForm.controls['leaveType'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <label class="form-label label-txt modal-field-label">{{ 'LEAVE_CYCLE' | customTranslate : 'Leave Cycle' }}*</label>
                                            <app-drop-down #leaveType class="field-input" [lists]='leaveCycleList' [controlName]="'leaveCycle'"
                                            (dataEmit)="emittedValue($event)"
                                            [selectedData]="holidayForm.value.leaveCycle" errorValue="{{ 'DEFAULT_DROPDOWN_VALUE' | customTranslate : 'Select' }}" errorMessage="{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}" #dropDownError [isError]="holidayErrorMsg" (errorEmit)="emitDropDownError($event, 'holidayLeaveCycle')"></app-drop-down>
                                        </div>
                                        <div class="field-input w-100"
                                        [class]="holidayForm.controls['noOfDays'].invalid && holidayForm.controls['noOfDays'].touched? 'has-error' : '' ">
                                            <label for="total-days" class="form-label label-txt modal-field-label">{{ 'TOTAL_DAYS' | customTranslate : 'Total Days' }}*</label>
                                            <input id="total-days" formControlName="noOfDays" class="form-control" type="number">
                                            <p class="error-msg">
                                                    <span *ngIf="holidayForm.controls['noOfDays'].invalid && holidayForm.controls['noOfDays'].touched && !this.holidayForm.controls['noOfDays'].hasError('pattern')">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                </span>
                                                <span *ngIf="holidayForm.controls['noOfDays'].touched && this.holidayForm.controls['noOfDays'].hasError('pattern')">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="field-input"
                                        [class]=" holidayForm.controls['startDate'].invalid && holidayForm.controls['startDate'].touched  || startDayError ? 'has-error' : '' ">
                                       
                                            <label class="form-label label-txt modal-field-label" for="start-date">{{ 'START_DATE' | customTranslate : 'Start Date' }}*</label>
                                            <input type="date" max="9999-12-31" formControlName="startDate" class="form-control" id="start-date" name="date"
                                            format="mm-dd-yyyy"  (change)="startDate($event); leaveCalc()"/>
                                            <p class="error-msg">
                                                <span
                                                *ngIf="holidayForm.controls['startDate'].invalid && holidayForm.controls['startDate'].touched || startDayError">
                                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                {{'SELECT_VALID_DATE' | customTranslate : 'Select valid date' }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="field-input"
                                        [class]=" holidayForm.controls['endDate'].invalid && holidayForm.controls['endDate'].touched || leaveError ? 'has-error' : '' ">
                                         <label class="form-label label-txt modal-field-label" for="end-date">{{ 'END_DATE' | customTranslate : 'End Date' }}*</label>
                                            <input type="date" max="9999-12-31" formControlName="endDate" class="form-control" id="end-date" name="date"
                                            format="mm-dd-yyyy" (change)="endDate($event); leaveCalc()" />
                                                <p class="error-msg"><span
                                                        *ngIf="holidayForm.controls['endDate'].invalid && holidayForm.controls['endDate'].touched || leaveError"> 
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                        {{ (leaveError ? ('VALIDATION.SUFF_LEAVE'| customTranslate : 'Leave is not sufficient') : ('SELECT_VALID_DATE'| customTranslate : 'Select valid date') )}}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="row pt-4 pb-4 padding-20px" >
                                        <div class="form-check col-md-4">
                                            <input class="form-check-input" name="checkbox-1"
                                                formControlName="Default" type="checkbox"
                                                id="defaultHoliday" value="Default">
                                            <label class="form-label label-txt modal-field-label" for="defaultHoliday">
                                                {{ 'DEFAULT' | customTranslate : 'Default' }}
                                            </label>
                                        </div>
                                        <div class="form-check col-md-4">
                                            <input class="form-check-input" name="checkbox-2"
                                                formControlName="active" type="checkbox"
                                                id="activeHoliday">
                                            <label class="form-label label-txt modal-field-label" for="activeHoliday">
                                                {{ 'ACTIVE' | customTranslate : 'Active' }}
                                            </label>
                                        </div>
                                        <!-- Default switch -->
                                        <div class="form-check form-switch col-md-4">
                                            <input class="form-check-input" type="checkbox" formControlName="paidLeave" role="switch" id="paidLeaveHoliday">
                                            <label class="form-label label-txt modal-field-label" for="paidLeaveHoliday">{{ 'PAID_LEAVE' | customTranslate : 'Paid Leave' }}</label>
                                        </div>
                                    </div>
                                    <div class="row input-margin justify-content-end">
                                        <div class="row-md-4 text-end input-margin-top">
                                            <app-fusion-button buttonValue=" {{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'}} "   (buttonClickEmit)="resetForm()"
                                            [buttonType]="'text-only card-btn hide-detail-view'"
                                            data-bs-dismiss="modal"></app-fusion-button>
                                            <app-fusion-button buttonValue=" {{'BUTTON_VALUE.SAVE' | customTranslate : 'Save'}} "
                                            [buttonDisable]="!holidayForm.valid || startDayError || leaveError"
                                             (buttonClickEmit)="saveHoliday()" data-bs-dismiss="modal"></app-fusion-button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="week-off-tab-pane" role="tabpanel" aria-labelledby="week-off-tab" tabindex="0">
                <div class="d-flex justify-content-end">
                    <app-fusion-button buttonType="btn btn-primary" data-bs-toggle="modal"
                        data-bs-target="#weekoffModal" buttonValue="+ {{ 'WEEK_OFF' | customTranslate : 'Week Off' }}" (buttonClickEmit)="weekOffFormDeclaration(); this.selection.clear();">
                     </app-fusion-button>
                </div>
                <app-data-table *ngIf="weekoffTableData" [data]="weekoffTableData"
                    [displayedColumns]="['leaveType','startDate', 'endDate', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'default','active', 'paidLeave']"
                    [headerLabels]="['Leave Type', 'Start Date' , 'End Date', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Default','Active', 'Paid Leave']"
                    [translatePath]="'LEAVE_MASTER_WEEKOFF_TABLE'"
                    [fromWhere]="'leaveMaster'" [withCheckBox]="false"
                    [actionButton]="true" [searchBar]="true" (onEditData)="onEditWeekOff($event)"
                    (OnDeleteData)="deleteWeekOff($event)" >
                </app-data-table>
                <!-- weekoff Modal -->
                <div class="modal fade container-md" id="weekoffModal" tabindex="-1"
                    aria-labelledby="weekoffModalLabel" aria-hidden="true" #weekOffModalOpen>
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header pt-2 pb-2">
                                <div class="modal-title fs-5" id="weekoffModalLabel">{{ 'WEEK_OFF' | customTranslate : 'Week Off' }}</div>
                                <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                                [fcBtnId]="'close'">
                                </app-fusion-button>
                            </div>

                            <form class="weekoff-form flex flex-column" id="weekoffForm" [formGroup]="weekoffForm" autocomplete="off">
                                <div class="bottom-line"></div>
                                <div class="flex mt-3 modal-padding">
                                    <div class="row g-3 flex">
                                        <div class="field-input w-100"
                                        [class]="weekoffForm.controls['leaveType'].invalid && weekoffForm.controls['leaveType'].touched? 'has-error' : '' ">
                                            <label class="form-label label-txt modal-field-label" for="leaveType">{{ 'LEAVE_TYPE' | customTranslate : 'Leave Type' }}*</label>
                                            <input type="text" formControlName="leaveType" class="form-control" id="leaveType"/>
                                            <p class="error-msg">
                                                <span *ngIf="weekoffForm.controls['leaveType'].invalid && weekoffForm.controls['leaveType'].touched">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> 
                                                    {{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="field-input"
                                        [class]=" weekoffForm.controls['startDate'].invalid && weekoffForm.controls['startDate'].touched  || startDayError ? 'has-error' : '' ">
                                        
                                            <label class="form-label label-txt modal-field-label" for="start-date">{{ 'START_DATE' | customTranslate : 'Start Date' }}*</label>
                                            <input type="date" max="9999-12-31" formControlName="startDate" class="form-control" id="start-date" name="date"
                                            format="mm-dd-yyyy" (change)="startDate($event); leaveCalc()"/>
                                            <p class="error-msg">
                                               <span
                                                    *ngIf="weekoffForm.controls['startDate'].invalid && weekoffForm.controls['startDate'].touched || startDayError">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{'SELECT_VALID_DATE' | customTranslate : 'Select valid date' }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="field-input"
                                        [class]="weekoffForm.controls['endDate'].invalid && weekoffForm.controls['endDate'].touched || leaveError ? 'has-error' : '' ">
                                       
                                            <label class="form-label label-txt modal-field-label" for="end-date">{{ 'END_DATE' | customTranslate : 'End Date' }}*</label>
                                            <input type="date" max="9999-12-31" formControlName="endDate" class="form-control" id="end-date" name="date"
                                            format="mm-dd-yyyy" (change)="endDate($event); leaveCalc()" />
                                            <p class="error-msg"><span
                                                    *ngIf="weekoffForm.controls['endDate'].invalid && weekoffForm.controls['endDate'].touched || leaveError"> 
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon> {{ (leaveError ? ('VALIDATION.SUFF_LEAVE'| customTranslate : 'Leave is not sufficient') : ('SELECT_VALID_DATE'| customTranslate : 'Select valid date') )}}
                                                </span>
                                            </p>
                                        </div>
                                        <label for="'weekOffLabel'" class="form-label label-txt modal-field-label">{{ 'WEEK_OFF' | customTranslate : 'Week Off' }}</label>
                                        <div class="col-md-4 flex flex-row" *ngFor="let days of Data; let i = index">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                            (change)="$event ? separateRowSelect(selection.toggle(days)) : null"
                                            [checked]="selection.isSelected(days)">{{days.name}}
                                        </mat-checkbox>
                                        </div>
                                        <div class="row pt-4 pb-4 padding-20px">
                                            <div class="form-check col-md-4">
                                                <input class="form-check-input" name="checkbox-1"
                                                    formControlName="Default" type="checkbox"
                                                    id="defaultWeekoff" value="Default">
                                                <label class="form-label label-txt modal-field-label" for="defaultWeekoff">
                                                    {{ 'DEFAULT' | customTranslate : 'Default' }}
                                                </label>
                                            </div>
                                            <div class="form-check col-md-4">
                                                <input class="form-check-input" name="checkbox-2"
                                                    formControlName="active" type="checkbox"
                                                    id="activeWeekoff">
                                                <label class="form-label label-txt modal-field-label" for="activeWeekoff">
                                                    {{ 'WORK_BASICINFO.ACTIVE' | customTranslate : 'Active' }}
                                                </label>
                                            </div>
                                            <!-- Default switch -->
                                            <div class="form-check form-switch col-md-4">
                                                <input class="form-check-input" type="checkbox" formControlName="paidLeave" role="switch" id="paidLeaveWeekoff">
                                                <label class="form-label label-txt modal-field-label" for="paidLeaveWeekoff">{{ 'PAID_LEAVE' | customTranslate : 'Paid Leave' }}</label>
                                            </div>
                                        </div>
                                        <div class="row input-margin justify-content-end">
                                            <div class="row-md-4 text-end input-margin-top">
                                                <app-fusion-button [buttonValue]=" 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel'"
                                                [buttonType]="'text-only card-btn hide-detail-view'" (buttonClickEmit)="resetForm()"
                                                data-bs-dismiss="modal"></app-fusion-button>
                                                <app-fusion-button buttonValue=" {{'BUTTON_VALUE.SAVE' | customTranslate : 'Save'}} "
                                                [buttonDisable]="!weekoffForm.valid || startDayError || leaveError" 
                                                (buttonClickEmit)="saveWeekoff()" data-bs-dismiss="modal"></app-fusion-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>