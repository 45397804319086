
<div class="form-card">
    <div class="col-md-12 mt-3">
        <div class="static-width-for-card width-100">
            <app-cards #hideAddEducation [inlineDetailed]="true">
                <div class="header-section flex content-space-between">
                    <span>{{ 'QUALIFICATION_INFO.QUALIFICATIONINFO' | customTranslate : 'Qualification Info'  }}</span>
                </div>

                <div class="body-section card-text">
                    <div class="card-btn show-detail-view text-primary"(click)="addEducation()" *ngIf="!isEditButtonOn">
                     {{ 'QUALIFICATION_INFO.QUALIFICATIONADD' | customTranslate : '+ ADD Qualification' }}
                    </div>
                </div>

                <div class="card-expanded-section detailed-section" plain>
                    <div class="card-header">{{ 'QUALIFICATION_INFO.DETAILS' | customTranslate : 'Add Qualification Details' }}</div>
                    <div class="card-body">
                        <form [formGroup]="educationInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-4" [class]=" this.educationInfoForm.controls['empDegree'].invalid && this.educationInfoForm.controls['empDegree'].touched  ? 'has-error' : '' ">
                                        <!-- <input type="text" tabindex="1" formControlName="empDegree"
                                                            id="degree" placeholder="Ex: Bachelor's"> -->
                                        <!--RG26AUG24 changed the label UI -->
                                        <label for="degree" class="form-label label-txt modal-field-label">{{ 'QUALIFICATION_INFO.DEGREE' | customTranslate : 'Degree' }}*</label>
                                        <select id="degree" formControlName="empDegree"
                                            placeholder="Ex. Bachelor's" class="form-select">
                                            <option *ngFor="let degree of degreeDropDown.valueList" selected [value]="degree.key">{{degree.value}}</option>
                                        </select>
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['empDegree'].invalid && this.educationInfoForm.controls['empDegree'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                        </div>

                                    <div class="field-input col-md-4" [class]=" educationInfoForm.controls['empSpecialization'].invalid && educationInfoForm.controls['empSpecialization'].touched ? 'has-error' : '' ">
                                        <label for="specilation" class="form-label label-txt modal-field-label">{{ 'QUALIFICATION_INFO.SPECIALIZATION' | customTranslate : 'Specialization' }}*</label>
                                        <input type="text" tabindex="2" formControlName="empSpecialization"
                                                            id="specilation" placeholder="Ex: Accounting,Business">
                                        <!-- <select id="specilation" formControlName="empSpecialization"
                                            placeholder="Ex. Accounting,Business" class="form-select">
                                            <option selected value=""></option>
                                            <option>CS</option>
                                            <option>IT</option>
                                            <option>Civil</option>
                                        </select> -->
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['empSpecialization'].invalid && this.educationInfoForm.controls['empSpecialization'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-4" [class]=" educationInfoForm.controls['empUniversity'].invalid && educationInfoForm.controls['empUniversity'].touched ? 'has-error' : '' ">
                                        <label for="university" class="form-label label-txt modal-field-label">{{ 'QUALIFICATION_INFO.UNIVERSITY' | customTranslate : 'University/Institute' }}*</label>
                                        <input type="text" tabindex="3" formControlName="empUniversity"
                                                            id="university" placeholder="Ex: Anna University">
                                        <!-- <select id="university" formControlName="empUniversity"
                                            placeholder="Ex. AnnaUniversity" class="form-select">
                                            <option selected value=""></option>
                                            <option>Anna University</option>
                                            <option>Madras University</option>
                                            <option>NIT</option>
                                        </select> -->
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['empUniversity'].invalid && this.educationInfoForm.controls['empUniversity'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="col-md-6" style="width: 48%;" >
                                        <label class="form-label label-txt modal-field-label"
                                                    for="flexRadioDefault">{{ 'QUALIFICATION_INFO.EDU_TYPE' | customTranslate : 'Education Type' }}</label>
                                        <div class="flex flex-direction-row">
                                            <div class="mb-3 ms-4" *ngFor="let edutype of educType">
                                                <label>
                                                    <input type="radio" name="eduType"
                                                        formControlName="eduType" [value]="edutype"/>
                                                        {{ 'EDUCTYPES.'+ edutype | customTranslate : edutype  }} 
                                                </label>
                                                
                                            </div>
                                        </div>
                                        <p class="error-msg"><span
                                            *ngIf="educationInfoForm.controls['eduType'].invalid && educationInfoForm.controls['eduType'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6" [class]=" educationInfoForm.controls['dateOfStart'].invalid && educationInfoForm.controls['dateOfStart'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="date-of-start">{{ 'QUALIFICATION_INFO.DOS' | customTranslate : 'Date Of Start' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control"
                                            formControlName="dateOfStart" id="date-of-start" name="date"
                                            format="mm-dd-yyyy" />
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['dateOfStart'].invalid && this.educationInfoForm.controls['dateOfStart'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6" [class]=" educationInfoForm.controls['dateOfEnd'].invalid && educationInfoForm.controls['dateOfEnd'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="date-of-end">{{ 'QUALIFICATION_INFO.DOE' | customTranslate : 'Date Of End' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfEnd"
                                            id="date-of-end" name="date" format="mm-dd-yyyy" />
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['dateOfEnd'].invalid && this.educationInfoForm.controls['dateOfEnd'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6" [class]=" educationInfoForm.controls['overallPercent'].invalid && educationInfoForm.controls['overallPercent'].touched ? 'has-error' : '' ">
                                        <label for="overall-percent" class="form-label label-txt modal-field-label">
                                            {{'QUALIFICATION_INFO.PERCENTAGE' | customTranslate : 'Over All Percentage'  }}*</label>
                                        <input type="number" formControlName="overallPercent"
                                            placeholder="Ex. 96.56" class="form-control"
                                            id="overall-percent">
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['overallPercent'].invalid && this.educationInfoForm.controls['overallPercent'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer border-0">
                        <div class="center-button text-right">
                            <div>{{'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button"  }}</div>
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'"
                                [dataDismiss]="''" (buttonClickEmit)="eduCancelButton()"
                                [fcBtnId]="'myId'" data-bs-dismiss=""></app-fusion-button>
                                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                                [buttonType]="'input-button'"
                                [dataDismiss]="''" [buttonDisable]="!educationInfoForm.valid"
                                [fcBtnId]="'myId'" data-bs-dismiss="" (buttonClickEmit)="saveEducationInfo(); hideAddEducation.hidePlainView()"></app-fusion-button>
                        </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>

     <div class="col-md-12 mt-1" *ngFor="let user of eduGetData; let i=index"> 
        <div class="static-width-for-card width-100">
            <app-cards #hideAll [inlineDetailed]="true" [modalDeleted]="true" [modalId]="'educationCardModal'">
                <div class="header-section flex content-space-between">
                    <span>{{'HEADINGS.QUALIFICATION' | customTranslate : 'Qualification'  }} : {{user.degree}}</span>
                    <div class="header-section flex content-space-between">
                        <div class="card-btn show-detail-view text-primary" *ngIf="!isEditButtonOn">
                            <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                                class="card-icon" (click)="eduOnEdit(user);" style="cursor: pointer;"></svg-icon>
                              </div>
                        <div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#educationCardModal">
                            <svg-icon src="../assets/images/icons/general/delete.svg"
                                class="card-icon ms-3" (click)="eduOnDelete(user)" style="cursor: pointer;"></svg-icon>
                        </div>     
                    </div>
                </div>
                 <div class="card-expanded-section modal-content modal-section" modalDelete> 
                    <div class="modal-header">
                        <div class="modal-title fs-6" id="cardModalLabel">{{'QUALIFICATION_INFO.DELETE' | customTranslate : 'Delete Qualification'  }}</div>
                        <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                        [fcBtnId]="'close'">
                        </app-fusion-button>
                    </div>

                    <div class="modal-body">
                        <p>{{'QUALIFICATION_INFO.DELETEINFO' | customTranslate : 'Are you sure you want to delete your'  }} {{deleteEduInfo?.degree}}?</p>
                    </div>
                    <div class="modal-footer">
                        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL_THANKS' | customTranslate : 'No Thanks' }}"
                         [buttonType]="'text-only'" [dataDismiss]="'modal'" (buttonClickEmit)="eduCancelButton()"
                         [fcBtnId]="'myId'" data-bs-dismiss="modal"></app-fusion-button>
                         <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.DELETE' | customTranslate : 'Delete' }}"
                        [fcBtnId]="'modalSave'" (buttonClickEmit)="deleteEdu()" [dataDismiss]="'modal'"></app-fusion-button>
                    </div>
                 </div> 

                <div class="body-section card-text">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-start width-100">
                            <svg-icon src="../assets/images/icons/general/logo.svg"
                                    class="card-icon ms-3 align-self-center"></svg-icon> 
                            <div class="d-flex flex-column ms-5 width-25">
                                <label class="label-head">{{user.university}}</label>
                                <label class="label-sub">{{user.degree}}</label>
                                <label class="label-sub">{{user.specialization}}</label>
                                <label class="label-sub">{{user.type}}</label>
                                <label class="label-sub">{{user.startDate | date:'mediumDate'}} - {{user.endDate | date:'mediumDate'}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-expanded-section detailed-section" plain id="#experienceData">
                    <div class="card-header">
                        <div class="modal-title fs-6" id="cardDetailedModalLabel">{{'QUALIFICATION_INFO.EDIT' | customTranslate : 'Edit Qualification Details' }}</div>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="educationInfoForm">
                            <div class="field-sets">
                                <div class="field-set-row flex">
                                    <div class="field-input col-md-4" [class]=" educationInfoForm.controls['empDegree'].invalid && educationInfoForm.controls['empDegree'].touched ? 'has-error' : '' ">
                                        <!-- <input type="text" tabindex="1" formControlName="empDegree"
                                                            id="degree" placeholder="Ex: Bachelor's"> -->
                                        <label for="degree" class="form-label label-txt modal-field-label">{{ 'QUALIFICATION_INFO.DEGREE' | customTranslate : 'Degree' }}*</label>
                                        <select id="degree" formControlName="empDegree"
                                            placeholder="Ex. Bachelor's" class="form-select" [value]="user.Degree">
                                            <option *ngFor="let degree of degreeDropDown.valueList" selected [value]="degree.key">{{degree.value}}</option>
                                        </select>
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['empDegree'].invalid && this.educationInfoForm.controls['empDegree'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-4" [class]=" educationInfoForm.controls['empSpecialization'].invalid && educationInfoForm.controls['empSpecialization'].touched ? 'has-error' : '' ">
                                        <label for="specilation" class="form-label label-txt modal-field-label">{{ 'QUALIFICATION_INFO.SPECIALIZATION' | customTranslate : 'Specialization' }}*</label>
                                        <input type="text" tabindex="2" formControlName="empSpecialization"
                                                            id="specilation" placeholder="Ex: Accounting,Business">
                                        <!-- <select id="specilation" formControlName="empSpecialization"
                                            placeholder="Ex. Accounting,Business" class="form-select" [value]="user.Specialization">
                                            <option selected value=""></option>
                                            <option>CS</option>
                                            <option>IT</option>
                                            <option>Civil</option>
                                        </select> -->
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['empSpecialization'].invalid && this.educationInfoForm.controls['empSpecialization'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-4" [class]=" educationInfoForm.controls['empUniversity'].invalid && educationInfoForm.controls['empUniversity'].touched ? 'has-error' : '' ">
                                        <label for="university" class="form-label label-txt modal-field-label">{{ 'QUALIFICATION_INFO.UNIVERSITY' | customTranslate : 'University/Institute' }}*</label>
                                        <input type="text" tabindex="3" formControlName="empUniversity"
                                                            id="university" placeholder="Ex: Anna University">
                                        <!-- <select id="university" formControlName="empUniversity"
                                            placeholder="Ex. AnnaUniversity" class="form-select" [value]="user.University">
                                            <option selected value=""></option>
                                            <option>Anna University</option>
                                            <option>Madras University</option>
                                            <option>NIT</option>
                                        </select> -->
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['empUniversity'].invalid && this.educationInfoForm.controls['empUniversity'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="col-md-6" style="width: 48%;" >
                                        <label class="form-label label-txt modal-field-label"
                                                    for="flexRadioDefault">{{'QUALIFICATION_INFO.TYPE' | customTranslate : 'Education Type*' }}*</label>
                                        <div class="flex flex-direction-row">
                                            <div class="mb-3 ms-4" *ngFor="let edutype of educType">
                                                <label>
                                                    <input type="radio" name="eduType"
                                                        formControlName="eduType" [value]="edutype"/>
                                                        {{'EDUCTYPES.'+ edutype | customTranslate : edutype}}
                                                </label>
                                            </div>
                                        </div>
                                        <p class="error-msg"><span
                                            *ngIf="educationInfoForm.controls['eduType'].invalid && educationInfoForm.controls['eduType'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6" [class]=" educationInfoForm.controls['dateOfStart'].invalid && educationInfoForm.controls['dateOfStart'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="date-of-start">{{ 'QUALIFICATION_INFO.DOS' | customTranslate : 'Date Of Start' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control"
                                            formControlName="dateOfStart" id="date-of-start" name="date"
                                            format="dd-mm-yyyy"[value]="user.StartDate" />
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['dateOfStart'].invalid && this.educationInfoForm.controls['dateOfStart'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6" [class]=" educationInfoForm.controls['dateOfEnd'].invalid && educationInfoForm.controls['dateOfEnd'].touched ? 'has-error' : '' ">
                                        <label class="form-label label-txt modal-field-label" for="date-of-end">{{ 'QUALIFICATION_INFO.DOE' | customTranslate : 'Date Of End' }}*</label>
                                        <input type="date" max="9999-12-31" class="form-control" formControlName="dateOfEnd"
                                            id="date-of-end" name="date" format="dd-mm-yyyy"/>
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['dateOfEnd'].invalid && this.educationInfoForm.controls['dateOfEnd'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>

                                    <div class="field-input col-md-6" [class]=" educationInfoForm.controls['overallPercent'].invalid && educationInfoForm.controls['overallPercent'].touched ? 'has-error' : '' ">
                                        <label for="overall-percent" class="form-label label-txt modal-field-label">
                                            {{ 'QUALIFICATION_INFO.PERCENTAGE' | customTranslate : 'Over All Percentage' }}*</label>
                                        <input type="number" formControlName="overallPercent"
                                            placeholder="Ex. 96.56" class="form-control"
                                            id="overall-percent">
                                        <p class="error-msg"><span
                                            *ngIf="this.educationInfoForm.controls['overallPercent'].invalid && this.educationInfoForm.controls['overallPercent'].touched">
                                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="card-footer border-0">
                        <div class="center-button text-right">
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                                [buttonType]="'text-only card-btn hide-detail-view'"
                                [dataDismiss]="''"
                                [fcBtnId]="'experienceData'" (buttonClickEmit)="eduCancelButton()"></app-fusion-button>
                                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                                [buttonType]="'input-button'"
                                [dataDismiss]="''" [buttonDisable]="!educationInfoForm.valid"
                                [fcBtnId]="''" (buttonClickEmit)="saveEducationInfo(); hideAll.hidePlainView()"></app-fusion-button>
                        </div>
                    </div>
                </div>
            </app-cards>
        </div>
    </div>
</div>
  
