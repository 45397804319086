import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { HrmComponent } from './pages/hrm/hrm.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { EssComponent } from './pages/ess/ess.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { FusionFrameworkComponent } from './pages/fusion-framework/fusion-framework.component';
import { AuthGuard } from './services/auth.guard';
import { ParkAppComponent } from './pages/park-app/park-app.component';
import { ModulesComponent } from './components/modules/modules.component';
import { HireRegistrationComponent } from './pages/hire-registration/hire-registration.component'; //SK31JUL24

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'hire/:companyId/register', component: HireRegistrationComponent}, //SK31JUL24 screen for Hire registration
  // SK24JUN24 implementing language code in url
  { path: 'hrm/:languageCode', component: HrmComponent,data:{roles:['Super User', 'HRM User', 'HRM Manager']},canActivate: [AuthGuard]},
  { path: 'settings/:languageCode', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: ['Super User']}},
  { path: 'ess/:languageCode', component: EssComponent,canActivate: [AuthGuard], data:{ roles:['ESS User', 'Super User', 'HRM User', 'HRM Manager']}},
  // { path: 'app/:appName/:moduleName/:tabName', 
  //   component: ParkAppComponent,canActivate: [AuthGuard], data:{ roles:['App User']}
  // },
  { path: 'app/:languageCode/:appName/:moduleName/:tabName', 
    component: ParkAppComponent,canActivate: [AuthGuard], data:{ roles:['App User', 'Super User']} //SK 20DEC23 given permission for custom apps for Super User
  },
  // { path: 'app/:appName', component: ParkAppComponent,canActivate: [AuthGuard], data:{ roles:['App User']}},
  { path: 'resetpassword', component: ResetPasswordComponent},
  { path: 'fusion-components', component: FusionFrameworkComponent},
  { path: '',   redirectTo: '/login', pathMatch: 'full' }, // redirect to `login` page by default
  { path: '**', component: PageNotFoundComponent}// Wildcard route for a 404 page, path:'**' should be the last item always
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
