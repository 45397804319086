import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SideNavEmitService } from '../../../services/side-nav-emit.service';
import { ApiService } from '../../../services/api.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { EssComponent } from '../ess.component';
import { Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-educationtab',
  standalone: true,
  imports: [CommonModule, SuccessFailureMessageComponent, CardsComponent, PopoverComponent, FusionButtonComponent, AngularSvgIconModule, FormsModule, ReactiveFormsModule, EssComponent,TranslateModule, CustomTranslatePipe],
  templateUrl: './educationtab.component.html',
  styleUrls: ['./educationtab.component.css']
})
export class EducationtabComponent implements OnInit {
  @Input() isHamClicked: boolean = false;

  hideOrShowNav: string = "side-nav-container";

  educationInfoForm!: FormGroup;
  isEditButtonOn = false;
  errorMessage = '';
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  educType = ['Full-Time', 'Part-Time', 'Distance']
  eduID: any;
  eduGetData: any;
  deleteEduInfo: any;
  employeeId: any;
  isDevMode: boolean = false;
  @Input() selectedUser: any;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  companyId = this.globalValues.orgId;
  degreeDropDown:any

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService,private globalValues: GlobalValuesService,private language : TranslateService) {
    this.educationInfoForm = this.formBuilder.group({
      'empDegree': ['', Validators.required],
      'empSpecialization': ['', Validators.required],
      'empUniversity': ['', Validators.required],
      'eduType': ['', Validators.required],
      'dateOfStart': ['', Validators.required],
      'dateOfEnd': ['', Validators.required],
      'overallPercent': ['', Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
      'eduID': ['']
    });
    this.isDevMode = isDevMode();
  }

  /** This is Add button function used to create a new data in that form group */
  addEducation(): void {
    this.educationInfoForm.reset();
    this.isEditButtonOn = true;
  }

  ngOnInit(): void {
    // SK01APR24
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();

    if (this.isHamClicked) {
      this.hideOrShowNav = "hideOrShowNav hide"
    }

    // SK27FEB24 getting degree dropdowns from local
    // SK06MAR24 settings data as whole stored in local and getting dropdowns from it
    const dropDowns = JSON.parse((localStorage.getItem('settingsData'))as any);
    this.degreeDropDown = dropDowns?.dropdowns?.filter((bg:any)=>bg.name === 'degree')[0]
    || {valueList:[
      {key:"bachelors", value:"Bachelor's"},
      {key:"masters", value:"Master's"},
      {key:"diploma", value:"Diploma"}
    ]};
  }

  ngOnChanges() {
    // SK01APR24
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();
  }

  saveMsg() {
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch', this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }

  deleteMsg() {
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.alertService.messageOnFetch('successFetch', this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }

  errorMsg(error: HttpErrorResponse) {
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  saveEducationInfo() {
    this.isEditButtonOn = false;
    if(this.isDevMode) console.log("Submit education Info");
    let value = this.educationInfoForm.value;
    if (value.eduID != null) {
      return this.apiService.writeValue('patch', '/employee/education', {'degree': value.empDegree, 'specialization': value.empSpecialization, 'university': value.empUniversity, 'type': value.eduType, 'startDate': value.dateOfStart, 'endDate': value.dateOfEnd, 'percentage': value.overallPercent, 'eduId': value.eduID})
      .subscribe({
        next: (eduInfo: any) => {
          if(this.isDevMode) console.log("Next :", eduInfo);
          this.saveMsg();

        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    } else {
      return this.apiService.writeValue('post', '/employee/education', {'degree': value.empDegree, 'specialization': value.empSpecialization, 'university': value.empUniversity, 'type': value.eduType, 'startDate': value.dateOfStart, 'endDate': value.dateOfEnd, 'percentage': value.overallPercent, 'employeeId': this.employeeId})
      .subscribe({
        next: (eduInfo) => {
          if(this.isDevMode) console.log("Next :", eduInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    }
  }

   /** These are the GET method functions that returns the data from database */
  getESSDatas(){
    // SK01APR24
    this.selectedUser = JSON.parse(localStorage.getItem('userData') as any); 
    this.getData();
  }

  getData(){
    this.eduGetData = this.selectedUser?.education;
  }

  /** These functions implemets the cancel button in the forms */
  eduCancelButton() {
    this.isEditButtonOn = false;
    this.educationInfoForm.reset();
  }

  /** These functions used to edit the form data that returned from database */
  eduOnEdit(userData: any) {
    this.isEditButtonOn = true;
    this.educationInfoForm.setValue({
      'empDegree': userData.degree,
      'empSpecialization': userData.specialization,
      'empUniversity': userData.university,
      'eduType': userData.type,
      'dateOfStart': userData.startDate,
      'dateOfEnd': userData.endDate,
      'overallPercent': userData.percentage,
      'eduID': userData.eduId
    });
  }

  eduOnDelete(deletedEdu: any) {
    this.deleteEduInfo = deletedEdu;
  }

  /** This is a delete button function. This removes the array data from the forms */
  deleteEdu() {
    this.apiService.writeValue('delete','/employee/education',{body: {'eduId': this.deleteEduInfo.eduId, 'employeeId': this.employeeId}})
    .subscribe({
      next: () => {
        this.deleteMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      }
    });
  }
}





