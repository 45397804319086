import { Directive, Input, HostBinding } from '@angular/core';

@Directive({
  selector: '[appAttendanceColor]',
  standalone: true
})
export class AttendanceColorDirective {

  // If possible try to make this as a pipe instead of directive
  // Pipe helps to avoid multiple execution of same function when ever angular emits an event or rerenders


  private previousContent: string | number = '';  //To avoid the function getting executed multiple times
  private previousDefaultHours: number = 0;  //To avoid the function getting executed multiple times
  private previousClassName: string = '';  //To avoid the function getting executed multiple times


  constructor() { }
  @Input() content: string = '';
  @Input() defaultHours: number = 0;
  private initialized:boolean = false;

  @HostBinding('class') // Since we want to bind a class we inform that the class name should have the returned value of the get function.

  get className(): string {

    //To avoid the function getting executed multiple times
    if (this.content === this.previousContent && this.defaultHours === this.previousDefaultHours) {
      // Return cached result if input properties have not changed
      return this.previousClassName;
    }

    
    let returnAttendanceClassName = '';
    console.log("Execution-Count");
    if (typeof this.content === 'string') {
      if (/^[a-zA-Z]+$/.test(this.content.trim().replace(/\s/g, "").replace(/-/g, "").replace(/_/g, ""))) {
        // we used the replace function to remove space, hyphens, underscore from the string incase if any present
        // Check if the content contains a given string
        if(['yes','y','present','a'].includes(this.content.toLocaleLowerCase())) returnAttendanceClassName = 'attendance-present'; 
        if(['no','n','absent','a'].includes(this.content.toLocaleLowerCase())) returnAttendanceClassName = 'attendance-absent'; 
        if(['holiday','h'].includes(this.content.toLocaleLowerCase())) returnAttendanceClassName = 'attendance-holiday'; 
        if(['off','weekoff'].includes(this.content.toLocaleLowerCase())) returnAttendanceClassName = 'attendance-weekoff'; 
        if(['half'].includes(this.content.toLocaleLowerCase())) returnAttendanceClassName = 'attendance-half'; 
      }
      if (/^[0-9.]+$/.test(this.content.trim()) && this.defaultHours && this.defaultHours > 0){
        if(parseFloat(this.content.trim()) >= this.defaultHours) returnAttendanceClassName = 'attendance-present'; 
        if(parseFloat(this.content.trim()) < (this.defaultHours / 2)) returnAttendanceClassName = 'attendance-absent'; 
        // In case of holiday, weekoff it has to be either 0, or 8 or some calculation to be added based on the holiday date, week off falling date etc....
        if((parseFloat(this.content.trim()) > (this.defaultHours / 2)) && (parseFloat(this.content.trim()) < this.defaultHours)) returnAttendanceClassName = 'attendance-half'; 
      }
    } else if (typeof this.content === 'number') {
      // Check if the content is greater than 8 
    } else {
      // Invalid content type
      returnAttendanceClassName = '';
    }
    return returnAttendanceClassName;
  }
}
