import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { Subscription } from 'rxjs';
import { FusionFrameworkService } from 'src/app/services/fusion-framework.service';
import { ModulesComponent } from 'src/app/components/modules/modules.component';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { NxtAppModule } from '@rangertechnologies/ngnxt';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { SideNavEmitService } from 'src/app/services/side-nav-emit.service';

@Component({
  selector: 'app-park-app',
  standalone: true,
  imports: [CommonModule, PageHeaderComponent, ModulesComponent, TabsComponent, DataTableComponent, FusionButtonComponent, ModalComponent, NxtAppModule, DashboardComponent, AngularSvgIconModule, DropDownComponent],
  templateUrl: './park-app.component.html',
  styleUrls: ['./park-app.component.css']
})
export class ParkAppComponent implements OnInit {
  pageComponentName = 'Parking';
  jsonSubscription: Subscription;
  configJSON: Array<any> = [];
  tabData =  'dashboard';
  employeeEntriesData : Array<any> = [];
  entriesData = false;
  employeeDetails : any;
  appName: any;
  moduleName:any;
  selectedModule: any;
  selectedTab: any;
  showSideNavInMobile: boolean = false;;
  hamBurgerClickSubscribe:Subscription
  languageCode:any; //SKS11OCT24

  constructor(private fusionFrameWork:FusionFrameworkService, private globalValues: GlobalValuesService, private apiService: ApiService, private alertService: AlertService, private errorHandler: ErrorHandlerService, private datePipe: DatePipe, private route: ActivatedRoute, private router: Router, private sideNavItem:SideNavEmitService) {
    console.log('inside park-app constructor');
    console.log(this.configJSON);
    this.jsonSubscription = this.fusionFrameWork.appJSONData$.subscribe((apps:any) => {
      console.log('inside park-app constructor after appJSONData ');
      this.configJSON = apps;
      this.loadApps();
    });

    // SK14MAY24 on clicking hamburger, based on flag module is shown
    this.hamBurgerClickSubscribe = this.sideNavItem.isHamburgerClicked$.subscribe(navItem =>  { 
      if(navItem == 'hamburger') {
        this.showSideNavInMobile = !this.showSideNavInMobile;
      }
    });
  }

  ngOnInit(): void {
    //SK 23JAN24 removed comment to fetch data on load
    console.log('inside park-app ngOnInit');
    const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24
    this.languageCode = userData?.languageCode || "en";
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      this.configJSON = JSON.parse(userDataConst).apps;
      this.loadApps();
    } else {
      console.log('waiting for the api response');
    }
    
  }

  loadApps() {
    console.log('inside loadApps()');
    this.route.paramMap.subscribe(params => {
      this.appName = params.get('appName');
      if(this.appName) {
        // Fetch Modules from the App List
        let appFiltered = this.configJSON?.filter((appFilter:any) => appFilter.name === this.appName);
        if(appFiltered[0].colourCode) document.documentElement.style.setProperty('--fusion-color', appFiltered[0].colourCode);
        this.moduleName = params.get('moduleName');
        if(appFiltered.length > 0) {
          if(this.moduleName == null || this.moduleName == undefined) {
            console.log('getting the first module on the ' + appFiltered[0].name + ' app');
            const module = appFiltered[0].modules[0];
            this.moduleName = module.name;
            // SK24JUN24
            this.router.navigateByUrl('/app/'+ this.languageCode || "en" + "/" + this.appName + '/' + module?.name, module?.tabs[0]?.name);//RS27AUG24
            this.emitModuleClick(module);
          } else {
            console.log('getting the module ' + this.moduleName + ' on the url');
            const module = appFiltered[0].modules?.filter((modFilter:any) => modFilter.name === this.moduleName);
            if(module.length > 0) {
              this.emitModuleClick(module[0]);
            } else {
              // Route to 404 - Page Not Found
            }
          }
        } else {
          // Route to 404 - Page Not Found
        }
      } else {
        // Route to 404 - Page Not Found
      }
    });
    this.ngOnDestroy();
  }

  emitModuleClick(event:any) {
    console.log('handling module ' + JSON.stringify(event));
    this.selectedModule = event;
    this.fusionFrameWork.getModuleData(event);
    /*
    this.route.paramMap.subscribe(params => {
      this.appName = params.get('appName');
      this.moduleName = params.get('moduleName');
      if(this.moduleName == undefined) this.moduleName = event?.name;
      this.fusionFrameWork.getModuleData(event);
      // this.ngOnDestroy();
    });
    */
  }

  tabContentEmit(event:any){
    console.log('inside tabContentEmit on Park-App');
    // this.tabData = event;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.jsonSubscription.unsubscribe();
  }
}
