import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, isDevMode, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { PopoverComponent } from '../popover/popover.component';
import { Constants } from 'src/app/models/Constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { GetValuePipe } from 'src/app/pipes/get-value.pipe';
import { EditColumnCheckPipe } from 'src/app/pipes/data-table/edit-column-check.pipe';
import { EditColumnTypePipe } from 'src/app/pipes/data-table/edit-column-type.pipe';
import { EditColumnDropdownPipe } from 'src/app/pipes/data-table/edit-column-dropdown.pipe';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TotalColumSumPipe } from 'src/app/pipes/data-table/total-colum-sum.pipe';
import { CommonScreenComponent } from '../side-screen/common-screen/common-screen.component';
import { MultiCheckboxComponent } from '../multi-checkbox/multi-checkbox.component';
import { MultipleFilterComponent } from '../multiple-filter/multiple-filter.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
import { CustomPaginatorIntl } from 'src/app/shared/custom-paginator-intl';
import { ApiService } from 'src/app/services/api.service';
import { saveAs } from 'file-saver';
import { AlertService } from 'src/app/services/alert.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { FileUploadComponent } from '../file-upload/file-upload.component';
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  imports: [
    CommonModule, 
    MatTableModule, 
    MatPaginatorModule, 
    MatSortModule, 
    MatCheckboxModule, 
    ReactiveFormsModule, 
    FormsModule, 
    SearchFilterComponent, 
    FusionButtonComponent, 
    PopoverComponent, 
    MatTooltipModule, 
    AngularSvgIconModule, 
    GetValuePipe, 
    EditColumnCheckPipe,
    EditColumnTypePipe,
    EditColumnDropdownPipe,
    TranslateModule,
    TotalColumSumPipe,
    CommonScreenComponent,
    MultiCheckboxComponent,
    MultipleFilterComponent,
    CustomTranslatePipe,
    FileUploadComponent //SKS25OCT24
  ],
  styleUrls: ['./data-table.component.css'],
  standalone: true,
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }] // Provide the custom paginator
})

export class DataTableComponent implements OnInit {
  @Input() data!: any[];  //  get data that to be displayed in a array
  @Input() displayedColumns: Array<string> = []; // get  columns values to display
  @Input() headerLabels!: string[]; // columns porper label that to be displayed in  header
  @Input() translatePath!: any;
  @Input() hyperLinkColumns: Array<string> = []; // columns porper label that to be displayed in  header
  @Input() withCheckBox: boolean = false;  // get if with or without checkbox in table
  @Input() searchBar = false; // if it is true, the search bar will be displayed on table
  @Input() editColumn: Array<any> = []; // columns that should be edited to be sent in ana array
  @Input() filterColumns: Array<any> = []; // colums in which filter action should be done is sent in this array
  @Input() tableSaveButton = false; // save button for editing LOP in payroll table, SK13MAR24 changed name from 'saveLOP'
  @Input() stickyColumn = false; // if the last column should be sticky, it should be sent as boolean value
  @Input() tableWidth = '100%'; // width of table can be adjusted from parent, by default it is 100%
  @Input() actionColumHeader = 'Action';  // to change the header for action column
  @Input() multipleFilter : any;
  @Input() actionButton:boolean = false;  //to show button colum as action
  @Input() fromWhere:string = '';  // to show the different  action buttons based on the component like If I provide 'expense' will show te expense action button only
  @Input() receiptImg:boolean = false; // to implement receipt icon in approval-expense;
  @Input () titleOn= false; // to include titles for the table;
  @Input () title:any;// the title for the table;
  @Input () isButtons:any;// is button present in table;
  @Input () buttonArray:any;// buttons to be displayed;
  @Input () tableId:any;
  @Input () isEditRow = false;
  @Input () isDeleteRow = false;
  @Input () addInlineRecord = false; //SK23FEB24 
  @Input () inlineElement:any //SK23FEB24
  @Input () editIconTooltip = 'Edit Record' //SK27FEB24
  @Input () viewIcon = false; //SK02APR24
  @Input () downloadIcon = false; //SK02APR24
  @Input () cumulativeDataColumns! : Array<any>; //SK16JUL24
  @Input() filterDataArray:any //MV24AUG24 Receives input data for Tab
  @Input() filterScreenConfigs :any
  @Input() exportFieldsListConfigs : any;
  @Input() exportFilterListConfigs : any;
  @Input() moudleName : any; //SKS25OCT24 for table name

  @Output() selectedValues = new EventEmitter<any[]>(); // to pass the selected checkbox values
  @Output() tableRowClick = new EventEmitter<any>; // datas to be passed when table row is clicked
  @Output() filteredArray = new EventEmitter<any>; // filtered data from heqder icon is sent in an array
  @Output() searchFilterData = new EventEmitter<any>; // search filter data  from search bar is sent to its parent
  @Output() onEditData = new EventEmitter<any>;
  @Output() onToggleChange = new EventEmitter<any>;
  @Output() saveButtonData = new EventEmitter<any>;
  @Output() OnDeleteData = new EventEmitter<any>;  // when delete button clicked pass the data
  @Output() onDownload = new EventEmitter<any>;  // when download button is clicked to pass the data
  @Output() approveData = new EventEmitter<{empId:any,id:any, data:any}>; //emit when  apporve the leave 
  @Output() rejectData = new EventEmitter<{empId:any,id:any}>;  // emit when reject the leave
  @Output() expenseAttachmentEmit = new EventEmitter<any>;
  @Output() commonButtonClickEmit = new EventEmitter<any>;
  @Output() hyperLinkColumnEmit = new EventEmitter<any>;
  @Output() viewIconEmit = new EventEmitter<any>;
  @Output() downloadIconEmit = new EventEmitter<any>;
  @Output() importButton = new EventEmitter<any>;
  @Output() exportButton = new EventEmitter<any>;
  @Output() sideNavButtons = new EventEmitter<any>;
  @Output() exportReports = new EventEmitter<any>;
  @Output() multipleSelectFilterExport = new EventEmitter<any>;
  @Output() filterDateEmitEvt : EventEmitter<any> = new EventEmitter<any>();

  
  @ViewChild(MatPaginator) paginator!: MatPaginator; //get references to the MatPaginator and MatSort components
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('editInput') editInput!: ElementRef
  @ViewChild('tablePaginator') tablePaginator! : ElementRef;
  @ViewChildren('editColElement', { read: ElementRef }) editColElement!: QueryList<any>;

  selection: any;
  stickyCondition!: number;
  searchFilter = false;
  filterArray: Array<any> = [];
  selectedFilter: any;
  filterCheckBox: any;
  selectedFilterColumn: any;
  selectedFilterKey: Array<any> = [];
  renderColumns: string = 'displayedColumns';
  isDevMode: boolean = false;
  itemsPerPage = Constants.PAGE_SIZE_OPTIONS;
  dataSource!: MatTableDataSource<any>;  // Declare a MatTableDataSource property for the table data.
  tableServiceData: Subscription; //SK23FEB24 to detech table data changes
  exportSidescreen:boolean = false;
  headerTranslateLabels: any;
  exportReportsData: any; //SKS25OCT24
  uploadModal: boolean = false
  filesArrayComp:any;
  employeeBulkFile: any;
  templateDownlaod = Constants.DOWNLOAD_TEMPLATE;
  errorMessage = '';
  loadingSpinner = false;
  importButtonData : any

  constructor(private globalValues:GlobalValuesService, private apiService: ApiService, private alertService: AlertService, private errorHandler: ErrorHandlerService, private translate : TranslateService) { 
    // SK23FEB24 if the data changes then, subscribe retrieves new data
    this.tableServiceData = this.globalValues.tableGetData$.subscribe((data:any)=>{
      this.data = data.config.data;
      this.dataSource = new MatTableDataSource(this.data);
      this.selection = new SelectionModel<any>(true, []);
      this.filterCheckBox = new SelectionModel<any>(true, []);
      if(this.isDevMode) console.log('data table data', this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      if (this.stickyColumn === true && this.displayedColumns) {
        this.stickyCondition = this.displayedColumns?.length;
      } else {
        this.stickyCondition = this.displayedColumns?.length + 1;
      }

      // SK13MAR24
      if(this.withCheckBox && this.actionButton){
        this.renderColumns = 'both';
      } else if(this.withCheckBox){
        this.renderColumns = 'select';
      } else if(this.actionButton){
        this.renderColumns = 'action';
      }
    })
  }

  ngOnChanges(){
    this.dataSource = new MatTableDataSource(this.data);
    this.selection = new SelectionModel<any>(true, []);
    this.filterCheckBox = new SelectionModel<any>(true, []);
    if(this.isDevMode) console.log('data table data', this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    if (this.stickyColumn === true && this.displayedColumns) {
      this.stickyCondition = this.displayedColumns?.length;
    } else {
      this.stickyCondition = this.displayedColumns?.length + 1;
    }
    //  SK13MAR24
    if(this.withCheckBox && this.actionButton){
      this.renderColumns = 'both';
    } else if(this.withCheckBox){
      this.renderColumns = 'select';
    } else if(this.actionButton){
      this.renderColumns = 'action';
    } 
    if(this.translatePath){
      this.headerTranslateLabels = this.headerLabels.map(headerLabels => {
        const headerTranslateLabels = this.translate.instant(`${this.translatePath}.${headerLabels}`);
        console.log(`${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels)
        return headerTranslateLabels === `${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels;
      });
    }else{
      this.headerTranslateLabels = this.headerLabels
    }
  }

  ngOnInit(): void {
    this.isDevMode = isDevMode();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updatePaginatorLabels(); // Call the method to update labels
    });
    // The dataSource property is set to a new MatTableDataSource instance that contains table  data
    // Create a new selection model that allows multiple items to be selected
    this.dataSource = new MatTableDataSource(this.data);
    this.selection = new SelectionModel<any>(true, []);
    this.filterCheckBox = new SelectionModel<any>(true, []);
    if(this.isDevMode) console.log('data table data', this.data);

    // this condition is checked to style the sticky cloumn properties
    if (this.stickyColumn === true && this.displayedColumns) {
      this.stickyCondition = this.displayedColumns?.length;
    } else {
      this.stickyCondition = this.displayedColumns?.length + 1;
    }

     //to render the action and check box columns;
    //  30JAN24 for both checkbox and action columns
    if(this.withCheckBox && this.actionButton){
      this.renderColumns = 'both';
    } else if(this.withCheckBox){
      this.renderColumns = 'select';
    } else if(this.actionButton){
      this.renderColumns = 'action';
    } 
    if(this.translatePath){
      this.headerTranslateLabels = this.headerLabels.map(headerLabels => {
        const headerTranslateLabels = this.translate.instant(`${this.translatePath}.${headerLabels}`);
        console.log(`${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels)
        return headerTranslateLabels === `${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels;
      });
      console.log("rrrrrrrrrr",this.headerTranslateLabels)
    }else{
      this.headerTranslateLabels = this.headerLabels
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(this.translatePath){
        this.headerTranslateLabels = this.headerLabels.map(headerLabels => {
          const headerTranslateLabels = this.translate.instant(`${this.translatePath}.${headerLabels}`);
          return headerTranslateLabels === `${this.translatePath}.${headerLabels}` ? headerLabels : headerTranslateLabels;
        });
      }else{
        this.headerTranslateLabels = this.headerLabels
      }
    });
  }
  updatePaginatorLabels() {
    // Manually trigger an update to the paginator
    if (this.paginator) {
      this.paginator._changePageSize(this.paginator.pageSize); // Re-initialize paginator with the current page size
      console.log("Paginator labels updated and paginator refreshed.");
    }
  }
  // closing the filter component when the mouse is clicked outside table
  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    this.searchFilter = false;
    event.stopPropagation(); // prevents any other default action
  }

  clearCheckBox(){
    this.selection.clear();
  }

  // filter data that to be displayed in header filter icon is passed in 'filterArray' array
  filter(datas: any) {
    this.filterArray = [];
    this.selectedFilter = datas;
    this.filterArray = this.multipleFilter[datas];
    // MV24AUG24 Determines the data source  and updates the active filter box with the corresponding filter data and selected box.
    this.globalValues.activeFilterBox({filterKeys:this.filterDataArray,activeBox:datas})  
  }


  ngAfterViewInit() {
    // set the paginator and sort properties of the MatTableDataSource instance to the MatPaginator and MatSort instances that you retrieved using @ViewChild.
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

   }

  isFirstCell(column: string): boolean {
    return this.displayedColumns.indexOf(column) === 0;
  }
    

  // apply search bar filter using mat
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.searchFilterData.emit(this.dataSource.filteredData)
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    // check if all rows are selected
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // if there is a selection then clear that selection
    // emit the selected value only to parent
    if (this.isSomeSelected()) {
      // SK10APR24 to remove the editRow, if the checkbox is unchecked
      this.selection.selected.forEach((element:any) => {
        if(element?.editRow) element.editRow = false;
      });
      this.selection.clear();
      this.selectedValues.emit(this.selection.selected);
    } else {
      // If no items are selected, either select all items or clear the selection
      // emit the selected values to parent
      this.isAllSelected()
        ? this.selection.clear()
        : this.dataSource.filteredData.forEach(row => {
          if(this.fromWhere === 'paySlip'){
            if(row?.isPayProcessed === true){
              this.selection.select(row);
            } 
          } else if (this.fromWhere === 'payrollTable'){
            if(row?.isPayProcessed === false){
              this.selection.select(row);
            } 
          } else {
            this.selection.select(row);
          }
          
        });
      this.selectedValues.emit(this.selection.selected);
    }
    if(this.isDevMode) console.log(' selected', this.selection.selected);
  }
 


  // Checks whether any items are currently selected in the table.
  isSomeSelected() {
    return this.selection.selected.length > 0;
  }


  // emit the check box values, that selected separately
  separateRowSelect(data: any, element:any) {
    // SK10APR24 to remove the editRow id check box is unchecked on separate row
    if(data && element?.editRow) {
      element.editRow = false;
    }
    if (data) {
      this.selectedValues.emit(this.selection.selected)
    }
  }

  // data that to be passed, when table row is clicked
  tableClick(data: any) {
    this.tableRowClick.emit(data);
  }

  // the selected filter data in header is passed as aray with that column name as first array and checked data as second array
  filteredData(filterItems: any) {
    const st = this.selectedFilterColumn.toLocaleString(); 
    const arr = [st, filterItems]
    this.filteredArray.emit(arr);
  }
  //to carryout delete functionality in child component;
  delete(data:any, index:any){
    // data.emitted = true;
    // SK27FEB24 added elements to emit ondeleting the record(made changes on wherever used)
    let orderData = this.dataSource._orderData(this.data);
    this.OnDeleteData.emit({data:data, index:index, allData:orderData});
  }
  // to emit data to document component on clicking download;
  downloadPdf(data:any){
   this.onDownload.emit(data);
  
  }


  //to save the edited data in table;
  saveButton(){
    // SK10APR24 emitting all data with selected data
    this.saveButtonData.emit({selectedData:this.selection.selected, allData:this.data});
  }

  // pass the rejected reason
  onEdit(data:any, index:any){
    // SK10APR24 for payroll condtions added on editng LOP, if payroll processed, checkbox will be disabled
    let orderData = this.dataSource._orderData(this.data);
    if (this.fromWhere === 'payrollTable'){
      if(data.payrollStatus === 'Run Payroll'){
        this.selection.select(data);
        if(typeof data === 'object') data.editRow = true;
      }
    } else {
      if(typeof data === 'object') data.editRow = true; // made editing row as true //SK06MAR24 solve undefined error on string
      this.selection.select(data);
    }
    this.onEditData.emit({data:data, index:index, allData:orderData});
  }
// on approve the leave 
  onApprove(empId:string,id:string,data:string){
    this.approveData.emit({empId,id,data});

  }
  // on reject the leave
  onReject(empId:string,id:string){
    this.rejectData.emit({empId,id});
  }

  expenseAttachment(event:any){
    this.expenseAttachmentEmit.emit(event);
  }

  commonButtonClick(buttonClickData:any){
    this.commonButtonClickEmit.emit(buttonClickData);
  }

  // SK 19DEC2023
  // This function is used to retrieve data from objects. 
  // In 'element' we will pass object and in 'column' we will pass path as string eg.employee.financeInfo[0].uan to retieve uan value 
  getValue(element:any, column:string){
    const initialEl = element; // SK21DEC23 fixed table search issue
    let flds = column.split('.');
    for(let i=0; i<flds.length; i++){
      let splitFlds = flds[i].split('[');
      if(splitFlds.length === 1){
        element = element[flds[i]] || '' as any;
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        element = element[splitFlds[0]][index]  || '' as any; //SK 20DEC23 To solve undefined error
      }
    }
    Object.assign(initialEl, { [column]: element }); // HA 11-DEC-23 Fixed Expression changed issue
    // Object.assign(initialEl, { [flds[flds.length - 1]]: element }); // SK21DEC23 fixed table search issue by assining column fields as object
    return element;
  }

  onClickHyperlink(column:any, element:any, index:number, isHyperLink:any){ 
    if(isHyperLink) this.hyperLinkColumnEmit.emit({column:column, element:element, index:index}); //SK13MAR24 hyperlinl columns only emitted
  }

  // SK19JAN24 to check whether the hyperlink action is present in that column
  checkHyperlinkCheck(col:any){
    return this.hyperLinkColumns?.includes(col) ? true: false;    
  }

  //SK23FEB24 on add record in table, the 'inlineElement' values will be assigned to the table data 
  addTableRecord(element:any){
    var obj:any = {};
    const keys = Object.keys(element[0]);
    keys.forEach((elementKey:any) => {
      obj[elementKey]='';
    });
    this.data.push(obj);
    const data:any = this.data;
    this.dataSource = new MatTableDataSource(data);
    this.selection = new SelectionModel<any>(true, []);
    this.filterCheckBox = new SelectionModel<any>(true, []);
    if(this.isDevMode) console.log('data table data', data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    if (this.stickyColumn === true && this.displayedColumns ) {
      this.stickyCondition = this.displayedColumns?.length;
    } else {
      this.stickyCondition = this.displayedColumns?.length + 1;
    }
  }

  // SK27FEB24 to edit the specifific element(alternate solution)
  doubleclickEdit(i:number, j:number, ele:any, col:any){
    const editColIndex = this.editColumn.findIndex((data:any)=>data === col);
    const ind = (this.data.length * editColIndex) + j;
    if(!this.editColElement.toArray()[ind].nativeElement.classList.contains("input-box")){
      this.editColElement.toArray()[ind].nativeElement.classList.toggle("input-box");
      this.editColElement.toArray()[ind].nativeElement.readOnly = false;
    }
  }

  viewIconClick(element:any){
    this.viewIconEmit.emit({element:element});
  } 

  downloadIconClick(element:any){
    this.downloadIconEmit.emit({element:element});
  }

  // SK03OCT24 table import button function
  importButtonEmit(event : any){
    this.uploadModal = true  //SKS25OCT24 for popup upload card
    this.importButtonData = event;
  }

  // SK03OCT24 table export button function
  exportButtonEmit(event: any){
    this.exportButton.emit();
    this.exportReportsData =event
    this.exportSidescreen = true
  }

  // SK03OCT24 multipleCheckBox button emit function
  exportFieldsButtonEmit(event:any){
    this.sideNavButtons.emit(event)
  }

  // SK03OCT24 multipleFilter button emit function
  exportFilterButtonEmit(event:any){
    this.sideNavButtons.emit(event)
  }

  // SK03OCT24 side nav export button function
  exportButtonSideNav(){
    this.exportReports.emit(this.exportReportsData)
  }

  // SK03OCT24 this function emit while selecting values in filter box
  selectedValuesEmit(event:any){
    this.multipleSelectFilterExport.emit(event)
  }

  // SK03OCT24 if date filter, emits the slected dates function
  filterDateEmit(event:any){
    this.filterDateEmitEvt.emit(event)
  }
  //SKS25OCT24 this is to clear the filename array in child component
  filesArrayClear(){
    this.filesArrayComp = [];
    this.uploadModal = false
    this.employeeBulkFile = null || undefined;
  }
  downloadTemplate(){
    this.apiService.getCSVTemplates(this.importButtonData.tableName).subscribe({
      next: (v: any) => {
        if(this.isDevMode) console.log(v);
        const file = new Blob([v], { type: 'text/csv' }); //SKS25OCT24 This matches the content type of the response
        saveAs(file, this.importButtonData.fileName+this.importButtonData.fileFormat);  //SKS25OCT24 name should be dynamic
        this.saveSuccess(this.importButtonData.fileName +" "+  this.templateDownlaod)
    },
      error: (e) => {
        this.showError(e);
      },
      complete: () => console.info('complete') 
    });
  }
  //SKS25OCT24 Alert Message functions
  saveSuccess(event:any){
    this.alertService.messageOnPass('success', event);
  }
  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }
  userFileEmitter(event:any){
    this.employeeBulkFile = event;
  }
  deletedFileInComp(event:any){
    this.filesArrayClear();
  }
   //SKS25OCT24 Upload the excel to add data to Employee record
   uploadRecordsFile(){
    const fd = new FormData();
    fd.append('create_file', this.employeeBulkFile, this.employeeBulkFile.name);
    this.apiService.writeValue(this.importButtonData.apiCall,this.importButtonData.endPoint,fd).subscribe({
      next: (response:any) => {
        if (this.isDevMode) console.log(response.data); //SKS25OCT24 Log the base64 string for debugging
    
        //SKS25OCT24 Convert base64 string to binary data
        const byteCharacters = atob(response.data); //SKS25OCT24 Decode base64
        const byteNumbers = new Uint8Array(byteCharacters.length);
        
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: 'text/xlsx' }); //SKS25OCT24 Create a Blob
    
        //SKS25OCT24 Use FileSaver.js's saveAs to trigger the download
        this.alertService.messageOnFetch('successFetch',this.employeeBulkFile.name, "The report file has been downloaded successfully.");
        saveAs(blob,this.importButtonData.fileName+ this.importButtonData.fileFormat);

    },
      error: (e) => {
        console.error("upload failed with ",e);
        this.errMessage(e);
      },
      complete: () => console.info('upload completed') 
    });
  }

    errMessage(err:any){
      this.loadingSpinner = false;
      this.errorHandler.handleError(err);
      this.errorMessage = this.errorHandler.errorMessage;
      this.alertService.messageOnPass('error', this.errorMessage);
    }
}