import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { id } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { SearchFilterPipe } from 'src/app/pipes/search-filter/search-filter.pipe';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-multi-checkbox',
  standalone: true,
  imports: [CommonModule,MatCheckboxModule, FusionButtonComponent, FormsModule, SearchFilterPipe,CustomTranslatePipe],
  templateUrl: './multi-checkbox.component.html', 
  styleUrls: ['./multi-checkbox.component.css']
})
export class MultiCheckboxComponent implements OnInit {

  @Input() label!:string; // SK15FEB24 label for card
  @Input() checkboxList:Array<any> = []; // SK15FEB24 the list that to to dispolayed in card
  @Input() activeCheckboxList:Array<any> = []; // the active list that to be check 
  @Input() cardSize = "col-md-6"; // SK15FEB24 card size
  @Input() componentData:any; // SK15FEB24 component data
  @Input() cardInlineSize = "col-md-2"; // SK15FEB24 inline chek box size
  @Input() buttonList:Array<any> = []; // SK15FEB24 button list in component
  @Input() cardClass : string = ""; //SK03OCT24
  @Input() clearSelection : boolean = false; //SK27OCT24
  @Input() displayValues:any = {
    keyString:"",
    valueString:""
  };
  @Input() checkBoxStyles : string = "";
  @Output() compDataEmit: EventEmitter<any> = new EventEmitter<any>(); // SK15FEB24 emit action while clicking save
  @Output() checkEmit: EventEmitter<any> = new EventEmitter<any>();
  checkboxSelection:any;
  @Input() radioCheck = false; // SK14MAR24 to select only one checkbox at a time
  isArrayofObj = false; //SK20FEB24 to check the list if 'array' / 'array of objects's
  @ViewChildren('allCheck') allCheck!:QueryList<any>; // SK14MAR24 to clear or check check the "Select/UnSelect All" checkbox
  searchText:any; //SK03OCT24

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes:SimpleChanges){
    // SK15FEB24 the avtive element made true from checkBoxList
    this.checkboxSelection = new SelectionModel<any>(true, []);
    if(changes["clearSelection"]){
      if(this.clearSelection){
        this.checkboxSelection.clear();
      }
    }
    if(changes["checkboxList"] || changes["activeCheckboxList"]){
      if(this.checkboxList?.length > 0)this.isArrayofObj =  typeof this.checkboxList[0] === "string" ? false : true;
      //SK20FEB24 checking the array of objects condition is true
      if(this.isArrayofObj){
        const checkboxList:any = [];
        const endpoint = this.componentData?.config?.endPoint.filter((obj:any)=>obj.name === 'checkboxList')
        // SK25JUL24
        if(endpoint){
          this.checkboxList?.forEach((element:any) => {
            // SK20FEB24, creating an element and assigning the value to a variable, that to be displayed in list
            const dupElement = {label:this.getValue(element, endpoint[0].checkBoxLabelKey),value:this.getValue(element, endpoint[0].checkBoxPatchKey)};
            checkboxList.push(dupElement);
            this.checkboxList = checkboxList;
          });
        } else {
          // SK03OCT24 displayValyes fetch the label/value keys from array list from parent
          if(this.displayValues == null || this.displayValues == undefined ){
            this.checkboxList?.forEach((element:any) => {
              // SK03OCT24, creating an element and assigning the value to a variable, that to be displayed in list
              const dupElement = {label:this.getValue(element, "label"),value:this.getValue(element, "value")};
              checkboxList.push(dupElement);
              this.checkboxList = checkboxList;
            });
          }
        }
        
        //SK20FEB24, selecting the checked element in list  
        // SK03OCT24 binding the keys and values as per parent data
        const activeCBList = typeof this.activeCheckboxList[0] === "string" ? this.activeCheckboxList : this.activeCheckboxList.map((data:any)=> data[this.displayValues?.valueString] || data.value)
        this.checkboxList?.forEach((element1:any) => {
          const val = element1[this.displayValues?.valueString] || element1.value;
          if(activeCBList.includes(val)){
            this.checkboxSelection.select(element1);
          }
        });
      } else {
        this.checkboxList?.forEach((element:any) => {
            if(this.activeCheckboxList.includes(element)){
              this.checkboxSelection.select(element);
            }
        });
      }
    }
  }

  // SK15FEB24 basid on action the event emits
  buttonClick(event:any){
    if(event.buttonAction ==="cancel"){
      this.allCheck.toArray()[0].checked = false; //SK14MAR24 to uncheck "Select/UnSelect All" checkbox on cancl button
      this.checkboxSelection.clear();
    } else {
      this.compDataEmit.emit({checkListArray:this.checkboxSelection.selected, compData:this.componentData, button:event});
    }
  }

  // SK20FEB24 to return the object value
  getValue(element:any, column:string){
    let flds = column.split('.');
    for(let i=0; i<flds.length; i++){
      let splitFlds = flds[i].split('[');
      if(splitFlds.length === 1){
        element = element[flds[i]] || '' as any;
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        element = element[splitFlds[0]][index]  || '' as any; //SK 20DEC23 To solve undefined error
      }
    }
    return element;
  }

  // SK14MAR24 on check/uncheck the checkbox list, the selction data is emitted to parent
  checkAction(evt:any, event:any){
    if(evt === "Select/UnSelect All"){
      if(event.checked){
        this.checkboxList.forEach((ele:any)=>this.checkboxSelection.select(ele))
      } else {
        this.checkboxSelection.clear();
      }
    } else {
      if(this.radioCheck){
        this.checkboxSelection.clear();
        if(event.checked){
          this.checkboxSelection.select(evt);
        }
      }
    }
    if(this.checkboxSelection.selected.length > 0 && evt !== "Select/UnSelect All") this.allCheck.toArray()[0].checked = false;
    if(this.checkboxSelection.selected.length === this.checkboxList.length) this.allCheck.toArray()[0].checked = true;
    this.checkEmit.emit(this.checkboxSelection.selected);
  }
}
