import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {
  currentToken: string = '';
  omitCalls = ['user/login', 'forgotpassword', 'resetpassword'];
  skipInterceptor = false;
  // SK15JUL24 implementing spinner on every api call
  private renderer: Renderer2;
  constructor(private spinner: NgxSpinnerService, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Fetch the JWT token so it can be sent to all the http request 
    // const idToken = localStorage.getItem("id_token");
    this.showLoader();
    this.currentToken = localStorage['auth_token'];

    this.omitCalls.forEach(api => {
      if (req.url.includes(api)) {
        this.skipInterceptor = true;
      } else {
        this.skipInterceptor = false;
      }
    });
    // Check if the user is logged in already and posses the JWT token
    if (this.currentToken && !this.skipInterceptor) {
      // In order to modify the http request we make a clone of it and add add in the required parameters
      req = req.clone({
        headers: req.headers.set('token', this.currentToken)
      });
    }
    // Just proceed with the request without pushing the token to the HTTP request
    // SK15JUL24 loader function on api call
    return next.handle(req).pipe(tap(async (event: HttpEvent<any>) => {
      await event;
      if (event instanceof HttpResponse) {
        this.hideLoader();
      }
    },
      (err: any) => {
        this.hideLoader();
      }));
  }

  private showLoader(): void {
    this.spinner.show();
  }
  private hideLoader(): void {
    this.spinner.hide();
  }
}
