import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';

@Component({
  selector: 'app-success-failure-message',
  standalone: true,
  templateUrl: './success-failure-message.component.html',
  imports:[CommonModule, FusionButtonComponent],
  styleUrls: ['./success-failure-message.component.css']
})
export class SuccessFailureMessageComponent implements OnInit {
  @Input() alertMessage:string = '';
  @Input() alertType:string = '';
  @Input() alertIcon:string = '';

  showSubmissionMsg = false;
  showDeletedMsg=false;
  showErrorMsg = false;
  errorMessage = '';
  
  
  constructor() { 
  }

  ngOnInit(): void {
  
  }
 
} 


