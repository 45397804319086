import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'customTranslate',
  pure: false,
  standalone: true
})
export class CustomTranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string, fallback: string): string {
    const translatedText = this.translate.instant(value);
    return translatedText === value ? fallback : translatedText;
  }

}
