import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavEmitService {

  // Observable string sources
  private clickedSideNavSource = new Subject<string>();
  private clickedHamBurgerSource = new Subject<string>();

  // Observable string streams
  clickedSideNav$ = this.clickedSideNavSource.asObservable();
  isHamburgerClicked$ = this.clickedHamBurgerSource.asObservable();

  // Service message commands
  clickedOnSideNav(navItem: string){
    this.clickedSideNavSource.next(navItem);
  }

  clickedOnHamburger(navItem: string){
    this.clickedHamBurgerSource.next(navItem);
  }
  constructor() { }

}
