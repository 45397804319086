export class Constants {
    static SERVER_BASE_URL: string = "http://arabic-api.rangerfusion.com";
    static SUCCESS_MESSAGE: string = "Data saved successfully";
    static DELETE_SUCCESS_MESSAGE: string = "Data deleted successfully";
    static APPROVE_MESSAGE : string = "Approval request approved successfully";
    static REJECT_MESSAGE : string = "Approval request rejected successfully";
    static DOWNLOAD_TEMPLATE : string = "template downloaded successfully";
    static PAGE_SIZE_OPTIONS :  Array<any> = ['5','10', '25', '50', '100', '200'];
    static leaveTypes: any[]=['Casual leave', 'Sick leave', 'Maternity leave', 'Paternity leave', 'Marriage leave', 'Compensation Off', 'Loss of Pay'];
    static BASIC : string = 'Basic';
    static HRA : string = 'House Rent Allowance';
    static EPF : string = 'Employee Provident Fund';
    static VPF : string = 'Voluntary Provident Fund';
    static ESI : string = 'Employee State Insurance';
    static PT : string = 'Professional Tax';
    static EMPLOYER_PF : string = 'Employer Provident Fund';
    static EMPLOYER_ESI : string = "Employer State Insurance";
    static SD : string = "Standard Deduction";
    static EARNINGS : string = "Earnings";
    static ALLOWANCES : string = "Allowances";
    static DEDUCTIONS : string = "Deductions";
    static BENEFITS : string = "Benefits"; //SK18JUN24 to add benefits as separate component in compensation
    static REIMBURSEMENTS : string = "Reimbursements";
    static OTHER_INVESTMENTS : string = 'Other Investments and Excemptions';
    static INCOME_FROM_OTHERSOUCES : string = 'Income from Other Sources';

    // SK21AUG24 static modules in all apps
    static hrmStaticModules:Array<any> = ["dashboard", "employees", "compensation", "payroll", "leaveAllocation", "attendance", "approvals"];
    static essStaticModules:Array<any> = ["details", "leaves", "expenses", "payslips", "taxdeclarations"]
    static settingsStaticModules:Array<any> = ["company", "localization", "leaveMaster", "bankInfo", "users", "apps", "dropDownManagement"]
    /*
    static configJSON: Array<any> = [
        {eventName: 'parking', icon: '../assets/images/icons/app-launcher/ess.svg', access: true,
            roles : ['App User'],
            fromJSON: true,
            modules:{
                label: 'Parking',
                switchCaseName:'Parking',
                defaultModule: 'details',
                list: [
                    {navName:'home', icon:'../assets/images/icons/side-nav/bill-line.svg', label:'Home', 
                        tabs:[
                            {
                                tabName:'Entries', 
                                path:'entries_button', 
                                type: 'nxtPage',
                                inputs:{
                                    responseObject : true,
                                    responseObjectKey:'jsonBody',
                                    responseArray : false,
                                    type:'openNxtButtonPage', 
                                    apiCall: "get",
                                    endpoint : "/nxt?name=a033t00000rRXl5AAG",
                                },
    
                            },
                        ]
                    },
                    {navName:'dashboard', icon:'../assets/images/icons/side-nav/bill-line.svg', label:'Dashboard', 
                        tabs:[
                            {
                                tabName:"Today's Status", 
                                path:'today_dashboard', 
                                type: 'dashboard',
                                inputs: {
                                    endpoint: '/parking/dashboard',
                                    apiCall: 'post',
                                    metrics : {
                                        noncomparison : [
                                            {key:'currentDate', name:'currentDate', chart:'Pie', filterType: 'noncomparison', label: "Today's Status"},
                                        ],
                                    }
                                }
    
                            },
                            {
                                tabName:'Weekly Status', 
                                path:'weekly_dashboard', 
                                type: 'dashboard',
                                inputs: {
                                    endpoint: '/parking/dashboard',
                                    apiCall: 'post',
                                    metrics : {
                                        noncomparison : [
                                            {key:'previousWeek', name:'previousWeek', chart:'Advanced Pie', filterType: 'noncomparison', label: 'Last Week Entries'},
                                        ],
                                    }
                                }
    
                            },
                            {
                                tabName:'Monthly Status', 
                                path:'monthly_dashboard', 
                                type: 'dashboard',
                                inputs: {
                                    endpoint: '/parking/dashboard',
                                    apiCall: 'post',
                                    metrics : {
                                        noncomparison : [
                                            {key:'previousMonth', name:'previousMonth', chart:'Advanced Pie', filterType: 'noncomparison', label: 'Last Month Entries'},
                                        ],
                                    }
                                }
    
                            },
                        ]
                    },
                    {navName:'employees', icon:'../assets/images/icons/side-nav/bill-line.svg', label:'Employees', 
                        tabs:[
                            {
                                tabName:'Employees', 
                                path:'parking_employees', 
                                type: 'table',
                                inputs: {
                                    endpoint: '/parking/employees',
                                    apiCall: 'get',
                                    inputs :{},
                                    responseObject : true,
                                    responseObjectKey:'employees',
                                    responseArray : false,
                                    searchBar: true,
                                    headerLabels:['Employee Id', 'First Name', 'Last Name', 'Number Plate', 'Division'],
                                    displayedColumns:['employeeId', 'firstName', 'lastName', 'numberPlate', 'division'],
                                    fromWhere: 'common',
                                    isButtons: true,
                                    withCheckBox:true,
                                    buttons: [
                                        { name: 'New Employee', class: 'btn btn-primary', buttonType:'newRecord', buttonAction:'openModal', 
                                            modalProperties:{
                                                inputs:{
                                                    responseObject : true,
                                                    responseObjectKey:'jsonBody',
                                                    responseArray : false,
                                                    type:'openNXTPage', 
                                                },
                                                type:'openNXTPage', 
                                                pageContent:'a033t00000rRUKcAAO', 
                                                modalTitle: 'New Employee',
                                                modalSaveButtonValue:'Save',
                                                modalCancelButtonValue:'Cancel',
                                                modalSize:'modal-lg',
                                                isModalFooter: false,
                                                responseObject : true,
                                                responseObjectKey:'jsonBody',
                                                responseArray : false,
                                                nxtAPI:'/parking/new',
                                                tableGetAPICall:'get',
                                                tableGetAPIPath:'/parking/employees',
                                                successMessage:'Employee added succesfully',
                                            }   
                                        },
                                        { name: 'Export Selected', buttonType:'exportSelected' , class: 'grey-border rounded-3 bg-white text-dark', buttonAction:'downloadCSV'},
                                    ]
                                },
                            },
                        ]
                    },
                    {navName:'entries', icon:'../assets/images/icons/side-nav/bill-line.svg', label:'Parking Entries', 
                        tabs:[
                            { 
                                tabName:'Parking Entries', 
                                path:'parking_entries', 
                                type: 'table',
                                inputs: {
                                    endpoint: '/parking/entries',
                                    apiCall: 'get',
                                    inputs :{},
                                    type : 'nxtGetCall',
                                    responseObject : true,
                                    responseObjectKey:'entries',
                                    responseArray : false,
                                    searchBar: true,
                                    headerLabels:['First Name', 'Last Name', 'Number Plate', 'Division', 'Parking Date', 'Parking Time', 'Parking ticket'],
                                    displayedColumns:['firstName', 'lastName', 'numberPlate', 'division', 'date','time', 'parkingTicket'],
                                    fromWhere: 'common',
                                    isButtons: true,
                                    withCheckBox:true,
                                    buttons: [
                                        { name: 'New Entry', class: 'btn btn-primary', buttonType:'newRecord', buttonAction:'openModal', 
                                            modalProperties:{
                                                type:'openNXTPage', 
                                                inputs:{
                                                    responseObject : true,
                                                    responseObjectKey:'jsonBody',
                                                    responseArray : false,
                                                    type:'openNXTPage', 
                                                },
                                                pageContent:'a033t00000rRTDpAAO',
                                                modalTitle: 'New Entry',
                                                modalSaveButtonValue:'Save',
                                                modalCancelButtonValue:'Cancel',
                                                modalSize:'modal-lg',
                                                isModalFooter: false,
                                                responseObject : true,
                                                responseObjectKey:'jsonBody',
                                                responseArray : false,
                                                nxtAPI:'/parking/new',
                                                tableGetAPICall:'get',
                                                tableGetAPIPath:'/parking/entries',
                                                successMessage:'Parking entry added succesfully',
                                            }   
                                        },
                                        { name: 'Export Selected', buttonType:'exportSelected' , class: 'grey-border rounded-3 bg-white text-dark', buttonAction:'downloadCSV'},
                                    ]
                                },
                            },
                        ]
                    },
                    {navName:'visitorLog', icon:'../assets/images/icons/side-nav/bill-line.svg', label:'Visitor Log', 
                        tabs:[
                            { 
                                tabName:'Visitor Entries', 
                                path:'visitor_entries', 
                                type: 'table',
                                inputs: {
                                    endpoint: '/parking/visitors',
                                    apiCall: 'get',
                                    inputs :{},
                                    type : 'nxtGetCall',
                                    responseObject : true,
                                    responseObjectKey:'visitors',
                                    responseArray : false,
                                    searchBar: true,
                                    headerLabels:['Employee Id', 'Visitor Name', 'Visit Purpose', 'Visit Date', 'Visitor Type', 'Visitor Id', 'Time In', 'Time Out', 'Signature'],
                                    displayedColumns:['employeeId', 'visitorName', 'visitPurpose', 'visitDate', 'visitorType', 'visitorId', 'timeIn', 'timeOut', 'signature'],
                                    fromWhere: 'common',
                                    isButtons: true,
                                    withCheckBox:true,
                                    buttons: [
                                        { name: 'New Visitor', class: 'btn btn-primary', buttonType:'newRecord', buttonAction:'openModal', 
                                            modalProperties:{
                                                type:'openNXTPage', 
                                                inputs:{
                                                    responseObject : true,
                                                    responseObjectKey:'jsonBody',
                                                    responseArray : false,
                                                    type:'openNXTPage', 
                                                },
                                                pageContent:'a033t00000rRUKwAAO',
                                                modalTitle: 'New Visitor Entry',
                                                modalSaveButtonValue:'Save',
                                                modalCancelButtonValue:'Cancel',
                                                modalSize:'modal-lg',
                                                isModalFooter: false,
                                                responseObject : true,
                                                responseObjectKey:'jsonBody',
                                                responseArray : false,
                                                nxtAPI:'/parking/new-visitor',
                                                tableGetAPICall:'get',
                                                tableGetAPIPath:'/parking/visitors',
                                                successMessage:'Visitor entry added successfully',
                                            }   
                                        },
                                        { name: 'Export Selected', buttonType:'exportSelected' , class: 'grey-border rounded-3 bg-white text-dark', buttonAction:'downloadCSV'},
                                    ]
                                },
                            },
                        ]
                    },
                    {navName:'stationary', icon:'../assets/images/icons/side-nav/bill-line.svg', label:'Stationary Entries', 
                        tabs:[
                            { 
                                tabName:'Stationary Entries', 
                                path:'stationary_entries', 
                                type: 'table',
                                inputs: {
                                    endpoint: '/parking/stationaries',
                                    apiCall: 'get',
                                    inputs :{},
                                    type : 'nxtGetCall',
                                    responseObject : true,
                                    responseObjectKey:'stationaries',
                                    responseArray : false,
                                    searchBar: true,
                                    headerLabels:['Employee Id', 'Item Type', 'Item Details', 'Number of Items', 'Date & Time'],
                                    displayedColumns:['employeeId', 'itemType', 'itemDetails', 'noOfItems', 'date'],
                                    fromWhere: 'common',
                                    isButtons: true,
                                    withCheckBox:true,
                                    buttons: [
                                        { name: 'New Stationary Entry', class: 'btn btn-primary', buttonType:'newRecord', buttonAction:'openModal', 
                                            modalProperties:{
                                                type:'openNXTPage', 
                                                inputs:{
                                                    responseObject : true,
                                                    responseObjectKey:'jsonBody',
                                                    responseArray : false,
                                                    type:'openNXTPage', 
                                                },
                                                pageContent:'a033t00000rRUL1AAO',
                                                modalTitle: 'New Stationary Entry',
                                                modalSaveButtonValue:'Save',
                                                modalCancelButtonValue:'Cancel',
                                                modalSize:'modal-lg',
                                                isModalFooter: false,
                                                responseObject : true,
                                                responseObjectKey:'jsonBody',
                                                responseArray : false,
                                                nxtAPI:'/parking/new-stationary ',
                                                tableGetAPICall:'get',
                                                tableGetAPIPath:'/parking/stationaries',
                                                successMessage:'Stationary entry added succesfully',
                                            }   
                                        },
                                        { name: 'Export Selected', buttonType:'exportSelected' , class: 'grey-border rounded-3 bg-white text-dark', buttonAction:'downloadCSV'},
                                    ]
                                },
                            },
                        ]
                    },
                ]
            }
        },       
    ];
    */
}
