// SK10JUN24 importing web usb type for usb print
/// <reference types="w3c-web-usb" />
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { FusionFrameworkService } from 'src/app/services/fusion-framework.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DataTableComponent } from '../data-table/data-table.component';
import { ApiService } from 'src/app/services/api.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { ModalComponent } from '../modal/modal.component';
import { NxtAppModule } from '@rangertechnologies/ngnxt';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { BrowseComponent } from '../browse/browse.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CardsComponent } from '../cards/cards.component'; //SK12FEB24 cards component in custom app 
import { MultiCheckboxComponent } from '../multi-checkbox/multi-checkbox.component';
import { AttendanceHrmComponent } from 'src/app/pages/attendance/attendance-hrm/attendance-hrm.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import * as saveAs from 'file-saver';
import { Constants } from 'src/app/models/Constants';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule, DashboardComponent, DataTableComponent, ModalComponent, NxtAppModule, FusionButtonComponent, RouterModule, BrowseComponent, AngularSvgIconModule, CardsComponent, MultiCheckboxComponent, AttendanceHrmComponent, NgxSpinnerModule, CustomTranslatePipe],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css']
})

export class TabsComponent implements OnInit {
  // jsonSubscription: Subscription;
  configTabs: Array<any> = [];
  @Input() inputModule: any;
  @Input() inputTab: any;
  @Input() skipRouting = false; //SK31JAN24 to skip routing from other page
  @Output() emitTabContent = new EventEmitter<any>;
  myActiveNav : any;
  displayedTabContent : any;
  tabsSubscription: Subscription;
  userDataSubscription!: Subscription;

  selectedTab: any;
  tableData = [];
  metricsArray : Array<any> = [];
  tableInputs:any;
  tabName:any;
  appName:any;
  moduleName:any;
  errorMessage:any;
  tabInputs:any;
  openModal = false;
  modalProperties:any;
  nxtJSON:any;
  data:Array<any> =[];
  buttonNxtInput:any;
  checkedList: Array<any> = [];
  hyperLinkAction: Array<any> = [];
  browseTabs: any; //SK23JAN24 to assign the browsed tab in tabs List as a whole
  bookValue:any
  configJSON:Array<any> = [];
  cardData:any; // SK14FEB24
  currentTab:any; // SK14FEB24
  employeeListAttendance:Array<any>=[]; //SK22APR24 labels and columns of employee attendance table
  multipleFilterArray:any; //SK11MAY24
  finalData: any; //SK11MAY24
  typeSelected! : string; //SK14MAY24 for loader spinner type
  @Input() languageCode:any; //SK24JUN24
  nxtQuestionnaireId = ""
  baseURL = Constants.SERVER_BASE_URL;
  tabConfigProperties:any;
  calendatMonth = ""; //SK13SEP24
  calendarEvent:any; //SK13SEP24

  constructor(private fusionFrameWork: FusionFrameworkService, private router: Router, private routeActive: ActivatedRoute, private apiService: ApiService, private globalValues: GlobalValuesService, private alertService: AlertService, private errorHandler: ErrorHandlerService, private datePipe: DatePipe, private spinnerService: NgxSpinnerService) { 
    console.log('inside tabs constructor ' + window.location);
    this.typeSelected = 'ball-spin-clockwise-fade'; //SK14MAY24 loader type
    this.tabsSubscription = this.fusionFrameWork.tabJSONData$.subscribe((module:any) => {
      console.log('inside tabs constructor tabsSubscription ' + module.name);
      let tabFiltered:any;
      this.routeActive.paramMap.subscribe(params => {
        // this.skipRouting = true;
        this.nxtQuestionnaireId = "";
        this.appName = params.get('appName');
        this.moduleName = params.get('moduleName');
        this.tabName = params.get('tabName');
        this.displayedTabContent = this.tabName;
        console.log('load the data from localstorage');
        const userDataConst:any = localStorage.getItem('userData');
        if(userDataConst) {
          let apps = JSON.parse(userDataConst).apps;
          let appFiltered = apps?.filter((appFilter:any) => appFilter.name === this.appName);
          let moduleFiltered = appFiltered[0].modules?.filter((modFilter:any) => modFilter.name === this.moduleName);
          //SK23JAN24 to fetch the module and tabs list form localstorage of browsed tab is added
          const module = JSON.parse(localStorage.getItem("browseTabs") as any);
          // SK 18JAN24 Need to improve it for dynamic tab
          if(this.moduleName === module?.name){
            this.browseTabs = module;
            tabFiltered = module?.tabs?.filter((tabFilter:any) => tabFilter.name === this.tabName);
            // SK10MAY24
            if(tabFiltered.length > 0){
              this.currentTabNav(tabFiltered[0]);
            } else {
              // SK10AUG24
              const defTab = module.tabs[0].config;
              if(defTab.type === "tableData"){
                let col = {
                  "name":this.tabName, 
                  "label":this.tabName, 
                  "browsedTab":true
                }
                const tabNav = {...defTab.hyperLinkActions[0],...col}
                this.browseTabs.tabs.push(tabNav);
                this.browseTabs = this.deleteCompenentData(this.browseTabs);
                localStorage.setItem('browseTabs',JSON.stringify(this.browseTabs));
                this.currentTabNav(tabNav);
              }
            }
          } else {
            this.browseTabs = moduleFiltered[0];
            tabFiltered = moduleFiltered[0].tabs?.filter((tabFilter:any) => tabFilter.name === this.tabName);
            if(tabFiltered.length > 0){
              this.currentTabNav(tabFiltered[0]);
            } else {
              // SK10AUG24
              const defTab = moduleFiltered[0].tabs[0].config;
              if(defTab.type === "tableData"){
                let col = {
                  "name":this.tabName, 
                  "label":this.tabName, 
                  "browsedTab":true
                }
                const tabNav = {...defTab.hyperLinkActions[0],...col}
                this.browseTabs.tabs.push(tabNav);
                this.browseTabs = this.deleteCompenentData(this.browseTabs);
                localStorage.setItem('browseTabs',JSON.stringify(this.browseTabs));
                this.currentTabNav(tabNav);
              }
            }
          }
          this.inputModule = moduleFiltered[0];
        } 
        this.ngOnDestroy();
      });
      //SK23JAN24 to fetch the current tab and load the data
      // if(tabFiltered) this.currentTabNav(tabFiltered[0]);
    });
  }

  ngOnInit(): void {
    //this.loadFilterState();//MV21AUG24 Load filter state on initialization
    // SK14MAY24
    this.calendarEvent = {year:new Date().getFullYear(), month: (new Date().getMonth()+1)} //SK13SEP24 default calendar date set to current month
    if(this.skipRouting){
      this.browseTabs = this.browseTabs ?  this.browseTabs : this.inputModule;
      const tabFiltered = this.browseTabs.tabs?.filter((tabFilter:any) => tabFilter.name === this.tabName);
      if(this.tabName){
        if(tabFiltered) this.currentTabNav(tabFiltered[0]);
      } else{
        this.currentTabNav(this.inputModule.tabs[0]);
      }
    }
    const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24
    this.languageCode = userData?.languageCode || "en";
  }

  // SK 31JAN24 passing the module data as input in settings component
  ngOnChanges(changes:SimpleChanges): void {
    //  SK10MAY24, to increase performance removed from ngOnChanges
    // SK21AUG24
    if(changes['inputModule']){
      // SK02FEB24,While reload, If the browse tabs has data list, that will be assigned
      if(this.skipRouting){
        this.browseTabs = this.inputModule;
        const tabFiltered = this.browseTabs.tabs?.filter((tabFilter:any) => tabFilter.name === this.tabName);
        if(this.tabName){
          if(tabFiltered) this.currentTabNav(tabFiltered[0]);
        } else{
          this.currentTabNav(this.inputModule.tabs[0]);
        }
      }
    }
  }
  
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.tabsSubscription.unsubscribe();
  }

  saveSuccess(event:any) {
    this.alertService.messageOnPass('success', event);
  }

  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  async currentTabNav(selTab:any, browseTabData:any| undefined = undefined){
    // SK10MAY24
    this.modalProperties = selTab?.modalProperties;
    this.tabConfigProperties = selTab?.config; //SK13SEP24 to store tab configs
    this.currentTab = selTab; //SK14FEB24 the current tab details saved to load the tab data on call
    //SK23JAN24 navigate the tab name on router for browse tabs
    //SK31JAN24  to skip routing from other page
    // SK24JUN24 langcode binding in url 
    const userData = JSON.parse(localStorage.getItem('userData') as any); //SKS11OCT24
    this.languageCode = userData.languageCode || "en";
    if(!this.skipRouting)this.router.navigate([`${"app"}`, `${this.languageCode || "en"}`, `${this.appName}`, `${this.moduleName}`, `${selTab?.name}`]);
    if(selTab?.dataGetProperties){
      this.bookValue = {};
      // SK15JUL24 to get the response from api
      try {
        // SK10AUG24 nxt questionnaire
        if(selTab?.type === "nxtQuestionnairePage"){
          const data = await this.apiService.fetchDataAsPromise(selTab?.dataGetProperties.endpoint+selTab?.name);
          this.bookValue = data[0];
          if(selTab?.dataGetProperties.responseObject){
            selTab.tabBrowseContent = data[selTab?.dataGetProperties.responseObjectKey][0];
            this.bookValue = data[selTab?.dataGetProperties.responseObjectKey][0];
          } else if(selTab?.dataGetProperties.responseArray){
            selTab.tabBrowseContent = data[0];
            this.bookValue = data[0];
          }
        } else if(selTab?.type === "nxtPage"){
          const data = await this.apiService.fetchDataAsPromise(selTab?.dataGetProperties.endpoint+selTab?.name);
          this.bookValue = data[0];
          if(selTab?.dataGetProperties.responseObject){
            selTab.tabBrowseContent = this.globalValues.getValue(data,selTab?.dataGetProperties.responseObjectKey);
            this.bookValue = this.globalValues.getValue(data,selTab?.dataGetProperties.responseObjectKey);
          } else if(selTab?.dataGetProperties.responseArray){
            selTab.tabBrowseContent = data[0];
            this.bookValue = data[0];
          }
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    } else if(selTab?.tabBrowseContent != undefined){
      this.bookValue = {};
      this.bookValue = selTab.tabBrowseContent;
    }
    this.data = [];
    console.log('onclick of the ' + selTab?.name + ' tab');
    this.selectedTab = selTab;
    this.displayedTabContent = selTab?.name;
    const tabConfig = selTab?.config;
    // SK12FEB24 to render multiple components in single screen
    if(selTab?.components != undefined){
      selTab.components.forEach((comps:any) => {
        // SK12FEB24 based on the component type the components will be listed
        if(comps?.type === 'dashboard') {
          this.dashboardGET(comps?.config?.apiCall, comps?.config?.endpoint, comps?.config?.metrics,selTab, comps)
        } else if (comps?.type === 'table'){
          if(comps?.config?.endpoint === "" || comps?.config?.endpoint === undefined){
            comps.config.data = [];
            if(comps.config.dataKey){
              const tableData = selTab?.tabBrowseContent[comps.config.dataKey];
              if(comps.config.isArrayofObj){
                comps.config.data = tableData;
              } else {
                tableData.forEach((indValue:any) => {
                  if(typeof indValue == "string"){
                    comps.config.data.push(Object.assign({[comps.config.dataKey]:indValue})) ;
                  }
                });
              }
            }
          } else {
            this.tabInputs = comps;
            this.hyperLinkAction = comps?.config?.hyperLinkActions; //SK03JUL24 hyperlink actions for component structure
            this.getData(comps?.config?.apiCall, comps?.config?.endpoint, comps);
          }
        } else if (comps?.type === 'nxtPage'){
          this.getData(comps?.config?.apiCall, comps?.config?.endpoint, comps);
        } else if (comps?.type === 'nxtQuestionnairePage'){ //SK10AUG24 nxt questionnaire
          this.nxtQuestionnaireId = comps?.tabBrowseContent.answerBookId || comps?.tabBrowseContent.questionnaire.bookId;
        } else if (comps?.type === 'card'){
          this.cardData = comps.config.cardList;
          if(selTab?.tabBrowseContent != undefined){
            this.cardData.forEach((cardDatum:any) => {
              cardDatum.config.details.forEach((fieldsValue:any) => {
                cardDatum.config.data = {}; //SK19FEB24 assigning the card details in config
                fieldsValue.value = this.getValue(selTab?.tabBrowseContent, fieldsValue.RF);
                cardDatum.config.data[fieldsValue.refField] = fieldsValue.value;
              });
              cardDatum.data = selTab?.tabBrowseContent;
            });
          } else {
            this.cardData.forEach((cardDatum:any) => {
              this.getData(cardDatum.config.apiCall, cardDatum.config.endpoint, {config:cardDatum.config});
            });
          }
        } else if (comps?.type === 'multiCheck'){ // SK15FEB24 multi checbox component
          comps.config.data = selTab?.tabBrowseContent;
          comps.config.endPoint.forEach((endPoint:any) => {
            // SK15FEB24 if the path is not defined, we will load the selected component data
            if(endPoint.path === ""){
              if(endPoint.name === "activeCheckboxList"){
                comps.config.activeCheckboxList = this.getValue(selTab?.tabBrowseContent, endPoint.responseObjectKey);
              } else if(endPoint.name === "checkboxList"){
                comps.config.checkboxList = this.getValue(selTab?.tabBrowseContent, endPoint.responseObjectKey);
              }
            } else {
              this.getData( endPoint.method, endPoint.path, {config:endPoint}, comps);
            }
          });
        } else if (comps?.type === 'attendanceTable'){
          // SK22APR24 configuring attendance table details
          this.getData(comps?.method, comps?.path, {config:comps});
        }
      });
    } else {
      if(selTab?.type === 'dashboard') {
        this.dashboardGET(tabConfig.apiCall, tabConfig.endpoint, tabConfig.metrics,selTab,null)
      } else if (selTab?.type === 'table' || selTab?.type === 'nxtPage'){
        this.tableInputs = tabConfig;
        this.hyperLinkAction = tabConfig?.hyperLinkActions;
        this.tabInputs = selTab;
        this.getData(tabConfig.apiCall, tabConfig.endpoint, selTab);
        // SK12FEB24 card component is added here
      } else if (selTab?.type === 'nxtQuestionnairePage'){ //SK10AUG24 nxt questionnaire
        this.nxtQuestionnaireId = selTab?.tabBrowseContent.answerBookId || selTab?.tabBrowseContent.questionnaire.bookId;
      }  else if (selTab?.type === 'card'){
        this.cardData = selTab.cardList.cardList;
        if(selTab?.tabBrowseContent != undefined){
          this.cardData.forEach((cardDatum:any) => {
            cardDatum.config.details.forEach((fieldsValue:any) => {
              cardDatum.config.data = {}; //SK19FEB24 assigning the card details in config
              fieldsValue.value = this.getValue(selTab?.tabBrowseContent, fieldsValue.RF);
              cardDatum.config.data[fieldsValue.refField] = fieldsValue.value;
            });;
          });
        } else {
          this.cardData.forEach((cardDatum:any) => {
            this.getData(cardDatum.config.apiCall, cardDatum.config.endpoint, {config:cardDatum.config});
          });
        }
      } else if (selTab?.type === 'attendanceTable'){
      }
    }
  }

  dashboardGET(apiCall:any, endPoint:any, metrics:any,config:any, event:any){
    for (let metricKey in metrics) {
      let types : any = {};
      //the keys that to be sent to API is filtered here 
      metrics[metricKey].forEach((item:any) => {
        types[item.key] = true;
      });
      // SK14MAY24 loader in calls
      // this.spinnerService.show();
      // SK27OCT24 dashboard route updates as backend change
      if(apiCall === "get"){
        this.apiService.fetchValue(endPoint).subscribe((data:any) => {
          // this.spinnerService.hide();
          // SK12FEB24 top render the dashboard in components array, we are assiging the values in same component object
          metrics[metricKey].forEach((element:any) => {
            element.data = data[element.name];
          });
          // SK10MAY24 dashboard configuration for component type data
          if(config.components === undefined){
            this.metricsArray = [];
            this.metricsArray.push(...metrics[metricKey]);
            this.globalValues.chartDataOnPass(this.metricsArray);
          } else {
            event.config.metricsArray = [];
            event.config.metricsArray.push(...metrics[metricKey]);
            this.globalValues.chartDataOnPass(event.config.metricsArray);
          }
        })
      } else {
        this.apiService.writeValue(apiCall,endPoint,{...{filter:metricKey}, ...types}).subscribe((data:any) => {
          // this.spinnerService.hide();
          // SK12FEB24 top render the dashboard in components array, we are assiging the values in same component object
          metrics[metricKey].forEach((element:any) => {
            element.data = data[element.name];
          });
          // SK10MAY24 dashboard configuration for component type data
          if(config.components === undefined){
            this.metricsArray = [];
            this.metricsArray.push(...metrics[metricKey]);
            this.globalValues.chartDataOnPass(this.metricsArray);
          } else {
            event.config.metricsArray = [];
            event.config.metricsArray.push(...metrics[metricKey]);
            this.globalValues.chartDataOnPass(event.config.metricsArray);
          }
        })
      }
    }
  }

  commonButtonClickEmit(event:any, index:any | undefined = undefined){
    if(event.buttonAction == 'openModal'){
      this.openModal = true;
      //SK 18JAN24 Whether the modal action is to create or edit data
      if(event.buttonType === 'newRecord'){
        this.bookValue = {};
      }
      this.modalProperties = event.modalProperties;
      if(this.modalProperties?.type === 'openNXTPage' || this.modalProperties?.type === 'calendar'){ //SK13SEP24 adding calendar  type check
        const url = '/nxt?name=' + this.modalProperties.pageContent;
        this.getData('get', url, this.modalProperties, index);
      }
    } else if(event.buttonAction == 'downloadCSV'){
      // this.downloadCsv();
      // // SK14MAY24 csv export
      this.downloadCurrentEmployees(event?.exportFields || []);
    } else if(event.buttonAction == 'downloadCSVServer'){
      // // SK24MAY24 xlsx export from server
      this.apiService.getXlsx(event.api).subscribe({
        next: (v: Blob) => {
          const file = new Blob([v], { type: 'text/xlsx' }); //SKS25OCT24 This matches the content type of the response
          saveAs(file, event.fileName+"export.xlsx");  //SKS25OCT24 name should be dynamic
          this.saveSuccess("File downloaded successfully");

        },
        error: (e) => {
            this.showError(e);
        },
        complete: () => console.info('complete') 
      })
    }
  }

  // SK12FEB24 method name changed from 'getTableData' the below
  getData(apiCall:any, endPoint:any, event:any, parentData:any | undefined = undefined): void{
    if(apiCall === 'get'){
      let finalGetCall:any;
      // SK12FEB24 if the config has endpoint with params, we will add the path to url
      if(event.config.isQueryParams){
        finalGetCall = endPoint + event.config.paramsKey + event.config.paramsValue;
      } else {
        finalGetCall = endPoint
      }
      // SK14MAY24 loader in calls
      // this.spinnerService.show();
      this.apiService.fetchValue(finalGetCall).subscribe((getData:any) => {
        // this.spinnerService.hide();
        event.config.data = [];
        if(event.config.responseObject){
          const key = event.config.responseObjectKey;
          if(event.config.type == 'openNXTPage' || event.config.type == 'calendar'){  //SK13SEP24 adding calendar  type check
            if(getData["nxtBindInFunctionsMap"]) {
              const evalfn = eval(getData["nxtBindInFunctionsMap"][getData?.name]);
            }
            // SK18JAN24 for hyperlink action type
              this.nxtJSON = getData[key];
              event.config.nxtJSON = getData[key]; //SK12FEB24
              // SK13SEP24 if type is calendar
              if(event.config.type === "calendar"){              
                this.handleCalendarDate(this.calendarEvent)
              }
          } else if(event.config.type == 'openNxtButtonPage'){
            this.nxtJSON = getData[key];
            console.log('inside getTableData'); // + this.buttonNxtInput);
          } else if(event.config.type === 'card'){ // SK20DEC23 assigning table data if response is an array array
            event.config.data = {};
            event.config.details.forEach((element:any) => {
              element.value = this.getValue(getData, element.RF);            
              event.config.data[element.refField] = element.value; //SK19FEB24 assigning the card details in config
            });
          } else if(event.config.type === 'multiCheck'){ // SK15FEB24 assigning checkbox data if response is an array array
            if(event.config.name === "activeCheckboxList"){
              parentData.config.activeCheckboxList = this.getValue(getData, event.config.responseObjectKey);
            } else if(event.config.name === "checkboxList"){
              parentData.config.checkboxList = this.getValue(getData, event.config.responseObjectKey);
            }
          } else if(event.config.type === "table" ||  event.config.type === "tableData"){
            this.data = [];
            event.config.data = [];
            this.data = this.getValue(getData,key);
            this.finalData = JSON.stringify(this.data);
            event.config.data.push(getData[key]);
            // SK11MAY24 adding filter keys in table
            if(event?.config?.filterColumns?.length > 0){
              this.multipleFilterArray = {};
              const filtObjs:any = {};
              event.config.filterColumns.forEach((element:any) => {
                this.multipleFilterArray[element] = null;
                filtObjs[element] = [];
              });
              this.data.forEach(element => {
                event.config.filterColumns.forEach((key:any) => {
                  if(Object.keys(element).includes(key)){
                    filtObjs[key].push(element[key])
                    this.multipleFilterArray[key] =(new Set(filtObjs[key]));
                  }
                });
              });
              event.config.multipleFilterArray = this.multipleFilterArray;
              const filter:any =localStorage.getItem('tabfilter') //MV24AUG24 get the tbfilter keys and pass to the filterRetin
              this.filterKeys=JSON.parse(filter)||{}
              this.filterRetain(this.filterKeys)
              // this.searchFilterData(event); //MV21AUG24 calling the filterdata
            }
          } else {
            this.data = [];
            this.data = this.getValue(getData,key);
            event.config.data.push(getData[key]);
            if(event.config.type === 'nxtGetCall'){
              this.data.forEach((data:any)=>{
                const date:any = this.datePipe.transform(data.parkingDate, 'short');
                data.time = date?.split(',')[1];
                data.date = this.datePipe.transform(date?.split(',')[0], 'mediumDate');
                delete data._id;
                delete data.parkingDate;
              })
            }
          }
        } else if(event?.config?.responseArray){
          if(event.config.type === 'tableData'){ // SK20DEC23 assigning table data if response is an array array
            this.data = getData;
            // SK11MAY24 adding filter keys in table 
            this.finalData = JSON.stringify(this.data);
            event.config.data = getData;
            if(event.config?.filterColumns?.length > 0){
              this.multipleFilterArray = {};
              const filtObjs:any = {};
              event.config.filterColumns.forEach((element:any) => {
                this.multipleFilterArray[element] = null;
                filtObjs[element] = [];
              });
              this.data.forEach(element => {
                event.config.filterColumns.forEach((key:any) => {
                  if(Object.keys(element).includes(key)){
                    filtObjs[key].push(element[key])
                    this.multipleFilterArray[key] =(new Set(filtObjs[key]));
                  }
                });
              });
              event.config.multipleFilterArray = this.multipleFilterArray;
            }
          } else if(event.config.type === 'card'){ // SK20DEC23 assigning table data if response is an array array
            event.config.details.forEach((element:any) => {
              element.value = getData;             
            });
          } else if(event.config.type === 'multiCheck'){ // SK15FEB24 if response is an array 
            if(event.config.name === "activeCheckboxList"){
              parentData.config.activeCheckboxList = getData;
            } else if(event.config.name === "checkboxList"){
              parentData.config.checkboxList = getData;
            }            
          } else if(event.config.type === 'attendanceTable'){
            // SK22APR24 configuring attendance table
            event.config.employeeListAttendance = getData;            
          }
        }
      })
    }
  }

  modalClose(event:any){
    this.openModal = event;
    this.nxtJSON = {};
  }

  async nxtFormHandler(event: any) {
    // HA 12-01-2024 Due to change of incoming data we are eliminating the below commented logics
    // console.log('event ', event);
    // const initialResponse = event;
    // const bookletID:any = Object.keys(event.data.bookQuestionsMap);
    // let response = [];
    // response = event.data.bookQuestionsMap[bookletID[0]].Questions__r.records;
    // response.forEach((element:any) => {
    //   if(element.Reference_Field__c === "employeeId"){
    //     element.input = element.input.valueObj ? element.input.valueObj.employeeId  : element.input;
    //   } else if(element.Reference_Field__c === 'division' || element.Reference_Field__c === 'visitorType'){
    //     element.input = element.selectedValue || ''
    //   } else if(element.Reference_Field__c === 'parkingDate' || element.Reference_Field__c === 'date' || element.Reference_Field__c === 'visitDate' || element.Reference_Field__c === 'timeIn' || element.Reference_Field__c === 'timeOut'){
    //     element.input = element.input.value;
    //   } else if(element.Reference_Field__c === 'signature'){
    //     element.input = element?.input[0]?.doc;
    //   }
    // });
    // response.push({Reference_Field__c: 'companyId', input:this.globalValues.orgId});
    // let json : any = initialResponse.data;
    // HA17JAN24 Instead event.data passing the event so that the nxtId will also get passed along with event
    if (event.action) {
      if (event.action === 'close') { //For any modal close we need to give the action value close
        this.modalClose(false);
      } else if (event.action === 'save') { //SK12FEB24 if action is 'save', it is a patch call
        // SK12FEB24 if the patch has params, we will assign that details from config JSON
        // SK10MAY24 if the nxt field type is "List", processing the data as string from object
        const bookletID: any = Object.keys(event.jsonBook.bookQuestionsMap);
        let response = [];
        response = event.jsonBook.bookQuestionsMap[bookletID[0]].Questions__r.records;
        response.forEach((element: any) => {
          if (element.Type__c === "List") {
            const subText = JSON.parse(element.Sub_Text__c);
            if (typeof element.input === "object") {
              element.input = element.input.valueObj[subText.field];
              const filter = event.data.filter((respData: any) => respData.Reference_Field__c === element.Reference_Field__c);
              filter[0].input = element.input;
            }
          }
        });
        event.data.forEach((record: any) => {
          this.bookValue[record.Reference_Field__c] = record.input || "";
          this.modalProperties.paramsValue = this.bookValue[this.modalProperties.paramsKey];
        });
        let endPoint: any;
        if (this.modalProperties.isQueryParams) {
          endPoint = this.modalProperties.nxtAPI + this.modalProperties.paramsValue;
        } else {
          endPoint = this.modalProperties.nxtAPI;
        }
        // SK14MAY24 loader in calls
        // this.spinnerService.show();
        this.apiService.writeValue('patch', endPoint, this.bookValue)
          .subscribe({
            next: (savedData) => {
              // this.spinnerService.hide();
              this.saveSuccess(this.modalProperties.successMessage);
              // this.getData(this.tabInputs.config.apiCall, this.tabInputs.config.endpoint, this.tabInputs);
              this.currentTabNav(this.currentTab);// SK14FEB24, To load the current tab details in post/patch
              this.modalClose(false);
            },
            error: (err: HttpErrorResponse) => {
              // this.spinnerService.hide();
              this.showError(err);
            }
          });
      } else if (event.action === 'submit') { // if the action is 'submit', it will be post call
        // SK10MAY24 if the nxt field type is "List", processing the data as string from object
        const bookletID: any = Object.keys(event.jsonBook.bookQuestionsMap);
        let response = [];
        response = event.jsonBook.bookQuestionsMap[bookletID[0]].Questions__r.records;
        //SK30MAY24 find if any File type is present in data array
        const types = response.map((type: any) => type.Type__c);
        if (types.includes("File")) {
          for ( let element of response) {
            if (element.Type__c === "List") {
              const subText = JSON.parse(element.Sub_Text__c);
              if (typeof element.input === "object") {
                element.input = element.input.valueObj[subText.field];
                const filter = event.data.filter((respData: any) => respData.Reference_Field__c === element.Reference_Field__c);
                filter[0].input = element.input;
              }
            } else if (element.Type__c === "File") { //SK30MAY24 handling if the respoinse id File
              // if(element?.input){
              const fileLength = element?.input || [];
              let finalFileArray: any = [];
              for (let files of fileLength) { 
                const fileCurrent = files
                // SK21AUG24 updated post logic for file
                if (files.doc) {
                  const fileFormData = this.createFormData(fileCurrent.doc, fileCurrent.name, fileCurrent.type);
                  try {
                    const data = await this.apiService.uploadDataAsPromise('/file',fileFormData);
                    console.log('Fetched data:', data);
                    finalFileArray.push(data.result[0]);
                    // You can process the data here
                  } catch (error) {
                    console.error('Error fetching data from', '/file', error);
                  }
                } else {
                  finalFileArray.push(files);
                }
                element.input = finalFileArray;
                const filter = event.data.filter((respData: any) => respData.Reference_Field__c === element.Reference_Field__c);
                filter[0].input = element.input;
              };   
            }
          }
          this.apiService.writeValue('post', this.modalProperties.nxtAPI,  event).subscribe({
            next: (savedData) => {
              // this.spinnerService.hide();
              this.saveSuccess(this.modalProperties.successMessage);
              this.currentTabNav(this.currentTab);// SK14FEB24, To load the current tab details in post/patch
              this.modalClose(false);
            },
            error: (err: HttpErrorResponse) => {
              // this.spinnerService.hide();
              this.showError(err);
            }
          });
        } else {
          response.forEach((element: any) => {
            if (element.Type__c === "List") {
              const subText = JSON.parse(element.Sub_Text__c);
              if (typeof element.input === "object") {
                element.input = element.input.valueObj[subText.field];
                const filter = event.data.filter((respData: any) => respData.Reference_Field__c === element.Reference_Field__c);
                filter[0].input = element.input;
              }
            }
          });
          this.apiService.writeValue('post', this.modalProperties.nxtAPI, event).subscribe({
            next: (savedData) => {
              // this.spinnerService.hide();
              this.saveSuccess(this.modalProperties.successMessage);
              this.currentTabNav(this.currentTab);// SK14FEB24, To load the current tab details in post/patch
              this.modalClose(false);
            },
            error: (err: HttpErrorResponse) => {
              // this.spinnerService.hide();
              this.showError(err);
            }
          });
        }
      } else if (event.action === 'print') {
        // SK14MAY24 print image code
        // var strHtml = this.bookValue[event.actionData];
        // var WindowObject:any = window.open('', 'PrintWindow', 'width=1200,height=800,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        // WindowObject.document.writeln(strHtml);        
        // WindowObject.document.close();
        // WindowObject.focus();
        // WindowObject.print();
        // WindowObject.close();
        // SK10JUN24 sending the data to printer
        this.requestDevice(this.bookValue[event.actionData]);
      }
    }
  }

  // SK30MAY24 creating formData and returning it
  createFormData(base64: string, fileName: string, contentType: string): FormData {
    const formData = new FormData();
    const blob = this.base64ToBlob(base64, contentType);
    formData.append('files', blob, fileName);
    return formData;
  }

  // SK30MAY24 base64 converting base64 to formData 
  base64ToBlob(base64: string, contentType: string): Blob {
    base64 = base64.split(",")[1];
    const byteCharacters = atob(base64);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }

  nxtButtonHandler(event:any){
    this.openModal = true;
    const obj = JSON.parse(event.Sub_Text__c);
    this.commonButtonClickEmit(obj);
  }

  tableCheckedDataa(event:any){
    this.checkedList = [];
    this.checkedList = event;
  }

  downloadCsv(): void {
    const csv = this.convertToCsv(this.checkedList);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exportData.csv'; //SK06MAR24
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  convertToCsv(data: any[]): string {
    const headers = Object.keys(data[0]).join(',');
    const csvData = data.map(item => Object.values(item).join(',')).join('\n');
    return headers + '\n' + csvData;
  }

  // SK18JAN24 for hyperlink action emit
  hyperLinkColumnEmit(event:any){
    this.hyperLinkAction.forEach((element:any) => {
      this.modalProperties = element.modalProperties;
      if(event.column === element.name){
        // SK18JAN24 if action is browse, we need to open that record as separate tab, NEED TO IMPROVE FURTHER
        if(!element.dataGetProperties){
          var col = element;
          this.bookValue = event.element;
          if(element.action === 'openBrowse'){
            col = {
              "name":event.element[event.column], 
              "label":event.element[event.column], 
              "browsedTab":true
            }
            const tabBrowseContent = {tabBrowseContent:event.element};
            const filteredTab = this.browseTabs.tabs.filter((data:any)=> data.label === event.element[event.column]);// SK14MAY24
            if(filteredTab.length === 0){
              let obj = {...element,...col,...tabBrowseContent}
              if(obj?.config?.data) delete obj?.config?.data
              this.browseTabs.tabs.push(obj);
              this.browseTabs = this.deleteCompenentData(this.browseTabs);
              localStorage.setItem('browseTabs',JSON.stringify(this.browseTabs));
              // SK24JUN24 langcode bind on routing
              if(!this.skipRouting)this.router.navigateByUrl('app/'+this.languageCode || "en"+'/'+this.appName+'/'+this.moduleName+'/'+event.element[event.column]) //SK02FEB24
            }
            //SK31JAN24
            const selectedTab = {...element,...col,...tabBrowseContent};
            this.currentTabNav(selectedTab);
          }
        } else {
          var col = element;
          if(element.action === 'openBrowse'){
            col = {
              "name":event.element[event.column], 
              "label":event.element[event.column], 
              "browsedTab":true
            }
            // SK15JUL24 this property is to get the data from the api metadata
            const dataGetProperties = {dataGetProperties:element.dataGetProperties};
            const tabBrowseContent = {tabBrowseContent:event.element};
            const filteredTab = this.browseTabs.tabs.filter((data:any)=> data.label === event.element[event.column]);// SK14MAY24
            if(filteredTab.length === 0){
              this.browseTabs.tabs.push({...element,...col,...dataGetProperties});
              this.browseTabs = this.deleteCompenentData(this.browseTabs);
              localStorage.setItem('browseTabs',JSON.stringify(this.browseTabs));
              // SK24JUN24 langcode bind on routing
              if(!this.skipRouting)this.router.navigate([`${"app"}`, `${this.languageCode || "en"}`, `${this.appName}`, `${this.moduleName}`, `${event.element[event.column]}`]);//RS27AUG24
              // if(!this.skipRouting)this.router.navigateByUrl('app/'+this.languageCode || "en-US"+'/'+this.appName+'/'+this.moduleName+'/'+event.element[event.column]) //SK02FEB24
            }
            //SK31JAN24
            const selectedTab = {...element,...col,...dataGetProperties, ...tabBrowseContent};
            this.currentTabNav(selectedTab);
          }
        } 
      }
    });
  }

  closeBrowseTab(tabData:any, index:number){
    this.browseTabs.tabs.splice(index,1);
    localStorage.setItem('browseTabs', JSON.stringify(this.browseTabs));
    if(tabData.name === this.displayedTabContent){
      //SK31JAN24
      const selectedTab = this.browseTabs.tabs[index - 1];
      this.currentTabNav(selectedTab);
    }
  }

  // 30JAN24 on clicking edit icon, need further development
  onEditRecord(event:any){

  }

  //  on clicking delete icon, need further development
  onDeleteRecord(event:any){

  }

  // SK12FEB24 on edit the card component
  onClickEditCard(card:any, comp:any){
    this.nxtJSON = {};
    this.bookValue = card.data;
    // SK10MAY24
    card.editButtonActions.buttonType = "editRecord";
    if(card.isEditButton){
      this.openModal = true;
      this.modalProperties = card.editButtonActions;
      this.commonButtonClickEmit(this.modalProperties)
    } 
  }

  // SK12FEB24, passing the string and get the value from object like "employee.email" 
  getValue(element:any, column:string){
    let flds = column.split('.');
    for(let i=0; i<flds.length; i++){
      let splitFlds = flds[i].split('[');
      if(splitFlds.length === 1){
        element = element[flds[i]] || '' as any;
      } else {
        let index = Number(splitFlds[1].split(']')[0]);
        element = element[splitFlds[0]][index]  || '' as any; //SK 20DEC23 To solve undefined error
      }
    }
    return element;
  }

  // SK15FEB24 emitting the multi check value and sent to backend
  multiCheckEmit(event:any){
    if(event.button.buttonAction === "save"){
      event.compData.config.data
      let endPoint:any;
      if(event.button.isParams) {
        endPoint = event.button.path + event.compData.config.data[event.button.paramsKey];
      } else {
        endPoint = event.button.path;
      }
      // SK20FEB24 pathc the object for array and array of objects conditions
      if(typeof event.checkListArray[0] === "string"){
        event.compData.config.data[event.button.patchKey] = event.checkListArray;
      } else {
        // SK20FEB24 if array of objects. the the value key data will assigned in an array
        const list:any = [];
        event.checkListArray.forEach((checkedList:any) => {
          list.push(checkedList.value);
        });
        event.compData.config.data[event.button.patchKey] = list;
      }
      // SK14MAY24 loader in calls
      // this.spinnerService.show();
      this.apiService.writeValue(event.button.method, endPoint, event.compData.config.data)
      .subscribe({
        next: (savedData) => {
          // this.spinnerService.hide();
          this.saveSuccess(event.button.successMessage);
          this.currentTabNav(this.currentTab);// SK14FEB24, To load the current tab details in post/patch
        },
        error: (err: HttpErrorResponse) => {
          // this.spinnerService.hide();
          this.showError(err);
        }
      });
    }
  }

  filterKeys:any = {};
  // SK11MAY24 filter the table data based on keys checked
  searchFilterData(event:any){
    this.filterKeys[event[0]] = event[1];
    localStorage.setItem('tabfilter',JSON.stringify(this.filterKeys)) // MV24AUG24 stringify the filterkeys to tabfilter
    this.filterRetain(this.filterKeys)
   }
    // this.saveFilterState(); //MV21AUG24 Save filter state after applying filters
  //MV24AUG24 Retain the filterdata
   filterRetain(filterkey:any){

    if(Object.keys(filterkey).length==0){
      this.data= JSON.parse(this.finalData);
    }else{
    this.data = [];
    let isDataChecked = false;
    for(let key of Object.keys(filterkey)){
      if (filterkey[key] && filterkey[key].length > 0){
        isDataChecked = true;
      }
    }
    if(!isDataChecked){
      this.data = JSON.parse(this.finalData);
    } else {
      for(let key of Object.keys(filterkey)){
        if (filterkey[key]) {
        const data = JSON.parse(this.finalData).filter((name:any)=> filterkey[key]?.includes(name[key]));
        const data1 = [...this.data,...data];
        this.data = this.removeDuplicates(data1);
      }
    }
   }
  }
}

  // SK11MAY24 to remove duplicate object form two arrays
  removeDuplicates(objects: any) {
    const seen = new Set<string>();
    const uniqueObjects: any = [];

    objects.forEach((obj:any) => {
        const key = JSON.stringify(obj);
        if (!seen.has(key)) {
            seen.add(key);
            uniqueObjects.push(obj);
        }
    });
    return uniqueObjects;
  }

  // SK14MAY24 exporting csv, with the selceted fields
  // code to be optimised for values in objects export
  downloadCurrentEmployees(fields:Array<any>){
    const csv = this.convertToCsv2(this.checkedList, fields);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'exported_data.csv');
    this.saveSuccess('Data exported successfully')
  }

  // SK14MAY24 csv convert as comma separation
  convertToCsv2(objArray: any[], fields:any) {
    let filterteData: any[] = [];
    objArray.forEach(element => {
      let objs:any = {};
      // only selected fields from configuration is returned
      fields.forEach((key:any) => {
        if(!Object.keys(element).includes(key)){
          element[key] = "-";
        } else {
          objs[key] = this.getValue(element, key);

        }
      });
      filterteData.push(objs);
    });
    filterteData
    var selectedObjectsForCSV = this.filterObjectKeys(objArray, fields);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    let header:any = {};
    fields.forEach((head:any) => {
      header[head] = head;
    });
    array.unshift(header)
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        }
        if(typeof array[i][index] == 'object'){
          line += JSON.stringify(array[i][index]).replace(/,/g,"++");
        }
        else{
          // // SK14MAY24 id string has comma, add another string quotes
          if(String(array[i][index])?.includes(",")){
            array[i][index] = '"' + array[i][index] + '"';
          } else if(array[i][index] === "" || array[i][index] === null || array[i][index] === undefined){
            array[i][index] = "-";
          }
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 

  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) { // keys are the reuired fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undeifned
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }

  // SK10JUN24 getting the connected device details
  async requestDevice(dataPrint:any) {
    try {
      const device = await navigator.usb.requestDevice({ filters: [] });
      this.testPrint(device,dataPrint);
      console.log(device);
    } catch (e) {
      console.error(e);
    }
  }

  // SK10JUN24 encoding the data and sent to printer
  async  testPrint(device:any,dataPrint:any) {
    const encoder = new TextEncoder();
    const data = encoder.encode(dataPrint);
    await device.open();
    await device.selectConfiguration(1);
    await device.claimInterface(0);
    await device.transferOut(device.configuration.interfaces[0].alternate.endpoints.find((obj:any) => obj.direction === 'out').endpointNumber,data);
    await device.close();
  }
  // SK10AUG24 nxt questionnaire button answer
  handleQuestionnaireQuestion(event:any){
    const questionAnswer = {questionId: event.questionDetail.Id, questionName: event.questionDetail.Name, questionAnswer: event.questionDetail.input, assessmentStatus:event.answerBookDetail.Status__c, answerBookId:event.answerBookDetail.Id, assessmentId: this.tabName, assessmentStartTime: new Date().toUTCString(), originalAnswer:event.questionDetail.Tracking_ID__c }
    this.apiService.writeValue("post", "/hire/answers", questionAnswer).subscribe({
      next: (savedData) => {
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    })
  }

  // SK10AUG24 nxt submit button 
  handleQuestionnaireSubmit(event:any){
    const questionAnswer = {assessmentStatus:event.AnswerBook.Status__c, answerBookId:event.AnswerBook.Id, assessmentId: this.tabName, assessmentEndTime: new Date().toUTCString() }
    this.apiService.writeValue("patch", "/hire/assessment?status=true", questionAnswer).subscribe({
      next: (savedData) => {
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    })
  }

  // SK10AUG24
  handleQuestionnaireBook(event:any){
    const questionAnswer = {questionId: event.questionbook.Id, questionName: event.questionbook.Name, questionAnswer: event.questionbook.input, assessmentStatus:event.answerbook.Status__c, answerBookId:event.answerbook.Id, assessmentId: this.tabName, assessmentStartTime: new Date().toUTCString() }
    this.apiService.writeValue("patch", "/hire/assessment?status=true", questionAnswer).subscribe({
      next: (savedData) => {
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    })

    // const questionAnswer2 = {questionId: event.questionDetail.Id, questionName: event.questionDetail.Name, questionAnswer: event.questionDetail.input, assessmentStatus:event.answerBookDetail.Status__c, answerBookId:event.answerBookDetail.Id, assessmentId: this.tabName, assessmentStartTime: new Date().toUTCString() }
    // this.apiService.writeValue("patch", "/hire/assessment?status=true", questionAnswer2).subscribe({
    //   next: (savedData) => {
    //   },
    //   error: (err: HttpErrorResponse) => {
    //     this.showError(err);
    //   }
    // })
  }

  // SK13SEP24 calendar type month emit
  async handleCalendarDate(event:any){
    this.calendatMonth = event.year+"-"+String(event.month).padStart(2,"0");
    console.log("tabConfigProperties", this.tabConfigProperties)
    if(this.tabConfigProperties?.type && this.tabConfigProperties?.type == 'calendar'){
      let bVal = {"calendar":[]}
      this.bookValue = {};
      // SK13SEP24 fetching the data by passing the emitted month from API
      const calendarData = await this.apiService.fetchDataAsPromise(this.tabConfigProperties.dataGetProperties.endpoint+this.calendatMonth)
      if(this.tabConfigProperties.dataGetProperties.responseObject){
        bVal.calendar = calendarData[this.tabConfigProperties.dataGetProperties.responseObjectKey]
        this.bookValue = bVal;
        console.log("bVal", this.bookValue);
      } else if(this.tabConfigProperties.dataGetProperties.responseArray){
        bVal.calendar = calendarData;
        this.bookValue = bVal;
      }                
    }
  }

  // SK13SEP24 converting the data 
  // SK13SEP24 this should be converted in fusionsMap in nxtconfigutaions table
  handleCalendarEventDate(event:any){
    if(event.action === "add"){
      // let obj :any = {};
      // const keys = Object.keys(event.data.entries[0]);
      // for(let key of keys){
      //   obj[event.data.entries[0][key]["Reference_Field__c"]] = event.data.entries[0][key]["input"];
      // }
      // obj["editor"]="candidate";
        this.apiService.writeValue(this.tabConfigProperties.dataSaveProperties.apiCall, this.tabConfigProperties.dataSaveProperties.endpoint,event)
        .subscribe({
          next: (savedData:any) => {
            this.saveSuccess(savedData.message);
            this.modalClose(false);
          },
          error: (err: HttpErrorResponse) => {
            this.showError(err);
          }
        });
    } else if (event.action === "edit"){

    }
  }

  // SK27OCT24 resctricting data storing in local
  deleteCompenentData(tabs:any){
    tabs?.tabs?.forEach((tab:any)=>{
      if(tab?.components != undefined){
        tab?.components.forEach((comps:any) => {
          if(comps?.config?.type === ("tableData" || "table")){
            if(comps?.config?.data) delete comps.config.data
          }
        });
      } else {
        if(tab.config.type === ("tableData" || "table")){
          if(tab?.config?.data) delete tab.config.data
        }
      }
    })
    return tabs;
  }
}
