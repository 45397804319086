import { Component, ErrorHandler, EventEmitter, Input, OnInit, Output, isDevMode, ElementRef, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { PayrollTableComponent } from '../payroll-table/payroll-table.component';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AmountToWordsPipe } from 'src/app/pipes/amount-to-words.pipe';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { AlertService } from 'src/app/services/alert.service';
import { Modal } from 'bootstrap';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { LangChangeEvent, TranslateModule,TranslateService } from '@ngx-translate/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';


@Component({
  selector: 'app-payrollcompare',
  standalone: true,
  imports: [CommonModule, FusionButtonComponent, NgxPaginationModule, DataTableComponent, PayrollTableComponent,NgxSpinnerModule,TranslateModule,CustomTranslatePipe],
  templateUrl: './payrollcompare.component.html',
  styleUrls: ['./payrollcompare.component.css']
})

export class PayrollcompareComponent implements OnInit {
  // to receive a function from parent;
  @Input() tableArray!: Array<any>;
  @Input() allEmployeeData!: Array<any>;
  @Input() queryData!: Array<any>;
  @Input() cumulativeDataColumns!: Array<any>; //SK16JUL24 getting the columns to be sum
  @Output("callCompareTable") callCompareTable: EventEmitter<any> = new EventEmitter();
  @Output() emitUpdated: EventEmitter<any> = new EventEmitter();
  @Output() fromPayrollCompare: EventEmitter<any> = new EventEmitter();
  @Output() alertMessageEmit = new EventEmitter();
  @Output() errorMessageEmit = new EventEmitter();
  payrollcompare!: Array<any>;
  checkedEmployees!: Array<any>;
  confirmButton!: Array<any>;
  generatePay!: object;
  queryMonth: any;
  selectedData!: Array<any>;
  disableButton = true;
  payrollMonth:any;
  multipleFilterArray : any;
  selectedCheckbox!: Array<any>;
  isDevMode: boolean = false;
  modalDirect!: Modal; // bootstrap modal to open using function
  @ViewChild('payrollConfirmModal') payrollConfirmModal!: ElementRef<any>; //to open the bootstrap modal based on click function in ts file

  /** The data that to be sent to filter array in table th is assigned in this variable*/
  nameFilter: any; 
  empIdFilter: any;
  typeSelected = ''; //SK18JUN24 spinner tyoe

  constructor(private apiService: ApiService, private numWords: AmountToWordsPipe, private currencyPipe: CurrencyPipe, private alertService: AlertService, private errorHandler: ErrorHandlerService, private globalValues: GlobalValuesService, private datePipe: DatePipe, private spinnerService: NgxSpinnerService,private translate : TranslateService) { 
    this.isDevMode = isDevMode();
    this.typeSelected = 'ball-spin-clockwise-fade'; // loader type
  }

  ngOnInit(): void {
    this.payrollcompare = this.tableArray;

    const nameArray: any[] = [];
    const empIdArray: any[] = [];
    this.payrollcompare.forEach(element => {
      this.payrollMonth = element.payrollMonth;
    });

    //The array that to be passed to check filter in table header are declared and sent in another array
    this.multipleFilterArray = {
      empName: null,
      empId: null
    };

    // datta for filters in table th
    this.tableArray?.forEach((element:any) => {  
      nameArray.push(element.employeeName);
      empIdArray.push(element.employeeId);
    });

    //each filter array data to be sent separately as an array of objects with the same key name
    this.multipleFilterArray.employeeName = (new Set(nameArray));
    this.multipleFilterArray.employeeId = (new Set(empIdArray));
  }

  ngOnChanges() {
    this.payrollcompare = this.tableArray;
  }

  /**SK25JUL24 This function will convert a sting to number, for converting rupees to numbers */
  currencyToNumber(data:any){
    if(data && typeof data === 'string'){
      const removeComma =  Number(data?.replace(/[^0-9.-]+/g,""));
      return Number(removeComma);
    } else if(data && typeof data === 'number'){
      return Number(data);
    } {
      return 0
    }
  }

  // The selected checkbox data in data table, will be stored in this array
  tableClickData(event: any) {
    this.selectedData = event;
    this.buttonValidation();
  }

  /** button will be enabled only 
      Anu of the checkbox is checked in that table
      And the dropdown list should be selected
  * */ 
  buttonValidation() {
    this.disableButton = this.selectedData === undefined || this.selectedData?.length === 0;
  }

  // The data that to sent to API service will be in array of object form 
  generateData(event: any) {
    this.confirmButton = [];
    this.checkedEmployees = [];
    this.selectedData.forEach(element => {
      this.checkedEmployees?.push(element.employeeId);
      this.confirmButton?.push({
        "employeeDetails" : {
          "Employee Name": "",
          "Designation": "",
          "Department": "",
          "Work Location": "",
          "Date Of Joining": "",
          // "Pay Period": "",
          "Pay Date": this.datePipe.transform(new Date(), 'mediumDate'),
          "PF A/c Number": '',
          "UAN Number": '',
          "PAN Number": "",
        },
        "EARNINGS": {
        },
        "Gross Earnings": 0,
        "DEDUCTIONS": {
        },
        "Total Deductions": 0,
        "REIMBURSEMENTS": [],
        "Total Reimbursement": 0,
        "Paid Days": 0,
        "LOP": 0,
        "Net Pay": 0,
        "amountInText": "",
        "employeeId": element.employeeId,
        'Currency code':"",
        "compId": '',
        "companyId" : this.globalValues.orgId,
        "fusionId" : "",
        "cumulativeTDS": Array<any>
      })
    });

    this.selectedData.forEach(tableData => {
      const index = this.confirmButton.findIndex((index: any) => { return index.employeeId === tableData?.employeeId });
      const indexValue = this.confirmButton[index];
      if (index >= 0) {
        indexValue["employeeDetails"]["Employee Name"] = tableData?.employeeName;
        indexValue["employeeDetails"]["Designation"] = tableData?.designation;
        indexValue["employeeDetails"]["Department"] = tableData?.department;
        indexValue["employeeDetails"]["Date Of Joining"] = this.datePipe.transform(tableData?.doj, 'mediumDate');
        indexValue["employeeDetails"]["Work Location"] = tableData?.location;
        indexValue["employeeDetails"]["PF A/c Number"] = tableData?.pf;
        indexValue["employeeDetails"]["UAN Number"] = tableData?.uan;
        indexValue["employeeDetails"]["PAN Number"] = tableData?.pan;
        // indexValue["employeeDetails"]["Pay Period"] = new Date(tableData?.payrollStartDate)?.toDateString() + " to " + new Date(tableData?.payrollEndDate)?.toDateString(); // SK02SEP24 sent payroll dates
        indexValue["DEDUCTIONS"]["TDS"] = {"pay": tableData?.tds || 0, "ytd": '-' };
        // SK21AUG24 loan and advance amounts to deduct
        if(tableData?.advanceToDeduct) indexValue["DEDUCTIONS"]["Advance"] = {"pay": tableData?.advanceToDeduct };
        if(tableData?.loanToDeduct) indexValue["DEDUCTIONS"]["Loan"] = {"pay": tableData?.loanToDeduct };
        indexValue["cumulativeTDS"] = tableData?.cumulativeTDS;
        const mapped = Object.keys(tableData).map(key => ({type: key, value: tableData[key]}));
        mapped.forEach((deduct:any) => {
          const array =  deduct.type.split('_');
          const key = array.length - 1;
          if(array[key] === 'Deductions' || array[key] === 'Earnings' || array[key] === 'Allowances' ){
            if(array[key] === 'Deductions'){
              // SK17JAN24 to solve empty data error
              if(deduct?.value)indexValue['DEDUCTIONS'][array[key - 1]] = { "pay": (deduct?.value), "ytd": ((deduct?.value) * 12) }
            } else if (array[key] === 'Earnings' || array[key] === 'Allowances')  {
              if(deduct?.value)indexValue['EARNINGS'][array[key - 1]] = { "pay": (deduct?.value), "ytd": ((deduct?.value) * 12) }
            }
          }
        });
        indexValue['Gross Earnings'] = tableData?.earnings;
        indexValue['Total Deductions'] = tableData?.deductions;
        indexValue['Paid Days'] = (tableData?.noOfDaysInMonth) - (tableData?.lossOfPay);
        indexValue["Net Pay"] = tableData?.netPay;
        indexValue["amountInText"] = this.numWords.transform(this.currencyToNumber(tableData?.netPay)) + " " + "only";
        this.queryMonth = tableData?.queryMonth;
        indexValue['LOP'] = tableData?.lossOfPay;
        indexValue['Currency code'] = tableData?.currencyCode;
        indexValue['compId'] = tableData?.compId;   
        indexValue["fusionId"] = tableData?.fusionId;
        indexValue["lopPerDay"] = tableData?.lopPerDay;
        indexValue["lopAmount"] = tableData?.lopAmount;
        // SK21AUG24 loan and advance data for the payroll month
        indexValue["advanceToInactive"] = tableData?.activeAdvances;
        indexValue["loanToInactive"] = tableData?.activeLoans;
        indexValue["payrollStartDate"] = tableData?.payrollStartDate; // SK02SEP24
        indexValue["payrollEndDate"] = tableData?.payrollEndDate; // SK02SEP24
      }
    });

    this.queryData.forEach(querData => {
      const index = this.confirmButton.findIndex((index: any) => { return index.employeeId === querData.employeeId });
      const indexValue = this.confirmButton[index];
      if (index >= 0) {
        let totalReimbursement = 0;
        querData.expense.forEach((expenseData: any) => {
        if (expenseData.reImbursed === 'Yes') {
          const amount = expenseData.amount;
          indexValue['REIMBURSEMENTS'].push({ [expenseData.category]: this.currencyReturn(expenseData.amount, expenseData.currencyCode)  });
          totalReimbursement += amount;
        }
        });
        
        this.confirmButton[index]['Total Reimbursement'] = totalReimbursement;
        this.generatePay = { 'employeeData': this.confirmButton };
        }
    });
    // this.spinnerService.show();
    this.apiService.writeValue('post','/hrm/payroll?month='+this.queryMonth,this.generatePay)
      .subscribe({
        next: (data: any) => {
          // this.spinnerService.hide();
          setTimeout(() => {
            this.emitUpdated.emit({data:this.payrollMonth});
            this.fromPayrollCompare.emit(true);
          }, 1000);
          this.alertService.messageOnPass('success', 'Payroll generated successfully');
          if(this.isDevMode) console.log("Payroll Generate Success", data);
        },
        error: (error: HttpErrorResponse) => {
          // this.spinnerService.hide();
          this.errorHandler.handleError(error);
          this.errorHandler.errorMessage
          this.alertService.messageOnPass('error', this.errorHandler.errorMessage);
          if(this.isDevMode) console.log('Payroll Generate Error', error);
        }
      })
  }

  onCancel() {
    this.callCompareTable.emit();  // to use a parent function;
  }

  /**  fuunctions for search filter in table th. This functions finds the array of checked filter and returns that data in an new array.
        That array will be assigned to payroll table. If no filter checkbox is checked, It displays all data */
  searchFilterData(evt: any) {
    this.selectedCheckbox = [];
    // The filter that checked was stored in separate array for each column
    if(evt[0] === 'employeeName'){
      this.nameFilter = evt[1];
    }
    if(evt[0] === 'employeeId'){
      this.empIdFilter = evt[1];
    }
    this.filterChecked()
  }

  filterChecked(){
    // finding the checked filter in that data array and filtering that whole object from data array
    const employeeName = this.tableArray.filter((name:any)=> this.nameFilter?.includes(name.employeeName));
    const empId  = this.tableArray.filter((id:any)=> this.empIdFilter?.includes(id.employeeId))

    // If no check box is checked, whole data array will be passed to data table
    if(employeeName?.length === 0 && empId.length === 0){
      this.payrollcompare = this.tableArray;
    } else {
      // here the selected check box of each column will be combined in one array and passed to datatable 
      const finalArray = [...employeeName, ...empId];
      let uniqueObjects = new Set(finalArray.map(item => JSON.stringify(item))); // removing the duplicate objectes by stringfy and parse
      this.payrollcompare = Array.from(uniqueObjects).map(item => JSON.parse(item));
    }
    this.buttonValidation();
  }

  openConfirmModal(){
    this.modalDirect = new Modal(this.payrollConfirmModal.nativeElement, { backdrop: 'static' });
    this.modalDirect.show();
  }

  /*  this function will transform the number to currency, with specific currency code mentioned */
  currencyReturn(rupee: number, code:string){
    const langAndCurrency = [
        { "languageCode": "af-ZA", "currencyCode": "ZAR" },
        { "languageCode": "am-ET", "currencyCode": "ETB" },
        { "languageCode": "ar-AE", "currencyCode": "AED" },
        { "languageCode": "ar-SA", "currencyCode": "SAR" },
        { "languageCode": "ar-EG", "currencyCode": "EGP" },
        { "languageCode": "az-AZ", "currencyCode": "AZN" },
        { "languageCode": "be-BY", "currencyCode": "BYN" },
        { "languageCode": "bg-BG", "currencyCode": "BGN" },
        { "languageCode": "bn-BD", "currencyCode": "BDT" },
        { "languageCode": "bs-BA", "currencyCode": "BAM" },
        { "languageCode": "ca-ES", "currencyCode": "EUR" },
        { "languageCode": "cs-CZ", "currencyCode": "CZK" },
        { "languageCode": "da-DK", "currencyCode": "DKK" },
        { "languageCode": "de-DE", "currencyCode": "EUR" },
        { "languageCode": "el-GR", "currencyCode": "EUR" },
        { "languageCode": "en-GB", "currencyCode": "GBP" },
        { "languageCode": "en-IN", "currencyCode": "INR" },
        { "languageCode": "en-US", "currencyCode": "USD" },
        { "languageCode": "es-ES", "currencyCode": "EUR" },
        { "languageCode": "es-MX", "currencyCode": "MXN" },
        { "languageCode": "et-EE", "currencyCode": "EUR" },
        { "languageCode": "fa-IR", "currencyCode": "IRR" },
        { "languageCode": "fi-FI", "currencyCode": "EUR" },
        { "languageCode": "fr-FR", "currencyCode": "EUR" },
        { "languageCode": "he-IL", "currencyCode": "ILS" },
        { "languageCode": "hi-IN", "currencyCode": "INR" },
        { "languageCode": "hr-HR", "currencyCode": "EUR" },
        { "languageCode": "hu-HU", "currencyCode": "HUF" },
        { "languageCode": "hy-AM", "currencyCode": "AMD" },
        { "languageCode": "id-ID", "currencyCode": "IDR" },
        { "languageCode": "is-IS", "currencyCode": "ISK" },
        { "languageCode": "it-IT", "currencyCode": "EUR" },
        { "languageCode": "ja-JP", "currencyCode": "JPY" },
        { "languageCode": "ka-GE", "currencyCode": "GEL" },
        { "languageCode": "kk-KZ", "currencyCode": "KZT" },
        { "languageCode": "km-KH", "currencyCode": "KHR" },
        { "languageCode": "ko-KR", "currencyCode": "KRW" },
        { "languageCode": "ku-TR", "currencyCode": "TRY" },
        { "languageCode": "ky-KG", "currencyCode": "KGS" },
        { "languageCode": "lt-LT", "currencyCode": "EUR" },
        { "languageCode": "lv-LV", "currencyCode": "EUR" },
        { "languageCode": "mk-MK", "currencyCode": "MKD" },
        { "languageCode": "ml-IN", "currencyCode": "INR" },
        { "languageCode": "mn-MN", "currencyCode": "MNT" },
        { "languageCode": "ms-MY", "currencyCode": "MYR" },
        { "languageCode": "nb-NO", "currencyCode": "NOK" },
        { "languageCode": "nl-NL", "currencyCode": "EUR" },
        { "languageCode": "pl-PL", "currencyCode": "PLN" },
        { "languageCode": "pt-BR", "currencyCode": "BRL" },
        { "languageCode": "pt-PT", "currencyCode": "EUR" },
        { "languageCode": "ro-RO", "currencyCode": "RON" },
        { "languageCode": "ru-RU", "currencyCode": "RUB" },
        { "languageCode": "sk-SK", "currencyCode": "EUR" },
        { "languageCode": "sl-SI", "currencyCode": "EUR" },
        { "languageCode": "sq-AL", "currencyCode": "ALL" },
        { "languageCode": "sr-RS", "currencyCode": "RSD" },
        { "languageCode": "sv-SE", "currencyCode": "SEK" },
        { "languageCode": "ta-IN", "currencyCode": "INR" },
        { "languageCode": "te-IN", "currencyCode": "INR" },
        { "languageCode": "th-TH", "currencyCode": "THB" },
        { "languageCode": "tr-TR", "currencyCode": "TRY" },
        { "languageCode": "uk-UA", "currencyCode": "UAH" },
        { "languageCode": "ur-PK", "currencyCode": "PKR" },
        { "languageCode": "uz-UZ", "currencyCode": "UZS" },
        { "languageCode": "vi-VN", "currencyCode": "VND" },
        { "languageCode": "zh-CN", "currencyCode": "CNY" },
        { "languageCode": "zh-TW", "currencyCode": "TWD" }
    ]
    const findIndex = langAndCurrency.findIndex(currCode=>currCode.currencyCode === code);
    let localCode = "en-IN";
    if(findIndex > 0){
        localCode =  langAndCurrency[findIndex].languageCode;
    }

    let formatter = new Intl.NumberFormat(localCode, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    const amt =  formatter.format(rupee).replace(code, `${code} `);
    return `${code} ${amt}`;
  }
}
