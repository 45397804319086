import { Component, OnInit, ViewChild, isDevMode, ElementRef } from '@angular/core';
import { SideNavEmitService } from '../../services/side-nav-emit.service';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service'
import { EssComponent } from '../ess/ess.component'
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CommonModule, DatePipe } from '@angular/common';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { Modal } from 'bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import * as moment from 'moment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-leave-master',
  standalone: true,
  imports : [CommonModule,PageHeaderComponent,FormsModule,ReactiveFormsModule,NgSelectModule, EssComponent, SuccessFailureMessageComponent, FusionButtonComponent, DropDownComponent, DataTableComponent, AngularSvgIconModule, MatCheckboxModule, TranslateModule, CustomTranslatePipe],
  templateUrl: './leave-master.component.html',
  styleUrls: ['./leave-master.component.css']
})
export class LeaveMasterComponent implements OnInit {

  @ViewChild('leaveType') leaveType!: DropDownComponent; 
  @ViewChild('leaveCycle') leaveCycle!: DropDownComponent;
  @ViewChild('tempModalOpen') tempModalOpen! : ElementRef<any>;
  @ViewChild('holidayModalOpen') holidayModalOpen! : ElementRef<any>;
  @ViewChild('weekOffModalOpen') weekOffModalOpen! : ElementRef<any>;
  @ViewChild('weekDaysCheck') weekDaysCheck! : ElementRef<any>;
  

  // sideNavsubscription: Subscription;  /* hiding since moved to Company settings page as a component */
  // leavesTabToShow = 'leaves'; /* not needed for now */
  
  errorMessage : any;
  showErrorMsg:any;
  pageComponentName = 'LeaveMaster';

  templateForm!: FormGroup;
  holidayForm!: FormGroup;
  weekoffForm! : FormGroup;

  templateLeaveData:any;
  holidaysLeaveData:any;
  weekOffLeaveData:any;
  templateGet: any;
  leaveStartDate!: any;
  availDays:any;
  leaveEndDate!: any;
  startDayError = false;
  leaveError = false;
  showNoOfLeaves = false;
  noOfDays=0;
  dateDiffer:any;
  startingDay:any;
  endingDay:any;
  startDate1: any;
  endDate1: any;
  dates!: Array<any>;
  carryOverDays=0 //SKS16OCT24
  allDays=false  //SKS16OCT24
  selectedLeaveType = ""; //SK17JUL24
  selectedCarryOverDays =""  //SKS16OCT24
  leaveTypeListAdded: Array<any> = []; //SK17JUL24 to store the assigned leave type in template
  carryOverName: string = "Select";
  selectedTemplate: string = "Select";
   
  // leaveTypeValue = 'Select';
  leaveTypeList: Array<string> = [];
  leaveTypeObjList = [{name:"Casual leave", value:"Casual leave"}, {name:"Sick leave", value:"Sick leave"}, {name:"Maternity leave", value:"Maternity leave"}, {name:"Paternity leave", value:"Paternity leave"}, {name:"Marriage leave", value:"Marriage leave"}, {name:"Compensation Off", value:"Compensation Off"}, {name:"Loss of Pay", value:"Loss of Pay"}];
  carryOverDaysList = [  { id: "allDays", name: 'All Days' },{ id: "numberValue", name: 'Add Days' }];    //SKS16OCT24
  carryOverNamesList: string[] = []; //SKS16OCT24
  leaveCycleValue = 'Select';
  leaveCycleList = ['Monthly', 'Yearly'];
  maxDays: { [key: string]: number } = {
    'Casual leave': 12,
    'Sick leave': 12,
    'Maternity leave': 182,
    'Paternity leave': 15,
    'Marriage leave': 15,
    'Compensation Off': 12,
    'Loss of Pay': 99
  };
  
  weekDaysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  isDevMode: boolean = false;
  leaveMasterModal! : Modal;
  weekoffTableData:any;
  numberRegex = "/^-?\d+(\.\d+)?$/";
  textRegex = "^[a-zA-Z ]*$";
  selection: any;
  tempErrorMsg = false;
  holidayErrorMsg = false;
  tempCycleError = false;
  carryOverDayError = false;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  companyId = this.globalVal.orgId;
  createdName = this.globalVal.fullLoginName + " - " + this.globalVal.empId;
  templateHeaders : Array<any> = [];
  weekOffHeaders : Array<any> = [];
  defaultDropDownValue : any;

  Data: Array<any> = [];

  saveSuccess(){
    this.alertService.messageOnPass('success', this.successMsg);
    this.ngOnInit();
    this.errorMessage = '';
  };
  
  deleteSuccess() {
    this.alertService.messageOnPass('success', this.deletedMsg);
    this.ngOnInit();
    this.errorMessage = '';
  }

  showError(err:any){
    this.showErrorMsg = true;
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  constructor(private sideNavItem: SideNavEmitService, private fb: FormBuilder, private apiService: ApiService, private errorHandler: ErrorHandlerService, private globalVal: GlobalValuesService, private alertService: AlertService, private route: ActivatedRoute, private datePipe : DatePipe, private translate : TranslateService) { 
    this.isDevMode = isDevMode();
    this.globalVal.laguageChanges$.subscribe(lang => {
      this.translateWeekDays();
      this.defaultValueFunction();
      this.templateHeadersValues();
      this.weekOffHeadersValues();
      this.templateLeaveTypeList();
      this.leaveCycleListFunction();
    }); 
    /* hiden temporarily as we have modified the nav tab functionalities and also have made few changes to retain the page on reload */
    this.carryOverNamesList = this.carryOverDaysList.map(day => day.name); //SKS16OCT24 convert obj to array for dropdown list
    this.leaveTypeList =  this.leaveTypeObjList.map(lst => lst.name);
    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    /* this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => this.leavesTabToShow = navItem); */ /* hiding since moved to Company settings page as a component */
  }

  translateWeekDays(){
    this.translate.get('WEEK_DAYS').subscribe((data:any)=> {
      this.Data = [
        { name: data.MONDAY, value: "Monday"},
        { name: data.TUESDAY, value: "Tuesday"},
        { name: data.WEDNESDAY, value: "Wednesday"},
        { name: data.THURSDAY, value: "Thursday"},
        { name: data.FRIDAY, value: "Friday"},
        { name: data.SATURDAY, value: "Saturday"},
        { name: data.SUNDAY, value: "Sunday"}
      ];
    });
  }

  templateHeadersValues(){
    const list = ['LEAVE_TYPE', 'LEAVE_CYCLE', 'TOTAL_DAYS', 'START_DATE', 'END_DATE', 'DEFAULT', 'ACTIVE', 'PAID_LEAVE', 'ACTIONS'];
    this.returnValue(list, 'templateHeaders', 'array')
  }

  weekOffHeadersValues(){
    const list = ['LEAVE_TYPE', 'START_DATE', 'END_DATE', 'WEEK_DAYS.MONDAY', 'WEEK_DAYS.TUESDAY', 'WEEK_DAYS.WEDNESDAY', 'WEEK_DAYS.THURSDAY', 'WEEK_DAYS.FRIDAY', 'WEEK_DAYS.SATURDAY', 'WEEK_DAYS.SUNDAY', 'DEFAULT', 'PAID_LEAVE', 'ACTIONS'];
    this.returnValue(list, 'weekOffHeaders', 'array')
  }

  templateLeaveTypeList(){
    const list = ['CASUAL_LEAVE', 'SICK_LEAVE', 'EARNED_LEAVE', 'MATERNITY_LEAVE', 'PATERNITY_LEAVE', 'LOSS_OF_PAY', 'COMPENSATION_OFF'];
    this.returnValue(list, 'leaveTypeList', 'array')
  }

  leaveCycleListFunction(){
    const list = ['MONTHLY', 'YEARLY'];
    this.returnValue(list, 'leaveCycleList', 'array')
  }

  defaultValueFunction(){
    const list = ['DEFAULT_DROPDOWN_VALUE']
    this.returnValue(list, 'defaultDropDownValue', 'string')
  }

  returnValue(array:any, variable:any, type:any){
    type === 'array' ? (this as any)[variable] = [] : (this as any)[variable] = '';
    array.forEach((element:any) => {
      this.translate.get(element).subscribe((keyValue) => {
        if(type === 'array'){
          (this as any)[variable].push(keyValue);
        } else if (type === 'string'){
          (this as any)[variable] = keyValue;
        }
      });
    });
  }

  emittedValue(event:any){
    this.selectedLeaveType = event.data; //SK17JUL24
    //In order to make the formname dynamic we converted the type to any
    const ele:any = this.leaveTypeObjList.find(val=>val.name === event.data);
    let formName = event.event.target.closest('form').getAttribute('id');
    let controlName = event.controlName;
    if(ele){
      (this as any)[formName].controls[controlName].setValue(ele.value);
      this.selectedTemplate = ele.name;
    } else {
      (this as any)[formName].controls[controlName].setValue(event.data);
    }
    
  }
  //SKS16OCT24
  emittedCarryOverDays(event:any){
    this.selectedCarryOverDays = event.data;
    const ele:any = this.carryOverDaysList.find(val=>val.name === event.data);
    let formName = event.event.target.closest('form').getAttribute('id');
    let controlName = event.controlName;
    if(ele.id === "allDays"){
      (this as any)[formName].controls[controlName].setValue(ele.id);
      this.carryOverName = ele.name;
    }
    //In order to make the formname dynamic we converted the type to any
    // (this as any)[formName].controls[controlName].setValue(event.data);
  }
  
  ngOnInit(): void {
    this.defaultValueFunction();
    this.templateFormDeclaration();
    this.holidayFormDeclaration();
    this.weekOffFormDeclaration();
    this.selection = new SelectionModel<any>(true, []);
    this.leaveTypeListAdded = []; //SK17JUL24
    this.apiService.fetchValue('/leavemaster').subscribe((data:any) =>{
      if(this.isDevMode) console.log('GET LEAVE MASTER',data);

      this.templateLeaveData = data.template;
      this.templateLeaveData.forEach((leavedata:any) => {
        this.leaveTypeListAdded.push(leavedata.leaveType);
        leavedata.tableStartDate = this.datePipe.transform(leavedata.startDate, 'mediumDate');
        leavedata.tableEndDate = this.datePipe.transform(leavedata.endDate, 'mediumDate');
      });
      const setArray = [...this.leaveTypeList,...this.leaveTypeListAdded, 'Add Other'] //SK17JUL24
      this.leaveTypeList = (new Set(setArray)) as any; //SK17JUL24

      this.holidaysLeaveData = data.holidays;
      this.holidaysLeaveData.forEach((leavedata:any) => {
        leavedata.tableStartDate = this.datePipe.transform(leavedata.startDate, 'mediumDate');
        leavedata.tableEndDate = this.datePipe.transform(leavedata.endDate, 'mediumDate');
      });

      this.weekoffTableData = [];
      this.weekOffLeaveData = data.weekoff;
      this.weekOffLeaveData.forEach((elementWeekOff:any) => {
        const days: any[] = [];
        elementWeekOff.weekoff.forEach((datum:any) => {
          days.push(datum.value);
        });
        this.weekoffTableData.push({
          leaveType:elementWeekOff.leaveType,
          startDate: this.datePipe.transform(elementWeekOff.startDate, 'mediumDate'),
          endDate: this.datePipe.transform(elementWeekOff.endDate, 'mediumDate'),
          monday: days.includes('Monday') ? 'Week Off' : 'Default',
          tuesday: days.includes('Tuesday') ? 'Week Off' : 'Default',
          wednesday: days.includes('Wednesday') ? 'Week Off' : 'Default',
          thursday: days.includes('Thursday') ? 'Week Off' : 'Default',
          friday: days.includes('Friday') ? 'Week Off' : 'Default',
          saturday: days.includes('Saturday') ? 'Week Off' : 'Default',
          sunday: days.includes('Sunday') ? 'Week Off' : 'Default',
          default: elementWeekOff.default,
          active:elementWeekOff.active,
          paidLeave: elementWeekOff.paidLeave,
          _id: elementWeekOff._id
        })
      });
    });

    if(this.isDevMode) console.log(this.globalVal.fullLoginName);
    if(this.isDevMode) console.log(this.globalVal.empId);
    if(this.isDevMode) console.log(this.globalVal.compFullName); 
    this.translateWeekDays();
      this.defaultValueFunction();
      this.templateHeadersValues();
      this.weekOffHeadersValues();
      this.templateLeaveTypeList();
      this.leaveCycleListFunction();
  }

  // emit the check box values, that selected separately
  separateRowSelect(data: any) {
    this.weekoffForm.value.weekoff =  this.selection.selected;
  }

  templateFormDeclaration(){
    this.carryOverName = "Select";
    this.selectedTemplate = "Select";
    this.tempErrorMsg = false;
    this.tempCycleError = false;
    this.carryOverDayError = false;
    this.templateForm = this.fb.group({
      'leaveType' : ['Select', this.selectValidator()],
      'leaveCycle' : ['Select', this.selectValidator()],
      'noOfDays' : ['', Validators.compose([Validators.required])],
      'startDate' : ['', Validators.required],
      'endDate' : ['', Validators.required],
      'Default' : [false],
      'active' : [false],
      'paidLeave' : [false],
      'templateID': [null],
      'createdBy': [this.createdName],
      'updatedBy': [this.createdName],
      'employeeId': [this.globalVal.empId],
      'companyName': [this.globalVal.compFullName],
      'carryOverDays': ['Select', this.selectValidator()] //SKS16OCT24
    });
  }

  holidayFormDeclaration(){
    this.holidayErrorMsg = false;
    this.holidayForm = this.fb.group({
      'leaveType' : ['', Validators.required],
      'leaveCycle' : ['Select', this.selectValidator()],
      'noOfDays' : ['', Validators.compose([Validators.required])],
      'startDate' : ['', Validators.required],
      'endDate' : ['', Validators.required],
      'Default' : [false],
      'active' : [false],
      'paidLeave' : [false],
      'holidayID': [null],
      'createdBy': [this.createdName],
      'updatedBy': [this.createdName],
      'employeeId': [this.globalVal.empId],
      'companyName': [this.globalVal.compFullName]
    });
  }

  weekOffFormDeclaration(){    
    this.weekoffForm = this.fb.group({
      'leaveType' : ['', Validators.required],
      'startDate' : ['', Validators.required],
      'endDate' : ['', Validators.required],
      'weekoff': [[]],
      'Default' : [false],
      'active' : [false],
      'paidLeave' : [false],
      'weekOffID': [null],
      'createdBy': [this.createdName],
      'updatedBy': [this.createdName],
      'employeeId': [this.globalVal.empId],
      'companyName': [this.globalVal.compFullName]
    });
  }

  // check if the dropdown values has 'Select' in all the forms
  selectValidator() {
    return (control:any) => {
      const value = control.value;
      const isValid = value !== 'Select';
      return isValid ? null : { invalidSelect: true };
    };
  }

  onEditTemplate(id:any){
    let element = this.templateLeaveData.find((p: any) => {return p._id === id.data});
    this.leaveMasterModal = new Modal(this.tempModalOpen.nativeElement, { backdrop: 'static' });
    this.leaveMasterModal.show();
    this.templateForm.patchValue({
      'leaveType' : element?.leaveType,
      'leaveCycle' : element?.leaveCycle,
      'noOfDays' : element?.noOfDays,
      'startDate' : moment(element?.startDate).format('YYYY-MM-DD'),
      'endDate' : moment(element?.endDate).format('YYYY-MM-DD'),
      'Default' : element?.default,
      'active' : element?.active,
      'paidLeave' : element?.paidLeave,
      'templateID': element?._id,
      'createdBy': this.createdName,
      'updatedBy': this.createdName,
      'employeeId': this.globalVal.empId,
      'companyName': element?.companyName,
      'carryOverDays': element?.carryOverDays //SKS16OCT24
    })
    const ele:any = this.carryOverDaysList.find(val=>val.id === element?.carryOverDays);
    if(ele?.id === "allDays"){
      this.carryOverName = ele.name;
    } else {
      this.carryOverName = element?.carryOverDays;
    }

    const elem:any = this.leaveTypeObjList.find(val=>val.name === element?.leaveType);
    if(elem)this.selectedTemplate = elem.name;
  }

  deleteTemplate(deleteTemplateId:any){
    // SK27FEB24 changed since updated code on delete in datatable
    let element = this.templateLeaveData.find((p: any) => {return p._id === deleteTemplateId.data});
   
    this.apiService.writeValue('delete','/leavemaster/template',{'body': { 'id': element._id, 'companyName': element.companyName, 'companyId' : this.companyId}}).subscribe({
      next: () => {
        this.deleteSuccess();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }

  onEditHoliday(id:any){
    let element = this.holidaysLeaveData.find((p: any) => {return p._id === id.data});
    this.leaveMasterModal = new Modal(this.holidayModalOpen.nativeElement, { backdrop: 'static' });
    this.leaveMasterModal.show();
    this.holidayForm.patchValue({
      'leaveType' : element?.leaveType,
      'leaveCycle' : element?.leaveCycle,
      'noOfDays' : element?.noOfDays,
      'startDate' : moment(element?.startDate).format('YYYY-MM-DD'),
      'endDate' : moment(element?.endDate).format('YYYY-MM-DD'),
      'Default' : element?.default,
      'active' : element?.active,
      'paidLeave' : element?.paidLeave,
      'holidayID': element?._id,
      'createdBy': this.createdName,
      'updatedBy': this.createdName,
      'employeeId': this.globalVal.empId,
      'companyName': element?.companyName
    })
  }

  deleteHoliday(id:any){
    // SK27FEB24 changed since updated code on delete in datatable
    let element = this.holidaysLeaveData.find((p: any) => {return p._id === id.data});
    let deleteID = element._id;
    let companyName = element._name;
    
    this.apiService.writeValue('delete', '/leavemaster/holidays', { body: { 'id': deleteID, 'companyName': companyName, 'companyId' : this.companyId}} ).subscribe({
      next: () => {
        this.deleteSuccess();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }

  onEditWeekOff(id:any){
    let element = this.weekOffLeaveData.find((p: any) => {return p._id === id.data});
    this.leaveMasterModal = new Modal(this.weekOffModalOpen.nativeElement, { backdrop: 'static' });
    this.leaveMasterModal.show();
    const days: any[] = [];
    element.weekoff.forEach((apiData:any) => {
      days.push(apiData.value);
    });
    this.selection.clear();
    this.Data.forEach((checkedDays:any) => {
      if(days.includes(checkedDays.value)){
        this.selection.select(checkedDays)
      }
    });

    // this.Data.forEach(row => this.selection.select(row));
    this.weekoffForm.patchValue({
      'leaveType' : element?.leaveType,
      'startDate' : moment(element?.startDate).format('YYYY-MM-DD'),
      'endDate' : moment(element?.endDate).format('YYYY-MM-DD'),
      'weekoff': element?.weekoff,
      'Default' : element?.default,
      'active' : element?.active,
      'paidLeave' : element?.paidLeave,
      'weekOffID': element?._id,
      'createdBy': this.createdName,
      'updatedBy': this.createdName,
      'employeeId': this.globalVal.empId,
      'companyName': element?.companyName
    })
  }

  deleteWeekOff(id:any){
    // SK27FEB24 changed since updated code on delete in datatable
    let element = this.weekOffLeaveData.find((p: any) => {return p._id === id.data});
    let deleteID = element._id;
    let companyName = element._name;

    this.apiService.writeValue('delete','/leavemaster/weekoff',{body: { 'id': deleteID, 'companyName': companyName, 'companyId' : this.companyId}}).subscribe({
      next: () => {
        this.deleteSuccess();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }
  // SK19JUL24 changing date format for all template, holidays, weekoff dates
  saveTemplate(){
    if(this.isDevMode) console.log("Save Template");
    const value = this.templateForm.value;
    if(value.templateID != null){
      this.apiService.writeValue('patch','/leavemaster/template',{'leaveType': value.leaveType, 'leaveCycle': value.leaveCycle, 'noOfDays': value.noOfDays, 'startDate': moment( value.startDate).format('YYYY-MM-DD'), 'endDate': moment( value.endDate).format('YYYY-MM-DD'), 'Default': value.Default, 'active': value.active, 'paidLeave': value.paidLeave, 'id': value.templateID, 'createdBy': value.createdBy, 'updatedBy': value.updatedBy, 'employeeId': value.employeeId, 'companyId' : this.companyId, 'carryOverDays': value.carryOverDays})
      .subscribe({
        next: (template) => {
          if(this.isDevMode) console.log("patch template :", template);
          this.saveSuccess();
          
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    } else {
      this.apiService.writeValue('post','/leavemaster/template',{'leaveType': value.leaveType, 'leaveCycle': value.leaveCycle, 'noOfDays': value.noOfDays, 'startDate': moment( value.startDate).format('YYYY-MM-DD'), 'endDate': moment( value.endDate).format('YYYY-MM-DD'),'Default': value.Default,'active': value.active,'paidLeave': value.paidLeave, 'createdBy': value.createdBy, 'updatedBy': value.updatedBy, 'employeeId': value.employeeId, 'companyId' : this.companyId, 'carryOverDays': value.carryOverDays})
      .subscribe({
        next: (template) => {
          if(this.isDevMode) console.log("save template :", template);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    }
  }

  saveHoliday(){
    if(this.isDevMode) console.log(" Holiday");
    const value = this.holidayForm.value;
    if(value.holidayID != null){
      this.apiService.writeValue('patch', '/leavemaster/holidays', { 'leaveType': value.leaveType, 'leaveCycle': value.leaveCycle, 'noOfDays': value.noOfDays, 'startDate': moment( value.startDate).format('YYYY-MM-DD'), 'endDate': moment( value.endDate).format('YYYY-MM-DD'), 'Default': value.Default, 'active': value.active, 'paidLeave': value.paidLeave, 'id': value.holidayID, 'createdBy': value.createdBy, 'updatedBy': value.updatedBy, 'employeeId': value.employeeId, 'companyId' : this.companyId })
      .subscribe({
        next: (holiday) => {
          if(this.isDevMode) console.log("patch holiday :", holiday);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    } else {
      this.apiService.writeValue('post', '/leavemaster/holidays', { 'leaveType': value.leaveType, 'leaveCycle': value.leaveCycle, 'noOfDays': value.noOfDays, 'startDate': moment( value.startDate).format('YYYY-MM-DD'), 'endDate': moment( value.endDate).format('YYYY-MM-DD'), 'Default': value.Default, 'active': value.active, 'paidLeave': value.paidLeave, 'createdBy': value.createdBy, 'updatedBy': value.updatedBy, 'employeeId': value.employeeId, 'companyId' : this.companyId })
      .subscribe({
        next: (holiday) => {
          if(this.isDevMode) console.log("save holiday :", holiday);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    }
  }

  saveWeekoff(){
    const value = this.weekoffForm.value;
    if(value.weekOffID != null){
      this.apiService.writeValue('patch', '/leavemaster/weekoff', { 'leaveType': value.leaveType, 'startDate': moment( value.startDate).format('YYYY-MM-DD'), 'endDate': moment( value.endDate).format('YYYY-MM-DD'), 'weekoff': this.selection.selected, 'Default': value.Default,'active': value.active,'paidLeave': value.paidLeave, 'id': value.weekOffID, 'createdBy': value.createdBy, 'updatedBy': value.updatedBy, 'employeeId': value.employeeId, 'companyId' : this.companyId })
      .subscribe({
        next: (weekoff) => {
          if(this.isDevMode) console.log("weekoff :", weekoff);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    } else {
        this.apiService.writeValue('post', '/leavemaster/weekoff', { 'leaveType': value.leaveType, 'startDate': moment( value.startDate).format('YYYY-MM-DD'), 'endDate': moment( value.endDate).format('YYYY-MM-DD'), 'weekoff': this.selection.selected, 'Default': value.Default, 'active': value.active,'paidLeave': value.paidLeave, 'createdBy': value.createdBy, 'updatedBy': value.updatedBy, 'employeeId': value.employeeId, 'companyId' : this.companyId })
        .subscribe({
        next: (weekoff) => {
          if(this.isDevMode) console.log("weekoff :", weekoff);
          this.saveSuccess();
          this.Data.forEach(element => {
            element.selected = false;
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    }
  }
  leaveCalc(){
    this.startingDay = new Date(this.leaveStartDate);
    this.endingDay = new Date (this.leaveEndDate);
    this.startDate1 = Math.floor((Date.UTC(this.endingDay?.getFullYear(),  this.endingDay?.getMonth(), this.endingDay?.getDate())));
    this.endDate1 =  Math.floor((Date.UTC(this.startingDay?.getFullYear(), this.startingDay?.getMonth(), this.startingDay?.getDate()) ));
    this.dateDiffer = (this.startDate1 - this.endDate1) / (1000 * 60 * 60 * 24); //finding the difference between selected dates
     this.dateError();
    this.showNoOfLeaves = !this.startDayError;
  }
  //displays error when end date is lesser than start date
  dateError() {
    this.startDayError = this.endingDay - this.startingDay < 0
  }
 
  startDate(event: any) {
    this.leaveStartDate = event.target.value;
  }

  endDate(event: any) {
    this.leaveEndDate = event.target.value;
  } 
 
  emitDropDownError(event:any, name:any){
    if(name === 'tempLeaveType'){
      this.tempErrorMsg = event;
    } else if (name === 'tempLeaveCycle'){
      this.tempCycleError = event;
    } else if (name === 'tempLeaveCarryOver'){
      this.carryOverDayError = event;
    }else {
      this.holidayErrorMsg = event;
    }
    
  }
  
  resetForm() {
    this.ngOnInit();
    this.leaveStartDate = NaN;
    this.leaveEndDate = NaN;
    this.startDayError = false;
    this.leaveError = false;
    this.showNoOfLeaves = false;
    this.startDate1 = null;
    this.endDate1 = null;
    this.dates=[];
    this.startingDay = null;
    this.endingDay = null;
    this.dateDiffer = null;
  
  }
}

