<div [ngSwitch]="switchScreens">
    <div *ngSwitchCase="selectFY">
        <div class="d-flex">
            <span class="error-msg mt-0 text-secondary">
                <svg-icon style="filter: grayscale(1);" src="../assets/images/icons/general/warning icon.svg" ></svg-icon>
                {{ 'FINANCIAL_YEAR' | customTranslate : 'Please select the financial year' }}
            </span>
        </div>
    </div>
    <div *ngSwitchCase="editScreen">
        <div *ngIf="!compareTax">
            <div class="fc-tab-view card inner-tab mt-4 w-85">
                <div class="m-2">
                  <div class="d-flex flex-column">
                    <div class="flex flex-direction-row fc-tab-buttons">
                      <ul class="nav nav-tabs fc-tabs" id="sectionsESSTabs" role="tablist">
                        <li class="nav-item d-flex" role="presentation">
                          <button class="nav-link active" [class.active]="sectionFilterIndex === i" id="tax-ess-component" data-bs-toggle="tab" data-bs-target="#tax-ess-component-tab-pane"
                            type="button" role="tab" aria-controls="tax-ess-component-tab-pane" aria-selected="false" (shown.bs.tab)="sectionFilterIndex = i;filterTableData(eachTypeOfComponent, 'tax')" *ngFor="let eachTypeOfComponent of sectionTypes; let i=index">{{eachTypeOfComponent}}</button>
                        </li>
                      </ul>
                    </div>
                    <div class="ms-3 me-3">
                        <div class="card">
                            <div class="tax-header d-flex flex-row justify-content-between ps-3 pe-3 pt-2 pb-2">
                                <div>{{ 'SEC_NAME' | customTranslate : 'Section Name' }} : {{filteredTab}} ( {{ 'MAX_LIMIT' | customTranslate : 'Maximum Limit' }}: {{secMaxLimit}} )</div>
                                <div>{{ 'TOTAL_DECLARED_AMOUNT' | customTranslate : 'Total Declared Amount' }}: {{declaredAmount}}</div>
                            </div>
                            <div *ngIf="exceedLimitWarn" class="d-flex justify-content-end pe-3 pt-2 align-items-center">
                                <span class="error-msg mt-0">
                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>
                                    {{'SEC_LIMIT' | customTranslate : 'The declared amount exceeds the section limit'  }}
                                </span>
                            </div>
                            <div *ngFor="let list of declarationList; let i=index">
                                <div *ngIf="list.section === filteredTab" >
                                    <div *ngIf="list.section === hra" class="tax-body d-flex flex-row justify-content-between ms-3 me-3 mb-4 mt-3">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex flex-row">
                                                <div class="d-flex flex-column">
                                                    <label class="form-label label-txt ">{{ 'FROM_DATE' | customTranslate : 'From Date' }}</label>
                                                    <input class="form-control" type="date" max="9999-12-31" [value]="list.rentFrom" placeholder="Select the date" (change)="hraData($event, 'rentFrom', i, list)">                                
                                                </div>
                                                <div class="d-flex flex-column ms-4">
                                                    <label class="form-label label-txt">{{ 'TO_DATE' | customTranslate : 'To Date' }}</label>
                                                    <input class="form-control" type="date" max="9999-12-31" [min]="list.rentFrom" [value]="list.rentTo" placeholder="Select the date" (change)="hraData($event, 'rentTo', i, list)">
                                                </div>
                                            </div>
                                            <div class="d-flex" [style]="list.hraDateValidation ? 'visibility: visible' : 'visibility: hidden'">
                                                <span class="error-msg mt-0">
                                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>
                                                    {{ 'VALIDATION.VALID_DATE' | customTranslate : 'Select valid from date' }}
                                                </span>
                                            </div>
                                            <div class="d-flex flex-column mt-4">
                                                <label class="form-label label-txt ">{{ 'ADDRESS.ADDRESS' | customTranslate : 'Address' }}</label>
                                                <textarea class="form-control" placeholder="Enter text" [value]="list.address" (keyup)="textInput($event, list, i, 'address')"></textarea>                               
                                            </div>
                                            <div class="d-flex flex-column mt-4">
                                                <label class="form-label label-txt ">{{ 'LAND_LORDNAME' | customTranslate : 'Land Lord's Name' }}</label>
                                                <input class="form-control" type="text" placeholder="" [value]="list.landLordName" (keyup)="textInput($event, list, i, 'landLordName')">                                
                                            </div>
                                            <div class="d-flex flex-row mt-4">
                                                <div class="d-flex flex-column">
                                                    <label class="form-label label-txt ">{{'LIVING_AREA' | translate }}</label>
                                                    <app-drop-down [selectedData]="list.livingArea" [lists]="['Metro' | translate, 'Non-Metro' | translate]" dropDownHeight="37px" dropDownWidth="200px" (dataEmit)="dropdownEmitFunction($event, list, i, 'livingArea')"></app-drop-down>
                                                </div>
                                                <div class="d-flex flex-column ms-4">
                                                    <label class="form-label label-txt">{{ 'LAND_PAN' | customTranslate : 'Landlord's PAN' }}
                                                        <svg-icon matTooltip="PAN Card must be mandatory. If rent is more than ₹1 Lakh per year." src="../assets/images/icons/general/info.svg" ></svg-icon>
                                                    </label>
                                                    <input class="form-control" type="text" max="9999-12-31" placeholder="" [value]="list.landLordPan" (keyup)="textInput($event, list, i, 'landLordPan')">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row">
                                            <div>
                                                <label class="form-label label-txt">{{ 'AMOUNT_YEAR' | customTranslate : 'Amount / year' }}</label>
                                                <div class="d-flex flex-row">
                                                    <input type="text" class="form-control" tabindex="2" placeholder="" [value]="list.amount" (keyup)="textInput($event, list, i, 'amount')">
                                                    <svg-icon src="../assets/images/icons/general/close-circle-line.svg" (click)="removeTaxField(list, i)" class="ms-2 align-self-center cursor-pointer"></svg-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="list.section != hra">
                                        <div class="tax-body d-flex flex-row justify-content-between ms-3 me-3 mb-4 mt-3">
                                            <div>
                                                <label class="form-label label-txt">{{ 'SELECT_INVESTMENT' | customTranslate : 'Select an Investment' }}</label>
                                                <ng-container *ngFor="let item of dropDownList">
                                                <app-drop-down [isDisabled]="!list.editable" [selectedData]="item | customTranslate : item" [lists]="dropDownList" dropDownHeight="37px" dropDownWidth="300px" (dataEmit)="dropdownEmitFunction($event, list, i, 'name')"></app-drop-down>
                                            </ng-container>
                                                <label  class="form-label label-txt" [class]="list.maxAmount ? 'visible' : 'invisible' ">{{'MAX_AMOUNT' | customTranslate : 'Maximum Amount'  }} : {{list.maxAmount}} {{list.condition ? ', ' + list.condition : ''}}</label>
                                                <div class="d-flex" [style]="list.duplicateName ? 'visibility: visible' : 'visibility: hidden'">
                                                    <span class="error-msg mt-0">
                                                        <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>
                                                        {{ 'SELECTED_ALREADY' | customTranslate : 'This investment type is already selected' }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-row">
                                                <div>
                                                    <label class="form-label label-txt">{{ 'DECLARED_AMOUNT' | customTranslate : 'Declared Amount' }}</label>
                                                    <div class="d-flex flex-row">
                                                        <input  type="text" class="form-control" [class]="!list.editable ? 'investment-background' : '' " tabindex="2" placeholder="" [value]="list.amount" (keyup)="textInput($event, list, i, 'amount')" [readonly]="!list.editable">
                                                        <svg-icon [style]="list.editable ? 'visibility:visible' : 'visibility:hidden'  " src="../assets/images/icons/general/close-circle-line.svg" (click)="removeTaxField(list, i)" class="ms-2 align-self-center cursor-pointer"></svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf=" financialYear === 'Select' ||  financialYear === undefined " class=" mt-4 ms-3 mb-4">
                                {{ 'FINANCIAL_YEAR' | customTranslate : 'Please select the financial year' }}
                            </div>
                            <div *ngIf=" financialYear != 'Select' &&  financialYear !== undefined ">
                                <div *ngIf="filteredTab === hra && !hraAdded"  class="text-primary cursor-pointer mt-4 ms-3 mb-4" (click)="addInvestments()">+ {{'ADD_RENTED_HOUSE' | customTranslate : 'Add Rented House' }}</div>
                                <div *ngIf="filteredTab !== hra"  class="text-primary cursor-pointer mt-4 ms-3 mb-4" (click)="addInvestments()">{{ 'ADD_INVESTMENT' | customTranslate : '+ Add Investment' }}</div>
                            </div>
                        </div>
            
                        <div class="d-flex justify-content-end mt-4">
                            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" [buttonType]="'text-only'" [tabIndex]="1" (buttonClickEmit)="saveDeclaration('cancel')">
                            </app-fusion-button>
                            <app-fusion-button buttonValue="{{ 'SAVE_COMPARE' | customTranslate : 'Save & Compare' }}" [tabIndex]="2" [buttonDisable]="saveButtonDisable || isDuplicateName || hraDateValidation || panValidation" (buttonClickEmit)="saveDeclaration('saveAndCompare')">
                            </app-fusion-button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div *ngIf="compareTax">
            <div>{{ 'COM_TAX' | customTranslate : 'Compare Tax Details' }}</div>
            <div class="card d-flex flex-column mt-4 w-75">
                <div class="d-flex flex-row mt-4 ms-4 me-4">
                    <div class="w-50 card p-3 table-header cursor-pointer" [class]="!newTaxRegime ? 'border border-primary' : '' " (click)="newTaxRegime = false; saveDeclaration('taxRegimeSwap')">
                        <div class="d-flex flex-row">
                            <svg-icon class="align-self-center" [src]="!newTaxRegime ? '../assets/images/icons/general/tax-checktick-true.svg' : '../assets/images/icons/general/tax-checktick.svg' " ></svg-icon>
                            <div class="d-flex flex-column ms-2">
                                <div class="medium-font"> {{ 'OLD_REG' | customTranslate : 'Old Tax Regime' }} </div>
                                <div class="small-font text-black-50">{{ 'TAX_INCOME' | customTranslate : 'Taxable Income' }} : <b class="small-font text-primary-emphasis">{{oldTaxNet}}</b></div>
                                <div class="small-font text-black-50">{{ 'TAX_LIABILITY' | customTranslate : 'Tax Liability' }} : <b class="small-font text-primary-emphasis">{{ finalOldNetTaxLiable }}</b></div>
                                <div class="small-font text-black-50"> {{ 'TDS_PAID' | customTranslate : 'TDS Paid' }} : <b class="small-font text-primary-emphasis">{{ tdsPaid }}</b></div>
                                <div class="small-font text-black-50">{{ 'TDS_DUE' | customTranslate : 'TDS Due' }} : <b class="small-font text-primary-emphasis">{{ taxDueOld }}</b></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-50 card p-3 ms-3 table-header cursor-pointer" [class]="newTaxRegime ? 'border border-primary' : '' " (click)="newTaxRegime = true; saveDeclaration('taxRegimeSwap')">
                        <div class="d-flex flex-row">
                            <svg-icon class="align-self-center" [src]="newTaxRegime ? '../assets/images/icons/general/tax-checktick-true.svg' : '../assets/images/icons/general/tax-checktick.svg' " ></svg-icon>
                            <div class="d-flex flex-column ms-2">
                                <div class="medium-font"> {{ 'NEW_REG' | customTranslate : 'New Tax Regime' }} </div>
                                <div class="small-font text-black-50">{{ 'TAX_INCOME' | customTranslate : 'Taxable Income' }} : <b class="small-font text-primary-emphasis"> {{newTaxNet}}</b></div>
                                <div class="small-font text-black-50"> {{ 'TAX_LIABILITY' | customTranslate : 'Tax Liability' }} : <b class="small-font text-primary-emphasis">{{ finalNewNetTaxLiable }}</b></div>
                                <div class="small-font text-black-50">{{ 'TDS_PAID' | customTranslate : 'TDS Paid' }}: <b class="small-font text-primary-emphasis">{{ tdsPaid }}</b></div>
                                <div class="small-font text-black-50">{{ 'TDS_DUE' | customTranslate : 'TDS Due' }}: <b class="small-font text-primary-emphasis">{{ taxDueNew }}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 p-4">
                    <div>
                        <div class="table-header d-flex flex-row">
                            <div class="ps-4 pt-3 pb-3 small-font w-50">{{ 'PARTICULARS' | customTranslate : 'Particulars' }}</div>
                            <div class="text-end pt-3 pb-3 pe-4 small-font w-50">{{ 'DECLARED_AMOUNT' | customTranslate : 'Declared Amount' }}</div>
                        </div>
                
                        <div *ngFor="let list of tableData; let i=index" class="mt-5">
                            <div class="ps-4 pt-3 pb-3 medium-font w-50 fw-bolder">{{list.type}}</div>
                            <div *ngFor="let split of list.splitUps; let j=index">
                                <div *ngIf="split.components === undefined" #openAccordion class="d-flex flex-row">
                                    <div #arrow class="w-50">
                                        <div class="ps-4 pt-3 pb-3 medium-font text-black">{{split.section}}</div>
                                    </div>
                                    <div class="w-50">
                                        <div class="text-end pt-3 pb-3 pe-4 medium-font text-black">{{split.amount}}</div>
                                    </div> 
                                </div>
                                <div *ngIf="split.components  !== undefined && split.components.length !== 0" #openAccordion (click)="accordionContent(i,j)" class="d-flex flex-row cursor-pointer">
                                        <div #arrow class="ps-4 pt-3 pb-3 d-flex flex-row w-50">
                                            <img  class="edit-open" src="../assets/images/icons/general/arrow-dropdown.svg">
                                            <div class="medium-font align-self-center text-black">{{split.section}}</div>
                                        </div>
                                        <div class=" pt-3 pb-3 pe-4 w-50">
                                            <div class="text-end medium-font text-black">{{split.amount}}</div>
                                        </div>
                                </div>
                                <div class="accordion-content" >
                                    <div *ngIf="split.section != hra ">
                                        <div class="investment-background  d-flex flex-row" *ngFor="let inv of split.components">
                                            <div class="ps-4 pt-3 pb-3 d-flex flex-row w-50">
                                                <div class="medium-font align-self-center text-black">{{inv.name}}</div>
                                            </div>
                                            <div class=" pt-3 pb-3 pe-4 w-50">
                                                <div class="text-end medium-font text-black">{{inv.amount}}</div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div *ngIf="split.section === hra ">
                                        <div class="investment-background  d-flex flex-row" *ngFor="let inv of split.components">
                                            <div class="ps-4 pt-3 pb-3 d-flex flex-column w-50">
                                                <div class="medium-font text-black">{{inv.rentFrom  | date:'mediumDate'}} - {{inv.rentTo  | date:'mediumDate'}}</div>
                                                <div class="medium-font text-black"></div>
                                                <div class="medium-font text-black">{{inv.address}}</div>
                                                <div class="medium-font text-black">{{inv.landLordName}}</div>
                                                <div class="medium-font text-black">{{inv.landLordPan}} - {{inv.livingArea}}</div>
                                            </div>
                                            <div class=" pt-3 pb-3 pe-4 w-50">
                                                <div class="text-end medium-font text-black">{{inv.calculatedAmount}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                                 
                            <div class="d-flex flex-row justify-content-between border-bottom border-top border-dark-subtle">
                                <div class="ps-4 pt-3 pb-3 medium-font w-50 fw-bolder">{{list.total.key}}</div>
                                <div class="text-end pt-3 pb-3 pe-4 medium-font w-50 fw-bolder">{{list.total.value}}</div>
                            </div>                          
                        </div>
                        <!-- <div class="table-footer d-flex flex-row">
                            <div class="ps-4 pt-3 pb-3 medium-font text-black w-50">Total Tax Liability</div>
                            <div class="text-end pt-3 pb-3 pe-4 medium-font text-black w-50">{{ newTaxRegime ? this.numberRoundDecimal(finalNewNetTaxLiable) : this.numberRoundDecimal(finalOldNetTaxLiable) }}</div>
                        </div> -->

                        <div class="d-flex justify-content-end mt-4">
                            <app-fusion-button buttonValue="{{'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" (buttonClickEmit)="saveDeclaration('cancel')" buttonType="text-only">
                            </app-fusion-button>
                            <app-fusion-button buttonValue="{{ 'EDIT_DECLARATION' | customTranslate : 'Edit Declaration' }}"(buttonClickEmit)="compareTax = false">
                            </app-fusion-button>
                            <app-fusion-button buttonType="ms-2" buttonValue="{{'BUTTON_VALUE.SUBMIT'| customTranslate : 'Edit Declaration'}}" [tabIndex]="3" (buttonClickEmit)="submitButton('DECLARED', 'submit')">
                            </app-fusion-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="declaredScreen">
        <div class="card it-declaration-note rounded-0 d-flex flex-row p-3">
            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>
            <span *ngIf="declaredSelctionList?.status === 'DECLARED'" class="medium-font ms-3">{{ 'DECLARED_SUBMIT' | customTranslate : 'You have submitted your IT Declaration to your payroll admin. However, if you need to make changes to the IT Declaration and submit it for review.' }}</span>
            <span *ngIf="declaredSelctionList?.status === 'DRAFT'" class="medium-font ms-3">{{'DRAFT_SUBMIT' | customTranslate : 'You have saved your IT Declaration as draft' }}</span>
        </div>
        <div class="card rounded-0 d-flex flex-column p-3">
            <div class="d-flex flex-row  justify-content-between">
                <div class="d-flex flex-row">
                    <div class="medium-font">{{ 'OVERALL_SUMMARY' | customTranslate : 'Overall Tax Summary' }}</div>
                    <div class="small-font ms-3">{{ 'FIN_YEAR' | customTranslate : 'Financial Year' }} : {{this.financialYear}}</div>
                </div>
                <div>
                    <div class="small-font">{{'TAX_REG' | customTranslate : 'Tax Regime' }} : {{newTaxRegime ? ('NEW_REG' | customTranslate : 'New Tax Regime') :('OLD_REG' | customTranslate : 'Old Tax Regime')}}</div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-between  mt-4">
                <div class="d-flex flex-row">
                    <div class="small-font me-5 d-flex flex-column">
                        <label class="small-font field-label">{{'NET_TAX' | customTranslate : 'Net Taxable Income' }}</label>
                        <span class="small-font mt-1">{{newTaxRegime ? newTaxNet : oldTaxNet }}</span>
                    </div>
                    <div class="small-font me-5 d-flex flex-column">
                        <label class="small-font field-label">{{'TOTAL_TAX' | customTranslate : 'Total Tax' }}</label>
                        <span class="small-font mt-1">{{newTaxRegime ? finalNewNetTaxLiable : finalOldNetTaxLiable}}</span>
                    </div>
                    <div class="small-font me-5 d-flex flex-column">
                        <label class="small-font field-label">{{'TOTAL_TAX' | customTranslate : 'Total Tax' }}</label>
                        <span class="small-font mt-1">{{newTaxRegime ? taxDueNew : taxDueOld }}</span>
                    </div>
                    <div class="small-font me-5 d-flex flex-column">
                        <label class="small-font field-label">{{'STATUS' | customTranslate : 'Status' }}</label>
                        <div class="small-font declared ps-1 pe-1 status-badge rounded mt-1" [ngClass]="{ 'bg-success': declaredSelctionList?.status ===('DECLARED'), 'bg-secondary bg-gradient': declaredSelctionList?.status === 'DRAFT'}">{{declaredSelctionList?.status | translate}}</div>
                    </div>
                </div>
                <app-fusion-button buttonType="ms-2" buttonValue="{{ 'EDIT_DECLARATION' | customTranslate : 'Edit Declaration' }}" [tabIndex]="3" (buttonClickEmit)="editDeclaration($event)">
                </app-fusion-button>
            </div>
        </div>
        <div class="mt-4" *ngFor="let list of exemptionList; let i=index">
            <div #declaredAccordion class="card rounded-0">
                <div #declaredArrow class="d-flex flex-row small-font p-3 justify-content-between cursor-pointer" (click)="toogleSavedList(i)">
                    <div class="d-flex flex-row" >
                        <img  class="edit-open" src="../assets/images/icons/general/arrow-dropdown.svg">
                        <div class="medium-font align-self-center text-black">{{list.section}}</div>
                    </div>
                    <div class="align-self-center">{{list.amount}}</div>
                </div>
            </div>
            <div class="accordion-content">
                <div *ngFor="let splitList of list.components; let j=index">
                    <div *ngIf="splitList.section != hra ">
                        <div class="investment-background  d-flex flex-row">
                            <div class="ps-4 pt-3 pb-3 d-flex flex-row w-50">
                                <div class="medium-font align-self-center text-black">{{splitList.name}}</div>
                            </div>
                            <div class=" pt-3 pb-3 pe-4 w-50">
                                <div class="text-end medium-font text-black">{{splitList.amount}}</div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="splitList.section === hra ">
                        <div class="investment-background  d-flex flex-row">
                            <div class="ps-4 pt-3 pb-3 d-flex flex-column w-50">
                                <div class="medium-font text-black">{{splitList.rentFrom  | date:'mediumDate'}} - {{splitList.rentTo  | date:'mediumDate'}}</div>
                                <div class="medium-font text-black"></div>
                                <div class="medium-font text-black">{{splitList.address}}</div>
                                <div class="medium-font text-black">{{splitList.landLordName}}</div>
                                <div class="medium-font text-black">{{splitList.landLordPan}} - {{splitList.livingArea}}</div>
                            </div>
                            <div class=" pt-3 pb-3 pe-4 w-50 align-self-center">
                                <div class="text-end medium-font text-black">{{splitList.calculatedAmount}}</div>
                                <div class="text-end small-font text-black">{{splitList.calculatedAmount / 12}} / month</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>