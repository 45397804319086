import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EssComponent } from '../ess.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { SideNavEmitService } from 'src/app/services/side-nav-emit.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-bankinfotab',
  standalone:true,
  templateUrl: './bankinfotab.component.html',
  imports: [CommonModule, PageHeaderComponent, FormsModule, ReactiveFormsModule, SuccessFailureMessageComponent,CardsComponent,PopoverComponent,FusionButtonComponent,AngularSvgIconModule,TranslateModule,
   EssComponent, CustomTranslatePipe],
  
  styleUrls: ['./bankinfotab.component.css']
})
export class BankinfotabComponent implements OnInit {

  isHamClicked:boolean = false;

  sideNavsubscription: Subscription;

  essTabToShow: string = 'details';
  pageComponentName = 'Ess';

  hideOrShowNav: string = "side-nav-container";
  documentsForm! : FormGroup;
  bankInfoForm! : FormGroup;
  numberRegex = "^[0-9]*$";
  mobileRgx = "^([0-9]{4,16})"; // Added this for text Validation and used them further down the lines
  textRegex = "^[a-zA-Z ]*$"; // Added this for text Validation and used them further down the lines
  errorMessage = '';
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  bankGetData:any;
  isDevMode: boolean = false;
  employeeId:any;
  @Input() selectedUser:any;
  @Input() employeeFinanceInfo : any;
  @Input() fromSettings  = false; //SK26JUL24 bank info from settings
  fromHrmPage = false;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  companyId = this.globalValues.orgId;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService,private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService, private globalValues: GlobalValuesService,private language:TranslateService) 
  {

    this.isDevMode = isDevMode();

    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => this.essTabToShow = navItem);

    /* Form groups created for the forms in ESS details tabs */
      this.bankInfoForm = this.formBuilder.group({
      'accName' : ['', Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'bankName' : ['', Validators.required],
      'accNum' : ['', Validators.required],
      'branchName' : ['', Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'ifsc' : ['', Validators.required],
      'city' : ['', Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'state': ['', Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'paymentMode': [''], //SK25JUL24
      }) 
  }

  ngOnInit(): void {
    if(this.router.routerState.snapshot.url.includes('/hrm') || this.fromSettings){
      this.employeeId = this.selectedUser?.employeeId;
      this.fromHrmPage = true;
      this.getData()
    } else if (this.router.routerState.snapshot.url.includes('/ess')){
      /** Calling the GET functions */
      this.employeeId = this.globalValues?.empId;
      this.getData();
      this.fromHrmPage = false;
    }


    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    } 
  }

  //SK 17JAN24 to update the bank info details
  ngOnChanges(): void {
    if(this.router.routerState.snapshot.url.includes('/hrm') || this.fromSettings){
      this.employeeId = this.selectedUser?.employeeId;
      this.fromHrmPage = true;
      this.getData()
    } else if (this.router.routerState.snapshot.url.includes('/ess')){
      /** Calling the GET functions */
      this.employeeId = this.globalValues?.empId;
      this.getData();
      this.fromHrmPage = false;
    }


    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    } 
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sideNavsubscription.unsubscribe();
  }



  saveMsg(){
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg);
    this.ngOnInit();
  }
  
  deleteMsg(){
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, this.successMsg);
    this.ngOnInit();
  }

  errorMsg(err: HttpErrorResponse){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  setBankInfo(){
    const value = this.bankInfoForm.value;
    // SK26JUL24 bank info update if from settings
    if(this.fromSettings){
      this.apiService.writeValue('patch', '/settings/finance', {'accountHolderName': value.accName, 'bankName': value.bankName, 'accountNumber': value.accNum, 'branchName': value.branchName, 'ifsc': value.ifsc, 'city': value.city, 'state': value.state, 'paymentMode': value.paymentMode}) //SK25JUL24
      .subscribe({
        next: (bankInfo:any) => {
          if(this.isDevMode) console.log("Next :", bankInfo);
          this.alertService.messageOnPass('success', bankInfo.message);
          this.globalValues.userGetAPI();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    } else {
      this.apiService.writeValue('patch', '/employee/bankinfo', {'accountHolderName': value.accName, 'bankName': value.bankName, 'accountNumber': value.accNum, 'branchName': value.branchName, 'ifsc': value.ifsc, 'city': value.city, 'state': value.state, 'employeeId': this.employeeId, 'paymentMode': value.paymentMode}) //SK25JUL24
      .subscribe({
        next: (bankInfo) => {
          if(this.isDevMode) console.log("Next :", bankInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    }
  }

  getData(){
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    // this.apiService.fetchValue('/hrm',params).subscribe((datas: any) => {
    //   this.bankGetData = datas?.financeInfo[0]?.bankInfo;
    //   this.setDatas();
    // });

    this.bankGetData = this.fromSettings ? this.employeeFinanceInfo : this.employeeFinanceInfo?.bankInfo[0];
  }

  setDatas(){
    this.bankInfoForm = this.formBuilder.group({
      'accName': [this.bankGetData?.accountHolderName, Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'bankName': [this.bankGetData?.bankName, Validators.required],
      'accNum': [this.bankGetData?.accountNumber, Validators.required],
      'branchName': [this.bankGetData?.branchName, Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'ifsc': [this.bankGetData?.ifsc, Validators.required],
      'city': [this.bankGetData?.city, Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'state': [this.bankGetData?.state, Validators.compose([Validators.pattern(this.textRegex), Validators.required])],
      'paymentMode':[this.bankGetData?.paymentMode || ""], //SK25JUL24
      'id':[this.bankGetData?._id],
    });
  }

}
