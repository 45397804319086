<!-- to get the month and year dropdown -->
<!-- ngIf is used so that the dropdown will not be visible in the comparison table -->
<div *ngIf="!isProceedOn">
  <app-drop-down  class="field-input" [lists]='dates' dropDownHeight="38px" dropDownWidth="270px" (dataEmit)="dropdownEmitFunction($event)" [selectedData]="selectaMonth"></app-drop-down>
  <div class="mt-3 d-flex flex-wrap align-items-center justify-content-between">
    <app-fusion-button [buttonDisable]="disableButton" class="proceed-btn" type="default" buttonValue="{{ 'GENERATE_PAYROLL' | customTranslate : 'Generate Payroll' }}" (buttonClickEmit)="callCompareTable()"></app-fusion-button>
    <div class="d-flex imp-exp-btns">
      <div>
        <!-- RS27AUG24 -->
        <!-- Used the translate pipe to successfully implement the translation -->
        <app-fusion-button [buttonType]="'grey-border grey-text text-only'"
          [modalToTrigger]="'uploadCompensationModal'" buttonValue="{{ 'UPLOAD_COMPENSATION' | customTranslate : 'Upload Compensation' }}"
          [fcBtnIconLeftSrc]="'../assets/images/icons/general/upload-icon.svg'"></app-fusion-button>
      </div>
      <div>
        <app-fusion-button [buttonType]="'grey-border grey-text text-only'" [buttonDisable]="selectaMonth == 'Select month and year'"
          buttonValue="{{ 'EXPORT_PF' | customTranslate : 'Export PF/ESI' }}" [isImageSvg]="true" [modalToTrigger]="'pfESIModal'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
        </app-fusion-button>
      </div>
      <!-- SK25JUL24 bank file export  -->
      <app-fusion-button (buttonClickEmit)="downloadBankFile()"  [buttonType]="'grey-border grey-text text-only'"  [buttonValue]="'GENERATE_FILES' | customTranslate : 'Generate files'" [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'" [isImageSvg]="true">
      </app-fusion-button>
    </div>
  </div>

    <!-- SK18JUN24 for dynamic columns added configJSON variable -->

  <app-data-table class="payroll-table" *ngIf="payrolls" [tableWidth]="this.configJSON?.tableWidth || '300%'" [data]="payrolls"
  [displayedColumns]="displayedColumns" [headerLabels]="headers" [translatePath]="'PAYROLL_TABLE_TABLE'"
    [stickyColumn]="true" [withCheckBox]="true"
    [filterColumns]="configJSON?.filterColumns || []"
    [editColumn]="['lossOfPay']" [tableSaveButton]="true" (saveButtonData)="saveLop($event)" (selectedValues)="tableClickData($event)"
    [filterDataArray]="filterKeys"
    [multipleFilter]="multipleFilterArray" [actionButton]="true" [isEditRow]="true" [cumulativeDataColumns]="cumulativeDataColumns" [editIconTooltip]="'Edit LOP'" (onEditData)="onEditRecord($event)" fromWhere="payrollTable" (filteredArray)="searchFilterData($event)">
  </app-data-table>

  <div class="footer-message">
    <img src="../assets/images/icons/general/exclamatory.svg" style="margin-bottom: 2px;">
    <span> {{'CLICK_ON_GENERATE_PAYROLL_TO COMPARE_PAYROLL' | customTranslate : 'Click on Generate Payroll to compare last month payroll and current payroll.'}}</span>
  </div>
</div>

<div id="compare-table" *ngIf="isProceedOn">
  <!-- passing a function from parent to child on rendering the child component -->
  <app-payrollcompare (callCompareTable)="onCompareCancel()" [cumulativeDataColumns]="cumulativeDataColumns" [tableArray]="selectedCheckbox" [allEmployeeData]="allEmpData"
    [queryData]="allQueryData" (emitUpdated)="dropdownEmitFunction($event)" (fromPayrollCompare)="fromPayroll($event)" (alertMessageEmit)="saveSuccess($event)" (errorMessageEmit)="showError($event)"> </app-payrollcompare>
</div>


<!-- Compensation bulk upload -->
<div class="modal fade container-md" id="uploadCompensationModal" tabindex="-1" aria-labelledby="compensationUploadModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-5 mb-0" id="personalModalLabel">{{ 'UPLOAD_COMPENSATION' | customTranslate : 'Upload Compensation' }}</div>
        <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal'" (buttonClickEmit)="filesArrayClear()" [fcBtnId]="'close'">
        </app-fusion-button>
      </div>
      <div class="modal-body padding-space">
        <div class="d-flex row align-items-center modal-with-list">
          <b class="bold-text">{{ 'STEPS_UPLOAD_COMPENSATION' | customTranslate : 'Steps to upload Compensation' }}</b>
          <ol>
            <li>{{ 'DOWNLOAD_COMPENSATION_TEMPLATE' | customTranslate : 'Download the Compensation template' }}<span class="link-to-download"
                (click)="downloadTemplate('compensation')">{{ 'HERE' | customTranslate : 'here' }}</span>.</li>
            <li>{{ 'FILL_COMPENSATION_DETAILS' | customTranslate : 'Fill the appropriate Compensation Details and upload it below' }}</li>
          </ol>
          <app-file-upload [fileUploadId]="'uploadCompensation'" [docVals]="['5 mb','csv, xlsx, xls']" [multiSelectFile]="false" [showPreview]="'fileName'"
            [acceptType]="'.csv,text/csv,.xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            (selectedFilesEmitter)="userFileEmitter($event)" [uploadReadyFiles]="filesArrayComp" (documentDelete)="deletedFileInComp($event)">
            <!-- Mandatory to give a unique value for 'fileUploadId' -->
            <!-- 'showPreview' can have either of the values 'iFrame' (or)  'fileName' -->
            <!-- 'acceptType'  helps to partially restrict user to select only given file type, check the list for acceptable values -->
          </app-file-upload>
        </div>
      </div>
      <div class="modal-footer">
        <app-fusion-button [buttonValue]="'Upload'| customTranslate : 'Upload'" [buttonType]="'btn btn-primary'" (buttonClickEmit)="uploadRecordsFile()"
        [buttonDisable]="!compensationBulkFile">
      </app-fusion-button>

      </div>
    </div>
  </div>
</div>

<!-- EPF , ESI modal -->
<div class="modal fade container-md" id="pfESIModal" tabindex="-1" aria-labelledby="employeEUploadModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-5 mb-0" id="personalModalLabel">{{ 'PF_ESI' | customTranslate : 'PF / ESI Template' }}</div>
        <app-fusion-button [buttonType]="'btn-close'" [dataDismiss]="'modal'" 
        (buttonClickEmit)="filesArrayClear()" [fcBtnId]="'close'">
        </app-fusion-button>
</div>
      <div class="modal-body padding-space">
        <div class="d-flex row align-items-center">
          <p> {{ 'TEMP_PF' | customTranslate : 'Click on the respective button to download the template for PF or ESI' }}</p>
          <div class="d-flex justify-content-around mt-3">
            <app-fusion-button [buttonType]="'grey-border grey-text text-only'"
            (buttonClickEmit)="downloadEsiPfTemplate('ecr',selectaMonth)" buttonValue="{{ 'DOWN_ECR' | customTranslate : 'Download ECR' }}"
              [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'"></app-fusion-button>
          
            <app-fusion-button [buttonType]="'grey-border grey-text text-only'"
            (buttonClickEmit)="downloadEsiPfTemplate('arrear',selectaMonth)" buttonValue="{{ 'DOWN_ECR(A)' | customTranslate : 'DOwnload ECR(Arrear)' }}"
              [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'"></app-fusion-button>
          
            <app-fusion-button [buttonType]="'grey-border grey-text text-only'"
            (buttonClickEmit)="downloadEsiPfTemplate('esi',selectaMonth)" buttonValue="{{ 'DOWN_ESI' | customTranslate : 'Download ESI' }}"
              [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'"></app-fusion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SK25JUL24 while clicking bank file export, side screen with selected field will be displayed -->
<app-common-screen *ngIf="bankFileSidescreen" headerTitle="{{ 'BANK_FILE_PROCESSING' | customTranslate : 'Bank File Processing' }}"(sideScreenEmittedEvent)="bankFileSidescreen = false" >
  <div class="side-main-content">
    <!-- <app-hrm-tds (closeSideScreen)="saveTDS($event)" [onLoadData]="taxSideScreenData" [taxComponentActivity]="taxComponentActivity" [activeSection]="activeSection" [sectionLimit]="sectionLimit" [totalSections]="sectionListData"></app-hrm-tds> -->

        <app-multi-checkbox class="ms-2 me-2"
          [label]="configTemplate?.label | customTranslate :  configTemplate?.label" 
          [cardSize]="configTemplate?.cardSize"
          [cardInlineSize]="configTemplate?.cardInlineSize"
          [checkboxList]="configTemplate?.checkboxList" 
          [activeCheckboxList]="configTemplate?.activeCheckboxList" 
          [buttonList]="configTemplate?.buttons" 
          [componentData]="configTemplate"
          (compDataEmit)="bankFieldsButtonEmit($event)">
        </app-multi-checkbox>
  </div>
</app-common-screen>