import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsComponent } from '../charts/charts.component';
import { Subscription } from 'rxjs';
import { GlobalValuesService } from 'src/app/services/global-values.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, ChartsComponent],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @Input() metricsArray : Array<any> = [];
  filterType  : any;
  chartArray: Array<any> = [];
  chartData: Subscription;

  constructor(private globalValues: GlobalValuesService) { 
    this.chartData = this.globalValues.chartDataChange$.subscribe((data:any)=>{
      this.chartArray = [];
      data.forEach((element:any) => {
        if(element.filterType === 'noncomparison'){
          // mapping the data to the desired format, that to be sent to dashboard component
          if(element.data){
            element.mappedData = [];
            for (const property in element.data) {
              element.mappedData.push(
                {name: property, value: element.data[property]},
              )
            }
          }
        }else if(element.filterType === 'comparison'){
          element.mappedData = [];
          if(element.data) {
            if(element.chart === 'Line'){
              // D3 result Mapping
              for (const key in element.data) {
                if (element.data.hasOwnProperty(key)) {
                  const obj:any = {
                    name: key,
                    series: []
                  };
                  for (const dataKey in element.data[key]) {
                    if (element.data[key].hasOwnProperty(dataKey)) {
                      obj.series.push({
                        name: dataKey,
                        value: element.data[key][dataKey]
                      });
                    }
                  }
                  element.mappedData.push(obj);
                }
              }
            }
            else if (element.chart === 'Bar'){
              // ChartJS result Mapping
              const labels = Array.from(
                new Set(
                  Object.keys(element.data)
                    .map((job) => Object.keys(element.data[job]))
                    .flat()
                )
              );

              element.mappedData = {
                labels: labels,
                datasets: Object.keys(element.data).map((job, index) => ({
                  data: labels.map((year) => element.data[job][year] || 0),
                  label: job,
                  backgroundColor: [ '#ff3d71', '#8cfac7', '#42aaff', '#6f42c1', '#17a2b8', '#007bff', '#fd7e14', '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'][index], // You can define your own colors here
                })),
              };
            }
          }
        }
      });
      this.chartArray = data;
    });
  }

  ngOnInit(): void {
  }
}
