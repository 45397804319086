import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateName',
  standalone: true
})
export class TruncateNamePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let convertedToString = JSON.stringify(value);
    let fileNameTruncated = (window.innerWidth > 767) ? convertedToString.slice(0,6) + " ... " +convertedToString.slice(-Math.abs(JSON.stringify(convertedToString.split(".").pop()).length+2)) : JSON.stringify(value).slice(0,5) + " ... " +JSON.stringify(value).split(".").pop();
    return JSON.stringify(value).length > 11 ?  fileNameTruncated.replace(/"/g,'') : value;
  }

}
