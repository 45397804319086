import { Pipe, PipeTransform } from '@angular/core';
import * as numberToWords from 'number-to-words';

@Pipe({
  name: 'amountToWords',
  standalone: true
})
export class AmountToWordsPipe implements PipeTransform {
  
  /* This pipe is used to convert the amount to words. For that, we need to install the plugin 'num-to-words' */ 
  transform(value: number): string {
    return numberToWords.toWords(value);
  }

}
