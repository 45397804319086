import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { ApiService } from 'src/app/services/api.service';
import { Constants } from 'src/app/models/Constants';
import { CompensationEditComponent } from './compensation-edit/compensation-edit.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { LangChangeEvent, TranslateModule,TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
@Component({
  selector: 'app-compensation',
  standalone: true,
  imports: [CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    DataTableComponent,
    TranslateModule,
    CompensationEditComponent
  ],
  templateUrl: './compensation.component.html',
  styleUrls: ['./compensation.component.css']
})
export class CompensationComponent implements OnInit {
  compGet: any;
  compDataTable: Array<any> = [];
  allData: any;
  itemsPerPage = Constants.PAGE_SIZE_OPTIONS;
  isDevMode: boolean = false;
  editCompensation = false;
  passEmployeeId : any;
  compensationHeaders:Array<any>=[];
 
  multipleFilterArray: any;
  filterKeys: any = {}; //SK18JUN24
  finalData: any;
  data: any;
  headers:Array<string> = [];
  TranslateHeaders: Array<string> = [];

  constructor(private apiService: ApiService,private globalVal: GlobalValuesService,private translate : TranslateService) {
    this.globalVal.laguageChanges$.subscribe(lang => {
    this.compensationHeadersValues();
    });
  }

  ngOnInit(): void {

    this.getEmployees();
    this.compensationHeadersValues();
  }

  getEmployees() {
    this.apiService.fetchValue('/hrm/allemployees').subscribe((get: any) => {
      if (this.isDevMode) console.log('All GET=>', "displays all the list of employees, COMMENTED since huge employees is slowing the process");
      this.compGet = get;
      this.compDataTable = [];
      this.allData = [];
       // setting the data as per we need in our  data table component 

      get?.forEach((element: any, index:number) => {
        const datum = element.employee;
        const comp = element?.financeInfo?.compensation; // SK17JAN24 path changed
        this.compDataTable.push({
          employeeId: datum?.employeeId,
          employeeName: datum?.firstName + ' ' + datum?.lastName,
        });
        comp?.forEach((compData: any) => {
          if (compData?.isActive === true) {
            this.compDataTable[index].currencyType = compData?.currencyCode || "";
            this.compDataTable[index].effectiveDate = moment(compData.effectiveDate).format('ll');
          }
        });
      });
      this.allData = this.compDataTable;
      this.finalData = JSON.stringify(this.allData);

      this.prepareFilters(); //MV24AUG24 Populate all filter options 
    });
  }
  compensationHeadersValues()
  {const list =["WORK_BASICINFO.EMPLOYEEID","PERSONAL_INFO.NAME","CURRENCY_TYPE","EFF_DATE"];
  this.returnValue(list, 'compensationHeaders', 'array')

  }
  
  returnValue(array:any, variable:any, type:any){
    type === 'array' ? (this as any)[variable] = [] : (this as any)[variable] = '';
    array.forEach((element:any) => {
      this.translate.get(element).subscribe((keyValue) => {
        if(type === 'array'){
          (this as any)[variable].push(keyValue);
        } else if (type === 'string'){
          (this as any)[variable] = keyValue;
        }
      });
    });
  }
//MV24AUG24 Prepares unique filter options for 'employeeId', 'employeeName', and 'currencyType' from the parsed `finalData`
  prepareFilters() {
    const filterkey = ["employeeId", "employeeName", "currencyType"];
    if (filterkey?.length > 0) {
      this.multipleFilterArray = {};
      const filtObjs: any = {};
      filterkey.forEach((element: any) => {
        this.multipleFilterArray[element] = null;
        filtObjs[element] = [];
      });
      JSON.parse(this.finalData).forEach((element: any) => {
        filterkey.forEach((key: any) => {
          if (Object.keys(element).includes(key)) {
            filtObjs[key].push(element[key]);
            this.multipleFilterArray[key] = (new Set(filtObjs[key]));
          
          }
        });
      });
      //MV24AUG24 get the compensation filter keys and pass to the filterData
      const filter:any =localStorage.getItem('compensationfilter')
      this.filterKeys=JSON.parse(filter)||{}
      this.filterData(this.filterKeys)
    }
  }

  searchFilterData(event: any) {
    this.filterKeys[event[0]] = event[1];
    localStorage.setItem('compensationfilter',JSON.stringify(this.filterKeys)) // MV24AUG24 stringify the filterkeys to compensationfilter
    this.filterData(this.filterKeys);
  }
//MV24AUG24 Retain the filterdata
  filterData(filterkey:any) {
    if(Object.keys(filterkey).length==0){
      this.allData = JSON.parse(this.finalData);
    }else{
      this.allData=[];
      let isDataChecked = false;

      for (let key of Object.keys(filterkey)) {
        if (filterkey[key].length > 0) {
          isDataChecked = true;
        }
      }
      if (!isDataChecked) {
        this.allData = JSON.parse(this.finalData);
      } else {
        for (let key of Object.keys(filterkey)) {
          const data = JSON.parse(this.finalData).filter((name: any) => filterkey[key]?.includes(name[key]));
          const data1 = [...this.allData, ...data];
          this.allData = this.removeDuplicates(data1);
        }
      }
    }   
  }
  
// MV24AUG24 to remove duplicate object form two arrays
  removeDuplicates(objects: any) {
    const seen = new Set<string>();
    const uniqueObjects: any = [];

    objects.forEach((obj: any) => {
      const key = JSON.stringify(obj);
      if (!seen.has(key)) {
        seen.add(key);
        uniqueObjects.push(obj);
      }
    });
    return uniqueObjects;
  }

  selectedRow(event: any) {
    this.editCompensation = true;
    this.passEmployeeId = { employeeId: event.employeeId, employeeName: event.employeeName }; //SK15JUL24 passing employee name also
  }
}