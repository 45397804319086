import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalValuesService } from '../services/global-values.service';
import { Subscription } from 'rxjs';
import { CustomTranslatePipe } from '../pipes/custom-translate.pipe';

@Component({
  selector: 'app-nav-tabs',
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,CustomTranslatePipe
  ],
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.css']
})
export class NavTabsComponent implements OnInit {
  @Input() tabsLists : Array<any> = [{name:'', value:'', browsedTab:false}];
  @Input() hideTabsList : Array<any> = [];
  @Input() activeTab : string='';
  @Input() activeSubTab : string=''; //SK22MAR24 to track the active subtab

  @Output() emitTabData  = new EventEmitter<any>;
  @Output() emitSubTabData  = new EventEmitter<any>; //SK22MAR24 emitting the sub tab data on click

  getTabs:Subscription
  // SK06MAR24 Created this component for navTabs
  constructor(private globalValues: GlobalValuesService) { 
    // SK06MAR24 setting and getting the tabs in and from local storage
    // SK13MAR24 storing the navtabs list in session storage instead of local storage
    this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || this.tabsLists;
    this.activeTab = sessionStorage.getItem('activeNavTab') as string || this.activeTab;
    this.activeSubTab = sessionStorage.getItem('activeNavSubTab') as string || this.activeSubTab;
    this.getTabs = this.globalValues.navTabsGetData$.subscribe((data:any)=>{
      sessionStorage.setItem('navTabsList', JSON.stringify(data.tabsList));
      sessionStorage.setItem('activeNavTab', data.activeTab);
      sessionStorage.setItem('activeNavSubTab', data.activeSubTab);
      this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) ;
      this.activeTab = sessionStorage.getItem('activeNavTab') as string;
      this.activeSubTab = sessionStorage.getItem('activeNavSubTab') as string;
    })
  }

  ngOnInit(): void {   
    this.tabsLists = JSON.parse(sessionStorage.getItem('navTabsList') as any) || this.tabsLists;
    this.activeTab = sessionStorage.getItem('activeNavTab') as string || this.activeTab; 
    this.activeSubTab = sessionStorage.getItem('activeNavSubTab') as string || this.activeSubTab;
    const filterTab = this.tabsLists.filter((data:any)=> data.value === this.activeTab);
    this.emitTabData.emit({data:filterTab[0], allData:this.tabsLists});
  }

  // SK06MAR24 emitting the selected tab to parent
  activeTabEmit(value:any){
    sessionStorage.setItem('activeNavTab', value.value);
    // const tabMain = this.tabsLists.findIndex((tab:any)=>tab.value === value.value);
    // this.tabsLists[tabMain].activeSubTab = this.activeSubTab;
    // sessionStorage.setItem('navTabsList', JSON.stringify(this.tabsLists));
    sessionStorage.setItem('activeNavSubTab', value.value);
    this.activeTab = value.value;
    this.emitTabData.emit({data:value,allData:this.tabsLists});
  }

  // SK22MAR24 same as active tab, we are emitting the sub tab details
  activeSubTabEmit(value:any){
    const tabMain = this.tabsLists.findIndex((tab:any)=>tab.value === this.activeTab);
    this.tabsLists[tabMain].activeSubTab = value.value;
    sessionStorage.setItem('navTabsList', JSON.stringify(this.tabsLists));
    sessionStorage.setItem('activeNavSubTab', value.value);
    this.emitSubTabData.emit({data:value, tabData:this.tabsLists, activeTab:this.activeTab, activeSubTab:this.activeSubTab});
  }

  // SK06MAR24 closing the browsed tabs
  // SK13MAR24 updated for spicing the tabs
  closeBrowseTab(tab:any,i:any){
    const list = this.tabsLists;
    this.tabsLists.splice(i,1);
    sessionStorage.setItem('navTabsList', JSON.stringify(this.tabsLists));
    if(tab.value === this.activeTab){
      this.activeTab = list[i - 1].value;
      if(list[i - 1].subTabs === undefined || list[i - 1].subTabs.length === 0 || list[i - 1].subTabs.length === null) sessionStorage.removeItem("activeNavSubTab")
      sessionStorage.setItem('activeNavTab', this.activeTab);
      this.emitTabData.emit({data:list[i - 1], allData:list});
    }
  }

  // SK22MAR24 closing the sub tab details
  closeBrowseSubTab(tab:any,i:any){    
    const tabMain = this.tabsLists.findIndex((tab:any)=>tab.value === this.activeTab);
    const list = this.tabsLists[tabMain].subTabs;
    this.tabsLists[tabMain].subTabs.splice(i,1);
    sessionStorage.setItem('navTabsList', JSON.stringify(this.tabsLists));
    if(tab.value === this.activeSubTab){
      this.activeSubTab = list[i - 1].value;
      this.tabsLists[tabMain].activeSubTab = this.activeSubTab;
      sessionStorage.setItem('activeNavSubTab', this.activeSubTab);
      sessionStorage.setItem('navTabsList', JSON.stringify(this.tabsLists));
      this.emitSubTabData.emit({data:list[i - 1], tabData:this.tabsLists, activeTab:this.activeTab, activeSubTab:this.activeSubTab});
    }
    
  }
}
