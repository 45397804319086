import { Directive, Output, EventEmitter, HostBinding, HostListener} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Directive({
  selector: '[appDragDropFiles]',
  standalone: true
})
export class DragDropFilesDirective {

  @Output() fileDropped = new EventEmitter<any>();
  @HostBinding('class.selectedFilesDraggedHover') selectedFilesDraggedHover: boolean | undefined;

  constructor(private sanitizer: DomSanitizer){}
  
  // Dragover Event
  @HostListener('dragover', ['$event']) dragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedFilesDraggedHover = true; /* Just a CSS styling while files are dragged and is hovered over the droppable area */
  }
  // Dragleave Event
  @HostListener('dragleave', ['$event']) public dragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedFilesDraggedHover = false; /* Removing the CSS done while hovering and changing it back to the default CSS. */
  }
  // Drop Event
  arrayOfUploadedFilesWithUrls:Array<any> = [];
  @HostListener('drop', ['$event']) public drop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedFilesDraggedHover = false;
    
    // We get all the files that are dropped in the 'DataTransfer' object , we iterate it and genrate a blob url for each file
    Object.values(event.dataTransfer.files).forEach( (eachDroppedFile: any) => {
      this.arrayOfUploadedFilesWithUrls?.splice(0,0,{file: eachDroppedFile,url: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(eachDroppedFile))});
    });

    // After all the processing we send the uploaded files from directive to the respective element
    this.fileDropped.emit({target: event, filesArray: this.arrayOfUploadedFilesWithUrls, file: event.dataTransfer});
     // clearing the file list, once the array is sent, so the new set is sent everytime dropped
    this.arrayOfUploadedFilesWithUrls = [];
  }
}
