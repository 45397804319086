
<div>Compare the Employee Payroll with last Month</div>
<div class="top-buttons">
  <app-fusion-button style="padding-right: 10px" buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" [buttonType]="'text-only'"
    (buttonClickEmit)="onCancel()"></app-fusion-button>
  <app-fusion-button [buttonType]="'btn btn-primary'"[buttonValue]="'Confirm' | customTranslate : 'Confirm'" (buttonClickEmit)="openConfirmModal()" [buttonDisable]="disableButton"> </app-fusion-button>
</div>

<app-data-table class="compare-table"
  [data]="payrollcompare"
  [displayedColumns]="['employeeId','employeeName','previousSalary','netPay','comments']"
  [headerLabels]="['Employee Id', 'Employee Name','Previous Month Salary','Current Month Salary','Comments']"
  [translatePath]="'PAYROLLCOMPARE'"
  [filterColumns]= "['employeeName','employeeId']"
  [cumulativeDataColumns]="cumulativeDataColumns"
  [withCheckBox]= "true" fromWhere="payrollCompare"
  (selectedValues)="tableClickData($event)" [multipleFilter]="multipleFilterArray" (filteredArray)="searchFilterData($event)">
</app-data-table>
  
<!-- Comparison Payroll Modal-->
<div class="modal fade container-md" id="payrollConfirmModal" tabindex="-1" aria-labelledby="payrollConfirmLabel"
  aria-hidden="true" #payrollConfirmModal>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title fs-6" id="payrollConfirmModal">Confirm Payroll</div>
           <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'" [fcBtnId]="'close'">
            </app-fusion-button>
        </div>
      <div class="modal-body">
        <p>You are about to confirm the payroll for {{payrollMonth}}?</p>
      </div>
      <div class="d-flex justify-content-end" style="padding-bottom:15px;padding-right:15px">
        <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" [buttonType]="'text-only card-btn hide-detail-view'"
          [dataDismiss]="'modal'"></app-fusion-button>
        <app-fusion-button [buttonType]="'button'" [buttonValue]="'Approve' | customTranslate : 'Approve'"  (buttonClickEmit)="generateData($event)"  [dataDismiss]="'modal'"
          class="'btn btn-primary ms-2'"></app-fusion-button>
      </div>
    </div>
  </div>
</div>