import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackendTranslateLoader } from './services/backend-translate-loader';
import { Constants } from "./models/Constants";

import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HrmComponent } from './pages/hrm/hrm.component';

import { PopoverComponent } from './components/popover/popover.component';

import { AuthGuard } from './services/auth.guard';
import { ApiInterceptorService } from './services/api-interceptor.service';
import { SideNavEmitService } from './services/side-nav-emit.service';
import { SuccessFailureMessageComponent } from './components/alert-message/success-failure-message.component';
import { NgxPaginationModule } from 'ngx-pagination';

// for my changes
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropFilesDirective } from './directives/drag-drop-files/drag-drop-files.directive';
import { TruncateNamePipe } from './pipes/truncateName/truncate-name.pipe';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AmountToWordsPipe } from './pipes/amount-to-words.pipe';
import { AttendanceColorDirective } from './directives/attendance-color/attendance-color.directive';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NbCardModule, NbThemeModule } from '@nebular/theme';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { NxtAppModule } from '@rangertechnologies/ngnxt';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomTranslatePipe } from './pipes/custom-translate.pipe'; //SK15JUL24 importing spinner module

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);

}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PopoverComponent,
    SuccessFailureMessageComponent,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatInputModule,
    DragDropFilesDirective,
    TruncateNamePipe,
    CurrencyPipe,
    AttendanceColorDirective,
    NbThemeModule.forRoot({ name: 'default' }),
    NgxChartsModule,
    NbCardModule,
    ChartModule,
    NbCardModule,
    NxtAppModule,
    TranslateModule.forRoot({
      // loader: {
      //   provide: TranslateLoader,
      //   useFactory: HttpLoaderFactory,
      //   deps: [HttpClient]
      // }
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new BackendTranslateLoader(http, Constants.SERVER_BASE_URL+'/settings/translations'),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CustomTranslatePipe
  ],
  exports: [AngularSvgIconModule],
  providers: [
    {provide: HTTP_INTERCEPTORS,useClass: ApiInterceptorService,multi: true},
    SideNavEmitService,
    AuthGuard,
    CurrencyPipe,
    AmountToWordsPipe,
    HrmComponent,
    DatePipe,
    TranslatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
