import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'editColumnType',
  standalone: true
})
export class EditColumnTypePipe implements PipeTransform {
  // SK10APR24 to check the type of edit column in table
  transform(arg:any, element: any, editCol: any): any {
    var returnVal :any ;
    returnVal = (editCol.filter((col:any)=>col.name === element));
    return returnVal[0].type; 
  }
}
