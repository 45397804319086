<div class="flex flex-direction-row height-100">
    <div class="left-nav" [class]="showSideNavInMobile ? 'show-mobile-menu' : ''">
        <div class="side-nav-container flex flex-direction-column empty-space-on-nav"
            (click)="clickdedEmptySideNav($event)">
            <div class="side-nav" [ngSwitch]="currentPageComponent">
                <div *ngFor="let app of configJSON"> 
                    <section class="nav-group" *ngSwitchCase="app.name">
                        <div class="nav-page-header">{{app.label | customTranslate : app.label}}</div>
                        <div *ngFor="let mod of app.modules">
                            <!-- SK24JUN24 adding language code in url -->
                            <a class="modules text-color" [routerLink]="['/app', languageCode, app.name, mod?.name, mod?.tabs[0]?.name]">
                                <div class="fc-nav-item cursor-pointer stroke-none"
                                    [ngClass]="moduleName == mod.name ? 'active' : ''"
                                    (click)="callNavService(mod)">
                                        <!-- <svg-icon [src]="mod.icon" class="nav-icon"></svg-icon> -->
                                        <img [src]="mod.icon" class="nav-icon">
                                        <span class="medium-font hide-on-collapse ms-2">{{mod.label | customTranslate : mod.label}}</span>
                                </div>
                            </a>
                        </div>
                    </section>
                </div> 
                <div class="app-version menu"> {{currentApplicationVersion}}</div>
            </div>
            
        </div>
    </div>
</div>