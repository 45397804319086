import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageHeaderComponent } from 'src/app/components/page-header/page-header.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DocumentComponent } from '../document/document.component';
import { EssComponent } from '../ess.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SideNavEmitService } from 'src/app/services/side-nav-emit.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-basicinfotab',
  standalone:true,
  templateUrl: './basicinfotab.component.html',
  imports: [CommonModule,PageHeaderComponent, FormsModule, ReactiveFormsModule, SuccessFailureMessageComponent,CardsComponent,PopoverComponent,FusionButtonComponent,AngularSvgIconModule,
   DocumentComponent, EssComponent,TranslateModule,CustomTranslatePipe],
  styleUrls: ['./basicinfotab.component.css']
})
export class BasicinfotabComponent implements OnInit {

  isHamClicked:boolean = false;

  sideNavsubscription: Subscription;

  essTabToShow: string = 'details';
  pageComponentName = 'Ess';

  hideOrShowNav: string = "side-nav-container";
  personalInfoForm! : FormGroup;
  contactInfoForm! : FormGroup;
  addressForm! : FormGroup;
  socialMediaForm! : FormGroup;
  basicInfoForm! : FormGroup;
  documentsForm! : FormGroup;
  sampleFile: File | any;
  emailRegex = "[A-Za-z0-9._%-+]+@[A-Za-z0-9._%-]+\\.[a-z]{2,10}";
  numberRegex = "^[0-9]{5,7}"; // updated this for text Validation and used them further down the lines
  // textRegex = "^[a-zA-Z ]*$"; // Added this for text Validation and used them further down the lines
  // tamilPattern = "^[அ-ஹா-ௌா-ௗ்ௐ]*$";
  mobileRgx = "^[0-9]{4,12}"; // updated this for text Validation and used them further down the lines
  textRegex = "^[a-zA-Z ]*$"; // Added this for text Validation and used them further down the lines
  errorMessage = '';
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  genders = [{name: 'Male', value: 'male'}, {name: 'Female', value: 'female'}, {name: 'Others', value: 'others'}];
  closeIcon = false;
  
  personalGetData: any;
  contactGetData:any;
  addressGetData:any;
  socialGetData:any;
  basicGetData:any;
  docGetData: any;
  userGetData:any;
  
  firstNameError=false;
  lastNameError = false;
  dobError=false;
  genderError = false;
  marStatusError = false;
  bloodGroupError = false;
  mardateError = false;
  officeMailError = false;
  personalMailError = false;
  phError = false;
  altPhError = false;
  add1Error = false;
  add2Error = false;
  cityError = false;
  stateError = false;
  pincodeError = false;
  countryError = false;
  getAllDatas!: object;
  companyId = this.globalValues.orgId;

  isDevMode: boolean = false;

  employeeId:any;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  @Input() selectedUser: any;
  @Input() fromHrmPage = false;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService,private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService, private globalValues:GlobalValuesService, private datePipe: DatePipe,private language :TranslateService) 
  {
    this.isDevMode = isDevMode();
    
    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => this.essTabToShow = navItem);

    /* Form groups created for the forms in ESS details tabs */
    this.personalInfoForm = this.formBuilder.group({
      'firstName':['', Validators.compose([Validators.pattern(this.textRegex), Validators.minLength(3), Validators.required])],
      'lastName' : ['', Validators.compose([Validators.pattern(this.textRegex), Validators.required])],  
      'dateOfBirth': ['', Validators.required],
      'gender': ['', Validators.required],
      'maritalStatus': ['', Validators.required],
      'bloodGroup': ['', Validators.required],
      'wedAnniversary': [''],
    });

    this.contactInfoForm = this.formBuilder.group({
      'officeMail':['', Validators.compose([Validators.pattern(this.emailRegex), Validators.required])],
      'personalMail' : ['', Validators.compose([Validators.pattern(this.emailRegex), Validators.required])],  
      'phNumber': ['', Validators.compose([Validators.required,Validators.pattern(this.mobileRgx)])],
      'altNumber': ['', Validators.compose([Validators.pattern(this.mobileRgx)])],
    });

    this.addressForm = this.formBuilder.group({
      'cAddress1':['', Validators.required],
      'cAddress2' : ['', Validators.required],  
      'cCity': ['', Validators.required],
      'cPinCode': ['',Validators.compose([Validators.minLength(5),Validators.maxLength(7), Validators.pattern(this.numberRegex), Validators.required])],
      'cState': ['', Validators.required],
      'cCountry': ['', Validators.required],
      'pAddress1':[''],
      'pAddress2' : [''],  
      'pCity': [''],
      'pPinCode': ['',Validators.compose([Validators.minLength(5),Validators.maxLength(7), Validators.pattern(this.numberRegex)])],
      'pState': [''],
      'pCountry': [''],
      'checkbox': []
    });

    this.socialMediaForm = this.formBuilder.group({
      'facebook':[''],
      'linkedIN' : [''],  
      'twitter': [''],
      'instagram': [''],
    });

      this.documentsForm = this.formBuilder.group({
      'docType' : ['', Validators.required],
      'docID' : ['', Validators.required],
      'issuedBy': ['', Validators.required], // Add this line
      'sponsor': ['', Validators.required],//RS27AUG24
      'file' : [''],
      'docGetID' : []
    })
  }

  bloodGroupDropdown:any; //SK27FEB24
  maritalStatusDropDown:any; //SK27FEB24
  ngOnInit(): void {
    // SK01APR24 getting data as input for parent
    this.employeeId = this.selectedUser?.employeeId;
    this.getData()

    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    } 

    // SK27FEB24 getting bloofgroup and marital status dropdowns from local
    // SK06MAR24 settings data as whole stored in local and getting dropdowns from it
    const dropDowns = JSON.parse((localStorage.getItem('settingsData'))as any);
    this.bloodGroupDropdown = dropDowns?.dropdowns?.filter((bg:any)=>bg.name === 'bloodgroup')[0] 
    || {valueList:[
      {key:"ab+", value:"AB+"},
      {key:"ab-", value:"AB-"},
      {key:"b+", value:"B+"},
      {key:"b-", value:"B-"},
      {key:"o+", value:"O+"},
      {key:"o-", value:"O-"},
      {key:"others", value:"Others"}
    ]};
    this.maritalStatusDropDown = dropDowns?.dropdowns?.filter((ms:any)=>ms.name === 'maritalstatus')[0]
    || {valueList:[
      {key:"married", value:"Married"},
      {key:"unmarried", value:"Un Married"}
    ]};
  }

  ngOnChanges(){
    this.employeeId = this.selectedUser?.employeeId;
    this.getData()
    
    if(this.isHamClicked){
      this.hideOrShowNav = "hideOrShowNav hide"
    } 
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sideNavsubscription.unsubscribe();
  }

  sideNavChange(event: any){
    //if(this.isDevMode) console.log("asasdasdasdasdasdas",JSON.stringify(event));
  }

  /** Function for Permanent address same as Current Address */
  handleCheckBox(event: any){
    if(event.target.checked){
      this.addressForm.value.pAddress1 = this.addressForm.value.cAddress1
      this.addressForm.value.pAddress2 = this.addressForm.value.cAddress2
      this.addressForm.value.pCity = this.addressForm.value.cCity
      this.addressForm.value.pPinCode = this.addressForm.value.cPinCode
      this.addressForm.value.pState = this.addressForm.value.cState
      this.addressForm.value.pCountry = this.addressForm.value.cCountry
    }
    else
    {
      this.addressForm.value.pAddress1 = ''
      this.addressForm.value.pAddress2 = ''
      this.addressForm.value.pCity = ''
      this.addressForm.value.pPinCode = null
      this.addressForm.value.pState = ''
      this.addressForm.value.pCountry = ''
    }
  }

  saveSuccess(){
    // MR 10JAN24 - Using messageOnPassFetch
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch', this.employeeId, this.successMsg);
    this.getData();
  }

  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);

  }
  
  /** These functions are used for Saving or Editing the data respectively in forms and sent to save and patch functions in api.service.ts*/
  savePersonalInfo(){
    if(this.isDevMode) console.log("Submit Personal Info");
    const value = this.personalInfoForm.value;
    const expiryTime: any = (localStorage.getItem('expires_at'));

    this.apiService.writeValue('patch','/employee/personal',{'firstName': value.firstName, 'lastName': value.lastName, 'dateOfBirth': value.dateOfBirth, 'gender': value.gender, 'maritalStatus': value.maritalStatus, 'bloodgroup': value.bloodGroup, 'weddingAnniversary': value.wedAnniversary, 'employeeId': this.employeeId})
    .subscribe({
      next: (personalInfo) => {
        if(this.isDevMode) console.log("Next :", personalInfo);
        this.saveSuccess();
      },
      error: (err: HttpErrorResponse) => {
        this.showError(err);
      }
    });
  }

  saveContactInfo(){
    if(this.isDevMode) console.log("Submit Contact Info");
    const value = this.contactInfoForm.value;

    this.apiService.writeValue('patch','/employee/contact',{'officialmail': value.officeMail, 'personalmail': value.personalMail, 'phone': value.phNumber, 'alternatephone': value.altNumber, 'employeeId': this.employeeId})
      .subscribe({
        next: (contactInfo) => {
          if(this.isDevMode) console.log("Next :", contactInfo);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
  }

  saveaddress(){
    if(this.isDevMode) console.log("Submit address Info");
    const value = this.addressForm.value;

    this.apiService.writeValue('patch','/employee/address',{'address1': value.cAddress1, 'address2': value.cAddress2, 'city1': value.cCity, 'postalcode1': value.cPinCode, 'state1': value.cState, 'country1': value.cCountry, 'address12': value.pAddress1, 'address22': value.pAddress2, 'city2': value.pCity, 'postalcode2': value.pPinCode, 'state2': value.pState, 'country2': value.pCountry, 'employeeId': this.employeeId})
      .subscribe({
        next: (addressInfo) => {
          if(this.isDevMode) console.log("Next :", addressInfo);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
  }

  saveSocialMediaInfo(){
    const value = this.socialMediaForm.value;
    this.apiService.writeValue('patch','/employee/social',{'facebook': value.facebook, 'linkedin': value.linkedIN, 'twitter': value.twitter, 'instagram': value.instagram, 'employeeId': this.employeeId})
      .subscribe({
        next: (smInfo) => {
          if(this.isDevMode) console.log("Next :", smInfo);
          this.saveSuccess();
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      });
    } 
  
  /** These are the GET method functions that returns the data from database */
  getESSDatas(){
    // SK01APR24 to get localstorage data
    this.selectedUser = JSON.parse(localStorage.getItem('userData') as any); 
    this.getData();
  }

  getData(){
    // MR 10JAN24 - Removed API Call
    /*
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    this.apiService.fetchValue('/hrm',params).subscribe((datas: any) => {
        if(this.isDevMode) console.log('OBJECTDATA',datas);
        this.personalGetData = datas?.loggedInUser;
        this.userGetData = datas?.loggedInUser[0];
        this.employeeId = datas?.loggedInUser[0]?.employeeId;
        this.setDatas();
    })
    */
    // MR 10JAN24 - Using data from HRM Component
    this.personalGetData = [];
    this.personalGetData.push(this.selectedUser);
    this.userGetData = this.selectedUser;
    this.setDatas();
  }

  addressCheckBox = false;
  setDatas(){
    this.personalInfoForm = this.formBuilder.group({
      'firstName':[this.userGetData?.firstName, Validators.compose([Validators.pattern(this.textRegex), Validators.minLength(3), Validators.required])],
      'lastName' : [this.userGetData?.lastName, Validators.compose([Validators.pattern(this.textRegex), Validators.required])],  
      'dateOfBirth': [this.datePipe.transform(this.userGetData?.personalinfo?.dateOfBirth, "YYYY-MM-dd"), Validators.required],
      'gender': [this.userGetData?.personalinfo?.gender, Validators.required],
      'maritalStatus': [this.userGetData?.personalinfo?.maritalStatus, Validators.required],
      'bloodGroup': [this.userGetData?.personalinfo?.bloodgroup, Validators.required],
      'wedAnniversary': [this.userGetData?.personalinfo?.weddingAnniversary],
    });
    this.contactInfoForm = this.formBuilder.group({
      'officeMail':[this.userGetData?.email, Validators.compose([Validators.pattern(this.emailRegex), Validators.required])],
      'personalMail' : [this.userGetData?.contactinfo?.personalmail, Validators.compose([Validators.pattern(this.emailRegex), Validators.required])],  
      'phNumber': [this.userGetData?.phone,Validators.compose([Validators.required,Validators.pattern(this.mobileRgx)])], //SK21DEC23
      'altNumber': [this.userGetData?.contactinfo?.alternatephone, Validators.compose([ Validators.pattern(this.mobileRgx)])],
    });
    this.addressForm = this.formBuilder.group({
      'cAddress1':[this.userGetData?.addressinfo?.currentaddress.address1, Validators.required],
      'cAddress2' : [this.userGetData?.addressinfo?.currentaddress.address2, Validators.required],  
      'cCity': [this.userGetData?.addressinfo?.currentaddress.city, Validators.required],
      'cPinCode': [this.userGetData?.addressinfo?.currentaddress.postalcode, Validators.compose([Validators.minLength(5), Validators.maxLength(7), Validators.pattern(this.numberRegex), Validators.required])],
      'cState': [this.userGetData?.addressinfo?.currentaddress.state, Validators.required],
      'cCountry': [this.userGetData?.addressinfo?.currentaddress.country, Validators.required],
      'pAddress1':[this.userGetData?.addressinfo?.permanentaddress.address1],
      'pAddress2' : [this.userGetData?.addressinfo?.permanentaddress.address2],  
      'pCity': [this.userGetData?.addressinfo?.permanentaddress.city],
      'pPinCode': [this.userGetData?.addressinfo?.permanentaddress.postalcode, Validators.compose([Validators.minLength(5), Validators.maxLength(7), Validators.pattern(this.numberRegex)])],
      'pState': [this.userGetData?.addressinfo?.permanentaddress.state],
      'pCountry': [this.userGetData?.addressinfo?.permanentaddress.country],
      'checkbox': []
    });
    this.socialMediaForm = this.formBuilder.group({
      'facebook':[this.userGetData?.socialinfo?.facebook],
      'linkedIN' : [this.userGetData?.socialinfo?.linkedin],  
      'twitter': [this.userGetData?.socialinfo?.twitter],
      'instagram': [this.userGetData?.socialinfo?.instagram]
    });

    if(
      this.addressForm.value.pAddress1 === this.addressForm.value.cAddress1 && this.addressForm.value.pAddress1 != ('' || null) &&
      this.addressForm.value.pAddress2 === this.addressForm.value.cAddress2 &&
      this.addressForm.value.pCity === this.addressForm.value.cCity &&
      this.addressForm.value.pPinCode === this.addressForm.value.cPinCode &&
      this.addressForm.value.pState === this.addressForm.value.cState &&
      this.addressForm.value.pCountry === this.addressForm.value.cCountry
    ){
      this.addressForm.controls['checkbox'].patchValue(true);
    }
  }

  /** These functions used to edit the form data that returned from database */
  /** the handle functions used to validate the form fields and to display the error message if any */
  handleFirstName(event: any): void{
    if (event.target.value.length > 2 || event.target.value.length === '') {
      this.firstNameError = false;
    } else {
      this.firstNameError = true;
    }
  }

  handleLastName(event: any): void{
    if (event.target.value.length >= 1 || event.target.value.length === '') {
      this.lastNameError = false;
    } else {
      this.lastNameError = true;
    }
  }

  handleDOB(event: any): void{
    if (event.target.value != '') {
      this.dobError = false;
    } else {
      this.dobError = true;
    }
  }

  handleGender(event: any): void{
    if (event.target.checked) {
      this.genderError = false;
    } else {
      this.genderError = true;
    }
  }

  handleMarStat(event: any): void{
    if (event.target.value != '') {
      this.marStatusError = false;
    } else {
      this.marStatusError = true;
    }
  }

  handleBloodgroup(event: any): void{
    if (event.target.value != '') {
      this.bloodGroupError = false;
    } else {
      this.bloodGroupError = true;
    }
  }

  handleOfficeMail(event: any): void{
    if (event.target.value != '' && event.target.value.match(this.emailRegex)) {
      this.officeMailError = false;
    } else {
      this.officeMailError = true;
    }
  }

  handlePersonalMail(event: any): void{
    if (event.target.value != '' && event.target.value.match(this.emailRegex)) {
      this.personalMailError = false;
    } else {
      this.personalMailError = true;
    }
  }

  handlePhNum(event: any): void{
    if (event.target.value != '' && event.target.value.match(this.mobileRgx)) {
      this.phError = false;
    } else {
      this.phError = true;
    }
  }

  handleAltNum(event: any): void{
    if (event.target.value != '' && event.target.value.match(this.mobileRgx)) {
      this.altPhError = false;
    } else {
      this.altPhError = true;
    }
  }

  handleAddress1(event: any): void{
    if (event.target.value != '' && event.target.value.length >= 3) {
      this.add1Error = false;
    } else {
      this.add1Error = true;
    }
  }

  handleCity(event: any): void{
    if (event.target.value != '') {
      this.cityError = false;
    } else {
      this.cityError = true;
    }
  }

  handleState(event: any): void{
    if (event.target.value != '' ) {
      this.stateError = false;
    } else {
      this.stateError = true;
    }
  }

  handlePinCode(event: any): void{
    if (event.target.value != '' && event.target.value.match(this.numberRegex) && event.target.value.length >= 6) {
      this.pincodeError = false;
    } else {
      this.pincodeError = true;
    }
  }

  handleCountry(event: any): void{
    if (event.target.value != '') {
      this.countryError = false;
    } else {
      this.countryError = true;
    }
  }
  popOverItemClick(){
    alert("Function called on click");
  }

  clickedButton($event: any){
    if(this.isDevMode) console.log("The button is clicked and here is the $Event", $event);
  }

  cardHeaderIconClick(){
    alert('Header icon is clicked in Card');
  }

}
