import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { Modal } from 'bootstrap';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, FusionButtonComponent, CustomTranslatePipe],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() modalTitle: any;
  @Input() isModalOpen =  false;
  @Input() modalSize = '';
  @Input() saveButtonValue = 'Save';
  @Input() modalFooter = true;
  @Input() cancelNXTEmit : any;
  @Output() saveButtonEmit  = new EventEmitter<any>;
  @Output() cancelButtonEmit = new EventEmitter<any>;
  modalDirect!: Modal;
  @ViewChild('demoModal') demoModal!: ElementRef<any>; //to open the bootstrap modal based on click function in ts file


  constructor() { }

  ngOnChanges(changes: SimpleChanges){
    if(changes['isModalOpen']){
      if(this.isModalOpen === true){
        this.modalOpen();
      } 
    }

    if(changes['cancelNXTEmit']){
      if(this.cancelNXTEmit === true){
        this.closeModal();
      }
    }
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.cancelButtonEmit.emit(false);
  }

  saveModal() {
    this.saveButtonEmit.emit();
  }

  modalOpen(){
    if(this.demoModal){
      this.modalDirect = new Modal(this.demoModal.nativeElement, { backdrop: 'static' });
      this.modalDirect.show();
    }
  }

}
