import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  public errorMessage: string = '';

  constructor(private router: Router) { }

  public handleError = (error: HttpErrorResponse) => {
    if (error.status === 500) {
      this.handle500Error(error);
    }
    else if (error.status === 404) {
      this.handle404Error(error)
    }
    else {
      this.handleOtherError(error);
    }
  }

  private handle500Error = (error: HttpErrorResponse) => {
    this.createErrorMessage(error);
    this.router.navigate(['/500']);
  }

  private handle404Error = (error: HttpErrorResponse) => {
    this.createErrorMessage(error);
    this.router.navigate(['/404']);
  }
  private handleOtherError = (error: HttpErrorResponse) => {
    this.createErrorMessage(error); //TODO: this will be fixed later; 
  }

  private createErrorMessage = (error: HttpErrorResponse) => {
    this.errorMessage = JSON.parse(error.message).error.message;
    //this.errorMessage = error.error ? error.error : error.statusText;

    // We need to sort this function properly 
    // in some cases error is given in 'JSON.parse(error.message).error.message', in some cases its 'error.error' in some cases its 'error.statusText', in some its 'JSON.parse(error.message).message'
    // we need write accordingly, for instance while uploading logo image it throws Status 413 Payload Too Large'
    // but this detail is not present in JSON.parse(error.message).error.message;
    // it is preent in JSON.parse(error.message).statusText or JSON.parse(error.message).message
  }
}