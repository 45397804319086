import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
const { Popover } = require('node_modules/bootstrap/dist/js/bootstrap.esm.min.js');
import { TranslateModule,TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-popover',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent implements OnInit {

  @Input() isBootstrap: boolean = false;
  @Input() dynamicPopover: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
    Array.from(document.querySelectorAll('[data-bs-toggle="popover"]'))
    .forEach(popoverNode => new Popover(popoverNode))
  }

}
