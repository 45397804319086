import { Component, EventEmitter, OnInit, Output, isDevMode } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { ApiService } from 'src/app/services/api.service';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { HttpParams } from '@angular/common/http';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-pay-slips-tab',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    DataTableComponent,
    FusionButtonComponent,
    DropDownComponent,
    TranslateModule,
    NgxSpinnerModule,
    CustomTranslatePipe
  ],
  templateUrl: './pay-slips-tab.component.html',
  styleUrls: ['./pay-slips-tab.component.css']
})
export class PaySlipsTabComponent implements OnInit {

  checkedRow: any;
  dropDownValue: any = 'Select month and year'
  disableButton = false;
  nameFilter: any;
  deptFilter: any;
  locationFilter: any;
  designationFilter:any;
  ptLocationFilter:any;
  paymentModeFilter:any;
  dropDownList: Array<any> = [];
  multipleFilterArray : any;
  employeeDataTable: Array<any> = [];

  selectedCheckbox!: Array<any>;
  allEmployeeData!: Array<any>;
  tableData: Array<any> = [];
  globalEmployeeId:any;
  queryMonth:any;
  isDevMode: boolean = false;
  headers:Array<string> = [];
  displayedColumns:Array<string> = [];
  settingsData:any
  errorMessage = '';
  downloadCurrentDisable = true;
  typeSelected = ''; //SK18JUN24
  isDynamicHRM = false; //SK18JUN24
  configJSON:any; //SK18JUN24
  filterKeys:any = {}; //SK18JUN24
  finalData:any; //SK18JUN24
  cumulativeDataColumns: Array<any> = []; //SK16JUL24

  @Output() alertMessageEmit = new EventEmitter<any>;
  constructor(private currencyPipe:CurrencyPipe,private apiService: ApiService, private alertService: AlertService, private spinnerService: NgxSpinnerService,private translate : TranslateService) { 
    this.isDevMode = isDevMode();
    this.typeSelected = 'ball-spin-clockwise-fade'; //SK18JUN24 loader type
  }

  ngOnInit(): void {
    this.monthAndYear();
    this.buttonValidation();
   

    this.apiService.fetchValue('/user').subscribe((settings:any) => {
      // SK18JUN24 get the config json details
      const userDataConst:any = localStorage.getItem('userData');
      if(userDataConst) {
        const loggedInUserData = JSON.parse(userDataConst).apps;
        loggedInUserData?.forEach((appArr:any) => {
          if(appArr.name === 'hrm'){
            this.isDynamicHRM = true;
            appArr?.modules?.forEach((modArr:any) => {
              if(modArr?.name === 'payroll'){
                modArr?.tabs.forEach((tabArr:any) => {
                  if(tabArr?.name === 'payroll'){
                    this.configJSON = tabArr?.config;
                  }
                });
              }
            });
          }
        });
      } else {
        console.log('waiting for the api response');
      }
      this.settingsData = [];
      this.settingsData = settings.settings[0].salary.components;
      this.cumulativeDataColumns = this.configJSON?.cumulativeDataColumns || [];

      const compDetails: any[] = [];
      this.settingsData.forEach((element:any) => {
        compDetails.push(element.name);
      });
      // SK18JUN24 validate and get the dynamic headerLabel  config if any
      if(this.configJSON?.headerLabels1 && this.configJSON?.headerLabels2){
        this.headers = [...this.configJSON?.headerLabels1, ...compDetails,...this.configJSON?.headerLabels2];
        console.log('this.headers',this.headers)
      } else {
        const empDetails = ['Employee Id', 'Employee Name','Department', 'Designation','Location','Working Days','Loss Of Pay','Casual Leave','Sick Leave','PT Location','Bank Name','IFSC Code','Bank Account','Payment Mode'];
        const empDetails2 = ['Gross', 'OT Amount', 'Reimbursements', 'TDS', 'Net','Payroll Status'];
        this.headers = [...empDetails, ...compDetails,...empDetails2];
        console.log('this.headers',this.headers)
      }

      const displayedCompDetails: any[] = [];
      this.settingsData.forEach((element:any) => {
        displayedCompDetails.push(element.name + '_' + element.type);
      });

      // SK18JUN24 validate and get the dynamic displayed comuns config if any
      if(this.configJSON?.displayedColumns1 && this.configJSON?.displayedColumns2){
        this.displayedColumns = [...this.configJSON?.displayedColumns1,...displayedCompDetails,...this.configJSON?.displayedColumns2]
        console.log('this.headers',this.displayedColumns)
      } else {
        const displayedCol = ['employeeId','employeeName','department','designation','location','workingdays','lossOfPay','casualLeave','sickLeave','ptLocation','bankName','ifsc','bankAccount','paymentMode'];
        const displayedCol2 = [ 'gross','otAmount', 'reimbursements', 'tds','netPay','payrollStatus'];
        this.displayedColumns = [...displayedCol,...displayedCompDetails,...displayedCol2]
        console.log('this.headers',this.displayedColumns)
      }
    });

  }

  // Number of months to be listed in dropdown is pushed in that array
  monthAndYear() {
    for (let i = 0; i <= 12; i++) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - i);
      var month = date.toLocaleString("default", { month: "long" });
      var year = date.getFullYear();
      this.dropDownList.push(month + " " + year);
    }
  }

  /** button will be enabled only 
      Anu of the checkbox is checked in that table
      And the dropdown list should be selected
  * */ 
  buttonValidation(){
    this.disableButton = this.checkedRow === undefined || this.checkedRow?.length === 0 || this.dropDownValue === 'Select month and year' ;
  }

  /* Pushing the leaves data based on selected month,
    this function executed when selecting the month in dropdown */
  getDataFunction(){
    const parameter = new HttpParams({
      fromObject:{
        month : this.queryMonth
      }
    });
    // this.spinnerService.show();
    // SK18JUN24 getting the table details directly from server
    this.apiService.fetchValue('/hrm/allemployees',parameter).subscribe((data:any) => {
      // this.spinnerService.hide();
      this.employeeDataTable = data.payrollData;
      this.finalData = JSON.stringify(data.payrollData);
      // SK18JUN24 configuring filter columns
      if(this.configJSON?.filterColumns?.length > 0){
        this.multipleFilterArray = {};
        const filtObjs:any = {};
        this.configJSON.filterColumns.forEach((element:any) => {
          this.multipleFilterArray[element] = null;
          filtObjs[element] = [];
        });
        this.employeeDataTable.forEach((element:any) => {
          this.configJSON.filterColumns.forEach((key:any) => {
            if(Object.keys(element).includes(key)){
              filtObjs[key].push(element[key])
              this.multipleFilterArray[key] =(new Set(filtObjs[key]));
            }
          });
        });
        this.configJSON.multipleFilterArray = this.multipleFilterArray;
      }
    });
  }

  // dropdown selected value will be emitted from child component
  /** The selected month will be received here and the initialData function and leaavesPushFunction will be executed  */
  emittedValue(event: any) {
    this.dropDownValue = event.data;
    this.checkedRow = [];
    const number = new Date('01' + " " + event.data).getMonth() + 1;
    const year = new Date('01' + " " + event.data).getFullYear();
    this.queryMonth = year + '-' + number;
    this.getDataFunction();
    this.buttonValidation();
  }

  // Functionality for downlaod button. converts the string file to to blob and it is made downloadable
  downloadButton() {
    this.checkedRow.forEach((element:any) => {
      if(element?.currentFile){
        this.processFile(element?.currentFile, element?.employeeName, element?.payrollMonth);
      }
    });
  }

  // The selected checkbox data will be assigned in ana array
  selectedCheckBox(event: any) {
    this.checkedRow = event;
    this.buttonValidation();
  }

  // this function is used to round the decimal digits, that exceeds two decimal points
  numberRoundDecimal(number:any) {
    return Math.round((number+Number.EPSILON)*Math.pow(10,2))/Math.pow(10,2);
  }

   // the AWS S3 link will be converted to a blob here
  processFile(url:any, empName:string, payrollMonth: string) : any{
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = (e1:any) =>  {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        const blob = request.response;
        // then blob is passed here to read the as text
        this.onChange(blob, empName, payrollMonth);
    };
    request.send();
  }

  // finally base64 string will be generated here
  onChange(event:any, empName:any, payrollMonth:any) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      var linkSource = e.target.result;
      const byteCharacters = window.atob(linkSource);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
    
      const url = URL.createObjectURL(blob);
      const fileName = empName + "_" + payrollMonth;
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.click();
      this.alertService.messageOnPass('success', 'Payslip(s) downloaded successfully');  
    };
    reader.readAsText(event);
  }
  
  tableCheckedDataa(checkedArray:any){
    this.checkedEmployees = [];
    this.checkedEmployees = checkedArray;
    if(this.checkedEmployees.length === 0){
      this.downloadCurrentDisable = true;
    } else {
      this.downloadCurrentDisable = false;
    }
    if(this.isDevMode) console.log('this.checkedEmployees',this.checkedEmployees);
  }
  saveSuccess(data:any) {
    this.alertService.messageOnPass('success', data)
    this.ngOnInit();
    this.errorMessage = '';
  }
  checkedEmployees:any;
  downloadCurrentEmployees(){
    const csv = this.convertToCsv(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'Pay slip.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }
  convertToCsv(objArray: any[]) {
    let  displayedComp:Array<any>=[];
    let objectArray: Array<any> = [];
    objArray.forEach(element => {
      objectArray.push(Object.keys(element));
    });
    console.log(objArray,'to check List')
    this.settingsData.forEach((element:any) => {
    displayedComp.push(element.name + '_' + element.type);
    console.log(displayedComp,'to check  CompanyDetailsList')
    });
    displayedComp = ['employeeId', 'employeeName','department', 'designation','location','lossOfPay',
    'casualLeave','sickLeave','ptLocation','ifsc','bankAccount','paymentMode',...displayedComp,'gross',
    'reimbursements','otAmount','tds','netPay','payrollStatus']
    console.log(displayedComp,'to CompanyDetailsList')
    var selectedObjectsForCSV = this.filterObjectKeys(objArray,displayedComp);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    var objArrays = objArray; // Your array of objects
    var displayedComps= displayedComp; // Replace with your dynamic key
    let dynamicObject: { [key: string]: any } = {}; 
// Create a dynamic object based on displayedComp
  for (const key of displayedComps) {

  dynamicObject[key] = key; // Add a key-value pair based on the key
  // Add the dynamic object to the beginning of the array
  objArrays.unshift(dynamicObject);
}
objArray.reverse();

    let str = '';
    array.unshift(dynamicObject)
      for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        } if(typeof array[i][index] == 'object'){
          line += JSON.stringify(array[i][index]).replace(/,/g,"++");
        }
        else{
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 
  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) { // keys are the required fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undefined
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }

  // SK18JUN24 filter the table data based on keys checked
  searchFilterData(event:any){
    this.filterKeys[event[0]] = event[1];
    this.employeeDataTable = [];
    let isDataChecked = false;
    for(let key of Object.keys(this.filterKeys)){
      if(this.filterKeys[key].length > 0){
        isDataChecked = true;
      }
    }
    if(!isDataChecked){
      this.employeeDataTable = JSON.parse(this.finalData);
    } else {
      for(let key of Object.keys(this.filterKeys)){
        const data = JSON.parse(this.finalData).filter((name:any)=> this.filterKeys[key]?.includes(name[key]));
        const data1 = [...this.employeeDataTable,...data];
        this.employeeDataTable = this.removeDuplicates(data1);
      }
    }
  }

  // SK18JUN24 to remove duplicate object form two arrays
  removeDuplicates(objects: any) {
    const seen = new Set<string>();
    const uniqueObjects: any = [];

    objects.forEach((obj:any) => {
        const key = JSON.stringify(obj);
        if (!seen.has(key)) {
            seen.add(key);
            uniqueObjects.push(obj);
        }
    });
    return uniqueObjects;
  }

}
