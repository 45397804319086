import { Component, EventEmitter, OnInit, Output, isDevMode } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { CardsComponent } from '../cards/cards.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-empnotify',
  standalone: true,
  imports: [CommonModule, CardsComponent, RouterModule, PageHeaderComponent, CustomTranslatePipe],
  templateUrl: './empnotify.component.html',
  styleUrls: ['./empnotify.component.css']
})
export class EmpnotifyComponent implements OnInit {

  notifyPopup = false;
  scrollBar = false;
  empNotification: any;
  notifyLength: Array<any> = [];
  Id: Array<any> = [];
  @Output() notifyCount = new EventEmitter();
  @Output() appliedCount = new EventEmitter();
  count: any;
  leaveTypes=['Casual leave', 'Sick leave', 'Maternity leave', 'Paternity leave', 'Marriage leave','Componsation leave'];
  leaveArray: Array<any> = [];
  expenseArray: Array<any> = [];
  leaveNotification:any;
  isDevMode: boolean = false;
  essUser: any;
  appliedCounts: any;
  permissionList:any;
  languageCode: any;//SKS11OCT24

  constructor(private apiService: ApiService, private router: Router, private appComponent: AppComponent, private globalValues: GlobalValuesService, private authServ: AuthService, private datePipe: DatePipe, private routeActive: ActivatedRoute) { 
    this.globalValues.loggedInuserDetails$.subscribe((data:any) => { //SK 20DEC23 assigned from user data from global value service
      console.log('Notification data',data);
      this.permissionList = data?.permissions;
      console.log('Permission List',this.permissionList);
    });

    this.routeActive.paramMap.subscribe((params:any) => {
      const userData = JSON.parse(localStorage.getItem('userData') as any);
      if(userData){
        this.languageCode= userData.languageCode    //SKS11OCT24
      }else{
        this.languageCode=  'en'
      }
    })
  }

  ngOnInit(): void {
    this.getNotification(); //SK 17JAN24 - notification called on component
  }


  getNotification(){
    this.apiService.fetchValue('/employee/notification').subscribe((data: any) => {
      if (isDevMode()) 
      this.empNotification = data.reverse();
      this.notifyLength = this.empNotification?.length;
      this.count = 0;
      for (let i = 0; i < this.empNotification?.length; i++) {
        this.empNotification[i].appliedOn = this.datePipe.transform(this.empNotification[i].appliedOn, 'mediumDate')
        if (this.empNotification[i]?.markAsRead === false) {
          this.count++;
        }
      }
      
      this.notifyCount.emit(this.count);// used once leaves/expense applied in apply leaves it will increment by 
      this.apiService.fetchValue('/approval').subscribe((data:any) => {
        if(this.isDevMode)
        this.leaveNotification = [];
        this.leaveNotification = data?.reverse();
        this.leaveNotification.forEach((element:any) => {
          if(element.leaveType != undefined){    
            this.leaveArray.push(element);
          }
          if(element.category != undefined){  
            this.expenseArray.push(element);
          }
          if(element.category) {
            element.appliedOn = this.datePipe.transform(element.appliedOn, 'mediumDate');
            element.type = 'expenses';
          } else {
            element.appliedon = this.datePipe.transform(element.appliedon, 'mediumDate');
            element.type = 'leaves';
          }
        });

        
        this.appliedCounts = 0;
        for (let i = 0; i < this.leaveNotification?.length; i++) {
            this.appliedCounts ++;
        }
        this.appliedCount.emit(this.appliedCounts);//used once leaves/expense getting approved/reject it will process
      });
    });
  }

  showApproval(event:any,from:string){
    // SK07MAR24 modified the condition for routing the page based on type of notification
    if(from === 'apprej'){
      if(event.status === 'Approved' || event.status === 'Rejected'){
        if(['Casual leave', 'Sick leave', 'Maternity leave', 'Paternity leave', 'Marriage leave', 'Compensation Off', 'Loss of Pay'].includes(event.type)){
          this.globalValues.activeNavModule('leaves');
          this.router.navigate(['/ess/', this.languageCode || "en"],//RA27AUG24
          {
            queryParams:{
              activeNav:'leaves',
            }
          });
        } else {
          this.globalValues.activeNavModule('expenses');
          this.router.navigate(['/ess/', this.languageCode || "en"],//RS27AUG24
          {
            queryParams:{
              activeNav:'expenses',
            }
          });
        }
      }
    } else {
      this.globalValues.activeNavModule('approvals');
      if(event === 'leave'){
        this.router.navigate(['/hrm/', this.languageCode || "en"],//RS27AUG24
        {
          queryParams:{
            activeNav:'approvals',
            approvalType: 'Leaves'
          }
        });
      } else {
        this.router.navigate(['/hrm/', this.languageCode || "en"],//RS27AUG24
        {
          queryParams:{
            activeNav:'approvals',
            approvalType: 'Expense'
          }
        });
      }
    }
  }

  markasRead() { // enable while leaves/expenses are in approved stage...
    this.apiService.writeValue('patch','/employee/notification',null).subscribe((data: any) => {
        console.log('markasRead',data);
        this.getNotification();
    })
  }
}