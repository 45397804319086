<div class="form-card">
  <div class="col-md-12 mt-3">
      <div class="static-width-for-card width-100">
          <app-cards #hideAddDependent [inlineDetailed]="true">
              <div class="header-section flex content-space-between">
                  <span>{{ 'HEADINGS.DEPENDENTS' | customTranslate : 'Dependents' }}</span>
              </div>

              <div class="body-section card-text">
                  <div class="card-btn show-detail-view text-primary"(click)="addDependent()" *ngIf="!isEditButtonOn">
                    {{ 'DEPENDENTS_INFO.ADDDEP' | customTranslate : 'Add Dependent' }}
                  </div>
              </div>

              <div class="card-expanded-section detailed-section" plain>
                  <div class="card-header">{{ 'DEPENDENTS_INFO.FAMILYMEB' | customTranslate : 'FAMILY MEMBERS' }}</div>
                  <div class="card-body">
                      <form [formGroup]="familyMembersForm">
                          <div class="field-sets">
                              <div class="field-set-row flex">
                                 <div class="field-input col-md-6" [class]="this.familyMembersForm.controls['fullName'].invalid && this.familyMembersForm.controls['fullName'].touched  ? 'has-error' : '' ">
                                  <!--RG26AUG24 changed the label UI -->
                                  <label for="fullName" class="form-label label-txt modal-field-label" cursor="none">{{ 'DEPENDENTS_INFO.FULLNAME' | customTranslate : 'Full Name' }}*</label> 
                                  <input type="text" formControlName="fullName" placeholder="" class="form-control" id="name">
                                   <!-- Updated the error message with formbuilder pattern-->
                                    <p class="error-msg">
                                      <span *ngIf="familyMembersForm.controls['fullName'].invalid && familyMembersForm.controls['fullName'].touched && !this.familyMembersForm.controls['fullName'].hasError('pattern')">
                                          <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                      </span>
                                      <span *ngIf="familyMembersForm.controls['fullName'].touched && this.familyMembersForm.controls['fullName'].hasError('pattern')">
                                          <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NAME' | customTranslate : 'Please enter a valid name' }}
                                      </span>
                                    </p>
                                </div>
                                
                                <div class="field-input col-md-4" [class]="familyMembersForm.controls['relation'].invalid && familyMembersForm.controls['relation'].touched  ? 'has-error' : '' ">
                                  <label for="relation" class="form-label label-txt modal-field-label">{{ 'DEPENDENTS_INFO.RELATION' | customTranslate : 'Relation' }}*</label> 
                                  <select id="relation" formControlName="relation"
                                       placeholder="mother" class="form-select">
                                       <option *ngFor="let et of relationDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                                   </select>
                                       <p class="error-msg"><span
                                   *ngIf="this.familyMembersForm.controls['relation'].invalid && this.familyMembersForm.controls['relation'].touched">
                                   <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                               </div>

                                <div class="field-input col-md-6" [class]=" familyMembersForm.controls['dob'].invalid && familyMembersForm.controls['dob'].touched ? 'has-error' : '' ">
                                  <label class="form-label label-txt modal-field-label" for="dob">{{ 'PERSONAL_INFO.DATEOFBIRTH' | customTranslate : 'Date of Birth' }}*</label>   
                                  <input type="date" max="9999-12-31" class="form-control" formControlName="dob"
                                      id="dateOfBirth" name="date" format="dd-mm-yyyy" />
                                       <p class="error-msg"><span
                                    *ngIf="this.familyMembersForm.controls['dob'].invalid && this.familyMembersForm.controls['dob'].touched">
                                    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                            </div>
                          </div>
                      </form>
                  </div>
                  <div class="card-footer border-0">
                      <div class="center-button text-right">
                          <div>{{ 'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button" }}</div>
                          <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                              [buttonType]="'text-only card-btn hide-detail-view'"
                              [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); famCancelButton()"
                              [fcBtnId]="'myId'"></app-fusion-button>
                              <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                               [buttonType]="'input-button'"  [dataDismiss]="''" (buttonClickEmit)="clickedButton($event);saveFamilyMembers(); hideAddDependent.hidePlainView()"
                              [buttonDisable]="!familyMembersForm.valid"></app-fusion-button>
                      </div>
                    <!-- <hr>  -->
                      <div class="card-btn show-detail-view text-primary"(click)="addDependent()" *ngIf="!isEditButtonOn">
                        {{ 'DEPENDENTS_INFO.ADDDEP' | customTranslate : 'Add Dependent' }}
                      </div>
                </div>
                  </div>
                </app-cards>
            </div>
          </div>

          <div class="col-md-12 mt-1" *ngFor="let user of famGetData; let i=index"> 
            <div class="static-width-for-card width-100" style="height: 100%">
               <app-cards #hideAll [inlineDetailed]="true" [modalDeleted]="true" [modalId]="'familyInfoModal'">
                  <div class="header-section flex content-space-between">
                    <span> {{ 'DEPENDENTS_INFO.FAMILYMEB' | customTranslate : 'FAMILY MEMBERS' }}:</span>                   
                    <div class="header-section flex content-space-between">
                      <div class="card-btn show-detail-view text-primary" *ngIf="!isEditButtonOn">
                        <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                          class="card-icon" (click)="onEditFam(user);" style="cursor: pointer;"></svg-icon>
                     </div>
                     <div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#familyInfoModal">
                        <svg-icon src="../assets/images/icons/general/delete.svg"
                         class="card-icon ms-3" (click)="famOnDelete(user)" style="cursor: pointer;"></svg-icon>
                    </div>     
                  </div>
               </div>

              <div class="card-expanded-section modal-content modal-section" modalDelete> 
                 <div class="modal-header">
                  <div class="modal-title fs-6" id="familyModalLabel"> {{ 'DEPENDENTS_INFO.DEL' | customTranslate : 'Delete Family' }}</div>
                  <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                 [fcBtnId]="'close'"></app-fusion-button>
                 </div>
                    <div class="modal-body">
                       <p>{{ 'DEPENDENTS_INFO.DELETE' | customTranslate : 'Are you sure you want to delete your family details' }}</p>
                    </div>
                 <div class="modal-footer">
                  <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL_THANKS' | customTranslate : 'No Thanks' }}"
                     [buttonType]="'text-only'" [dataDismiss]="'modal'" (buttonClickEmit)="clickedButton($event); famCancelButton()"
                       [fcBtnId]="'myId'" [dataDismiss]="'modal'"></app-fusion-button>
                       <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.DELETE' | customTranslate : 'Delete' }}"
                     [fcBtnId]="'modalSave'" (buttonClickEmit)="deleteFM()" [dataDismiss]="'modal'"></app-fusion-button>      
                </div>
            </div> 
            
    
           <div class="body-section card-text">
             <div class="d-flex flex-column">
               <div class="d-flex flex-row justify-content-start width-100" style="margin-left: -40px">
                 <div class="d-flex flex-column ms-5 width-25">
                      <label class="label-head" style="font-size: 15px">{{user.fullName}}</label>
                      <label class="label-sub" style="font-size: 15px">{{user.relation}}</label>
                      <label class="label-sub">{{user.dateOfBirth | date: 'mediumDate'}}</label>
                        </div>
              </div>
          </div>
      </div>
       
      
        <!--family edit form  -->
      <div class="card-expanded-section detailed-section" plain>
        <div class="card-header">
            <div class="modal-title fs-6" id="cardDetailedModalLabel"> {{ 'DEPENDENTS_INFO.EDITFAMMEB' | customTranslate : 'Edit Family Member Details' }}</div>
        </div>
        <div class="card-body">
            <form [formGroup]="familyMembersForm">
                <div class="field-sets">
                    <div class="field-set-row flex">
                        <div class="field-input col-md-4" [class]=" familyMembersForm.controls['fullName'].invalid && familyMembersForm.controls['fullName'].touched ? 'has-error' : '' ">
                          <label for="fullName" class="form-label label-txt modal-field-label">{{ 'DEPENDENTS_INFO.FULLNAME' | customTranslate : 'Full Name' }}*</label>
                          <input type="text" formControlName="fullName" placeholder="" class="form-control" id="name">
                            <p class="error-msg"><span
                                *ngIf="this.familyMembersForm.controls['fullName'].invalid && this.familyMembersForm.controls['fullName'].touched">
                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                        </div>

                        <div class="field-input col-md-4" [class]=" familyMembersForm.controls['relation'].invalid && familyMembersForm.controls['relation'].touched ? 'has-error' : '' ">
                          <label for="relation" class="form-label label-txt modal-field-label">{{ 'DEPENDENTS_INFO.RELATION' | customTranslate : 'Relation' }}*</label>
                          <select id="relation" formControlName="relation"
                              placeholder=" " class="form-select" [value]="user.relation">
                              <option *ngFor="let et of relationDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                          </select>
                          <p class="error-msg"><span
                              *ngIf="this.familyMembersForm.controls['relation'].invalid && this.familyMembersForm.controls['relation'].touched">
                              <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                        </div>
                        <div class="field-input col-md-6" [class]=" familyMembersForm.controls['dob'].invalid && familyMembersForm.controls['dob'].touched ? 'has-error' : '' ">
                          <label class="form-label label-txt modal-field-label" for="dob">{{ 'PERSONAL_INFO.DATEOFBIRTH' | customTranslate : 'Date of Birth' }}*</label>   
                          <input type="date" max="9999-12-31" class="form-control"
                                formControlName="dob" id="dob" name="date"
                                format="dd-mm-yyyy"/>
                                   <p class="error-msg"><span
                            *ngIf="this.familyMembersForm.controls['dob'].invalid && this.familyMembersForm.controls['dob'].touched">
                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                        </div>  
                    </div>
                </div>
            </form>
        </div>  
        <div class="card-footer border-0">
          <div class="center-button text-right">
              <div>{{ 'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button" }}</div>
              <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                  [buttonType]="'text-only card-btn hide-detail-view'"
                  [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); famCancelButton()"
                  [fcBtnId]="'myId'" [dataDismiss]="''"></app-fusion-button>
                  <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                   [buttonType]="'input-button'"  [dataDismiss]="''" (buttonClickEmit)=" clickedButton($event); saveFamilyMembers(); hideAddDependent.hidePlainView()"
                  [buttonDisable]="!familyMembersForm.valid"></app-fusion-button>

          </div>
        </div>
      </div>
    </app-cards>
  </div> 
</div>
</div>

<!-- emergency card -->
<div class="form-card">
  <div class="col-md-12 mt-3">
      <div class="static-width-for-card width-100">
          <app-cards #hideAddMember [inlineDetailed]="true">
              <div class="header-section flex content-space-between">
                  <span>{{ 'DEPENDENTS_INFO.EMERCONT' | customTranslate : 'Emergency Contact' }}</span>
              </div>
              <div class="body-section card-text">
                  <div class="card-btn show-detail-view text-primary"(click)="addMember()" *ngIf="!isEditButtonOn">
                    {{ 'DEPENDENTS_INFO.ADDMEB' | customTranslate : 'Add Member' }}
                  </div>
              </div>
              <div class="card-expanded-section detailed-section" plain>
                  <div class="card-header">{{ 'DEPENDENTS_INFO.EMERCONT' | customTranslate : 'Emergency Contact' }}</div>
                  <div class="card-body">
                      <form [formGroup]="emergencyForm">
                          <div class="field-sets">
                              <div class="field-set-row flex">
                                 <div class="field-input col-md-6" [class]="emergencyForm.controls['fullName'].invalid && emergencyForm.controls['fullName'].touched  ? 'has-error' : '' ">
                                  <label for="fullName" class="form-label label-txt modal-field-label" cursor="none">{{ 'DEPENDENTS_INFO.FULLNAME' | customTranslate : 'Full Name' }}*</label> 
                                  <input type="text" formControlName="fullName" placeholder="" class="form-control" id="name">
                                   <p class="error-msg"><span
                              *ngIf="this.emergencyForm.controls['fullName'].invalid && this.emergencyForm.controls['fullName'].touched">
                              <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                
                                <div class="field-input col-md-4" [class]="emergencyForm.controls['relation'].invalid && emergencyForm.controls['relation'].touched  ? 'has-error' : '' ">
                                  <label for="relation" class="form-label label-txt modal-field-label">{{ 'DEPENDENTS_INFO.RELATION' | customTranslate : 'Relation' }}*</label> 
                                  <select id="relation" formControlName="relation"
                                       placeholder="mother" class="form-select">
                                       <option *ngFor="let et of relationDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                                   </select>
                                       <p class="error-msg"><span
                                     *ngIf="this.emergencyForm.controls['relation'].invalid && this.emergencyForm.controls['relation'].touched">
                                     <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                                </div>
                                      <div class="field-input col-md-6" [class]=" emergencyForm.controls['phNumber'].invalid && emergencyForm.controls['phNumber'].touched ? 'has-error' : '' ">
                                        <label for="phnumber" class="form-label label-txt modal-field-label">{{ 'USER_DETAILS.PHONE_NUMBER' | customTranslate : 'Phone Number' }}*</label>
                                        <input type="tel" formControlName="phNumber" id="phnumber" placeholder="">
                                            <p class="error-msg">
                                              <span *ngIf="this.emergencyForm.controls['phNumber'].invalid && this.emergencyForm.controls['phNumber'].touched && !this.emergencyForm.controls['phNumber'].hasError('pattern')">
                                                  <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                                              </span>
                                              <span *ngIf="this.emergencyForm.controls['phNumber'].hasError('pattern') && this.emergencyForm.controls['phNumber'].touched">
                                                  <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_NUMBER' | customTranslate : 'Please enter a valid number' }}
                                              </span>
                                            </p>
                                     </div>
                              </div>
                          </div>
                      </form>
                  </div>
                  <div class="card-footer border-0">
                      <div class="center-button text-right">
                          <div>{{ 'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button" }}</div>
                          <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                              [buttonType]="'text-only card-btn hide-detail-view'"
                              [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); emerCancelButton()"
                              [fcBtnId]="'myId'" [dataDismiss]="''"></app-fusion-button>
                              <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                          [buttonType]="'input-button'" [dataDismiss]="''" (buttonClickEmit)="clickedButton($event);saveEmergencyContact(); hideAddMember.hidePlainView()"
                           [buttonDisable]="!emergencyForm.valid"></app-fusion-button>

                      </div>
                    </div>
                  </div>
                </app-cards>
            </div>
          </div>
          <div class="col-md-12 mt-1" *ngFor="let user of emergencyGetData; let i=index"> 
            <div class="static-width-for-card width-100" style="height: 100%">
               <app-cards #hideAll [inlineDetailed]="true" [modalDeleted]="true" [modalId]="'emergencyInfoModal'">
                  <div class="header-section flex content-space-between">
                    <span>{{ 'DEPENDENTS_INFO.EMERCONT' | customTranslate : 'Emergency Contact' }}:</span>                  
                    <div class="header-section flex content-space-between">
                      <div class="card-btn show-detail-view text-primary" *ngIf="!isEditButtonOn">
                        <svg-icon src="../assets/images/icons/general/pencil-icon.svg"
                          class="card-icon" (click)="onEditEmergency(user);" style="cursor: pointer;"></svg-icon>
                     </div>
                     <div class="card-modal-btn" data-bs-toggle="modal" data-bs-target="#emergencyInfoModal">
                        <svg-icon src="../assets/images/icons/general/delete.svg"
                         class="card-icon ms-3" (click)="emerOnDelete(user)" style="cursor: pointer;"></svg-icon>
                    </div>     
                  </div>
               </div>
              <div class="card-expanded-section modal-content modal-section" modalDelete> 
                 <div class="modal-header">
                  <div class="modal-title fs-6" id="emergencyModalLabel">{{ 'DEPENDENTS_INFO.DELEMER' | customTranslate : 'Delete Emergency Details' }}</div>
                  <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
                 [fcBtnId]="'close'"></app-fusion-button>
                </div>

                    <div class="modal-body">
                       <p>{{ 'DEPENDENTS_INFO.DELETEEMER' | customTranslate : 'Are you sure you want to delete your Emergency  Contact' }}?</p>
                    </div>
                 <div class="modal-footer">
                  <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL_THANKS' | customTranslate : 'No Thanks' }}"
                     [buttonType]="'text-only'" [dataDismiss]="'modal'" (buttonClickEmit)="clickedButton($event); emerCancelButton()"
                       [fcBtnId]="'myId'" [dataDismiss]="'modal'"></app-fusion-button>
                       <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.DELETE' | customTranslate : 'Delete' }}"
                     [fcBtnId]="'modalSave'" (buttonClickEmit)="deleteEC()" [dataDismiss]="'modal'"></app-fusion-button>      
                </div>
            </div> 
           <div class="body-section card-text">
             <div class="d-flex flex-column">
               <div class="d-flex flex-row justify-content-start width-100" style=" margin-left: -40px">
                 <div class="d-flex flex-column ms-5 width-25">
                      <label class="label-head" style="font-size: 15px">{{user.fullName}}</label>
                      <label class="label-sub" style="font-size: 15px">{{user.relation}}</label>
                      <label class="label-sub">{{user.phoneNumber}}</label>
                </div>
              </div>
          </div>
      </div> 
      <!-- Emergency edit form -->
      <div class="card-expanded-section detailed-section" plain>
        <div class="card-header">
            <div class="modal-title fs-6" id="cardDetailedModalLabel">{{ 'DEPENDENTS_INFO.EDITEMER' | customTranslate : 'Edit Emergency Contact Details' }}</div>
        </div>
        <div class="card-body">
            <form [formGroup]="emergencyForm">
                <div class="field-sets">
                    <div class="field-set-row flex">
                        <div class="field-input col-md-4" [class]=" emergencyForm.controls['fullName'].invalid && emergencyForm.controls['fullName'].touched ? 'has-error' : '' ">
                          <label for="fullName" class="form-label label-txt modal-field-label">{{ 'DEPENDENTS_INFO.FULLNAME' | customTranslate : 'Full Name' }}*</label>
                          <input type="text" formControlName="fullName" placeholder="" class="form-control" id="name">
                            <p class="error-msg"><span
                                *ngIf="this.emergencyForm.controls['fullName'].invalid && this.emergencyForm.controls['fullName'].touched">
                                <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                        </div>
                        <div class="field-input col-md-4" [class]=" emergencyForm.controls['relation'].invalid && emergencyForm.controls['relation'].touched ? 'has-error' : '' ">
                          <label for="relation" class="form-label label-txt modal-field-label">{{ 'DEPENDENTS_INFO.RELATION' | customTranslate : 'Relation' }}*</label>
                          <select id="relation" formControlName="relation"
                              placeholder=" " class="form-select" [value]="user.relation">
                              <option *ngFor="let et of relationDropdown.valueList" selected [value]="et.key">{{et.value}}</option>
                          </select>
                          <p class="error-msg"><span
                              *ngIf="this.emergencyForm.controls['relation'].invalid && this.emergencyForm.controls['relation'].touched">
                              <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}</span></p>
                       </div> 
                       <div class="field-input col-md-4" [class]=" emergencyForm.controls['fullName'].invalid && emergencyForm.controls['fullName'].touched ? 'has-error' : '' ">
                        <label for="phNumber" class="form-label label-txt modal-field-label">{{ 'USER_DETAILS.PHONE_NUMBER' | customTranslate : 'Phone Number' }}*</label>     
                        <input type="tel" formControlName="phNumber" placeholder="" class="form-control" id="phNumber">
                         <p class="error-msg">
                                <span
                            *ngIf="this.emergencyForm.controls['phNumber'].invalid && this.emergencyForm.controls['phNumber'].touched && !this.emergencyForm.controls['phNumber'].hasError('pattern') ">
                            <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.REQUIRED_FIELD' | customTranslate : 'This field is required' }}
                              </span>
                            <span *ngIf="this.emergencyForm.controls['phNumber'].hasError('pattern') && this.emergencyForm.controls['phNumber'].touched">
                              <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>{{ 'VALIDATION.VALID_PHONE_NUMBER' | customTranslate : 'Please enter a Valid Phone Number' }}
                          </span>
                        </p>
                      </div> 
                    </div>
                </div>
            </form>
        </div>  
        <div class="card-footer border-0">
                      <div class="center-button text-right">
                          <div>{{ 'VALIDATION.REQ*' | customTranslate : "All the '* - required' field must be filled to enable button" }}</div>
                          <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}"
                              [buttonType]="'text-only card-btn hide-detail-view'"
                              [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); emerCancelButton()"
                              [fcBtnId]="'myId'" [dataDismiss]="''"></app-fusion-button>
                                 <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" 
                              [buttonType]="'input-button'" [dataDismiss]="''" (buttonClickEmit)="clickedButton($event); saveEmergencyContact(); hideAddMember.hidePlainView()"
                              [buttonDisable]="!emergencyForm.valid"></app-fusion-button>

                      </div>
                    </div>
                </div>
            </app-cards>
        </div> 
    </div>
</div>



                    
                           
                              
                            
                
            
              
                           
                        

                        
                      
                        
                        
               
           
        
        
        