<div class="table-flex">
  <div class="flex flex-row">
    <div class="fc-tab-view">
      <div class="flex flex-direction-row mt-25">
        <ul class="nav nav-tabs fc-tabs" id="tabView" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="first-tab" data-bs-toggle="tab" data-bs-target="#first-tab-pane"
              type="button" role="tab" aria-controls="first-tab-pane" (click)="resetForm('inProgress'); inProgressTab = true"
              aria-selected="true">{{'IN_PROGRESS' | customTranslate : 'Inprogress'}}</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="second-tab" data-bs-toggle="tab" data-bs-target="#second-tab-pane"
              type="button" role="tab" aria-controls="second-tab-pane" (click)="resetForm('history'); inProgressTab = false"
              aria-selected="false">{{'HISTORY' | customTranslate : 'History' }}</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="tab-content" id="fcTabContent">
    <div class="tab-pane fade show active" id="first-tab-pane" role="tabpanel" aria-labelledby="Inprogress"
      tabindex="0" style="padding:5px">
      <app-data-table [data]="approvalnotification" [tableWidth]="'120%'" [displayedColumns]="['employeeId','name','tableStartDate', 'tableEndDate', 'leaveType','leaveBalance','noofdays',
        'status','reason','appliedon']" [headerLabels]="['Employee Id','Employee Name','Start Date', 'End Date','Leave Type','Leave Balance',
        'No.of Days','Status','Reason','Applied On']" [translatePath]="'APPROVAL_NOTIFI_TABLE'"
      [actionButton]="true"  (selectedValues)="tableCheckedDataa($event)" [withCheckBox]="false"  [fromWhere]="'approval'" [searchBar]="true"  
        (approveData)="onApprove($event)" (rejectData)="onreject($event)">
      </app-data-table>
    </div>
  
    <div class="tab-pane fade" id="second-tab-pane" role="tabpanel" aria-labelledby="History" tabindex="1">
      <!-- RS27AUG24 -->
      <!-- Used the translate pipe to successfully implement the translation -->
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <app-fusion-button [buttonType]="'grey-border rounded-3 bg-white text-dark'" 
        
         [buttonValue]="'EXPORT' | customTranslate : 'Export' "
         [buttonDisable]="downloadCurrentDisable" 
         (buttonClickEmit)="downloadCurrentEmployees()"
         [fcBtnIconLeftSrc]="'../assets/images/icons/general/download-icon.svg'">
        </app-fusion-button>
      </div>
      <app-data-table  [data]="historyData"  [tableWidth]="'120%'"[displayedColumns]="['employeeId','name','tableStartDate', 'tableEndDate', 'leaveType','leaveBalance','noofdays',
        'status','reason', 'appliedon','rejectReason']" [headerLabels]="['Employee Id','Employee Name','Start Date', 'End Date','Leave Type','Leave Balance',
        'No.of Days','Status', 'Reason', 'Applied On', 'Comments' ]" [translatePath]="'APPROVAL_NOTIFI_HISTORY_TABLE'"
        [fromWhere]="'leave'"  (selectedValues)="tableCheckedDataa($event)"
       [actionButton]="false" [searchBar]="true" [withCheckBox]="true">
      </app-data-table>

    </div>
  </div>
</div>


<div class="modal fade" id="approvalReject">
  <div class="modal-dialog d-flex align-items-center h-100">
    <div class="modal-content modal-custom">
      <div class="modal-header modal-custom-header modal-custom">
        <div class="modal-title fs-6">{{ 'REJ_REASON' | customTranslate : 'Reject Reason' }}</div>
        <app-fusion-button  [buttonType]= "'btn-close'" [dataDismiss]="'modal'"
        [fcBtnId]="'close'">
        </app-fusion-button>
      </div>
      <div class="modal-body modal-custom-body d-flex flex-column">
        <form [formGroup]="rejectionform">
          <textarea class="text-box" 
            formControlName="rejectReason"></textarea>
        </form>
        <div class="mt-5 d-flex justify-content-end">
          <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" [buttonType]="'text-only'" [dataDismiss]="'modal'"
             [fcBtnId]="'myId'"></app-fusion-button>
          <app-fusion-button [buttonValue]="'Reject' | customTranslate : 'Reject'" [dataDismiss]="'modal'"
            (buttonClickEmit)="reasonText()" [buttonDisable]="!rejectionform.valid" [fcBtnId]="'modalSave'">
          </app-fusion-button>
        </div>
      </div>
    </div>
  </div>
</div>