
<div class="alert alert-dismissible fade show form-submission-message {{alertType}}" role="alert">
    <div class="info">
        <img [src] ="alertType === 'success' ?  '../assets/images/icons/Vectorsuccesstick.svg' : '../assets/images/icons/Vectorwarn.svg'" style="width:13.33px; height:13.33px; margin-top: 3px">
        <span class="white-text">{{alertMessage}}</span>
        <app-fusion-button [buttonType]="'custom-btn'" class="fc-btn-icon right-icon" dataDismiss="alert" [fcBtnIconRightSrc]="'../assets/images/icons/general/cross-icon.svg'"></app-fusion-button>
    </div>
</div>



  
  

