<div class="card">
    <div class="content">
        <div class="search">
            <div class="search-bar">
                <input class="medium-font width-100" type="text" placeholder="{{ 'SEARCH' | customTranslate : 'Search'  }}..." [(ngModel)]="searchText"
                    style="border:none;">
                <img class="card-btn" src="../assets/images/icons/general/Search.svg">
            </div>
        </div>
        <div class="filter-content" [style]="'overflow-y: auto'">
            <div *ngFor="let data of filterItems | searchFilter : searchText">
                <div class="d-flex mt-3 mb-3">
                    <input type="checkbox" [checked]="filterCheckBox.isSelected(data)" [value]="data" [id]="data"
                        (change)="checkedData(filterCheckBox.toggle(data))">
                    <label class="medium-font ms-2 label-data">{{data}}</label>
                </div>
            </div>
        </div>
    </div>
</div>