<div>
  <div class="position-relative">
    <!-- SK03OCT24 if not multiple select dropdown  -->
    <div style="width: {{dropDownWidth}};" *ngIf="!multipleSelect">
      <button type="button" class="button-fc {{isError ? 'button-error' : ''}}" (blur)="outsideClick()" (click)="dropDown = !dropDown" [disabled]="isDisabled" style="height:{{dropDownHeight}}; border-radius:{{dropBorderRadius}} ">
        <div class="select-content">
          <div class="btn-font-size selected-value" [attr.data-selected]="selectedData">
            {{ isListOfTypeObject ? (selectedData ? selectedData[objectDisplayKey] || 'Select' : 'Select') : selectedData || 'Select' }}
          </div>
          <div class="arrow {{dropDown === true ? 'active' : '' }}"></div>
        </div>
        <div class="border" *ngIf="dropDown" style="width: {{dropDownWidth}};">
          <div *ngFor="let list of lists" (click)="listValue($event,list, 'singleSelect');dropDown = false"
            [style]="list === selectedData ? 'background: rgba(0, 123, 255, 0.1)' : 'background : white' ">
            <div class="drop-list">
              <div class="list-select">{{list}}</div>
            </div>
          </div>
        </div>
      </button>
    </div>

    <!-- SK03OCT24 if multiple select dropdown. This selects the multiple data from dropdown -->
    <div style="width: {{dropDownWidth}};" *ngIf="multipleSelect">
      <button type="button" class="button-fc {{isError ? 'button-error' : ''}}" (blur)="outsideClick()"
        [disabled]="isDisabled" style="height:{{dropDownHeight}}; border-radius:{{dropBorderRadius}} ">
        <div class="select-content flex justify-content-end h-100 align-items-center" (click)="dropDown = !dropDown">
          <div *ngFor="let sel of multipleSelectedView; let i=index" class="btn-font-size selected-value me-2"
            [attr.data-selected]="selectedData">
            {{sel}}
            <svg-icon class="close-hover ms-1" *ngIf="i >= 0" src="../assets/images/icons/general/cross-icon.svg"
              (click)="removeSelectedFilter(sel,i)"></svg-icon>
          </div>
          <div class="arrow {{dropDown === true ? 'active' : '' }}"></div>
        </div>
        <div class="border" *ngIf="dropDown" style="width: {{dropDownWidth}};">
          <div class="search m-3">
            <div class="search-bar flex">
              <input class="medium-font width-100" type="text" placeholder="{{'SEARCH' | customTranslate : 'Search'}}..." [(ngModel)]="searchText"
                style="border:none;">
              <img class="card-btn" src="../assets/images/icons/general/Search.svg">
            </div>
          </div>
          <div *ngFor="let list of lists | searchFilter : searchText"
            (click)="listValue($event,list, 'multipleSelect');dropDown = false"
            [style]="list === selectedData ? 'background: rgba(0, 123, 255, 0.1)' : 'background : white' ">
            <div class="drop-list">
              <div class="list-select">{{list}}</div>
            </div>
          </div>
        </div>
      </button>
    </div>

    <!-- Dropdown for Object Type -->
    <div *ngIf="dropDown && isListOfTypeObject" class="border" style="width: {{dropDownWidth}};">
      <div *ngFor="let list of lists" (click)="listValue($event, list,'singleSelect'); dropDown = false"
        [style.background]="list === selectedData ? 'rgba(0, 123, 255, 0.1)' : 'white'">
        <div class="drop-list">
          <div class="list-select">{{list[objectDisplayKey]}}</div>
        </div>
      </div>
    </div>

    <!-- Dropdown for String Type -->
    <div *ngIf="dropDown && !isListOfTypeObject" class="border" style="width: {{dropDownWidth}};">
      <div *ngFor="let list of lists" (click)="listValue($event, list,'singleSelect'); dropDown = false" [style.background]="list === selectedData ? 'rgba(0, 123, 255, 0.1)' : 'white'">
        <div class="drop-list">
          <div class="list-select">{{list}}</div>
        </div>
      </div>
    </div>


    <!-- Error Message -->
    <div *ngIf="isError" class="flex flex-row">
    <svg-icon src="../assets/images/icons/general/warning icon.svg"></svg-icon>
    <p class="margin-top-auto">
      <span class="error-msg">{{errorMessage}}</span>
    </p>
    </div>
  </div>
</div>