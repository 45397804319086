<div class="content-section flex flex-column flex-grow max-width-minus-side-nav" style="--grow-value: 1">
    <div class="" *ngIf="approvalsTabToShow == 'details'"> <!-- Removed class ' main-content' since the leave master is moved to the Company settings page -->
        <div class="flex flex-direction-row">
          <div class="row flex flex-row">
            <!-- <div class="approval">{{'APPROVALS' | customTranslate : '' }}</div> -->
            <div class="leaves">
              <app-drop-down [lists]='option' dropDownWidth="120px"
                  dropDownHeight="36px" dropFontWeight="500" dropFontSize="12px"
                  (dataEmit)="emittedValue($event)"
                  dropFontColor="#434555" dropBorderRadius="5px 5px 5px 5px" [selectedData]="eventValue">
              </app-drop-down>
            </div>
          </div>
        </div>
        <div [ngSwitch]="eventValue">
            <div *ngSwitchCase="'Leaves'">
               <app-approval-notificationtab></app-approval-notificationtab>
            </div>
            <div *ngSwitchCase="'Expense'">
             <app-expense-tab></app-expense-tab>
            </div> 
            <div *ngSwitchCase="'Skill'"></div>
            <div *ngSwitchCase="'Assert'"></div>
        </div>
    </div>
    <div class="main-content" *ngIf="approvalsTabToShow == 'settings'">
      <!-- RS27AUG24 -->
      <!-- Used the translate pipe to successfully implement the translation -->
      {{ 'SETTINGS' | customTranslate : 'Settings'  }}
    </div>
</div>
<!-- TO BE USED IN FUTURE ********************* DON'T REMOVE ********************* -->
<!-- <div class="main-content" *ngIf="approvalsTabToShow == 'settings'">
    Settings
</div> -->