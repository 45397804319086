import { Component, ElementRef, OnInit, ViewChild, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavEmitService } from '../../services/side-nav-emit.service';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { DataTableComponent } from '../../components/data-table/data-table.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service'
import { EssComponent } from '../ess/ess.component'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { of, Subscription } from 'rxjs';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { MultiSelectComponent } from 'src/app/components/multi-select/multi-select.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CompensationComponent } from './compensation/compensation.component';
import { PayrollTableComponent } from './payroll-table/payroll-table.component';
import { PayrollcompareComponent } from './payrollcompare/payrollcompare.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { Constants } from 'src/app/models/Constants';
import { saveAs } from 'file-saver';
import { PayrollComponent } from 'src/app/components/payroll/payroll.component';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { PaySlipsTabComponent } from './payroll/pay-slips-tab/pay-slips-tab.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { ApplyLeavesComponent } from '../../pages/ess/leaves/apply-leaves/apply-leaves.component';
import { ExpensesTabComponent } from '../../pages/ess/expenses/expenses-tab/expenses-tab.component';
import { WorktabComponent } from '../ess/worktab/worktab.component';
import { BasicinfotabComponent } from '../ess/basicinfotab/basicinfotab.component';
import { EducationtabComponent } from '../ess/educationtab/educationtab.component';
import { ExperiencetabComponent } from '../ess/experiencetab/experiencetab.component';
import { DocumentComponent } from '../ess/document/document.component';
import { DependenttabComponent } from '../ess/dependenttab/dependenttab.component';
import { BankinfotabComponent } from '../ess/bankinfotab/bankinfotab.component';
import { Tab } from 'bootstrap';
import { AttendanceHrmComponent } from '../attendance/attendance-hrm/attendance-hrm.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalsComponent } from '../approvals/approvals.component';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { SideScreenComponent } from 'src/app/components/side-screen/side-screen.component';
import { CommonScreenComponent } from 'src/app/components/side-screen/common-screen/common-screen.component';
import { HrmTdsComponent } from './hrm-tds-form/hrm-tds.component';
import { LangChangeEvent, TranslateModule,TranslateService } from '@ngx-translate/core';
import { PdfTemplateComponent } from 'src/app/components/pdf-template/pdf-template.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { BrowseComponent } from 'src/app/components/browse/browse.component';
import { MultiCheckboxComponent } from 'src/app/components/multi-checkbox/multi-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { TabsComponent } from 'src/app/components/tabs/tabs.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

@Component({
  selector: 'app-hrm',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    ApplyLeavesComponent,
    ExpensesTabComponent,
    MatPaginatorModule,
    SideScreenComponent,
    MatSortModule,
    DataTableComponent,
    PageHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    EssComponent,
    SuccessFailureMessageComponent,
    CardsComponent,
    FusionButtonComponent,
    MultiSelectComponent,
    CompensationComponent,
    PayrollTableComponent,
    NgxPaginationModule,
    PayrollcompareComponent,
    PayrollComponent,
    DropDownComponent,
    FileUploadComponent,
    PaySlipsTabComponent,
    WorktabComponent,
    BasicinfotabComponent,
    EducationtabComponent,
    ExperiencetabComponent,
    DocumentComponent,
    DependenttabComponent,
    BankinfotabComponent,
    AttendanceHrmComponent,
    AngularSvgIconModule,
    ApprovalsComponent,
    MatCheckboxModule,
    CommonScreenComponent,
    HrmTdsComponent,
    TranslateModule,
    PdfTemplateComponent,
    DashboardComponent,
    BrowseComponent,
    MultiCheckboxComponent,
    TabsComponent,
    CustomTranslatePipe
  ],
  templateUrl: './hrm.component.html',
  styleUrls: ['./hrm.component.css']
})

export class HrmComponent implements OnInit {
  @ViewChild('closeAddEmployeeModal') closeAddEmployeeModal!: ElementRef;
  
  getData: any;
  ess !:boolean;
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  errorMessage = '';
  empGet : any;
  empDataTabe:Array<any> = [];
  allData : any;
  empFilterGet:Array<any> = [];
  pageComponentName = 'Hrm';
  emailRegex = "[A-Za-z0-9._%-+]+@[A-Za-z0-9._%-]+\\.[a-z]{2,10}"; // Added this for text Validation and used them further down the lines
  numberRegex = "^[0-9]*$";
  mobileRgx = "^((\\+91-?)|0)?[0-9]{10}"; // Added this for text Validation and used them further down the lines
  textRegex = "^[a-zA-Z ]*$";
  nameRegex = "^[\p{L} ]*$";

  selectedUser:any;
  expenseHistory: any;

  newEmployeeForm! : FormGroup;
  leaveAllocationForm!: FormGroup;
  leaveAllocationFilterForm!: FormGroup;

  sideNavsubscription: Subscription;
  hrmTabToShow: string = 'dashboard'; //SK01FEB24
  dynamicDashboard : Array<any> = []; //SK01FEB24

  basicEmpID:any;

  isDevMode: boolean = false;
  dept! : any[];
  desig! : any[];
  rpm! : any[];
  rsm!: any[];
  gender! : any[];
  location! : any[];
  pageNumber:number=1;
  tableSize:number=4;
  deptData: Array<any> = [];
  desigData: Array<any>  = [];
  rpmData: Array<any>  = [];
  rsmData: Array<any>  = [];
  genderData: Array<any>  = [];
  locationData: Array<any>  = [];
  leaveAllocationEmpData: Array<any> = [];

  tempGet:any;
  holidayGet:any;
  weekOffGet:any;
  companyId = this.globalValues.orgId;

  templateList:Array<any> = [];
  holidayList:Array<any> = [];
  loadingSpinner = false;
  employeeBulkFile: any;
  showSettings = false;
  showHrm = false;
  selectedEmployee:any;
  tabDirect! : Tab
  @ViewChild('demoTab') private demoTab!: ElementRef<any>;
  downloadCurrentDisable = true;
  filesArrayComp:any;
  currentEmpId : any  = localStorage.getItem('empId');
  selection: any;
  templateSelection:any;
  holidaySelection:any;
  weekOffSelection:any;
  permissionSelection:any;
  weekOffValidation = false;

  showSideScreen = false;
  componentTypes: Array<String> = [];
  componentActivity: string = 'create';
  componentFilterIndex: number = 0;
  settingsData: any;
  settingsComponentListTableData: any;
  settingsComponentListData: Array<any> = [];
  sideScreenData = { };
  taxSidescreen = false;
  headersLabel : Array<any> = [];
  allocationLabel:Array<any> = [];
  payrollLabel:Array<any> = [];
  hrmTaxArray:any;
  filteredTab:any;
  activeSection:any;
  sectionLimit:any;
  rolesList: Array<any> = [
    'Super User',
    'Fusion User',
    'HRM User',
    'Recruiter Manager',
    'ESS User',
    'Project User',
    'Finance User',
    'HRM Manager',
    'KEN Manager',
    'Project Manager',
    'KEN User',
    'Recruiter',
    'App User',
    'No Access'
  ];
  onPopState: any;
  nameFilter: any;
  deptFilter: any;
  locationFilter: any;
  designationFilter:any;
  primaryReportingManagerFilter:any;
  genderFilter:any;
  templateDownlaod = Constants.DOWNLOAD_TEMPLATE;
  @ViewChild('leaveAllocTable') leaveAllocTable! : DataTableComponent;
  tdsListArray: Array<any> = [];
  taxSideScreenData:any;
  taxComponentActivity:any;
  permissionsList:Array<any> = [];
  selectedEmployeeId:any;
  sectionTypes: Array<any>  = [];
  sectionFilterIndex = 0;
  sectionListData:any;
  nonComparisonResults!: Array<any>;
  comparisonResults : any;
  filterType  : any;
  dashboardQuery  : any;
  metricsArray!: Array<any>;
  multipleFilterArray : any;
  tableData: Array<any> = [];
  checkedRow: any;
  configJSON: any;
  isDynamicHRM = false;

  empUpdatesSubscription: Subscription;
  tabsList : Array<any> = [];
  activeTab:any;
  tabSwitch:any;
  isDynamicDashboard = false; // SK01FEB24, check if dashboard in present in custom HRM
  employeeListAttendance:Array<any> = []; //SK22APR24
  accessor:any; //SK22APR24
  hrmDynamicModules: Array<any> = [];
  hrmStaticModules = Constants.hrmStaticModules;
  hrmModuleData : any;
  exportFieldsListConfigs:any; //SK03OCT24
  exportFilterListConfigs:any; //SK03OCT24
  filterScreenConfigs:Array<any> = []; //SK03OCT24
  employeeFilter:any; //SK03OCT24
  appUserCondition = false; //SK03OCT24

  constructor(private http: HttpClient, private fb: FormBuilder, private apiService: ApiService, private errorHandler: ErrorHandlerService, private sideNavItem: SideNavEmitService,private globalValues: GlobalValuesService, private appComp: AppComponent,private router: Router, private route: ActivatedRoute, private alertService: AlertService, private spinnerService: NgxSpinnerService, private translate : TranslateService) {
    this.globalValues.globalValues();
    this.isDevMode = isDevMode();

    // SK21AUG24 dynamic module get
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'hrm'){
          this.hrmDynamicModules = appArr.modules;
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'employees'){
              modArr.tabs.forEach((tabArr:any) => {
                if(tabArr.name === 'employees'){
                  this.configJSON = tabArr.config;
                  this.isDynamicHRM = true;
                }
              });
            }
          });
        }
      });
    } else {
      console.log('waiting for the api response');
    }
    
    this.empUpdatesSubscription = this.alertService.passAlertType$.subscribe((alert: any) => {
      if (alert.type === 'successFetch') { this.getAPIData(alert.fetchId); }
    });
   
    //Code to observe, fetch the clicked Side nav item and display the respective page on the content area
    this.sideNavsubscription = this.sideNavItem.clickedSideNav$.subscribe(navItem => {
      this.hrmTabToShow = navItem;
      this.hrmModuleData = this.hrmDynamicModules.find((mod:any)=> mod.name === navItem) //SK21AUG24 filtering active module
      this.resetLeaveAllocation();
      // SK01APR24 call only if the module is dashboard
      if(navItem === "dashboard"){
        this.initialDashboardCall();
      }
    });
    this.dept =
      [
        {name :'Administration',checked : false},
        {name :'Accounts and Finance',checked : false},
        {name :'Human Resources',checked : false},
        {name :'Information Technology',checked : false},
        {name :'Infrastructures',checked : false},
        {name :'Security Technology',checked : false}
    ]

    this.desig =
      [
        {name :'Admin',checked : false},
        {name :'Accountant',checked : false},
        {name :'Business Analyst',checked : false},
        {name :'Developer',checked : false},
        {name :'Designer',checked : false},
        {name :'Chief Architect',checked : false},
        {name :'Chief Technology Officer',checked : false},
    ]

    this.rpm = [];
    this.rsm = [];

    this.gender = [
      {name :'Male',checked : false},
      {name :'Female',checked : false},
      {name :'Others',checked : false},
    ]

    this.location = [
      {name :'Tamil Nadu',checked : false},
      {name :'Kerala',checked : false},
      {name :'Andhra Pradesh',checked : false},
      {name :'Karnataka',checked : false},
      {name :'Telangana',checked : false},
      {name :'Maharastra',checked : false},
    ]

    this.newEmployeeForm = this.fb.group({
      'firstName' : ['', Validators.compose([Validators.required])],
      'lastName' : [''],
      'email' : ['', Validators.compose([Validators.pattern(this.emailRegex)])],
      'phone': [''],
      'employeeID' : ['', Validators.required],
      'employmentType':[''],
      'dateOfJoining' : ['',Validators.required],
      'officeLocation' : [''],
      'isAppUser':[false],
      'fusionId':[''],
      'password':[''],
      'department' : [''],
      'designation' : [''],
      'reportingManager1':[''],
      'reportingManager2': [''],
      'gender': ['', Validators.required],
      'roles' : [[]],
      'genID':[null],
      'companyName': [this.globalValues.compFullName]
    });

    this.leaveAllocationForm = this.fb.group({
      'weekoffLeaves': [''],
    });

    this.leaveAllocationFilterForm = this.fb.group({
      'dept': '',
      'designation': '',
      'reportManager': '',
    })
    

    if(JSON.parse(localStorage.getItem('hrmTabList') as any) != null){
      this.tabsList = JSON.parse(localStorage.getItem('hrmTabList') as any);
    } else {
      //SK01FEB24, The active sub tab is also stored in local, So assigning both as object
      this.tabsList = [{tab:'Employees', subTab:'Nil'}];
      localStorage.setItem('hrmTabList', JSON.stringify(this.tabsList));
    }
    
    this.accessor = "HRM"; //SK22APR24
  }
 
  renderPage(event:any){
    this.pageNumber= event;
  }
  


  ngOnInit(): void {
    this.selection = new SelectionModel<any>(true, []);
    this.templateSelection = new SelectionModel<any>(true, []);
    this.holidaySelection = new SelectionModel<any>(true, []);
    this.weekOffSelection = new SelectionModel<any>(true, []);
    this.permissionSelection = new SelectionModel<any>(true, []);
    this.route.queryParams.subscribe(params => {
      this.hrmTabToShow = params['activeNav'] || 'dashboard'; // SK01FEB24
      this.hrmModuleData = this.hrmDynamicModules.find((mod:any)=> mod.name === params['activeNav']) //SK21AUG24 filtering active modules
      // this.appComp.callNavService(this.hrmTabToShow);    //used for  to retain the sidenav style 
      // this.ess = params['activeDetailPage'] == 'true' ? true : false;
    });

   // this.router.navigate([], { queryParams: { activeDetailPage: this.ess } });

    this.initialFunctions();
    this.initialDashboardCall();

    // SK21DEC23 If dynamic HRM app present, that value will be passed to datatable 
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'hrm'){
          this.hrmDynamicModules = appArr.modules;
          appArr.modules.forEach((modArr:any) => {
            if(modArr.name === 'employees'){
              modArr.tabs.forEach((tabArr:any) => {
                if(tabArr.name === 'employees'){
                  this.configJSON = tabArr.config;
                  this.isDynamicHRM = true;
                }
              });
            }
          });
        }
      });
    } else {
      console.log('waiting for the api response');
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sideNavsubscription.unsubscribe();
  }

  initialDashboardCall(){
    this.filterType  = 'noncomparison';
    this.dashboardQuery  = 'designation';
    this.dashboardAPI();
  }

  initialFunctions(){
    if(this.ess){
      this.onEditEmp(JSON.parse(this.currentEmpId));
    }

    if(this.ess != true){
      this.getEmp();
    }
    this.getEmployees();
    this.getLeaveMaster();
    // this.saveFilter();   // This might be removed or used in future if its idle for long kindly remove this line and the related function 
    this.showSetting();
  }

  resetEmp(){
    this.getEmp();
  }

  shareCheckedList(item:any[]){
    this.deptData = item;
    if(this.isDevMode) console.log(item);
  }

  shareIndividualCheckedList(item:[]){
    if(this.isDevMode) console.log(item);
  }

  desigCheckedList(item:any[]){
    this.desigData = item;
    if(this.isDevMode) console.log(item);
  }

  desigIndividualCheckedList(item:[]){
    if(this.isDevMode) console.log(item);
  }

  rpmCheckedList(item:any[]){
    this.rpmData = item;
    if(this.isDevMode) console.log(item);
  }

  rpmIndividualCheckedList(item:{}){
    if(this.isDevMode) console.log(item);
  }

  rsmCheckedList(item:any[]){
    this.rsmData = item;
    if(this.isDevMode) console.log(item);
  }

  rsmIndividualCheckedList(item:{}){
    if(this.isDevMode) console.log(item);
  }

  genderCheckedList(item:any[]){
    this.genderData = item;
    if(this.isDevMode) console.log(item);
  }

  genderIndividualCheckedList(item:[]){
    if(this.isDevMode) console.log(item);
  }

  locationrCheckedList(item:any[]){
    this.locationData = item;
    if(this.isDevMode) console.log(item);
  }

  locationIndividualCheckedList(item:[]){
    if(this.isDevMode) console.log(item);
  }

  selectedTemplate(data:any){
    this.templateList = data; //SK14MAR24
  }

  selectedHolidays(data:any){
    this.holidayList = data; //SK14MAR24
  }

  
  weekOffHolidays(data:any, d:any){
    if(data.length > 0){ //SK14MAR24
      this.leaveAllocationForm.controls['weekoffLeaves'].patchValue(data[0]);
      this.weekOffValidation = true;
    } else {
      this.leaveAllocationForm.controls['weekoffLeaves'].patchValue("");
      this.weekOffValidation = false;
    }
  }

  //Alert Message functions
  saveSuccess(event:any){
    this.alertService.messageOnPass('success', event);
  }

  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  saveMessage(messageFor:string = ''){
    this.errorMessage = '';
    switch(messageFor){
      case 'component-create':
        this.alertService.messageOnPass('success',  'Component added successfully');
        break;
      case 'component-update':
        this.alertService.messageOnPass('success',  'Component updated successfully');
        break;
      case 'component-delete': 
        this.alertService.messageOnPass('success',  'Component deleted successfully');
        break;
      default:
        this.loadingSpinner = false;
        this.initialFunctions();
        this.alertService.messageOnPass('success',  'Employee added successfully');
        this.resetLeaveAllocation();
        this.selection.clear();
    }
  }

  errMessage(err:any){
    this.loadingSpinner = false;
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }
  
  // Fetching the Datas from APIs for different uses
  getEmp(){
    this.newEmployeeForm = this.fb.group({
      'firstName' : ['', Validators.compose([Validators.required])],
      'lastName' : [''],
      'email' : ['', Validators.compose([Validators.pattern(this.emailRegex)])],
      'phone': [''],
      'employmentType':[''],
      'employeeID' : ['', Validators.required],
      'dateOfJoining' : ['', Validators.required],
      'officeLocation' : [''],
      'isAppUser':[false],
      'fusionId':[''],
      'password':[''],
      'department' : [''],
      'designation' : [''],
      'reportingManager1':[''],
      'reportingManager2': [''],
      'gender': ['', Validators.required],
      'roles' : [[]],
      'genID':[null],
      'companyName': [this.globalValues.compFullName]
    })
  }

  getEmployees(){
    this.apiService.fetchValue('/hrm/allemployees').subscribe((get: any) => {
      this.employeeListAttendance = get; //SK22APR24
      if(this.isDevMode) console.log('All GET=>',"displays all the list of employees, COMMENTED since huge employees is slowing the process")
      this.empGet = get;
      this.empDataTabe = [];
      this.allData = [];
      this.empFilterGet = [];
      // setting the data as per we need in our  data table component 
      get?.forEach((element:any)=>{
        const datum = element?.employee
        const workinfo = datum?.workinfo;
        const basicinfo = datum?.basicinfo;
        this.empDataTabe.push(element) // Passing element only, since, we use split data directly in displayedColumns //SK 19DEC2023

        this.empFilterGet.push({
          employeeId: datum?.employeeId,
          name : datum?.firstName + ' ' + datum?.lastName,
          designation : workinfo?.designation,
          department : workinfo?.department,
          primaryReportingManager: datum?.primaryReportingManager,
          secondaryReportingManager: datum?.secondaryReportingManager,
          officeLocation : basicinfo?.officeLocation,
          gender : datum?.gender,
          employmentType:basicinfo?.employmentType,
        })
      })
      this.allData = this.empDataTabe;
      this.tableData =this.empDataTabe;
       this.multipleFilterArray = {
        name: null,
        department: null,
        designation: null,
        officeLocation: null,
        primaryReportingManager: null,
        gender: null
      };

      //SK24JAN24 active tabs is stored as object
      if(localStorage.getItem('activeHrmTab') != null ){
        this.showTabs(JSON.parse(localStorage.getItem('activeHrmTab') as any));
      } else {
        this.activeTab = {tab:'Employees'}; // SK01FEB24 changed default tab as employees in Employees module
        this.tabSwitch = 'Employees';
      }
    
      const nameArray: any[] = [];
      const deptArray: any[] = [];
      const desigArray: any[] = [];
      const locationArray: any[] = [];
      const primaryReportingManagerArray: any[] = [];
      const genderArray: any[] = [];
      this.tableData?.forEach(element => {  
   
        nameArray.push(element.name);
        deptArray.push(element.department);
        desigArray.push(element.designation);
        locationArray.push(element.officeLocation);
        primaryReportingManagerArray.push(element.primaryReportingManager);
        genderArray.push(element.gender);
      });
      
      //each filter array data to be sent separately as an array of objects with the same key name
      this.multipleFilterArray.name = (new Set(nameArray));
      this.multipleFilterArray.department = (new Set(deptArray));
      this.multipleFilterArray.designation = (new Set(desigArray));
      this.multipleFilterArray.officeLocation = (new Set(locationArray));
      this.multipleFilterArray.primaryReportingManager = (new Set(primaryReportingManagerArray));
      this.multipleFilterArray.gender = (new Set(genderArray));
 //   this.empFilterGet = this.tableData;
    });
  }
  
  filterChecked(){
    // finding the checked filter in that data array and filtering that whole object from data array
    const name = this.tableData.filter((name:any)=> this.nameFilter?.includes(name.name));
    const department  = this.tableData.filter((depart:any)=> this.deptFilter?.includes(depart.department));
    const officeLocation  = this.tableData.filter((location:any)=> this.locationFilter?.includes(location.officeLocation));
    const designation  = this.tableData.filter((designat:any)=> this.designationFilter?.includes(designat.designation));
    const primaryReportingManager  = this.tableData.filter((primaryReportingManager:any)=> this.primaryReportingManagerFilter?.includes(primaryReportingManager.primaryReportingManager));
    const gender  = this.tableData.filter((gender:any)=> this.genderFilter?.includes(gender.gender));

    // If no check box is checked, whole data array will be passed to data table
    if(name?.length === 0 && department.length === 0 && officeLocation.length === 0 && designation.length === 0 && primaryReportingManager.length === 0 && gender.length === 0){
      this.empFilterGet = this.tableData;
    } else {
      // here the selected check box of each column will be combined in one array and passed to datatable 
      const finalArray = [...name, ...department, ...officeLocation, ...designation, ...primaryReportingManager,...gender];
      let uniqueObjects = new Set(finalArray.map(item => JSON.stringify(item))); // removing the duplicate objectes by stringfy and parse
      this.empFilterGet = Array.from(uniqueObjects).map(item => JSON.parse(item));
    }
 
  }
  selectedCheckBox(event: any) {
    this.checkedRow = event;
   
  }
  searchFilterData1(evt: any) {
    this.checkedRow = [];
    // The filter that checked was stored in separate array for each column
    if(evt[0] === 'name'){
      this.nameFilter = evt[1];
    }
    if(evt[0] === 'department'){
      this.deptFilter = evt[1];
    }
    if(evt[0] === 'officeLocation'){
      this.locationFilter = evt[1];
    }
    if(evt[0] === 'designation'){
      this.designationFilter = evt[1];
    }
    if(evt[0] === 'primaryReportingManager'){
      this.primaryReportingManagerFilter = evt[1];
    }
    if(evt[0] === 'gender'){
      this.genderFilter = evt[1];
    }
    this.filterChecked();
  }

  getLeaveMaster(){
    this.apiService.fetchValue('/leavemaster').subscribe((data : any) => {
      if(this.isDevMode) console.log('Leave Master data...', data);
      // SK14MAR24 returning the template, holidays and weekoff values using map method
      this.tempGet = data?.template.map((temp:any)=>{ if(temp.active) return temp.leaveType} );
      this.holidayGet = data?.holidays.map((hol:any)=>{ if(hol.active) return hol.leaveType} );
      this.weekOffGet = data?.weekoff.map((off:any)=>{ if(off.active) return off.leaveType} );
    });
  }

  getFilters(){
    this.apiService.fetchValue('/hrm/allemployees').subscribe((data: any) => {
      if(this.isDevMode) console.log('Employees data...', data);
      if(this.rpm.length === 0 && this.rsm.length === 0){
        data.employees.forEach((element:any) => {
          const datum = element.employee;
          this.rpm.push({name: datum.employeeId + ' - ' + datum.firstname + ' ' + datum.lastname, checked:false});
          this.rsm.push({name: datum.employeeId + ' - ' + datum.firstname + ' ' + datum.lastname, checked:false});
        });
      }
    });
  }    
  //MV24AUG24  Fetches user data, then initializes and populates filters for 'employeeId', 'name', 'department', 'designation', and 'gender' with unique values.
  getUserData(){
    this.apiService.fetchValue('/user').subscribe((datas:any) => {
      this.settingsData = datas.settings[0];
      this.tdsListArray = this.settingsData.taxDeclarations;
      this.sectionListData = this.settingsData.taxDeclarations;
      const filterkey =["employeeId","name","department","designation","gender"]
      if(filterkey?.length > 0){
        this.multipleFilterArray = {};
        const filtObjs:any = {};
        filterkey.forEach((element:any) => {
          this.multipleFilterArray[element] = null;
          filtObjs[element] = [];
        });
        this.empFilterGet.forEach((element:any) => {
          filterkey.forEach((key:any) => {
            if(Object.keys(element).includes(key)){
              filtObjs[key].push(element[key])
              this.multipleFilterArray[key] =(new Set(filtObjs[key]));
            }
          });
        });
        // this.config.multipleFilterArray = this.multipleFilterArray;
      }
    
      
      if(datas.settings[0].salary.components.length > 0){
         // here we store the entire SALARY data from the settings api
        this.settingsComponentListData = this.settingsData.salary.components; // here we hold just the components data from the SALARY api
        this.settingsComponentListTableData = this.settingsComponentListData; // this is used to do the the filters and other tasks with the table 
      }
      this.componentTypes = [...new Set(this.settingsComponentListData.map(item => item.type))];
      this.filterTableData(this.componentTypes[this.componentFilterIndex === 0 ? 0 : this.componentFilterIndex], 'salary');

      // this.sectionTypes = [...new Set(this.tdsListArray.map(item => item.sectionName))];
      // this.sectionTypes = [...new Set(this.tdsListArray.map(item => Object.keys(item)))];
      this.sectionTypes = [];
      this.tdsListArray.forEach(element => {
        this.sectionTypes.push(...Object.keys(element));
      });
      this.filterTableData(this.sectionTypes[this.sectionFilterIndex === 0 ? 0 : this.sectionFilterIndex], 'tax');
    })}
    
  /** These functions are used for Saving or Editing the data respectively in forms and sent to save and patch functions in api.service.ts*/
  saveEmployee(){
    this.loadingSpinner = true;
    let value = this.newEmployeeForm.value;
    if(value.roles.length === 0) {
      value.roles = ['No Access'];
    }
    const url = (window.location.href).split('/')[2].split('.');
    const source = url[1] + '.' + url[2];
    this.closeAddEmployeeModal.nativeElement.click();
    // objects structure redefined
    this.apiService.writeValue('post', '/hrm', {'firstName': value.firstName, 'lastName': value.lastName, 'email': value.email, 'employeeId': value.employeeID, 'isAppUser':value.isAppUser, 'userName': value.fusionId , 'password': value.password ,'gender': value.gender,  'employmentType':value.employmentType, 'permissions': value.roles, 'primaryReportingManager': value.reportingManager1, 'secondaryReportingManager': value.reportingManager2,
     'companyName': value.companyName, 'department': value.department, 'designation': value.designation, 'dateOfJoining': value.dateOfJoining,
     'officeLocation': value.officeLocation,'phone': value.phone,'companyId' : this.companyId, 'source': source})
    .subscribe({
      next: (newEmployee) => {
        if(this.isDevMode) console.log("Saved :", newEmployee);
        this.saveMessage();
        this.getEmp();
      },
      error: (err: HttpErrorResponse) => {
        this.errMessage(err);
      }
    });
  }

  templateClear = false; //SK27OCT24 
  holidayClear = false; //SK27OCT24
  weekOffClear = false; //SK27OCT24
  saveLeaveAllocation(){
    const value = this.leaveAllocationForm.value;
    // this.spinnerService.show(); //SK14MAR24
    this.apiService.writeValue('patch', '/hrm/leaveallocation', {'template': this.templateList, 'holidays': this.holidayList, 'weekoff': value.weekoffLeaves, 'employeeid': this.leaveAllocationEmpData, 'companyId' : this.companyId})
    .subscribe({
      next: (newEmployee) => {
        // this.spinnerService.hide(); //SK14MAR24
        if(this.isDevMode) console.log("Patched :", newEmployee);
        this.saveSuccess('Leave allocated successfully');
        this.leaveAllocationEmpData = [];
        this.templateList = [];
        this.holidayList = [];
        //SK27OCT24 to clear checkboz after save
        this.templateClear = true;
        this.holidayClear = true;
        this.weekOffClear = true;
        this.resetLeaveAllocation();
      },
      error: (err: HttpErrorResponse) => {
        // this.spinnerService.hide(); //SK14MAR24
        this.showError(err);
      }
    });
    this.templateClear = false;
    this.holidayClear = false;
    this.weekOffClear = false;
  }

  resetLeaveAllocation(){
      this.leaveAllocationForm.controls['weekoffLeaves'].patchValue('');
      this.templateSelection?.clear();
      this.holidaySelection?.clear();
      this.weekOffSelection?.clear();
      this.leaveAllocTable?.clearCheckBox();
      this.weekOffValidation = false;
  }
  
   downloadCurrentEmployeesAllocations(){
    const csv = this.convertToCsvLeaveAllocation(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'Leave Allocation.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }
  convertToCsvLeaveAllocation(objArray: any[]) {
    let objectArray: Array<any> = [];
    objArray.forEach(element => {
      objectArray.push(Object.keys(element));
    });
    var selectedObjectsForCSV = this.filterObjectKeysLeavesAllocation(objArray, ['employeeId','name','department','designation','gender','primaryReportingManager','secondaryReportingManager','officeLocation']);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    array.unshift({employeeId:'Employee Id',name:'Employee Name',department:'Department',designation:'Designation',gender:'Gender',primaryReportingManager:'Primary Reporting Manager',secondaryReportingManager:'Secondary Reporting Manager',officeLocation:'Office Location'})
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        }
        if(typeof array[i][index] == 'object'){
          line += JSON.stringify(array[i][index]).replace(/,/g,"++");
          if(this.isDevMode) console.log("a",array[i][index]);
        }
        else{
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 
 
  // Function to get only the required fields for showing it in the excel(Used for Leave Allocation Data-Table)
  filterObjectKeysLeavesAllocation(arrayOfObjects: any, keys: string[]) { // keys are the required fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undefined
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }
  saveFilter(){
    const value = this. leaveAllocationFilterForm.value;
    const params = new HttpParams({
      'fromObject':{
        'department' : this.deptData,
        'designation': this.desigData,
        'primaryReportingManager': this.rpmData,
        'secondaryReportingManager': this.rsmData,
        'gender': this.genderData,
        'officeLocation': this.locationData
      }
    });

    this.apiService.fetchValue('/hrm/employees',params)
      .subscribe({
        next: (filter:any) => {
          if(this.isDevMode) console.log("filter :", filter);
          // this.empFilterGet = filter.employees;
        },
        error: (err: HttpErrorResponse) => {
          this.showError(err);
        }
      })
  }

  employeeFinanceInfo: any;
  employeeLeaves:any;
  employeeExpenses:any;
  /** These functions used to edit the form data that returned from database */
  onEditEmp(element:any){
    this.ess = true;
    // Store empId in local storage 
    // this.router.navigate([], { queryParams: { activeDetailPage: true } });
    // localStorage.setItem('empId', JSON.stringify({employeeId :element.employeeId}));
   
    this.basicEmpID = element.employeeId;
    // this.selectedEmployee = {employeeId :this.basicEmpID}
    
    const params = new HttpParams({
      fromObject:{
        employeeid : this.basicEmpID
      }
    });

    this.apiService.fetchValue('/hrm',params).subscribe((datas: any) => {
      if(this.isDevMode) console.log('USER DATA',datas);
      this.expenseHistory = datas.expense;
      this.selectedEmployee = datas?.loggedInUser;
      // SK17JAN24 for reducing api call, assigned value and sent to child component
      this.employeeFinanceInfo = datas?.financeInfo[0];
      this.employeeLeaves = datas?.leaves;
      this.employeeExpenses = datas?.expense;
    })

    this.newEmployeeForm.patchValue({
      'firstName' : element?.firstName,
      'lastName' : element?.lastName,
      'email' : element?.email,
      'phone': element?.phone,
      'employmentType':element?.employmentType,
      'employeeID' : element?.employeeId,
      'dateOfJoining' : element?.dateOfJoining,
      'officeLocation' : element?.officeLocation,
      'department' : element?.department,
      'designation' : element?.designation,
      'fusionId':element?.fusionId,
      'reportingManager1': element?.primaryReportingManager,
      'reportingManager2': element?.secondaryReportingManager,
      'gender': element?.gender,
      'roles' : element?.roles,
      'genID' : element?._id,
    })
  }

  // MR 10JAN24 - API call to get the updates from Server - Ideally this should happen from HRM Component and passed to all components
  getAPIData(empId: string) {
    const params = new HttpParams({
      fromObject:{
        employeeid : empId
      }
    });

    this.apiService.fetchValue('/hrm',params).subscribe((hrmData: any) => {
      this.expenseHistory = hrmData?.expense;
      this.selectedEmployee = hrmData?.loggedInUser;
      this.employeeFinanceInfo = hrmData?.financeInfo[0];
      this.employeeLeaves = hrmData?.leaves;
    });
  }

  goBack(){
    this.ess =false;
    this.router.navigate([], { queryParams: { activeDetailPage: false } });
    this.getEmployees();
  }

  tableClickData(event:any){
    const empObj = {...event[0]};
    this.leaveAllocationEmpData = [];
    this.onEditEmp(empObj);
    event.forEach((element:any) => {
      this.leaveAllocationEmpData.push(element.employeeId);
    });
  }

  closeDetail() {
    this.ess = false;
    // navigate to the same URL with the ess query parameter removed
    this.router.navigate([], { queryParams: { ess: null }, queryParamsHandling: 'merge' });
   // Remove the 'employee' key from local storage
    localStorage.removeItem('empId');
    
 }

  showSetting(){
    this.showSettings = this.globalValues.superUser;
    this.showHrm = this.globalValues.hrmUser;
  }

  searchFilterData(filteredTable:any){
    this.empGet = filteredTable;
  }

  //function to print the months on selection from dropdown;
  emittedValue($event:any) {
    if(this.isDevMode) console.log($event);
  }
  
  downloadTemplate(templateType: string){
    this.apiService.getCSVTemplates(templateType).subscribe({
      next: (v: any) => {
        if(this.isDevMode) console.log(v);
        const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
        saveAs(file, templateType+'-template.csv');  //name should be dynamic
        this.saveSuccess('Employee ' +  this.templateDownlaod)
    },
      error: (e) => {
        this.showError(e);
        console.error(e);

      },
      complete: () => console.info('complete') 
    });
  }

  // Upload the excel to add data to Employee record
  uploadRecordsFile(){
    const fd = new FormData();
    fd.append('file', this.employeeBulkFile, this.employeeBulkFile.name);
    this.apiService.writeValue('post','/hrm',fd).subscribe({
      next: (response: any) => {
        if(this.isDevMode) console.log(" upload completed ",response);
        this.saveSuccess('Employees added successfully');
        this.getEmp();
        this.filesArrayClear();
    },
      error: (e) => {
        console.error("upload failed with ",e);
        this.errMessage(e);
      },
      complete: () => console.info('upload completed') 
    });
  }

  tableCheckedDataa(checkedArray:any){
    this.checkedEmployees = [];
    this.checkedEmployees = checkedArray;
    if(this.checkedEmployees.length === 0){
      this.downloadCurrentDisable = true;
    } else {
      this.downloadCurrentDisable = false;
    }
    if(this.isDevMode) console.log('this.checkedEmployees',this.checkedEmployees);
  }

  userFileEmitter(event:any){
    this.employeeBulkFile = event;
  }
 
  checkedEmployees:any;
  downloadCurrentEmployees(){
    const csv = this.convertToCsv(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'HRM Employees.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }

  convertToCsv(objArray: any[]) {
    // SK19JUL24 update selected export fields
    let arrayOfObjects: { dateOfJoining: any; department: any; designation: any; email: any;  employmentType:any; employeeId: any; firstName: any; lastName: any; gender: any; officeLocation: any; phone: any;}[] = [];
    objArray.forEach(element => {
      const id = this.empGet.findIndex((data:any) =>{
        return data.employeeId === element.employeeId;
      });
      //SK14MAY24 
      arrayOfObjects.push({
        dateOfJoining: this.empGet[id].employee?.basicinfo?.dateOfJoining || "",
        department: this.empGet[id].employee?.workinfo?.department || "",
        designation: this.empGet[id].employee?.workinfo?.designation || "",
        email: this.empGet[id].employee?.email || "",
        employmentType:this.empGet[id].employee?.basicinfo?.employmentType || "",
        employeeId: this.empGet[id].employee?.employeeId || "",
        firstName: this.empGet[id].employee?.firstName || "",
        lastName: this.empGet[id].employee?.lastName || "",
        gender: this.empGet[id].employee?.personalinfo?.gender || "",
        officeLocation: this.empGet[id].employee?.basicinfo?.officeLocation || "",
        phone: this.empGet[id].employee?.contactinfo?.phone || "",
      });
    });
    var selectedObjectsForCSV = this.filterObjectKeys(arrayOfObjects, ['employeeId','firstName','lastName','gender','officeLocation','employmentType','phone','dateOfJoining','department','designation','email',]);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    array.unshift({employeeId:'employeeId',firstname:'firstname',lastname:'lastname',gender:'gender',officeLocation:'officeLocation', employmentType:'employmentType',phoneNumber:'phoneNumber',dateOfJoining:'dateOfJoining',department:'department',designation:'designation',email:'email'})
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        }
        if(typeof array[i][index] == 'object'){
          line += JSON.stringify(array[i][index]).replace(/,/g,"++");
          if(this.isDevMode) console.log("a",array[i][index]);
        }
        else{
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 

  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) { // keys are the reuired fields set by us 
    let filteredObjects:any = [];
    arrayOfObjects.forEach((obj: any,i: any) =>   // Should check for error scenario, arrayofObjects will be null / undeifned
    filteredObjects.push( keys.reduce((acc: any, key: any) => {
          if (obj.hasOwnProperty(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {})));
    return filteredObjects;
  }

  selectedRow(event:any){
    //SK24JAN24, Prevoiusly only the active tab is fetched, So that tab is directly pushed as Array, Now Sub Tab is also stored in local now it is pushed as object with tabs and their active sub tab 
    this.tabsList = JSON.parse(localStorage.getItem('hrmTabList') as any);
    const findEmployeeId = this.tabsList.filter((empId:any)=>empId.tab === event.employee.employeeId);
    if(findEmployeeId.length == 0){
      this.tabsList.push({tab:event.employee.employeeId, subTab:'basic'} as any);
      localStorage.setItem('hrmTabList',JSON.stringify(this.tabsList));
      this.showTabs({tab:event.employee.employeeId});
    } else  {
      this.tabsList = JSON.parse(localStorage.getItem('hrmTabList') as any);
      localStorage.setItem('hrmTabList',JSON.stringify(this.tabsList));
      this.tabSwitch = 'employeeId';
      this.activeTab = {"tab":event.employee.employeeId}; //SK18JAN24 for active employee record tab
      localStorage.setItem('activeHrmTab', JSON.stringify(this.activeTab));
    }
    this.onEditEmp(event.employee);
    this.selectedEmployeeId = event.employeeId;
    const obj = this.empGet.filter((data:any) => data.employeeId === event.employeeId)
    this.permissionsList = obj[0]?.employee?.permissions;
    this.rolesList?.forEach(element => { // SK 21DEC23 to solve undefined error, added '?'
      if(this.permissionsList?.includes(element)){
        this.permissionSelection.select(element);
      }
    });
  } 
//           [headerLabels]="['EmployeeId', 'Name', 'Designation','Department','Email','Phone','Employment Type']"
         
  savPermissions(){
    this.apiService.writeValue('patch', '/hrm', {permissions: this.permissionSelection.selected, employeeId: this.selectedEmployeeId, companyId: this.globalValues.orgId  }).subscribe({
      next : () =>{
        this.saveSuccess('Roles updated successfully')
      },
      error : (error:HttpErrorResponse) => {
        this.errMessage(error)
      }
    })
  }

  //While clicking Rnun Payroll button in dashboard screen, the Payroll should be active, for that bootstrap Tab module is imported
  payrollActiveTab(){
    this.tabDirect = new Tab(this.demoTab.nativeElement);
    this.tabDirect.show();
  }

  // this is to clear the filename array in child component
  filesArrayClear(){
    this.filesArrayComp = [];
    this.employeeBulkFile = null || undefined;
  }

  deletedFileInComp(event:any){
    this.filesArrayClear();
  }

  // emit the check box values, that selected separately
  separateRowSelect(data: any) {
    this.newEmployeeForm.controls['roles'].patchValue(this.selection.selected);
  }

  sideScreenEvent($event: any){
    if($event)
    switch(Object.keys($event)[0]){
      case 'open': // This is to open the side screen to add new salary setting component 
        this.showSideScreen = $event.open;
        break;
      case 'formData':         
        // Based on the componentActivity either add the new data ('create')  or update existing data ('edit')
        if(this.componentActivity == 'create'){
          this.settingsComponentListData.push($event.formData);

          // Make the patch call by passing the components array after pushing the respective data
          this.callPatchForSalaryComponent(this.settingsComponentListData,'component-create');
        }
        else{ 
          // better to check for else if(this.componentActivity == 'update' )rather than else condition
          // this is executed when user clicks on edit a data from existing table
          // First we remove the selected data from the array 
          // this.settingsComponentListData = this.settingsComponentListData.filter((obj:any) => obj !== this.sideScreenData);
          this.settingsComponentListData.forEach((element:any, index:number) => {
            if(element === this.sideScreenData){
              this.settingsComponentListData[index] = $event.formData;
            }
          });
          // then we push the updated data on to the table data also 
          // this.settingsComponentListData.push($event.formData);

          // Make the patch call by passing the components array after pushing the respective data
          this.callPatchForSalaryComponent(this.settingsComponentListData,'component-update');
        }
        break;
    }
  }

  editPayrollComponent($event: any, component:any){
    if(component === 'salary'){
      // This function will pass the data to the side screen , hence prefills the fields with the selected data from the table
      // And then opens the side screen
      this.sideScreenData = $event.data;
      this.showSideScreen = true;
      this.componentActivity = 'update';
    } else if(component === 'tax'){
      
      this.taxSideScreenData = $event.data;
      this.taxSidescreen = true;
      this.taxComponentActivity = 'update';
    }
  }

  deletePayrollComponent($event: any, component:any){
    if(component === 'salary'){
      this.componentActivity = 'delete';
      // we compare the selected object and remove it from the consolidated component data stored in 'settingsComponentListData'
      this.settingsComponentListData = this.settingsComponentListData.filter((obj:any) => obj !== $event.data);
          
      // Make the patch call by passing the components array after deleting the respective data
      this.callPatchForSalaryComponent(this.settingsComponentListData,"component-delete");
    } else if (component === 'tax'){
      // SK27FEB24 changed since updated code on delete in datatable
      this.taxComponentActivity = 'delete';
      this.sectionListData[0][$event.data.sectionName]['investment'].forEach((element:any, index:number) => {
        if($event.data.name === element.name){
          this.sectionListData[0][$event.data.sectionName]['investment'].splice(index,1);
        }
      });
      if(this.sectionListData[0][$event.data.sectionName]['investment'].length === 0){
        delete this.sectionListData[0][$event.data.sectionName];
      }
      this.tdsPatchCall(this.sectionListData, "component-delete");
    }
    
  }

  // filtering the datas based on tab selected on hrm tds and salary components
  filterTableData(typeTofilter: any, component:any){
    this.filteredTab = typeTofilter;
    if(component === 'salary'){
      this.settingsComponentListTableData =  this.settingsComponentListData.filter((item:any) => item.type.toLowerCase().trim() === typeTofilter.toLowerCase().trim());
    } else if(component === 'tax'){
      this.tdsListArray = [];
      let investments = {};
      this.sectionListData[0]?.[typeTofilter]?.['investment'].forEach((element:any) => {
        investments = element;
        this.activeSection = {'sectionName': typeTofilter} || '';
        
        this.sectionLimit = {'maxSectionLimit': this.sectionListData[0][typeTofilter].maxSectionLimit} || '';
        this.tdsListArray.push({...element, ...this.activeSection, ...this.sectionLimit});
      });
    }
  }

  callPatchForSalaryComponent(salaryComponentArray: Array<object>, successMessage:  string){
    // We update it in the database with the patch api as we are just updating the existing data and we dont have a separate api as of now for create, edit, delete
    this.apiService.writeValue('patch', '/settings/salary', {da:this.settingsData.salary['da'], hra:this.settingsData.salary['hra'], pfEmployeeShare:this.settingsData.salary['pfEmployeeShare'], pensionShare:this.settingsData.salary['pensionShare'], pfEmployerShare:this.settingsData.salary['pfEmployerShare'], esiEmployeeShare: this.settingsData.salary['esiEmployeeShare'], esiEmployerShare: this.settingsData.salary['esiEmployerShare'],components: salaryComponentArray as any, companyId: this.settingsData['companyId']})
    .subscribe({
      next: (salarysettings) => {
        this.saveMessage(successMessage);

        if(this.componentActivity == 'create'){
          this.getUserData();
          this.showSideScreen = false;
          this.filterTableData(this.componentTypes[this.componentFilterIndex], 'salary');
        }
        if(this.componentActivity == 'update'){
          // this will update the table data , so that we dont have to call a service 
          this.showSideScreen = false;
          this.settingsComponentListTableData = this.settingsComponentListData;
          this.filterTableData(this.componentTypes[this.componentFilterIndex], 'salary');
        }
        if(this.componentActivity == 'delete'){
          // As a temporary work around, Now we modify the tabledata instead of depending on api and waiting for data we make the changes here itself
          // So once the 'settingsComponentListData' is updated we again filter the respective data for the table based on the current filterType
          this.filterTableData(this.componentTypes[this.componentFilterIndex], 'salary');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
        this.errMessage(err);
      }
    });
  }

  // the toogle button function in hrm tds and slaray component table, which literally does patch call based on modifed data
  detectToggleChange($event: any, component:any){
    if(component === 'salary'){
      this.componentActivity = 'update';
      this.sideScreenData = $event;
      this.sideScreenEvent({'formData': $event});
    } else if(component === 'tax'){
      this.taxComponentActivity = 'update';
      this.taxSideScreenData = $event;
      this.saveTDS({'formData': $event, 'activity': 'update'});
    }
  }

  // creating the new tax component in hrm tds
  addTaxComp(){
    this.taxSidescreen = true; 
    this.taxComponentActivity = 'create';
    this.taxSideScreenData = {};
  }

  // the datas sent from hrm-tds component in processed here, based on that component creating / updating done
  saveTDS(event:any){
    this.taxComponentActivity = event.activity;
    const data = event.formData;
    const investmentObj = {
      'name' : event.formData.name,
      'maxAmount' : event.formData.maxAmount,
      'minAmount' : event.formData.minAmount,
      'maxPercent' : event.formData.maxPercent,
      'minPercent' : event.formData.minPercent,
      'active' : event.formData.active,
      'isExempted' : event.formData.isExempted,
      'condition' : event.formData.condition
    }
    switch(this.taxComponentActivity) {
      case 'close' : 
        this.taxSidescreen = false;
        break;
      case 'create' :
        if(this.sectionListData.length === 0){
          this.sectionListData.push({ 
            [event.formData.sectionName]: {
            'maxSectionLimit' : event.formData.maxSectionLimit,
            'investment' : [investmentObj]
          }});
        } else {
          if(this.sectionTypes.includes(event.formData.sectionName)){
            this.sectionListData[0][event.formData.sectionName]['maxSectionLimit'] = event.formData.maxSectionLimit;
            this.sectionListData[0][event.formData.sectionName]['investment'].push(investmentObj);
          }  else {
            this.addSection(event, investmentObj);
          }
        }
        this.tdsPatchCall(this.sectionListData, 'component-create');
        break;
      case 'update' :
        this.sectionListData[0][event.formData.sectionName]['maxSectionLimit'] = event.formData.maxSectionLimit;
        this.sectionListData[0][event.formData.sectionName]['investment'].forEach((element:any, index:number) => {
          const eachData = {
            'name' : this.taxSideScreenData.name,
            'maxAmount' : this.taxSideScreenData.maxAmount,
            'minAmount' : this.taxSideScreenData.minAmount,
            'maxPercent' : this.taxSideScreenData.maxPercent,
            'minPercent' : this.taxSideScreenData.minPercent,
            'active' : this.taxSideScreenData.active,
            'isExempted' : this.taxSideScreenData.isExempted
          };
          
          if(this.taxSideScreenData.name === element.name){
            this.sectionListData[0][event.formData.sectionName]['investment'][index] = investmentObj;
          }
        });
        this.tdsPatchCall(this.sectionListData, 'component-update');
        break;
    }
  }

  addSection(event:any, investements:any){
    Object.assign(this.sectionListData[0], { 
      [event.formData.sectionName]: {
        'maxSectionLimit' : event.formData.maxSectionLimit,
        'investment' : [investements]
      }
    });
  }

  // the hrm tds data sent through this api call
  tdsPatchCall(array:any, alertMessage:any){
    this.apiService.writeValue('patch', '/hrm/tax', {tax: array, companyId: this.globalValues.orgId})
    .subscribe({
      next : (success:any) => {
        console.log(success);
        this.taxSidescreen = false;
        this.saveMessage(alertMessage);
        this.getUserData();
        
      },
      error : (error:any) => {
        console.log(error);
        this.showError(error)
      }
    })
  }

  dropdownEmitFunction(event:any, filter:any){
    if(filter == 'type'){
      // The value for the selected key is mapped here and sent to api
      const keys : any = {
        'Comparison Charts' : 'comparison',
        'Non-Comparison Charts' : 'noncomparison',
      }
      this.filterType = keys[event.data];
    } else if(filter == 'metrics'){
      // The value for the selected key is mapped here and sent to api
      const keys : any = {
        'Designation' : 'designation',
        'Gender' : 'gender',
        'Experience' : 'workExperience',
        'Office Location' : 'officeLocation',
        'Job Title' : 'jobTitle',
        'Blood Group' : 'bloodgroup',
        'Employment Type' : 'employmentType',
        'Marital Status' : 'maritalStatus'
      }
      this.dashboardQuery = keys[event.data];
    } 
    this.dashboardAPI();
  }

  //fetching the dashboard inputs from api call
  dashboardAPI(){
    this.metricsArray = [];
    let metrics : any;
    // SK01FEB24, If Dashboard module is found in custom app, the dashboards metrics is assigned to a variable
    const userDataConst:any = localStorage.getItem('userData');
    if(userDataConst) {
      const loggedInUserData = JSON.parse(userDataConst).apps;
      loggedInUserData.forEach((appArr:any) => {
        if(appArr.name === 'hrm'){
          appArr.modules.forEach((modArr:any) => {
            if (modArr.name === "dashboard"){
              modArr.tabs.forEach((tabArr:any) => {                
                if(tabArr.name === 'dashboard'){ //SK02FEB24, Dahsboard fix, this was hrm dashboard
                  this.isDynamicDashboard = true;
                  this.dynamicDashboard = tabArr.config.metrics;
                }
              });
            }
          });
        }
      });
    }
    // object that contains filter keys(Array data type) i.e. comparison or non-comparison charts, in that array we will be listing the data based on metrics
    // SK01FEB24
    if(this.isDynamicDashboard){
      metrics = this.dynamicDashboard;
    } else {
      metrics = {
        noncomparison : [
          {key:'leaves', name:'leaveType', chart:'Pie', filterType: 'noncomparison', label: 'Leaves'},
          {key:'salary', name:'salary', chart:'Advanced Pie', filterType: 'noncomparison', label: 'Salary Processed'},
          {key:'designation', name:'designation', chart:'Bar', filterType: 'noncomparison', label: 'Designation'}, 
          {key:'gender', name:'gender', chart:'Pie', filterType: 'noncomparison', label: 'Gender'}, 
          {key:'officeLocation', name:'officeLocation', chart:'Bar', filterType: 'noncomparison', label: 'Office Location'}, 
          {key:'jobTitle', name:'jobTitle', chart:'Pie', filterType: 'noncomparison', label: 'Job Title'},
          {key:'bloodgroup', name:'bloodgroup', chart:'Advanced Pie', filterType: 'noncomparison', label: 'Blood Group'}, 
          {key:'employmentType', name:'employmentType', chart:'Pie', filterType: 'noncomparison', label: 'Employment Type'}, 
          {key:'maritalStatus', name:'maritalStatus', chart:'Advanced Pie', filterType: 'noncomparison', label: 'Marital Status'},
          {key:'workExperience', name:'workExperience', chart:'Pie', filterType: 'noncomparison', label: 'Work Experience'}, 
        ],
      }
    }

    this.metricsArray = [];
    for (let metricKey in metrics) {
      let types : any = {};
      //the keys that to be sent to API is filtered here 
      metrics[metricKey].forEach((item:any) => {
        types[item.key] = true;
      });
      this.apiService.writeValue('post','/hrm/dashboard',{...{filter:metricKey}, ...types}).subscribe((data:any) => {
        metrics[metricKey].forEach((element:any) => {
          element.data = data[element.name];
        });
        this.metricsArray.push(...metrics[metricKey]);
        this.globalValues.chartDataOnPass(this.metricsArray);
      })
    }
  }

  //SK18JAN24 to display employee records as tabs. Dashboard and Employees tab will be common. 
  // While clicking employee records, it will render dynamically
  showTabs(tab:any){
    //SK24JAN24
    this.tabsList = JSON.parse(localStorage.getItem('hrmTabList') as any);
    const index = this.allData.findIndex((i:any)=> i.employeeId === tab.tab);
    if(index >= 0) this.selectedRow(this.allData[index]);
    this.ess = false;
    this.activeTab = {"tab":tab.tab};
    this.tabSwitch = tab.tab;
    localStorage.setItem('activeHrmTab', JSON.stringify(this.activeTab));
    localStorage.setItem('hrmTabList', JSON.stringify(this.tabsList));
    if(tab.tab === 'Employees'){
      this.ess = false;
    } else if(tab.tab === 'Dashboard') {
      this.dashboardAPI();
    } else {
      this.tabSwitch = 'employeeId'
      this.ess = true;
    }
  }

  closeBrowseTab(tabData:any, index:number){
    this.tabsList.splice(index,1);
    localStorage.setItem('hrmTabList', JSON.stringify(this.tabsList));
    if(tabData.tab === this.activeTab.tab){
      this.showTabs(this.tabsList[index - 1]);
    }
  }

  checkAppUser(event:any, from:string){
    (this.newEmployeeForm.value.isAppUser && this.newEmployeeForm.value.password === '') || this.newEmployeeForm.value.isAppUser && this.newEmployeeForm.value.fusionId === '' ?  this.appUserCondition = true :  this.appUserCondition = false;
  }

  // SK03OCT24 runs on clicking export button in table
  exportButtonEmit(event:any){
    this.apiService.fetchData("/settings/reports?reportName=employees").subscribe((report:any)=>{
      this.employeeFilter = report;
      this.filterScreenConfigs = []

      // SK03OCT24 configs for side screen filters
      let exportFieldsList:any = {};
      let objs = report.exportFields
      let displayValues = {
        keyString:"name",
        valueString:"value"
      }
      exportFieldsList.type = "multipleCheckBox";
      exportFieldsList.displayValues = displayValues;
      exportFieldsList.label = "Select Fields to Export";
      exportFieldsList.cardSize = "col-md-12";
      exportFieldsList.cardInlineSize = "col-md-6";
      exportFieldsList.checkboxList = objs.allFields;
      exportFieldsList.activeCheckboxList = objs.selectedFields;
      exportFieldsList.checkBoxStyles = "max-height:150px; overflow:auto"
      exportFieldsList.buttons = [
        {
          "buttonType": "",
          "name": "save",
          "label": "Save Fields",
          "class": "btn btn-primary ms-2",
          "buttonAction": "save",
          "path": "/settings/reports/employees",
          "method": "patch",
          "patchKey": "permissions",
          "isParams": "false",
          "paramsKey": "",
          "paramsValue": "",
          "successMessage": "Data Saved Successfully"
        }
      ]
      if(this.configJSON)this.configJSON.exportFieldsListConfigs = exportFieldsList;
      this.exportFieldsListConfigs = exportFieldsList;
      this.filterScreenConfigs.push(exportFieldsList);


      let exportFilterList:any = {};
      let displayFilterValues = {
        keyString:"name",
        valueString:"value"
      }
      exportFilterList.type = "multipleFilter";
      exportFilterList.displayValues = displayFilterValues;
      exportFilterList.label = "Select Filters";
      exportFilterList.cardSize = "col-md-12";
      exportFilterList.cardInlineSize = "col-md-12";
      exportFilterList.checkBoxStyles = ""
      exportFilterList.selectionData = report?.exportFields?.selectors || [] 
      exportFilterList.buttons = [
        {
          "buttonType": "",
          "name": "saveFilter",
          "label": "Save Filters",
          "class": "btn btn-primary ms-2",
          "buttonAction": "saveFilter",
          "path": "/settings/reports/employees",
          "method": "patch",
          "patchKey": "",
          "isParams": "false",
          "paramsKey": "",
          "paramsValue": "",
          "successMessage": "Data Saved Successfully"
        }
      ]
      if(this.configJSON)this.configJSON.exportFilterListConfigs = exportFilterList;
      this.exportFilterListConfigs = exportFilterList;
      this.filterScreenConfigs.push(exportFilterList);
    })
  }

  // SK03OCT24 emitted on sideNav save buttons
  sideNavSavButtonEmit(evt:any){
    if(evt?.button?.buttonAction === 'export'){
      let collectionName = "employees";
      this.apiService.getXlsx("/dbops/file?filter=3&reportName="+collectionName).subscribe({
        next: (v: any) => {
          if (this.isDevMode) console.log(v);
          const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
          saveAs(file, collectionName+'.xlsx');  //name should be dynamic
          this.saveSuccess("File downloaded successfully");
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
          this.showError(e);
        },
        complete: () => console.info('complete')
      });
    } else if(evt?.button?.buttonAction === 'save'){
        this.apiService.writeValue("patch", "/settings/reports/employees", {selectedFields:evt.checkListArray}).subscribe({
          next: (success: any) => {
            this.saveSuccess(success.message);
            this.globalValues.userGetAPI();
          },
          error: (e: HttpErrorResponse) => {
            console.error(e);
            this.showError(e);
          },
          complete: () => console.info('complete')
        });
    } else if(evt?.button?.buttonAction === 'saveFilter'){
      this.apiService.writeValue("patch", "/settings/reports/employees", {selectors: this.employeeFilter.exportFields.selectors}).subscribe({
        next: (success: any) => {
          this.saveSuccess(success.message);
          this.globalValues.userGetAPI();
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
          this.showError(e);
        },
        complete: () => console.info('complete')
      });
    }
  }

  // SK03OCT24 emitted on selecting Export button in Side Nav filter
  exportReports(){
    let collectionName = "employees";
    this.apiService.getXlsx("/dbops/file?reportName="+collectionName).subscribe({
      next: (v: any) => {
        if (this.isDevMode) console.log(v);
        const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
        saveAs(file, collectionName+'.xlsx');  //name should be dynamic
        this.saveSuccess("File downloaded successfully");
      },
      error: (e: HttpErrorResponse) => {
        console.error(e);
        this.showError(e);
      },
      complete: () => console.info('complete')
    });
  }

  // SK03OCT24 multiple select filter emit
  multipleSelectFilterExport(evt:any){
    const ind = this.employeeFilter?.exportFields?.selectors.findIndex((filtId:any)=> filtId.id === evt.list.id)
    if(ind>=0){
      this.employeeFilter.exportFields.selectors[ind] = evt.list;
    }
  }

  // SK03OCT24
  filterDateEmitEvt(evt:any){

  }
}
