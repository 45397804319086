<!-- SK06MAR24 Created this component for navTabs -->
<div class="mb-2 p-0">
    <ul class="nav nav-tabs fc-tabs" id="navTabsComp" role="tablist">
        <li *ngFor="let tab of tabsLists; let i=index" class="nav-item flex me-2" role="presentation">
            <button *ngIf="!hideTabsList?.includes(tab.value)" class="nav-link" id="{{tab.name}}" data-bs-toggle="tab"
                [class]="tab?.value === activeTab ? 'active': ''" type="button" role="tab"
                (click)="activeTabEmit(tab)">{{tab.name | customTranslate : tab.name}}
            </button>
            <svg-icon class="close-hover" *ngIf="tab.browsedTab"
                src="../assets/images/icons/general/cross-icon.svg" (click)="closeBrowseTab(tab,i)">
            </svg-icon>
        </li>
    </ul>

    <!-- SK22MAR24 sub tab content -->
    <div [ngSwitch]="activeTab" >
        <div *ngFor="let tab of tabsLists; let i=index" >
            <div *ngSwitchCase="tab?.value" class="flex">
                <ul class="nav nav-tabs fc-tabs mt-2" id="navTabsComp" role="tablist">
                    <li *ngFor="let tab2 of tab.subTabs; let i=index" class="nav-item flex flex me-2" role="presentation">
                        <div class="flex flex-column">
                            <button class="nav-link sub-button" id="{{tab2.name}}" data-bs-toggle="tab"
                            [class]="tab2?.value === activeSubTab ? 'activeSub': ''" type="button" role="tab"
                            (click)="activeSubTabEmit(tab2)" (shown.bs.tab)="activeSubTabEmit(tab2)">
                            <span style="font-size: small;">{{tab2.name | customTranslate : tab2.name}}</span>
                            </button>
                            <div [style]="tab2?.value === activeSubTab ? 'border-bottom: 2px solid #D8315B;': ''"  ></div>
                        </div>
                        <div class="ms-2">
                            <svg-icon class="close-hover" *ngIf="tab2.browsedTab"
                                src="../assets/images/icons/general/cross-icon.svg" (click)="closeBrowseSubTab(tab2,i)">
                            </svg-icon>
                        </div>       
                    </li> 
                </ul>
                
            </div>
        </div>
    </div>
</div>