import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalColumSum',
  standalone: true
})
export class TotalColumSumPipe implements PipeTransform {
  // SK16JUL24 to  sum the column data

  transform(arg:any, data: any, cumulativeDataColumns: any, column:string): any {
    if(cumulativeDataColumns.includes(column)){
      const value =  cumulativeDataColumns.includes(column) ? data?.reduce((accum: number, curr: any ) => (Number(this.currencyToNumber(accum)))  + (Number(this.currencyToNumber(curr[column])) || 0), 0) : null;
      // return Number((value || 0).toFixed(2)).toFixed(2) //SK19JUL24 decimal fix in column sum
      return this.currencyReturn(value, "INR");
    } else {
      return null
    }
    

    
  }

  /** SK25JUL24 This function will convert a sting to number, for converting rupees to numbers */
  currencyToNumber(data:any){
    // const removeComma = data?.replace(/\,/g, '') // to remove commas from the string
    // return Number(removeComma.substring(1));
    if(data && typeof data === 'string'){
      const removeComma =  Number(data?.replace(/[^0-9.-]+/g,""));
      return Number(removeComma);
    } else if(data && typeof data === 'number'){
      return Number(data);
    } {
      return 0
    }
  }

  currencyReturn(rupee: number, code:string){
    let formatter = new Intl.NumberFormat(navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    const amt =  formatter.format(rupee);
    return `${amt}`;
  }

}
