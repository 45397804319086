import { Component, EventEmitter, OnInit, Output, isDevMode } from '@angular/core';
import { CommonModule, CurrencyPipe} from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { PayrollcompareComponent } from '../payrollcompare/payrollcompare.component';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { Constants } from 'src/app/models/Constants';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { CommonScreenComponent } from 'src/app/components/side-screen/common-screen/common-screen.component';
import { MultiCheckboxComponent } from 'src/app/components/multi-checkbox/multi-checkbox.component';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-payroll-table',
  standalone: true,
  imports: [CommonModule,NgxPaginationModule,FusionButtonComponent,FormsModule,AngularSvgIconModule,DataTableComponent,DropDownComponent,PayrollcompareComponent,SuccessFailureMessageComponent,FileUploadComponent,NgxSpinnerModule, CommonScreenComponent, MultiCheckboxComponent, TranslateModule, CustomTranslatePipe],
  templateUrl: './payroll-table.component.html',
  styleUrls: ['./payroll-table.component.css']
})

export class PayrollTableComponent implements OnInit {

  errorMessage = '';
  isProceedOn = false;
  currentDate = new Date(); // Get the current date
  previousMonthDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1); // Get the date of the previous month
  previousMonthString = this.previousMonthDate.toLocaleString('default', { month: 'long' }); // Get the month value as a string
  currentYear = new Date().getFullYear();
  selectaMonth = this.previousMonthString +' '+ this.currentYear;
  dates: Array<any> = []; // To store months and years for the dropdown;
  selectedCheckbox!: Array<any>; // The check box selected data will be stores in this array
  payrolls: Array<any> = []; // That 'tabledata' data will be assigned here separately, this will be sent to datatable  
  lopMonth:any; // the month selected in dropdown is converted into query params format
  queryMonth:any; // the month selected in dropdown is converted into query params format
  disableButton = true; // button validation variable
  completedPayroll!: Array<any>; // The table data of payroll completed employees will be assigned in this array
  inCompletePayroll!: Array<any>; // The table data of payroll incomplete employees will be assigned in this array
  fromPayrollCompare = false;
  allEmpData!: Array<any>;
  allQueryData!: Array<any>;

  dropDownList: Array<any> = []; // data to be shown in month dropdown is sent in this variable to dropdown component
  multipleFilterArray : any; // The data that to be sent to filter array in table th is sent as array of objects in this variable
  selectedMonth :any; // The selected month in dropdown is stored in this variable
  compareArray!: Array<any>; // The checked employee data will be sent to compare table as Array
  queriedData!: Array<any>; // While selecting month, the data will be received from API get call and that is stored in this variable

  filesArrayComp:any;
  compensationBulkFile: any;

  @Output() alertMessageEmit = new EventEmitter();
  @Output() errorMessageEmit = new EventEmitter();

  isDevMode: boolean = false;
  templateDownload =  Constants.DOWNLOAD_TEMPLATE;
  headers:Array<string> = [];
  displayedColumns:Array<string> = [];
  settingsData:any
  tableData:any;
  result:any;
  isDynamicHRM = false; //SK18JUN24
  configJSON:any; //SK18JUN24
  typeSelected = ''; //SK18JUN24
  filterKeys:any = {}; //SK18JUN24
  finalData:any; //SK18JUN24
  cumulativeDataColumns:Array<any> = []; //SK16JUL24
  bankFileSidescreen = false;
  configTemplate :any;

  constructor(private apiService: ApiService,private errorHandler: ErrorHandlerService,private router:Router, private currencyPipe:CurrencyPipe, private alertService: AlertService, private globalValues: GlobalValuesService, private spinnerService: NgxSpinnerService,private translate : TranslateService) {
    this.typeSelected = 'ball-spin-clockwise-fade'; // SK18JUN24 loader type
    this.isDevMode = isDevMode();
    //to get the month and year in dropdown;
    for (var i = 0; i < 6; i++) {
      var d = new Date();
      d.setDate(1);
      d.setMonth(d.getMonth() - i);
      var month = d.toLocaleString("default", { month: "long" });
      var year = d.getFullYear();
      this.dates.push(month + " " + year);
    }
    this.globalValues.laguageChanges$.subscribe(lang => {
      this.headervalues();
    });

    // SK25JUL24 multicheck for bankxport fields
    this.configTemplate = {
      "type": "multiCheck",
      "name":"bankFile",
      "label": "Save / Export Fields",
      "cardSize": "col-md-12",
      "cardInlineSize": "col-md-6",
      "checkboxList":[
        {'label':'Employee ID', 'value':'employeeId'}, 
        {'label':'Account Holder Name', 'value':'accountHolderName'}, 
        {'label':'Bank Name', 'value':'bankName'},
        {'label':'Account Number', 'value':'accountNumber'}, 
        {'label':'Branch Name', 'value':'branchName'}, 
        {'label':'IFSC', 'value':'ifsc'}, 
        {'label':'Payment Mode', 'value':'paymentMode'}, 
        {'label':'Pay Date', 'value':'payDate'}, 
        {'label':'Net Pay', 'value':'netPay'}, 
      ],
      "activeCheckboxList":[
        {'label':'Employee ID', 'value':'employeeId'}, 
        {'label':'Account Holder Name', 'value':'accountHolderName'}, 
        {'label':'Bank Name', 'value':'bankName'},
        {'label':'Account Number', 'value':'accountNumber'}, 
        {'label':'Branch Name', 'value':'branchName'}, 
        {'label':'IFSC', 'value':'ifsc'}, 
        {'label':'Payment Mode', 'value':'paymentMode'}, 
        {'label':'Pay Date', 'value':'payDate'}, 
        {'label':'Net Pay', 'value':'netPay'}, 
      ],
      "buttons": [
        {
          "buttonType": "",
          "name": "save",
          "label": "Save Fields",
          "class": "btn btn-primary ms-2",
          "buttonAction": "save",
          "path": "/settings/finance/",
          "method": "patch",
          "patchKey": "permissions",
          "isParams": "false",
          "paramsKey": "",
          "paramsValue": "",
          "successMessage": "Data Saved Successfully"
        },
        {
            "buttonType": "",
            "name": "export",
            "label": "Export",
            "class": "btn btn-primary ms-2",
            "buttonAction": "export",
            "path": "/settings/users/",
            "method": "patch",
            "patchKey": "permissions",
            "isParams": "true",
            "paramsKey": "fusionId",
            "paramsValue": "",
            "successMessage": "Permission Assigned Successfully"
        }
      ]
    }
  }

  ngOnInit(): void {
    const userDataConst:any = localStorage.getItem('userData');
    const settingsDataConst:any = localStorage.getItem('settingsData');
    if(userDataConst && settingsDataConst){
      this.settingsData = [];
      const loggedInSettingsData = JSON.parse(settingsDataConst);
      // SK18JUN24 to get the dynamic config details
      this.configTemplate.activeCheckboxList = loggedInSettingsData?.finance?.bankExportFields;
      this.configTemplate.checkboxList = loggedInSettingsData?.finance?.bankExportAllFields;
      this.settingsData = loggedInSettingsData?.salary?.components;
      if(userDataConst) {
        const loggedInUserData = JSON.parse(userDataConst).apps;
        loggedInUserData?.forEach((appArr:any) => {
          if(appArr.name === 'hrm'){
            this.isDynamicHRM = true;
            appArr?.modules?.forEach((modArr:any) => {
              if(modArr?.name === 'payroll'){
                modArr?.tabs.forEach((tabArr:any) => {
                  if(tabArr?.name === 'payroll'){
                    this.configJSON = tabArr?.config;
                  }
                });
              }
            });
          }
        });
      } else {
        console.log('waiting for the api response');
      }

      const compDetails: any[] = [];
      this.settingsData?.forEach((element:any) => {
        compDetails.push(element.name);
      });
      // SK18JUN24 validate and get the dynamic headerLabel  config if any
      if(this.configJSON?.headerLabels1 && this.configJSON?.headerLabels2){
        this.headers = [...this.configJSON?.headerLabels1, ...compDetails,...this.configJSON?.headerLabels2];
      } else {
        const empDetails = ['Employee Id', 'Employee Name','Department', 'Designation','Location','Working Days','Loss Of Pay','Casual Leave','Sick Leave','PT Location','Bank Name','IFSC Code','Bank Account','Payment Mode'];
        const empDetails2 = ['Gross', 'OT Amount', 'Reimbursements', 'TDS', 'Net','Payroll Status'];
        this.headers = [...empDetails, ...compDetails,...empDetails2];
      }
      this.cumulativeDataColumns = this.configJSON?.cumulativeDataColumns || []; //SK16JUL24 to get column list to sum
      const displayedCompDetails: any[] = [];
      this.settingsData?.forEach((element:any) => {
        displayedCompDetails.push(element.name + '_' + element.type);
      });
      // SK18JUN24 validate and get the dynamic displayedColumns  config if any

      if(this.configJSON?.displayedColumns1 && this.configJSON?.displayedColumns2){
        this.displayedColumns = [...this.configJSON?.displayedColumns1,...displayedCompDetails,...this.configJSON?.displayedColumns2]
      } else {
        const displayedCol = ['employeeId','employeeName','department','designation','location','workingdays','lossOfPay','casualLeave','sickLeave','ptLocation','bankName','ifsc','bankAccount','paymentMode'];
        const displayedCol2 = [ 'gross','otAmount', 'reimbursements', 'tds','netPay','payrollStatus'];
        this.displayedColumns = [...displayedCol,...displayedCompDetails,...displayedCol2]
      }
    } else {
      this.apiService.fetchValue('/user').subscribe((settings:any) => {
        this.settingsData = [];
        // SK18JUN24 to get the dynamic config details
        this.settingsData = settings?.settings[0]?.salary?.components;
        const userDataConst:any = localStorage.getItem('userData');
        this.configTemplate.activeCheckboxList = settings?.settings[0]?.finance?.bankExportFields;
        this.configTemplate.checkboxList = settings?.settings[0]?.finance?.bankExportAllFields;
        if(userDataConst) {
          const loggedInUserData = JSON.parse(userDataConst).apps;
          loggedInUserData?.forEach((appArr:any) => {
            if(appArr.name === 'hrm'){
              this.isDynamicHRM = true;
              appArr?.modules?.forEach((modArr:any) => {
                if(modArr?.name === 'payroll'){
                  modArr?.tabs.forEach((tabArr:any) => {
                    if(tabArr?.name === 'payroll'){
                      this.configJSON = tabArr?.config;
                    }
                  });
                }
              });
            }
          });
        } else {
          console.log('waiting for the api response');
        }
  
        const compDetails: any[] = [];
        this.settingsData?.forEach((element:any) => {
          compDetails.push(element.name);
        });
        // SK18JUN24 validate and get the dynamic headerLabel  config if any
        if(this.configJSON?.headerLabels1 && this.configJSON?.headerLabels2){
          this.headers = [...this.configJSON?.headerLabels1, ...compDetails,...this.configJSON?.headerLabels2];
        } else {
          const empDetails = ['Employee Id', 'Employee Name','Department', 'Designation','Location','Working Days','Loss Of Pay','Casual Leave','Sick Leave','PT Location','Bank Name','IFSC Code','Bank Account','Payment Mode'];
          const empDetails2 = ['Gross', 'OT Amount', 'Reimbursements', 'TDS', 'Net','Payroll Status'];
          this.headers = [...empDetails, ...compDetails,...empDetails2];
        }
        this.cumulativeDataColumns = this.configJSON?.cumulativeDataColumns || []; //SK16JUL24 to get column list to sum
        const displayedCompDetails: any[] = [];
        this.settingsData?.forEach((element:any) => {
          displayedCompDetails.push(element.name + '_' + element.type);
        });
        // SK18JUN24 validate and get the dynamic displayedColumns  config if any
  
        if(this.configJSON?.displayedColumns1 && this.configJSON?.displayedColumns2){
          this.displayedColumns = [...this.configJSON?.displayedColumns1,...displayedCompDetails,...this.configJSON?.displayedColumns2]
        } else {
          const displayedCol = ['employeeId','employeeName','department','designation','location','workingdays','lossOfPay','casualLeave','sickLeave','ptLocation','bankName','ifsc','bankAccount','paymentMode'];
          const displayedCol2 = [ 'gross','otAmount', 'reimbursements', 'tds','netPay','payrollStatus'];
          this.displayedColumns = [...displayedCol,...displayedCompDetails,...displayedCol2]
        }
      });
    } 

    const currentDate = new Date(); // Get the current date
    const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1); // Get the date of the previous month
    const year = new Date().getFullYear();
    const previousMonthString = previousMonthDate.toLocaleString('default', { month: 'long' }); // Get the month value as a string
    const previousMonthValue = previousMonthDate.getMonth() + 1; // Get the month value (add 1 because months are zero-indexed)
    if(this.selectaMonth == previousMonthString +' '+ year) {
      this.selectedMonth = previousMonthValue + " " + year;
      this.queryMonth = year + '-' + previousMonthValue;
      this.lopMonth = previousMonthValue + '-' + year; // to select the month from dropdown while editing lop;
      this.dropdownEmitFunction({data:this.selectaMonth});
    }
  }

  /** The selected month will be received here and the initialTableData function and leaavesPushFunction will be executed  */
  dropdownEmitFunction(event: any) {
    this.selectedCheckbox = [];
    this.selectaMonth = event.data;
    this.selectedMonth = event.data;
    const month = new Date('01' + " " + event.data).getMonth() + 1;
    const year = new Date('01' + " " + event.data).getFullYear();
    this.queryMonth = year + '-' + month;
    this.lopMonth = month + '-' + year; // to select the month from dropdown while editing lop;

    const parameter = new HttpParams({
      fromObject:{
        month : this.queryMonth
      }
    });
    // this.spinnerService.show();
    // SK18JUN24 get table details dorectly from endpoint
    this.apiService.fetchValue('/hrm/allemployees',parameter).subscribe((data:any) => {
      // this.spinnerService.hide();
      this.queriedData = data.employeeDetails;
      this.payrolls = data.payrollData;
      this.finalData = JSON.stringify(data.payrollData);
      // SK18JUN24 filter columns configs
      if(this.configJSON?.filterColumns?.length > 0){
        this.multipleFilterArray = {};
        const filtObjs:any = {};
        this.configJSON.filterColumns.forEach((element:any) => {
          this.multipleFilterArray[element] = null;
          filtObjs[element] = [];
        });
        this.payrolls.forEach((element:any) => {
          this.configJSON.filterColumns.forEach((key:any) => {
            if(Object.keys(element).includes(key)){
              filtObjs[key].push(element[key])
              this.multipleFilterArray[key] =(new Set(filtObjs[key]));
            }
          });
        });
        this.configJSON.multipleFilterArray = this.multipleFilterArray;
        const filter:any =localStorage.getItem('payrollfilter') //MV24AUG24 get the payrollfilter keys and pass to the filter retain
        this.filterKeys=JSON.parse(filter)||{}
        this.filterRetain(this.filterKeys)
      }
    });
  }

  // to save the editted LOP;
  saveLop(event:any){
    const empId: any[] = [];
    const lossOfPay: any[] = [];
    //to push the selected checkbox data;
    // SK27FEB24 editing all lop inputs
    event?.selectedData?.forEach((element:any) => {
      empId.push (element.employeeId);
      lossOfPay.push (element.lossOfPay || 0);
    });
    //to send the editted data to backend;
    if(empId.length > 0 && lossOfPay.length > 0){
      // this.spinnerService.show();
      this.apiService.writeValue('patch','/employee/leaves?month='+this.lopMonth,{ 'employeeId': empId, 'days': lossOfPay}).subscribe({
        next: (savedLop) => {
          // this.spinnerService.hide();
          if(this.isDevMode) console.log("LOP Status:", savedLop);
          this.saveSuccess('Your save was successful');
          this.dropdownEmitFunction({data:this.selectedMonth});
        },
        error:(err: HttpErrorResponse) => {
          // this.spinnerService.hide();
          this.showError(err);
        }
      })
    } else {
      this.alertService.messageOnPass('error', "No employees selected");
    }
    
  }

  // data that to be passed, when checkbox checked is stored in a variable
  tableClickData(event: any) {
    this.selectedCheckbox = event;
    this.buttonValidation();
  }

  /*  this function will transform the number to currency, with specific currency code mentioned */
  currencyReturn(rupee: number){
    return this.currencyPipe?.transform(rupee, "INR")
  }

  /** This function will convert a sting to number, for converting rupees to numbers */
  currencyToNumber(data:any){
    const removeComma = data?.replace(/\,/g, '') // to remove commas from the string
    return Number(removeComma.substring(1));
  }

  //  function executed after the payroll is executed for the selected month
  fromPayroll(event:any){
    this.fromPayrollCompare = event;
    this.isProceedOn = false;
  }

  /* function to call comparison table, the checked employees data will be pushed in new array, 
    and that array will be passed to compare table component */
  callCompareTable(){
    this.isProceedOn = true;
    this.compareArray = [];
    const empID: any[] = [];
    this.allEmpData = [];
    this.allQueryData = [];
    this.selectedCheckbox.forEach(data => {
      empID.push(data.employeeId); 
    });
    this.queriedData.forEach(element => {
      if(empID.includes(element.employeeId)){
        this.allQueryData.push(element);
      }
    });
  }

  // After clicking cancel button in payrollcompare screen, payroll-table screen will be displayed and the table data and filter checkbox will be reset
  onCompareCancel(){
    this.isProceedOn = false;
    this.selectedCheckbox = [];
    this.buttonValidation();
  }

  /** button will be enabled only 
      Anu of the checkbox is checked in that table
      And the dropdown list should be selected
  * */ 
  buttonValidation(){
    this.disableButton = this.selectedCheckbox === undefined || this.selectedCheckbox?.length === 0;
  }

  saveSuccess(data:any){
    this.alertService.messageOnPass('success', data);
  }

  showError(err:any){
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  downloadTemplate(templateType: string){
    this.apiService.getCSVTemplates(templateType).subscribe({
      next: (v: any) => {
        if(this.isDevMode) console.log(v);
        const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
        saveAs(file, templateType+'-template.csv');  //name should be dynamic
        this.saveSuccess('Compensation ' + this.templateDownload)
    },
      error: (e) => {
        this.showError(e);
        console.error(e);

      },
      complete: () => console.info('complete') 
    });
  }

  downloadEsiPfTemplate(templateType: string,selectedPeriod: string){
    let convertedPeriod = ''
    const date = new Date(selectedPeriod);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding leading zero if necessary
    convertedPeriod = year + '-' + month;  // So the input "April 2023" will be converted to "2023-04" 
    this.apiService.getEsiPFTemplates(templateType+"&month="+convertedPeriod).subscribe({
      next: (v: any) => {
        // if(this.isDevMode) console.log(v);
        const file = new Blob([v], { type: 'text/plain' }); // This matches the content type of the response
        let fileNamePrefix = '';
        if(templateType.includes('ecr')) fileNamePrefix = 'ecr';
        if(templateType.includes('arrear')) fileNamePrefix = 'ecr_arrear';
        if(templateType.includes('esi')) fileNamePrefix = 'esi';
        saveAs(file, fileNamePrefix+'-template.txt');  //name should be dynamic
        if(templateType == 'ecr') this.saveSuccess('ECR ' + this.templateDownload);
        if(templateType == 'arrear') this.saveSuccess('Arrear ' + this.templateDownload);
        if(templateType == 'esi') this.saveSuccess('ESI ' + this.templateDownload);
    },
      error: (e) => {
        this.showError(e);
        console.error(e);

      },
      complete: () => console.info('complete') 
    });
  }

  // Set of function related to fileupload 
  // Starts here ( file upload )

  // Upload the excel to add data to Employee record
  uploadRecordsFile(){
  
    const fd = new FormData();
    fd.append('file', this.compensationBulkFile, this.compensationBulkFile.name);
    
    this.apiService.writeValue('post','/employee/compensation',fd).subscribe({
      next: (response: any) => {
        if(this.isDevMode) console.log(" upload completed ",response);
        this.saveSuccess('Compensation uploaded successfully');
        this.filesArrayClear();
    },
      error: (e) => {
        console.error("upload failed with ",e);
        this.showError(e);
      },
      complete: () => console.info('upload completed') 
    });
  }

  // this is to clear the filename array in child component
  filesArrayClear(){
    this.filesArrayComp = [];
    this.compensationBulkFile = null || undefined;
  }

  userFileEmitter(event:any){
    this.compensationBulkFile = event;
  }

  deletedFileInComp(event:any){
    this.filesArrayClear();
  }

  // this function is used to round the decimal digits, that exceeds two decimal points
  numberRoundDecimal(number:any) {
    return Math.round((number+Number.EPSILON)*Math.pow(10,2))/Math.pow(10,2);
  }
  headervalues()
  {
    const list = ['WORK_BASICINFO.EMPLOYEEID', 'WORK_BASICINFO.EMPLOYEE_NAME','WORK_INFO.DEPARTMENT', 'WORK_INFO.DESIGNATION','EXPERIENCES_INFO.LOCATION','WORKING_DAYS','LOSS_OF_PAY','CASUAL_LEAVE',
    'SICK_LEAVE','PT_LOC','BANK_INFO.BANKNAME','BANK_INFO.IFSCCODE','BANK_INFO.ACCNO','PAY_MODE','M_CODE','GROSS_EARNINGS', 'OT_AMOUNT', 'REIMBURSEMENTS', 'TDS', 'NET','PAY_ROLL_STATUS'];
   
    this.returnValue(list, 'headers', 'array')
  }

  returnValue(array:any, variable:any, type:any){
    type === 'array' ? (this as any)[variable] = [] : (this as any)[variable] = '';
    array.forEach((element:any) => {
      this.translate.get(element).subscribe((keyValue) => {
        if(type === 'array'){
          (this as any)[variable].push(keyValue);
        } else if (type === 'string'){
          (this as any)[variable] = keyValue;
        }
      });
    });
  }

  groupData(data:any){
    this.tableData = [];
    this.tableData = data;
    var groups = new Set(this.tableData.map((item:any) => item.type))
    this.result = [];
    groups.forEach((type) => {
      if(type != 'Reimbursements'){
        this.result.push({
          type: type, 
          components: this.tableData.filter((i:any) => i.type === type)
        });
      }
    })

    return this.result;
  }

  // SK27FEB24 on editing specific record lop value, created 'editRecord' object and made true
  onEditRecord(event:any){
    const index = this.payrolls.findIndex((emp:any)=>emp.employeeId === event.data.employeeId);
    this.payrolls[index] = event.data;
    // this.globalValues.tableData({config:{data:this.payrolls, displayedColumns:this.displayedColumns}});
  }

  // SK18JUN24 filter the table data based on keys checked
  searchFilterData(event:any){ 
      this.filterKeys[event[0]] = event[1];
      localStorage.setItem('payrollfilter',JSON.stringify(this.filterKeys))// MV24AUG24 stringify the filterkeys to payrollfilter
      this.filterRetain(this.filterKeys)
}
//MV24AUG24 Retain the filter state 
 filterRetain(filterkey:any){
    
    if(Object.keys(filterkey).length==0){
      this.payrolls = JSON.parse(this.finalData);
    }else{
      this.payrolls = [];
      let isDataChecked = false;
      
      for(let key of Object.keys(filterkey)){
        if(filterkey[key].length > 0){
          isDataChecked = true;
        }
      }
      if(!isDataChecked){
        this.payrolls = JSON.parse(this.finalData);
      } else {
        for(let key of Object.keys(filterkey)){
          const data = JSON.parse(this.finalData).filter((name:any)=> filterkey[key]?.includes(name[key]));
          const data1 = [...this.payrolls,...data];
          this.payrolls = this.removeDuplicates(data1);
        }
      }
    }
  }

  // SK18JUN24 to remove duplicate object form two arrays
  removeDuplicates(objects: any) {
    const seen = new Set<string>();
    const uniqueObjects: any = [];

    objects.forEach((obj:any) => {
        const key = JSON.stringify(obj);
        if (!seen.has(key)) {
            seen.add(key);
            uniqueObjects.push(obj);
        }
    });
    return uniqueObjects;
  }
  
  // SK25JUL24
  downloadBankFile(){
    this.bankFileSidescreen = true
  }

  // SK25JUL24 exporting/ saving bank fields
  bankFieldsButtonEmit(evt:any){
    if(evt?.button?.buttonAction === 'export'){
      this.apiService.getXlsx("/hrm/allemployees?month="+ this.queryMonth+"&bankFileExport=true").subscribe({
        next: (v: any) => {
          if (this.isDevMode) console.log(v);
          const file = new Blob([v], { type: 'text/csv' }); // This matches the content type of the response
          saveAs(file, this.selectaMonth + "_Bank_Export" || 'export.xlsx');  //name should be dynamic
          this.saveSuccess("File downloaded successfully");
        },
        error: (e: HttpErrorResponse) => {
          console.error(e);
          this.showError(e);
        },
        complete: () => console.info('complete')
      });
    } else if(evt?.button?.buttonAction === 'save'){
        this.apiService.writeValue("patch", "/settings/finance?month=", {fields:evt.checkListArray}).subscribe({
          next: (success: any) => {
            this.saveSuccess(success.message);
            this.globalValues.userGetAPI();
          },
          error: (e: HttpErrorResponse) => {
            console.error(e);
            this.showError(e);
          },
          complete: () => console.info('complete')
        });
    }
  }
}