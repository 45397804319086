<div class="d-flex flex-row justify-content-between">
    <svg-icon *ngIf="getEmployeeId.employeeName && getEmployeeId.employeeId" class="cursor-pointer" src="../assets/images/icons/general/arrow.svg" (click)="backEmitButton()"></svg-icon>
    <app-fusion-button buttonType="primary" buttonValue="+ {{'Add Record' | customTranslate : 'Add Record'}}"  (buttonClickEmit)="formDeclaration(); this.addNewRecord = true; this.editRecord = false" [buttonDisable]="addNewRecord ||  editRecord"></app-fusion-button>
</div>
<!-- SK15JUL24 displaying employee Id and Name -->
<div *ngIf="getEmployeeId.employeeName && getEmployeeId.employeeId">{{getEmployeeId.employeeName + "[" + getEmployeeId.employeeId + "]"}}</div>

<div class="comp-container p-4  mt-4" *ngIf="addNewRecord">
    <form [formGroup]="compensationForm" >
        <div class="d-flex flex-row">
            <div class="w-75 d-flex">
                <div class="d-flex row justify-content-start">
                    <div class="col-4 input-margin-top">
                        <label class="form-label label-txt">{{ 'ANNUAL_CTC' | customTranslate : 'Annual CTC' }}</label>
                        <div class="field-input">
                            <input class="form-control" type="number" placeholder="" formControlName="annualCTC" (keyup)="ctcFunction()">
                        </div>
                    </div>
                    <div class="col-4 input-margin-top">
                        <label class="form-label label-txt">{{ 'GROSS_SALARY' | customTranslate : 'Gross Salary' }}</label>
                        <div class="field-input">
                            <input class="form-control" type="number" placeholder="" formControlName="grossPay" [readOnly]="true">
                        </div>
                    </div>
                    <!-- SK15JUL24 adding variable pay input -->
                    <div class="col-4 input-margin-top">
                        <!-- RS27AUG24 -->
                        <!-- Used the translate pipe to successfully implement the translation -->
                        <label class="form-label label-txt">{{ 'VARIABLE_PAY_PER_ANUM' | customTranslate : 'Variable Pay per Annum' }}</label>
                        <div class="field-input">
                            <input class="form-control" type="number" placeholder="" formControlName="variablePayYearly" [readOnly]="true">
                        </div>
                    </div>
                    <div class="col-3 input-margin-top">
                        <label class="form-label label-txt">{{ 'NET_SALARY_PER_ANUM' | customTranslate : 'Net Salary per Annum' }}</label>
                        <div class="field-input">
                            <input class="form-control" type="number" placeholder="" formControlName="netPay" [readOnly]="true">
                        </div>
                    </div>
                    <div class="col-3 input-margin-top">
                        <!-- SK18JUN24 net pay per month-->
                        <label class="form-label label-txt">{{ 'TAKE_HOME_SALARY_PER_MONTH' | customTranslate : 'Take Home Salary per Month' }}</label>
                        <div class="field-input">
                            <input class="form-control" type="number" placeholder="" formControlName="netPayMonthly" [readOnly]="true">
                        </div>
                    </div>
                    <div class="col-3 input-margin-top">
                        <label class="form-label label-txt">{{ 'EFF_DATE' | customTranslate : 'Effective Date' }}</label>
                        <div class="field-input">
                            <input class="form-control" type="date" placeholder="" formControlName="effectiveDate" (change)="calculateTotal()">
                        </div>
                    </div>
                    <!-- SK18JUN24 balance amount to tally the compensation -->
                    <div class="col-3 input-margin-top text-end">
                        <label class="form-label label-txt">{{ 'BALANCE_AMOUNT_TO_TALLY' | customTranslate : 'Balance Amount To Tally' }}</label>
                        <div class=" text-end"> {{balanceToTally}}
                        </div>
                    </div>
                    <!-- SK25JUL24 adding currency code in compensation -->
                    <div class="col-3 input-margin-top">
                        <label class="form-label label-txt">{{ 'CURRENCY_CODE' | customTranslate : 'Currency Code' }}</label>
                        <app-drop-down  [lists]='currencyDropdown'
                        dropBorderRadius="5px 5px 5px 5px"
                        (dataEmit)="emittedValue($event, 'currencyCode')"
                        [selectedData]="currencyValue"></app-drop-down>
                    </div>
                </div>
            </div>
            <div class="w-25  d-flex justify-content-center">
                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" buttonType="text-only" (buttonClickEmit)="addNewRecord = false; editRecord = false"></app-fusion-button>
                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" [buttonDisable]="compensationForm.invalid" (buttonClickEmit)="saveCompensation()"></app-fusion-button>
            </div>
        </div>

        <table>
            <thead>
                <th class="ps-4">{{ 'SALARY_COMPONENTS' | customTranslate : 'Salary Components' }}</th>
                <th class="ps-4">{{ 'CALCULATE_TYPE' | customTranslate : 'Calculation Type' }}</th>
                <th class="text-end pe-4">{{ 'AMT_MONTH' | customTranslate : 'Amount Monthly' }}</th>
                <th class="text-end pe-4">{{ 'AMT_YEAR' | customTranslate : 'Amount Yearly' }}</th>
            </thead>
        
            <tbody *ngFor="let item of result; let i=index">
                <td class="comp-background">{{ item.type | customTranslate : item.type }}</td>
                <td class="comp-background"></td>
                <td class="comp-background"></td>
                <td class="comp-background"></td>
                <!-- Salary components-->
                <tr *ngFor="let value of item.components; let j=index">
                    <td >{{ value.componentName | customTranslate : value.componentName }}</td>

                    <!-- Calculation type-->
                    <td *ngIf="value.calculationBasis === 'flat' ">
                        {{ 'SYS_CALCULATE' | customTranslate : 'System Calculated' }}
                    </td>
                    <td class="flex flex-row" *ngIf="value.calculationBasis === 'percentage' ">
                        <input class="form-control tabe-input text-end radius-none" type="number" [value]="value.percentRate" (keyup)="calculatePercentAmount(i, $event, j)">
                        <div class="align-self-center ms-2">    {{ 'BASIC_PER' | customTranslate : '% of Basic' }}</div>
                    </td>
                    <td class="flex flex-row"  *ngIf="value.calculationBasis === 'fixed' ">
                        <input class="form-control tabe-input text-end radius-none" type="number" [value]="value.percentRate" (keyup)="calculatePercentAmount(i, $event, j)">
                        <div class="align-self-center ms-2">{{ 'GROSS_PER' | customTranslate : '% of Gross' }}</div>
                    </td>

                    <!-- Amount monthly-->
                    <td >
                        <input class="form-control tabe-input text-end radius-none margin-65" type="number" [value]="value.amountMonthly" [readonly]="true">
                    </td>

                    <!-- Amount yearly-->
                    <td >
                        <input class="form-control tabe-input text-end radius-none margin-65" type="number" [value]="value.amountYearly"  (keyup)="value.calculationBasis === 'fixed' || value.calculationBasis === 'percentage' ? calculateFixedPercentAmount(i, $event, j) : calculateFixedAmount(i, $event, j)">
                    </td>
                </tr>
            </tbody>
            <tr class="comp-background">
                <td class="">{{ 'COST_COMPANY' | customTranslate : 'Cost To Company' }}</td>
                <td></td>
                <td class="text-end  pe-4">{{compensationForm.controls['totalCalculatedMonthly'].value}}</td>
                <td class="text-end  pe-4">{{compensationForm.controls['totalCalculatedYearly'].value}}</td>
            </tr>
            <!-- SK18JUN24 total deductions and netpay  -->
            <tr class="comp-background">
                <td class="">{{ 'TOTAL_DEDUCTIONS_DEDUCTIONS_PLUS_BENEFITS' | customTranslate : 'Total Deductions (Deductions + Benefits)' }}</td>
                <td></td>
                <td class="text-end  pe-4">{{compensationForm.controls['totalDeductionsMonthly'].value}}</td>
                <td class="text-end  pe-4">{{compensationForm.controls['totalDeductionsYearly'].value}}</td>
            </tr>
            <tr class="comp-background">
                <td class="">{{ 'NET_PAY' | customTranslate : 'Net pay' }}</td>
                <td></td>
                <td class="text-end  pe-4">{{compensationForm.controls['totalNetPayMonthly'].value}}</td>
                <td class="text-end  pe-4">{{compensationForm.controls['totalNetPayYearly'].value}}</td>
            </tr>
        </table>
        <div class="comp-background mt-4 ps-4 pt-4 pb-4">{{ 'OT_CAL' | customTranslate : 'Over Time Calculation' }}</div>
        <div class="flex flex-row ps-4 pe-4 mt-4 justify-content-between">
            <div class="w-50">{{ 'CAL_BASIC_PAY' | customTranslate : 'Calculation type to be included with Basic Pay' }} </div>
            <div class="w-50 text-end">
                <label *ngFor="let allowance of allowanceList" class="cursor-pointer ms-3">
                    <input type="radio" name="overTimeCalculationComps" formControlName="overTimeCalculationComps" [value]="allowance.componentName" (change)="overTimeAmount()" /> 
                    {{ allowance.componentName }}
                </label>
            </div>
        </div>
        <div class="flex flex-row ps-4 pe-4 mt-5 justify-content-between">
            <div class="w-50">{{ 'NO_WORKING_MONTH' | customTranslate : 'Number of regular working days in a month' }}</div>
            <div class="w-50">
                <input class="form-control tabe-input text-end radius-none margin-65" type="number" formControlName="overTimeCalcDays" (keyup)="calculateOT($event, 'overTimeCalcDays')">
            </div>
        </div>
        <div class="flex flex-row ps-4 pe-4 mt-5 justify-content-between">
            <div class="w-50">{{ 'NO_WORKING_HOURS' | customTranslate : 'Number of regular working hours for this employee' }}</div>
            <div class="w-50">
                <input class="form-control tabe-input text-end radius-none margin-65" type="number"  formControlName="overTimeCalcHours" (keyup)="calculateOT($event, 'overTimeCalcHours')">
            </div>
        </div>
        <div class="flex flex-row ps-4 pe-4 mt-5 justify-content-between">
            <div class="w-50">{{ 'CALCULATE_OT/HOUR' | customTranslate : 'Calculated OT amount/hour' }}</div>
            <div class="w-50">
                <input class="form-control tabe-input text-end radius-none margin-65" type="number"  formControlName="overTimeCalcAmount" (keyup)="calculateOT($event, 'overTimeCalcAmount')">
            </div>
        </div>
        <div class="w-100 mt-3  d-flex justify-content-end">
            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" buttonType="text-only" (buttonClickEmit)="addNewRecord = false; editRecord = false"></app-fusion-button>
            <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" [buttonDisable]="compensationForm.invalid" (buttonClickEmit)="saveCompensation()"></app-fusion-button>
        </div>
    </form>
</div>

<div *ngFor="let comp of compInfo; let i=index"  >
    <div #openAccordion  class="comp-container p-4  mt-4">
        <div class="d-flex flex-row justify-content-between">
            <div #arrow class="d-flex flex-row">
                <img class="edit-open" src="../assets/images/icons/general/arrow-dropdown.svg"  (click)="toggleForm(i,comp);">
                <div class="ms-2 align-self-center">{{comp.compensationId | customTranslate : comp.compensationId}}</div>
            </div>
            <div class="d-flex flex-row">
                <img *ngIf="!editRecord" class="me-2 cursor-pointer" src="../assets/images/icons/Vectorpencil.svg" (click)="editComp(comp); toggleHelper(i); editRecord = true;">
                <mat-checkbox *ngIf="!editRecord"  #checkComp class="me-2" (click)="$event.stopPropagation()" id="compId{{i}}"
                    (change)="$event ? activateCompensation($event, comp, i) : null"
                    [checked]="activateCompSelection.isSelected(comp)"  [disabled]="comp.activeCheckbox"  [matTooltip]="comp.activeCheckbox === true ? 'Please complete the process to activate this compensation' : '' ">
                </mat-checkbox>
                <div *ngIf="!editRecord" class="w-100 me-2">{{ 'IN_USE' | customTranslate : 'In use' }}</div>
            </div>
        </div>
    </div>
    <div class="comp-container mt-4 accordion-content">
        <form class="p-4" [formGroup]="compensationForm" >
            <div class="d-flex flex-row">
                <div class="w-75 d-flex">
                    <div class="d-flex row justify-content-start">
                        <div class="col-4 input-margin-top">
                            <label class="form-label label-txt">{{ 'ANNUAL_CTC' | customTranslate : 'Annual CTC' }}</label>
                            <div class="field-input">
                                <input class="form-control" type="number" placeholder="" formControlName="annualCTC" (keyup)="ctcFunction()">
                            </div>
                        </div>
                        <div class="col-4 input-margin-top">
                            <label class="form-label label-txt">{{ 'GROSS_SALARY' | customTranslate : 'Gross Salary' }}</label>
                            <div class="field-input">
                                <input class="form-control" type="number" placeholder="" formControlName="grossPay" [readOnly]="true">
                            </div>
                        </div>
                        <div class="col-4 input-margin-top">
                            <!-- SK15JUL24 -->
                            <label class="form-label label-txt">{{ 'VARIABLE_PAY_PER_ANUM' | customTranslate : 'Variable Pay per Annum' }}</label>
                            <div class="field-input">
                                <input class="form-control" type="number" placeholder="" formControlName="variablePayYearly" [readOnly]="true">
                            </div>
                        </div>
                        <div class="col-3 input-margin-top">
                            <label class="form-label label-txt">{{ 'GROSS_SALARY' | customTranslate : 'Gross Salary' }}</label>
                            <div class="field-input">
                                <input class="form-control" type="number" placeholder="" formControlName="netPay" [readOnly]="true">
                            </div>
                        </div>
                        <div class="col-3 input-margin-top">
                            <!-- SK18JUN24 net salary per month -->
                            <label class="form-label label-txt">{{ 'TAKE_HOME_SALARY_PER_MONTH' | customTranslate : 'Take Home Salary per Month' }}</label>
                            <div class="field-input">
                                <input class="form-control" type="number" placeholder="" formControlName="netPayMonthly" [readOnly]="true">
                            </div>
                        </div>
                        <div class="col-3 input-margin-top">
                            <label class="form-label label-txt">{{ 'EFF_DATE' | customTranslate : 'Effective Date' }}</label>
                            <div class="field-input">
                                <input class="form-control" type="date" placeholder="" formControlName="effectiveDate" (change)="calculateTotal()">
                            </div>
                        </div>
                        <!-- SK18JUN24 balance amount to be tallied for compensation-->
                        <div class="col-3 input-margin-top text-end">
                            <label class="form-label label-txt">{{ 'BALANCE_AMOUNT_TO_TALLY' | customTranslate : 'Balance Amount To Tally' }}</label>
                            <div class=" text-end"> {{balanceToTally}}
                            </div>
                        </div>
                        <!-- SK25JUL24 adding currency code -->
                        <div class="col-3 input-margin-top">
                            <label class="form-label label-txt">{{ 'CURRENCY_CODE' | customTranslate : 'Currency Code' }}</label>
                            <app-drop-down  [lists]='currencyDropdown'
                            dropBorderRadius="5px 5px 5px 5px"
                            (dataEmit)="emittedValue($event, 'currencyCode')"
                            [selectedData]="currencyValue"></app-drop-down>
                        </div>
                    </div>
                </div>
                <!-- ngif is used to display the buttons only in edit mode -->
                <div *ngIf="isViewOn" class="w-25  d-flex justify-content-center">
                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" buttonType="text-only" (buttonClickEmit)="addNewRecord = false; editRecord = false; cancelAccordion(i)"></app-fusion-button>
                    <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" [buttonDisable]="compensationForm.invalid" (buttonClickEmit)="saveCompensation()"></app-fusion-button>
                </div>
            </div>
            <table>
                <thead>
                    <th class="ps-4">{{ 'SALARY_COMPONENTS' | customTranslate : 'Salary Components' }}</th>
                    <th class="ps-4">{{ 'CALCULATE_TYPE' | customTranslate : 'Calculation Type' }}</th>
                    <th class="text-end pe-4">{{ 'AMT_MONTH' | customTranslate : 'Amount Monthly' }}</th>
                    <th class="text-end pe-4">{{ 'AMT_YEAR' | customTranslate : 'Amount Yearly' }}</th>
                </thead>
            
                <tbody *ngFor="let item of result; let i=index">
                    <td class="comp-background">{{ item.type | customTranslate : item.type }}</td>
                    <td class="comp-background"></td>
                    <td class="comp-background"></td>
                    <td class="comp-background"></td>
                    <!-- Salary components-->
                    <tr *ngFor="let value of item.components; let j=index">
                        <td >{{ value.componentName | customTranslate : value.componentName }}</td>
    
                        <!-- Calculation type-->
                        <td *ngIf="value.calculationBasis === 'flat' ">
                            {{ 'SYS_CALCULATE' | customTranslate : 'System Calculated' }}
                        </td>
                        <td class="flex flex-row" *ngIf="value.calculationBasis === 'percentage' ">
                            <input class="form-control tabe-input text-end radius-none" type="number" [value]="value.percentRate" (keyup)="calculatePercentAmount(i, $event, j)">
                            <div class="align-self-center ms-2">{{ 'BASIC_PER' | customTranslate : '% of Basic' }}</div>
                        </td>
                        <td class="flex flex-row"  *ngIf="value.calculationBasis === 'fixed' ">
                            <input class="form-control tabe-input text-end radius-none" type="number" [value]="value.percentRate" (keyup)="calculatePercentAmount(i, $event, j)">
                            <div class="align-self-center ms-2">{{ 'GROSS_PER' | customTranslate : '% of Gross' }}</div>
                        </td>
    
                        <!-- Amount monthly-->
                        <td >
                            <input class="form-control tabe-input text-end radius-none margin-65" type="number" [value]="value.amountMonthly" [readonly]="true">
                        </td>
    
                        <!-- Amount yearly-->
                        <td >
                            <input class="form-control tabe-input text-end radius-none margin-65" type="number" [value]="value.amountYearly"  (keyup)="value.calculationBasis === 'fixed' || value.calculationBasis === 'percentage' ? calculateFixedPercentAmount(i, $event, j) : calculateFixedAmount(i, $event, j)">
                        </td>
                    </tr>
                </tbody>
                <tr class="comp-background">
                    <td class="">{{ 'COST_COMPANY' | customTranslate : 'Cost To Company' }}</td>
                    <td></td>
                    <td class="text-end  pe-4">{{compensationForm.controls['totalCalculatedMonthly'].value}}</td>
                    <td class="text-end  pe-4">{{compensationForm.controls['totalCalculatedYearly'].value}}</td>
                </tr>
                <!-- SK18JUN24 total deduction and net pay details -->
                <tr class="comp-background">
                    <td class="">{{ 'TOTAL_DEDUCTIONS_DEDUCTIONS_PLUS_BENEFITS' | customTranslate : 'Total Deductions (Deductions + Benefits)' }}</td>
                    <td></td>
                    <td class="text-end  pe-4">{{compensationForm.controls['totalDeductionsMonthly'].value}}</td>
                    <td class="text-end  pe-4">{{compensationForm.controls['totalDeductionsYearly'].value}}</td>
                </tr>
                <tr class="comp-background">
                    <td class="">{{ 'NET_PAY' | customTranslate : 'Net pay' }}</td>
                    <td></td>
                    <td class="text-end  pe-4">{{compensationForm.controls['totalNetPayMonthly'].value}}</td>
                    <td class="text-end  pe-4">{{compensationForm.controls['totalNetPayYearly'].value}}</td>
                </tr>
            </table>
            <div class="comp-background mt-4 ps-4 pt-4 pb-4">{{ 'OT_CAL' | customTranslate : 'Over Time Calculation' }}</div>
            <div class="flex flex-row ps-4 pe-4 mt-4 justify-content-between">
                <div class="w-50">{{ 'CAL_BASIC_PAY' | customTranslate : 'Calculation type to be included with Basic Pay' }} </div>
                <div class="w-50 text-end">
                    <label *ngFor="let allowance of allowanceList" class="cursor-pointer ms-3">
                        <input type="radio" name="overTimeCalculationComps" formControlName="overTimeCalculationComps" [value]="allowance.componentName" (change)="overTimeAmount()" /> 
                        {{ allowance.componentName | customTranslate : allowance.componentName }}
                    </label>
                </div>
            </div>
            <div class="flex flex-row ps-4 pe-4 mt-5 justify-content-between">
                <div class="w-50">{{ 'NO_WORKING_MONTH' | customTranslate : 'Number of regular working days in a month' }}</div>
                <div class="w-50">
                    <input class="form-control tabe-input text-end radius-none margin-65" type="number" formControlName="overTimeCalcDays" (keyup)="calculateOT($event, 'overTimeCalcDays')">
                </div>
            </div>
            <div class="flex flex-row ps-4 pe-4 mt-5 justify-content-between">
                <div class="w-50">{{ 'NO_WORKING_HOURS' | customTranslate : 'Number of regular working hours for this employee' }}</div>
                <div class="w-50">
                    <input class="form-control tabe-input text-end radius-none margin-65" type="number"  formControlName="overTimeCalcHours" (keyup)="calculateOT($event, 'overTimeCalcHours')">
                </div>
            </div>
            <div class="flex flex-row ps-4 pe-4 mt-5 justify-content-between">
                <div class="w-50">{{ 'CALCULATE_OT/HOUR' | customTranslate : 'Calculated OT amount/hour' }}</div>
                <div class="w-50">
                    <input class="form-control tabe-input text-end radius-none margin-65" type="number"  formControlName="overTimeCalcAmount" (keyup)="calculateOT($event, 'overTimeCalcAmount')">
                </div>
            </div>
            <div *ngIf="isViewOn" class="w-100  mt-3  d-flex justify-content-end">
                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.CANCEL' | customTranslate : 'Cancel' }}" buttonType="text-only" (buttonClickEmit)="addNewRecord = false; editRecord = false; cancelAccordion(i)"></app-fusion-button>
                <app-fusion-button buttonValue="{{ 'BUTTON_VALUE.SAVE' | customTranslate : 'Save' }}" [buttonDisable]="compensationForm.invalid" (buttonClickEmit)="saveCompensation()"></app-fusion-button>
            </div>
        </form>
    </div>
</div>
