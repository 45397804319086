import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserModel } from '../models/user.model';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user:any;
  roles: any;

  constructor(private router: Router, private authService: AuthService, private _loc : Location) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expTime: any = (localStorage.getItem('refresh_expires_at'));

    if (new Date() < new Date (expTime * 1000)) {
      // logged in so return true
      this.roles = this.authService.role;
      for(let i=0; i < route.data['roles'].length; i++){
        if(this.roles.includes(route.data['roles'][i])){
          return true;
        }
      }
      window.alert('You are not authorized');
      this.router.navigateByUrl('login');
      return false 
    }
    // not logged in so redirect to login page
    localStorage.removeItem('auth_token');
    localStorage.removeItem("expires_at");
    localStorage.removeItem('userData');    // MR 07DEC23 LocalStorage
    window.alert('You are not logged In')
    localStorage.setItem("routerURL", state.url) //SK10AUG24
    this.router.navigateByUrl('login');
    return false; 
  }
}
