<button type="button" class="btn btn-primary fc-button {{buttonType}}" [tabindex]="tabIndex"
    [attr.data-bs-toggle]="modalToTrigger != '' ? 'modal' : ''"
    [attr.data-bs-target]="modalToTrigger != '' ? '#'+modalToTrigger : ''"
    style="--fc-button-custom-bg: {{fcBtnBgColor}};--fc-button-custom-border: {{fcBtnBorder}};--fc-button-custom-color: {{fcBtnTextColor}};--fc-button-custom-height: {{fcBtnHeight}};--fc-button-custom-width: {{fcBtnWidth}};--fc-button-hover-bg: {{fcBtnHoverBgColor}};--fc-button-hover-text-color: {{fcBtnHoverTextColor}};"
    [disabled]="buttonDisable" [attr.data-bs-dismiss]='dataDismiss' (click)="buttonClicked($event)"
    [attr.id]="fcBtnId != 'default' ? fcBtnId : null">
    <svg-icon *ngIf="fcBtnIconLeftSrc != '' && isImageSvg" src="{{fcBtnIconLeftSrc}}"
        class="fc-btn-icon left-icon"></svg-icon>
    <img *ngIf="fcBtnIconLeftSrc != '' && !isImageSvg" src="{{fcBtnIconLeftSrc}}" class="fc-btn-icon left-icon">
    <span class="fc-btn-text">{{buttonValue}}</span>
    <svg-icon *ngIf="fcBtnIconRightSrc != '' && isImageSvg" src="{{fcBtnIconRightSrc}}"
        class="fc-btn-icon right-icon"></svg-icon>
    <img *ngIf="fcBtnIconRightSrc != '' && !isImageSvg" src="{{fcBtnIconRightSrc}}" class="fc-btn-icon right-icon">
</button>