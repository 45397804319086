
<ng-template #popoverContent>
    <ng-content select=".hover-base"></ng-content>
</ng-template>

<!-- Bootstrap Popover -->
<div *ngIf="isBootstrap" class="bc-popover">
    <div class="btn btn-secondary me-2" data-bs-container="body" data-bs-trigger="hover" data-bs-toggle="popover" data-bs-placement="top" [attr.data-bs-content]="dynamicPopover">
        <ng-container *ngTemplateOutlet="popoverContent"></ng-container>
    </div>
</div>

<!-- Fusion Custom Popover -->
<div *ngIf="!isBootstrap" class="fusion-popover">
    <div class="popover-base">
        <ng-container *ngTemplateOutlet="popoverContent"></ng-container>
        <div class="popover-content">
            <ng-content select=".hover-content"></ng-content>
        </div>
    </div>
</div>