import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SideNavEmitService } from '../../../services/side-nav-emit.service';
import { ApiService } from '../../../services/api.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { EssComponent } from '../ess.component';
import { Router } from '@angular/router';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
  selector: 'app-experiencetab',
  standalone: true,
  imports: [CommonModule, SuccessFailureMessageComponent, CardsComponent, PopoverComponent, FusionButtonComponent, AngularSvgIconModule, FormsModule, ReactiveFormsModule, EssComponent,TranslateModule, CustomTranslatePipe],
  templateUrl: './experiencetab.component.html',
  styleUrls: ['./experiencetab.component.css']
})

export class ExperiencetabComponent implements OnInit {
  @Input() isHamClicked: boolean = false;

  hideOrShowNav: string = "side-nav-container";
  experienceInfoForm!: FormGroup;
  isEditOn = false;
  errorMessage = '';
  showSubmissionMsg = false;
  showDeletedMsg = false;
  showErrorMsg = false;
  expGetData: any;
  deleteExpInfo: any;
  isDevMode: boolean = false;
  employeeId: any;
  @Input() selectedUser: any;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  companyId = this.globalValues.orgId;
  empTypeDropdown:any

  constructor(private formBuilder: FormBuilder, private apiService: ApiService, private sideNavItem: SideNavEmitService, private errorHandler: ErrorHandlerService, private router: Router, private alertService: AlertService,private globalValues:GlobalValuesService,private language:TranslateService) {
    this.experienceInfoForm = this.formBuilder.group({
      'designation': ['', Validators.required],
      'companyName': ['', Validators.required],
      'location': ['', Validators.required],
      'empType': ['', Validators.required],
      'dateOfStart': ['', Validators.required],
      'dateOfEnd': ['', Validators.required],
      'expID': ['']
    })
    this.isDevMode = isDevMode();
  }

  addExperience(): void {
    this.isEditOn = true;
    this.experienceInfoForm.reset();
  }

  ngOnInit(): void {
    // SK01APR24
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();

    if (this.isHamClicked) {
      this.hideOrShowNav = "hideOrShowNav hide"
    }

    // SK27FEB24 getting emptype dropdowns from local
    // SK06MAR24 settings data as whole stored in local and getting dropdowns from it
    const dropDowns = JSON.parse((localStorage.getItem('settingsData'))as any);
    this.empTypeDropdown = dropDowns?.dropdowns?.filter((bg:any)=>bg.name === 'emptype')[0]
    || {valueList:[
      {key:"fullTime", value:"Full Time"},
      {key:"partTime", value:"Part Time"},
      {key:"contract", value:"Freelancer"},
      {key:"freeLancer", value:"Contract"}
    ]};
  }

  ngOnChanges(): void {
    this.employeeId = this.selectedUser?.employeeId;
    this.getData();
    
    if (this.isHamClicked) {
      this.hideOrShowNav = "hideOrShowNav hide"
    }
  }

  saveMsg() {
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch', this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }

  deleteMsg() {
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.alertService.messageOnFetch('successFetch', this.employeeId, this.successMsg); // SK17JAN24 reducing api call
    this.ngOnInit();
  }

  errorMsg(err: HttpErrorResponse) {
    this.errorHandler.handleError(err);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  saveExperienceInfo() {
    this.isEditOn = false;
    if(this.isDevMode) console.log("Submit experience Info");
    let value = this.experienceInfoForm.value;
    if (value.expID != null) {
      return this.apiService.writeValue('patch', '/employee/experience', {'designation': value.designation, 'companyName': value.companyName, 'location': value.location, 'employementType': value.empType, 'startDate': value.dateOfStart, 'endDate': value.dateOfEnd, 'expId': value.expID})
      .subscribe({
        next: (expInfo) => {
          if(this.isDevMode) console.log("Next :", expInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    } else {
      return this.apiService.writeValue('post', '/employee/experience', {'designation': value.designation, 'companyName': value.companyName, 'location': value.location, 'employementType': value.empType, 'startDate': value.dateOfStart, 'endDate': value.dateOfEnd, 'employeeId': this.employeeId})
      .subscribe({
        next: (expInfo) => {
          if(this.isDevMode) console.log("Next :", expInfo);
          this.saveMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
    }
  }
  
  /** These are the GET method functions that returns the data from database */
  getESSDatas(){
    this.apiService.fetchValue('/user').subscribe((datas:any) => {
        this.employeeId = datas?.loggedInUser[0]?.employeeId
        this.getData();
    })
  }

  getData(){
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    // SK17JAN24 reducing api call
    this.expGetData = this.selectedUser?.experience;
  }

  cancelButton() {
    this.isEditOn = false;
    this.experienceInfoForm.reset();
  }

  onEdit(user: any) {
    this.isEditOn = true;
    this.experienceInfoForm.setValue({
      'designation': user.designation,
      'companyName': user.companyName,
      'location': user.location,
      'empType': user.employementType,
      'dateOfStart': user.startDate,
      'dateOfEnd': user.endDate,
      'expID': user.expId
    })
  }

  expOnDelete(deletedExp: any) {
    this.deleteExpInfo = deletedExp;
  }

  deleteExp() {
    this.apiService.writeValue('delete','/employee/experience',{ body: { 'expId': this.deleteExpInfo.expId, 'employeeId': this.employeeId}}).subscribe({
      next: () => {
        this.deleteMsg();
      },
      error: (err: HttpErrorResponse) => {
        this.errorMsg(err);
      }
    });
  }
}