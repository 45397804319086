import { Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FusionButtonComponent } from '../fusion-button/fusion-button.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApiService } from 'src/app/services/api.service';
import { Alert } from 'bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { TranslateModule } from '@ngx-translate/core';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
// import { deepClone } from '@angular-devkit/core';


@Component({
  selector: 'app-pdf-template',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, FusionButtonComponent, MatCheckboxModule,TranslateModule,CustomTranslatePipe],
  templateUrl: './pdf-template.component.html',
  styleUrls: ['./pdf-template.component.css']
})
export class PdfTemplateComponent implements OnInit {

  formatList = [{name:'Format 1', switchCase:'default template'}, {name:'Format 2', switchCase:'template 1'}];
  activeList:any;
  formatData: any;
  editTemplate = false;
  checkBox:any;
  accessData:any;
  fromObject:any;
  defaultTemplate:any;
  // editList = [{name:'Show PAN', value:'pan', checked: false}, {name:'Show YTD values', value:'ytd', checked: false}, {name:'Show Work Location', value:'workLocation', checked: false}, {name:'Show Department', value:'department', checked: false}, {name:'Show Paid Days', value:'workingDays', checked: false}, {name:'Show LOP Days', value:'lossOfPay', checked: false}]
  editList = [{name:'Show PAN', value:'pan', checked: false}, {name:'Show Work Location', value:'workLocation', checked: false}, {name:'Show Department', value:'department', checked: false}, {name:'Show Paid Days', value:'workingDays', checked: false}, {name:'Show LOP Days', value:'lossOfPay', checked: false}]
  constructor( private apiService: ApiService, private alertService: AlertService, private errorHandle: ErrorHandlerService, private globalValues: GlobalValuesService) {
  };

  ngOnInit(): void {
    this.getDefaultData();
    this.checkBox = new SelectionModel<any>(true, []);
    this.formatData = {
      company: {companyName:'ABC Studio', address:'#305, G1 - Information Technology Park, OMR, Chennai - 600 097'},
      employeePayDetails:{
        payslipMonth:'Payslip for the month of July 2023',
        summary: 'Employee Pay Summary',
        name: 'Employee Name : Virat Kohli',
        designation:'Designation : Manager',
        workLocation:'Location : Chennai',
        department:'Department : HR',
        doj:'Date Of Joining : 2023-05-01',
        payPeriod:'Pay Period : July 2023',
        payDate:'Pay Date : 31 July 2023',
        pf:'PF A/c Number : PFEMP2',
        uan:'UAN Number : UANEMP2',
        pan:'PAN Number : PANEMP2',
        tds:'TDS : 0',
        paidDays:'Paid days : 29',
        lop:'LOP : 0'
      },
      payDetails :{
        netPay: {name:'Employee Net Pay', value:'47370.86'},
        netPayInWords: 'forty seven thousand three hundred and seventy only',
        grossEarning: {name:'Gross Earning', value:'45858.35', ytd:474000},
        totalDeductions: {name:'Total Deductions', value:'5329.15', ytd:57600},
        totalReimbursements: {name:'Total Reimbursements', value:'2700'},
        components: {
          earnings:[
            {name:'Travel Allowance', value: 2916.67, ytd: 25000},
            {name:'Medical Allowance', value: 2916.67, ytd: 25000},
            {name:'Special Allowance', value: 7971.67, ytd:80000},
            {name:'HRA', value: 9573.33, ytd:100000},
            {name:'Basic', value: 19146.67, ytd:210000},
            {name:'Bonus', value: 1666.67, ytd:18000},
            {name:'Comission', value: 1666.67, ytd:18000},
          ],
          deductions: [
            {name:'TDS', value: 1187.49, ytd:12000},
            {name:'PF Employer', value: 1800, ytd:20600},
            {name:'Medical Insurance', value: 333.33, ytd:3000},
            {name:'EPF', value: 1800, ytd:20000},
            {name:'Professional Tax', value: 208.33, ytd:2000},
            {name:'LWF', value: 0, ytd:0},
          ],
          reimbursements : [
            {name:'Travel', value: 1200},
            {name:'Food', value: 1500}
          ]
        }
      }
    };
  }

  getDefaultData(){
    this.apiService.fetchValue('/user').subscribe((data:any) => {
      const datAccess = data?.payslipTemplate;
      this.defaultTemplate = data?.payslipTemplate.defaultTemplate;
      if( datAccess !== (null || undefined)){
        this.accessData = JSON.parse(JSON.stringify(datAccess));
        this.fromObject = JSON.parse(JSON.stringify(datAccess)); // to copy the object that should not stored in same memory
        this.activeList = this.accessData.defaultTemplate;
      } else {
        this.accessData = {
          ytd:false,
          pan: false,
          workLocation: false, 
          department: false, 
          workingDays: false, 
          lossOfPay: false, 
          defaultTemplate: this.activeList,
        }
      }
      this.editTemplate = false;
    })
  }

  clickedFormat(value:any){
    this.activeList = value;
    this.accessData['defaultTemplate'] =  value;
  }

  editClick(){
    this.editTemplate = true;
    let trueValues: any[] = []
    const accessDatas = this.fromObject;
    for (const key in accessDatas) {
      if (accessDatas[key] === true) {
        trueValues.push(key);
      }
    }
    this.editList.forEach((element:any) => {
      if(trueValues.includes(element.value)){
        element.checked = true;
        this.accessData[element.value] = true;
      } else {
        element.checked = false;
        this.accessData[element.value] = false;
      }
    });
  }

  showValues(value:any, event:any){
    // this.editClick()
    this.accessData[value] = event?.checked;
  }

  cancelButton(){
    this.editTemplate = false;
    this.editList.forEach((element:any) => {
      if(element.checked === true){
        this.accessData[element.value] = true;
      } else {
        this.accessData[element.value] = false;
      }
    });
  }

  saveShowFields(){
    const orgId = {companyId:this.globalValues.orgId};
    this.apiService.writeValue('patch', '/hrm/payslip', {...this.accessData, ...orgId})
    .subscribe({
      next: (success) => {
        this.successMessage('Fields updated successfully')
        this.getDefaultData();

      }, 
      error: (error: HttpErrorResponse) => {
        this.errorMessageFunction(error);
      }
    });
  }

  successMessage(msg:any){
    this.alertService.messageOnPass( 'success',msg)
  }

  errorMessageFunction(msg:any){
    this.errorHandle.handleError(msg);
    const msgOnError = this.errorHandle.errorMessage;
    this.alertService.messageOnPass( 'error', msgOnError)
  }
}

