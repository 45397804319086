<div class="flex flex-row h-100">
    <!-- SK16APR24 content width reduced on hovering module nav to avoid width overflow  -->
    <!-- // SK14MAY24 hamburger condition for opening module-->
    <div class="left-nav"  [class]="showSideNavInMobile ? 'show-mobile-menu' : ''">
        <app-modules [jsonArray]="configJSON" [languageCode]="languageCode" (moduleLabel)="emitModuleClick($event)"></app-modules>
    </div>
    <div class="content-section flex-grow w-100 max-width-minus-nav-width">
        <app-page-header></app-page-header>
        <div class="ms-2 me-2 overflow-auto">
            <app-tabs class="ms-2" [inputModule]="selectedModule" [languageCode]="languageCode" [inputTab]="selectedTab"></app-tabs> <!-- (emitTabContent)="tabContentEmit($event)" -->
        </div>
    </div>
</div>