import { Component, OnInit, isDevMode, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService }  from '../../../services/api.service';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';

interface HolidayDetails{
  startDate: any;
  leaveType: String;
 }


@Component({
  selector: 'app-holidaydetails',
  standalone: true,
  imports: [CommonModule, CustomTranslatePipe],
  templateUrl: './holidaydetails.component.html',
  styleUrls: ['./holidaydetails.component.css']
})

export class HolidaydetailsComponent implements OnInit {
  @Input() header: Array<any> = [];
  @Input() details: Array<any> = [];
  @Input() title:string = ''; //SK14MAR24 title if card
  @Input() fixedTable = false; //SK14MAR24 whether it should be fixed or not
  @Input() fixedTableSize = ""; //SK14MAR24 if fixed the size of card
  holidayArray:any


  isDevMode: boolean = false;
  
//to fetch data from  backend;
  constructor(private apiService: ApiService) { 
    this.isDevMode = isDevMode();
    this.holidayArray = this.details;
  }

  ngOnInit(): void {
    
  }

  ngOnChanges(){
    this.holidayArray = this.details;
  }

}
