import { Component, OnInit, ViewChild, Input, ElementRef, isDevMode } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FusionButtonComponent } from 'src/app/components/fusion-button/fusion-button.component';
import { CardsComponent } from 'src/app/components/cards/cards.component';
import { FormGroup, Validators, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { SuccessFailureMessageComponent } from 'src/app/components/alert-message/success-failure-message.component';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

import { DropDownComponent } from 'src/app/components/drop-down/drop-down.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { HrmComponent } from 'src/app/pages/hrm/hrm.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { DataTableComponent } from '../../../../../app/components/data-table/data-table.component';
import { EssComponent } from '../../ess.component';
import { Router } from '@angular/router';
import { GlobalValuesService } from 'src/app/services/global-values.service';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { Modal } from 'bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from 'src/app/models/Constants';
import { AlertService } from 'src/app/services/alert.service';
import { TruncateNamePipe } from "../../../../pipes/truncateName/truncate-name.pipe";
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CustomTranslatePipe } from 'src/app/pipes/custom-translate.pipe';
@Component({
    selector: 'app-expenses-tab',
    standalone: true,
    templateUrl: './expenses-tab.component.html',
    styleUrls: ['./expenses-tab.component.css'],
    imports: [CommonModule, FusionButtonComponent, CardsComponent,SuccessFailureMessageComponent, ReactiveFormsModule, DropDownComponent, PopoverComponent, MatTableModule, MatPaginatorModule, MatSortModule, DataTableComponent, EssComponent, FileUploadComponent, TruncateNamePipe,TranslateModule, CustomTranslatePipe]
})
export class ExpensesTabComponent implements OnInit {

  @Input() fromHrm: boolean = false;
  @Input() expenseHistory: any;
  @Input() selectedUser: any;
  @Input() employeeExpenses:any; 

  @ViewChild('curCode') curCode!: DropDownComponent;
  @ViewChild('expCategory') expCategory!: DropDownComponent;
  @ViewChild('reimbursmentData') reimbursmentData!: DropDownComponent;
  @ViewChild('demoModal') demoModal!: ElementRef<any>; //to open the bootstrap modal based on click function in ts file



  modalDirect!: Modal; // bootstrap modal to open using function
  tabsPage = true;
  sampleFile: any;
  filesArray: Array<any> = [];
  messageDisplay = '';
  errorMessage: any;
  receiptArray:Array<any>=[]; //to display filename in our component;
  documentFileName:any;
  templateHeaders : Array<any> = [];
  categoryValue = 'Select';
  currencyValue = 'INR';
  reimbursmentValue = 'Yes';
  status='Pending';
  expenseForm!: FormGroup;
  expenseClaimHistory: Array<any> = [];
  loggedUser: any;
  historyLength: any;
  isDevMode: boolean = false;
  editExpenseDatas = false;
  employeeId: any;
  uploadedEmployeeName:any;
  loggedUserName:any;
  safeSrc:any;
  successMessage:any;
  isFileuploaded:boolean=false;
  successMsg = Constants.SUCCESS_MESSAGE;
  deletedMsg = Constants.DELETE_SUCCESS_MESSAGE;
  companyId = this.globalValues.orgId;
  downloadCurrentDisable = true;
  projectsList:Array<any> = []; //SK25JUL24
  projectCode = 'Select'; //SK25JUL24
  
  constructor(private errorHandler: ErrorHandlerService, private formBuilder: FormBuilder, private apiService: ApiService, private hrmfun: HrmComponent, private router: Router, private globalValues: GlobalValuesService, private sanitizer: DomSanitizer, private alertService: AlertService, private datePipe: DatePipe,private translate : TranslateService) {
    this.isDevMode = isDevMode();
    
    this.expenseForm = this.formBuilder.group({
      'expenseDate': ['', Validators.required],
      'category': ['', Validators.required],
      'currencyCode': ['INR'],
      'status':['Pending'],
      'amount': ['', Validators.required],
      'claimReimbursement': ['Yes'],
      'description': [''],
      'billnumber': [''],
      'userID': [''],
      'file': [[], Validators.required],
      'project':""
    });
    this.globalValues.laguageChanges$.subscribe(lang => {
    
      this.templateHeadersValues();
    });
  }

  ngOnChanges() {
    // this.uploadedEmployeeName = this.globalValues.fullLoginName;

    // if (this.router.routerState.snapshot.url.includes('/hrm')) {
    //   this.employeeId = this.selectedUser?.employeeId;
    //   this.getData();
    // } else {
    //   this.employeeId = this.globalValues.empId;
    //   this.getData();
    // }
  }

  ngOnInit(): void {
    this.initialLoadData();
  }

  initialLoadData(){
    this.uploadedEmployeeName = this.globalValues.fullLoginName; 

    if (this.router.routerState.snapshot.url.includes('/hrm')) {
      this.employeeId = this.selectedUser?.employeeId;
      this.getData();
    } else {
      this.employeeId = this.globalValues.empId;
      this.getData();
    }
  }
  templateHeadersValues(){
    const list =['CATEGORY','ATTACHMENTS', 'DATE', 'AMOUNT','STATUS','COMMENTS', 'APPLIED_ON', 'REIMBURSED_STATUS']
    this.returnValue(list, 'templateHeaders', 'array')
  }
  
  returnValue(array:any, variable:any, type:any){
    type === 'array' ? (this as any)[variable] = [] : (this as any)[variable] = '';
    array.forEach((element:any) => {
      this.translate.get(element).subscribe((keyValue) => {
        if(type === 'array'){
          (this as any)[variable].push(keyValue);
        } else if (type === 'string'){
          (this as any)[variable] = keyValue;
        }
      });
    });
  }
  
  async getData() {
    const params = new HttpParams({
      fromObject:{
        employeeid : this.employeeId,
        companyId : this.companyId
      }
    });
    // SK25JUL24 getting projects if any
    const data = await this.getProjects();
    this.projectsList = [];
    data.forEach((list:any) => {
      this.projectsList.push(list.code)
    });
    // SK17JAN24 reducing api call
    this.loggedUser = this.selectedUser;
    this.expenseClaimHistory = this.employeeExpenses;
    this.expenseClaimHistory?.forEach((element:any, index:number) => {
      element.appliedOn = this.datePipe.transform(element.updatedAt, 'mediumDate');
      element.expenseDate = this.datePipe.transform(element.date, 'mediumDate');
      element.receipt.forEach((file:any, fileIndex:any) => {
        this.processFile(file.file, index, fileIndex)
      });
    });
    this.historyLength = this.employeeExpenses?.length;  
    this.loggedUserName =   this.selectedUser?.firstName + " " +  this.selectedUser?.lastName;
  }

  async getProjects(){
    try {
      const data = await this.apiService.fetchDataAsPromise('/project');
      return data;
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }

  // the AWS S3 link will be converted to a blob here
  processFile(url:any, elementIndex:number, fileIndex:any) : any{
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = (e1:any) =>  {
        var reader = new FileReader();
        reader.readAsDataURL(request.response);
        const blob = request.response;
        // then blob is passed here to read the as text
        this.onChange(blob, elementIndex, fileIndex)
    };
    request.send();
  }

  // finally base64 string will be generated here
  onChange(event:any, elementIndex:number, fileIndex:number) {
    var reader = new FileReader();
    reader.onload = (e: any) => {
      const fileOutput = e.target.result;
      // this base64 string will be assigned to docList array to display in UI
      this.expenseClaimHistory[elementIndex].receipt[fileIndex].file = fileOutput;
    };
    reader.readAsText(event);
  }

  toBeReimbursed = ['Yes', 'No', 'Reimbursed'];

  currencyList = [
    "AED",
    "AFN",
    "ALL",
    "ARS",
    "AUD",
    "ATS",
    "BSD",
    "BHD",
    "BDT",
    "BBD",
    "BEF",
    "BMD",
    "BRL",
    "BGN",
    "CAD",
    "CHF",
    "CLP",
    "CNY",
    "CRC",
    "CYP",
    "CZK",
    "COP",
    "DZD",
    "DKK",
    "DEM",
    "DOP",
    "DEM",
    "EGP",
    "EEK",
    "ESP",
    "EUR",
    "FJD",
    "FIM",
    "FRF",
    "GRD",
    "GTQ",
    "GBP",
    "HKD",
    "HUF",
    "HRK",
    "INR",
    "IDR",
    "IRR",
    "ISK",
    "IQD",
    "IEP",
    "ILS",
    "ITL",
    "JMD",
    "JPY",
    "JOD",
    "KES",
    "KRW",
    "KRW",
    "KWD",
    "LBP",
    "LUF",
    "LKR",
    "MYR",
    "MTL",
    "MUR",
    "MXN",
    "MAD",
    "NLG",
    "NZD",
    "NOK",
    "OMR",
    "PKR",
    "PEN",
    "PHP",
    "PLN",
    "PTE",
    "QAR",
    "RON",
    "ROL",
    "RUB",
    "SAR",
    "SGD",
    "SKK",
    "SIT",
    "SDD",
    "SEK",
    "TWD",
    "THB",
    "TTD",
    "TND",
    "TRY",
    "USD",
    "VEB",
    "VND",
    "XOF",
    "XAF",
    "XPF",
    "XCD",
    "XAU",
    "XDR",
    "XPD",
    "XPT",
    "XAG",
    "XDR",
    "ZAR",
    "ZMK",
  ]
  //dropdown value emitted from parent component assigned to form control
  emittedValue(event:any, formName: string){
    this.expenseForm.controls[formName].setValue(event.data);
  }

  // resetting the dropdowm to default values using view child decorator
  resetDropDownValues(){
    this.categoryValue = 'Select';
    this.currencyValue = 'INR';
    this.status='Pending',
    this.reimbursmentValue = 'Yes';
    this.projectCode = 'Select';
    this.filesArray = [];
    this.receiptArray=[];
    this.messageDisplay = '';
    this.expenseForm.patchValue({
      'expenseDate': '',
      'category': '',
      'currencyCode': 'INR',
      'amount': '',
      'status':'Pending',
      'claimReimbursement': 'Yes',
      'description': '',
      'billnumber': '',
      'userID': '',
      'file':[],
      'project':''
    });
  }

  //save button function - passing the values to the api
  saveExpenseInfo() {
    if(this.expenseForm.value.userID != ''){
      this.expensePatch();
    } else {
      this.expensePost();
    }
  }

  timeFormat(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
    const day = currentDate.getDate();
    const appliedOn = `${year}-${month}-${day}`;
    const time = currentDate.toLocaleTimeString('en-AU');
    return `${appliedOn}, ${time}`;
  }

  expensePost(){
    const formattedDateTime = this.timeFormat();
    if (this.fromHrm) {
      let value = this.expenseForm.value;
      
      return this.apiService.writeValue('post', '/employee/expense', { 'date': value.expenseDate, 'status':value.status,'category': value.category, 'currencycode': value.currencyCode, 'amount': value.amount, 'reImbursed': value.claimReimbursement, 'description': value.description, 'billNumber': value.billnumber, 'receipt': this.filesArray, 'time': formattedDateTime, 'employeeId': this.loggedUser.employeeId, 'name': this.loggedUserName, 'project':value.project})
        .subscribe({
          next: (expenseInfo) => {
            if(this.isDevMode) console.log("Next :", expenseInfo);
            this.saveMsg('Expense Applied Successfully');
            this.docResetButton();
            this.hrmfun.onEditEmp(this.selectedUser);
            this.globalValues.employeeDataGet();
          },
          error: (err: HttpErrorResponse) => {
            this.errorMsg(err);
          }
        });
    } else {
      let value = this.expenseForm.value;
      
      return this.apiService.writeValue('post', '/employee/expense', { 'date': value.expenseDate, 'category': value.category, 'status':value.status,'currencycode': value.currencyCode, 'amount': value.amount, 'reImbursed': value.claimReimbursement, 'description': value.description, 'billNumber': value.billnumber, 'receipt': this.filesArray, 'time': formattedDateTime, 'employeeId': this.loggedUser.employeeId, 'name': this.loggedUserName, 'project':value.project })
        .subscribe({
          next: (expenseInfo) => {
            if(this.isDevMode) console.log("Next :", expenseInfo);
            this.saveMsg('Expense Applied Successfully');
            this.docResetButton();
            // this.globalValues.employeeDataGet();
          },
          error: (err: HttpErrorResponse) => {
            if(this.isDevMode) console.log(err)
            this.errorMsg(err);
          }
        });
    }
  }

  expensePatch(){
    const formattedDateTime = this.timeFormat();
    let value = this.expenseForm.value;
    value.status = 'Pending';
    if (this.fromHrm) {
      let currenEmp = this.selectedUser;
      return this.apiService.writeValue('patch', '/employee/expense', { 'date': value.expenseDate, 'status':value.status,'category': value.category, 'currencycode': value.currencyCode, 'amount': value.amount, 'reImbursed': value.claimReimbursement, 'description': value.description, 'billNumber': value.billnumber, 'receipt': this.filesArray, 'time': formattedDateTime, 'id': value.userID, 'employeeId': currenEmp.employeeId, 'name': this.loggedUserName, 'project':value.project })
        .subscribe({
          next: (expenseInfo) => {
            if(this.isDevMode) console.log("Next :", expenseInfo);
            this.saveMsg('Expense Updated Successfully');
            this.docResetButton();
            this.hrmfun.onEditEmp(this.selectedUser); // update the expense table when edit expense
            this.globalValues.employeeDataGet();
            },
          error: (err: HttpErrorResponse) => {
            this.errorMsg(err);
          }
        });
    } else {
      return this.apiService.writeValue('patch', '/employee/expense', { 'date': value.expenseDate, 'status':value.status,'category': value.category, 'currencycode': value.currencyCode, 'amount': value.amount, 'reImbursed': value.claimReimbursement, 'description': value.description, 'billNumber': value.billnumber, 'receipt': this.filesArray, 'time': formattedDateTime, 'id': value.userID, 'employeeId': this.loggedUser.employeeId, 'name': this.loggedUserName, 'project':value.project})
      .subscribe({
          next: (expenseInfo) => {
            if(this.isDevMode) console.log("Next :", expenseInfo);
            this.saveMsg('Expense Updated Successfully');
            this.successMessage = 'Expense Updated Successfully';
            this.docResetButton();
          },
          error: (err: HttpErrorResponse) => {
            this.errorMsg(err);
          }
        });
    }
  }

  newExpense(){
    this.docResetButton()
    this.isFileuploaded = false;
    this.resetDropDownValues();
    this.editExpenseDatas = true;
    // this.expenseForm.reset();
  }

  //while clicking the edit button expense claim history, the data are fetched base on '_id' valkue and binded to the from
  editExpense(id: any) {
    this.editExpenseDatas = true;
    this.receiptArray = [];
    this.filesArray=[];
    const index = this.expenseClaimHistory.findIndex(x => x._id === id.data);
    const date = this.expenseClaimHistory[index].date;
    const category = this.expenseClaimHistory[index].category;
    const currencyCode = this.expenseClaimHistory[index].currencyCode;
    const status =this.expenseClaimHistory[index].status;
    const reimbursed = this.expenseClaimHistory[index].reImbursed;
    const amount = this.expenseClaimHistory[index].amount;
    const description = this.expenseClaimHistory[index].description;
    const billNumber = this.expenseClaimHistory[index].billNumber;
    const userId = this.expenseClaimHistory[index]._id;
    const receipt = this.expenseClaimHistory[index].receipt;
    this.currencyValue = currencyCode;
    this.status = status;
    this.categoryValue = category;
    this.reimbursmentValue = reimbursed;
    this.projectCode = this.expenseClaimHistory[index]?.project || "Select";
    this.expenseForm.patchValue({
      'expenseDate': date,
      'category': category,
      'currencyCode': currencyCode,
      'amount': amount,
      'status': status,
      'claimReimbursement': reimbursed,
      'description': description,
      'billnumber': billNumber,
      'userID': userId,
      'file': receipt,
      'project':this.projectCode
    })

    this.filesArray = this.expenseForm.value.file;
    receipt.forEach((element: any) => {
      // The file in base64 format is changed to file format and sent to uploadReadyFiles array in file-upload component
      const fileType = element.file.split(':')[1].split(';')[0]; // used to split filetype from base64
      const byteCharacters = window.atob(element.file.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new File([byteArray], element.fileName, { type: fileType });
      // This is the format of uploadReadyFiles array that to be displayed in child component
      // So, on editing, the file in the database is displayed in this format
      const url = URL.createObjectURL(file);
      this.receiptArray.push({file:file, url:this.sanitizer.bypassSecurityTrustResourceUrl(url)});
    });
  }

  saveMsg(message:string) {
    // this.alertService.messageOnPass('success', this.successMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, message); // SK17JAN24 reducing api call
    setTimeout(() => {this.initialLoadData()}, 5000);
  }

  deleteMsg() {
    // this.alertService.messageOnPass('success', this.deletedMsg);
    this.alertService.messageOnFetch('successFetch',this.employeeId, "Expense Delete Successfully"); // SK17JAN24 reducing api call
    this.initialLoadData();
  }
  
  tableCheckedDataa(checkedArray:any){
    this.checkedEmployees = [];
    this.checkedEmployees = checkedArray;
    if(this.checkedEmployees.length === 0){
      this.downloadCurrentDisable = true;
    } else {
      this.downloadCurrentDisable = false;
    }
    if(this.isDevMode) console.log('this.checkedEmployees',this.checkedEmployees);
  }
  checkedEmployees:any;
  downloadCurrentEmployees(){
    const csv = this.convertToCsv(this.checkedEmployees);
    const blob = new Blob([csv], { type: 'text/csv' });
    saveAs(blob, 'Expense History.csv');
    this.saveSuccess('Selected employee details exported successfully')
  }
  convertToCsv(objArray: any[]) {
    let arrayOfObjects: { category: any;name:any,expenseDate:any,amount:any,status:any,project:string,rejectionReason:any,appliedOn:any,reImbursed:any}[] = [];
    objArray.forEach(element => {
        arrayOfObjects.push({
        category: element.category,
        name:element.name,
        expenseDate: element.expenseDate,
        amount: element.amount,
        status: element.status,
        rejectionReason: element.rejectionReason,
        reImbursed: element.reImbursed,
        appliedOn:element.appliedOn,
        project:element?.project || ""

      });
    });
    console.log(objArray,'to check List')
    var selectedObjectsForCSV = this.filterObjectKeys(arrayOfObjects, ['category', 'expenseDate','amount', 'status','project', 'rejectionReason','appliedOn','reImbursed']);
    const array = typeof selectedObjectsForCSV !== 'object' ? JSON.stringify(selectedObjectsForCSV) : selectedObjectsForCSV;
    let str = '';
    array.unshift({category: "Category",expenseDate:'Date',amount:'Amount',status:'Status',project:'Project',rejectionReason:'Comments',appliedOn:'Applied On', reImbursed:'Reimbursed Status'})
      for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') {
          line += ',';
        } if (index === 'appliedOn' || index === 'expenseDate') {
          
          // Format startDate: "October 11, 2023" to "October 11 2023"
          line += array[i][index].replace(/,/g, '-');
      }
        else{
        line += array[i][index];
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
 
  // Function to get only the required fields for showing it in the excel
  filterObjectKeys(arrayOfObjects: any, keys: string[]) {
    let filteredObjects: any = [];
    arrayOfObjects.forEach((obj: any) => {
        filteredObjects.push(
            keys.reduce((acc: any, key: any) => {
                // Check if the key exists in the object and is not null or undefined
                if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined) {
                    acc[key] = obj[key];
                } else {
                    // If the key is not present or is null/undefined, replace it with an empty string
                    acc[key] = '';
                }
                return acc;
            }, {})
        );
    });
    return filteredObjects;
}
  saveSuccess(event:any) {
    this.alertService.messageOnPass('success', event);
  
  }
  errorMsg(error: HttpErrorResponse) {
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
    this.alertService.messageOnPass('error', this.errorMessage);
  }

  deleteExpense(id: any) {
    // SK27FEB24 changed since updated code on delete in datatable
    const data: object = { id : id.data, companyId : this.companyId }
    
    return this.apiService.writeValue('delete','/employee/expense', { 'body': { 'id': id.data, 'companyId' : this.companyId } })
      .subscribe({
        next: (expenseInfo) => {
          if(this.isDevMode) console.log("Next :", expenseInfo);
          this.saveMsg('Expense Deleted Successfully');
          this.docResetButton();
          if (this.fromHrm) {
            this.hrmfun.onEditEmp(this.selectedUser);
          }
          this.deleteMsg();
        },
        error: (err: HttpErrorResponse) => {
          this.errorMsg(err);
        }
      });
  }

  //cancel button function - resetting the form and alert messages
  docResetButton() {
    this.expenseForm.reset();
    this.resetDropDownValues();
    this.editExpenseDatas = false;
  }

  filePopupCancel() {
    this.messageDisplay = '';
  }

  emitAttachmentClick(event:any){
    this.modalDirect = new Modal(this.demoModal.nativeElement, { backdrop: 'static' });
    this.modalDirect.show();
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(event);
  }
 
  // this is to clear the filename array in child component
  // whenever the file is added, it is sent to fileUpload function and converted to base64 format
  fileNameArray(event:any){
    this.receiptArray = event;
    this.filesArray = [];
    event.forEach((element:any) => {
      this.fileUpload(element);
    });
  }

  //function when removing the files from this component array
  // whenever the file is removed from this component, the remaining file in that array will sent in loop to fileUpload function and converted to base64 format
  removeFile(index: number,file:any) {
    this.filesArray = [];
    this.receiptArray.splice(index, 1);
    if(this.receiptArray.length==0){
      this.expenseForm.controls['file'].patchValue([]);
    } else {
    this.receiptArray.forEach((element:any) => {
      this.fileUpload(element);
    });
    }
  }

  //function when removing the files from this file-upload component array
  // whenever the file is removed from file-upload component, the remaining file in that array will sent in loop to fileUpload function and converted to base64 format
  removeReceipt(event:any){
    this.receiptArray = event;
    this.filesArray=[];
    if(event.length == 0){
      this.expenseForm.controls['file'].patchValue([]); // when the file removed form list, we are assigning null to that formcontrolname
    } else {
      event.forEach((element:any) => {
        this.fileUpload(element);
      });
    }
  }

  // //converting the file to base 64 and assigning that to formControlName
  fileUpload(event: any) {
    var file: File = event.file;
    var reader = new FileReader();
    reader.onload = () => {
      this.sampleFile = reader.result;
      this.isFileuploaded=true;
      this.documentFileName= file.name;
      this.filesArray.push({file:this.sampleFile,fileName:this.documentFileName});
      this.expenseForm.controls['file'].patchValue(this.filesArray);
    };
    reader.readAsDataURL(file); 
  }
}
